import {Stack, Typography} from "@mui/material";
import React from "react";
import {CircleUpdateIcon} from "../../../../../images";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import PastCallCard from "../PastCallCard/PastCallCard";
import {useParams} from "react-router-dom";

interface PastCallCardContainerProps {
    maxheight?: string;
    labelText?: string;
    gap?: string;
    pastMeetings?: any;
    handleMeetingOnClick?: any;
    handleGetMeetingOnClick?: () => void;
}

const PastCallCardContainer: React.FC<PastCallCardContainerProps> = ({
                                                                         labelText,
                                                                         gap = "10px",
                                                                         maxheight = "240px",
                                                                         pastMeetings,
                                                                         handleMeetingOnClick,
                                                                         handleGetMeetingOnClick,
                                                                     }) => {
    const {t} = useTranslation()
    const params = useParams();

    const handleMeetingClick = (meetingId: any) => {
        handleMeetingOnClick(meetingId);
    };
    const handleGetPastMeetingClick = () => {
        handleGetMeetingOnClick && handleGetMeetingOnClick();
    };

    return (
        <Stack
            sx={{
                width: "100%",
                maxHeight: maxheight,
                bgcolor: "white",
                marginTop: "10px",
                padding: "10px",
            }}
        >
            <Stack
                sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                    marginBottom: "10px",
                }}
            >
                <Typography
                    sx={{font: "normal normal 600 15px/19px Source Serif Pro"}}
                >
                    {labelText}
                </Typography>
                <Stack
                    sx={{
                        height: "1px",
                        borderRadius: "2px",
                        flex: 1,
                        bgcolor: "#E9EDF2",
                    }}
                />
            </Stack>
            {Object.keys(pastMeetings).length === 0 ? (
                <div style={{display: "flex", alignItems: "center"}}>
                    <CircleUpdateIcon/>
                    <Typography
                        onClick={() => handleGetPastMeetingClick()}
                        sx={{
                            font: "normal normal 300 16.29px/13px Source Serif Pro",
                            marginLeft: "10px",
                        }}
                    >
                        {t("VIDEO_CENTER.CALL_LOGS")}
                    </Typography>
                </div>
            ) : (
                <Stack sx={{gap: gap, overflow: "auto", scrollbarWidth: "none"}}>
                    {Object.keys(pastMeetings).map((meetingId) => {
                        const meetingDetail = pastMeetings[meetingId];
                        return (
                            <PastCallCard
                                isSelected={params?.id === meetingId ? true : false}
                                key={meetingId}
                                meetingId={meetingDetail?.meetingId}
                                handleMeetingClick={handleMeetingClick}
                                meeting_Id={meetingDetail._id}
                                meetingTime={dayjs(meetingDetail?.createdMeeting).format(
                                    "DD-MM-YYYY - h:mm A"
                                )}
                            />
                        );
                    })}
                </Stack>
            )}
        </Stack>
    );
};

export default PastCallCardContainer;
