import React from "react";
import { Stack, Typography } from "@mui/material";
import { getFileIcon } from "../../../../../Utils";
import {
  SmallThreeDotMenu,
  SmallThreeDotMenuListInterface,
} from "../../../../common";
import styles from "./CloudStorageFilePreviewCard.module.css";
import {
  DeleteIcon,
  Details2Icon,
  DownloadIcon,
  MoveIcon,
  ShareIcon,
} from "../../../../../images";

const MENU_LISTS: SmallThreeDotMenuListInterface[] = [
  {
    id: 1,
    icon: Details2Icon,
    text: "Details",
    // LANG: "",
  },
  {
    id: 2,
    icon: ShareIcon,
    text: "Share",
    // LANG: "",
  },
  {
    id: 3,
    icon: DownloadIcon,
    text: "Download",
  },
  {
    id: 4,
    icon: MoveIcon,
    text: "Move",
  },
  {
    id: 5,
    icon: DeleteIcon,
    text: "Delete",
    color: "red",
  },
];

interface CloudStorageFilePreviewCardProps {
  fileName: string;
}

const CloudStorageFilePreviewCard: React.FC<
  CloudStorageFilePreviewCardProps
> = ({ fileName }) => {
  return (
    <Stack className={styles["cloudStorageFilePreviewCard"]}>
      <Stack className={styles["cloudStorageFilePreviewCard__info"]}>
        {getFileIcon({ name: fileName, iconSize: 25 })}
        <Typography className={styles["cloudStorageFilePreviewCard__text"]}>
          {fileName}
        </Typography>
      </Stack>
      <SmallThreeDotMenu menuItems={MENU_LISTS} />
    </Stack>
  );
};

export default CloudStorageFilePreviewCard;
