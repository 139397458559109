import React, { useState } from "react";
import TitleContainer from "../../TitleContainer/TitleContainer";
import {
  BadgeDropdownTitleContainer,
  ChipInputField,
  CustomRadioFormControlLabel,
} from "../../../../../../../common";
import { RadioGroup } from "@mui/material";
import { KeyWordsMatchStateEnum } from "../../../../CommandControlSearchContainer";

type ComponentDataType = {
  bodyKeywords: string[]; // Array of body keywords
  matchType: KeyWordsMatchStateEnum; // Selected match type (Exact, Contains, etc.)
};
interface EmployeeDepartmentProps {
  stateData: ComponentDataType; // Current data passed from the parent
  setStateData: (data: ComponentDataType) => void; // Function to update data in the parent
}

const EmployeeDepartment: React.FC<EmployeeDepartmentProps> = ({
  setStateData,
  stateData,
}) => {
  const handleBodyKeywordsChange = (keywords: string[]) => {
    setStateData({ ...stateData, bodyKeywords: keywords });
  };

  // Handle updates for "matchType" radio buttons
  const handleMatchTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedMatchType = event.target.value as KeyWordsMatchStateEnum;
    setStateData({ ...stateData, matchType: selectedMatchType });
  };
  return (
    <TitleContainer title="Department, Team or Role" isCollapasable>
      <BadgeDropdownTitleContainer title="Keywords">
        <ChipInputField
          initialChips={stateData.bodyKeywords}
          onChange={handleBodyKeywordsChange} // Pass handler for body keywords
        />
      </BadgeDropdownTitleContainer>
      <RadioGroup
        aria-labelledby="employeeStatusValue-radio-buttons-group"
        name="employeeStatusValue-radio-buttons-group"
        value={stateData.matchType}
        onChange={handleMatchTypeChange}
        sx={{ p: 0, marginTop: "5px", gap: "13px" }}
      >
        <CustomRadioFormControlLabel
          value={KeyWordsMatchStateEnum.EXACT_MATCH}
          label="Exact match"
        />
        <CustomRadioFormControlLabel
          value={KeyWordsMatchStateEnum.CONTAINS}
          label="Contains"
        />
        <CustomRadioFormControlLabel
          value={KeyWordsMatchStateEnum.DOES_NOT_CONTAIN}
          label="Does not contain"
        />
      </RadioGroup>
    </TitleContainer>
  );
};

export default EmployeeDepartment;
