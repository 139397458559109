import { RadioGroup } from "@mui/material";
import React, { useState } from "react";
import { CustomRadioFormControlLabel } from "../../../../../../common";

interface BonusEligibilityRadioGroupsProps {}

const BonusEligibilityRadioGroups: React.FC<
  BonusEligibilityRadioGroupsProps
> = ({}) => {
  const [bonusEligibility, setBonusEligibility] = useState("yes");
  return (
    <RadioGroup
      aria-labelledby="Bonus-radio-buttons-group"
      name="Bonus-radio-buttons-group"
      value={bonusEligibility}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedValue = event.target.value;
        setBonusEligibility(selectedValue);
      }}
      sx={{ p: 0, flexDirection: "row", alignItems: "center", gap: "10px" }}
    >
      <CustomRadioFormControlLabel value="yes" label="Yes" />
      <CustomRadioFormControlLabel value="no" label="NO" />
    </RadioGroup>
  );
};

export default BonusEligibilityRadioGroups;
