import React from "react";
import TitleContainer from "../../TitleContainer/TitleContainer";
import { MultiSelectRadioBtn } from "../../../../../../../common";
import { AdditionalFilterMultiRadioBtnType } from "../../../../CommandControlSearchContainer";

const attachementsTypeList: AdditionalFilterMultiRadioBtnType = {
  _id: "attachementsType",
  field: ["message"],
  translatedTitle: "Attachements",
  option: [
    { _id: "1", translatedTitle: "Images", value: "Images" },
    { _id: "2", translatedTitle: "Grids", value: "Grids" },
    { _id: "3", translatedTitle: "Videos", value: "Videos" },
    { _id: "4", translatedTitle: "Decks", value: "Decks" },
    { _id: "5", translatedTitle: "Docs", value: "Docs" },
    { _id: "6", translatedTitle: "Audio", value: "Audio" },
  ],
};

type ComponentDataType = string[]; // this is type for this component data
interface AttachementsProps {
  stateData: ComponentDataType; // Current data passed from the parent
  setStateData: (data: ComponentDataType) => void; // Function to update data in the parent
}

const Attachements: React.FC<AttachementsProps> = ({
  setStateData,
  stateData,
}) => {
  const handleSelectionChange = (value: string) => {
    const updatedMeetingTypes = stateData.includes(value)
      ? stateData.filter((item) => item !== value) // Remove if already selected
      : [...stateData, value]; // Add if not selected

    // Update the state in the parent
    setStateData(updatedMeetingTypes);
  };
  return (
    <TitleContainer
      title={attachementsTypeList.translatedTitle}
      isCollapasable
      childrenSx={{ flexDirection: "row", flexWrap: "wrap" }}
    >
      {attachementsTypeList.option.map((item, index) => (
        <MultiSelectRadioBtn
          sx={{ width: "45%" }}
          key={item._id}
          isChecked={stateData.includes(item.value)}
          label={item.translatedTitle}
          onClick={() => handleSelectionChange(item.value)}
        />
      ))}
    </TitleContainer>
  );
};

export default Attachements;
