import React from "react";
import { EstimatedARRIcon } from "../../../../../images";
import { PayrollCardHeaderButton, PayrollCardMenu } from "../../common";
import { DealStageCurrencySymbols } from "../../../../common";

interface DeductionsPayrollCardProps {}

const DeductionsPayrollCard: React.FC<DeductionsPayrollCardProps> = ({}) => {
  const currencySymbol = DealStageCurrencySymbols.USD;

  return (
    <div>
      <PayrollCardHeaderButton
        bgColor="#FF8181"
        currencySymbol={currencySymbol}
        icon={EstimatedARRIcon}
        label="Deductions"
        value={15000.0}
      >
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Federal Tax"
          primaryValue={5000}
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="State Tax"
          primaryValue={1000}
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Local Tax"
          primaryValue={2500}
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Social Security"
          primaryValue={500}
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Medicare"
          primaryValue={10000}
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Health Plan"
          primaryValue={2000}
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Retirement"
          primaryValue={500}
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Other Insurance"
          primaryValue={700}
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Garnishments"
          primaryValue={260}
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Union Dues"
          primaryValue={150}
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Loan Repayment"
          primaryValue={450}
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Other Deductions"
          primaryValue={200}
        />
      </PayrollCardHeaderButton>
    </div>
  );
};

export default DeductionsPayrollCard;
