import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import {
  ActivtiyIcon,
  AllDirArrowCircleIcon,
} from "../../../../../../../images";

interface CardHeaderViewProps {
  onCloseClick: () => void;
}

const CardHeaderView: React.FC<CardHeaderViewProps> = ({ onCloseClick }) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        height: "55px",
        flexShrink: 0,
        bgcolor: "white",
        padding: "0px 20px",
        boxSizing: "border-box",
        borderBottom: "1px solid #E9EDF2",
      }}
    >
      <IconButton
        onClick={onCloseClick}
        sx={{ p: 0, borderRadius: 0, flexDirection: "row", gap: "5px" }}
        disableRipple
      >
        <AllDirArrowCircleIcon color="black" direction="left" w={15} />
        <Typography
          sx={{
            font: "normal normal 300 11px/14px Source Serif Pro",
            color: "black",
          }}
        >
          Close
        </Typography>
      </IconButton>

      <Stack sx={{ flexDirection: "row", gap: "5px" }}>
        <ActivtiyIcon w={15} />
        <Typography
          sx={{
            font: "normal normal 300 11px/14px Source Serif Pro",
            color: "black",
          }}
        >
          Audit logs
        </Typography>
      </Stack>
    </Stack>
  );
};

export default CardHeaderView;
