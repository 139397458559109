import {Stack} from "@mui/material";
import React from "react";
import {EventIconButton} from "../../../../../../common";
import {SnoozeButton} from "../../../../../Message/components";
import {DoneIcon} from "../../../../../../../images";
import {useTranslation} from "react-i18next";

interface ButtonGroupOneViewProps {
    onButtonClick: (eventId: number, snoozeDuration?: string) => void; // Add this prop
    notificationInfo?: any;
}

const ButtonGroupOneView: React.FC<ButtonGroupOneViewProps> = ({onButtonClick, notificationInfo}) => {
    const {t} = useTranslation();
    const handleClick = (eventId: number, snoozeDuration: string) => {
        // onButtonClick(eventId, snoozeDuration); // Pass the unique ID
    };

    return (
        <Stack sx={{flexDirection: "row", alignItems: "center", gap: "10px"}}>
            <EventIconButton
                buttonTitle={t("CALENDAR.START")}
                icon={DoneIcon}
                iconColor="#008D4C"
                onClick={() => handleClick(4, "0")} // Add onClick handler
            />
            <SnoozeButton onSelect={(value:any) => handleClick(7, value)}/>
        </Stack>
    );
};

export default ButtonGroupOneView;
