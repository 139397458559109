import {Avatar, Box, Stack, Typography} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MessagesState } from "../../../../redux/Slice/message/messageTypes";
import { RootState } from "../../../../redux/store";
import Grid from "@mui/material/Grid";
import { AuthState } from "../../../../redux";
import {
  addChannelAction,
  addChannelIdAction,
  addChannelNameAction,
  addEditMessageContentAction,
  addEditMessageContentStateAction,
  addReplyMessageContentAction,
  sortChannelsAction,
} from "../../../../redux/Slice/message/messageSlice";
import {
  getChannelMessages,
  markMessagesAsRead,
  sendMessages,
  updateMessage,
} from "../../../../redux/Slice/message/messageActions";
import { MessagesScreen } from "../messagesScreen";
import {
  CloseIcon,
  InfoIcon,
  MailOutlineIcon, SlackPng, StreamIconBearishOSIconPng,
  VideocamIcon,
} from "../../../../images";
import { MessageActionBar } from "../../../common/MessageActionBar";
import { useNavigate, useParams } from "react-router-dom";
import MessageActivitySidebar from "../components/MessageActivitySidebar/MessageActivitySidebar";
import {getHTMLContent, MESSAGE_ENUMS} from "../constant";
import { MessageCenterTabs } from "../components";
import { SearchBar } from "../../CRM/components";
import { useTranslation } from "react-i18next";
import {FullScreenLoader, SmallPinnedCard} from "../../../common";

const MessageChats = () => {
  const {
    messages,
    channels,
    currentChannelId,
    currentChannel,
    editMessageContent,
    replyMessageContent,
    loading,
  } = useSelector((state: RootState) => state.message as MessagesState);
  const { user } = useSelector((state: RootState) => state.auth as AuthState);

  const { t } = useTranslation();

  const [clear, setClear] = useState<boolean>(false);
  const [message, setMessage] = useState<string>(
    editMessageContent?.content ? editMessageContent?.content : ""
  );
  const [openSchedule, setOpenSchedule] = useState<boolean>(false);
  const [messageMentionedIds, setMessageMentionedIds] = useState<string[]>([]);
  const [messageFlags, setMessageFlags] = useState<string[]>([]);
  const [showActivitySidebar,setShowActivitySidebar] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [vdAndMgIcon, setVdAndMgIcon] = useState(false);

  useEffect(() => {
    if (editMessageContent?.content) {
      setMessage(editMessageContent?.content);
    } else {
      setMessage("");
    }
  }, [editMessageContent?.content]);

  useEffect(() => {
    if (!currentChannelId) {
      dispatch(addChannelIdAction(id));
    }
    if (currentChannelId) {
      const currentChannel = channels[currentChannelId];
      getChannelDetails(currentChannelId);
      dispatch(addChannelAction(currentChannel));
      dispatch(sortChannelsAction());
      dispatch(addEditMessageContentAction(null));
      dispatch(addReplyMessageContentAction(null));
      dispatch(addChannelNameAction(currentChannel?.displayName));
    }
  }, [currentChannelId]);

  const getChannelDetails = async (channelId: string) => {
    const error: any = await dispatch(
      getChannelMessages({
      id: channelId,
      channelType: channelId.length === 11 
        ? MESSAGE_ENUMS.SLACK 
        : channelId.length === 24
        ? MESSAGE_ENUMS.BEARISH 
        : MESSAGE_ENUMS.TEAMS,
      })
    );
    if (error?.state?.message?.error) {
      navigate(`/Message`);
    }
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value: any = event;
    const htmlConvertText = getHTMLContent(value);
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlConvertText;

    const plainText: any = tempDiv?.innerText || tempDiv?.textContent;
    if (plainText.trim() === "") {
      return;
    }
    setOpenSchedule(false);
    const tempElement = document.createElement("div");
    tempElement.innerHTML = value;
    const mentions = tempElement.querySelectorAll(".tribute-mention");

    const mentionArray = Array.from(mentions).map((mention: any) =>
      mention.textContent.trim()
    );

    const paragraphs = tempElement.querySelectorAll("p");

    const flagArray = Array.from(paragraphs)
      .map((p: HTMLElement) => p.textContent?.trim())
      .filter((text) => {
        return (
          text &&
          text.startsWith("/") &&
          ["Low", "Medium", "High"].includes(text.substring(1))
        );
      })
      .map((text: any) => text.substring(1).toUpperCase());

    setMessageFlags(flagArray);
    setMessageMentionedIds(mentionArray);
    setMessage(value);
  };

  const handleEmojiSelect = (emoji: { native: string }) => {
    setMessage((prevMessage) => prevMessage + emoji.native);
  };

  const handleCancelReply = async () => {
    await dispatch(addReplyMessageContentAction(null));
  };
  const editMessageState = async (editMessageContentId: any) => {
    let editedMessageContentState = await {
      content: message,
      id: messages[editMessageContentId],
    };

    await dispatch(addEditMessageContentStateAction(editedMessageContentState));
  };

  const handleClearInput = () => {
    dispatch(addEditMessageContentAction(null));
    setClear(!clear);
    setMessage("");
    setOpenSchedule(false);
  };

  function formatHtml(html:any) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    function processNode(node:any) {
      if (node.nodeType === Node.TEXT_NODE) {
        return node.textContent.trim() + " ";
      }
      let result = "";

      for (let child of node.childNodes) {
        if (child.nodeName === "STRONG") {
          result += "*" + processNode(child) + "* ";
        } else if (child.nodeName === "EM") {
          result += "_" + processNode(child) + "_ ";
        } else if (child.nodeName === "BR") {
          result += "\n"; // Replace <br> with newline
        } else {
          result += processNode(child);
        }
      }
      return result;
    }

    return processNode(tempDiv).trim();
  }

  const handleSendMessage = async () => {
    const froalaFormat = formatHtml(message);
    if (editMessageContent?.content) {
      let editedMessageContent = {
        content: currentChannel?.channelType === MESSAGE_ENUMS.SLACK ? froalaFormat.trim() : message.trim(),
        channelId: currentChannelId,
        slackMessageTimeStamp: editMessageContent?.id,
        channelType: currentChannel?.channelType
          ? currentChannel?.channelType
          : MESSAGE_ENUMS.BEARISH,
          teamId: currentChannel?.teamId,
      };
      await dispatch(
        updateMessage(editMessageContent?.id, editedMessageContent)
      );
      editMessageState(editMessageContent?.id);
    } else {
      const payload: any = {
        content:
          currentChannel?.channelType === MESSAGE_ENUMS.SLACK ? froalaFormat : message,
        channelId: currentChannelId,
        replyTo: replyMessageContent?.id ? replyMessageContent?.id : null,
        mentioned: messageMentionedIds ? messageMentionedIds : [],
        channelType: currentChannel?.channelType
          ? currentChannel?.channelType
          : MESSAGE_ENUMS.BEARISH,
          teamId: currentChannel?.teamId,
       
      };
      await dispatch(sendMessages(payload));
    }
    if (currentChannel?.channelType === MESSAGE_ENUMS.BEARISH) {
      dispatch(markMessagesAsRead(currentChannelId));
    }
    setClear(!clear);
    setMessage("");
    setMessageFlags([]);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleCloseChat = () => {
    dispatch(addChannelIdAction(null));
    dispatch(addChannelNameAction(null));
    dispatch(addChannelAction(null));
    navigate(`/Message`);
  };
  if (loading) {
    return <FullScreenLoader />;
  }
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        style={{ padding: "0 30px 0 30px", marginBottom: "20px" }}
      >
        <Box
          onClick={handleCloseChat}
          display="flex"
          justifyContent="end"
          alignItems="center"
          p={2}
        >
          <Box>
            <SearchBar
              iconSize={15}
              containerSx={{ borderRadius: "5px" }}
              font="normal normal 400 11px/14px Source Serif Pro"
              customStyles={{ width: "330px", display: "flex", color: "black" }}
              placeholder={t("MESSAGE.SEARCH_ALL_CHATS")}
            />
          </Box>
        </Box>
        <MessageCenterTabs />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={showActivitySidebar ? 9 : 12} style={{paddingTop:"4px"}} >
          <Box
            display="flex"
            padding="0 30px 0 30px"
            flexDirection="column"
            height="77vh"
            width="100%"
          >
            <Box display="flex" alignItems="center" height="46px">
              {currentChannel?.displayName && (
                  <SmallPinnedCard
                      size={30}
                      userName={currentChannel?.displayName}
                      isActive={currentChannel?.status === "online" || undefined}
                      userIconSrc={currentChannel?.avatar || ""}
                  />
              )}
              <Stack style={{display:"flex",width:"100%",flexDirection:"row",alignItems:"center"}} >
                <Typography
                    sx={{ font: "normal normal 600 16.29px/13px Source Serif Pro" }}
                    ml={2}
                >
                  {currentChannel?.displayName}
                </Typography>
                <Box
                    component={"img"}
                    sx={{ height: "15px", width: "15px",marginLeft:"20px" }}
                    src={currentChannel?.channelType === MESSAGE_ENUMS.SLACK
                        ? SlackPng
                        : StreamIconBearishOSIconPng}
                    alt="logo"
                />
              </Stack>
              {currentChannel && (
                <Box
                  display="flex"
                  width="100%"
                  alignItems="end"
                  justifyContent="end"
                  style={{ padding: "16px 0px 16px 16px" }}
                >
                  {vdAndMgIcon && (
                    <>
                      <VideocamIcon
                        onClick={() => navigate("/Calls")}
                        style={{ marginRight: "16px" }}
                      />
                      <MailOutlineIcon
                        onClick={() => navigate("/Email")}
                        style={{ marginRight: "16px" }}
                      />
                    </>
                  )}
                  {currentChannel?.channelType === "BEARISH" && (
                      <div
                          style={{display: "flex", cursor: "pointer"}}
                          onClick={() => setShowActivitySidebar(!showActivitySidebar)}
                      >
                        <InfoIcon style={{marginRight: "16px"}}/>
                      </div>
                  )}
                  <CloseIcon onClick={handleCloseChat}/>
                </Box>
              )}
            </Box>
            <Box
                id="scrollableDiv"
                flexGrow={1}
                height="100%"
                overflow="auto"
                sx={{
                  padding: "0px 40px 16px 16px",
                margin: "20px 0px 10px 0px",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <MessagesScreen
                channels={channels}
                user={user}
                messages={messages}
              />
            </Box>
            {(currentChannel?.isGroupChat
              ? currentChannel?.currentUserRole?.toUpperCase() !== "VIEWER" &&
                !currentChannel?.currentUserLeave
              : !currentChannel?.currentUserLeave) && (
              <MessageActionBar
                handleClearInput={handleClearInput}
                openSchedule={openSchedule}
                handleSendMessage={handleSendMessage}
                handleSearchChange={handleSearchChange}
                handleKeyPress={handleKeyPress}
                message={message}
                handleEmojiSelect={handleEmojiSelect}
                replyMessage={replyMessageContent}
                handleCancelReply={handleCancelReply}
                currentChannel={currentChannel}
                userDetails={user}
                setOpenSchedule={setOpenSchedule}
              />
            )}
          </Box>
        </Grid>
        {showActivitySidebar && <Grid item xs={3} style={{ height: "79vh" }}>
          <MessageActivitySidebar />
        </Grid>}

      </Grid>
    </>
  );
};

export default MessageChats;
