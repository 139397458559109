import React from "react";
import { CurrencyDropdown } from "../../../../common";
import { Stack } from "@mui/material";

interface TableCurrencyAreaProps {
  value: any;
}

const TableCurrencyArea: React.FC<TableCurrencyAreaProps> = ({}) => {
  return (
    <Stack alignItems={"center"}>
      <CurrencyDropdown />
    </Stack>
  );
};

export default TableCurrencyArea;
