import React, { useState } from "react";
import TitleContainer from "../../TitleContainer/TitleContainer";
import { MultiSelectRadioBtn } from "../../../../../../../common";
import { AdditionalFilterMultiRadioBtnType } from "../../../../CommandControlSearchContainer";

const EmailTypeList: AdditionalFilterMultiRadioBtnType = {
  _id: "emailType",
  field: ["email"],
  translatedTitle: "Email Type",
  option: [
    { _id: "1", translatedTitle: "Received", value: "Received" },
    { _id: "2", translatedTitle: "Sent", value: "Sent" },
    { _id: "3", translatedTitle: "Draft", value: "Draft" },
    { _id: "4", translatedTitle: "Archived", value: "Archived" },
    { _id: "5", translatedTitle: "Spam/Junk", value: "Spam/Junk" },
    { _id: "6", translatedTitle: "Deleted", value: "Deleted" },
  ],
};
type ComponentDataType = string[];

interface EmailTypeProps {
  stateData: ComponentDataType; // Current data passed from the parent
  setStateData: (data: ComponentDataType) => void; // Function to update data in the parent
}

const EmailType: React.FC<EmailTypeProps> = ({ setStateData, stateData }) => {
  const handleSelectionChange = (value: string) => {
    const updatedMeetingTypes = stateData.includes(value)
      ? stateData.filter((item) => item !== value) // Remove if already selected
      : [...stateData, value]; // Add if not selected

    // Update the state in the parent
    setStateData(updatedMeetingTypes);
  };
  return (
    <TitleContainer
      title={EmailTypeList.translatedTitle}
      isCollapasable
      childrenSx={{ flexDirection: "row", flexWrap: "wrap" }}
    >
      {EmailTypeList.option.map((item) => (
        <MultiSelectRadioBtn
          sx={{ width: "45%" }}
          key={item._id}
          isChecked={stateData.includes(item.value)}
          label={item.translatedTitle}
          onClick={() => handleSelectionChange(item.value)}
        />
      ))}
    </TitleContainer>
  );
};

export default EmailType;
