import { Stack, Typography } from "@mui/material";
import React from "react";

interface BadgeDropdownTitleContainerProps {
  title: string;
  children: React.ReactNode;
}

const BadgeDropdownTitleContainer: React.FC<
  BadgeDropdownTitleContainerProps
> = ({ children, title }) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        gap: "10px",
        alignItems: "start",
        borderBottom: "0.5px solid #E9EDF2",
      }}
    >
      <Typography
        sx={{
          font: "normal normal 300 14px/18px Source Serif Pro",
          whiteSpace: "nowrap",
          flexShrink: 0,
          paddingTop: "10px",
        }}
      >
        {title}
      </Typography>
      {children}
    </Stack>
  );
};

export default BadgeDropdownTitleContainer;
