import { Stack, Typography } from "@mui/material";
import React from "react";
import { UserInfoChip } from "../../../../../Email/components";
import { timeCardTableRowsWidth } from "../../TimeCardTableType";

interface ApprovedBViewProps {
  labelText: "Time off: Approved by" | "Scheduled by:";
}

const ApprovedBView: React.FC<ApprovedBViewProps> = ({ labelText }) => {
  return (
    <Stack sx={{ gap: "5px" }}>
      <Typography sx={{ font: "normal normal 900 13px/16px Source Serif Pro" }}>
        {labelText}
      </Typography>

      <UserInfoChip
        chippMaxWidth={`${
          timeCardTableRowsWidth.ShiftWidth +
          timeCardTableRowsWidth.BreakWidth -
          22
        }px`}
        chipHeight="30px"
        popoverPlace="top"
        userEmail="Email@mail.com"
        userName="Jay Jenners this name may be long here"
        closeButtonClick={() => null}
      />
    </Stack>
  );
};

export default ApprovedBView;
