import React from "react";
import { SearchMembers } from "../SearchMembers";
import { ProjectSearchedCard } from "../ProjectSearchedCard";
import { DummyImage, ProjectIcon } from "../../../images";

interface SearchBarProjectsProps {}

const SearchBarProjects: React.FC<SearchBarProjectsProps> = ({}) => {
  return (
    <SearchMembers isLoading>
      <ProjectSearchedCard iconType="emoji" icon={"😄"} />
      <ProjectSearchedCard iconType="img" icon={DummyImage} />
      <ProjectSearchedCard iconType="svg" icon={ProjectIcon} />
      <ProjectSearchedCard iconType="emoji" icon={"😄"} />
      <ProjectSearchedCard iconType="img" icon={DummyImage} />
      <ProjectSearchedCard iconType="svg" icon={ProjectIcon} />
      <ProjectSearchedCard iconType="emoji" icon={"😄"} />
      <ProjectSearchedCard iconType="img" icon={DummyImage} />
      <ProjectSearchedCard iconType="svg" icon={ProjectIcon} />
    </SearchMembers>
  );
};

export default SearchBarProjects;
