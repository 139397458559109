import { InputBase, Stack, Typography } from "@mui/material";
import React from "react";
import { ConnectedIcon } from "../../../../../../../images";
import { adjustHexColor, getUser } from "../../../../../../../Utils";

interface SlugViewProps {
  updateField?: any;
}

const SlugView: React.FC<SlugViewProps> = ({ updateField }) => {

  const user = getUser();

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
        font: "normal normal 300 12px/15px Source Serif Pro",
      }}
    >
      <ConnectedIcon w={20} />
      <Typography
        sx={{
          font: "inherit",
          color: adjustHexColor("#000", 50),
          maxWidth: "210px",
          overflow: "scroll",
          whiteSpace: "nowrap",
          scrollbarWidth: "none",
        }}
      >
        {`os.bearishos.com/${user?.data?.firstName + "" + user?.data?.lastName}/`}
      </Typography>

      <InputBase
        placeholder="slug"
        sx={{
          height: "30px",
          width: "110px",
          padding: "0px 5px",
          borderRadius: "5px",
          border: "1px solid #E9EDF2",
          font: "inherit",
          color: adjustHexColor("#000", 100),
          "& input": {
            "&::placeholder": {
              opacity: 0.5,
            },
          },
        }}
        defaultValue={
        ''
        }
        onChange={(e: any) =>
          updateField && updateField("bookingLinkSlug", e.target.value)
        }
      />
    </Stack>
  );
};

export default SlugView;
