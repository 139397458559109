// In your `api.ts` or a similar file

import { createApiAction } from "../constantApi_config";
import {
    /* Add to Contact actions */
    addContact__RequestAction,
    addContact__FailureAction,
    addContact__SuccessAction,

    /* Get One Contact actions */
    getOneContact__RequestAction,
    getOneContact__FailureAction,
    getOneContact__SuccessAction,

    /* Update One Contact actions */
    updateOneContact__RequestAction,
    updateOneContact__FailureAction,
    updateOneContact__SuccessAction,

    /* Delete One Contact actions */
    deleteOneContact__RequestAction,
    deleteOneContact__FailureAction,
    deleteOneContact__SuccessAction,

    /* Add Business actions */
    addBusiness__RequestAction,
    addBusiness__FailureAction,
    addBusiness__SuccessAction,

    /* Get One Business actions */
    getOneBusiness__RequestAction,
    getOneBusiness__FailureAction,
    getOneBusiness__SuccessAction,

    /* Update One Business actions */
    updateOneBusiness__RequestAction,
    updateOneBusiness__FailureAction,
    updateOneBusiness__SuccessAction,

    /* Delete One Business actions */
    deleteOneBusiness__RequestAction,
    deleteOneBusiness__FailureAction,
    deleteOneBusiness__SuccessAction,

    /* Update Custom Elements actions */
    updateCustomElements__RequestAction,
    updateCustomElements__FailureAction,
    updateCustomElements__SuccessAction,

    /* Get All Contacts actions */
    getAllContacts__RequestAction,
    getAllContacts__FailureAction,
    getAllContacts__SuccessAction,

    /* Get All Business actions */
    getAllBusiness__RequestAction,
    getAllBusiness__FailureAction,
    getAllBusiness__SuccessAction,

    /* Create Group actions */
    createGroup__RequestAction,
    createGroup__FailureAction,
    createGroup__SuccessAction,

    /* Get All Groups actions */
    getAllGroups__RequestAction,
    getAllGroups__FailureAction,
    getAllGroups__SuccessAction,

    /* Get Group By ID actions */
    getGroupById__RequestAction,
    getGroupById__FailureAction,
    getGroupById__SuccessAction,

    /* Update Group actions */
    updateGroup__RequestAction,
    updateGroup__FailureAction,
    updateGroup__SuccessAction,

    /* Delete Group actions */
    deleteGroup__RequestAction,
    deleteGroup__FailureAction,
    deleteGroup__SuccessAction,

    /* Add to Group actions */
    addToGroup__RequestAction,
    addToGroup__FailureAction,
    addToGroup__SuccessAction,

    /* Create Deal actions */
    createDeal__RequestAction,
    createDeal__FailureAction,
    createDeal__SuccessAction,

    /* Get All Deals actions */
    getAllDeals__RequestAction,
    getAllDeals__FailureAction,
    getAllDeals__SuccessAction,

    /* Get Deal By ID actions */
    getDealById__RequestAction,
    getDealById__FailureAction,
    getDealById__SuccessAction,

    /* Update Deal actions */
    updateDeal__RequestAction,
    updateDeal__FailureAction,
    updateDeal__SuccessAction,

    /* Delete Deal actions */
    deleteDeal__RequestAction,
    deleteDeal__FailureAction,
    deleteDeal__SuccessAction,

    /* Add Note to Deal actions */
    addNoteTo__RequestAction,
    addNoteTo__FailureAction,
    addNoteTo__SuccessAction,

    /* Get All Outlook Contacts actions */
    getAllOutlookContacts__RequestAction,
    getAllOutlookContacts__FailureAction,
    getAllOutlookContacts__SuccessAction,

    /* Get Outlook Contact By ID actions */
    getOutlookContactById__RequestAction,
    getOutlookContactById__FailureAction,
    getOutlookContactById__SuccessAction,

    /* Create Outlook Contact actions */
    createOutlookContact__RequestAction,
    createOutlookContact__FailureAction,
    createOutlookContact__SuccessAction,

    /* Update Outlook Contact actions */
    updateOutlookContact__RequestAction,
    updateOutlookContact__FailureAction,
    updateOutlookContact__SuccessAction,
    getNoteTo__RequestAction,
    getNoteTo__SuccessAction,
    getNoteTo__FailureAction,
} from "./CRMCenterSlice";

/* Add Contact */
export const addToContact__api = (payload: {
    businessId?: string;
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;
}) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/crm/add-contacts`,
        onStart: addContact__RequestAction.type,
        onSuccess: addContact__SuccessAction.type,
        onError: addContact__FailureAction.type,
    });

/* Get One Contact */
export const getOneContact__api = (contactId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/crm/contacts/${contactId}`,
        onStart: getOneContact__RequestAction.type,
        onSuccess: getOneContact__SuccessAction.type,
        onError: getOneContact__FailureAction.type,
    });

/* Update One Contact */
export const updateOneContact__api = (
    contactId: string,
    payload: {
        firstName: string;
        lastName: string;
        company: string;
    }
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        data: payload,
        url: `/crm/contacts/${contactId}`,
        onStart: updateOneContact__RequestAction.type,
        onSuccess: updateOneContact__SuccessAction.type,
        onError: updateOneContact__FailureAction.type,
    });

/* Delete One Contact */
export const deleteOneContact__api = (contactId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/crm/contacts/${contactId}`,
        onStart: deleteOneContact__RequestAction.type,
        onSuccess: deleteOneContact__SuccessAction.type,
        onError: deleteOneContact__FailureAction.type,
    });

/* Add Business */
export const addBusiness__api = (payload: {
    businessName: string;
    businessLogo?: string;
    description?: string;
    domain: string;
    categories?: { name?: string; color?: string }[];
    primaryLocation?: string;
    socialLinks?: { [key: string]: string };
    employeeCount?: number;
    estimatedARR?: number;
    fundingAmount?: number;
    foundingDate?: string;
}) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/crm/add-business`,
        onStart: addBusiness__RequestAction.type,
        onSuccess: addBusiness__SuccessAction.type,
        onError: addBusiness__FailureAction.type,
    });

/* Get One Business */
export const getOneBusiness__api = (businessId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/crm/business/${businessId}`,
        onStart: getOneBusiness__RequestAction.type,
        onSuccess: getOneBusiness__SuccessAction.type,
        onError: getOneBusiness__FailureAction.type,
    });

/* Update One Business */
export const updateOneBusiness__api = (
    businessId: string,
    payload: {
        businessName: string;
        businessLogo: string;
        description: string;
        domain: string;
        categories: { name: string; color: string }[];
        primaryLocation: string;
        socialLinks: { [key: string]: string };
        employeeCount: number;
        estimatedARR: number;
        fundingAmount: number;
        foundingDate: string;
    }
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        data: payload,
        url: `/crm/business/${businessId}`,
        onStart: updateOneBusiness__RequestAction.type,
        onSuccess: updateOneBusiness__SuccessAction.type,
        onError: updateOneBusiness__FailureAction.type,
    });

/* Delete One Business */
export const deleteOneBusiness__api = (businessId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/crm/business/${businessId}`,
        onStart: deleteOneBusiness__RequestAction.type,
        onSuccess: deleteOneBusiness__SuccessAction.type,
        onError: deleteOneBusiness__FailureAction.type,
    });

/* Update Custom Elements */
export const updateCustomElements__api = (
    businessId: string,
    payload: {
        customElements: { elementType: string; value: any; description: string }[];
    }
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        data: payload,
        url: `/crm/business/${businessId}/custom-elements`,
        onStart: updateCustomElements__RequestAction.type,
        onSuccess: updateCustomElements__SuccessAction.type,
        onError: updateCustomElements__FailureAction.type,
    });

/* Get All Contacts */
export const getAllContacts__api = (payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/crm/contacts?page=${payload?.page}&limit=${payload?.limit}`,
        onStart: getAllContacts__RequestAction.type,
        onSuccess: getAllContacts__SuccessAction.type,
        onError: getAllContacts__FailureAction.type,
    });

/* Get All Business */
export const getAllBusiness__api = (payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/crm/business?page=${payload?.page}&limit=${payload?.limit}`,
        onStart: getAllBusiness__RequestAction.type,
        onSuccess: getAllBusiness__SuccessAction.type,
        onError: getAllBusiness__FailureAction.type,
    });
/* Create Group */
export const createGroup__api = (payload: {
    groupName: string;
    groupIcon?: string;
    groupDetails?: string;
    people?: string[];
    companies?: string[];
    deals?: string[];
}) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/crm/groups`,
        onStart: createGroup__RequestAction.type,
        onSuccess: createGroup__SuccessAction.type,
        onError: createGroup__FailureAction.type,
    });

/* Get All Groups */
export const getAllGroups__api = (payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/crm/groups?page=${payload?.page}&limit=${payload?.limit}`,
        onStart: getAllGroups__RequestAction.type,
        onSuccess: getAllGroups__SuccessAction.type,
        onError: getAllGroups__FailureAction.type,
    });

/* Get Group By ID */
export const getGroupById__api = (groupId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/crm/groups/${groupId}`,
        onStart: getGroupById__RequestAction.type,
        onSuccess: getGroupById__SuccessAction.type,
        onError: getGroupById__FailureAction.type,
    });

/* Update Group */
export const updateGroup__api = (
    groupId: string,
    payload: {
        groupName?: string;
        groupIcon?: string;
        groupDetails?: string;
        people?: string[];
        companies?: string[];
        deals?: string[];
    }
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        data: payload,
        url: `/crm/groups/${groupId}`,
        onStart: updateGroup__RequestAction.type,
        onSuccess: updateGroup__SuccessAction.type,
        onError: updateGroup__FailureAction.type,
    });

/* Delete Group */
export const deleteGroup__api = (groupId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/crm/groups/${groupId}`,
        onStart: deleteGroup__RequestAction.type,
        onSuccess: deleteGroup__SuccessAction.type,
        onError: deleteGroup__FailureAction.type,
    });

/* Add to Group */
export const addToGroup__api = (
    groupId: string,
    payload: {
        contactId?: string;
        companyId?: string;
        dealId?: string;
    }
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/crm/groups/${groupId}/add`,
        onStart: addToGroup__RequestAction.type,
        onSuccess: addToGroup__SuccessAction.type,
        onError: addToGroup__FailureAction.type,
    });

/* Create Deal */
export const createDeal__api = (payload: {
    dealName: string;
    dealStage?: string;
    dealOwner: string;
    dealValue: number;
    // description?: string;
    company?: string;
    contactsInvolved?: string[];
}) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/crm/deals`,
        onStart: createDeal__RequestAction.type,
        onSuccess: createDeal__SuccessAction.type,
        onError: createDeal__FailureAction.type,
    });

/* Get All Deals */
export const getAllDeals__api = (payload: any) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/crm/deals?page=${payload?.page}&limit=${payload?.limit}`,
        onStart: getAllDeals__RequestAction.type,
        onSuccess: getAllDeals__SuccessAction.type,
        onError: getAllDeals__FailureAction.type,
    });

/* Get Deal By ID */
export const getDealById__api = (dealId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/crm/deals/${dealId}`,
        onStart: getDealById__RequestAction.type,
        onSuccess: getDealById__SuccessAction.type,
        onError: getDealById__FailureAction.type,
    });

/* Update Deal */
export const updateDeal__api = (
    dealId: string,
    payload: {
        dealName?: string;
        dealStage?: string;
        dealOwner?: string;
        dealValue?: number;
        description?: string;
        company?: string;
        contactsInvolved?: string[];
    }
) =>
    createApiAction({
        axiosRequired: true,
        method: "PUT",
        data: payload,
        url: `/crm/deals/${dealId}`,
        onStart: updateDeal__RequestAction.type,
        onSuccess: updateDeal__SuccessAction.type,
        onError: updateDeal__FailureAction.type,
    });

/* Delete Deal */
export const deleteDeal__api = (dealId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "DELETE",
        url: `/crm/deals/${dealId}`,
        onStart: deleteDeal__RequestAction.type,
        onSuccess: deleteDeal__SuccessAction.type,
        onError: deleteDeal__FailureAction.type,
    });

/* Add Note to Deal */
export const addNoteTo__api = (payload: {
    title: string;
    content: string;
    section: string;
    sectionId: string;
}) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/global/notes`,
        onStart: addNoteTo__RequestAction.type,
        onSuccess: addNoteTo__SuccessAction.type,
        onError: addNoteTo__FailureAction.type,
    });

export const getNoteTo__api = (section: string, sectionId: string) =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/global/notes/section/${section}?sectionId=${sectionId}`,
        onStart: getNoteTo__RequestAction.type,
        onSuccess: getNoteTo__SuccessAction.type,
        onError: getNoteTo__FailureAction.type,
    });

/* ------------ OutLook Contact Start -------------- */

/* Get All Outlook Contacts */
export const getAllOutlookContacts__api = (payload: { accessToken?: string }) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/outlook/contacts`,
        onStart: getAllOutlookContacts__RequestAction.type,
        onSuccess: getAllOutlookContacts__SuccessAction.type,
        onError: getAllOutlookContacts__FailureAction.type,
    });

/* Get Outlook Contact By ID */
export const getOutlookContactById__api = (
    contactId: string,
    payload: { accessToken?: string }
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: { contactId, accessToken: payload.accessToken },
        url: `/outlook/contacts/me`,
        onStart: getOutlookContactById__RequestAction.type,
        onSuccess: getOutlookContactById__SuccessAction.type,
        onError: getOutlookContactById__FailureAction.type,
    });

/* Create Outlook Contact */
export const createOutlookContact__api = (payload: {
    newContact: any;
    accessToken?: string;
}) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: payload,
        url: `/outlook/contacts/create`,
        onStart: createOutlookContact__RequestAction.type,
        onSuccess: createOutlookContact__SuccessAction.type,
        onError: createOutlookContact__FailureAction.type,
    });

/* Update Outlook Contact */
export const updateOutlookContact__api = (
    contactId: string,
    payload: { updatedContact: any; accessToken?: string }
) =>
    createApiAction({
        axiosRequired: true,
        method: "POST",
        data: {
            contactId,
            updatedContact: payload.updatedContact,
            accessToken: payload.accessToken,
        },
        url: `/outlook/contacts/update`,
        onStart: updateOutlookContact__RequestAction.type,
        onSuccess: updateOutlookContact__SuccessAction.type,
        onError: updateOutlookContact__FailureAction.type,
    });

/* ------------ OutLook Contact End -------------- */
