import React from "react";
import TitleContainer from "../TitleContainer/TitleContainer";
import { Box, Stack, Typography } from "@mui/material";
import { StyledRadioButtonBlack } from "../../../../../../common";
import {
  EmailIconPng,
  CalendarIconBearishOSIconPng,
  StreamBearishOSLogoIconPng,
  CRMIconPng,
  StreamIconBearishOSIconPng,
  HRIconPng,
  TaskCenterIconPng,
} from "../../../../../../../images";
import {
  filterValueType,
  SearchGroups,
  sourcesOptionsType,
} from "../../../CommandControlSearchContainer";

type InnerComponentType = {
  onButtonClick: (value: string) => void;
  isSelected: boolean;
  value: SearchGroups;
};
const FilterRadioButton: React.FC<InnerComponentType> = ({
  isSelected,
  onButtonClick,
  value,
}) => {
  const getData = (): { label: string; imgSrc: string } => {
    switch (value) {
      case "calendar":
        return { label: "Plan", imgSrc: CalendarIconBearishOSIconPng };
      case "crm":
        return { label: "CRM", imgSrc: CRMIconPng };
      case "email":
        return { label: "Email", imgSrc: EmailIconPng };
      case "message":
        return { label: "Stream", imgSrc: StreamIconBearishOSIconPng };
      case "trax":
        return { label: "Trax", imgSrc: HRIconPng };
      case "video":
        return { label: "Video", imgSrc: StreamBearishOSLogoIconPng };
      case "workspace":
        return { label: "Workspace", imgSrc: TaskCenterIconPng };
    }
  };
  return (
    <Stack
      onClick={() => onButtonClick(value)}
      sx={{
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
        width: "fit-content",
        cursor: "pointer",
      }}
    >
      <StyledRadioButtonBlack radioSize={18} checked={!!isSelected} />
      <Box
        sx={{ height: "20px", width: "20px" }}
        component={"img"}
        src={getData().imgSrc}
        alt="logo"
      />
      <Typography sx={{ font: "normal normal 300 14px/18px Source Serif Pro" }}>
        {getData().label}
      </Typography>
    </Stack>
  );
};

interface CentersFilterViewProps {
  primaryValue: filterValueType;
  setPrimaryValue: React.Dispatch<React.SetStateAction<filterValueType>>;
  sourcesoptionsList: sourcesOptionsType[];
}
const CentersFilterView: React.FC<CentersFilterViewProps> = ({
  primaryValue,
  setPrimaryValue,
  sourcesoptionsList,
}) => {
  // in FilterRadioButton ( inner component ) translated label is used
  const centerList: SearchGroups[] = [
    "video",
    "email",
    "calendar",
    "message",
    "crm",
    "trax",
    "workspace",
  ];

  // const handleCenterToggle = (value: SearchGroups) => {
  //   setPrimaryValue((prev) => {
  //     const isSelected = prev.centersValue.includes(value);

  //     // Helper: Get sources related to a specific center value
  //     const getRelatedSources = (center: SearchGroups) => {
  //       return sourcesoptionsList.filter((item) => item.group.includes(center));
  //     };

  //     // 1. Handle addition of a value
  //     if (!isSelected) {
  //       const relatedSources = getRelatedSources(value);

  //       const newSources = [
  //         ...prev.sources,
  //         ...relatedSources.filter(
  //           (source) =>
  //             !prev.sources.some(
  //               (existingSource) => existingSource._id === source._id
  //             )
  //         ),
  //       ];

  //       return {
  //         ...prev,
  //         centersValue: [...prev.centersValue, value],
  //         sources: newSources,
  //       };
  //     }

  //     // 2. Handle removal of a value
  //     const updatedCenters = prev.centersValue.filter(
  //       (center) => center !== value
  //     );

  //     if (updatedCenters.length === 0) {
  //       // Remove all related sources if no centers are left
  //       const relatedSources = getRelatedSources(value);
  //       const newSources = prev.sources.filter(
  //         (source) =>
  //           !relatedSources.some(
  //             (relatedSource) => relatedSource._id === source._id
  //           )
  //       );

  //       return {
  //         ...prev,
  //         centersValue: updatedCenters,
  //         sources: newSources,
  //       };
  //     }

  //     // Filter out sources not matching the remaining centers
  //     const remainingSources = updatedCenters.flatMap((center) =>
  //       getRelatedSources(center)
  //     );
  //     const filteredSources = prev.sources.filter((source) =>
  //       remainingSources.some(
  //         (remainingSource) => remainingSource._id === source._id
  //       )
  //     );

  //     return {
  //       ...prev,
  //       centersValue: updatedCenters,
  //       sources: filteredSources,
  //     };
  //   });
  // };

  const handleCenterToggle = (value: SearchGroups) => {
    setPrimaryValue((prev) => {
      const isSelected = prev.centersValue.includes(value);

      // Helper: Get sources related to a specific center value
      const getRelatedSources = (center: SearchGroups) => {
        return sourcesoptionsList.filter((item) => item.group.includes(center));
      };

      // 1. Handle addition of a value
      if (!isSelected) {
        const relatedSources = getRelatedSources(value);

        const newSources = [
          ...prev.sources,
          ...relatedSources.filter(
            (source) =>
              !prev.sources.some(
                (existingSource) => existingSource._id === source._id
              )
          ),
        ];

        return {
          ...prev,
          centersValue: [...prev.centersValue, value],
          conditionalCentersValue: [...prev.centersValue, value], // Sync here
          sources: newSources,
        };
      }

      // 2. Handle removal of a value
      const updatedCenters = prev.centersValue.filter(
        (center) => center !== value
      );

      if (updatedCenters.length === 0) {
        // Remove all related sources if no centers are left
        const relatedSources = getRelatedSources(value);
        const newSources = prev.sources.filter(
          (source) =>
            !relatedSources.some(
              (relatedSource) => relatedSource._id === source._id
            )
        );

        return {
          ...prev,
          centersValue: updatedCenters,
          conditionalCentersValue: updatedCenters, // Sync here
          sources: newSources,
        };
      }

      // Filter out sources not matching the remaining centers
      const remainingSources = updatedCenters.flatMap((center) =>
        getRelatedSources(center)
      );
      const filteredSources = prev.sources.filter((source) =>
        remainingSources.some(
          (remainingSource) => remainingSource._id === source._id
        )
      );

      return {
        ...prev,
        centersValue: updatedCenters,
        conditionalCentersValue: updatedCenters, // Sync here
        sources: filteredSources,
      };
    });
  };

  return (
    <TitleContainer
      title="Centers"
      childrenSx={{ flexDirection: "row", flexWrap: "wrap", gap: "10px" }}
    >
      {centerList.map((item, index) => (
        <FilterRadioButton
          key={index}
          isSelected={!!primaryValue.centersValue?.some((i) => i === item)}
          onButtonClick={() => handleCenterToggle(item)}
          value={item}
        />
      ))}
    </TitleContainer>
  );
};

export default CentersFilterView;
