import React from "react";
import TitleContainer from "../TitleContainer/TitleContainer";
import { Stack, Typography } from "@mui/material";
import {
  DatePickerMini,
  DatePickerWithIcon,
  StyledRadioButtonBlack,
} from "../../../../../../common";

interface DateFilterViewProps {}

const DateFilterView: React.FC<DateFilterViewProps> = ({}) => {
  return (
    <TitleContainer title="Date Range and Time" childrenSx={{ gap: "10px" }}>
      {/* ------------------------------------------ */}
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
        <StyledRadioButtonBlack radioSize={18} />
        <Typography
          sx={{ font: "normal normal 300 14px/18px Source Serif Pro" }}
        >
          From:
        </Typography>
        <DatePickerWithIcon
          hideIcon
          sx={{
            font: "normal normal 900 15px/19px Source Serif Pro",
            color: "black",
          }}
        />
        <Typography
          sx={{
            font: "normal normal 300 14px/18px Source Serif Pro",
            mx: "5px",
          }}
        >
          to
        </Typography>
        <DatePickerWithIcon
          hideIcon
          sx={{
            font: "normal normal 900 15px/19px Source Serif Pro",
            color: "black",
          }}
        />
      </Stack>
      {/* ---------------------------------------------- */}
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
        <StyledRadioButtonBlack radioSize={18} />
        <Typography
          sx={{ font: "normal normal 300 14px/18px Source Serif Pro" }}
        >
          On:
        </Typography>
        <DatePickerWithIcon
          hideIcon
          sx={{
            font: "normal normal 900 15px/19px Source Serif Pro",
            color: "black",
          }}
        />
      </Stack>
      {/* ---------------------------------------------- */}
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
        <StyledRadioButtonBlack radioSize={18} />
        <Typography
          sx={{ font: "normal normal 300 14px/18px Source Serif Pro" }}
        >
          At:
        </Typography>
        <DatePickerMini
          hideIcon
          sx={{
            border: "0px",
            font: "normal normal 900 15px/19px Source Serif Pro",
            color: "black",
            width: "fit-content",
          }}
          additionalWord="PST"
          onFormatedTimeSelect={(time) => console.log(time)}
        />
      </Stack>
    </TitleContainer>
  );
};

export default DateFilterView;
