import React from "react";
import {
  Menu,
  MenuProps,
  styled,
  IconButton,
  MenuItem,
  Typography,
  Divider,
  SxProps,
  Tooltip,
} from "@mui/material";
import styles from "./SmallThreeDotMenu.module.css";
import {
  AnalyticsIcon,
  CalendarIcon,
  ChecklistIcon,
  DeleteIcon,
  GanttIcon,
  ListsIcon,
  RenameIcon,
  ShareIcon,
  ThreeDotIconIcon,
  TreeIcon,
  UpdateIcon,
} from "../../../images";
import { SmallThreeDotMenuListInterface } from "./SmallThreeDotMenuTypes";
import { useTranslation } from "react-i18next";

interface SmallThreeIconButtonProps {
  w?: number;
  sx?: SxProps;
  menuItems?: SmallThreeDotMenuListInterface[];
  onClickAction?: (someArg: string) => void;
  displayOption?: "left" | "right";
  headerIcon?: React.ElementType;
  menuWidth?: string;
  hoverText?: string;
}

const StyledMenu = styled((props: MenuProps) => {
  const horizontal =
    props.anchorEl instanceof HTMLElement &&
    props.anchorEl.getAttribute("data-display-option") === "right"
      ? "right"
      : "center";

  const transformHorizontal =
    props.anchorEl instanceof HTMLElement &&
    props.anchorEl.getAttribute("data-display-option") === "right"
      ? "left"
      : "right";

  return (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: transformHorizontal,
      }}
      {...props}
    />
  );
})(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 5,
    minWidth: 125,
    boxSizing: "border-box",
    boxShadow: "0px 3px 6px #00000029",
    "& .MuiMenu-list": {
      padding: "5px 0px",
    },
  },
}));

const MENU_LISTS: SmallThreeDotMenuListInterface[] = [
  {
    id: 1,
    icon: UpdateIcon,
    text: "Update Details",
    LANG: "VIDEO_CENTER.MEETING.UPDATE_DETAILS",
  },
  {
    id: 2,
    icon: AnalyticsIcon,
    text: "Analytics",
    LANG: "VIDEO_CENTER.MEETING.ANALYTICS",
  },
  {
    id: 3,
    icon: RenameIcon,
    text: "Rename",
    LANG: "VIDEO_CENTER.MEETING.RENAME",
  },
  {
    id: 4,
    icon: ShareIcon,
    text: "Share",
    LANG: "VIDEO_CENTER.MEETING.SHARE",
  },
  {
    id: 5,
    icon: DeleteIcon,
    text: "Delete",
    color: "red",
    LANG: "VIDEO_CENTER.MEETING.DELETE",
  },
];

const MENU_LISTS_2: SmallThreeDotMenuListInterface[] = [
  {
    id: 6,
    icon: ListsIcon,
    text: "List",
  },
  {
    id: 7,
    icon: TreeIcon,
    text: "Tree",
  },
  {
    id: 8,
    icon: CalendarIcon,
    text: "Calendar",
  },
  {
    id: 9,
    icon: GanttIcon,
    text: "Gantt",
  },
  {
    id: 10,
    icon: ChecklistIcon,
    text: "Checklist",
  },
];

const SmallThreeDotMenu: React.FC<SmallThreeIconButtonProps> = ({
  w = 16,
  sx,
  menuItems = MENU_LISTS,
  onClickAction,
  displayOption = "left",
  headerIcon = ThreeDotIconIcon,
  menuWidth = "125px",
  hoverText,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const target = event.currentTarget;
    target.setAttribute("data-display-option", displayOption);
    setAnchorEl(target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={hoverText} placement="top" arrow disableInteractive>
        <IconButton
          {...props}
          onClick={handleClick}
          sx={{ ...sx, p: "2px" }}
          disableRipple
          disableFocusRipple
        >
          {React.createElement(headerIcon, { w: w })}
        </IconButton>
      </Tooltip>
      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{ style: { minWidth: menuWidth, width: "fit-content" } }}
      >
        {menuItems.map((item) => {
          return item.divider ? (
            <Divider
              key={item.id} // Add key to Divider
              sx={{
                marginTop: "1px !important",
                marginBottom: "1px !important",
                marginX: "10px",
              }}
            />
          ) : (
            <MenuItem
              key={item.id}
              className={styles.smallThreeDotMenuItem}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onClickAction && onClickAction(item.text);
                handleClose();
              }}
            >
              {item.icon && (
                <item.icon
                  w={15}
                  color={item.color || "black"}
                  direction={item.direction}
                />
              )}
              <Typography
                className={styles.smallThreeDotMenuItemText}
                sx={{ color: item.textColor || "black" }}
              >
                {item?.LANG ? t(item.LANG) : item.text}
              </Typography>
            </MenuItem>
          );
        })}
      </StyledMenu>
    </>
  );
};

export default SmallThreeDotMenu;
