import { timeListTableRowsWidth } from "../../../TimeCardTable";

/**
 * Calculates the time difference between two hh:mm:ss formatted strings.
 * @param start - Start time in hh:mm:ss format.
 * @param end - End time in hh:mm:ss format.
 * @returns The difference as a string in hh:mm:ss format.
 */
export function calculateClockTimeDifference({ end, start }: { start: string, end: string }): string {
    const [startHours, startMinutes, startSeconds] = start.split(":").map(Number);
    const [endHours, endMinutes, endSeconds] = end.split(":").map(Number);

    // Convert start and end times to seconds
    const startTotalSeconds = startHours * 3600 + startMinutes * 60 + startSeconds;
    const endTotalSeconds = endHours * 3600 + endMinutes * 60 + endSeconds;

    // Calculate the difference in seconds
    let differenceInSeconds = endTotalSeconds - startTotalSeconds;

    // Handle negative differences (if start > end, assume next day)
    if (differenceInSeconds < 0) {
        differenceInSeconds += 24 * 3600; // Add 24 hours in seconds
    }

    // Convert the difference back to hh:mm:ss
    const diffHours = Math.floor(differenceInSeconds / 3600);
    differenceInSeconds %= 3600;
    const diffMinutes = Math.floor(differenceInSeconds / 60);
    const diffSeconds = differenceInSeconds % 60;

    // Format as hh:mm:ss
    const formatTime = (unit: number) => unit.toString().padStart(2, "0");

    return `${formatTime(diffHours)}:${formatTime(diffMinutes)}:${formatTime(diffSeconds)}`;
}



// ----------------------------------------------------------------------------------------------------------------


/**
  * Calculates the length of any card based on seconds.
  * @param start - The start time in "HH:mm:ss" format.
  * @param end - The end time in "HH:mm:ss" format.
  * @returns card length in px.
  */
export function calculateCardStartPoint(start: string): string {
    const time = calculateTimeDifferenceAsSecond("00:00:00", start);
    const length = (time / 3600) * timeListTableRowsWidth.timeAreaWidth;
    return `${length}px`;
}


// ----------------------------------------------------------------------------------------------------------------


/**
 * Calculates the difference in seconds between two time strings.
 * @param start - The start time in "HH:mm:ss" format.
 * @param end - The end time in "HH:mm:ss" format.
 * @returns The difference in seconds.
*/
export function calculateTimeDifferenceAsSecond(start: string, end: string): number {
    const [startHours, startMinutes, startSeconds] = start
        .split(":")
        .map(Number);
    const [endHours, endMinutes, endSeconds] = end.split(":").map(Number);

    const startInSeconds = startHours * 3600 + startMinutes * 60 + startSeconds;
    const endInSeconds = endHours * 3600 + endMinutes * 60 + endSeconds;

    return endInSeconds - startInSeconds;
}






// ----------------------------------------------------------------------------------------------------------------


/**
 * Calculates the length of any card based on seconds.
 * @param start - The start time in "HH:mm:ss" format.
 * @param end - The end time in "HH:mm:ss" format.
 * @returns card length in px.
*/
export function calculateCardLength(start: string, end: string): string {
    const time = calculateTimeDifferenceAsSecond(start, end);
    const length = (time / 3600) * timeListTableRowsWidth.timeAreaWidth;
    return `${length}px`;
}





// ----------------------------------------------------------------------------------------------------------------







/**
 * Generate a list of work and break intervals from the provided data.
 * @param data - The input data object with work and break details.
 * @returns An array of intervals starting with a break and alternating between work and break.
 */
export const generateDataList = (data: {
    workStartTime: string;
    workStopTime: string;
    breakList: { _id: string; start: string; end: string }[];
}) => {
    const { workStartTime, workStopTime, breakList } = data;

    if (!breakList || breakList.length === 0) {
        return [];
    }

    const dataList: { type: "work" | "break"; startTime: string; endTime: string }[] =
        [];

    let currentWorkStart = workStartTime;

    const adjustTime = (time: string, seconds: number) => {
        const [hours, minutes, secs] = time.split(":").map(Number);
        const date = new Date(1970, 0, 1, hours, minutes, secs);
        date.setSeconds(date.getSeconds() + seconds);
        return date.toTimeString().substring(0, 8);
    };

    for (let i = 0; i < breakList.length; i++) {
        const breakItem = breakList[i];

        // Add the break interval
        dataList.push({
            type: "break",
            startTime: breakItem.start,
            endTime: breakItem.end,
        });

        // Calculate the work interval if there is a next break or workStopTime
        const nextWorkEnd =
            i < breakList.length - 1
                ? adjustTime(breakList[i + 1].start, -1)
                : workStopTime;

        if (
            new Date(`1970-01-01T${currentWorkStart}Z`) <
            new Date(`1970-01-01T${nextWorkEnd}Z`)
        ) {
            dataList.push({
                type: "work",
                startTime: adjustTime(breakItem.end, 1),
                endTime: nextWorkEnd,
            });
        }

        // Update the current work start time for the next iteration
        currentWorkStart = adjustTime(breakItem.end, 1);
    }

    return dataList;
};


// ----------------------------------------------------------------------------------------------------------------


/**
 * Converts a time string in hh:mm:ss format into a readable string.
 * @param time - The time string in hh:mm:ss format.
 * @returns A string representing hours and minutes (e.g., "7hrs 30min").
*/
export const formatStringTime = (time: string): string => {
    const [hours, minutes] = time.split(':').map(Number);
    return `${hours}hrs ${minutes}min`;
};


// ----------------------------------------------------------------------------------------------------------------


/**
 * Converts a time string in hh:mm:ss format to 12-hour format with AM/PM,
 * ensuring two digits for hours, minutes, and seconds.
 * @param time - The time string in hh:mm:ss format.
 * @returns A string in the format "hh:mm:ss AM/PM".
*/
export const to12HourFormat = (time: string): string => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const adjustedHours = (hours % 12 || 12).toString().padStart(2, '0'); // Convert 0 to 12 and ensure two digits
    return `${adjustedHours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${period}`;
};

// ----------------------------------------------------------------------------------------------------------------


/**
 * Calculates the total break time from a list of breaks.
 * @param breakList - Array of breaks with start and end times.
 * @returns A string representing the total break time in the format "XXhrs XXmin".
*/
export const calculateTotalBreakTime = (
    breakList: { _id: string; start: string; end: string }[]
): string => {
    let totalMinutes = 0;

    const timeToMinutes = (time: string): number => {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    };

    for (const { start, end } of breakList) {
        totalMinutes += timeToMinutes(end) - timeToMinutes(start);
    }

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours.toString().padStart(2, '0')}hrs ${minutes.toString().padStart(2, '0')}min`;
};



// ----------------------------------------------------------------------------------------------------------------


/**
 * Calculates the difference between two times and formats it as "XXhrs XXmin".
 * @param start - The start time in "HH:mm:ss" format.
 * @param end - The end time in "HH:mm:ss" format.
 * @returns A string representing the time difference in "XXhrs XXmin" format.
 */
export const calculateTimeDifference = (start: string, end: string): string => {
    const timeToMinutes = (time: string): number => {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    };

    const startMinutes = timeToMinutes(start);
    const endMinutes = timeToMinutes(end);

    // Calculate the difference in minutes
    const totalMinutes = endMinutes - startMinutes;

    if (totalMinutes < 0) {
        throw new Error("End time must be greater than or equal to start time.");
    }

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours.toString().padStart(2, '0')}hrs ${minutes.toString().padStart(2, '0')}min`;
};



// ----------------------------------------------------------------------------------------------------------------




/**
 * Calculates working hours by subtracting totalBreak and overtime from the duration between checkInTime and checkOutTime.
 * @param checkInTime - The check-in time in "HH:mm:ss" format.
 * @param checkOutTime - The check-out time in "HH:mm:ss" format.
 * @param totalBreak - Total break time in "XXhrs XXmin" format.
 * @param overtime - Overtime in "XXhrs XXmin" format.
 * @returns A string representing the net working hours in "XXhrs XXmin" format.
 */
export const calculateWorkingHours = (
    checkInTime: string,
    checkOutTime: string,
    totalBreak: string,
    overtime: string
): string => {
    const timeToMinutes = (time: string): number => {
        const [hours, minutes] = time.split(/[hrs|min]+/).map(Number).filter(Boolean);
        return hours * 60 + minutes;
    };

    const timeToMinutesFromHHMMSS = (time: string): number => {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    };

    // Convert all times to minutes
    const checkInMinutes = timeToMinutesFromHHMMSS(checkInTime);
    const checkOutMinutes = timeToMinutesFromHHMMSS(checkOutTime);
    const breakMinutes = timeToMinutes(totalBreak);
    const overtimeMinutes = timeToMinutes(overtime);

    // Calculate total working minutes
    const totalMinutes = checkOutMinutes - checkInMinutes - breakMinutes - overtimeMinutes;

    if (totalMinutes < 0) {
        throw new Error("Invalid input: Total working time cannot be negative.");
    }

    // Convert minutes back to hours and minutes
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours.toString().padStart(2, '0')}hrs ${minutes.toString().padStart(2, '0')}min`;
};

// // Example usage
// console.log(calculateWorkingHours("08:00:00", "17:00:00", "01hrs 30min", "02hrs 00min")); // Output: "05hrs 30min"
// console.log(calculateWorkingHours("09:00:00", "18:00:00", "01hrs 15min", "01hrs 00min")); // Output: "06hrs 45min"
