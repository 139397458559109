import React, { useState } from "react";
import {
  IconButton,
  ListItem,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { QuickActionIcon } from "../../../images";
import {
  QuickActionsMenuList,
  QuickActionsMenuListInterface,
} from "./QuickActionsMenuType";
import {useTranslation} from "react-i18next";

interface QuickActionsMenuProps {
  options?: QuickActionsMenuListInterface[];
  onSelect: (value: QuickActionsMenuListInterface["name"],event?:any) => void;
}

const QuickActionsMenu: React.FC<QuickActionsMenuProps> = ({
  onSelect,
  options = QuickActionsMenuList,
}) => {
    const {t}=useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "quick-actions-popover" : undefined;

  return (
    <Stack>
      <IconButton
        sx={{ padding: 0 }}
        disableRipple
        onClick={handleClick}
        aria-describedby={id}
      >
        <QuickActionIcon w={15} />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            padding: 0,
            marginTop: "5px",
            borderRadius: "5px",
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            borderRadius: "5px",
            // gap: "20px",
            // padding: "0px 20px",
            alignItems: "center",
          }}
        >
          {options.map((item, i) => (
            <ListItem
              button
              key={i}
              onClick={(event:any) => {
                onSelect(item.name,event); // Send the name to the onSelect handler
                handleClose(); // Close the popover
              }}
            >
              <Stack sx={{ gap: "3px", alignItems: "center" }}>
                {React.createElement(item.icon, { w: 20 })}
                <Typography
                  sx={{
                    fontWeight: 300,
                    fontSize: "11px",
                    lineHeight: "14px",
                    fontFamily: "Source Serif Pro",
                  }}
                >
                  {t(item.LANG)}
                </Typography>
              </Stack>
            </ListItem>
          ))}
        </Stack>
      </Popover>
    </Stack>
  );
};

export default QuickActionsMenu;
