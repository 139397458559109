import React from "react";
import {
  ThreeDotLargeMenuHeaderOptionsInterface,
  ThreeDotLargeMenuItemProps,
  ThreeDotMenuLarge,
} from "../../../../common";
import {
  AnalyticsIcon,
  ArchiveIcon,
  BearishOSIcon,
  CheckedCircleIcon,
  ClockIcon,
  DeleteIcon,
  DocumentIcon,
  EmailIcon,
  EyeIcon,
  ForwardIcon,
  ProjectIcon,
  ReplyIcon,
  RightArrowIcon,
  StrongestrelationshipIcon,
} from "../../../../../images";
import {
  Dialog,
  DialogContent,
  IconButton,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { ArchiveSuccess } from "../ArchiveSuccess";
import { MoveEmail } from "../MoveEmail";
import { AddEmailtoTask } from "../AddEmailtoTask";
import { SnoozeEmail } from "../SnoozeEmail";
import EmailApiAction from "../EmailActionApi/EmailActions";
import { Delete } from "../../../../common/PopupLarge/PopupLarge.stories";
import { ReplyAll } from "@mui/icons-material";
import useEmailApiAction from "../EmailActionApi/EmailActions";
import axios from "axios";
import { baseURL, getCurrentIp } from "../../../../../redux/middleware/apiMiddleware";

// interface EmailClickedMenuProps {
//   tooltipText?: string;
//   displayOption?: "left" | "right";
//   accessToken: string;
//   messageId: string;

// }

interface EmailClickedMenuWithAccessProps {
  accessToken: string | null;
  messageId: string | null;
  tooltipText?: string;
  displayOption?: "left" | "right";
}

interface EmailClickedMenuWithoutAccessProps {
  tooltipText: string;
  displayOption: "left" | "right";
  accessToken?: never;  // explicitly disallow accessToken and messageId
  messageId?: never;
}

// Use a union type to accept either of the two prop combinations
type EmailClickedMenuProps = EmailClickedMenuWithAccessProps | EmailClickedMenuWithoutAccessProps;


// interface IconsGroupViewProps {
//   email: Email;
//   accessToken: string;
//   messageId: string;
// }

const OptionsList: ThreeDotLargeMenuItemProps[] = [
  { text: "Reply all", icon: ReplyIcon },
  { text: "Archive", icon: ArchiveIcon },
  { text: "Mark as read", icon: EyeIcon },
  { text: "Move to", icon: RightArrowIcon },
  {
    text: "Delete",
    icon: DeleteIcon,
    iconProps: { color: "#FF4500" },
  },
  { divider: true },
  { text: "Mark as unread", icon: EmailIcon },
  { text: "Open contact card", icon: StrongestrelationshipIcon },
  { text: "View analytics", icon: AnalyticsIcon },
  { text: "Print PDF", icon: DocumentIcon },
  { divider: true },
  { text: "Add to task", icon: CheckedCircleIcon },
  {
    text: "Connect to project",
    icon: ProjectIcon,
    iconProps: { color: "black", w: 15 },
  },
  {
    text: "Send in text",
    icon: BearishOSIcon,
    iconProps: { color: "black", w: 15 },
  },
];

export const ThreeDotLargeMenuHeaderOptions: ThreeDotLargeMenuHeaderOptionsInterface[] =
  [
    {
      textOrIcon: ReplyIcon,
      title: "Reply",
      titleStyle: { font: "normal normal 300 13px/16px Source Serif Pro" },
    },
    {
      textOrIcon: ClockIcon,
      title: "Snooze",
      titleStyle: { font: "normal normal 300 13px/16px Source Serif Pro" },
    },
    {
      textOrIcon: ForwardIcon,
      title: "Forward",
      titleStyle: { font: "normal normal 300 13px/16px Source Serif Pro" },
    },
  ];

const EmailClickedMenu: React.FC<EmailClickedMenuProps> = (props) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState<string | null>(null);
  

  const { EmailApiAction } = useEmailApiAction();


  const handleClick = (title: string) => {
    setSelectedOption(title);
    handleAction(title); // Call the action handler
  
    // Only open the modal for specific actions that require additional UI
    const modalActions = ["Snooze", "Move to", "Add to task", "Connect to project"];
    if (modalActions.includes(title)) {
      setOpenModal(true);
    }
  };
  

  const handleClose = () => {
    setOpenModal(false);
    setSelectedOption(null);
  };
  const handleAction = async (title: string) => {
    // Make sure accessToken and messageId are not null or undefined
    if (props.accessToken && props.messageId) {
      const { accessToken, messageId } = props;

      switch (title) {
        case "Reply all":
          console.log("Reply all clicked");

          break;
        case "Archive":
          // <EmailApiAction
          //   accessToken={accessToken} // Access token is guaranteed to be a string here
          //   messageId={messageId}     // Message ID is guaranteed to be a string here
          //   actionType={"archive"}
          // />;
          await EmailApiAction(accessToken, messageId, "archive"); 
          break;
        case "Mark as read":
          // <EmailApiAction
          //   accessToken={accessToken} // Access token is guaranteed to be a string here
          //   messageId={messageId}     // Message ID is guaranteed to be a string here
          //   actionType={"markRead"}
          // />;
          break;
        case "Move to":
          setOpenModal(true);
          break;
        case "Delete":
          // <EmailApiAction
          //   accessToken={accessToken} // Access token is guaranteed to be a string here
          //   messageId={messageId}     // Message ID is guaranteed to be a string here
          //   actionType={"archive"}
          // />;
          await EmailApiAction(accessToken, messageId, "delete");

          break;
        case "Mark as unread":
          await EmailApiAction(accessToken, messageId, "markRead");

          console.log("Mark as unread clicked");
          break;
        case "Open contact card":
          console.log("Open contact card clicked");
          break;
        case "View analytics":
          console.log("View analytics clicked");
          break;
        case "Print PDF":
          console.log("Print PDF clicked");
          break;
        case "Add to task":
          setOpenModal(true);
          break;
        case "Connect to project":
          setOpenModal(true);
          break;
        case "Send in text":
          console.log("Send in text clicked");
          break;
        case "Snooze":
          console.log("Snooze clicked");
          break;
        case "Reply":
          console.log("Reply clicked");
          break;
        case "Forward":
          console.log("Forward clicked");
          break;
        default:
          console.log("Unknown action");
      }
    } else {
      console.log("Access token or message ID is missing");
    }
  };

  const handleSnoozeEmail = async (value: string): Promise<void> => {
    const { messageId } = props;
    if (!messageId) {
      console.error("Message ID is missing");
      return;
    }
    try {
      const res = await axios.put(
        `${baseURL}/email/snooze-email`,
        { messageId, snoozeTime: value },
        {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Current-IP": await getCurrentIp(),
          }
        }
      );
      if (res?.status === 200) {
        setOpenModal(false);
      }
    } catch (err) {
      console.error(err);
    }
  };



  const renderModalContent = () => {
    if (props.accessToken && props.messageId) { 
      switch (selectedOption) {

        // case "Delete":
        //   return (
        //     <>
        //       <EmailApiAction
        //         accessToken={accessToken} // Access token is guaranteed to be a string here
        //         messageId={messageId}     // Message ID is guaranteed to be a string here
        //         actionType={"delete"}
        //       />   <ArchiveSuccess />

        //     </>
        //   )
        // case "Mark as read":
        //   return (
        //     <>
        //       <EmailApiAction
        //         accessToken={accessToken} // Access token is guaranteed to be a string here
        //         messageId={messageId}     // Message ID is guaranteed to be a string here
        //         actionType={"markRead"}
        //       />   <ArchiveSuccess />

        //     </>
        //   )

        // case "Archive":
        //   return (
        //     <>
        //       <EmailApiAction
        //         accessToken={accessToken} // Access token is guaranteed to be a string here
        //         messageId={messageId}     // Message ID is guaranteed to be a string here
        //         actionType={"archive"}
        //       />
        //       <ArchiveSuccess />
        //     </>
        //   )
        // return <ArchiveSuccess />;
        case "Snooze":
          return <SnoozeEmail handleSnoozeEmail={handleSnoozeEmail} variant={"SnoozeEmail"} />;
        case "Reply":
          return <SnoozeEmail variant={"SnoozeEmail"} />;
        case "Reply all":
        // return <SnoozeEmail variant={"SnoozeEmail"} />;
        case "Move to":
          return <MoveEmail />;
        case "Add to task":
          return <AddEmailtoTask />;
        case "Connect to project":
          return <AddEmailtoTask />;
        default:
          return null; // Fallback if no option matches
      }
    } else { 
      return null;
    }
  };



  return (
    <>
      <ThreeDotMenuLarge
        options={OptionsList.map((option) => ({
          ...option,
        }))}
        headersOptions={ThreeDotLargeMenuHeaderOptions}
        displayOption={props?.displayOption}
        tooltipText={props?.tooltipText}
        getClickedOption={(title: any) => handleClick(title)}
      />

      <Dialog open={openModal} onClose={handleClose}>
        <DialogContent>
          {renderModalContent()}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EmailClickedMenu;
