import React, { useState } from "react";
import TitleContainer from "../../TitleContainer/TitleContainer";
import { MultiSelectRadioBtn } from "../../../../../../../common";
import { AdditionalFilterMultiRadioBtnType } from "../../../../CommandControlSearchContainer";

const recordingStatusList: AdditionalFilterMultiRadioBtnType = {
  _id: "recordingStatus",
  field: ["video"],
  translatedTitle: "Recording Status",
  option: [
    { _id: "1", translatedTitle: "Recorded", value: "recorded" },
    { _id: "2", translatedTitle: "No Recording", value: "noRecording" },
  ],
};

type ComponentDataType = string[];

interface RecordingStatusProps {
  stateData: ComponentDataType; // Current data passed from the parent
  setStateData: (data: ComponentDataType) => void; // Function to update data in the parent
}

const RecordingStatus: React.FC<RecordingStatusProps> = ({
  setStateData,
  stateData,
}) => {
  const handleSelectionChange = (value: string) => {
    const updatedMeetingTypes = stateData.includes(value)
      ? stateData.filter((item) => item !== value) // Remove if already selected
      : [...stateData, value]; // Add if not selected

    // Update the state in the parent
    setStateData(updatedMeetingTypes);
  };
  return (
    <TitleContainer title={recordingStatusList.translatedTitle} isCollapasable>
      {recordingStatusList.option.map((item) => (
        <MultiSelectRadioBtn
          key={item._id}
          isChecked={stateData.includes(item.value)}
          label={item.translatedTitle}
          onClick={() => handleSelectionChange(item.value)}
        />
      ))}
    </TitleContainer>
  );
};

export default RecordingStatus;
