import React, { useState } from "react";
import { Popover } from "@mui/material";
import {
  ExpensesBadgesMenuOptions,
  ExpensesBadgesMenuType,
} from "./ExpensesBadgesMenuType";
import { MainListView } from "./view";
import { ExpensesBadges } from "../ExpensesBadges";

interface ExpensesBadgesMenuProps {
  initialData: ExpensesBadgesMenuType;
  onChange?: (newValue: ExpensesBadgesMenuType) => void;
}

const ExpensesBadgesMenu: React.FC<ExpensesBadgesMenuProps> = ({
  initialData,
  onChange,
}) => {
  const [expensesBadgedata, setExpensesBadgedata] =
    useState<ExpensesBadgesMenuType>(initialData);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    onChange && onChange(expensesBadgedata);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover-menu" : undefined;

  return (
    <div>
      <span onClick={handleClick} style={{ cursor: "pointer" }}>
        <ExpensesBadges badgeData={expensesBadgedata.badgeData} />
      </span>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            borderRadius: "5px",
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            padding: 0,
            marginTop: "15px",
            scrollbarWidth: "none",
          },
        }}
      >
        <MainListView
          options={ExpensesBadgesMenuOptions}
          initialData={expensesBadgedata}
          onChange={(newData) => setExpensesBadgedata(newData)}
        />
      </Popover>
    </div>
  );
};

export default ExpensesBadgesMenu;
