import React, { useState } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { PlayArrowIcon } from "../../../../../../../images";
import { TextLinkButton } from "../../../../../../common";

interface ViewMoreContainerProps {
  children: React.ReactNode;
  showViewMore: boolean;
  viewMoreClick: () => void;
}

const ViewMoreContainer: React.FC<ViewMoreContainerProps> = ({
  children,
  showViewMore,
  viewMoreClick,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <Stack sx={{ gap: "15px" }}>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{ font: "normal normal 400 14px/18px Source Serif Pro" }}
        >
          Sources
        </Typography>
        <IconButton
          sx={{ padding: "2px" }}
          onClick={() => setIsCollapsed((pre) => !pre)}
        >
          <PlayArrowIcon w={20} direction={isCollapsed ? "down" : "up"} />
        </IconButton>
      </Stack>
      <div
        style={{
          height: isCollapsed ? "0px" : "fit-content",
          overflow: "hidden",
          transition: "height 0.3s ease",
        }}
      >
        <Stack
          sx={{
            gap: "10px",
          }}
        >
          {children}
        </Stack>
      </div>
      {showViewMore && (
        <Stack sx={{ flexDirection: "row", justifyContent: "end" }}>
          <TextLinkButton
            onClick={viewMoreClick}
            buttonLabel="View More"
            sx={{
              color: "#2A30B5",
              font: "normal normal 300 12px/15px Source Serif Pro",
              ":hover": { textDecorationLine: "underline" },
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default ViewMoreContainer;
