import React from "react";
import { ColoredBadge } from "../../../../common";

export type ExpenseStatusType = "approved" | "rejected" | "pending";

interface TraxApprovedBadgeProps {
  variant: ExpenseStatusType;
}

const TraxApprovedBadge: React.FC<TraxApprovedBadgeProps> = ({ variant }) => {
  const getBadgeData = (): {
    label: string;
    bgColor: string;
    fontColor: string;
  } => {
    switch (variant) {
      case "approved":
        return { bgColor: "#008D4C", fontColor: "#fff", label: "Approved" };
      case "rejected":
        return { bgColor: "#FF0000", fontColor: "#fff", label: "Rejected" };
      case "pending":
        return { bgColor: "#FFA500", fontColor: "#fff", label: "Pending" };
    }
  };
  return (
    <ColoredBadge
      title={getBadgeData().label}
      sx={{ width: "100px", height: "25px" }}
      bgColor={getBadgeData().bgColor}
      bgColorOpacity={65}
      fontColor={getBadgeData().fontColor}
      ColoredBadgeFont={"normal normal 400 13px/16px Source Serif Pro"}
    />
  );
};

export default TraxApprovedBadge;
