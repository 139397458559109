import React from "react";
import { DealStageCurrencySymbols } from "../../../../common";
import { PayrollCardHeaderButton, PayrollCardMenu } from "../../common";
import { InvestingIcon } from "../../../../../images";

interface CreditsPayrollCardProps {}

const CreditsPayrollCard: React.FC<CreditsPayrollCardProps> = ({}) => {
  const currencySymbol = DealStageCurrencySymbols.USD;
  return (
    <div>
      {" "}
      <PayrollCardHeaderButton
        bgColor="#2A30B5"
        currencySymbol={currencySymbol}
        icon={InvestingIcon}
        label="Credits "
        value={15000.0}
      >
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Expenses Reimbursements "
          primaryValue={5000}
          labelBoxWidth="240px"
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Education Reimbursements"
          primaryValue={1000}
          labelBoxWidth="240px"
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Professional Memberships"
          primaryValue={2500}
          labelBoxWidth="240px"
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Equipment Reimbursements "
          primaryValue={500}
          labelBoxWidth="240px"
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Other Reimbursements"
          primaryValue={10000}
          labelBoxWidth="240px"
        />
      </PayrollCardHeaderButton>
    </div>
  );
};

export default CreditsPayrollCard;
