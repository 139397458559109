import React from "react";
import { InputBase, Stack } from "@mui/material";
import { EventTimeView, HeaderView } from "./view";
import {
  CheckboxFilledIcon,
  DeadlineIcon,
  MultiCircleIcon,
  TeamMemberIcon,
  XCircleIcon,
} from "../../../../../images";
import {
  DropdownWithImageBadge,
  DUMMY_USER_EMAIL_LIST,
  EventIconButton,
} from "../../../../common";

interface PendingEventCommandControlPopupProps {}

const PendingEventCommandControlPopup: React.FC<
  PendingEventCommandControlPopupProps
> = () => {
  return (
    <Stack sx={{ width: "100%" }}>
      <HeaderView titleText="event name" />

      <Stack
        sx={{ boxSizing: "border-box", padding: "20px 25px", gap: "25px" }}
      >
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
          <DeadlineIcon w={20} />
          <InputBase
            sx={{
              flexGrow: 1,
              height: "30px",
              font: "normal normal 400 18px/23px Source Serif Pro",
            }}
            placeholder="Event title"
            value={"Example Event name"}
            onChange={(e: any) => null}
          />
        </Stack>

        <EventTimeView />

        <Stack sx={{ flexDirection: "row", alignItems: "start", gap: "10px" }}>
          <span style={{ marginTop: "6px" }}>
            <TeamMemberIcon w={20} />
          </span>
          <DropdownWithImageBadge
            options={DUMMY_USER_EMAIL_LIST}
            initialData={DUMMY_USER_EMAIL_LIST.slice(3, 10)}
            onChangeSelection={() => null}
            showMaxSelected={4}
            readonlyComponent
            placeholder="Add guests"
          />
        </Stack>

        <Stack sx={{ flexDirection: "row", gap: "5px" }}>
          <EventIconButton
            buttonTitle="Accept"
            icon={CheckboxFilledIcon}
            iconColor="#21B481"
          />
          <EventIconButton
            buttonTitle="Reject"
            icon={XCircleIcon}
            iconColor="#FF0000"
          />
          <EventIconButton
            buttonTitle="Maybe"
            icon={MultiCircleIcon}
            iconColor="#FFB100"
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PendingEventCommandControlPopup;
