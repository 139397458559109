import { Stack, Typography } from "@mui/material";
import React from "react";
import { TextLinkButton } from "../../../../common";
import { BalancesIcon } from "../../../../../images";

const InlineBoxArea: React.FC<{ label: string; value: number }> = ({
  label,
  value,
}) => {
  return (
    <Stack
      sx={{
        flex: 1,
        flexShrink: 0,
        borderTop: "1px solid #E9EDF2",
        borderRight: "1px solid #E9EDF2",
      }}
    >
      <Stack
        sx={{
          height: "30px",
          flexShrink: 0,
          borderBottom: "1px solid #E9EDF2",
          boxSizing: "border-box",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
        >
          {label}
        </Typography>
      </Stack>

      <Stack
        sx={{
          height: "40px",
          flexShrink: 0,
          boxSizing: "border-box",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{ font: "normal normal 900 13px/16px Source Serif Pro" }}
        >
          {value.toString().padStart(2, "0")}
        </Typography>
      </Stack>
    </Stack>
  );
};

interface BalanceDetailsAreaProps {
  vacationDays: number;
  pto: number;
  sickDays: number;
  bereavement: number;
  maternity: number;
  other: number;
}

const BalanceDetailsArea: React.FC<BalanceDetailsAreaProps> = ({
  bereavement,
  maternity,
  other,
  pto,
  sickDays,
  vacationDays,
}) => {
  return (
    <Stack gap={"20px"}>
      {/* -- header -- */}
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
          <BalancesIcon w={20} color="black" />
          <Typography
            sx={{ font: "normal normal 400 13px/16px Source Serif Pro" }}
          >
            Balances
          </Typography>
        </Stack>
        <TextLinkButton
          buttonLabel="View all"
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
        />
      </Stack>

      {/* ------------------------------ */}

      <Stack
        sx={{
          borderLeft: "1px solid #E9EDF2",
          borderBottom: "1px solid #E9EDF2",
        }}
      >
        <Stack sx={{ flexDirection: "row" }}>
          <InlineBoxArea label="Vacation Days" value={vacationDays} />
          <InlineBoxArea label="PTO" value={pto} />
          <InlineBoxArea label="Sick days" value={sickDays} />
        </Stack>
        <Stack sx={{ flexDirection: "row" }}>
          <InlineBoxArea label="Bereavement" value={bereavement} />
          <InlineBoxArea label="Maternity" value={maternity} />
          <InlineBoxArea label="Other" value={other} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default BalanceDetailsArea;
