import { Stack, Typography } from "@mui/material";
import React, { useRef } from "react";
import { adjustHexColor } from "../../../../../Utils";
import {
  DateHeaderView,
  EventView,
  TaskView,
} from "../../../Calendar/components/SidebarCalendar/view";
import { SidebarCalendarDataListInterface } from "../../../Calendar/components";
import { CoverImgButton, DateSelectorArea } from "./view";
import { useDispatch } from "react-redux";
import { uploadCloudFile__api } from "../../../../../redux";
import { saveOrUpdateCoverImage__api } from "../../../../../redux/Slice/Global/globalActions";

interface DashboardSidebarCalendarProps {
  dataList?: SidebarCalendarDataListInterface[];
  textColor?: "white" | "black";
}

const DashboardSidebarCalendar: React.FC<DashboardSidebarCalendarProps> = ({
  dataList,
  textColor = "black",
}) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleSetCoverImage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      const formData = new FormData();
      formData.append("files", selectedFile);

      const response: any = await dispatch(uploadCloudFile__api(formData));

      // file link from the response
      const fileLink = response.data?.[0].path;

      if (fileLink) {
        const payload = {
          dashboardCoverImageUrl: fileLink,
        };

        await dispatch(saveOrUpdateCoverImage__api(payload));
      }
    }
  };
  const handleSelectCoverImage = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Stack sx={{ height: "100%" }}>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "end",
          paddingRight: "20px",
        }}
      >
        <CoverImgButton
          textColor={textColor}
          onClick={handleSelectCoverImage}
        />
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleSetCoverImage}
        />
      </Stack>
      <DateSelectorArea textColor={textColor} />
      <Stack
        sx={{
          width: "265px",
          flexShrink: 0,
          height: "calc(100% - 73px)",
          overflowY: "scroll",
          scrollbarWidth: "none",
          backgroundColor: adjustHexColor("#ffffff", 65),
          borderRadius: "5px",
          padding: "12px 12px 15px 15px",
          boxSizing: "border-box",
          gap: "3px",
          borderTop: "1px solid #E9EDF2",
          borderBottom: "1px solid #E9EDF2",
          borderLeft: "1px solid #E9EDF2",
          mt: "5px",
        }}
      >
        {Array?.isArray(dataList) && dataList?.length > 0 ? (
          dataList?.map((item: any) => (
            <Stack sx={{ gap: "3px" }} key={item?.id}>
              <DateHeaderView date={item?.dateAndTime} />
              <Stack sx={{ gap: "3px" }}>
                {Array?.isArray(item?.dataList) &&
                  item?.dataList?.map((value: any) =>
                    value?.type === "event" ? (
                      <EventView key={value?.data?.id} events={value?.data} />
                    ) : (
                      <TaskView key={value?.data?.id} tasks={value?.data} />
                    )
                  )}
              </Stack>
            </Stack>
          ))
        ) : (
          <Typography
            sx={{ font: "normal normal 400 15px/16px Source Serif Pro" }}
          >
            No data available
          </Typography> // Handle empty or invalid data
        )}
      </Stack>
    </Stack>
  );
};

export default DashboardSidebarCalendar;
