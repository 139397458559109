import {
  Drawer,
  Stack,
  IconButton,
  Popover,
  Skeleton,
  Typography, Box,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import CompanyIconAndName from "../../CompanyIconAndName";
import { MainActionButton } from "../../../Buttons";
import styles from "../../DrawerNavbar.module.css";
import { ButtonType, MainActionColor } from "../../../Buttons/AllButtonProps";
import { GearIcon } from "../../../../../images";
import {
  CalendarSettings,
  InCalendarEventPopup,
  SidebarCalenderContainer,
  SidebarCalenderInfoCard,
  SidebarMiniCalender,
} from "../../../../pages/Calendar/components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import {
  CalendarState,
  getAllCalendars__api,
  recallAPI,
  toggleCalendarIdVisibility,
} from "../../../../../redux";
import CommandControlSearchContainer
  from "../../../../pages/Dashboard/components/CommandControlSearchContainer/CommandControlSearchContainer";
import { useTranslation } from "react-i18next";

type PlanDrawerProps = {
  drawerOpen: boolean;
  drawerCloser: () => void;
};

const DrawerContainer = styled("div")({
  position: "fixed",
  top: 0,
  height: "100vh",
  zIndex: 1100,
});
const PlanDrawer: React.FC<PlanDrawerProps> = ({
  drawerOpen,
  drawerCloser,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loader, setLoader] = useState<boolean>(false);
  const [showSearchContainer, setShowSearchContainer] = useState(false);
  const calendarState = useSelector(
    (state: RootState) => state && (state?.calendar as CalendarState)
  );

  const { reCall, allCalendarList, isGetAllCalendarList, lastApiCallTime } =
    calendarState || {};
  const [showCalendarSetting, setShowCalendarSetting] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [allCalendarLists, setAllCalendarList] = useState([]);
  const getAllCalendar = async () => {
    try {
      setLoader(true);
      const action = getAllCalendars__api();
      await dispatch(action);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(recallAPI(null)); // Reset any recall state if applicable
      setLoader(false);
    }
  };

  useEffect(() => {
    const currentTime = Date.now();
    const timeDifference = lastApiCallTime
      ? currentTime - lastApiCallTime
      : Infinity; // Use Infinity for first call
    const shouldCallApi = (() => {
      // Check if calendar list is empty and no error object is present
      const noCalendars =
        Array?.isArray(allCalendarList) && allCalendarList?.length < 1;
      const errorPresent = reCall === "getAllCal" && timeDifference > 90000; // Error present and time since last call > 90 seconds
      const timeSinceLastCallExceeded = timeDifference > 90000 && !reCall; // Time since last call > 90 seconds and no error
      // Determine if we should call the API
      return (
        ((noCalendars && !reCall) || // No calendars and no error
          timeSinceLastCallExceeded || // Time since last call is sufficient and no error
          errorPresent) &&
        !isGetAllCalendarList
      );
    })();
    const timeout = setTimeout(() => {
      if (shouldCallApi || reCall === "All") {
        getAllCalendar(); // Call the API for calendars
      }
    }, 5000); // 3 seconds delay for debounce
    return () => clearTimeout(timeout);
    // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [allCalendarList, lastApiCallTime, isGetAllCalendarList, reCall]);

  useEffect(() => {
    if (allCalendarList && allCalendarList?.length > 0) {
      setAllCalendarList(allCalendarList);
    }
  }, [allCalendarList]);

  const handleToggleCalendarVisibility = (calendarId: string) => {
    dispatch(toggleCalendarIdVisibility(calendarId));
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
          (event.ctrlKey && event.key === " ") ||
          (event.metaKey && event.key === " ") ||
          (event.ctrlKey && event.key === "s") ||
          (event.metaKey && event.key === "s")
      ) {
        event.preventDefault();
        setShowSearchContainer((prev: any) => !prev);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      {showCalendarSetting && (
        <div
          style={{
            position: "absolute",
            zIndex: "9999",
            top: "7%",
            left: "290px",
            width: `88vw`,
            maxWidth: "calc(100vw - 300px)",
            overflowX: "auto",
            overflowY: "scroll",
            maxHeight: "calc(100vh - 45px)",
            height: "100vh",
          }}
        >
          <CalendarSettings setShowCalendarSetting={setShowCalendarSetting} />
        </div>
      )}
      <DrawerContainer>
        <Drawer
          sx={{
            position: "absolute",
            top: 0,
            height: "100vh",
            width: "215px",
            // left: '-240px', // Start hidden behind the Navbar
            transition: drawerOpen
              ? "left 225ms ease-out"
              : "left 195ms ease-in",
            left: drawerOpen ? "30px" : "-215px",
            zIndex: 1100, // Ensure the drawer is behind the navbar
            "& .MuiPaper-root": {
              position: "absolute",
              top: 0,
              height: "100vh",
              width: "215px",
              left: 0,
              boxSizing: "border-box",
              zIndex: 1100, // Ensure the paper is also behind the navbar
            },
          }}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
        >
          <Stack
            bgcolor={"white"}
            direction="column"
            flex={1}
            className={`${styles.drawerNavbar__drawer}`}
          >
            {/* Company logo and name here */}
            <CompanyIconAndName arrowButtonClickHandler={drawerCloser} />
            <Stack className={styles.drawerNavbar__content}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                {/* Buttons and other lists here */}
                <MainActionButton
                  sx={{ marginY: "25px" }}
                  onClick={(e: any) => handleClick(e)}
                  colorVarient={MainActionColor.CalendarMarronPink}
                  label={t("VIDEO_CENTER.CREATE")}
                  types={ButtonType.Button}
                />
                <Stack>
                  <IconButton
                    onClick={(event) =>
                      setShowCalendarSetting(!showCalendarSetting)
                    }
                  >
                    <GearIcon w={17} />
                  </IconButton>
                </Stack>
              </Stack>
              <SidebarMiniCalender />
              <SidebarCalenderContainer
                label="Calendars"
                getAllCalendar={getAllCalendar}
                cl={allCalendarLists?.length}
                viewAllButtonClick={() => {}}
                children={
                  <>
                    {loader ? (
                      // Show MUI Skeleton while loading
                      Array.from({ length: 4 }, (_, index) => (
                        <Skeleton
                          key={index}
                          variant="rounded"
                          width={"100%"}
                          height={40}
                          sx={{ bgcolor: "lightgray", opacity: 0.6 }}
                        />
                      ))
                    ) : allCalendarLists && allCalendarLists.length > 0 ? (
                      allCalendarLists?.map((calendar: any, index: number) => (
                        <SidebarCalenderInfoCard
                          key={calendar?._id || index}
                          label={calendar?.name}
                          iconImgSrc={calendar?.icon as string}
                          viewStatus={true}
                          onEyeButtonClick={() => {
                            handleToggleCalendarVisibility &&
                              handleToggleCalendarVisibility(calendar?._id);
                          }}
                          isAliveToken={calendar?.isAliveToken}
                          calColor={calendar?.color}
                          _id={calendar?._id}
                        />
                      ))
                    ) : (
                      // Show message when no calendar is found
                      <Typography
                        variant="body1"
                        sx={{
                          font: "normal normal 500 13px/17px Source Serif Pro",
                          textAlign: "center",
                          color: "text.secondary", // Optional: adjust color to fit your theme
                        }}
                      >
                        No calendar found or created.
                      </Typography>
                    )}
                  </>
                }
              />
            </Stack>
          </Stack>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <InCalendarEventPopup handleClose={handleClose} />
          </Popover>
          {showSearchContainer && (
              <Box
                  sx={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1000,
                  }}
              >
                <CommandControlSearchContainer />
              </Box>
          )}
        </Drawer>
      </DrawerContainer>
    </>
  );
};

export default PlanDrawer;
