import { Stack, Typography } from "@mui/material";
import React from "react";
import { DealStageCurrencySymbols } from "../../../../common";

interface TableExpenseAreaProps {
  value: any;
}

const TableExpenseArea: React.FC<TableExpenseAreaProps> = ({}) => {
  const currencySymbol = DealStageCurrencySymbols.USD;
  return (
    <Stack alignItems={"center"}>
      <Typography sx={{ font: "normal normal 900 12px/15px Source Serif Pro" }}>
        {currencySymbol}
        {10000}
      </Typography>
    </Stack>
  );
};

export default TableExpenseArea;
