import React from "react";
import { SmallPinnedCard } from "../../../../../../../common";

interface SingleUserDataAreaProps {
  isOnline?: boolean;
  userName?: string;
  userImgSrc?: string;
  userAvatarColor?: string;
}

const SingleUserDataArea: React.FC<SingleUserDataAreaProps> = ({
  isOnline,
  userAvatarColor,
  userImgSrc,
  userName,
}) => {
  return (
    <SmallPinnedCard
      size={30}
      userName={userName}
      isActive={isOnline}
      userIconSrc={userImgSrc}
      userAvatarColor={userAvatarColor}
    />
  );
};

export default SingleUserDataArea;
