import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { EventContentArg, CalendarApi } from "@fullcalendar/core";
import { Button, Stack, IconButton, Typography, Box } from "@mui/material";
import "./customStyles.css";
import { AllDirArrowsIcon } from "../../../../images";
import { formatDate, getUser } from "../../../../Utils";
import { TimeZoneCalendarDropdown } from "../../../common";
import { EventCard } from "../components";
import Holidays from "date-holidays";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { LayoutState, updateEventDate__api } from "../../../../redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

// Initialize dayjs plugins
dayjs.extend(utc);
dayjs.extend(timezone);

// Define types for event data and task data
// Define types for guests
interface Guest {
  userId: string | null;
  email: string;
  isExternal: boolean;
  responseStatus: string;
  note?: string; // Optional
  _id: string;
}

// Define types for video call information
interface VideoCall {
  platform: string;
  customLink?: string | null; // Optional
}

// Define types for repeat information
interface Repeat {
  custom: {
    interval: number;
    repeatDays: string[];
    frequency: string;
    endDate?: string | null; // Optional
  };
  type: string;
}

// Define types for travel time information
interface TravelTime {
  time: number;
  direction: string;
}

// Define the main Event interface
interface Event {
  _id: string;
  title: string;
  start: string; // This should be a valid date string
  end: string; // This should be a valid date string
  eventType: string;
  taskOrEvent: string;
  calendarId: string;
  date: string; // Original date in ISO format
  startTime: string; // Time as a string
  endTime: string; // Time as a string
  allDay: boolean;
  timezone: string;
  bookingLinkId: string | null; // Optional
  guests: Guest[];
  location: string;
  showAs: string;
  description: string;
  isDeleted: boolean;
  createdBy: string;
  createdByType: string;
  createdAt: string; // ISO date string
  updatedAt: string; // ISO date string
  repeat: Repeat;
  travelTime: TravelTime;
  videoCall: VideoCall;
  proposedTimes: any[]; // Adjust type if you know what this should be
  isHoliday?: boolean;
  cardColor?: string;
  __v: number;
  calendarProvider?: any; // Event
}

const EventRender: React.FC<{ eventInfo: EventContentArg }> = ({
  eventInfo,
}) => {
  const { title, extendedProps } = eventInfo.event;
  const { cardColor, cardType, status } = extendedProps;

  return (
    <EventCard
      eventName={title}
      cardColor={cardColor || "red"}
      cardType={cardType}
      extendedProps={extendedProps}
      timeDuration={extendedProps.timeDuration || 1}
      status={cardType === "card" ? status : undefined}
    />
  );
};

// Today button component
const TodayButton: React.FC<{ calendarRef: React.RefObject<FullCalendar> }> = ({
  calendarRef,
}) => {
  const { t } = useTranslation();
  return (
    <Button
      className="today-cus"
      onClick={() => calendarRef.current?.getApi().today()}
    >
      {t("CALENDAR.TODAY")}
    </Button>
  );
};

// Month navigation buttons component
const MonthNavigationButtons: React.FC<{
  calendarRef: React.RefObject<FullCalendar>;
}> = ({ calendarRef }) => (
  <div>
    <IconButton onClick={() => calendarRef.current?.getApi().prev()}>
      <AllDirArrowsIcon direction="left" />
    </IconButton>
    <IconButton onClick={() => calendarRef.current?.getApi().next()}>
      <AllDirArrowsIcon direction="right" />
    </IconButton>
  </div>
);

// Current date display component
const CurrentDateComponent: React.FC = () => {
  const currentDate = new Date().toISOString().split("T")[0];
  const formattedDate = formatDate(currentDate);
  return (
    <Typography
      sx={{
        fontFamily: "Source Serif Pro",
        fontWeight: "300",
        fontSize: "15px",
        lineHeight: "18.8px",
      }}
    >
      {formattedDate}
    </Typography>
  );
};

// Custom view options for day/month/week/list
const CustomDayMonthWeekList: React.FC<{
  calendarRef: React.RefObject<FullCalendar>;
  calendarView?: any;
}> = ({ calendarRef, calendarView }) => {
  const { t } = useTranslation();
  const [currentView, setCurrentView] = useState<string>("dayGridMonth");

  const handleViewChange = (view: string) => {
    calendarRef.current?.getApi().changeView(view);
    setCurrentView(view);
  };

  useEffect(() => {
    calendarRef.current?.getApi().changeView(calendarView);
    setCurrentView(calendarView);
  }, [calendarView]);

  return (
    <div>
      {["timeGridDay", "timeGridWeek", "dayGridMonth", "listMonth"].map(
        (view) => (
          <Button
            key={view}
            onClick={() => handleViewChange(view)}
            style={{
              fontWeight: currentView === view ? "bold" : "normal",
              fontFamily: "Source Serif Pro",
              fontSize: "15px",
              textTransform: "none",
              color: "black",
            }}
          >
            {view === "timeGridDay"
              ? t("CALENDAR.DAY")
              : view === "timeGridWeek"
                ? t("CALENDAR.WEEK")
                : view === "dayGridMonth"
                  ? t("CALENDAR.MONTH")
                  : t("CALENDAR.LIST")}
          </Button>
        )
      )}
    </div>
  );
};

// Main calendar component
const MainCalendar: React.FC<{
  type: any;
  calendarView?: string;
  eventsList: Event[];
  l: any;
}> = ({ calendarView, type, eventsList, l }) => {
  const dispatch = useDispatch();
  const layoutState = useSelector(
    (state: RootState) => state && (state?.layout as LayoutState)
  );
  const calendarState = useSelector(
    (state: RootState) => state && state?.calendar
  );
  const {
    calSettings,
    hiddenCalendarIds,
    update__start_endTime__based__TimeZone,
  } = calendarState || {};

  const [events, setEvents] = useState<Event[]>([]);
  const [holidays, setHolidays] = useState<Event[]>([]);
  const [showChristianHolidays, setShowChristianHolidays] = useState(true);
  const [showOrthodoxHolidays, setShowOrthodoxHolidays] = useState(true);
  const [showMuslimHolidays, setShowMuslimHolidays] = useState(true);
  const [showHinduHolidays, setShowHinduHolidays] = useState(true);
  const [showJewishHolidays, setShowJewishHolidays] = useState(true);
  const calendarRef = useRef<FullCalendar>(null);

  const convertToTimezone = (date: string, timezone: string) => {
    return dayjs(date).tz(timezone, true).toISOString(); // Convert to the specified timezone and return as ISO string
  };

  const user = getUser();

  useEffect(() => {
    const processedEvents = eventsList
      ?.map((event: Event) => {
        // Check for necessary fields
        if (!event?.startTime || !event?.endTime || !event?.timezone) {
          console.error("Event missing necessary time fields:", event);
          return null; // Skip this event if required fields are missing
        }

        // Use the `update__start_endTime__based__TimeZone` if available, otherwise fall back to the event's timezone
        const effectiveTimezone =
          update__start_endTime__based__TimeZone || event?.timezone;

        // Convert start and end time to the effective timezone using dayjs
        const start = dayjs(event?.startTime)
          .utc() // Ensure the input is treated as UTC
          .tz(effectiveTimezone, true); // Convert to the effective timezone
        const end = dayjs(event?.endTime)
          .utc()
          .tz(effectiveTimezone, true);


        // Calculate time duration (in hours)
        const startDate = start.toDate(); // Convert Dayjs object to JavaScript Date
        const endDate = end.toDate(); // Convert Dayjs object to JavaScript Date
        const timeDuration = Math.round(
          (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60)
        ); // Convert milliseconds to hours
        const validDuration = Math.min(Math.max(timeDuration, 1), 12);

        // Determine cardType and status
        const cardType =
          event?.taskOrEvent === "event"
            ? "card"
            : event?.taskOrEvent === "task"
              ? "task"
              : undefined;

        let status;

        // Logic to set status
        const userId = user?.data?._id; // Assuming user ID can be retrieved
        if (userId) {
          // Ensure userId is defined
          if (event?.createdBy === userId) {
            if (event?.bookingLinkId === null) {
              status = "Accept"; // User is the creator
            } else if (event?.bookingLinkId !== null) {
              status =
                event?.guests?.[0]?.responseStatus === "accepted"
                  ? "Accept"
                  : "pending";
            }
          } else if (event?.guests?.some((guest) => guest?.userId === userId)) {
            status =
              event?.guests?.find((guest) => guest?.userId === userId)
                ?.responseStatus === "accepted"
                ? "Accept"
                : "pending"; // Define this based on your logic
          } else {
            status = "pending"; // Default status if no other conditions are met
          }
        } else {
          console.warn("User ID is not defined");
        }

        // Return the new event format with extendedProps
        return {
          _id: event?._id,
          title: event?.title,
          start: start.toISOString(), // Convert Dayjs object to ISO string
          end: end.toISOString(),
          eventType: event?.eventType,
          taskOrEvent: event?.taskOrEvent,
          calendarId: event?.calendarId,
          allDay: event?.allDay,
          timezone: event?.timezone,
          guests: event?.guests,
          location: event?.location,
          showAs: event?.showAs,
          description: event?.description,
          isDeleted: event?.isDeleted,
          createdBy: event?.createdBy,
          createdByType: event?.createdByType,
          createdAt: event?.createdAt,
          updatedAt: event?.updatedAt,
          repeat: event?.repeat,
          travelTime: event?.travelTime,
          videoCall: event?.videoCall,
          proposedTimes: event?.proposedTimes,
          isHoliday: event?.isHoliday, // Keep original isHoliday value
          __v: event?.__v,
          calendarProvider: event?.calendarProvider,
          extendedProps: {
            cardType, // Include cardType in extendedProps
            timeDuration: validDuration, // Include timeDuration
            status: cardType === "card" ? status : undefined, // Include status
            taskCompleted: new Date() > endDate, // Check if the task is completed
            st: start.toISOString(),
            et: end.toISOString(),
            cardColor: event?.cardColor,
            cId: event?.calendarId,
            eId: event?._id,
            calendarProvider: event?.calendarProvider,
            ceType:
              event?.bookingLinkId !== null
                ? "host"
                : event?.createdBy !== user?.data?._id
                  ? "guest"
                  : "host",
            title: event?.title,
          },
        };
      })
      ?.filter(
        (event) =>
          event !== null && !hiddenCalendarIds?.includes(event?.calendarId) // Filter out hidden calendar events
      ); // Remove any null values

    setEvents(processedEvents as any[]);
  }, [eventsList, hiddenCalendarIds, update__start_endTime__based__TimeZone]); // Include hiddenCalendarIds as a dependency

  useEffect(() => {
    const hd = new Holidays();
    const currentYear = new Date().getFullYear();
    let fetchedHolidays: Event[] = [];

    const fetchHolidays = (country: string, type: string, color: string) => {
      hd.init(country);
      const countryHolidays = hd?.getHolidays(currentYear)?.map((event) => ({
        _id: event?.date,
        title: event?.name,
        start: event?.date,
        end: event?.date,
        eventType: "card",
        taskOrEvent: "event",
        calendarId: `${event?.date}-${event?.name}`, // Ensure unique calendar ID
        allDay: true,
        timezone: "UTC",
        guests: [], // Ensure this is an array as per your Event interface
        location: "", // Default empty
        showAs: "holiday", // Ensure this matches your Event interface requirements
        description: "", // Default empty
        isDeleted: false,
        createdBy: "", // Assign a valid user or ID if necessary
        createdByType: "", // Assign as needed
        createdAt: new Date().toISOString(), // Assign current date
        updatedAt: new Date().toISOString(), // Assign current date
        repeat: {
          custom: { interval: 0, repeatDays: [], frequency: "" },
          type: "none",
        }, // Default repeat information
        travelTime: { time: 0, direction: "both" }, // Default travel time
        videoCall: { platform: "", customLink: null }, // Default video call info
        proposedTimes: [], // Ensure this is an array as per your Event interface
        date: event?.date, // Add missing properties
        startTime: "", // Provide a default if not applicable
        endTime: "", // Provide a default if not applicable
        bookingLinkId: null, // Default as needed
        isHoliday: true, // Set to true for holiday events
        cardColor: "darkblue",
        __v: 0, // Initialize __v or update as needed
      }));

      // Now fetchedHolidays should align with Event[]
      fetchedHolidays = [...fetchedHolidays, ...countryHolidays];
    };

    if (showChristianHolidays) fetchHolidays("US", "Christian", "blue");
    if (showOrthodoxHolidays) fetchHolidays("RU", "Orthodox", "green");
    if (showMuslimHolidays) fetchHolidays("SA", "Muslim", "purple");
    if (showHinduHolidays) fetchHolidays("IN", "Hindu", "orange");
    if (showJewishHolidays) fetchHolidays("IL", "Jewish", "yellow");

    setHolidays(fetchedHolidays);
  }, [
    showChristianHolidays,
    showOrthodoxHolidays,
    showMuslimHolidays,
    showHinduHolidays,
    showJewishHolidays,
  ]);
  // Use effect to sync visibility state with calendar settings
  useEffect(() => {
    if (calSettings) {
      setShowChristianHolidays(
        calSettings?.holidayCalendars?.religiousHolidays?.christian
      );
      setShowOrthodoxHolidays(
        calSettings?.holidayCalendars?.religiousHolidays?.orthodox
      );
      setShowMuslimHolidays(
        calSettings?.holidayCalendars?.religiousHolidays?.muslim
      );
      setShowHinduHolidays(
        calSettings?.holidayCalendars?.religiousHolidays?.hindu
      );
      setShowJewishHolidays(
        calSettings?.holidayCalendars?.religiousHolidays?.jewish
      );
    }
  }, [calSettings]);

  const normalizeDate = (dateString: string): string => {
    // Handle date-only input (YYYY-MM-DD format)
    if (dateString.length === 10) {
      // Return the date string as is without 'T00:00:00.000Z'
      return dateString;
    }

    // Handle full date-time input (e.g., YYYY-MM-DDTHH:MM:SS)
    const parsedDate = new Date(dateString);
    if (isNaN(parsedDate.getTime())) {
      throw new Error("Invalid date format");
    }

    // Convert to ISO string to normalize the date-time
    const isoString = parsedDate.toISOString();

    // Check if the ISO string represents a date-only (00:00:00) and strip the time part if necessary
    if (isoString.endsWith("T00:00:00.000Z")) {
      // If it's a date-only format (no specific time), remove the time and return only the date part
      return isoString.slice(0, 10); // Return YYYY-MM-DD
    }

    // Otherwise, return the full date-time
    return isoString; // Full date-time (e.g., 2024-11-22T20:40:00.000Z)
  };

  const updateEventDate = async (
    calendarId: string,
    eventId: string,
    date: string
  ) => {
    if (!date) {
      return;
    }

    let normalizedDate: string;
    try {
      // Normalize the date before making the API call
      normalizedDate = normalizeDate(date);
    } catch (error) {
      console.error("Date normalization error:", error);
      return; // Exit if there's an error in date normalization
    }

    try {
      // Prepare the API call with normalized date
      const action = updateEventDate__api(calendarId, eventId, normalizedDate);
      await dispatch(action);
    } catch (err) {
      console.log("API call error:", err);
    }
  };

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();

      // Wait for the drawer animation to finish
      const timer = setTimeout(() => {
        calendarApi.updateSize(); // First, trigger resize

        // Find a date element to click, ideally today's date
        const todayCell = document.querySelector(
          ".fc-day-today"
        ) as HTMLElement; // Cast to HTMLElement
        if (todayCell) {
          todayCell.click(); // Programmatically trigger a click on the current day
          console.log("clicked");
        } else {
          // Fallback: click on any date (e.g., first day of the calendar)
          const firstDateCell = document.querySelector(
            ".fc-daygrid-day"
          ) as HTMLElement; // Cast to HTMLElement
          if (firstDateCell) {
            firstDateCell.click();
            console.log("clicked");
          }
        }
      }, 1000); // Delay for the layout to adjust

      return () => clearTimeout(timer); // Cleanup on unmount
    }
  }, [layoutState?.isDrawer_Calendar, l]);

  return (
    <Box>
      <Stack direction="row" alignItems="center" gap={1}>
        <TodayButton calendarRef={calendarRef} />
        <MonthNavigationButtons calendarRef={calendarRef} />
        <CurrentDateComponent />
        <CustomDayMonthWeekList
          calendarView={calendarView}
          calendarRef={calendarRef}
        />
        <TimeZoneCalendarDropdown />
      </Stack>
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={false}
        events={[...events, ...holidays]}
        dateClick={(arg) => console.log(arg)}
        eventContent={(eventInfo) => <EventRender eventInfo={eventInfo} />}
        editable
        selectable
        eventDrop={(info) => {
          if (info?.event?.extendedProps?.isHoliday) {
            info?.revert(); // Revert the drag if the event is a holiday
          } else {
            // Update the event if it's not a holiday
            setEvents((prevEvents) => {
              const updatedEvents = prevEvents?.map((event) =>
                event?._id === info?.event?.id
                  ? {
                    ...event,
                    start: info?.event?.startStr, // Use startStr for all-day events
                    // Omit end if not needed
                    // end: info.event?.endStr, // You can comment this out if not required
                  }
                  : event
              );

              // Log the updated event details here
              const startStr = info?.event?.startStr;

              if (startStr) {
                if (updateEventDate) {
                  updateEventDate(
                    info?.event?._def?.extendedProps?.calendarId,
                    info?.event?._def?.extendedProps?._id,
                    startStr
                  );
                }
              } else {
                console.error("Event start is null", info?.event);
              }

              return updatedEvents;
            });
          }
        }}
      />
    </Box>
  );
};

export default MainCalendar;
