import { CloudStorageFileCompanyTypeEnum } from "../components/common";
import {
  BearishLogoPng,
  BoxLogoPng,
  DropBoxLogoPng,
  GoogleDriveLogoPng,
  OneDriveLogoPng,
} from "../images";

export const getPlateform = (plateform: any) => {
  switch (plateform) {
    case CloudStorageFileCompanyTypeEnum.BearishOS:
      return BearishLogoPng;

    case CloudStorageFileCompanyTypeEnum.Box:
      return BoxLogoPng;

    case CloudStorageFileCompanyTypeEnum.DropBox:
      return DropBoxLogoPng;

    case CloudStorageFileCompanyTypeEnum.GoogleDrive:
      return GoogleDriveLogoPng;

    case CloudStorageFileCompanyTypeEnum.OneDrive:
      return OneDriveLogoPng;
  }
};
