import {Stack, Box, IconButton, Tooltip, Divider} from "@mui/material";
import React, {useState} from "react";
import {
    AllDirArrowCircleIcon,
    LasTTouchPointIcon,
    MultiCircleIcon,
    RecordIcon,
    SettingsIcon,
    VentureCapitalIcon,
} from "../../../images";
import {keyframes} from "@emotion/react";
import {ScreenRecordingLive} from "../../pages/Trax/components";

interface BottomPopOutUniversalProps {
    onClick?: (buttonName: string) => void;
}

const BottomPopOutUniversal: React.FC<BottomPopOutUniversalProps> = ({}) => {
    const [isOpened, setIsOpened] = useState(false);
    const [openScreenRecordingLive, setOpenScreenRecordingLive] = useState(false);

    type menuType = {
        id: number;
        icon?: React.ElementType;
        iconColor?: string;
        iconDirection?: "up" | "down" | "left" | "right";
        hoverText?: string;
        buttonName?: string;
        divider?: boolean;
    };

    const menuList: menuType[] = [
        {
            id: 1,
            buttonName: "clockIn",
            hoverText: "Clock In",
            icon: LasTTouchPointIcon,
            iconColor: "#308893",
            iconDirection: "up",
        },
        {
            id: 2,
            buttonName: "break",
            hoverText: "Break",
            icon: MultiCircleIcon,
            iconColor: "#FFB100",
        },
        {
            id: 3,
            buttonName: "clockOut",
            hoverText: "Clock Out",
            icon: LasTTouchPointIcon,
            iconColor: "#FF0000",
        },
        {
            id: 4,
            divider: true,
        },
        {
            id: 5,
            buttonName: "openBilling",
            hoverText: "Open Billing",
            icon: VentureCapitalIcon,
            iconColor: "#21B481",
        },
        {
            id: 6,
            buttonName: "screenRecord",
            hoverText: "Screen Record",
            icon: RecordIcon,
            iconColor: "#F3206A",
        },
        {
            id: 7,
            buttonName: "setting",
            hoverText: "Setting",
            icon: SettingsIcon,
        },
    ];

    const growAnimation = keyframes`
        from {
            width: 50px;
        }
        to {
            width: 265px;
        }
    `;

    const shrinkAnimation = keyframes`
        from {
            width: 265px;
        }
        to {
            width: 50px;
        }
    `;


    const handleOpenAndCloseScreenRecordingSection = () => {
        setOpenScreenRecordingLive(!openScreenRecordingLive);
    };

    return (
        <>
            <Box
                sx={{
                    position: "relative",
                    height: "40px",
                    width: "50px",
                    borderRadius: "50px 5px 5px 50px",
                    bgcolor: "white"
                }}
            >
                {openScreenRecordingLive && (
                    <ScreenRecordingLive handleCloseScreenRecordingSection={handleOpenAndCloseScreenRecordingSection}/>
                )}
                <Stack
                    sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        right: 0,
                        justifyContent: "center",
                        height: "40px",
                        width: isOpened ? "265px" : "50px", // Animation for width
                        animation: `${isOpened ? growAnimation : shrinkAnimation
                        } 0.3s ease`,
                        bgcolor: "white",
                        borderRadius: "50px 5px 5px 50px",
                        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.25)",
                        borderTop: "1px solid #E9EDF2",
                        borderBottom: "1px solid #E9EDF2",
                        borderLeft: "1px solid #E9EDF2",
                        cursor: "pointer",
                        position: "absolute",
                        overflow: "hidden",
                        padding: !isOpened ? "0px" : "0px 5px",
                        transition: "width 0.3s ease", // Smooth transition for width
                    }}
                >
                    <IconButton
                        onClick={() => setIsOpened((prev) => !prev)}
                        sx={{
                            marginRight: isOpened ? "auto" : "unset", // Pushes icon to the left when opened
                            transition: "margin-right 0.3s ease",
                        }}
                    >
                        <AllDirArrowCircleIcon
                            w={20}
                            direction={isOpened ? "left" : "right"}
                        />
                    </IconButton>
                    {isOpened &&
                        menuList.map((item, index) =>
                            item.divider ? (
                                <Divider
                                    key={item.id}
                                    orientation="vertical"
                                    sx={{margin: "0px 5px", height: "25px"}}
                                />
                            ) : (
                                <Tooltip
                                    key={item.id}
                                    title={item.hoverText}
                                    placement="bottom"
                                    arrow
                                >
                                    <IconButton
                                        onClick={
                                            item.buttonName === "screenRecord"
                                                ? handleOpenAndCloseScreenRecordingSection
                                                : undefined
                                        }
                                    >
                                        {item.icon &&
                                            React.createElement(item.icon, {
                                                w: 20,
                                                color: item.iconColor,
                                                direction: item.iconDirection,
                                            })}
                                    </IconButton>
                                </Tooltip>
                            )
                        )}
                </Stack>
            </Box>

        </>
    );
};

export default BottomPopOutUniversal;
