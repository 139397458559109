// EventView.tsx
import React, { useState } from "react";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { SidebarCalendarDataListInterface } from "../../SidebarCalendarType";
import { getCalenderLogo, getUser } from "../../../../../../../Utils";
import { CheckboxFilledIcon, XCircleIcon } from "../../../../../../../images";

type EventDataListType = Extract<
  SidebarCalendarDataListInterface["dataList"][number],
  { type: "event" }
>["data"];

interface EventViewProps {
  events: any;
  handleEventResponse?: any;
}

const EventView: React.FC<EventViewProps> = ({
  events,
  handleEventResponse,
}) => {
  const user = getUser();

  const [temp__click, settemp__click] = useState<any>(false)

  return (
    <Stack
      sx={{
        bgcolor: "white",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "5px",
        borderRadius: "5px",
        border:
          (events.status && !temp__click) === "pending" ? "1px solid #FFB100" : "1px solid white",
      }}
    >
      <Stack
        sx={{
          gap: "10px",
          bgcolor: "white",
          flexDirection: "row",
          alignItems: "start",
        }}
      >
        <Box
          component={"img"}
          src={getCalenderLogo({ calendarType: events?.calendarType })}
          alt="calendar logo"
          sx={{ height: "20px", width: "20px", flexShrink: 0 }}
        />
        <Stack>
          <Typography
            sx={{
              font: "normal normal 300 15px/19px Source Serif Pro",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "100%",
              maxWidth: events.status === "pending" ? "150px" : "200px",
            }}
          >
            {events?.eventName}
          </Typography>
          <Typography
            sx={{
              font: "normal normal 300 13px/16px Source Serif Pro",
              color: "#B9B9B9",
            }}
          >
            {events?.eventTime}
          </Typography>
        </Stack>
      </Stack>

      {(events?.status && !temp__click) === "pending" && (
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
          <Tooltip title="Accept" placement="top" arrow>
            <IconButton
              onClick={() => {
                handleEventResponse(events?.e?.calendarId, events?.e?._id,
                  events?.e?.bookingLinkId !== null
                    ? "host"
                    : events?.e?.createdBy !== user?.data?._id
                      ? "guest"
                      : "host",
                      "Accept"
                );
                settemp__click(true);
              }}
              sx={{ p: "1px", color: "#21B481" }}
            >
              <CheckboxFilledIcon color="#21B481" w={15} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Reject " placement="top" arrow>
            <IconButton
              onClick={() => {
                handleEventResponse(events?.e?.calendarId, events?.e?._id,
                  events?.e?.bookingLinkId !== null
                    ? "host"
                    : events?.e?.createdBy !== user?.data?._id
                      ? "guest"
                      : "host",
                      "Reject"
                );
              }}
              sx={{ p: "1px", color: "#FF0000" }}
            >
              <XCircleIcon color="#FF0000" w={15} />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
    </Stack>
  );
};

export default EventView;
