import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";
import { StreamBearishOSLogoIconPng } from "../../../../../images";
import styles from "./UpcomingCallCard.module.css";
import moment from "moment-timezone";

interface UpcomingCallCardProps {
  isSelected?: boolean;
  meetingDetail?: string;
  meetingStartTime?: string;
  meetingId?: string;
  handleMeetingClick?: any;
  meeting_Id?: string;
  meetingEndTime?: string;
  userTimeZone?: string;
}

const UpcomingCallCard: React.FC<UpcomingCallCardProps> = ({
  isSelected = false,
  meetingDetail = "Instant Meeting",
  meetingStartTime = "2024-11-18T15:23:00",
  meetingEndTime = "2024-11-18T15:23:00",
  meetingId,
  handleMeetingClick,
  meeting_Id,
  userTimeZone = "America/New_York",
}) => {

  const formatTimeInTimeZone = (time: string, timeZone: string) => {
    return moment.tz(time, timeZone).format("hh:mm a");
  };

  const formattedStartTime = formatTimeInTimeZone(meetingStartTime, userTimeZone);
  const formattedEndTime = formatTimeInTimeZone(meetingEndTime, userTimeZone);
  const formattedDateTime = `${formattedStartTime} - ${formattedEndTime} ${moment.tz(userTimeZone).format('z')}`;

  return (
    <Stack className={styles.upcomingCallCard}>
      <Stack className={styles.upcomingCallCard__indicator} />
      <Stack
        className={styles.upcomingCallCard__content}
        sx={{ bgcolor: isSelected ? adjustHexColor("#E9EDF2", 35) : "inherit" }}
        onClick={() => handleMeetingClick(meeting_Id)}
      >
        <Stack className={styles.upcomingCallCard__header}>
          <Box
            component={"img"}
            src={StreamBearishOSLogoIconPng}
            sx={{ height: "20px", width: "20px" }}
            alt="logo"
          />
          <Typography className={styles.upcomingCallCard__meetingName}>
            {meetingDetail}
          </Typography>
        </Stack>
        <Typography className={styles.upcomingCallCard__time}>
          {formattedDateTime}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default UpcomingCallCard;
