import { InputBase, Stack, Typography } from "@mui/material";
import React from "react";
import { CameraIcon } from "../../../../../../../images";

interface VideoNameAndPreviewAreaProps {
    videoStream?: string | null;
    videoTitle: (value: string) => void;
}

const VideoNameAndPreviewArea: React.FC<
  VideoNameAndPreviewAreaProps
> = ({videoStream,videoTitle}) => {
  return (
    <Stack sx={{ gap: "40px" }}>
      <Stack sx={{ gap: "5px" }}>
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
          <CameraIcon color="black" w={15} />
          <Typography
            sx={{ font: "normal normal 300 12px/15px Source Serif Pro" }}
          >
            Video Name
          </Typography>
        </Stack>
        <InputBase
          sx={{
            height: "30px",
            borderRadius: "5px",
            border: "1px solid #E9EDF2",
            font: "normal normal 400 13px/16px Source Serif Pro",
            width: "100%",
            padding: "0px 10px",
          }}
          onChange={(e) => videoTitle(e.target.value)}
        />
      </Stack>
      <Stack sx={{ alignItems: "center" }}>
          <Stack
              sx={{
                  height: "200px",
                  width: "400px",
                  borderRadius: "5px",
                  boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.10)",
              }}
          >
              {videoStream ? (
                  <video src={videoStream} controls style={{ width: "100%", height: "100%" }} />
              ) : (
                  <div>video preview here</div>
              )}
          </Stack>
      </Stack>
    </Stack>
  );
};

export default VideoNameAndPreviewArea;
