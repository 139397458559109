import React, { FC, useState, useCallback } from "react";
import { Box, Stack, Typography } from "@mui/material";
import g from "./CrmLargePopup.module.css";
import { CompanyIcon, PeoplesIcon } from "../../../../../images";
import {
  ButtonSmall,
  ButtonType,
  DropdownWithImageBadge,
  DropdownWithImgBadgeInterface,
  LargeInputBox,
  SmallButtonColor,
} from "../../../../common";
import {
  addBusiness__api,
  addToContact__api,
  createDeal__api,
  createGroup__api,
  recallAPI__CRM,
} from "../../../../../redux";
import { useDispatch, useSelector } from "react-redux";
import { AddDealPopupLarge } from "../AddDealPopupLarge";
import { CreateGroupPopUp } from "../CreateGroupPopUp";
import { RootState } from "../../../../../redux/store";

// Define the prop types for the component
interface CrmLargePopupProps {
  label: "company" | "contact" | "deal" | "group" | "";
  handleClose?: any;
}

// Define the state types for the input values
interface InputValues {
  companyName?: string;
  companyDomain?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: number;
}

// Define initial state
const initialInputValues: InputValues = {
  companyName: "",
  companyDomain: "",
  email: "",
  firstName: "",
  lastName: "",
  phone: 91,
};

const CrmLargePopup: FC<CrmLargePopupProps> = ({
  label = "Company",
  handleClose,
}) => {
  const crmState = useSelector((state: RootState) => state && state?.crm);
  const { companyList } = crmState || {};
  const dispatch = useDispatch();
  const [inputValues, setInputValues] =
    useState<InputValues>(initialInputValues);
  // console.log(inputValues, "inputValues");
  const handleInputChange = useCallback(
    (field: keyof InputValues) => (e: any | string) => {
      const value = typeof e === "string" ? e : e?.target?.value;
      setInputValues((prevValues) => ({
        ...prevValues,
        [field]: value,
      }));
    },
    []
  );

  const addContact = async () => {
    try {
      const payload: any = {
        // businessId: inputValues?.companyDomain,
        firstName: inputValues?.firstName,
        lastName: inputValues?.lastName,
        email: inputValues?.email,
        // phone: inputValues?.phone,
      };
      const action = addToContact__api(payload);
      const addContactRes: any = await dispatch(action);
      if (addContactRes?.success) {
        dispatch(recallAPI__CRM("getAllContacts"));
        if (handleClose) {
          handleClose();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addBusiness = async () => {
    try {
      const payload: any = {
        businessName: inputValues?.companyName,
        domain: inputValues?.companyDomain,
      };
      const action = addBusiness__api(payload);
      const addBusinessRes: any = await dispatch(action);
      if (addBusinessRes?.success) {
        dispatch(recallAPI__CRM("getAllCompanies"));
        if (handleClose) {
          handleClose();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createGroup = async (data: any) => {
    try {
      if (!data || !data?.groupName) {
        return;
      }

      const action = createGroup__api(data);

      const createGroupRes: any = await dispatch(action);

      if (createGroupRes?.success) {
        dispatch(recallAPI__CRM("getAllGroups"));
        if (handleClose) {
          handleClose();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createDeal = async (data: any) => {
    try {
      if (!data || !data?.dealName || !data?.dealOwner || !data?.dealValue) {
        return;
      }

      const action = createDeal__api(data);

      const createDealRes: any = await dispatch(action);

      if (createDealRes?.success) {
        dispatch(recallAPI__CRM("getAllDeals"));
        if (handleClose) {
          handleClose();
        }
      }
    } catch (error) {}
  };

  const handleClickButtonLabel = async () => {
    if (label === "company") {
      addBusiness();
    } else if (label === "contact") {
      addContact();
    }
  };

  // Transform function to map data to DropdownWithImgBadgeInterface
  const transformToDropdownWithImgBadge = (
    data: any
  ): DropdownWithImgBadgeInterface => ({
    _id: data?._id,
    name: data?.businessName,
    userIconSrc: data?.businessLogo,
    value: data?.description,
    firstName: data?.createdBy?.firstName,
    lastName: data?.createdBy?.lastName,
    email: data?.businessName + `@${data?.domain}`,
    status: data?.createdBy?.status,
    avatar: data?.createdBy?.avatar,
    displayName: `${data.createdBy?.firstName || ""} ${
      data?.createdBy?.lastName || ""
    }`?.trim(),
  });

  // Transform all items in companyList
  const transformedCompanyList = companyList?.map((company: any) =>
    transformToDropdownWithImgBadge(company)
  );

  return (
    <Box className={g["crmLargePopupBox-outer"]}>
      <Box className={g.crmLargePopupBox}>
        {(label === "company" || label === "contact") && (
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              gap={1}
            >
              {label === "company" ? <CompanyIcon /> : <PeoplesIcon />}
              <Typography className={g.labelCrmLargePopup}>{label}</Typography>
            </Stack>
          </Box>
        )}
        {label === "company" ? (
          <Stack mt={1.5} gap={1}>
            <LargeInputBox
              height="30px"
              label="Company Name"
              value={inputValues.companyName || ""}
              onChange={(e) => handleInputChange("companyName")(e)}
              borderColor="#E9EDF2"
            />
            <LargeInputBox
              height="30px"
              label="Company Domain"
              value={inputValues.companyDomain || ""}
              onChange={(e) => handleInputChange("companyDomain")(e)}
              borderColor="#E9EDF2"
            />
          </Stack>
        ) : label === "deal" ? ( // Condition for deals
          <Stack mt={1.5} gap={1}>
            <AddDealPopupLarge createDeal={createDeal} variants="CreateDeal" />
          </Stack>
        ) : label === "group" ? ( // New condition for groups
          <Stack mt={1.5} gap={1}>
            <CreateGroupPopUp
              variant="groupWithoutObj"
              createGroup={createGroup}
            />
          </Stack>
        ) : (
          // Default case for other labels
          <Stack mt={1.5} gap={1}>
            {/* <LargeInputBox
              height="30px"
              label="Email"
              value={inputValues.email || ""}
              onChange={(e) => handleInputChange("email")(e)}
              borderColor="#E9EDF2"
            /> */}

            <Stack position={"relative"} top={-7} sx={{ flexGrow: 1 }}>
              <DropdownWithImageBadge
                options={transformedCompanyList || []}
                onChangeSelection={(value) => {
                  const emailArray = value?.map((user) => user?.email);
                  handleInputChange("email")(emailArray?.[0]);
                }}
                placeholder="Email Address"
                showMaxSelected={1}
              />
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={"20px"}
            >
              <LargeInputBox
                height="30px"
                label="First Name"
                value={inputValues.firstName || ""}
                onChange={(e) => handleInputChange("firstName")(e)}
                borderColor="#E9EDF2"
              />
              <LargeInputBox
                height="30px"
                label="Last Name"
                value={inputValues.lastName || ""}
                onChange={(e) => handleInputChange("lastName")(e)}
                borderColor="#E9EDF2"
              />
            </Stack>
          </Stack>
        )}

        {/* Action buttons */}
        {label !== "deal" && label !== "group" && (
          <Box sx={{ marginTop: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              gap={"15px"}
              justifyContent="flex-end"
            >
              <ButtonSmall
                label="Cancel"
                types={ButtonType.Button}
                colorVarient={SmallButtonColor.Transparent}
              />
              <ButtonSmall
                label="Create"
                types={ButtonType.Button}
                onClick={() => handleClickButtonLabel()}
                colorVarient={SmallButtonColor.CRMOrange}
              />
            </Stack>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CrmLargePopup;
