import { Stack, SxProps } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";
import { DealStageCurrencySymbols } from "../../../../common";

type ComponentListType = {
  bgColor: string;
  bgOpacity: number;
  fontColor: string;
};

type valueAreaProps = ComponentListType & {
  currencySymbol: string;
  value: number | string;
  sx?: SxProps;
};

const ValueArea: React.FC<valueAreaProps> = ({
  bgColor,
  bgOpacity,
  fontColor,
  currencySymbol,
  value,
  sx,
}) => {
  return (
    <Stack
      sx={{
        height: "35px",
        width: "125px",
        bgcolor: adjustHexColor(bgColor, bgOpacity),
        font: "normal normal 900 13px/16px Source Serif Pro",
        color: fontColor,
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
    >
      {currencySymbol} {value}
    </Stack>
  );
};

interface ListAccountsProps {}

const ListAccounts: React.FC<ListAccountsProps> = ({}) => {
  const currencySymbol = DealStageCurrencySymbols.USD;
  const componentList: ComponentListType[] = [
    { bgColor: "#21B481", bgOpacity: 65, fontColor: "#fff" },
    { bgColor: "#FF0000", bgOpacity: 45, fontColor: "#fff" },
    { bgColor: "#9B6C00", bgOpacity: 45, fontColor: "#fff" },
    { bgColor: "#E9EDF2", bgOpacity: 75, fontColor: "#000" },
  ];

  const ValueList: string[] = ["110000", "121212", "225525", "2221221"];

  return (
    <Stack sx={{ flexDirection: "row" }}>
      {componentList.map((item, index) => {
        const valueData = ValueList[index];

        return (
          <ValueArea
            key={index}
            bgColor={item.bgColor}
            bgOpacity={item.bgOpacity}
            fontColor={item.fontColor}
            value={valueData}
            currencySymbol={currencySymbol}
            sx={{
              borderRadius:
                index === 0
                  ? "5px 0px 0px 5px"
                  : index === componentList.length - 1
                  ? "0px 5px 5px 0px"
                  : "0px",
            }}
          />
        );
      })}
    </Stack>
  );
};

export default ListAccounts;
