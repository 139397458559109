import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../../../Utils";
import SearchIconView, {
  renderIconPng,
} from "../SearchIconView/SearchIconView";
import {
  FilePreviewArea,
  MultiUserDataArea,
  SingleUserDataArea,
} from "./InternalCard";
import {
  ScrollingTextTypography,
  SmallGroupedAvatarDataTpyes,
} from "../../../../../../common";
import {
  SearchGroups,
  SearchIconPngNameType,
} from "../../CommandControlSearchContainerType";
import styles from "./SearchResultCard.module.css";

interface SearchResultCardProps {
  data: any;
  isSelected?: boolean;
}

const SearchResultCard: React.FC<SearchResultCardProps> = ({
  data,
  isSelected = false, //if true permanent bgcolor will applied
}) => {
  const getUserOrFileicon = () => {
    if (data.userData?.length == 1) {
      return (
        <SingleUserDataArea
          isOnline={data.userData[0].status}
          userName={data.userData[0].name}
          userImgSrc={data.userData[0].userImgSrc}
        />
      );
    }

    if (data.userData?.length > 1) {
      const userlist: SmallGroupedAvatarDataTpyes[] = data.userData.map(
        (user: any) => {
          const email = user.email;
          const avatarColor = user.avatarColor;

          return {
            name: user.name,
            email: email,
            imgSrc: user.userImgSrc,
            avatarColor: avatarColor,
          };
        }
      );
      return <MultiUserDataArea userDataList={userlist} />;
    }

    if (!!data.fileType) {
      return <FilePreviewArea fileType={data.fileType} />;
    }
  };

  // make here your own condition instead of this === operator for render the img src if needed
  const getImgIcon = (oriantations: string): SearchIconPngNameType => {
    if (oriantations === "streamBearishMsgPng") {
      return "streamBearishMsgPng";
    } else if (oriantations === "slackPng") {
      return "slackPng";
    } else if (oriantations === "MicrosoftTeamPng") {
      return "MicrosoftTeamPng";
    } else if (oriantations === "streamBearishCallPng") {
      return "streamBearishCallPng";
    } else if (oriantations === "googleMeetPng") {
      return "googleMeetPng";
    } else if (oriantations === "webexPng") {
      return "webexPng";
    } else if (oriantations === "zoomPng") {
      return "zoomPng";
    } else if (oriantations === "outlookEmailPng") {
      return "outlookEmailPng";
    } else if (oriantations === "gmailPng") {
      return "gmailPng";
    } else if (oriantations === "exchangeServerPng") {
      return "exchangeServerPng";
    } else if (oriantations === "bearishCalendarPng") {
      return "bearishCalendarPng";
    } else if (oriantations === "outlookCalendarPng") {
      return "outlookCalendarPng";
    } else if (oriantations === "googleCalendarPng") {
      return "googleCalendarPng";
    } else if (oriantations === "taskCenterPng") {
      return "taskCenterPng";
    } else if (oriantations === "cloudStoragePng") {
      return "cloudStoragePng";
    } else if (oriantations === "docsPng") {
      return "docsPng";
    } else if (oriantations === "gridsPng") {
      return "gridsPng";
    } else if (oriantations === "wikisPng") {
      return "wikisPng";
    } else if (oriantations === "checklistPng") {
      return "checklistPng";
    } else if (oriantations === "boardPng") {
      return "boardPng";
    } else if (oriantations === "decksPng") {
      return "decksPng";
    } else if (oriantations === "oneDrivePng") {
      return "oneDrivePng";
    } else if (oriantations === "boxPng") {
      return "boxPng";
    } else if (oriantations === "dropboxPng") {
      return "dropboxPng";
    } else if (oriantations === "googleDrivePng") {
      return "googleDrivePng";
    } else if (oriantations === "jiraPng") {
      return "jiraPng";
    } else if (oriantations === "confluencePng") {
      return "confluencePng";
    } else if (oriantations === "crmPng") {
      return "crmPng";
    } else if (oriantations === "salesForcePng") {
      return "salesForcePng";
    } else if (oriantations === "userAvatar") {
      return "userAvatar";
    } else if (oriantations === "traxHrPng") {
      return "traxHrPng";
    } else if (oriantations === "successFactorPng") {
      return "successFactorPng";
    } else if (oriantations === "concurPng") {
      return "concurPng";
    } else {
      return "cloudStoragePng";
    }
  };

  // make here your own condition instead of this === operator for render the bordercolor if needed
  const getBorderClr = (resultType: string): SearchGroups => {
    if (resultType === "calendar") {
      return "calendar";
    } else if (resultType === "crm") {
      return "crm";
    } else if (resultType === "email") {
      return "email";
    } else if (resultType === "message") {
      return "message";
    } else if (resultType === "trax") {
      return "trax";
    } else if (resultType === "video") {
      return "video";
    } else if (resultType === "workspace") {
      return "workspace";
    } else {
      return "workspace";
    }
  };

  return (
    <Button
      onClick={() => console.log("first")}
      className={styles.SearchResultCard__container}
      sx={{
        bgcolor: isSelected ? adjustHexColor("#E6BA8C", 25) : "inherit",
        ":hover": {
          bgcolor: isSelected
            ? adjustHexColor("#E6BA8C", 25)
            : adjustHexColor("#E6BA8C", 15),
        },
      }}
    >
      <Stack className={styles.SearchResultCard__container__details}>
        <SearchIconView
          iconType={getImgIcon(data.oriantations)}
          searchGroup={getBorderClr(data.resultType)}
          headerUserData={data.headerUserData}
        />
        <span style={{ margin: "0px 5px 0px 10px" }}>
          {getUserOrFileicon()}
        </span>
        <Typography
          sx={{
            font: "normal normal 600 13px/16px Source Serif Pro",
            width: "fit-content",
            whiteSpace: "nowrap",
          }}
        >
          {data.resultTitle || data.userData[0].name}
        </Typography>

        {!data.fileType && (
          <Box
            component={"img"}
            src={renderIconPng(getImgIcon(data.oriantations))}
            alt="logo"
            sx={{ height: "15px", width: "15px" }}
          />
        )}

        {data.primaryDetails && (
          <Typography
            sx={{
              font: "normal normal 600 13px/16px Source Serif Pro",
              whiteSpace: "nowrap",
            }}
          >
            {data.primaryDetails}
          </Typography>
        )}

        {data.details && <ScrollingTextTypography text={data.details} />}
      </Stack>
      {data.dateOrTime && (
        <Stack
          sx={{
            width: "min-content",
            minWidth: "60px",
            flexShrink: 0,
            color: "black",
          }}
        >
          <Typography
            sx={{
              font: "normal normal 300 13px/16px Source Serif Pro",
              whiteSpace: "nowrap",
            }}
          >
            {data.action} {data.dateOrTime}
          </Typography>
        </Stack>
      )}
    </Button>
  );
};

export default SearchResultCard;
