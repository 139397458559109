import { Stack, Typography } from "@mui/material";
import React from "react";
import { CheckboxMedium } from "../../../../../../common";
import {
  AttendanceStatusColorLiist,
  AttendanceStatusType,
} from "../../../CrmStatusBadge";

const EdgeChip: React.FC<{ edgeColor: string }> = ({ edgeColor }) => {
  return (
    <Stack
      sx={{
        width: "5px",
        borderRadius: "5px",
        height: "100%",
        flexShrink: 0,
        bgcolor: edgeColor,
        position: "absolute",
        left: -8,
      }}
    />
  );
};

interface DateViewProps {
  // day:string;
  // date:string
  attendanceType: AttendanceStatusType;
}

const DateView: React.FC<DateViewProps> = ({ attendanceType }) => {
  const getEdgeComponent = () => {
    switch (attendanceType) {
      case "absent":
        return <EdgeChip edgeColor={AttendanceStatusColorLiist.absent} />;
      case "dayoff":
        return <EdgeChip edgeColor={AttendanceStatusColorLiist.dayoff} />;
      case "holiday":
        return <EdgeChip edgeColor={AttendanceStatusColorLiist.holiday} />;
      case "pto":
        return <EdgeChip edgeColor={AttendanceStatusColorLiist.pto} />;
      case "sick":
        return <EdgeChip edgeColor={AttendanceStatusColorLiist.sick} />;
      case "late":
      case "onTime":
        return <CheckboxMedium />;
    }
  };

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        gap: "20px",
        height: "calc(100% - 5px)",
      }}
    >
      <Stack
        sx={{
          width: "15px",
          position: "relative",
          height: "100%",
          justifyContent: "center",
        }}
      >
        {getEdgeComponent()}
      </Stack>
      <Stack
        sx={{
          font: "normal normal 400 13px/16px Source Serif Pro",
          gap: "5px",
        }}
      >
        <Typography sx={{ font: "inherit" }}>Mon</Typography>
        <Typography sx={{ font: "inherit" }}>Jun 1st, 2024</Typography>
      </Stack>
    </Stack>
  );
};

export default DateView;
