import React, { useRef, useState } from "react";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { AllDirArrowCircleIcon } from "../../../../../../../images";

interface HeaderViewProps {}

const HeaderView: React.FC<HeaderViewProps> = ({}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFiles(Array.from(event.target.files)); // Store all selected files in state
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        height: "55px",
        flexShrink: 0,
        bgcolor: "white",
        padding: "0px 20px",
        boxSizing: "border-box",
        borderBottom: "1px solid #E9EDF2",
      }}
    >
      <IconButton
        sx={{ p: 0, borderRadius: 0, flexDirection: "row", gap: "5px" }}
        disableRipple
      >
        <AllDirArrowCircleIcon color="black" direction="left" w={15} />
        <Typography
          sx={{
            font: "normal normal 300 11px/14px Source Serif Pro",
            color: "black",
          }}
        >
          Close
        </Typography>
      </IconButton>

      <Typography sx={{ font: "normal normal 300 11px/14px Source Serif Pro" }}>
        Receipt from Monday 00/00/0000
      </Typography>

      <Button
        variant="text"
        onClick={handleButtonClick}
        sx={{
          textTransform: "none",
          color: "#0027FF",
          padding: "2px 5px",
          borderRadius: "5px",
          font: "normal normal 300 11px/14px Source Serif Pro",
        }}
      >
        Update
      </Button>

      {/* Hidden file input */}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        multiple // Allow multiple file selection
      />
    </Stack>
  );
};

export default HeaderView;
