import React from "react";
import { AdditionalFilterMultiRadioBtnType } from "../../../../CommandControlSearchContainer";
import TitleContainer from "../../TitleContainer/TitleContainer";
import { MultiSelectRadioBtn } from "../../../../../../../common";

const workspaceOwnershipList: AdditionalFilterMultiRadioBtnType = {
  _id: "workspaceOwnership",
  field: ["workspace"],
  translatedTitle: "Workspace Ownership",
  option: [
    { _id: "1", translatedTitle: "Owned by me", value: "Owned by me" },
    { _id: "2", translatedTitle: "Shared with Me", value: "Shared with Me" },
    { _id: "3", translatedTitle: "Guest", value: "Guest" },
  ],
};

type ComponentDataType = string[]; // this is type for this component data
interface WorkspaceOwnershipProps {
  stateData: ComponentDataType; // Current data passed from the parent
  setStateData: (data: ComponentDataType) => void; // Function to update data in the parent
}

const WorkspaceOwnership: React.FC<WorkspaceOwnershipProps> = ({
  setStateData,
  stateData,
}) => {
  const handleSelectionChange = (value: string) => {
    const updatedMeetingTypes = stateData.includes(value)
      ? stateData.filter((item) => item !== value) // Remove if already selected
      : [...stateData, value]; // Add if not selected

    // Update the state in the parent
    setStateData(updatedMeetingTypes);
  };

  return (
    <TitleContainer
      title={workspaceOwnershipList.translatedTitle}
      isCollapasable
    >
      {workspaceOwnershipList.option.map((item, index) => (
        <MultiSelectRadioBtn
          key={item._id}
          isChecked={stateData.includes(item.value)}
          label={item.translatedTitle}
          onClick={() => handleSelectionChange(item.value)}
        />
      ))}
    </TitleContainer>
  );
};

export default WorkspaceOwnership;
