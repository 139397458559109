import React from "react";
import { PerformanceTab } from "../../components";

interface EmpPerformanceProps {}

const EmpPerformance: React.FC<EmpPerformanceProps> = ({}) => {
  return <PerformanceTab />;
};

export default EmpPerformance;
