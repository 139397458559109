import React from "react";
import {
  EmployeeStatus,
  employeeStatusBadgeOptionsList,
} from "./EmployeeStatusBadgeMenuType";
import { BadgeDropdown } from "../../common";

interface EmployeeStatusBadgeMenuProps {
  initialValue: EmployeeStatus;
  onSelection: (value: string) => void;
}

const EmployeeStatusBadgeMenu: React.FC<EmployeeStatusBadgeMenuProps> = ({
  initialValue,
  onSelection,
}) => {
  return (
    <BadgeDropdown
      menuLabel="Employee Type"
      initialValue={initialValue}
      options={employeeStatusBadgeOptionsList}
      onSelectionChange={(value) => onSelection(value)}
    />
  );
};

export default EmployeeStatusBadgeMenu;
