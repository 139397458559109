import React from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { CommandControlDashboardCardVariants } from "../CommandControlDashboard/CommandControlDashboard";
import { PendingEventCommandControlPopup } from "../PendingEventCommandControlPopup";
import { EventVideoCallHover } from "../EventVideoCallHover";
import { PostCallEventHover } from "../PostCallEventHover";
import { XCircleIcon } from "../../../../../images";
import { adjustHexColor } from "../../../../../Utils";
import { MessageSmallerPopout } from "../../../../common";
import { ChildContainer } from "./view";

interface DashboardCardHoverPopupProps {
  cardType: CommandControlDashboardCardVariants;
  data: any;
  handleOnBackClick: () => void;
}

const DashboardCardHoverPopup: React.FC<DashboardCardHoverPopupProps> = ({
  cardType,
  handleOnBackClick,
}) => {
  const getComponent = () => {
    switch (cardType) {
      case "task":
        return <div>task card here</div>;
      case "taskDone":
        return <div>task done card here</div>;
      case "overdueTask":
        return <div>overdue task card here</div>;
      case "message":
        return (
          <ChildContainer labelBoxColor="#775EE2" labelTitle="@message">
            <MessageSmallerPopout onBackButtonClick={() => null} hideXbutton />
          </ChildContainer>
        );
      case "flag":
        return <div>flag card here</div>;
      case "chat":
        return <div>chat card here</div>;
      case "pendingEvent":
        return <PendingEventCommandControlPopup />;
      case "upcomingEvent":
        return <div>upcomingEvent card here</div>;
      case "reminderEvent":
        return <div>removeEvent card here</div>;
      case "call":
        return <EventVideoCallHover />;
      case "postCall":
        return <PostCallEventHover />;
      case "timeOff":
        return <div>timeoff card here</div>;
      case "expense":
        return <div>exp card here</div>;
      case "payroll":
        return <div>payroll card here</div>;
      case "timesheet":
        return <div>timesheet card here</div>;
      case "performace":
        return <div>performace card here</div>;
      case "development":
        return <div>development card here</div>;
      case "compliance":
        return <div>compliance card here</div>;
    }
  };

  return (
    <div
      style={{
        maxHeight: "640px",
      }}
    >
      <Stack
        sx={{
          height: "fit-content",
          width: "455px",
          borderRadius: "5px",
          bgcolor: adjustHexColor("#ffffff", 65),
          boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
          position: "relative",
        }}
      >
        <IconButton
          sx={{
            padding: "5px",
            position: "absolute",
            right: "5px",
            top: "4px",
          }}
          onClick={handleOnBackClick}
        >
          <XCircleIcon w={20} color="white" />
        </IconButton>
        <Stack sx={{ height: "100%" }}>{getComponent()}</Stack>
      </Stack>
    </div>
  );
};

export default DashboardCardHoverPopup;
