import { Button, InputBase, Stack, Typography } from "@mui/material";
import React, {useEffect, useState} from "react";
import { ButtonType, EditableTextArea } from "../../../../common";
import { adjustHexColor } from "../../../../../Utils";
import { MediumButtonSmall } from "../MediumButtonSmall";

interface ConnectFilePopupProps {
    onSave: (file: any, title: string, description: string) => void;
    onClose: () => void;
    fileData?: any; // Add this line
}

const ConnectFilePopup: React.FC<ConnectFilePopupProps> = ({ onSave, onClose, fileData  }) => {
    const [file, setFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState<string>("");
    const [title, setTitle] = useState(fileData?.title || ""); // Update this line
    const [description, setDescription] = useState(fileData?.description || ""); // Update this line
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (fileData) {
            setTitle(fileData.title);
            setDescription(fileData.description);
        }
    }, [fileData]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setFile(event.target.files[0]);
            setFileName(event.target.files[0].name);
        }
    };

    const handleSave = () => {
        setLoading(true);
        onSave(file, title, description);
    };

    return (
        <Stack
            sx={{
                bgcolor: "white",
                borderRadius: "5px",
                boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.16)",
                width: "280px",
                padding: "20px 20px 15px 20px",
                boxSizing: "border-box",
            }}
        >
            <Stack gap={"15px"}>
                <Typography
                    sx={{ font: "normal normal 900 18px/23px Source Serif Pro" }}
                >
                    {fileData ? "Edit File" : "Connect a File"}
                </Typography>
                <Typography
                    sx={{ font: "normal normal 200 13px/16px Source Serif Pro" }}
                >
                    Connect a file to your agenda and call to have it during your meeting
                </Typography>
            </Stack>

            <Stack sx={{ marginTop: "17px", gap: "10px" }}>
                <Stack gap={"5px"}>
                    <Typography
                        sx={{ font: "normal normal 200 10px/13px Source Serif Pro" }}
                    >
                        File Name
                    </Typography>
                    <InputBase
                        placeholder="Give your file a name"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        sx={{
                            height: "30px",
                            border: "1px solid #E9EDF2",
                            borderRadius: "5px",
                            padding: "0px 5px",
                            font: "normal normal 900 13px/16px Source Serif Pro",
                        }}
                    />
                </Stack>
                <Stack gap={"5px"}>
                    <Typography
                        sx={{ font: "normal normal 200 10px/13px Source Serif Pro" }}
                    >
                        File Details
                    </Typography>
                    <EditableTextArea
                        minRows={15}
                        maxRows={15}
                        value={description}
                        onTextChange={(newValue) => setDescription(newValue)}
                        style={{
                            font: "normal normal 400 13px/16px Source Serif Pro",
                            border: "1px solid #E9EDF2",
                            borderRadius: "5px",
                            padding: "5px",
                            height: "240px",
                            maxHeight: "240px",
                        }}
                        placeholder="Add some details to display alongside your file or press BEBA AI to get a brief summary of your file."
                    />
                </Stack>
            </Stack>

            <Button
                variant="text"
                component="label"
                sx={{
                    height: "30px",
                    border: "1px solid #E9EDF2",
                    borderRadius: "5px",
                    padding: "0px 10px",
                    flexDirection: "row",
                    justifyContent: "start",
                    textTransform: "none",
                    color: "black",
                    marginTop: "15px",
                }}
            >
                <Typography
                    sx={{
                        font: "normal normal 900 13px/16px Source Serif Pro",
                        color: adjustHexColor("#000000", 30),
                    }}
                >
                    {fileName || "Search & Choose File"}
                </Typography>
                <input type="file" hidden onChange={handleFileChange} />
            </Button>

            <Stack
                sx={{ flexDirection: "row", justifyContent: "end", marginTop: "20px" }}
            >
                <MediumButtonSmall
                    label="Save Now"
                    types={ButtonType.Button}
                    onClick={handleSave}
                    disabled={loading}
                />
            </Stack>
        </Stack>
    );
};

export default ConnectFilePopup;