import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { DoneIcon, StreamIconBearishOSIconPng } from "../../../../../images";
import { AvatarNormal, EventIconButton } from "../../../../common";
import { SnoozeButton } from "../../../Message/components";
import { ThreeDotMenuTraxCommand } from "../ThreeDotMenuTraxCommand";
import { BarCommandControlTraxTagsEnum } from "./BarCommandControlTraxType";
import { ThreeDotMenuTraxCommandUsers } from "../ThreeDotMenuTraxCommandUsers";
import styles from "./BarCommandControlTrax.module.css";
import { useTranslation } from "react-i18next";

interface BarCommandControlTraxProps {
  tag: BarCommandControlTraxTagsEnum;
  dotMenuType: "traxCommand" | "traxCommandUsers";
}

const BarCommandControlTrax: React.FC<BarCommandControlTraxProps> = ({
  tag,
  dotMenuType,
}) => {
  const { t } = useTranslation();
  const getTagLabel = (): { tagName: string; translatedWord: string } => {
    switch (tag) {
      case BarCommandControlTraxTagsEnum.Complaince:
        return {
          tagName: "complaince",
          translatedWord: "TRAX.DRAWER_LABELS.COMPLIANCE",
        };
      case BarCommandControlTraxTagsEnum.Development:
        return {
          tagName: "development",
          translatedWord: "TRAX.DRAWER_LABELS.DEVELOPMENT",
        };
      case BarCommandControlTraxTagsEnum.Expense:
        return {
          tagName: "expense",
          translatedWord: "TRAX.DRAWER_LABELS.EXPENSES",
        };
      case BarCommandControlTraxTagsEnum.Payroll:
        return {
          tagName: "payroll",
          translatedWord: "TRAX.DRAWER_LABELS.PAYROLL",
        };
      case BarCommandControlTraxTagsEnum.Performance:
        return {
          tagName: "performance",
          translatedWord: "TRAX.DRAWER_LABELS.PERFORMANCE",
        };
      case BarCommandControlTraxTagsEnum.TimeOff:
        return {
          tagName: "timeOff",
          translatedWord: "TRAX.TIME_OFF",
        };
      case BarCommandControlTraxTagsEnum.Timesheet:
        return {
          tagName: "timesheet",
          translatedWord: "TRAX.TIME_SHEET",
        };
    }
  };

  return (
    <Stack className={styles.barCommandControlTrax}>
      {/* User icon with online/offline-status and name here */}
      <Stack className={styles.barCommandControlTrax__user}>
        <AvatarNormal size={30} username="A B" />

        <Typography className={styles.barCommandControlTrax__username}>
          User Name here
        </Typography>
      </Stack>

      {/* Heighlighter[@tags] and message text here */}
      <Stack className={styles.barCommandControlTrax__content}>
        <Stack className={styles.barCommandControlTrax__contentRow}>
          <Typography className={styles.barCommandControlTrax__tagText}>
            @{t(getTagLabel().translatedWord)}
          </Typography>
          <Stack
            sx={{
              flexGrow: 1,
              //   maxHeight: "140px",
              width: "calc(100% - 80px)",
            }}
          >
            <Typography className={styles.barCommandControlTrax__message}>
              Example text and messages will appear here for you to see with
              ease Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Unde, aut?
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {/* Buttons and company icons */}
      <Stack className={styles.barCommandControlTrax__actions}>
        <Box
          className={styles.barCommandControlTrax__icon}
          component={"img"}
          alt="logo"
          src={StreamIconBearishOSIconPng}
        />

        <EventIconButton
          buttonTitle={t('TRAX.STATUS_TAB.APPROVE')}
          icon={DoneIcon}
          iconColor="#008D4C"
        />

        <SnoozeButton onSelect={(value:any) => null} />

        {dotMenuType === "traxCommand" ? (
          <ThreeDotMenuTraxCommand />
        ) : (
          <ThreeDotMenuTraxCommandUsers />
        )}
      </Stack>
    </Stack>
  );
};

export default BarCommandControlTrax;
