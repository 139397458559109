import { Popover, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { BilledIcon } from "../../../../../images";
import { AcceptRejectButtonGroup } from "../../../../common";
import { ReciptListview } from "./view";
import { ExpenseStatusType } from "../TraxApprovedBadge/TraxApprovedBadge";
import { ReceiptRejected } from "../ReceiptRejected";

export type ExpenseItemType = {
  _id: string;
  itemName: string;
  price: number;
  tax: number;
  total: number;
  status?: ExpenseStatusType;
};

const Dummy_ExpenseItems: ExpenseItemType[] = [
  {
    _id: "1ew",
    itemName:
      "item name here jsdfklasdj; flksdj flkajsd;kfasj df;ksdjf asdfjasfjsdlkf asdk",
    price: 200,
    tax: 10,
    total: 210,
    status: "pending",
  },
  {
    _id: "2wdd1",
    itemName: "item name here",
    price: 400,
    tax: 20,
    total: 420,
    status: "approved",
  },
  {
    _id: "2dd21",
    itemName: "item name here",
    price: 500,
    tax: 25,
    total: 525,
    status: "pending",
  },
  {
    _id: "7yhs",
    itemName: "item name here",
    price: 423,
    tax: 21.15,
    total: 444.15,
    status: "pending",
  },
  {
    _id: "1sse",
    itemName: "item name here",
    price: 32,
    tax: 1.6,
    total: 33.6,
    status: "rejected",
  },
  {
    _id: "13ewew",
    itemName: "item name here",
    price: 230,
    tax: 11.5,
    total: 241.5,
    status: "approved",
  },
  {
    _id: "1fsw",
    itemName: "item name here",
    price: 3200,
    tax: 160,
    total: 3360,
    status: "rejected",
  },
  {
    _id: "1werr",
    itemName: "item name here",
    price: 5400,
    tax: 270,
    total: 5670,
    status: "pending",
  },
  {
    _id: "1224d",
    itemName: "item name here",
    price: 290,
    tax: 14.5,
    total: 304.5,
    status: "pending",
  },
  {
    _id: "1sswe",
    itemName: "item name here",
    price: 2000,
    tax: 100,
    total: 2100,
    status: "pending",
  },
];

interface ReciptReviewProps {}

const ReciptReview: React.FC<ReciptReviewProps> = ({}) => {
  const [selectedList, setSelectedList] = useState<ExpenseItemType[]>([]);
  const [showRejectedMenu, setShowRejectedMenu] = useState(false);
  const [rejectedList, setRejectedList] = useState<ExpenseItemType[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const rejectHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (selectedList.length > 0) {
      setRejectedList(selectedList);
      setAnchorEl(event.currentTarget);
      setShowRejectedMenu(true);
    }
  };

  const handleClosePopover = () => {
    setShowRejectedMenu(false);
    setAnchorEl(null);
  };

  return (
    <Stack
      sx={{
        width: "525px",
        height: "295px",
        borderRadius: "5px",
        bgcolor: "white",
        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
        padding: "18px 15px 15px 10px",
        gap: "15px",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
          <BilledIcon w={15} />

          <Typography
            sx={{ font: "normal normal 300 12px/15px Source Serif Pro" }}
          >
            Expense Line Items
          </Typography>
        </Stack>

        <AcceptRejectButtonGroup
          onAcceptClick={() => null}
          onRejectClick={rejectHandler}
        />
      </Stack>

      <ReciptListview
        list={Dummy_ExpenseItems}
        selectedList={selectedList}
        setSelectedList={setSelectedList}
        selectedTotal={selectedList.reduce((sum, item) => sum + item.total, 0)}
      />

      <Popover
        open={showRejectedMenu}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ReceiptRejected rejectedList={rejectedList} />
      </Popover>
    </Stack>
  );
};

export default ReciptReview;
