import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import {
  AllDirArrowCircleIcon,
  TeamMemberIcon,
} from "../../../../../../../images";

interface HeaderViewProps {
  onBackButtonClick: () => void;
  onShareButtonClick: () => void;
}

const HeaderView: React.FC<HeaderViewProps> = ({
  onBackButtonClick,
  onShareButtonClick,
}) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        boxSizing: "border-box",
        padding: "0px 17px",
        height: "55px",
        flexShrink: 0,
        borderBottom: "1px solid #E9EDF2",
      }}
    >
      <Button
        onClick={() => onBackButtonClick()}
        sx={{
          minWidth: 0,
          flexDirection: "row",
          alignItems: "center",
          padding: "4px 6px",
          textTransform: "none",
          color: "black",
          gap: "5px",
        }}
      >
        <AllDirArrowCircleIcon direction="left" w={15} color="black" />
        <Typography
          sx={{ font: "normal normal 300 11px/13px Source Serif Pro" }}
        >
          Close
        </Typography>
      </Button>

      <Button
        onClick={() => onShareButtonClick()}
        sx={{
          minWidth: 0,
          flexDirection: "row",
          alignItems: "center",
          padding: "4px 6px",
          textTransform: "none",
          color: "black",
          gap: "5px",
        }}
      >
        <TeamMemberIcon w={15} />
        <Typography
          sx={{ font: "normal normal 300 11px/13px Source Serif Pro" }}
        >
          Share
        </Typography>
      </Button>
    </Stack>
  );
};

export default HeaderView;
