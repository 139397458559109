import { Stack, Typography } from "@mui/material";
import React from "react";

interface LastPaymentViewProps {
  currencySymbol: string;
  ammount: number;
  date: string;
}

const LastPaymentView: React.FC<LastPaymentViewProps> = ({
  ammount,
  currencySymbol,
  date,
}) => {
  return (
    <Stack
      sx={{
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography sx={{ font: "normal normal 900 13px/16px Source Serif Pro" }}>
        {currencySymbol}
        {ammount}
      </Typography>

      <Typography sx={{ font: "normal normal 400 13px/16px Source Serif Pro" }}>
        on {date}
      </Typography>
    </Stack>
  );
};

export default LastPaymentView;
