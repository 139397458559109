import {Stack} from "@mui/material";
import React from "react";

import {
    SlackPng,
    StreamIconBearishOSIconPng,
} from "../../../../../../images";
import {MessageNameCard} from "../../../../MessageNameCard";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../../redux/store";
import Loader from "../../../../Loader/Loader";
import {AuthState} from "../../../../../../redux";
import {MESSAGE_ENUMS} from "../../../../../pages/Message/constant";
import {useParams} from "react-router-dom";

interface DefaultQuickMsgViewProps {
    channelsArray?: any;
}

const DefaultQuickMsgView: React.FC<DefaultQuickMsgViewProps> = ({
                                                                     channelsArray,
                                                                 }) => {
    const {user} = useSelector((state: RootState) => state.auth as AuthState);

    const param = useParams();

    if (!channelsArray) return <Loader/>;

    return (
        <Stack sx={{width: "100%", gap: "5px"}}>
            {Object.values(channelsArray || {}).map((data: any, index: number) => {
                if (data.isPinned) return null;
                return (
                    <MessageNameCard
                        key={index}
                        lastMessage={data?.lastMessage || ""}
                        channelDetails={data}
                        userData={user}
                        companyImgSrc={
                            data.channelType === MESSAGE_ENUMS.SLACK
                                ? SlackPng
                                : StreamIconBearishOSIconPng
                        }
                        userName={data.displayName}
                        channelId={data?._id}
                        channelName={data.displayName}
                        isSelected={data._id === param?.id}
                        userIconSrc={data?.avatar || ""}
                        isUserActive={data?.status === "online" || undefined}
                    />
                );
            })}
        </Stack>
    );
};

export default DefaultQuickMsgView;
