import React, { useState, useEffect, useRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import CompanyDetails from "../../pages/ProfileMapping/components/CompanyNameAutocomplete/CompanyDetails";

interface CompanyOption {
  claimed: boolean;
  name: string | null;
  domain: string;
  icon: string | null;
  brandId: string; // Unique identifier
}

interface Props {
  companyAvalibilityCheckLoader: boolean;
  companyOptions?: any[];
  handleCompanyRegistryList: (searchValue: string) => void; // Function to handle API call
  initialSearchValue?: string; // Optional initial value
  onCompanySelect: (company: any) => void; // Callback for when a company is selected
}

const CompanyNameAutocomplete: React.FC<Props> = ({
  companyAvalibilityCheckLoader,
  companyOptions = [],
  handleCompanyRegistryList,
  initialSearchValue = "",
  onCompanySelect,
}) => {
  const [inputValue, setInputValue] = useState<string>(initialSearchValue);
  const [value, setValue] = useState<CompanyOption | null>(null);
  const [selectedByUser, setSelectedByUser] = useState<boolean>(false);

  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setInputValue(initialSearchValue);
    setValue(
      companyOptions.find((option) => option.name === initialSearchValue) ||
        null
    );
  }, [initialSearchValue, companyOptions]);

  const handleInputChange = (
    event: React.SyntheticEvent,
    newInputValue: string
  ) => {
    setInputValue(newInputValue);
    setValue(null);
    setSelectedByUser(false);
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      handleCompanyRegistryList(newInputValue);
    }, 1000);
  };

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: CompanyOption | null
  ) => {
    if (newValue) {
      setInputValue(newValue.name || "");
      setValue(newValue);
      onCompanySelect(newValue);
    }
  };

  return (
    <Autocomplete
      disablePortal
      id="company-name-autocomplete"
      options={companyOptions}
      loading={companyAvalibilityCheckLoader}
      loadingText="Loading..."
      noOptionsText="Type your company name"
      getOptionLabel={(option: CompanyOption) => option.name || ""}
      isOptionEqualToValue={(option: CompanyOption, value: CompanyOption) =>
        option.brandId === value.brandId
      }
      sx={{ width: "100%" }}
      value={value}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleChange}
      onFocus={() => setSelectedByUser(true)}
      onBlur={() => setSelectedByUser(false)}
      renderOption={(props, option) => (
        <li {...props} key={option.brandId}>
          <CompanyDetails company={option} />
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Company name"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {companyAvalibilityCheckLoader && (
                  <CircularProgress color="inherit" size={20} />
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default CompanyNameAutocomplete;
