import { Stack } from "@mui/material";
import React from "react";
import {
  BreakArea,
  OverTimeArea,
  StartTimeArea,
  WorkTimeArea,
} from "./InternalComponents";
import { BreakListInterface } from "../../../TimeCardTable";
import { AttendanceStatusType } from "../../../CrmStatusBadge";
import {
  calculateCardLength,
  calculateCardStartPoint,
  calculateTimeDifference,
  calculateTotalBreakTime,
  calculateWorkingHours,
  generateDataList,
  to12HourFormat,
} from "./TimeCardUtils";

interface TimeDetailsCardProps {
  workType: AttendanceStatusType;
  workStartTime?: string;
  workStopTime?: string;
  breakList?: BreakListInterface[];
  overtime?: BreakListInterface;
}

const TimeDetailsCard: React.FC<TimeDetailsCardProps> = ({
  workType,
  breakList,
  overtime,
  workStartTime = "",
  workStopTime = "",
}) => {
  const cardHeight = "40px";

  const calculatedWorkEndTime = overtime ? overtime.start : workStopTime!;
  const firstStop: string =
    breakList?.[0].start || overtime?.start || workStopTime!;
  const list: { type: "work" | "break"; startTime: string; endTime: string }[] =
    generateDataList({
      workStartTime: breakList?.[0].start!,
      workStopTime: calculatedWorkEndTime,
      breakList: breakList!,
    });

  console.log(calculatedWorkEndTime);

  return (
    <Stack
      sx={{
        marginLeft: calculateCardStartPoint(workStartTime),
        height: cardHeight,
        flexDirection: "row",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          "& > *:last-child": {
            borderRadius: "0px 5px 5px 0px",
          },
        }}
      >
        <StartTimeArea
          checkInTime={to12HourFormat(workStartTime)}
          checkoutTime={to12HourFormat(workStopTime!)}
          totalBreak={calculateTotalBreakTime(breakList || [])}
          cardtype={workType}
          cardWidth={calculateCardLength(workStartTime, firstStop)}
          totalWorkTime={calculateWorkingHours(
            workStartTime,
            workStopTime,
            calculateTotalBreakTime(breakList || []),
            overtime && overtime.start && overtime.end
              ? calculateTimeDifference(overtime.start, overtime.end)
              : ""
          )}
        />

        {list.map((item, index) =>
          item.type === "break" ? (
            <BreakArea
              key={index}
              totalTime={calculateTimeDifference(item.startTime, item.endTime)}
              cardWidth={calculateCardLength(item.startTime, item.endTime)}
            />
          ) : (
            <WorkTimeArea
              key={index}
              totalTime={calculateTimeDifference(item.startTime, item.endTime)}
              cardWidth={calculateCardLength(item.startTime, item.endTime)}
            />
          )
        )}
      </Stack>
      {overtime && (
        <OverTimeArea
          totalTime={calculateTimeDifference(overtime.start, overtime.end)}
          cardWidth={calculateCardLength(overtime.start, overtime.end)}
        />
      )}
    </Stack>
  );
};

export default TimeDetailsCard;
