import { Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { DateAndTimePicker } from "../../../../common";
import { useTranslation } from "react-i18next";

interface ScheduleMessageProps {
  onSchedule?:any;
  styles?:any;
}

const ScheduleMessage: React.FC<ScheduleMessageProps> = ({ onSchedule ,styles}) => {
    const {t}=useTranslation()
  const [selectedDataAndTime, setSelectedDateAndTime] = useState<any>(null);

  const handleScheduleClick = () => {
    if (selectedDataAndTime) {
      onSchedule(selectedDataAndTime);
    }
  };
  return (
    <Stack
      sx={{
        width: "225px",
        height: "98px",
        padding: "10px 10px 0 10px",
        gap: "10px",
        bgcolor: "white",
        borderRadius: "5px",
        zIndex:"1",
        boxShadow: "0 0px 7px rgba(0, 0, 0, 0.2),0 1px 0px rgba(0, 0, 0, 0.11),0 2px 2px rgba(0, 0, 0, 0.1),0 2px 2px rgba(0, 0, 0, 0.1)",
        ...styles
      }}
    >
      <Typography sx={{ font: "normal normal 400 13px/16px Source Serif Pro" }}>
          {t('MESSAGE.SCHEDULE_YOUR_MESSAGE')}
      </Typography>

      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
          font: "normal normal 300 12px/15px Source Serif Pro",
        }}
      >
        <DateAndTimePicker  onSelect={(time:any) => setSelectedDateAndTime(time)}  />
      </Stack>

      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "end",
          marginTop: "-2px",
        }}
      >
        <Button variant="text" sx={{ p: "2px 5px", textTransform: "none" }}>
          <Typography
            sx={{
              font: "normal normal 700 13px/16px Source Serif Pro",
              color: "#775EE2",
            }}
            onClick={handleScheduleClick}
          >
              {t('MESSAGE.SCHEDULE_NOW')}
          </Typography>
        </Button>
      </Stack>
    </Stack>
  );
};

export default ScheduleMessage;