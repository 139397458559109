import React from "react";
import { TextLabelDropdown } from "../../common";
import {
  PayFrequencyMenuOptions,
  PayFrequencyMode,
} from "./PayFrequencyMenuType";

interface PayFrequencyMenuProps {
  initialValue: PayFrequencyMode;
  onSelect: (value: string) => void;
}

const PayFrequencyMenu: React.FC<PayFrequencyMenuProps> = ({
  initialValue,
  onSelect,
}) => {
  return (
    <TextLabelDropdown
      menuLabel="Pay Frequency"
      initialValue={initialValue}
      options={PayFrequencyMenuOptions}
      onSelect={(value) => onSelect(value)}
    />
  );
};

export default PayFrequencyMenu;
