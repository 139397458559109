import { Stack } from "@mui/material";
import React, { lazy, Suspense } from "react";
import {
  a11yProps,
  StyledTab,
  TabPanel,
  TabsWithState,
} from "../../../../common/CardTabsWithoutIcon";
import {
  ActivtiyIcon,
  BilledIcon,
  CloseDayIcon,
  DeadlineIcon,
  PayrollIcon,
} from "../../../../../images";
import { TabLoader } from "../../../../common";

interface TraxCenterTabsProps {}

const LazyOverviewTab = lazy(() => import("./tabs/OverviewTab/OverviewTab"));
const LazyTimeAndAttendanceTab = lazy(
  () => import("./tabs/TimeAndAttendanceTab/TimeAndAttendanceTab")
);
const LazyExpensesTab = lazy(() => import("./tabs/ExpensesTab/ExpensesTab"));
const LazyPayrollTab = lazy(() => import("./tabs/PayrollTab/PayrollTab"));
const LazyBenefitsTab = lazy(() => import("./tabs/BenefitsTab/BenefitsTab"));

const tabs = [
  {
    label: "Overview",
    icon: <ActivtiyIcon w={20} />,
    content: <LazyOverviewTab />,
  },
  {
    label: "Time & Attendance",
    icon: <DeadlineIcon w={20} />,
    content: <LazyTimeAndAttendanceTab />,
  },
  {
    label: "Expenses",
    icon: <BilledIcon w={20} />,
    content: <LazyExpensesTab />,
  },
  {
    label: "Payroll",
    icon: <PayrollIcon w={20} />,
    content: <LazyPayrollTab />,
  },
  {
    label: "Benefits",
    icon: <CloseDayIcon w={20} />,
    content: <LazyBenefitsTab />,
  },
];

const TraxCenterTabs: React.FC<TraxCenterTabsProps> = ({}) => {
  const [tabValue, setTabValue] = React.useState(0);
  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        gap: "20px",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "24px",
          borderBottom: "1px solid #E9EDF2",
          justifyContent: "space-between",
        }}
      >
        <Stack direction="row" gap="25px" alignItems={"center"}>
          <TabsWithState
            ariaLabel="teax center tabs"
            value={tabValue}
            onChange={(event, newValue) => setTabValue(newValue)}
            tabIndicatorColor="#00767B"
            sx={{
              maxWidth: "max-content",
              gap: "25px",
              borderBottom: "0px solid #E9EDF2",
              "& .css-1aquho2-MuiTabs-indicator": {
                bottom: "0px !important",
              },
            }}
          >
            {tabs.map((tab, index) => (
              <StyledTab
                icon={tab.icon}
                style={{
                  font: "normal normal 300 13px/16px Source Serif Pro",
                  padding: "10px 0px",
                }}
                key={index}
                // disableRipple
                label={tab.label}
                {...a11yProps(index)}
              />
            ))}
          </TabsWithState>
        </Stack>
      </Stack>

      {tabs.map((tab, index) => (
        <TabPanel
          style={{ display: "contents" }}
          key={index}
          value={tabValue}
          index={index}
        >
          <Suspense fallback={<TabLoader />}>{tab.content}</Suspense>
        </TabPanel>
      ))}
    </Stack>
  );
};

export default TraxCenterTabs;
