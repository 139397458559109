import { Button, ButtonProps, Typography } from "@mui/material";
import React from "react";
import { DeviceStatusType } from "../../ScreenRecordingLive";

interface IconActionButtonProps extends ButtonProps {
  icon: React.ElementType;
  buttonLabel: string;
  buttonState: DeviceStatusType;
  onButtonClick: (value: DeviceStatusType) => void;
}

const IconActionButton: React.FC<IconActionButtonProps> = ({
  buttonLabel,
  buttonState,
  onButtonClick,
  icon,
  sx,
  ...props
}) => {
  const getIconColor = () => {
    switch (buttonState) {
      case "unTouch":
        return "black";
      case "active":
        return "#21B481";
      case "inActive":
        return "#FF0000";
    }
  };
  return (
    <Button
      onClick={() => {
        if (buttonState === "unTouch" || buttonState === "inActive") {
          onButtonClick("active");
        }
        if (buttonState === "active") {
          onButtonClick("inActive");
        }
      }}
      sx={{
        textTransform: "none",
        width: "50px",
        minWidth: 0,
        minHeight: 0,
        height: "65px",
        boxSizing: "border-box",
        borderRadius: "5px",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        flexShrink: 0,
        ...sx,
      }}
      {...props}
    >
      {React.createElement(icon, { w: 25, color: getIconColor() })}
      <Typography
        sx={{
          font: "normal normal 400 13px/16px Source Serif Pro",
          color: "black",
        }}
      >
        {buttonLabel}
      </Typography>
    </Button>
  );
};

export default IconActionButton;
