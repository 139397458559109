import React, { useState } from "react";
import { CheckboxMedium, ColoredBadge } from "../../../../common";
import { Divider, Popover, Stack, SxProps, Typography } from "@mui/material";
import { BadgeDropdownMenuType } from "./BadgeDropdowType";

interface BadgeDropdownProps {
  menuLabel: string;
  menuWidth?: string;
  initialValue: string;
  onSelectionChange: (value: string) => void;
  options: BadgeDropdownMenuType[];
  menuContainerSx?: SxProps;
  badgeSx?: SxProps;
  badgeFont?: string;
  hideMenuLabelUnderline?: boolean;
}

const BadgeDropdown: React.FC<BadgeDropdownProps> = ({
  initialValue,
  onSelectionChange,
  options,
  menuContainerSx,
  menuLabel,
  menuWidth = "160px",
  badgeFont = "normal normal 400 13px/16px Source Serif Pro",
  badgeSx,
  hideMenuLabelUnderline = false,
}) => {
  const [selectedData, setSelectedData] = useState<string>(initialValue);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "badge dropdpwn-menu-popover" : undefined;

  const getBadgeData = (): {
    label: string;
    badgeLabel: string;
    bgColor: string;
    fontColor: string;
    badgeOpacity: number;
  } => {
    const item = options.find((d) => d.value === selectedData);
    if (!item || item.divider) {
      return {
        label: "undefined",
        badgeLabel: "undefined",
        bgColor: "#FF0000",
        fontColor: "black",
        badgeOpacity: 65,
      };
    }

    return {
      label: item.label || "undefined",
      badgeLabel: item.badgeLabel || item.label || "undefined",
      bgColor: item.BadgeColor || "#FF0000",
      fontColor: item.fontColor || "black",
      badgeOpacity: item.BadgeColorOpacity || 65,
    };
  };

  const changeHandler = (value: string) => {
    setSelectedData(value);
    onSelectionChange(value);
    handleClose();
  };

  return (
    <div>
      <span onClick={handleButtonClick} style={{ cursor: "pointer" }}>
        <ColoredBadge
          ColoredBadgePadding="0px"
          title={getBadgeData().badgeLabel}
          sx={{
            width: "75px",
            height: "20px",
            boxSizing: "border-box",
            ...badgeSx,
          }}
          bgColor={getBadgeData().bgColor}
          bgColorOpacity={getBadgeData().badgeOpacity}
          fontColor={getBadgeData().fontColor}
          ColoredBadgeFont={badgeFont}
        />
      </span>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            borderRadius: "5px",
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            padding: 0,
            marginTop: "15px",
            scrollbarWidth: "none",
            backgroundColor: "white",
          },
        }}
      >
        <Stack
          sx={{
            width: menuWidth,
            boxSizing: "border-box",
            borderRadius: "5px",
            bgcolor: "#fff",
            padding: "10px 10px 10px 10px",
            gap: "7px",
            maxHeight: "500px",
            border: "1px solid #E9EDF2",
            ...menuContainerSx,
          }}
        >
          <Typography
            sx={{
              paddingBottom: "9px",
              font: "normal normal 300 15px/19px Source Serif Pro",
              borderBottom: hideMenuLabelUnderline
                ? "0px solid #E9EDF2 "
                : "1px solid #E9EDF2",
            }}
          >
            {menuLabel}
          </Typography>
          <Stack sx={{ gap: "10px", overflow: "auto", scrollbarWidth: "none" }}>
            {options.map((item) =>
              item.divider ? (
                <Divider key={item._id} />
              ) : (
                <Stack
                  onClick={() => changeHandler(item.value)}
                  key={item._id}
                  sx={{
                    flexDirection: "row",
                    alignItems: "start",
                    gap: "10px",
                    cursor: "pointer",
                  }}
                >
                  <CheckboxMedium checked={selectedData === item.value} />
                  <Typography
                    sx={{
                      font: "normal normal 300 15px/19px Source Serif Pro",
                    }}
                  >
                    {item.label}
                  </Typography>
                </Stack>
              )
            )}
          </Stack>
        </Stack>
      </Popover>
    </div>
  );
};

export default BadgeDropdown;
