import React from "react";
import { ColoredBadge } from "../../../../common";
import { ExpensesBadgesDataType } from "../ExpensesBadgesMenu/ExpensesBadgesMenuType";

interface ExpensesBadgesProps {
  badgeData: ExpensesBadgesDataType;
}

const ExpensesBadges: React.FC<ExpensesBadgesProps> = ({ badgeData }) => {
  return (
    <ColoredBadge
      ColoredBadgePadding="0px"
      title={badgeData.badgeLabel}
      sx={{ width: "80px", height: "20px", boxSizing: "border-box" }}
      bgColor={badgeData.badgeColor}
      bgColorOpacity={65}
      fontColor={badgeData.fontColor}
      ColoredBadgeFont={"normal normal 400 12px/15px Source Serif Pro"}
    />
  );
};

export default ExpensesBadges;
