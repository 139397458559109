import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GreenDropdownIcon, XCircleIcon } from "../../../images";
import { AccessView, GraphView, InsightsView, LinksView } from "./View";
import { useDispatch } from "react-redux";
import {
  getDetailsSliderState,
  getOneFile__api,
  getOneFile__BOX__api,
} from "../../../redux";

interface ItemMainInsightsProps {
  closeDetails?: () => void;
  selectedFileData: any;
}

const ItemMainInsights: React.FC<ItemMainInsightsProps> = ({
  closeDetails,
  selectedFileData,
}) => {
  const dispatch = useDispatch();
  const [fileData, setFileData] = useState<any | null>(null);
  const handleStateToggle = async () => {
    dispatch(getDetailsSliderState("Main Details"));
  };

  // Handle fetching file data
  const handleGetOneFile = async (fileId: string, platform?: string) => {
    if (!fileId) return;

    if (!platform) {
      platform = "BearishOS";
    }

    try {
      let action;

      // Conditional API call based on provider
      if (platform === "Box") {
        action = getOneFile__BOX__api(fileId);
      } else {
        action = getOneFile__api(fileId);
      }
      const fileResponse: any = await dispatch(action);

      if (fileResponse?.success && fileResponse?.data) {
        setFileData(fileResponse.data);
      } else {
        setFileData(null); // Reset to null if not found
      }
    } catch (error) {
      console.error("Error fetching file data:", error);
      setFileData(null); // Reset to null on error
    }
  };

  // Effect to get the file data when selectedFileData changes
  useEffect(() => {
    if (selectedFileData && selectedFileData?._id) {
      handleGetOneFile(selectedFileData?._id, selectedFileData?.platform);
    }
  }, [selectedFileData]);
  return (
    <Stack
      height="35%"
      overflow="hidden"
      maxHeight="35%"
      sx={{
        "&::-webkit-scrollbar": {
          width: "0 !important", // hide scrollbar on Chrome, Safari, etc.
        },
        scrollbarWidth: "none", // hide scrollbar on Firefox
        overflowX: "hidden",
        overflowY: "scroll",
        width: "385px",
        height: "84vh",
        bgcolor: "white",
        padding: "15px 15px 0px 15px",
        boxSizing: "border-box",
      }}
    >
      {/* ----------------    card title + Back button + close button --------------- */}
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "10px",
          alignItems: "start",
        }}
      >
        <Typography
          sx={{
            font: "normal normal 400 14px/18px Source Serif Pro",
            paddingBottom: "5px",
            borderBottom: "1px solid #E9EDF2",
          }}
        >
          Item Insights
        </Typography>

        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "15px" }}>
          <IconButton
            sx={{ p: 0, gap: "5px", alignItems: "center" }}
            disableRipple
            onClick={() => handleStateToggle()}
          >
            <GreenDropdownIcon
              open
              sx={{ transform: "rotate(180deg)", height: "11px" }}
            />
            <Typography
              sx={{ font: "normal normal 400 13px/16px Source Serif Pro" }}
            >
              Back
            </Typography>
          </IconButton>

          <Tooltip title="close" placement="top" arrow>
            <IconButton
              onClick={() => closeDetails && closeDetails()}
              sx={{ p: 0 }}
              disableRipple
            >
              <XCircleIcon w={15} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
      {/* ----------------    card title + Back button + close button container end here --------------- */}

      {/*---------    scrollable container here  --------- */}

      <Stack
        sx={{
          flex: 1,
          overflowY: "scroll",
          scrollbarWidth: "none",
          gap: "20px",
          paddingBottom: "20px",
        }}
      >
        <InsightsView
          linkCount={fileData?.link?.length || 0}
          shareCount={fileData?.totalShares || 0}
          viewCount={fileData?.totalViews || 0}
        />
        <AccessView />
        <GraphView fileData={fileData} />
        <LinksView fileData={fileData} />
      </Stack>
      {/*---------    scrollable container end here  --------- */}
    </Stack>
  );
};

export default ItemMainInsights;
