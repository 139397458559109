import React from "react";
import {Stack} from "@mui/material";
import {AddButttonContainer, DetailsArea, HeaderView, VideoNameAndPreviewArea,} from "./view";
import {ButtonSmall, SmallButtonColor} from "../../../../common";
import {FolderFileIcon, ProjectIcon, WorkspaceCIcon,} from "../../../../../images";
import {SearchWorkSpaceDropdown} from "../../../../common/SearchWorkSpaceDropdown";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../redux/store";
import {WorkspaceState} from "../../../../../redux";
import SearchProjectsDropdown from "../../../../common/SearchProjectsDropdown/SearchProjectsDropdown";
import {VideoCenterState} from "../../../../../redux/Slice/videoCenter/VideoCenterTypes";
import {ConnectedProjectChipButton, ConnectedWorkspaceChipButton} from "../../../Calls/components";

interface PostScreenRecordingDetailsProps {
  saveRecordingDetails: any;
  screenVideoStream: string | null;
  handleSelectedWorkSpace?: any;
  handleSelectedProjects?: any;
  setRecordingTitle: any;
  setRecordingDescription: any;
  handleBackButtonClick: any;
    handleOnShareButtonClick?: any;
}

const PostScreenRecordingDetails: React.FC<
  PostScreenRecordingDetailsProps
> = ({
       saveRecordingDetails,
       screenVideoStream,
       handleSelectedWorkSpace,
       handleBackButtonClick,
       handleSelectedProjects,
       setRecordingTitle,
       setRecordingDescription,
                                           handleOnShareButtonClick
     }) => {
  const [workspaceArray, setWorkspaceArray] = React.useState<any>([]);
  const [projectsArray, setProjectsArray] = React.useState<any>([]);
  const workspaceState = useSelector(
      (state: RootState) => state.workspace as WorkspaceState
  );

  const {projects} = useSelector(
      (state: RootState) => state.videoCenter as VideoCenterState
  );

  const handleSaveRecordingDetails = () => {
    saveRecordingDetails()
  }

  const handleSelectProjects = (selectedOption: any) => {
    const isPresent = projectsArray.some((project: any) => project.id === selectedOption.id);
    if (!isPresent) {
      handleSelectedProjects(selectedOption);
      setProjectsArray([...projectsArray, selectedOption]);
    }
  }

  const handleSelectWorkSpace = (selectedOption: any) => {
    handleSelectedWorkSpace(selectedOption);
    setWorkspaceArray([...workspaceArray, selectedOption]);
  }

  const handleSetTitleValue = (value: string) => {
    setRecordingTitle(value);
  }

  const handleSetDescriptionValue = (value: string) => {
    setRecordingDescription(value);
  }

  return (
    <Stack
      sx={{
        width: "550px",
        height: "100%",
        borderRadius: "5px 0px 0px 5px",
        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
      }}
    >
      {/* header container */}
      <HeaderView
          onBackButtonClick={handleBackButtonClick}
        onShareButtonClick={handleOnShareButtonClick}
      />
      {/* body container */}
      <Stack
        sx={{
          overflow: "auto",
          scrollbarWidth: "none",
          padding: "20px",
          boxSizing: "border-box",
          gap: "40px",
        }}
      >

        <VideoNameAndPreviewArea videoStream={screenVideoStream} videoTitle={handleSetTitleValue}/>

        <DetailsArea setVideoDescription={handleSetDescriptionValue} />

        <Stack sx={{ gap: "25px" }}>
          <AddButttonContainer icon={ProjectIcon} label="Connected Projects">
            <SearchProjectsDropdown
                onSelect={handleSelectProjects}
                options={projects}
            />
          </AddButttonContainer>
          {projectsArray &&
              projectsArray?.map(
                  (project: any, index: any) => (
                      <ConnectedProjectChipButton
                          key={index}
                          project={project}
                      />
                  )
              )}

          <AddButttonContainer
            icon={WorkspaceCIcon}
            label="Connected Workspaces"
          >
            <SearchWorkSpaceDropdown
                alwaysOpen
                onSelect={handleSelectWorkSpace}
                options={workspaceState?.workspaceList?.workspaceList}
            />
            {workspaceArray &&
                workspaceArray?.map(
                    (workspace: any, index: any) => (
                        <ConnectedWorkspaceChipButton
                            key={index}
                            workspace={workspace}
                        />
                    )
                )}
          </AddButttonContainer>

          <AddButttonContainer icon={FolderFileIcon} label="Add to Folder">
            <SearchWorkSpaceDropdown
                alwaysOpen
                onSelect={handleSelectWorkSpace}
                options={workspaceState?.workspaceList?.workspaceList}
            />
          </AddButttonContainer>
        </Stack>

        <Stack sx={{ flexDirection: "row", justifyContent: "end" }}>
          <ButtonSmall
            label="Save"
            colorVarient={SmallButtonColor.TraxBluish}
            onClick={handleSaveRecordingDetails}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PostScreenRecordingDetails;
