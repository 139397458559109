import { Box, Divider, Modal, Stack, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import {
  EmailSmallerPopout,
  MediumSearchbar,
  MUIModalstyle,
  SwitchSmall,
} from "../../common";
import { ViewMode } from "../Projects";
import ColorTabsEmail from "./components/ColorTabsEmail/ColorTabsEmail";
import {
  ActivityEmailSidebar,
  EmailCardManager,
  InsideEmailTopBar,
} from "./components";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { msalInstance } from "./components/authConfig";
import { AccountInfo } from "@azure/msal-browser";
import { fetchEmailsFromBackend } from "../../../redux/Slice/email/MsalInitialize";
import { EmailData } from "./components/EmailTypes/EmailTypes";
import {
  getAccessToken,
  getUser,
  setAccessToken,
  setUser,
} from "../../common/LocalStorage";
import {
  AccountCenter,
  AccountType,
  addConnectedAccount,
  changeLayoutState,
  ConnectedAccount,
  recallAPI__MAIL,
} from "../../../redux";
import { emptyEmails } from "../../../images";

type Params = Record<string, string | undefined>;
const Email = () => {
  const [infoButton, setInfoButton] = useState(false);
  const [viewMode, setViewMode] = useState<ViewMode>("square");
  // const [accessToken, setAccesstoken] = useState<string | null>(null);
  const [sortedEmails, setSortedEmails] = useState<EmailData[]>([]);
  const emailsState = useSelector((state: RootState) => state && state?.emails);
  const { mailSettings } = useSelector(
    (state: RootState) => state && state?.emails
  );
  const { emailId, emailIndex } = useParams<Params>();

  const accessToken = getAccessToken();
  const savedUser = getUser();

  const dispatch = useDispatch();
  const emailsData = useSelector(
    (state: RootState) => state && state?.emails?.emails?.data?.emails
  );

  useEffect(() => {
    if (emailsData) {
      const sortedEmailsCopy = [...emailsData]?.sort(
        (a: any, b: any) => a?.priority - b?.priority
      );
      setSortedEmails(sortedEmailsCopy);
    }
  }, [emailsData]);

  const sortEmailsByPriority = () => {
    if (Array.isArray(emailsData)) {
      const sorted = [...emailsData].sort((a, b) => a?.priority - b?.priority);
      setSortedEmails(sorted);
    } else {
      console.error("emailsData is not an array or is undefined.");
      setSortedEmails([]); // Optional: Set to an empty array or handle as needed
    }
  };

  const handleTabSwitch = async (value: string) => {
    try {
      const res = await fetchEmailsFromBackend(accessToken, dispatch, value);
      return res;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const initializeMsal = async () => {
      try {
        await msalInstance.initialize();
        console.log("MSAL is initialized in email");

        const accounts = msalInstance.getAllAccounts();
        let correctAccount: AccountInfo | undefined;

        if (savedUser && accounts.length > 0) {
          correctAccount = accounts?.find(
            (account) => account?.homeAccountId === savedUser
          );
        }

        if (!correctAccount && accounts?.length > 0) {
          correctAccount = accounts[0];
          setUser(correctAccount?.homeAccountId);
          // localStorage.setItem('loggedInUser', correctAccount.homeAccountId);
        }

        if (correctAccount) {
          try {
            const tokenResponse = await msalInstance.acquireTokenSilent({
              account: correctAccount,
              scopes: ["Mail.Read", "User.Read", "Mail.ReadWrite", "Mail.Send"],
            });
            // setAccesstoken(tokenResponse?.accessToken);
            await setAccessToken(tokenResponse?.accessToken);
            // localStorage.setItem('accessToken', tokenResponse.accessToken);

            // Check if the token is alive based on expiration
            const isTokenAlive =
              tokenResponse?.idTokenClaims &&
              (tokenResponse?.idTokenClaims as { exp?: number })?.exp !==
                undefined
                ? (tokenResponse?.idTokenClaims as { exp: number })?.exp *
                    1000 >
                  Date.now() // Convert exp to milliseconds
                : false;

            // Fetch user profile picture
            const profilePicUrl: string | null = await fetchUserProfilePicture(
              tokenResponse?.accessToken
            );

            // Add account with the profile picture
            await handleAddAccount(
              tokenResponse?.uniqueId,
              isTokenAlive,
              profilePicUrl
            );

            await fetchEmailsFromBackend(
              tokenResponse?.accessToken,
              dispatch,
              ""
            );
          } catch (silentError) {
            console.error("Silent token acquisition failed:", silentError);
          }
        } else {
          console.log("Fetching emails from token");
        }
      } catch (err) {
        console.error("MSAL Initialization Error:", err);
      }
    };
    initializeMsal();
  }, []);

  // Function to handle adding a connected account
  const handleAddAccount = async (
    _id: string,
    _isTokenAlive: boolean,
    _profilePic?: string | null
  ) => {
    if (!_id) {
      return;
    }

    const newAccount: ConnectedAccount = {
      id: _id,
      name: "My OutLook",
      type: AccountType.OUTLOOK,
      isTokenAlive: _isTokenAlive,
      center: AccountCenter.EMAIL,
      accountProfilePic: _profilePic === null ? "" : _profilePic, // Include the profile picture if available
    };

    await dispatch(addConnectedAccount(newAccount)); // Dispatch action to add the account
  };
  async function fetchUserProfilePicture(accessToken: string) {
    const url = "https://graph.microsoft.com/v1.0/me/photo/$value";

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const blob = await response.blob(); // Get the profile picture as a blob
      const imageUrl = URL.createObjectURL(blob); // Create a URL for the blob
      return imageUrl; // Return the image URL for use in an <img> tag
    } else {
      console.error(
        "Failed to fetch profile picture",
        response.status,
        response.statusText
      );
      return null; // Handle error accordingly
    }
  }
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    dispatch(recallAPI__MAIL(null));
  };
  const handleClose = () => setOpen(false);
  useEffect(() => {
    if (emailsState?.reCallAPIs === "COMPOSE") {
      handleOpen();
    }
  }, [emailsState]);

  const handleShowListView = () => {
    const drawerType = "isDrawer_Email";

    // Toggle the listViewShow state
    const updatedListView = !infoButton;

    // Open the drawer if listViewShow is false, otherwise close it
    if (!updatedListView) {
      dispatch(
        changeLayoutState({
          type: drawerType,
          value: true, // Open the drawer when listViewShow is false
        })
      );
    } else {
      dispatch(
        changeLayoutState({
          type: drawerType,
          value: false, // Close the drawer when listViewShow is true
        })
      );
    } // Update the listViewShow state
    setInfoButton(updatedListView);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "93.68vh",
        overflowY: "scroll",
        backgroundColor: "white",
        backgroundImage: `url(${mailSettings?.appreanceTab?.theme || ""})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        "&::before": {
          content: '""',
          position: "absolute",
          top: "55px",
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: mailSettings?.appreanceTab?.theme
            ? "rgba(0, 0, 0, 0.5)"
            : "",
          zIndex: 1,
        },
        zIndex: 0,
        "&::-webkit-scrollbar": {
          display: "none", // Hide scrollbar for WebKit browsers
        },
        msOverflowStyle: "none", // Hide scrollbar for IE and Edge
        scrollbarWidth: "none", // Hide scrollbar for Firefox
      }}
    >
      <Stack
        // p="15px 25px 2px 25px"
        width={"100%"}
        zIndex={2}
        position={"relative"}
      >
        <Stack
          direction={"row"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          width={"100%"}
          height={"100%"}
        >
          <Stack width={"100%"}>
            <Stack
              width={"99.5%"}
              direction={"row"}
              alignItems={"flex-end"}
              justifyContent={"flex-end"}
              gap={2}
              mr={"5px"}
              padding={"20px 10px 5px 10px"}
            >
              <SwitchSmall
                viewMode={viewMode}
                setViewMode={setViewMode}
                disabled={Boolean(emailId && emailIndex)}
              />
              <MediumSearchbar bgOpacity={true} ph={"Search all emails"} />
            </Stack>

            <Stack width={"100%"} padding={"0 10px 5px 10px"}>
              <ColorTabsEmail
                sortEmailsByPriority={sortEmailsByPriority}
                handleTabSwitch={handleTabSwitch}
                theme={mailSettings?.appreanceTab?.theme || ""}
              />
              <Divider
                sx={{
                  borderColor: "#E9EDF2",
                  width: "100%",
                  height: "0px",
                  position: "relative",
                  top: "0px",
                }}
                orientation="horizontal"
              />
            </Stack>

            <Stack
              direction={"row"}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
            >
              <Stack
                sx={{
                  overflow: "hidden",
                  maxHeight: "84%",
                  height: "59rem !important",
                  "&::-webkit-scrollbar": {
                    width: "0 !important",
                  },
                  scrollbarWidth: "none",
                  overflowX: "hidden",
                  overflowY: "scroll",
                  width: "100%",
                  borderRight: "1px solid lightgray",
                }}
              >
                {sortedEmails && sortedEmails?.length > 0 ? (
                  <EmailCardManager
                    setViewMode={setViewMode}
                    viewMode={viewMode}
                    emails={sortedEmails}
                  />
                ) : (
                  <Stack
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"center"}
                    direction={"column"}
                  >
                    {!mailSettings?.appreanceTab?.theme && (
                      <img
                        src={emptyEmails}
                        alt="No emails available"
                        style={{
                          width: "50%", // Adjust width as necessary
                          height: "auto", // Maintain aspect ratio
                          display: "block",
                          margin: "0 auto", // Center the image
                        }}
                      />
                    )}
                    <Typography
                      children={"No emails available"}
                      sx={{
                        font: "normal normal 500 17px/21px Source Serif Pro",
                        textAlign: "center",
                        color: "text.secondary", // Optional: adjust color to fit your theme
                      }}
                    />
                  </Stack>
                )}
              </Stack>

              {emailId && emailIndex && (
                <Stack
                  sx={{ borderRight: "1px solid lightgray" }}
                  minWidth={infoButton ? "72%" : "74%"}
                >
                  <InsideEmailTopBar
                    infoButton={() => {
                      handleShowListView();
                    }}
                  />
                </Stack>
              )}
            </Stack>
          </Stack>

          {emailId && emailIndex && infoButton && (
            <Stack
              sx={{
                overflow: "hidden",
                maxHeight: "84%",
                height: "66.4rem!important",
                "&::-webkit-scrollbar": {
                  width: "0 !important",
                },
                scrollbarWidth: "none",
                overflowX: "hidden",
                overflowY: "scroll",
                minWidth: "293px",
              }}
            >
              <ActivityEmailSidebar />
            </Stack>
          )}
        </Stack>
      </Stack>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={MUIModalstyle}>
          <div style={{ width: "650px", height: "650px" }}>
            {" "}
            <EmailSmallerPopout
              popupType={"compose"}
              emailData={[]}
              onCloseClick={() => handleClose()}
            />
          </div>
        </Box>
      </Modal>
    </Box>
  );
};

export default Email;
