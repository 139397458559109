import React from "react";
import {
  AttendanceListInterface,
  timeCardTableRowsWidth,
} from "../../TimeCardTableType";
import { Stack } from "@mui/material";
import {
  ApprovalActionArea,
  ApprovalTimeView,
  ApprovedBView,
  AttendanceBadge,
  CellWrapper,
  DateView,
} from "../../view";

interface SickDayCardProps {
  data: AttendanceListInterface;
}

const SickDayCard: React.FC<SickDayCardProps> = ({ data }) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        height: "100%",
      }}
    >
      <CellWrapper
        colWidth={
          timeCardTableRowsWidth.ShiftWidth + timeCardTableRowsWidth.BreakWidth
        }
        hideRightBorder
      >
        <ApprovedBView labelText="Time off: Approved by" />
      </CellWrapper>

      <CellWrapper hideRightBorder colWidth={timeCardTableRowsWidth.HoursWidth}>
        <AttendanceBadge AttendanceType={data.attendanceType} />
      </CellWrapper>

      <CellWrapper
        hideRightBorder
        colWidth={
          timeCardTableRowsWidth.OvertWidth + timeCardTableRowsWidth.NotesWidth
        }
      >
        <ApprovalTimeView />
      </CellWrapper>
    </Stack>
  );
};

export default SickDayCard;
