import { WorkspaceState } from "../workspaceTypes";
import { PayloadAction } from "@reduxjs/toolkit";

export const createWorkspace__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const getAllWorkspace__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    // Update the state
    state.error = action.payload;
    state.workspaceList = {
        workspaceList: [], // Reset the list on failure
        api_call_timeStamp: new Date().toISOString(), // Record the time of the API call
        is_api_called: false,
        WorkspaceListError: action.payload,
    };
};

export const getAllSharedWorkspace__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    // Log to check if the function is called and inspect the arguments

    // Update the state
    state.error = action.payload;
    state.sharedWorkspaceList = {
        workspaceSharedList: [], // Reset the list on failure
        WorkspaceSharedListError: action.payload,
    };
};

export const getOneWorkspace__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const updateWorkspace__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const deleteWorkspace__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const addWorkspaceContent__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const reOrderWorkspaceContent__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const updateWorkspaceContent__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const deleteWorkspaceContent__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const addWorkspaceMember__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const getAllWorkspaceMember__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const updateWorkspaceMember__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const deleteWorkspaceMember__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const addWorkspaceCustomField__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const updateWorkspaceCustomField__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const deleteWorkspaceCustomField__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const addWorkspaceTag__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const updateWorkspaceTag__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const deleteWorkspaceTag__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const addWorkspaceTemplate__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const applyWorkspaceTemplate__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const addWorkspaceIntegration__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const updateWorkspaceIntegration__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const deleteWorkspaceIntegration__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const addWorkspaceActivity__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const getAllWorkspaceActivity__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const updateWorkspaceColumn__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const addWorkspaceConnection__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const addWorkspaceReminder__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const addWorkspaceVideoReport__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const addWorkspaceFile__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const addWorkspaceMedia__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const addWorkspaceBookMark__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const addWorkspaceTableOfContent__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const addWorkspaceEquation__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const addWorkspaceButton__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const addWorkspaceSyncElement__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const addWorkspaceHeadingToggle__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const addWorkspaceCodeBlock__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const deleteWorkspaceInBulk__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const addGrid__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const getAllGrids__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};
export const getOneGrid__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};
export const updateOneGrid__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};
export const deleteGrid__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};
export const addSheet__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};
export const updateSheet__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};
export const deleteSheet__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};
export const reorderSheets__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};
export const updateCell__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};
export const addComponent__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};
export const getAllComponents__Failure = (
    state: WorkspaceState,
    action: PayloadAction<{ data: any; error: string }>
) => {
    const { data, error } = action.payload;
    const workspaceId = data.workspaceId;

    if (state.componentsData[workspaceId]) {
        state.componentsData[workspaceId].loading = false;
    }

    state.error = error;
};
export const getOneComponent__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};
export const updateOneComponent__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};
export const deleteOneComponent__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};
export const deleteBulkComponents__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};
export const createTask__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};
export const getOneTask__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};
export const updateOneTask__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const deleteOneTask__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};
export const getAllTasks__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};
export const createSubTask__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};
export const getAllSubTasks__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};
export const deleteBulkTasks__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const AddWhiteboard__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const GetAllWhiteboards__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const GetOneWhiteboard__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const UpdateWhiteboard__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const DeleteWhiteboard__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const AddObject__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const UpdateObject__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const DeleteObject__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const AddCollaborator__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const GetAllCollaborators__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const UpdateCollaborator__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const DeleteCollaborator__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const CreateVersion__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const GetAllVersions__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const RestoreVersion__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const addDocs__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const getAllDocs__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const getOneDoc__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

export const updateOneDoc__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

// Add Wiki Failure
export const AddWiki__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

// Get All Wikis Failure
export const GetAllWikis__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

// Get One Wiki Failure
export const GetOneWiki__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

// Add Wiki Page Failure
export const AddWikiPage__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

// Get Wiki Page Failure
export const GetWikiPage__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

// Update Wiki Page Failure
export const UpdateWikiPage__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

// Add Component View Failure
export const AddComponentView__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
) => {
    state.error = action.payload;
};

// Create Page Failure
export const CreatePage__Confluence__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
  ) => {
    state.error = action.payload;
  };
  
  // Retrieve All Pages Failure
  export const RetrieveAllPage__Confluence__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
  ) => {
    state.error = action.payload;
  };
  
  // Update Page Failure
  export const UpdatePage__Confluence__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
  ) => {
    state.error = action.payload;
  };
  
  // Retrieve Page Failure
  export const RetrievePage__Confluence__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
  ) => {
    state.error = action.payload;
  };
  
  // List Pages In Space Failure
  export const ListPagesInSpace__Confluence__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
  ) => {
    state.error = action.payload;
  };
  
  // Search Content Failure
  export const SearchContent__Confluence__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
  ) => {
    state.error = action.payload;
  };
  
  // Delete Page Failure
  export const DeletePage__Confluence__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
  ) => {
    state.error = action.payload;
  };
  
  // Add Comment Failure
  export const AddComment__Confluence__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
  ) => {
    state.error = action.payload;
  };
  
  // Retrieve Comments Failure
  export const RetrieveComments__Confluence__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
  ) => {
    state.error = action.payload;
  };
  
  // Like Content Failure
  export const LikeContent__Confluence__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
  ) => {
    state.error = action.payload;
  };
  
  // Retrieve Attachments Failure
  export const RetrieveAttachments__Confluence__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
  ) => {
    state.error = action.payload;
  };
  
  // Add Label to Content Failure
  export const AddLabelToContent__Confluence__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
  ) => {
    state.error = action.payload;
  };
  
  // View Content Labels Failure
  export const ViewContentLabels__Confluence__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
  ) => {
    state.error = action.payload;
  };
  
  // Delete Attachment Failure
  export const DeleteAttachment__Confluence__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
  ) => {
    state.error = action.payload;
  };
  
  // Create Space Failure
  export const CreateSpace__Confluence__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
  ) => {
    state.error = action.payload;
  };
  
  // Get Space Failure
  export const GetSpace__Confluence__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
  ) => {
    state.error = action.payload;
  };
  
  // List Spaces Failure
  export const ListSpaces__Confluence__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
  ) => {
    state.error = action.payload;
  };
  
  // Delete Space Failure
  export const DeleteSpace__Confluence__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
  ) => {
    state.error = action.payload;
  };
  
  // Update Space Permissions Failure
  export const UpdateSpacePermissions__Confluence__Failure = (
    state: WorkspaceState,
    action: PayloadAction<string>
  ) => {
    state.error = action.payload;
  };
  