import { Stack, Typography } from "@mui/material";
import React from "react";

interface GroupContainerProps {
  title: string;
  children: React.ReactNode;
}

/** Capitalizes the first letter of a string */
const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const GroupContainer: React.FC<GroupContainerProps> = ({ children, title }) => {
  return (
    <Stack>
      <Typography
        sx={{
          my: "17px",
          font: "normal normal 400 13px/16px Source Serif Pro",
        }}
      >
        {capitalizeFirstLetter(title)}
      </Typography>
      <Stack sx={{ gap: "10px" }}>{children}</Stack>
    </Stack>
  );
};

export default GroupContainer;
