import React from "react";
import { AcceptRejectButtonGroup } from "../../../../../../common";
import { ExpenseStatusType } from "../../../TraxApprovedBadge/TraxApprovedBadge";
import { AttendanceStatusType } from "../../../CrmStatusBadge";
import { IconButton, Stack } from "@mui/material";
import { EditCreatedIcon, NotesLineIcon } from "../../../../../../../images";
import StatusInfoArea from "../StatusInfoArea/StatusInfoArea";

interface ApprovalActionAreaProps {
  approvalStatus: ExpenseStatusType;
  isOvertime?: boolean;
  cardType: AttendanceStatusType;
  hideNotedButton?: boolean;
}

const ApprovalActionArea: React.FC<ApprovalActionAreaProps> = ({
  approvalStatus,
  cardType,
  isOvertime = false,
  hideNotedButton = false,
}) => {
  const getApprovalStage = () => {
    switch (cardType) {
      case "onTime":
      case "late":
        if (isOvertime) {
          switch (approvalStatus) {
            case "pending":
              return (
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <AcceptRejectButtonGroup />
                  <IconButton>
                    <EditCreatedIcon w={18} />
                  </IconButton>
                </Stack>
              );
            case "approved":
              return <StatusInfoArea infoType="approved" />;
            case "rejected":
              return <StatusInfoArea infoType="rejected" />;
          }
        } else {
          return (
            <IconButton>
              <EditCreatedIcon w={18} />
            </IconButton>
          );
        }

      default:
        return (
          <Stack
            sx={{ flexDirection: "row", alignItems: "center", gap: "3px" }}
          >
            {!hideNotedButton && (
              <IconButton>
                <NotesLineIcon w={18} />
              </IconButton>
            )}
            <IconButton>
              <EditCreatedIcon w={18} />
            </IconButton>
          </Stack>
        );
    }
  };

  return <Stack>{getApprovalStage()}</Stack>;
};

export default ApprovalActionArea;
