import { Button, ButtonProps, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../../../Utils";

interface ActionButtonProps extends ButtonProps {
  activeColor: string;
  icon: React.ElementType;
  buttonLabel: string;
  isActive: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  activeColor,
  buttonLabel,
  icon,
  isActive,
  sx,
  ...props
}) => {
  return (
    <Button
      sx={{
        textTransform: "none",
        width: "50px",
        minWidth: 0,
        minHeight: 0,
        height: "65px",
        boxSizing: "border-box",
        borderRadius: "5px",
        bgcolor: isActive ? adjustHexColor(activeColor, 25) : "inherit",
        ":hover": {
          bgcolor: isActive ? adjustHexColor(activeColor, 25) : "inherit",
        },
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        flexShrink: 0,
        color: activeColor,
        ...sx,
      }}
      {...props}
    >
      {React.createElement(icon, { w: 25, color: "black" })}
      <Typography
        sx={{
          font: "normal normal 400 13px/16px Source Serif Pro",
          color: "black",
        }}
      >
        {buttonLabel}
      </Typography>
    </Button>
  );
};

export default ActionButton;
