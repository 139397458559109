import { Box, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import g from "./insideFile.module.css";
import { RightSidePanel } from "./Components";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getOneFile__api, getOneFile__BOX__api } from "../../../../redux";
import { DocumentViewer, ImageViewer, PdfViewer, VideoPlayer } from "./view";

const InsideFileCloudStorage = () => {
  const dispatch = useDispatch();
  const { fileId, fileType } = useParams<{
    fileId?: string;
    fileType?: string;
  }>();

  const [rightPanelOpen, setRightPanelOpen] = useState<boolean>(false);
  const [fileData, setFileData] = useState<any | null>(null); // Use proper typing
  useEffect(() => {
    const fetchFileData = async () => {
      if (!fileId || !fileType) return;
      const params = new URLSearchParams(window.location.search);
      const provider = params.get("provider");
      try {
        let action;

        // Conditional API call based on provider
        if (provider === "Box") {
          action = getOneFile__BOX__api(fileId);
        } else {
          action = getOneFile__api(fileId);
        }
        const fileResponse: any = await dispatch(action);

        if (fileResponse?.success && fileResponse?.data) {
          setFileData(fileResponse.data);
        } else {
          setFileData(null); // Reset to null if not found
        }
      } catch (error) {
        console.error("Error fetching file data:", error);
        setFileData(null); // Reset to null on error
      }
    };

    fetchFileData();
  }, [dispatch, fileId, fileType]);

  const renderFileComponent = (rightPanelOpen: any) => {
    if (!fileData) return null;

    const extension = fileData?.name?.split(".").pop()?.toLowerCase();
    const path = fileData?.path;

    switch (extension) {
      case "jpg":
      case "jpeg":
      case "png":
      case "webp":
        return <ImageViewer path={path} />;
      case "mp4":
      case "mov":
      case "webm":
        return <VideoPlayer path={path} />;
      case "pdf":
        return <PdfViewer rightPanelOpen={rightPanelOpen} path={path} />;
      case "doc":
      case "docx":
      case "xls":
      case "xlsx":
        return <DocumentViewer path={path} />;
      default:
        return <div>Unsupported file type</div>;
    }
  };

  return (
    <Box
      sx={{
        justifyContent: rightPanelOpen ? "flex-start" : "center",
      }}
      className={g.insideFile__mainContainer}
    >
      <Stack
        width={rightPanelOpen ? "65%" : "85%"}
        ml={rightPanelOpen ? "15px" : "0px"}
        height={"96vh"}
        bgcolor={"white"}
      >
        {" "}
        {renderFileComponent(rightPanelOpen)}
      </Stack>

      {/* <Box
        sx={{
          position: "fixed",
          bottom: "25px",
          left: rightPanelOpen ? "20%" : "unset",
        }}
      >
        <BottomSidePanel fileType={fileType} />
      </Box> */}

      <Box
        sx={{ position: "fixed", right: "5px", height: "100%", top: "53px" }}
      >
        <RightSidePanel
          rightPanelOpen={rightPanelOpen}
          setRightPanelOpen={setRightPanelOpen}
        />
      </Box>
    </Box>
  );
};

export default InsideFileCloudStorage;
