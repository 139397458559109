import { BadgeDropdownMenuType } from "../../common";

export type EmployeeType =
  | "fullTime"
  | "partTime"
  | "independentC"
  | "contract"
  | "temporary"
  | "freelancer"
  | "intern";

export const employeeTypeBadgeOptionsList: BadgeDropdownMenuType<EmployeeType>[] =
  [
    // { _id: "1", divider: true },
    {
      _id: "2",
      label: "Full Time",
      value: "fullTime",
      BadgeColor: "#5FD9AF",
      BadgeColorOpacity: 100,
      fontColor: "white",
    },
    {
      _id: "3",
      label: "Part Time",
      value: "partTime",
      BadgeColor: "#96A3ED",
      BadgeColorOpacity: 100,
      fontColor: "white",
    },
    {
      _id: "4",
      label: "Independent C.",
      badgeLabel: "I.C.",
      value: "independentC",
      BadgeColor: "#046D48",
      BadgeColorOpacity: 100,
      fontColor: "white",
    },
    {
      _id: "5",
      label: "Contract",
      value: "contract",
      BadgeColor: "#4A90E2",
      BadgeColorOpacity: 100,
      fontColor: "white",
    },
    {
      _id: "6",
      label: "Temporary",
      value: "temporary",
      BadgeColor: "#FFC107",
      BadgeColorOpacity: 100,
      fontColor: "white",
    },
    {
      _id: "7",
      label: "Freelancer",
      value: "freelancer",
      BadgeColor: "#FF8A80",
      BadgeColorOpacity: 100,
      fontColor: "white",
    },
    {
      _id: "8",
      label: "Intern",
      value: "intern",
      BadgeColor: "#FFF280",
      BadgeColorOpacity: 100,
      fontColor: "black",
    },
  ];
