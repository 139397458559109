import React, { useCallback, useContext, useEffect, useState } from "react";
import RecordingCallMenu from "../RecordingCallMenu/RecordingCallMenu";
import styles from "./MeetingHeader.module.css";
import {
  AvatarMedium,
  BgColorCode,
  FullScreenLoader,
} from "../../../../common";
import {
  CopyLinkIcon,
  MeetingModeIcon,
  StreamBearishOSLogoIconPng,
  WorkSpaceModeIcon,
} from "../../../../../images";
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { Meeting } from "../../index";
//@ts-ignore
import {
  NotificationContainer,
  NotificationManager,
  //@ts-ignore
} from "react-notifications";
import JoinRequestCard from "../JoinRequestCard/JoinRequestCard";
import {
  getOnGoingMeetingAgenda,
  responseToJoinMeeting,
} from "../../../../../redux";
import {
  removeGuestRequestAction,
  setOnGoingMeetingAction,
} from "../../../../../redux/Slice/videoCenter/VideoCenterSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MeetingContext } from "../../../../Povider";
import WaitingRoom from "../WaitingRoom";
import { useTranslation } from "react-i18next";
import UserProfile from "../../../../common/TopNavbar/UserProfile";
import {StyledMenu} from "../../../../common/TopNavbar/StyledMenubar";
import Logo from '../../../../../images/other/topNavbar/BearishOSNewLogo.png'

const MeetingHeader = () => {
  const {t}=useTranslation()
  let bgColorCode = BgColorCode.Default;
  let userInitials = "AA";
  const { onGoingMeeting } = useSelector((state) => state.videoCenter);
  const { user } = useSelector((state) => state.auth);
  const [view, setView] = useState("preMetting");
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const meetingContext = useContext(MeetingContext);
  const checkHostOrNot =
    meetingContext.meetingSession ||
    onGoingMeeting?.meeting?.meetingHostId === user?.data?._id;
  const [anchorEl, setAnchorEl] = useState()

  const handleCopyLink = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url).then(
      () => console.log("Message copied to clipboard!"),
      (err) => console.error("Failed to copy message:", err)
    );
  };

  //@ts-ignore
  const handleGuestResponse = async (userDetails, chimeMeetingId, status) => {
    try {
      await dispatch(
        responseToJoinMeeting({
          meetingId: params.id,
          userId: userDetails?.userId,
          chimeMeetingId,
          meeting_id: onGoingMeeting?.meeting?._id,
          status,
        })
      );
      await dispatch(removeGuestRequestAction(userDetails?.userId));
    } catch (error) {
      console.error(`Error handling guest ${status}:`, error);
    }
  };

  useEffect(() => {
    const { notificationData, meeting } = onGoingMeeting || {};
    const { userDetails } = notificationData || {};

    if (notificationData && meeting?.meetingHostId === user?.data?._id) {
      const handleResponse = (status) =>
        handleGuestResponse(userDetails, meeting.chimeMeetingId, status);

      NotificationManager.info(
        <JoinRequestCard
          cardType="joinRequest"
          userName={userDetails?.userName || "Guest"}
          handleReject={() => handleResponse("rejected")}
          handleAccept={() => handleResponse("accepted")}
          OnHandleWait={() => handleResponse("wait")}
        />,
        "Join Request",
        0 // Keep the notification open indefinitely
      );
    }
  }, [onGoingMeeting?.notificationData]);

  const joinMeeting = useCallback(async () => {
    if (!params.id) {
      navigate("/Calls");
    } else {
      setLoading(true);

      const permissions = await navigator.permissions.query({ name: 'camera' });

      const microphonePermissions = await navigator.permissions.query({ name: 'microphone' });

      if (permissions.state !== 'granted' || microphonePermissions.state !== 'granted') {
        const permissionsGranted = await meetingContext.requestMediaPermissions();
        if (!permissionsGranted) {
          alert(
              "You need to allow camera and microphone access to join the meeting."
          );
          navigate("/Calls");
          return;
        }
      }

      const meetingData = await meetingContext?.getMeeting({
        meetingId: params?.id,
        attendeeId: user?.data?._id,
      });

      await dispatch(setOnGoingMeetingAction(meetingData));
      if (meetingData?.meetingHostId === user?.data?._id) {
        await handleJoinMeetingClick(
            meetingData.chimeMeetingId,
            meetingData._id
        );
      }

      setLoading(false);
    }
  }, [params.id]);

  const handleJoinMeetingClick = async (chimeMeetingId, meetingId) => {
    setLoading(true);
    if (params.id && chimeMeetingId) {
      const attendee = await meetingContext?.createAttendee({
        meetingId: params?.id,
      });
      meetingContext.joinMeeting({
        meetingId: chimeMeetingId,
        attendeeId: attendee?.chimeAttendeeId,
      });
      await meetingContext.configureAudioOutput();
      await meetingContext.startVideoInput();
      await meetingContext.startAudioInput();

      await meetingContext.startSession();
      if (meetingId) {
        dispatch(getOnGoingMeetingAgenda(meetingId));
      }
    }
    setLoading(false);
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    (async () => await joinMeeting())();
  }, [joinMeeting]);

  if (loading) {
    return <FullScreenLoader />;
  }

  return (
    <div className={styles.mainContainer}>
      <AppBar
        style={{ height: "50px" }}
        position="static"
        color="transparent"
        elevation={0}
      >
        <Toolbar
          style={{ minHeight: "50px" }}
          sx={{ justifyContent: "space-between" }}
        >
          {/* Left Section */}
          <Stack
            sx={{
              textAlign: "start",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "15%",
              gap:"10px"
            }}
            className={styles.upcomingCallCard__header}
          >
            <Box
              component={"img"}
              src={Logo}
              sx={{ height: "30px", width: "30px", marginRight: "8px" }}
              alt="logo"
            />
            <Typography
              className={styles.upcomingCallCard__meetingName}
              sx={{ font: "normal normal 400 18.8px/15px Source Serif Pro" }}
            >
              {checkHostOrNot ? onGoingMeeting?.meeting?.meetingType === "INSTANT" && t("VIDEO_CENTER.MEETING.INSTANT_MEETING") : t("VIDEO_CENTER.MEETING.BEARISH_OS")}
            </Typography>
          </Stack>

          {checkHostOrNot && (
            <div
              style={{ display: "flex", alignItems: "center", width: "45%" }}
            >
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "20px",
                  font: "normal normal 400 13px/16px Source Serif Pro",
                }}
              >
                <IconButton
                  color="inherit"
                  style={{
                    marginLeft: "20px",
                    borderRadius: "8px",
                    padding: "8px",
                  }}
                  onClick={handleCopyLink}
                >
                  <CopyLinkIcon w={20} />
                  <Typography
                    className={styles.tab}
                    sx={{
                      font: "normal normal 400 16.29px/13px Source Serif Pro",
                      marginLeft: "8px",
                    }}
                  >
                    {t('VIDEO_CENTER.MEETING.COPY_MEETING_LINK')}
                  </Typography>
                </IconButton>
                <Stack
                  sx={{
                    font: "inherit",
                    cursor: "pointer",
                    marginLeft: "20px",
                    padding: "8px",
                    borderRadius: "8px",
                    transition: "background-color 0.3s",
                  }}
                  onClick={() => setView("preMetting")}
                >
                  <Typography
                    className={styles.tab}
                    sx={{
                      font: "normal normal 400 16.29px/13px Source Serif Pro",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <MeetingModeIcon w={20} />
                    <span style={{ marginLeft: "8px" }}>{t('VIDEO_CENTER.MEETING.MEETING_MODE')}</span>
                  </Typography>
                  <Stack
                    sx={{
                      height: "2px",
                      bgcolor: view === "preMetting" ? "#FF8181" : "inherit",
                      borderRadius: "10px",
                      width: "100%",
                    }}
                  />
                </Stack>
                <Stack
                  sx={{
                    font: "inherit",
                    cursor: "pointer",
                    marginLeft: "20px",
                    padding: "8px",
                    borderRadius: "8px",
                    transition: "background-color 0.3s",
                  }}
                  onClick={() => setView("MeetingNotes")}
                >
                  <Typography
                    className={styles.tab}
                    sx={{
                      font: "normal normal 400 16.29px/13px Source Serif Pro",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <WorkSpaceModeIcon w={20} />
                    <span style={{ marginLeft: "8px" }}>{t('VIDEO_CENTER.MEETING.WORKSPACE_MODE')}</span>
                  </Typography>
                  <Stack
                    sx={{
                      height: "2px",
                      bgcolor: view === "MeetingNotes" ? "#FF8181" : "inherit",
                      borderRadius: "10px",
                      width: "100%",
                    }}
                  />
                </Stack>
              </Stack>
            </div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "33%",
              justifyContent: "flex-end",
            }}
          >
            {checkHostOrNot && <RecordingCallMenu />}
            <Divider
              orientation="vertical"
              flexItem
              style={{ marginLeft: "5px", marginRight: "20px" }}
            />
            <AvatarMedium
              showBadge={true}
              userName={user?.data?.firstName?.[0] + user?.data?.lastName?.[0]}
              bgColorCode={"white"}
              onClick={handleAvatarClick}
            />
            <StyledMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
              <Box className={styles.topNavbarUserBox}>
                <UserProfile
                    fullName={
                        user?.data?.firstName + " " + user?.data?.lastName ||
                        "user-name"
                    }
                    email={user?.data?.email || "user-email"}
                />
              </Box>
            </StyledMenu>
          </div>
        </Toolbar>
      </AppBar>
      <div className={styles.nanna} />
      {checkHostOrNot && (
        <Meeting workMode={view === "preMetting" ? false : true} />
      )}
      {!checkHostOrNot && <WaitingRoom />}
      <NotificationContainer />
    </div>
  );
};

export default MeetingHeader;
