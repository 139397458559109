import { PayloadAction } from "@reduxjs/toolkit";
import { CloudStorageState } from "../CloudStorageType";

export const uploadCloudFile__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};

// Get All Files
export const getAllFiles__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};

// Get One File
export const getOneFile__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};

// Download File
export const downloadFile__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
    state.downloadFile = null;
};

// Share File With Users
export const shareFileWithUsers__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};

// Share File With Email
export const shareFileWithEmail__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};

// Delete File
export const deleteFile__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};

// Update File
export const updateFile__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};

// Upload New Version
export const uploadNewVersion__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};

// Create Folder
export const createFolder__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};

// Rename Folder
export const renameFolder__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};

// Get All Folders
export const getAllFolders__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};

// Get All Folders
export const getAllMemberCS__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
    state.memberOfCloudStorageFile = null;
};

// Create Link
export const createLink__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};

// Get Link
export const getLink__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
    state.linkData = null;
};

// Delete Link
export const deleteLink__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};

// Get All Link
export const getAllLink__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};

// Add View
export const addView__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};

// Add Time
export const addTime__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};

// Toggle Download
export const toggleDownload__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};

// Toggle Ask Details
export const toggleAskDetails__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};

// Update Expiration
export const updateExpiration__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};

// Update Link Password
export const updateLinkPassword__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};

// Toggle Watermark
export const toggleWatermark__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};

/* --------------------- BOX ------------------------------ */

// File Management Routes
export const uploadFile__BOX__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};
export const downloadFile__BOX__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};
export const lockFile__BOX__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};
export const unlockFile__BOX__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};
export const createSharedLink__BOX__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};
export const disableSharedLink__BOX__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};
export const addComment__BOX__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};
export const retrieveComments__BOX__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};

// Folder Management
export const createFolder__BOX__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};
export const deleteFolder__BOX__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};
export const searchFilesAndFolders__BOX__Failure = (
    state: CloudStorageState,
    action: PayloadAction<string>
) => {
    state.error = action?.payload;
    state.data = null;
};
