import { BadgeDropdownMenuType } from "../../common";

export type EmployeeStatus =
  | "active"
  | "inactive"
  | "onLeave"
  | "pending"
  | "terminated"
  | "resigned"
  | "probation"
  | "furloughed"
  | "training"
  | "cEnded"
  | "medical"
  | "paternity"
  | "maternity"
  | "retired";

export const employeeStatusBadgeOptionsList: BadgeDropdownMenuType<EmployeeStatus>[] =
  [
    // { _id: "1", divider: true },
    {
      _id: "2",
      label: "Active",
      value: "active",
      BadgeColor: "#21B481",
      BadgeColorOpacity: 100,
      fontColor: "white",
    },
    {
      _id: "3",
      label: "Inactive",
      value: "inactive",
      BadgeColor: "#9E9E9E",
      BadgeColorOpacity: 100,
      fontColor: "white",
    },
    {
      _id: "4",
      label: "On Leave",
      value: "onLeave",
      BadgeColor: "#FF9800",
      BadgeColorOpacity: 100,
      fontColor: "white",
    },
    {
      _id: "5",
      label: "Pending",
      value: "pending",
      BadgeColor: "#03A9F4",
      BadgeColorOpacity: 100,
      fontColor: "white",
    },
    {
      _id: "6",
      label: "Terminated",
      value: "terminated",
      BadgeColor: "#D32F2F",
      BadgeColorOpacity: 100,
      fontColor: "white",
    },
    {
      _id: "7",
      label: "Resigned",
      value: "resigned",
      BadgeColor: "#8B0000",
      BadgeColorOpacity: 100,
      fontColor: "white",
    },
    {
      _id: "8",
      label: "Probation",
      value: "probation",
      BadgeColor: "#FFC107",
      BadgeColorOpacity: 100,
      fontColor: "white",
    },
    {
      _id: "9",
      label: "Furloughed",
      value: "furloughed",
      BadgeColor: "#FF6F61",
      BadgeColorOpacity: 100,
      fontColor: "white",
    },
    {
      _id: "10",
      label: "Training",
      value: "training",
      BadgeColor: "#FFEB3B",
      BadgeColorOpacity: 100,
      fontColor: "black",
    },
    {
      _id: "11",
      label: "C. Ended",
      value: "cEnded",
      BadgeColor: "#795548",
      BadgeColorOpacity: 100,
      fontColor: "white",
    },
    {
      _id: "12",
      label: "Medical",
      value: "medical",
      BadgeColor: "#FFCDD2",
      BadgeColorOpacity: 100,
      fontColor: "black",
    },
    {
      _id: "13",
      label: "Paternity",
      value: "paternity",
      BadgeColor: "#CE93D8",
      BadgeColorOpacity: 100,
      fontColor: "white",
    },
    {
      _id: "14",
      label: "Maternity",
      value: "maternity",
      BadgeColor: "#CE93D8",
      BadgeColorOpacity: 100,
      fontColor: "white",
    },
    {
      _id: "15",
      label: "Retired",
      value: "retired",
      BadgeColor: "#BDBDBD",
      BadgeColorOpacity: 100,
      fontColor: "white",
    },
  ];
