import { Stack, Typography } from "@mui/material";
import React from "react";

interface TableDateAreaProps {
  value: any;
}

const TableDateArea: React.FC<TableDateAreaProps> = ({}) => {
  return (
    <Stack
      sx={{ font: "normal normal 400 13px/16px Source Serif Pro", gap: "5px" }}
    >
      <Typography sx={{ font: "inherit" }}>Mon</Typography>
      <Typography sx={{ font: "inherit" }}>Jun 1st, 2024</Typography>
    </Stack>
  );
};

export default TableDateArea;
