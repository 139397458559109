import React, { useState } from "react";
import { IconButton, Stack, Typography, Popover } from "@mui/material";
import { FirstMessageIcon } from "../../../../../../../images";
import { DealStageCurrencySymbols } from "../../../../../../common";
import { ExpenseItemType } from "../../../ReciptReview/ReciptReview";
import styles from "./ListTableView.module.css";
import { CommentCard } from "../../../../../Workspace/components";

interface ListTableViewProps {
  list: ExpenseItemType[];
  total: number;
}

const ListTableView: React.FC<ListTableViewProps> = ({ list, total }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const isPopoverOpen = Boolean(anchorEl);

  const priceWidth = 80;
  const taxWidth = 80;
  const totalsWidth = 80;
  const currencySymbol = DealStageCurrencySymbols.USD;

  return (
    <Stack className={styles.listTableView}>
      {/* header */}
      <Stack className={styles.listTableView__header}>
        <Stack className={styles.listTableView__headerItem}>Item name</Stack>
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "8px" }}>
          <Stack
            className={styles.listTableView__headerItem}
            sx={{ width: priceWidth }}
          >
            Price
          </Stack>
          <Stack
            className={styles.listTableView__headerItem}
            sx={{ width: taxWidth }}
          >
            Tax
          </Stack>
          <Stack
            className={styles.listTableView__headerItem}
            sx={{ width: totalsWidth }}
          >
            Totals
          </Stack>
        </Stack>
      </Stack>

      {/* body */}
      <Stack className={styles.listTableView__body}>
        {list.map((item, index) => (
          <Stack key={index} className={styles.listTableView__row}>
            <Stack className={styles.listTableView__rowDetails}>
              <Stack className={styles.listTableView__rowDetailsItem}>
                {item.itemName}
              </Stack>
              <Stack
                sx={{ flexDirection: "row", alignItems: "center", gap: "8px" }}
              >
                <Stack
                  className={styles.listTableView__rowDetailsItem}
                  sx={{ width: priceWidth }}
                >
                  {currencySymbol} {item.price}
                </Stack>
                <Stack
                  className={styles.listTableView__rowDetailsItem}
                  sx={{ width: taxWidth }}
                >
                  {currencySymbol} {item.tax}
                </Stack>
                <Stack
                  className={styles.listTableView__rowDetailsItem}
                  sx={{ width: totalsWidth }}
                >
                  {currencySymbol} {item.total}
                </Stack>
              </Stack>
            </Stack>

            {/* IconButton with Popover */}
            <IconButton
              className={styles.listTableView__iconButton}
              onClick={handlePopoverOpen}
            >
              <FirstMessageIcon w={15} />
            </IconButton>

            {/* Popover */}
            <Popover
              open={isPopoverOpen}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                style: {
                  boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
                  scrollbarWidth: "none",
                  width: "355px",
                  minHeight: "350px",
                  maxHeight: "450px",
                  marginTop: "10px",
                  padding: "15px",
                  marginLeft: "-5px",
                  border: "1px solid #E9EDF2",
                },
              }}
            >
              <CommentCard />
            </Popover>
          </Stack>
        ))}
      </Stack>

      <Stack className={styles.listTableView__total}>
        <Typography className={styles.listTableView__totalValue}>
          {currencySymbol} {total}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ListTableView;
