import {
  Stack,
  Typography,
  styled,
  Tooltip,
  TooltipProps,
} from "@mui/material";
import React, { lazy, Suspense, useState } from "react";
import {
  a11yProps,
  StyledTab,
  TabPanel,
  TabsWithState,
} from "../../../../common/CardTabsWithoutIcon";
import { useSelector } from "react-redux";
import { AuthState } from "../../../../../redux";
import { RootState } from "../../../../../redux/store";

import { useTranslation } from "react-i18next";

interface CreateNewMessagesProps {
  handleGetBackClick: () => void;
}

const LazyInternalChatTab = lazy(
  () => import("./tabs/InternalChatTab/InternalChatTab")
);

// const LazyExternalChatTab = lazy(
//   () => import("./tabs/ExternalChatTab/ExternalChatTab")
// );

const LazyChannelTab = lazy(() => import("./tabs/ChannelTab/ChannelTab"));

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#616161", // Background color
    maxWidth: 200, // Tooltip width
    font: "normal normal 15px/20px Source Sarif pro",
  },
  [`& .MuiTooltip-arrow`]: {
    color: "#616161", // Arrow color same as background
  },
}));

// Usage

const CreateNewMessages: React.FC<CreateNewMessagesProps> = ({handleGetBackClick}) => {
  const [tabValue, setTabValue] = useState(0);
  const userState = useSelector((state: RootState) => state.auth as AuthState);
  const {t}=useTranslation()


  const tabs: {
    label: string;
    content: React.ReactNode;
    tooltipText: string;
  }[] = [
    {
      label: t('MESSAGE.INTERNAL_CHAT'),
      content: <LazyInternalChatTab usersList={userState.usersList} handleBackClick={handleGetBackClick} />,
      tooltipText: t('MESSAGE.INTERNAL_CHATS_INFO'),
    },
    // {
    //   label: t('MESSAGE.EXTERNAL_CHAT'),
    //   content: <LazyExternalChatTab />,
    //   tooltipText: t('MESSAGE.EXTERNAL_CHATS_INFO'),
    // },
    {
      label: t('MESSAGE.CHANNEL'),
      content: <LazyChannelTab usersList={userState.usersList} handleBackClick={handleGetBackClick} />,
      tooltipText: t('MESSAGE.CHANNELS_INFO'),
    },
  ];

  return (
    <Stack
      sx={{
        width: "360px",
        minHeight: "410px",
        padding: "15px",
        boxSizing: "border-box",
        gap: "10px",
        bgcolor: "white",
      }}
    >
      <Typography sx={{ font: "normal normal 700 15px/19px Source Serif Pro" }}>
        {t('MESSAGE.CREATE_NEW')}
      </Typography>

      <TabsWithState
        ariaLabel="channel details tabs"
        value={tabValue}
        onChange={(event, newValue) => setTabValue(newValue)}
      >
        {tabs.map((tab, index) => (
          <CustomTooltip
            title={tab.tooltipText}
            arrow
            placement="top"
            enterDelay={1000}
          >
            <StyledTab
              key={index}
              disableRipple
              label={tab.label}
              {...a11yProps(index)}
            />
          </CustomTooltip>
        ))}
      </TabsWithState>

      {tabs.map((tab, index) => (
        <TabPanel
          style={{ display: "contents" }}
          key={index}
          value={tabValue}
          index={index}
        >
          <Suspense fallback={<div>Loading...</div>}>{tab.content}</Suspense>
        </TabPanel>
      ))}
    </Stack>
  );
};

export default CreateNewMessages;
