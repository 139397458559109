import { createSlice } from "@reduxjs/toolkit";
import {DashboardState} from "./dashboardTypes";
import {getAllEvents__Failure, getAllEvents__Request, getAllEvents__Success} from "./DashboardSliceActions";


const initialState: DashboardState = {
    error: null,
    loading: false,
    notificationCard: {},
    eventsAndTasks: {},
};

/*
 * ==============================================
 * Create a slice for dashboard logic
 * ==============================================
 */

const dashboard = createSlice({
    name: "dashboard", // Name of the slice
    initialState, // Initial state of the slice
    reducers: {

        // get All Events api
        getAllEvents__Request,
        getAllEvents__Failure,
        getAllEvents__Success,

    },
});

/*
 * ======================================================
 * Export the actions to be used in dispatch calls
 * ======================================================
 */

export const {

    /* Get all Events actions */
    getAllEvents__Request: getAllEvents__RequestAction,
    getAllEvents__Failure: getAllEvents__FailureAction,
    getAllEvents__Success: getAllEvents__SuccessAction,

} = dashboard.actions;

export default dashboard.reducer;