import { IconButton, Stack } from "@mui/material";
import React from "react";
import { EditCreatedIcon } from "../../../../../images";
import { useDispatch } from "react-redux";
import { employeeExpenseIdHandler } from "../../../../../redux";

interface TableEditButtonAreaProps {
  expId: string;
}

const TableEditButtonArea: React.FC<TableEditButtonAreaProps> = ({ expId }) => {
  const dispatch = useDispatch();

  const expPreviewShowHandler = () => {
    dispatch(employeeExpenseIdHandler(expId));
  };
  return (
    <Stack alignItems={"center"}>
      <IconButton onClick={() => expPreviewShowHandler()}>
        <EditCreatedIcon w={15} />
      </IconButton>
    </Stack>
  );
};

export default TableEditButtonArea;
