// rootReducer.ts

// Import the necessary functions and reducers from Redux Toolkit and other files
import { combineReducers } from "@reduxjs/toolkit";

// Import the individual reducers for the different slices of state
import authReducer from "./Slice/auth/authSlice";
import snackbarReducer from "./Slice/snackbarSlice/snackbarSlice";
import layoutReducer from "./Slice/Layout/layoutSlice";
import workspaceReducer from "./Slice/workspace/workspaceSlice";
import fileUploadReducer from "./Slice/FileUploadManager/FileUploadManagerSlice";
import cloudStorageReducer from "./Slice/CloudStorage/CloudStorageSlice";
import CommentsReducer from "./Slice/comments/CommentsSlice";
import emailsReducer from "./Slice/email/emailSlice";
import messageReducer from "./Slice/message/messageSlice";
import videoCenterReducer from "./Slice/videoCenter/VideoCenterSlice"
import shareReducer from "./Slice/Share/ShareSlice";
import calenderReducer from "./Slice/Calendar/CalendarSlice";
import crmCenterReducer from "./Slice/CRMCenter/CRMCenterSlice"
import traxCenterReducer from "./Slice/TraxCenter/TraxCenterSlice";
import dashboardReducer from "./Slice/dashboard/dashboardSlice";
import globalReducer from "./Slice/Global/globalSlice";
/*
  Combine the individual reducers into a single rootReducer.
  This rootReducer will manage the overall state structure of the application.
  Each slice of state will be managed by its respective reducer.
  
  Structure of the state:
  {
    auth: {...},       // Managed by authReducer
    snackbar: {...},   // Managed by snackbarReducer
    layout: {...}      // Managed by layoutReducer
  }
*/
const rootReducer = combineReducers({
  auth: authReducer,
  snackbar: snackbarReducer,
  layout: layoutReducer,
  workspace: workspaceReducer,
  fileState: fileUploadReducer,
  cloudStorage: cloudStorageReducer,
  comments: CommentsReducer,
  emails: emailsReducer,
  message: messageReducer,
  videoCenter: videoCenterReducer,
  share: shareReducer,
  calendar: calenderReducer,
  crm: crmCenterReducer,
  trax: traxCenterReducer,
  global:globalReducer,
  dashboard: dashboardReducer,
});

/** 
    Define the RootState type using TypeScript's ReturnType utility type.
    This ensures that the type of the root state is derived from the rootReducer's return type,
    providing strong typing throughout the application.
*/
export type RootState = ReturnType<typeof rootReducer>;

// Export the rootReducer as the default export of this module.
export default rootReducer;
