import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DropdownWIconLarge } from "../DropdownWIconLarge";
import { TimeZonePng } from "../../../images";
import { getAllTimezonesFormatted } from "../../pages/Settings/Components/common";
import { RootState } from "../../../redux/store";
import { updateStartEndTimeBasedOnTimeZone } from "../../../redux";

interface TimeZoneCalendarDropdownProps {
  initialValue?: string;
  onChange?: (selectedOption: string) => void;
}

const TimeZoneCalendarDropdown: React.FC<TimeZoneCalendarDropdownProps> = ({
  initialValue,
  onChange,
}) => {
  const dispatch = useDispatch();
  const currentTimeZone = useSelector(
    (state: RootState) =>
      state &&
      state.calendar &&
      state.calendar.update__start_endTime__based__TimeZone
  );
  const [timezones, setTimezones] = useState<string[]>([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState<string>(
    initialValue ||
    currentTimeZone ||
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  useEffect(() => {
    const fetchedTimezones = getAllTimezonesFormatted();
    setTimezones(
      fetchedTimezones?.map((timezone) => timezone?.name || timezone?.id)
    );
  }, []);

  useEffect(() => {
    if (!initialValue) {
      dispatch(updateStartEndTimeBasedOnTimeZone(selectedTimeZone));
    }
  }, [dispatch, initialValue, selectedTimeZone]);

  useEffect(() => {
    if (onChange && selectedTimeZone) {
      onChange(selectedTimeZone); // Notify parent of the selected timezone
    }
  }, [selectedTimeZone, onChange]);

  return (
    <DropdownWIconLarge
      multiple={false}
      inputHeight={25}
      initialValue={selectedTimeZone}
      width="200px"
      icon={"chevron"}
      iconSrc={TimeZonePng}
      options={timezones}
      onChange={(value) => {
        setSelectedTimeZone(value);
        dispatch(updateStartEndTimeBasedOnTimeZone(value)); // Dispatch the new value to Redux
      }}
    />
  );
};

export default TimeZoneCalendarDropdown;
