import { Box, IconButton, Stack, Popover, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  BilledIcon,
  DummyImage,
  FirstMessageIcon,
} from "../../../../../../../images";
import { CommentCard } from "../../../../../Workspace/components";

interface ImagePreviewAreaViewProps {}

const ImagePreviewAreaView: React.FC<ImagePreviewAreaViewProps> = ({}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "start",
        justifyContent: "space-between",
      }}
    >
      <Box
        component={"img"}
        src={DummyImage}
        sx={{
          width: "350px",
          height: "375px",
          bgcolor: "#EBEBEB",
          objectFit: "fill",
        }}
      />

      <IconButton sx={{ p: 0 }} onClick={handleClick}>
        <FirstMessageIcon w={20} />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            scrollbarWidth: "none",
            width: "355px",
            minHeight: "350px",
            maxHeight: "450px",
            marginTop: "10px",
            padding: "15px",
          },
        }}
      >
        <Stack height={"100%"} overflow={"hidden"}>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              py: "3px",
            }}
          >
            <BilledIcon w={15} />
            <Typography
              sx={{ font: "normal normal 300 12px/15px Source Serif Pro" }}
            >
              Receipt Comment
            </Typography>
          </Stack>

          <Stack sx={{ overflow: "auto", scrollbarWidth: "none" }}>
            <CommentCard />
          </Stack>
        </Stack>
      </Popover>
    </Stack>
  );
};

export default ImagePreviewAreaView;
