import { Button, Popover, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { ProjectIcon } from "../../../../../../../images";
import { ConnectedProjectChipButton } from "../../../../../Calls/components";
import { SearchBarProjects } from "../../../../../../common";

interface ConnectedProjectsViewProps {}

const ConnectedProjectsView: React.FC<ConnectedProjectsViewProps> = ({}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [isProjectConnected, setIsProjectConnected] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "currency-popover" : undefined;
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
        <ProjectIcon w={15} />
        <Typography
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
        >
          Connected Projects
        </Typography>
      </Stack>
      {isProjectConnected ? (
        <ConnectedProjectChipButton />
      ) : (
        <Button
          aria-describedby={id}
          onClick={handleClick}
          variant="text"
          sx={{
            textTransform: "none",
            color: "#0027FF",
            padding: "2px 5px",
            borderRadius: "5px",
            font: "normal normal 300 11px/14px Source Serif Pro",
          }}
        >
          Connect a project
        </Button>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            padding: 0,
          },
        }}
      >
        <SearchBarProjects />
      </Popover>
    </Stack>
  );
};

export default ConnectedProjectsView;
