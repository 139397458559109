import { MenuItem, Stack } from "@mui/material";
import React from "react";

type Props = {
    handleOnClick?: (action: string) => void;
};

const CreateWorkspacePopUp = ({ handleOnClick }: Props) => {
    // Workspace options and actions
    const workspaceOptions = [
        { label: "Create Bearish Workspace", action: "BearishOS" },
        { label: "Create Confluence Space", action: "Confluence" },
        { label: "Create Jira Workspace", action: "Jira" },
    ];

    return (
        <Stack
            sx={{
                width: 180,
                borderRadius: 1,
                boxShadow: "0px 3px 3px 0px #00000026",
            }}
        >
            {workspaceOptions.map(({ label, action }, index) => (
                <MenuItem
                    key={index}
                    sx={{ fontSize: 13, fontWeight: 400, fontFamily: "Source Serif Pro" }}
                    onClick={() => handleOnClick?.(action)} // Trigger callback if provided
                >
                    {label}
                </MenuItem>
            ))}
        </Stack>
    );
};

export default CreateWorkspacePopUp;
