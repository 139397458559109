import {Box, Drawer, Popover, Stack} from "@mui/material";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {styled} from "@mui/system";
import CompanyIconAndName from "../../CompanyIconAndName";
import styles from "../../DrawerNavbar.module.css";
import {useNavigate} from "react-router-dom";
import {MeetingContext} from "../../../../Povider";
import {CreateCallButton} from "../../../Buttons/CreateCallButton";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../redux/store";
import {VideoCenterState} from "../../../../../redux/Slice/videoCenter/VideoCenterTypes";
import {
    getSingleMeetingFailureAction,
    setEndMeetingObjBlankAction,
    setIsLoadingAction,
    setSelectedMeetingAction,
} from "../../../../../redux/Slice/videoCenter/VideoCenterSlice";
import {JoinMeetingCard, UpcomingCallCardContainer} from "../../../../pages/Calls/components";
import {PastCallCardContainer} from "../../../../pages/Calls/components/PastCallCardContainer";
import {useTranslation} from "react-i18next";
import {
    AuthState,
    getPastMeetings,
    getUpcomingMeetings,
} from "../../../../../redux";
import {getAllExistingUsers} from "../../../../../redux/Slice/auth/authActions";
import {
    getAllProjectsGlobal__api, getAllWorkspaceGlobal__api,
    getCoverImages__api,
} from "../../../../../redux/Slice/Global/globalActions";
import {CommandControlSearchContainer} from "../../../../pages/Dashboard/components/CommandControlSearchContainer";
import {InCalendarEventPopup} from "../../../../pages/Calendar/components";

type CallsDrawerProps = {
    drawerOpen: boolean;
    drawerCloser: () => void;
};

const DrawerContainer = styled("div")({
    position: "fixed",
    top: 0,
    height: "100vh",
    zIndex: 1100, // Ensure the drawer is behind the navbar
});
const CallsDrawer: React.FC<CallsDrawerProps> = ({
                                                     drawerOpen,
                                                     drawerCloser,
                                                 }) => {
    const meetingContext = useContext(MeetingContext);
    const [showSearchContainer, setShowSearchContainer] = useState(false);
    const [anchorForJoinMeetingPopup, setAnchorForJoinMeetingPopup] = React.useState<HTMLButtonElement | null>(null);
    const [anchorForScheduleMeetingPopup, setAnchorForScheduleMeetingPopup] = React.useState<HTMLButtonElement | null>(null);
    const [meetingLink, setMeetingLink] = React.useState<string>("");
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {errors, upcomingMeetings, pastMeetings} = useSelector(
        (state: RootState) => state.videoCenter as VideoCenterState
    );

    const {user} = useSelector((state: RootState) => state.auth as AuthState);

    const onCallButtonClickHandler = useCallback(async (data: any,event:any) => {
        if (data.includes("Start")) {
            if (meetingContext?.createMeeting) {
                dispatch(setEndMeetingObjBlankAction(null));
                const meeting = await meetingContext.createMeeting();
                if (meeting?.externalMeetingId)
                    navigate(`/Calls/${meeting?.externalMeetingId}`);
            }
        }

        if (data.includes("Schedule")) {
            setAnchorForScheduleMeetingPopup(event.currentTarget);
        }

        if (data.includes("Join")) {
            setAnchorForJoinMeetingPopup(event.currentTarget);
        }

    }, []);


    const handleMeetingOnClick = async (value: string) => {
        dispatch(setIsLoadingAction(true));
        const selectedMeeting = upcomingMeetings[value];
        await dispatch(setSelectedMeetingAction(selectedMeeting));
        navigate(`/Calls/CallDetails/${selectedMeeting?._id}`);
        dispatch(setIsLoadingAction(false));
    };

    const handlePastMeetingOnClick = async (value: string) => {
        navigate(`/Calls/PastCallDetails/${value}`)
    };

    useEffect(() => {
        if (errors) {
            navigate(`/Calls`);
            dispatch(getSingleMeetingFailureAction(null));
        }
    }, [errors]);

    const handleGetUserMeeting = async () => {
        await dispatch(getUpcomingMeetings());
        await dispatch(getAllWorkspaceGlobal__api());
        await dispatch(getAllProjectsGlobal__api(user?.data?._id));
        await dispatch(getAllExistingUsers());
    };

    const handleGetCoverImage = async () => {
        dispatch(getCoverImages__api());
    }

    useEffect(() => {
        handleGetUserMeeting();
        handleGetCoverImage()
        const timer = setTimeout(() => {
            dispatch(getPastMeetings());
        }, 3000);

        return () => clearTimeout(timer);
    }, [dispatch]);

    const handleCloseJoinMeetingPopup = () => {
        setAnchorForJoinMeetingPopup(null);
    }

    const handleCloseScheduleMeetingPopup = () => {
        setAnchorForScheduleMeetingPopup(null);
    }

    const handleJoinCall = () => {
        const link = meetingLink;
        window.open(link, "_blank");
        console.log("Join Call");
    }                                                                          // Add this function to handle join call button

    const handleChangeMeetingLink = (value: string) => {
        setMeetingLink(value);
    }
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (
                (event.ctrlKey && event.key === " ") ||
                (event.metaKey && event.key === " ") ||
                (event.ctrlKey && event.key === "s") ||
                (event.metaKey && event.key === "s")
            ) {
                event.preventDefault();
                setShowSearchContainer((prev: any) => !prev);
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const openJoinMeetingPopup = Boolean(anchorForJoinMeetingPopup);
    const JoinMeetingPopupId = openJoinMeetingPopup ? 'simple-popover' : undefined;

    const openScheduleMeetingPopup = Boolean(anchorForScheduleMeetingPopup);
    const scheduleMeetingId = openScheduleMeetingPopup ? 'simple-popover' : undefined;

    return (
        <>
            <DrawerContainer>
                <Drawer
                    sx={{
                        position: "absolute",
                        top: 0,
                        height: "100vh",
                        width: "215px",
                        // left: '-240px', // Start hidden behind the Navbar
                        transition: drawerOpen
                            ? "left 225ms ease-out"
                            : "left 195ms ease-in",
                        left: drawerOpen ? "30px" : "-215px",
                        zIndex: 1100, // Ensure the drawer is behind the navbar
                        "& .MuiPaper-root": {
                            position: "absolute",
                            top: 0,
                            height: "100vh",
                            width: "215px",
                            left: 0,
                            boxSizing: "border-box",
                            zIndex: 1100, // Ensure the paper is also behind the navbar
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={drawerOpen}
                >
                    <Stack
                        bgcolor={"white"}
                        direction="column"
                        flex={1}
                        className={`${styles.drawerNavbar__drawer}`}
                    >
                        {/* Company logo and name here */}
                        <CompanyIconAndName arrowButtonClickHandler={drawerCloser}/>
                        <Stack className={styles.drawerNavbar__content}>
                            {/* Buttons and other lists here */}
                            <Box style={{marginTop: "20px", marginLeft: "5px"}}>
                                <CreateCallButton onClick={onCallButtonClickHandler}/>
                            </Box>
                            <Popover
                                id={JoinMeetingPopupId}
                                open={openJoinMeetingPopup}
                                anchorEl={anchorForJoinMeetingPopup}
                                onClose={handleCloseJoinMeetingPopup}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <JoinMeetingCard onChangeLink={handleChangeMeetingLink} handleJoinCall={handleJoinCall} />
                            </Popover>
                            <Popover
                                id={scheduleMeetingId}
                                open={openScheduleMeetingPopup}
                                anchorEl={anchorForScheduleMeetingPopup}
                                onClose={handleCloseScheduleMeetingPopup}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                            >
                                <InCalendarEventPopup handleClose={handleCloseScheduleMeetingPopup} />
                            </Popover>
                            <UpcomingCallCardContainer
                                handleMeetingOnClick={handleMeetingOnClick}
                                upcomingMeetings={upcomingMeetings}
                                labelText={t("VIDEO_CENTER.UPCOMING_CALLS")}
                            />
                            <PastCallCardContainer
                                handleMeetingOnClick={handlePastMeetingOnClick}
                                pastMeetings={pastMeetings}
                                labelText={t("VIDEO_CENTER.PAST_CALLS")}
                            />
                        </Stack>
                        {showSearchContainer && (
                            <Box
                                sx={{
                                    position: "fixed",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    zIndex: 1000,
                                }}
                            >
                                <CommandControlSearchContainer />
                            </Box>
                        )}
                    </Stack>
                </Drawer>
            </DrawerContainer>
        </>
    );
};

export default CallsDrawer;
