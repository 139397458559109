import { Stack } from "@mui/material";
import React from "react";
import { EventIconButton } from "../../../../../../common";
import { DoneIcon } from "../../../../../../../images";
import { SnoozeButton } from "../../../../../Message/components";

interface ButtonGroupOneViewProps {}

const ButtonGroupOneView: React.FC<ButtonGroupOneViewProps> = ({}) => {
  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
      <EventIconButton buttonTitle="Done" icon={DoneIcon} iconColor="#008D4C" />
      <SnoozeButton onSelect={(value:any) => null} />
    </Stack>
  );
};

export default ButtonGroupOneView;
