import React from "react";
import { InputBase, Stack } from "@mui/material";
import { EventTimeView, HeaderView } from "./view";
import {
  DeadlineIcon,
  TeamMemberIcon,
  VideoCallFilledIcon,
} from "../../../../../images";
import {
  ButtonSmall,
  DropdownWithImageBadge,
  DUMMY_USER_EMAIL_LIST,
  SmallButtonColor,
} from "../../../../common";
interface EventVideoCallHoverProps {}

const EventVideoCallHover: React.FC<EventVideoCallHoverProps> = ({}) => {
  return (
    <Stack sx={{ width: "100%" }}>
      <HeaderView titleText="event name" />

      <Stack
        sx={{ boxSizing: "border-box", padding: "20px 25px", gap: "25px" }}
      >
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
          <DeadlineIcon w={20} />
          <InputBase
            sx={{
              flexGrow: 1,
              height: "30px",
              font: "normal normal 400 18px/23px Source Serif Pro",
            }}
            placeholder="Event title"
            value={"Example Event name"}
            onChange={(e: any) => null}
          />
        </Stack>

        <EventTimeView />

        <Stack sx={{ flexDirection: "row", alignItems: "start", gap: "10px" }}>
          <span style={{ marginTop: "6px" }}>
            <TeamMemberIcon w={20} />
          </span>
          <DropdownWithImageBadge
            options={DUMMY_USER_EMAIL_LIST}
            initialData={DUMMY_USER_EMAIL_LIST.slice(3, 10)}
            onChangeSelection={() => null}
            showMaxSelected={4}
            readonlyComponent
            placeholder="Add guests"
          />
        </Stack>

        <Stack
          sx={{
            flexDirection: "row",
            gap: "15px",
            justifyContent: "space-between",
          }}
        >
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
              flex: 1,
            }}
          >
            <VideoCallFilledIcon />
            <InputBase
              placeholder="Teams Meeting Links"
              sx={{
                height: "30px",
                flex: 1,
                borderRadius: "5px",
                border: "1px solid #E9EDF2",
                font: "normal normal 300 12px/15px Source Serif Pro",
                boxSizing: "border-box",
                padding: "0px 10px",
              }}
            />
          </Stack>

          <Stack
            sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}
          >
            <ButtonSmall
              label="Go to call"
              colorVarient={SmallButtonColor.Unbilled}
            />
            <ButtonSmall
              label="Start call"
              colorVarient={SmallButtonColor.CallsSalmon}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EventVideoCallHover;
