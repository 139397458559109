import React from "react";
import { EstimatedARRIcon, ProjectIcon } from "../../../../../images";
import { PayrollCardHeaderButton, PayrollCardMenu } from "../../common";
import { DealStageCurrencySymbols } from "../../../../common";

interface ProjectsPayrollCardProps {}

const ProjectsPayrollCard: React.FC<ProjectsPayrollCardProps> = ({}) => {
  const currencySymbol = DealStageCurrencySymbols.USD;
  return (
    <div>
      <PayrollCardHeaderButton
        bgColor="#008D4C"
        currencySymbol={currencySymbol}
        icon={ProjectIcon}
        label="Projects"
        value={15000.0}
      >
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Project Based Earnings"
          primaryValue={5000}
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Project Based Bonuses"
          primaryValue={1000}
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Project Based Overtime"
          primaryValue={2500}
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Project Deductions"
          primaryValue={500}
        />
      </PayrollCardHeaderButton>
    </div>
  );
};

export default ProjectsPayrollCard;
