import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { EmployeeStatusBadgeMenu, EmployeeTypeBadge } from "../../components";

const InlineBox: React.FC<{ label: string; children: React.ReactNode }> = ({
  children,
  label,
}) => {
  return (
    <Box
      sx={{
        border: "1px solid #E9EDF2",
        height: "65px",
        width: "140px",
        borderRadius: "5px",
        boxShadow: " 0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
        boxSizing: "border-box",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        flexShrink: 0,
      }}
    >
      <Typography sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}>
        {label}
      </Typography>

      {children}
    </Box>
  );
};

interface EmployeeOverviewAreaProps {}

const EmployeeOverviewArea: React.FC<EmployeeOverviewAreaProps> = ({}) => {
  return (
    <Stack sx={{ gap: "15px" }}>
      <Typography sx={{ font: "normal normal 400 13px/16px Source Serif Pro" }}>
        Employee Overview
      </Typography>

      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <InlineBox label="Employment Type">
          <EmployeeTypeBadge
            initialValue="freelancer"
            onSelection={(newValue) => null}
          />
        </InlineBox>
        <InlineBox label=" Employee Status">
          <EmployeeStatusBadgeMenu
            initialValue="active"
            onSelection={(newValue) => null}
          />
        </InlineBox>
      </Stack>
    </Stack>
  );
};

export default EmployeeOverviewArea;
