import { Stack } from "@mui/material";
import React from "react";
import { EventIconButton } from "../../../../../../common";
import {
  CheckboxFilledIcon,
  MultiCircleIcon,
  XCircleIcon,
} from "../../../../../../../images";
import { useTranslation } from "react-i18next";

interface ButtonGroupTwoViewProps {
    onButtonClick: (eventId: number,snoozeDuration:string,notificatonId:string,calenderId:string,event_id:string) => void; // Update this prop
    notificationInfo?: any;
}

const ButtonGroupTwoView: React.FC<ButtonGroupTwoViewProps> = ({onButtonClick,notificationInfo}) => {
  const { t } = useTranslation();
  const handleClick = (id:any) => {
    onButtonClick(id,"0","",notificationInfo?.notifyData?.calendarId,notificationInfo?.notifyData?.eventId); // Pass the unique ID
  };

  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
        <EventIconButton
            buttonTitle={t('CALENDAR.ACCEPT')}
            icon={CheckboxFilledIcon}
            iconColor="#21B481"
            onClick={() => handleClick(1)} // Add onClick handler
        />
        <EventIconButton
            buttonTitle={t('CALENDAR.REJECT')}
            icon={XCircleIcon}
            iconColor="#FF0000"
            onClick={() => handleClick(2)} // Add onClick handler
        />
        <EventIconButton
            buttonTitle={t('CALENDAR.MAYBE')}
            icon={MultiCircleIcon}
            iconColor="#FFB100"
            onClick={() => handleClick(3)} // Add onClick handler
        />
    </Stack>
  );
};

export default ButtonGroupTwoView;
