import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    checkMeetingExistance,
    getAllMeetingNotes,
    getMeetingsFiles,
    getSelectedMeetingAgenda,
} from "../../../../redux";
import {setSelectedMeetingAction} from "../../../../redux/Slice/videoCenter/VideoCenterSlice";
import {VideoCenterState} from "../../../../redux/Slice/videoCenter/VideoCenterTypes";
import {RootState} from "../../../../redux/store";
import {CallCenterTabs} from "../components";
import {Box} from "@mui/material";
import {SearchBar} from "../../CRM/components";
import {FullScreenLoader} from "../../../common";
import {useTranslation} from "react-i18next";

type CallDetailsProps = {};
const CallDetails = (props: CallDetailsProps) => {
    const {t} = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {upcomingMeetings, loading} = useSelector(
        (state: RootState) => state.videoCenter as VideoCenterState
    );

    const handleGetUserMeetingDetails = async (meetingId: any) => {
        const IsMeetingExist: any = await dispatch(checkMeetingExistance(params.id));
        console.log(IsMeetingExist);
        if (IsMeetingExist.success) {
            if (upcomingMeetings) {
                const selectedMeeting = upcomingMeetings[meetingId];
                if (selectedMeeting) {
                    await dispatch(setSelectedMeetingAction(selectedMeeting));
                    await dispatch(getSelectedMeetingAgenda(selectedMeeting?.chimeMeetingInfo?._id));
                    await dispatch(getAllMeetingNotes(selectedMeeting?.chimeMeetingInfo?._id));
                    await dispatch(getMeetingsFiles(selectedMeeting?.chimeMeetingInfo?._id));
                }
            }
        } else {
            navigate("/Calls");
        }
    };

    useEffect(() => {
        if (params.id) {
            handleGetUserMeetingDetails(params.id);
        }
    }, [params.id, upcomingMeetings.length]);

    return (
        <Box
            display="flex"
            padding="0 15px 0 15px"
            flexDirection="column"
            width="100%"
        >
            <Box display="flex" justifyContent="end" alignItems="center" p={2}>
                <SearchBar
                    iconSize={15}
                    font="normal normal 400 11px/14px Source Serif Pro"
                    customStyles={{width: "330px", display: "flex", color: "black"}}
                    containerSx={{borderRadius: "5px!important"}}
                    placeholder={t("VIDEO_CENTER.MEETING.SEARCH_CALLS")}
                />
                {loading && <FullScreenLoader/>}
            </Box>
            <Box
                display="flex"
                alignItems="center"
                style={{padding: "0 20px 0 20px"}}
            >
                <CallCenterTabs/>
            </Box>
        </Box>
    );
};

export default CallDetails;
