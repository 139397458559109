import { Middleware } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";
import CryptoJS from "crypto-js";
import { showSnackbar } from "../Slice";
import { CookieStorage } from "redux-persist-cookie-storage";
import Cookies from "js-cookie";
import { persistStore } from "redux-persist";
import { store } from "../store";

export const getCurrentIp = async () => {
  try {
    // Check if the IP address is already stored in localStorage
    const storedIp = localStorage.getItem("currentIp");

    if (storedIp) {
      return storedIp;
    }

    // If IP is not found in localStorage, fetch it from the API
    const response = await axios.get("https://api64.ipify.org?format=json");
    const ip = response?.data?.ip;

    // Store the fetched IP in localStorage
    if (ip) {
      localStorage.setItem("currentIp", ip);
    }

    return ip;
  } catch (error) {
    console.error("Error fetching current IP address:", error);
    return null; // Return null if there's an error fetching the IP
  }
};

const cookieStorageInstance = new CookieStorage(Cookies);
// Define the structure of actions that interact with APIs
interface ApiAction {
  type: string;
  payload: {
    axiosRequired: boolean;
    method: AxiosRequestConfig["method"];
    url: string;
    data?: any;
    onStart?: string;
    onSuccess: string;
    onError: string;
    headers?: Record<string, string>; // New headers field
    unwrap?: boolean;
  };
}

// Function to clear cookies for multiple domains
const clearCookies = () => {
  // Determine domains for local and production environments
  const domains = [
    ".localtest.me", // local development domain
    ".bearishos.com", // production domain
  ];

  // Get all cookies
  const cookies = Cookies.get();

  // Loop through each domain and clear cookies
  domains.forEach((domain) => {
    for (const cookieName in cookies) {
      if (cookies?.hasOwnProperty(cookieName)) {
        try {
          // Remove the cookie by its name for the specific domain
          Cookies.remove(cookieName, {
            path: "/",
            domain,
            secure: process.env.REACT_APP_NODE === "production", // Only secure in production
          });

          // Verify if the cookie was removed
          if (!Cookies.get(cookieName)) {
            console.log(
              `Successfully removed cookie: ${cookieName} from domain: ${domain}`
            );
          } else {
            console.warn(
              `Failed to remove cookie: ${cookieName} from domain: ${domain}`
            );
          }
        } catch (error) {
          console.error(
            `Error removing cookie ${cookieName} from domain ${domain}:`,
            error
          );
        }
      }
    }
  });
};

// Function to clear all storage (localStorage, sessionStorage, cookies, and Redux state)
export const clearAllStorage = () => {
  // Clear localStorage
  console.log("Clearing localStorage...");
  localStorage.clear();

  // Clear sessionStorage
  console.log("Clearing sessionStorage...");
  sessionStorage.clear();

  // Clear cookies
  console.log("Clearing cookies...");
  clearCookies();

  // Clear Redux persisted state using redux-persist
  persistStore(store)
    ?.purge()
    ?.then(() => {
      console.log("Redux state purged successfully");
    })
    .catch((error) => {
      console.error("Error purging Redux state:", error);
    });
};

// Type guard to check if an action is an API action
const isApiAction = (action: any): action is ApiAction => {
  return (
    typeof action === "object" &&
    action !== null &&
    "payload" in action &&
    typeof action.payload === "object" &&
    action.payload !== null &&
    "axiosRequired" in action.payload &&
    typeof action.payload.axiosRequired === "boolean" &&
    "method" in action.payload &&
    typeof action.payload.method === "string" &&
    "url" in action.payload &&
    typeof action.payload.url === "string" &&
    "onSuccess" in action.payload &&
    typeof action.payload.onSuccess === "string" &&
    "onError" in action.payload &&
    typeof action.payload.onError === "string"
  );
};

// Encryption settings
const secretKey = process.env.REACT_APP_CRYPTO_SECRET;
const enableEncryption = false;

// Function to encrypt data
const encryptData = (data: any): string => {
  if (!secretKey) {
    throw new Error("Missing encryption secret key");
  }
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey)?.toString();
};

// Function to decrypt data
const decryptData = (ciphertext: string): any => {
  if (!secretKey) {
    throw new Error("Missing encryption secret key");
  }
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

// Determine the base URL based on the environment
// export const baseURL = process.env.REACT_APP_BACKEND__LOCAL_BASEURL_DEVELOPMENT;
export const baseURL =
  process.env.REACT_APP_NODE === "production"
    ? process.env.REACT_APP_BACKEND_BASEURL_PRODUCTION
    : process.env.REACT_APP_NODE === "localDevelopment"
      ? process.env.REACT_APP_BACKEND__LOCAL_BASEURL_DEVELOPMENT
      : process.env.REACT_APP_BACKEND_BASEURL_DEVELOPMENT;

// API Middleware
const ApiMiddleware: Middleware =
  ({ dispatch, getState }) =>
    (next) =>
      async (action: any) => {
        // Explicitly type the action as `any`

        if (!isApiAction(action) || !action.payload.axiosRequired) {
          return next(action);
        }

        // Destructure action payload
        const {
          method,
          url,
          data,
          onStart,
          onSuccess,
          onError,
          headers: actionHeaders,
          unwrap = true,
        } = action.payload;

        // Default headers
        const defaultHeaders: Record<string, string> = {
          language: "en",
          Authorization: `Bearer ${localStorage.getItem("token") || ""}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Current-IP": await getCurrentIp(),
        };

        // Merge default headers with action headers
        const headers = { ...defaultHeaders, ...actionHeaders };

        // Dispatch onStart action if provided
        if (onStart) {
          dispatch({ type: onStart });
        }

        // Determine if data needs encryption
        const isFormData = data instanceof FormData;
        const requestData =
          enableEncryption && data && !isFormData ? encryptData(data) : data;
        let responseObj: any;
        try {
          // Make API call with headers
          const response = await axios({
            method,
            url: `${baseURL}${url}`,
            data: requestData,
            headers,
            transformResponse: [
              (responseData) => {
                // Decrypt response if encryption is enabled
                return enableEncryption && !isFormData
                  ? decryptData(responseData)
                  : responseData;
              },
            ],
          });

          // Check if response data is JSON
          let responseData;
          try {
            responseData =
              typeof response.data === "string"
                ? JSON.parse(response.data)
                : response.data;
          } catch (parseError) {
            console.warn("Error parsing response", parseError);

            // Dispatch onError action with response data
            dispatch({ type: onError, payload: "Error parsing response" });

            // Show success message if provided in response
            dispatch(
              showSnackbar({
                message: "Error parsing response",
                severity: "error",
              })
            );

            throw new Error("Response is not valid JSON");
          }

          // Dispatch onSuccess action with response data
          dispatch({ type: onSuccess, payload: responseData });
          responseObj = responseData;

          // Show success message if provided in response
          if (responseData.message) {
            dispatch(
              showSnackbar({
                message: responseData.message,
                severity: "success",
              })
            );
          }

          return Promise.resolve(responseData);
        } catch (error: any) {
          const getErrors = [
            "Invalid user",
            "Not authorized, token failed",
            "Needs to loggedIn first!",
            "Token Expired",
            "Authentication Failed",
            "Unauthorized request",
            "jwt expired",
          ];

          let responseData;

          // Try to parse error response
          try {
            responseData =
              typeof error?.response?.data === "string"
                ? JSON.parse(error.response.data)
                : error.response.data;
          } catch (parseError) {
            responseData = error?.response?.data;
          }

          if (responseData?.message) {
            // Dispatch onError action with error message
            dispatch({
              type: onError,
              payload: responseData || "",
            });

            // Show error message from API response if available
            dispatch(
              showSnackbar({
                message: responseData?.message || "",
                severity: "error",
              })
            );
          }
          if (
            getErrors?.includes(responseData?.message) ||
            getErrors?.includes(responseData?.fullError)
          ) {
            clearAllStorage();
            window.location.replace("/login");
          }

          // Log the error for debugging purposes
          console.error("API Middleware Error: ", error);
        }
        return unwrap
          ? getState()
          : {
            response: responseObj,
            state: getState(),
          };
      };

export default ApiMiddleware;
