import { Stack, Typography } from "@mui/material";
import React from "react";
import { ReceiptActionArea } from "../ReceiptActionArea";
import {
  CardHeaderView,
  CategoryView,
  ConnectedPeopleView,
  ConnectedProjectsView,
  CurrencyView,
  ExpenseView,
  ReceiptView,
  TypeView,
} from "./view";
import {
  ButtonSmall,
  EditableTextArea,
  SmallButtonColor,
} from "../../../../common";
import { CreatedIcon, NotesLineIcon } from "../../../../../images";

interface AuditlogsSidebarProps {
  closeDrawer: () => void;
}

const AuditlogsSidebar: React.FC<AuditlogsSidebarProps> = ({ closeDrawer }) => {
  return (
    <Stack
      sx={{
        height: "100%",
        width: "550px",
        bgcolor: "white",
        overflow: "hidden",
      }}
    >
      <CardHeaderView onCloseClick={closeDrawer} />
      <Stack
        sx={{
          overflow: "auto",
          scrollbarWidth: "none",
          padding: "15px 20px 20px 20px",
          gap: "40px",
        }}
      >
        <ReceiptActionArea
          date="Monday, June 1st, 2024"
          displayArea="rejected"
          commentIconButtonClick={(e) => null}
        />

        <ExpenseView />

        <ReceiptView />

        {/* -------------  Details ----------------- */}
        <Stack gap={"5px"}>
          <Stack
            sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}
          >
            <CreatedIcon w={15} />
            <Typography
              sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
            >
              Details
            </Typography>
          </Stack>
          <EditableTextArea
            minRows={2}
            maxRows={4}
            style={{ border: "1px solid #E9EDF2", padding: "5px" }}
          />
        </Stack>

        <CategoryView />
        <TypeView />
        <CurrencyView />
        <ConnectedProjectsView />
        <ConnectedPeopleView />

        {/* ------------- 7 > Notes ----------------- */}
        <Stack gap={"5px"}>
          <Stack
            sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}
          >
            <NotesLineIcon w={15} />
            <Typography
              sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
            >
              Notes
            </Typography>
          </Stack>
          <EditableTextArea
            minRows={3}
            maxRows={4}
            style={{ border: "1px solid #E9EDF2", padding: "5px" }}
          />
        </Stack>

        {/* ------------------------------ */}
        {/* buttons here */}
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            gap: "15px",
          }}
        >
          <ButtonSmall
            label="Save & Go to Next day"
            sx={{ width: "145px" }}
            colorVarient={SmallButtonColor.Transparent}
          />
          <ButtonSmall
            label="Save"
            colorVarient={SmallButtonColor.TraxBluish}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AuditlogsSidebar;
