import { Stack, Typography } from "@mui/material";
import React from "react";

interface TableEditTextAreaProps {
  value: any;
}

const TableEditTextArea: React.FC<TableEditTextAreaProps> = ({}) => {
  return (
    <Stack alignItems={"center"}>
      <Typography
        sx={{
          font: "normal normal 400 11px/14px Source Serif Pro",
          overflow: "hidden",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 3,
          textOverflow: "ellipsis",
        }}
      >
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores
        labore quia excepturi expedita, totam voluptas dolores modi doloribus.
        Iste eveniet nihil quaerat magni! Facilis, voluptatum.
      </Typography>
    </Stack>
  );
};

export default TableEditTextArea;
