import React from "react";
import { Button, ButtonProps } from "@mui/material";
import style from "./ButtonXLarge.module.css";
import LoaderDualX from "../../Loaders/LoaderDualX";
import { ButtonType, ButtonXLargeColor } from "../AllButtonProps";

interface ButtonXLargeProps extends ButtonProps {
  colorVarient?: ButtonXLargeColor;
  buttonSize?: "default";
  label: string;
  fullwidth?: boolean;
  types?: ButtonType;
  loaderLogin?: boolean;
  someValue?: any;
  onClick?: any;
}

const ButtonXLarge: React.FC<ButtonXLargeProps> = React.memo(
  ({
    colorVarient = ButtonXLargeColor.Orange,
    buttonSize = "default",
    label,
    fullwidth = true,
    types = ButtonType.Submit,
    loaderLogin = false,
    onClick,
    someValue = "",
    ...props
  }) => {
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(someValue);
      }
    };

    const buttonClass = `
    ${style.ButtonXLarge}
    ${style[`ButtonXLarge--${buttonSize}`]}
    ${style[`ButtonXLarge--${colorVarient}`]}
    ${fullwidth ? style["ButtonXLarge--fullwidth"] : ""}
  `;

    return (
      <Button
        disableElevation
        variant="contained"
        type={types}
        className={buttonClass}
        onClick={types === ButtonType.Submit ? undefined : handleClick}
        {...props}
        aria-label={label}
      >
        {loaderLogin ? <LoaderDualX /> : label}
      </Button>
    );
  }
);

export default ButtonXLarge;
