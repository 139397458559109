import { Drawer, Stack } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import CompanyIconAndName from "../../CompanyIconAndName";
import { MainActionButton } from "../../../Buttons";
import styles from "../../DrawerNavbar.module.css";
import { ButtonType, MainActionColor } from "../../../Buttons/AllButtonProps";
import {
  BilledIcon,
  ComplianceIcon,
  DeadlineIcon,
  DocumentIcon,
  PayrollIcon,
  TeamMemberIcon,
  TypeIcon,
} from "../../../../../images";
import {
  TraxDrawerButtons,
  TraxDrawerContainer,
} from "../../../../pages/Trax/components";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TraxPaths } from "../../../../pages";

type TraxDrawerProps = {
  drawerOpen: boolean;
  drawerCloser: () => void;
};

const DrawerContainer = styled("div")({
  position: "fixed",
  top: 0,
  height: "100vh",
  zIndex: 1100, // Ensure the drawer is behind the navbar
});
const TraxDrawer: React.FC<TraxDrawerProps> = ({
  drawerOpen,
  drawerCloser,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <DrawerContainer>
        <Drawer
          sx={{
            position: "absolute",
            top: 0,
            height: "100vh",
            width: "215px",
            // left: '-240px', // Start hidden behind the Navbar
            transition: drawerOpen
              ? "left 225ms ease-out"
              : "left 195ms ease-in",
            left: drawerOpen ? "30px" : "-215px",
            zIndex: 1100, // Ensure the drawer is behind the navbar
            "& .MuiPaper-root": {
              position: "absolute",
              top: 0,
              height: "100vh",
              width: "215px",
              left: 0,
              boxSizing: "border-box",
              zIndex: 1100, // Ensure the paper is also behind the navbar
            },
          }}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
        >
          <Stack
            bgcolor={"white"}
            direction="column"
            flex={1}
            className={`${styles.drawerNavbar__drawer}`}
          >
            {/* Company logo and name here */}
            <CompanyIconAndName arrowButtonClickHandler={drawerCloser} />
            <Stack className={styles.drawerNavbar__content}>
              {/* Buttons and other lists here */}
              <MainActionButton
                sx={{ marginY: "25px" }}
                onClick={() => console.log("Home button clicked")}
                colorVarient={MainActionColor.TraxBluish}
                label={t("VIDEO_CENTER.CREATE")}
                types={ButtonType.Button}
              />
              <Stack gap={"15px"}>
                <TraxDrawerContainer label={t("TRAX.DRAWER_LABELS.TRAX")}>
                  <TraxDrawerButtons
                    icon={TeamMemberIcon}
                    buttonLabel={t("TRAX.DRAWER_LABELS.EMPLOYEE_DIRECTORY")}
                    onClick={() =>
                      navigate(`/Trax/employee-directory` as TraxPaths)
                    }
                    isSelected={location.pathname.startsWith(
                      "/Trax/employee-directory"
                    )}
                  />
                  <TraxDrawerButtons
                    icon={DeadlineIcon}
                    buttonLabel={t("TRAX.DRAWER_LABELS.TIME_AND_ATTENDANCE")}
                    onClick={() =>
                      navigate(`/Trax/time-attendance` as TraxPaths)
                    }
                    isSelected={location.pathname.startsWith(
                      "/Trax/time-attendance"
                    )}
                  />
                  <TraxDrawerButtons
                    icon={BilledIcon}
                    buttonLabel={t("TRAX.DRAWER_LABELS.EXPENSES")}
                  />
                  <TraxDrawerButtons
                    icon={PayrollIcon}
                    buttonLabel={t("TRAX.DRAWER_LABELS.PAYROLL")}
                  />
                </TraxDrawerContainer>

                <TraxDrawerContainer
                  label={t("TRAX.DRAWER_LABELS.PERFORMANCE")}
                >
                  <TraxDrawerButtons
                    icon={TeamMemberIcon}
                    buttonLabel={t("TRAX.DRAWER_LABELS.REVIEWS")}
                  />
                  <TraxDrawerButtons
                    icon={TypeIcon}
                    buttonLabel={t("TRAX.DRAWER_LABELS.DEVELOPMENT")}
                  />
                  <TraxDrawerButtons
                    icon={ComplianceIcon}
                    buttonLabel={t("TRAX.DRAWER_LABELS.COMPLIANCE")}
                  />
                  <TraxDrawerButtons
                    icon={DocumentIcon}
                    buttonLabel={t("TRAX.DRAWER_LABELS.AUDITING_AND_REPORTS")}
                  />
                </TraxDrawerContainer>
              </Stack>
            </Stack>
          </Stack>
        </Drawer>
      </DrawerContainer>
    </>
  );
};

export default TraxDrawer;
