import React from "react";
import TitleContainer from "../../TitleContainer/TitleContainer";
import {
  BadgeDropdownTitleContainer,
  ChipInputField,
  CustomRadioFormControlLabel,
  DropdownWithImageBadge,
  DUMMY_USER_EMAIL_LIST,
  HostDropdown,
} from "../../../../../../../common";
import { RadioGroup } from "@mui/material";
import { KeyWordsMatchStateEnum } from "../../../../CommandControlSearchContainer";

type ComponentDataType = {
  from: any[]; // Array of selected "from" items
  hosts: any[]; // Array of selected "hosts" items
  bodyKeywords: string[]; // Array of body keywords
  matchType: KeyWordsMatchStateEnum; // Selected match type (Exact, Contains, etc.)
};

interface EmailSourceProps {
  stateData: ComponentDataType; // Current data passed from the parent
  setStateData: (data: ComponentDataType) => void; // Function to update data in the parent
}

const EmailSource: React.FC<EmailSourceProps> = ({
  setStateData,
  stateData,
}) => {
  // Handle updates for the "from" field
  const handleFromChange = (newFrom: any[]) => {
    setStateData({ ...stateData, from: newFrom });
  };

  // Handle updates for the "hosts" field
  const handleHostsChange = (newHosts: any[]) => {
    setStateData({ ...stateData, hosts: newHosts });
  };

  // Handle updates for the "bodyKeywords" field
  const handleBodyKeywordsChange = (keywords: string[]) => {
    setStateData({ ...stateData, bodyKeywords: keywords });
  };

  // Handle updates for "matchType" radio buttons
  const handleMatchTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedMatchType = event.target.value as KeyWordsMatchStateEnum;
    setStateData({ ...stateData, matchType: selectedMatchType });
  };

  return (
    <TitleContainer
      title={"Email Source"}
      isCollapasable
      childrenSx={{ gap: "15px" }}
    >
      {/* ------------------------------------------ */}
      <BadgeDropdownTitleContainer title="From">
        <DropdownWithImageBadge
          initialData={stateData.from}
          onChangeSelection={handleFromChange} // Pass handler for from
          alwaysShowPlaceholder
          placeholder="Type here..."
          options={DUMMY_USER_EMAIL_LIST}
          disableAddUserInList
          showMaxSelected={4}
        />
      </BadgeDropdownTitleContainer>

      {/* ------------------------------------------ */}

      <BadgeDropdownTitleContainer title="Hosts">
        <HostDropdown
          getHosts={() => null}
          onChangeSelection={() => null}
          options={[]}
        />
      </BadgeDropdownTitleContainer>

      {/* ------------------------------------------ */}
      <BadgeDropdownTitleContainer title="Body keywords">
        <ChipInputField
          initialChips={stateData.bodyKeywords}
          onChange={handleBodyKeywordsChange} // Pass handler for body keywords
        />
      </BadgeDropdownTitleContainer>

      {/* ------------------------------------------ */}
      <RadioGroup
        aria-labelledby="email-radio-buttons-group"
        name="email-radio-buttons-group"
        value={stateData.matchType}
        onChange={handleMatchTypeChange} // Pass handler for radio buttons
        sx={{ p: 0, marginTop: "5px", gap: "13px" }}
      >
        <CustomRadioFormControlLabel
          value={KeyWordsMatchStateEnum.EXACT_MATCH}
          label="Exact match"
        />
        <CustomRadioFormControlLabel
          value={KeyWordsMatchStateEnum.CONTAINS}
          label="Contains"
        />
        <CustomRadioFormControlLabel
          value={KeyWordsMatchStateEnum.DOES_NOT_CONTAIN}
          label="Does not contain"
        />
      </RadioGroup>
      {/* ------------------------------------------ */}
    </TitleContainer>
  );
};

export default EmailSource;
