import React from "react";
import { PayrollCardHeaderButton, PayrollCardMenu } from "../../common";
import { DealStageCurrencySymbols } from "../../../../common";
import { BonusMiniIcon } from "../../../../../images";

interface NetPayCardPayrollProps {}

const NetPayCardPayroll: React.FC<NetPayCardPayrollProps> = ({}) => {
  const currencySymbol = DealStageCurrencySymbols.USD;
  return (
    <PayrollCardHeaderButton
      bgColor="#21B481"
      currencySymbol={currencySymbol}
      icon={BonusMiniIcon}
      label="Net Pay"
      value={50000.0}
    >
      <PayrollCardMenu
        onButtonClick={() => {
          console.log("ok");
        }}
        currencySymbol={currencySymbol}
        translatedLabel="Total Earnings"
        primaryValue={5000}
      />
      <PayrollCardMenu
        onButtonClick={() => {
          console.log("ok");
        }}
        currencySymbol={currencySymbol}
        translatedLabel="Total Deductions"
        primaryValue={1000}
      />
      <PayrollCardMenu
        onButtonClick={() => {
          console.log("ok");
        }}
        currencySymbol={currencySymbol}
        translatedLabel="Total Allowances"
        primaryValue={2500}
      />
      <PayrollCardMenu
        onButtonClick={() => {
          console.log("ok");
        }}
        currencySymbol={currencySymbol}
        translatedLabel="Total Credits"
        primaryValue={500}
      />
      <PayrollCardMenu
        onButtonClick={() => {
          console.log("ok");
        }}
        currencySymbol={currencySymbol}
        translatedLabel="Total Projects Pay"
        primaryValue={10000}
      />
      <PayrollCardMenu
        onButtonClick={() => {
          console.log("ok");
        }}
        currencySymbol={currencySymbol}
        translatedLabel="Final Net Pay"
        primaryValue={2000}
      />
    </PayrollCardHeaderButton>
  );
};

export default NetPayCardPayroll;
