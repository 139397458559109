import {GlobalPayload, GlobalState} from "../globalTypes";
import {PayloadAction} from "@reduxjs/toolkit";
import {getAllProjectsGlobal__api} from "../globalActions";

export const createScreenRecording__Success = (
    state: GlobalState,
    action: PayloadAction<GlobalPayload>
) => {
    state.error = null;
}


export const getAllScreenRecordings__Success = (
    state: GlobalState,
    action: PayloadAction<GlobalPayload>
) => {
    state.error = null;
}

export const getScreenRecordingById__Success = (
    state: GlobalState,
    action: PayloadAction<GlobalPayload>
) => {
    state.error = null;
}

export const updateScreenRecording__Success = (
    state: GlobalState,
    action: PayloadAction<GlobalPayload>
) => {
    state.error = null;
}

export const deleteScreenRecording__Success = (
    state: GlobalState,
    action: PayloadAction<GlobalPayload>
) => {
    state.error = null;
}

export const saveOrUpdateCoverImage__Success = (
    state: GlobalState,
    action: PayloadAction<GlobalPayload>
) => {
    state.error = null;
    const coverImageData = action.payload.data;
    state.coverImages = {
        messageCenterCoverImageUrl: coverImageData?.messageCenterCoverImageUrl,
        calenderCenterCoverImageUrl: coverImageData?.calenderCenterCoverImageUrl,
        emailCenterCoverImageUrl: coverImageData?.emailCenterCoverImageUrl,
        callCenterCoverImageUrl: coverImageData?.callCenterCoverImageUrl,
        dashboardCoverImageUrl: coverImageData?.dashboardCoverImageUrl,
    };
}

export const getCoverImages__Success = (
    state: GlobalState,
    action: PayloadAction<GlobalPayload>
) => {
    const coverImageData = action.payload.data[0];
    state.coverImages = {
        messageCenterCoverImageUrl: coverImageData?.messageCenterCoverImageUrl,
        calenderCenterCoverImageUrl: coverImageData?.calenderCenterCoverImageUrl,
        emailCenterCoverImageUrl: coverImageData?.emailCenterCoverImageUrl,
        callCenterCoverImageUrl: coverImageData?.callCenterCoverImageUrl,
        dashboardCoverImageUrl: coverImageData?.dashboardCoverImageUrl,
    };
    state.error = null;
}

export const getAllProjectsGlobal__Success = (
    state: GlobalState,
    action: PayloadAction<GlobalPayload>
) => {
    state.loading = false;
    state.error = null;
    state.userProjects = action.payload.data;
}

export const getAllWorkspaceGlobal__Success = (
    state: GlobalState,
    action: PayloadAction<GlobalPayload>
) => {
    state.loading = false;
    state.error = null;
    state.userWorkspaces = action.payload.data;
}