import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DropdownWithImageBadge, DropdownWithImgBadgeInterface } from "../../../../../../common";
import axios from "axios";
import { baseURL, getCurrentIp } from "../../../../../../../redux/middleware/apiMiddleware";
import { getToken } from "../../../../../../common/LocalStorage";

interface FromViewProps {
  updateFilterData: (
    key: "name" | "from" | "subjectKeywords" | "bodyKeywords" | "hosts" | "basicFilters" | "priorityLevel" | "advancedActions",
    value: any
  ) => void;
  fromData?: string[];
}

const FromView: React.FC<FromViewProps> = ({ updateFilterData, fromData }) => {
  const [userListData, setListData] = useState<DropdownWithImgBadgeInterface[]>([]);
  const [selectedInitialValues, setSelectedInitialValues] = useState<DropdownWithImgBadgeInterface[]>([]);
  const token = getToken();

  const handleDropdownChange = (selectedOptions: DropdownWithImgBadgeInterface[]) => {
    const uniqueSelectedValues = Array.from(new Set([...selectedInitialValues, ...selectedOptions]));

    setSelectedInitialValues(uniqueSelectedValues);
    const selectedValuesIds = uniqueSelectedValues.map(option => option._id);

    updateFilterData("from", selectedValuesIds);
  };

  useEffect(() => {
    const getUsers = async () => {
      try {

        const res = await axios.get(`${baseURL}/user`, {
          headers: { Authorization: `Bearer ${token}` , "Content-Type": "application/json",
          Accept: "application/json",
          "X-Current-IP": await getCurrentIp(), }
        });

        setListData(res.data.data)

      } catch (err) {
        console.error("Error fetching user data:", err);
      }
    };

    getUsers();
  }, [token]);



  useEffect(() => {
    if (userListData.length > 0 && fromData) {
      const fromDataIds = fromData.map((user: any) => user._id);
      const initialValues = userListData.filter(user => fromDataIds.includes(user._id));
      setSelectedInitialValues(initialValues);
    }
  }, [fromData, userListData]);
 
  return (
    <Stack
      sx={{
        flexDirection: "row",
        gap: "10px",
        alignItems: "start",
        borderBottom: "0.5px solid #E9EDF2",
        marginTop: "10px",
      }}
    >
      <Typography
        sx={{
          font: "normal normal 300 14px/18px Source Serif Pro",
          paddingTop: "10px",
        }}
      >
        From
      </Typography>
      <DropdownWithImageBadge
        placeholder="Type to select users..."
        options={userListData && userListData}
        onChangeSelection={handleDropdownChange}
        // selectedOptions={selectedInitialValues}
      />
    </Stack>
  );
};

export default FromView;
