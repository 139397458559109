import {
  Button,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";
import {
  AllDirArrowCircleIcon,
  ArrowAllIcon,
  LockIcon,
} from "../../../../../images";

type PayrollCardMenuOptionType = {
  translatedLabel: string;
  primaryValue: number;
  secondryValue?: number;
  currencySymbol: string;
  showReimbursementSymbol?: boolean;
  showLockIcon?: boolean;
  onButtonClick: () => void;
  onClick?: () => void;
  labelBoxWidth?: string;
};

export const PayrollCardMenu: React.FC<PayrollCardMenuOptionType> = ({
  currencySymbol,
  primaryValue,
  secondryValue,
  translatedLabel,
  showLockIcon,
  showReimbursementSymbol,
  onButtonClick,
  labelBoxWidth = "160px",
  onClick,
}) => {
  return (
    <MenuItem
      onClick={onClick}
      sx={{
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
        minHeight: 0,
        padding: "0px 10px",
        height: "30px",
      }}
    >
      <Stack
        onClick={onButtonClick}
        sx={{
          flexDirection: "row",
          flex: 1,
          gap: "10px",
        }}
      >
        <Stack
          sx={{
            width: labelBoxWidth,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Stack
            sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}
          >
            <Typography
              sx={{
                font: "normal normal 300 15px/19px Source Serif Pro",
              }}
            >
              {translatedLabel}
            </Typography>
            {showLockIcon && <LockIcon w={18} color="black" />}
          </Stack>

          {showReimbursementSymbol && (
            <Tooltip
              title="Reimbursement"
              placement="top"
              arrow
              disableInteractive
            >
              <Typography
                sx={{
                  font: "normal normal 700 15px/19px Source Serif Pro",
                  color: "#21B481",
                }}
              >
                R
              </Typography>
            </Tooltip>
          )}
        </Stack>

        <Typography
          sx={{
            font: "normal normal 300 15px/19px Source Serif Pro",
            width: "80px",
          }}
        >
          {currencySymbol}
          {primaryValue}
        </Typography>
        {secondryValue && (
          <Typography
            sx={{
              font: "normal normal 600 15px/19px Source Serif Pro",
              width: "80px",
            }}
          >
            <span style={{ marginRight: "10px" }}>of</span>
            {currencySymbol}
            {secondryValue}
          </Typography>
        )}

        <AllDirArrowCircleIcon direction="right" color="#0027FF" />
      </Stack>
    </MenuItem>
  );
};

interface PayrollCardHeaderButtonProps {
  bgColor: string;
  bgOpacity?: number;
  icon: React.ElementType;
  iconColor?: string;
  IconSize?: number;
  label: string;
  value: string | number;
  currencySymbol: string;
  children: React.ReactNode;
}

export const PayrollCardHeaderButton: React.FC<
  PayrollCardHeaderButtonProps
> = ({
  bgColor,
  bgOpacity = 65,
  currencySymbol,
  label,
  value,
  icon,
  IconSize = 20,
  iconColor = "white",
  children,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChildClick = () => {
    handleClose(); // Closes the menu when a child is clicked
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      // Type assertion ensures `onClick` exists on the props
      return React.cloneElement(
        child as React.ReactElement<{ onClick?: () => void }>,
        {
          onClick: handleChildClick,
        }
      );
    }
    return child;
  });

  return (
    <>
      <Button
        onClick={handleButtonClick}
        sx={{
          flexDirection: "row",
          alignItems: "center",
          width: "430px",
          height: "45px",
          flexShrink: 0,
          textTransform: "none",
          color: "white",
          justifyContent: "space-between",
          padding: "0px 10px",
          borderRadius: "5px 0px",
          bgcolor: adjustHexColor(bgColor, bgOpacity),
          ":hover": {
            bgcolor: adjustHexColor(bgColor, bgOpacity + 10),
          },
        }}
      >
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
          {React.createElement(icon, { w: IconSize, color: iconColor })}
          <Typography
            sx={{
              font: "normal normal 900 15px/19px Source Serif Pro",
              width: "85px",
            }}
          >
            {label}
          </Typography>
          <Typography
            sx={{
              font: "normal normal 900 15px/19px Source Serif Pro",
            }}
          >
            {currencySymbol} {value}
          </Typography>
        </Stack>
        <ArrowAllIcon w={20} open={!!anchorEl} color="white" />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: "430px",
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            mt: "10px",
          },
        }}
      >
        {childrenWithProps}
      </Menu>
    </>
  );
};
