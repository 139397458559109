import React from "react";
import {
  AttendanceListInterface,
  timeCardTableRowsWidth,
} from "../../TimeCardTableType";
import { Stack } from "@mui/material";
import {
  ApprovalActionArea,
  ApprovedBView,
  AttendanceBadge,
  CellWrapper,
  DateView,
} from "../../view";

interface DayOffCardProps {
  data: AttendanceListInterface;
}

const DayOffCard: React.FC<DayOffCardProps> = ({ data }) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        height: "100%",
        // bgcolor: "#FAF3F3",
      }}
    >
      <CellWrapper
        colWidth={
          timeCardTableRowsWidth.ShiftWidth + timeCardTableRowsWidth.BreakWidth
        }
        hideRightBorder
      >
        <ApprovedBView labelText="Scheduled by:" />
      </CellWrapper>

      <CellWrapper hideRightBorder colWidth={timeCardTableRowsWidth.HoursWidth}>
        <AttendanceBadge AttendanceType={data.attendanceType} />
      </CellWrapper>

      <CellWrapper
        hideRightBorder
        colWidth={
          timeCardTableRowsWidth.OvertWidth + timeCardTableRowsWidth.NotesWidth
        }
      ></CellWrapper>
    </Stack>
  );
};

export default DayOffCard;
