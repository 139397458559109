import { Box, Popover, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { TextLinkButton } from "../../../../../../common";

interface AddButttonContainerProps {
  icon: React.ElementType;
  label: string;
  children: React.ReactNode;
  popoverHeight?: string;
  popoverWidth?: string;
}

const AddButttonContainer: React.FC<AddButttonContainerProps> = ({
  children,
  icon,
  label,
  popoverHeight = "250px",
  popoverWidth = "250px",
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
          py: "1px",
        }}
      >
        {React.createElement(icon, { w: 15, color: "black" })}
        <Typography
          sx={{ font: "normal normal 300 12px/15px Source Serif Pro" }}
        >
          {label}
        </Typography>
      </Stack>

      <TextLinkButton
        onClick={handleOpenPopover}
        buttonLabel="Add"
        sx={{
          color: "#0008C6",
          font: "normal normal 400 12px/15px Source Serif Pro",
        }}
      />
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{ sx: { p: 0, marginLeft: "-10px" } }}
      >
        <Box
          sx={{
            width: popoverWidth,
            height: popoverHeight,
            padding: "10px",
          }}
        >
          {children}
        </Box>
      </Popover>
    </Stack>
  );
};

export default AddButttonContainer;
