import React from "react";
import { Stack, Typography } from "@mui/material";
import {
  CheckboxMedium,
  DealStageCurrencySymbols,
} from "../../../../../../common";
import styles from "./ReciptListview.module.css";
import { ExpenseItemType } from "../../ReciptReview";
import { ExpenseStatusType } from "../../../TraxApprovedBadge/TraxApprovedBadge";
import { adjustHexColor } from "../../../../../../../Utils";

interface ReciptListviewProps {
  list: ExpenseItemType[];
  selectedList: ExpenseItemType[];
  setSelectedList: React.Dispatch<React.SetStateAction<ExpenseItemType[]>>;
  selectedTotal?: number;
}

const ReciptListview: React.FC<ReciptListviewProps> = ({
  list,
  selectedTotal = 0,
  selectedList,
  setSelectedList,
}) => {
  const priceWidth = 80;
  const taxWidth = 80;
  const totalsWidth = 80;
  const currencySymbol = DealStageCurrencySymbols.USD;

  const getBgcolor = (status: ExpenseStatusType): string => {
    switch (status) {
      case "pending":
        return "#fff";
      case "approved":
        return adjustHexColor("#008D4C", 30);
      case "rejected":
        return adjustHexColor("#FF0000", 30);
    }
  };

  const handleCheckboxClick = (item: ExpenseItemType) => {
    setSelectedList((prevSelectedList) => {
      // Check if item is already selected
      const isSelected = prevSelectedList.some(
        (selectedItem) => selectedItem._id === item._id
      );

      if (isSelected) {
        // If selected, remove it from the list
        return prevSelectedList.filter(
          (selectedItem) => selectedItem._id !== item._id
        );
      } else {
        // If not selected, add it to the list
        return [...prevSelectedList, item];
      }
    });
  };

  return (
    <Stack
      className={styles["recipt-listview"]}
      gap={"10px"}
      overflow={"hidden"}
    >
      {/* header */}
      <Stack className={styles["recipt-listview__header"]}>
        <Stack className={styles["recipt-listview__item-name"]}>
          Item name
        </Stack>
        <Stack className={styles["recipt-listview__price-tax-totals"]}>
          <Stack
            className={styles["recipt-listview__price"]}
            style={{ width: priceWidth }}
          >
            Price
          </Stack>
          <Stack
            className={styles["recipt-listview__tax"]}
            style={{ width: taxWidth }}
          >
            Tax
          </Stack>
          <Stack
            className={styles["recipt-listview__totals"]}
            style={{ width: totalsWidth }}
          >
            Totals
          </Stack>
        </Stack>
      </Stack>

      {/* body */}
      <Stack className={styles["recipt-listview__body"]}>
        {list.map((item) => (
          <Stack
            key={item._id}
            className={styles["recipt-listview__body-item"]}
          >
            <CheckboxMedium
              checked={selectedList.some(
                (selectedItem) => selectedItem._id === item._id
              )}
              onClick={() => handleCheckboxClick(item)}
            />
            <Stack
              className={styles["recipt-listview__body-content"]}
              sx={{ bgcolor: getBgcolor(item.status || "pending") }}
            >
              <Stack className={styles["recipt-listview__item-name"]}>
                {item.itemName}
              </Stack>
              <Stack className={styles["recipt-listview__price-tax"]}>
                <Stack
                  className={styles["recipt-listview__price"]}
                  style={{ width: priceWidth }}
                >
                  {currencySymbol} {item.price}
                </Stack>
                <Stack
                  className={styles["recipt-listview__tax"]}
                  style={{ width: taxWidth }}
                >
                  {currencySymbol} {item.tax}
                </Stack>
                <Stack
                  className={styles["recipt-listview__totals"]}
                  style={{ width: totalsWidth }}
                >
                  {currencySymbol} {item.total}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        ))}
      </Stack>

      <Stack alignItems={"end"}>
        <Typography className={styles["recipt-listview__total"]}>
          {currencySymbol} {selectedTotal}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ReciptListview;
