import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Typography,
  Box,
  Container,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { BearishOSIcon } from "../../../images";
import { confluence__oauth__handle, Jira__oauth__handle } from "../../../redux";

const CallBack_Workspace = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [status, setStatus] = useState<"loading" | "success" | "error" | null>(
    null
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    const state = params.get("state"); // Extract the `state` parameter

    if (code && state) {
      if (state === "confluence_integration") {
        handleConfluenceVerification(code);
      } else if (state === "jira_integration") {
        handleJiraVerification(code);
      } else {
        setStatus("error");
        console.error("Invalid slug or path for integration");
      }
    } else {
      setStatus("error");
      console.error("No code parameter found in the URL or invalid path");
    }
    // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [location.pathname]);

  const handleConfluenceVerification = async (code: string) => {
    setStatus("loading");
    try {
      const action: any = confluence__oauth__handle(code);
      const response: any = await dispatch(action);

      if (response?.success) {
        setStatus("success");
        setTimeout(() => navigate("/workspace?integration=confluence"), 2000);
      } else {
        setStatus("error");
        console.error("Confluence verification failed");
      }
    } catch (err) {
      setStatus("error");
      console.error("Error during Confluence verification:", err);
    }
  };

  const handleJiraVerification = async (code: string) => {
    setStatus("loading");
    try {
      const action: any = Jira__oauth__handle(code);
      const response: any = await dispatch(action);

      if (response?.success) {
        setStatus("success");
        setTimeout(() => navigate("/workspace?integration=jira"), 2000);
      } else {
        setStatus("error");
        console.error("Jira verification failed");
      }
    } catch (err) {
      setStatus("error");
      console.error("Error during Jira verification:", err);
    }
  };

  const renderContent = () => {
    switch (status) {
      case "loading":
        return (
          <>
            <CircularProgress size={60} />
            <Typography
              sx={{ fontFamily: "Source Serif Pro" }}
              variant="h6"
              mt={2}
            >
              Connecting your account...
            </Typography>
          </>
        );
      case "success":
        return (
          <>
            <CheckCircleIcon sx={{ fontSize: 80, color: "success.main" }} />
            <Typography
              sx={{ fontFamily: "Source Serif Pro" }}
              variant="h5"
              fontWeight="bold"
              mt={2}
            >
              Connection Successful!
            </Typography>
            <Typography
              sx={{ fontFamily: "Source Serif Pro" }}
              variant="body1"
              mt={1}
            >
              Your account is now securely connected. Enjoy seamless integration
              with Bearish OS.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{
                mt: 3,
                px: 4,
                borderRadius: "20px",
                fontFamily: "Source Serif Pro",
                textTransform: "capitalize",
              }}
            >
              Navigating to workspace...
            </Button>
          </>
        );
      case "error":
        return (
          <>
            <ErrorIcon sx={{ fontSize: 80, color: "error.main" }} />
            <Typography
              sx={{ fontFamily: "Source Serif Pro" }}
              variant="h5"
              fontWeight="bold"
              mt={2}
            >
              Something Went Wrong!
            </Typography>
            <Typography
              sx={{ fontFamily: "Source Serif Pro" }}
              variant="body1"
              mt={1}
            >
              We couldn’t connect your account. Please try again or contact
              support.
            </Typography>
            <Button
              variant="outlined"
              color="error"
              sx={{
                fontFamily: "Source Serif Pro",
                mt: 3,
                px: 4,
                borderRadius: "20px",
              }}
              onClick={() => window.location.reload()}
            >
              Retry
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <Card
        elevation={6}
        sx={{
          maxWidth: 600,
          width: "100%",
          borderRadius: "20px",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            py: 4,
            background: "linear-gradient(135deg, #3f51b5, #5c6bc0)",
          }}
        >
          <BearishOSIcon w={70} />
        </Box>
        <CardContent
          sx={{
            px: 4,
            py: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {renderContent()}
        </CardContent>
      </Card>
    </Container>
  );
};

export default CallBack_Workspace;
