import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Skeleton,
} from "@mui/material";
import {
  GreenDropdownIcon,
  HDotsIcon,
  PlusIcon,
  WorkspaceIcon2,
} from "../../../../../../../images";
import g from "./workspaceStyle.module.css";
import { useNavigate } from "react-router-dom";
import { StyledTooltip } from "../../../../../StyledTooltip";
import { AddToWorkspace } from "../../../../../../pages/Workspace/components";
import {
  getAllComponents__api,
  GetAllComponentsParams,
  WorkspaceData,
  WorkspaceState,
  ComponentItem,
  getAllWhiteboards__api,
  getAllDocs__api,
  getAllWikis__api,
  addWikiPage__api,
  AddWikiPage,
  listPagesInSpace__Confluence__api,
  retrieveAllPages__Confluence__api,
  getAllBoards__Jira__api,
} from "../../../../../../../redux";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../redux/store";
import { IconKey, RenderWorkspaceIcon } from "../../../../../../../Utils";
import { renderIconPng } from "../../../../../../pages/Dashboard/components/CommandControlSearchContainer/view/SearchIconView/SearchIconView";

type WorkspaceNameProps = {
  workspace: WorkspaceData;
  open__bar?: boolean;
  minMaxEclip?: boolean;
};

const WorkspaceName: React.FC<WorkspaceNameProps> = ({
  minMaxEclip = false,
  workspace, open__bar = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const workspaceState = useSelector(
    (state: RootState) => state.workspace as WorkspaceState
  );

  const componentsData = workspaceState?.componentsData || {};
  const workspaceComponents = componentsData[workspace._id]?.data.items || [];
  const [documentTypes, setDocumentTypes] = useState<ComponentItem[]>([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [newPageName, setNewPageName] = useState("");
  const [componentsLoader, setComponentsLoader] = useState<boolean>(false);

  const [forPageIds, setForPageIds] = useState({ wid: "", cid: "" });

  const handleNavigate = (
    id: string,
    componentType: string,
    componentId: string,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    subcomponentId?: string
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (componentType === "" && componentId === "") {
      // Navigate only with the id if componentType and componentId are empty
      navigate(`/workspace/${id}?source=${workspace?.source}`);
    } else {
      if (componentType === "Wiki" && !subcomponentId) {
        setDialogOpen(true);
        setForPageIds({ wid: id, cid: componentId });
        return;
      }

      if (subcomponentId) {
        navigate(
          `/workspace/private/${id}/${componentType}/${componentId}/${subcomponentId}?source=${workspace?.source}`
        );
      } else {
        // Navigate with full componentType and componentId if available

        navigate(
          `/workspace/private/${id}/${componentType}/${componentId}?source=${workspace?.source}`
        );
      }
    }
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
    setNewPageName("");
  };

  const handleAddPage = async (workspaceId: string, componentId: string) => {
    if (!workspaceId || !componentId) return;
    const payload: AddWikiPage = {
      title: newPageName,
      content: { text: "content" },
      parentPageId: componentId, // document ID or parent ID
    };
    // Call API to add the page
    await dispatch(addWikiPage__api(componentId, payload, workspaceId));
  };

  const handleCreatePage = async () => {
    // Logic to create a new page with `newPageName`
    // console.log("New page name:", newPageName);
    await handleAddPage(forPageIds?.wid, forPageIds?.cid);
    // After creating the page, close the dialog
    await handleGetWorkspaceItems(true);
    handleDialogClose();
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(open__bar ? true : false);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleGetWorkspaceItems = async (dontReflect?: boolean) => {
    const isLoader = dontReflect ? false : true;

    if (isLoader) {
      setComponentsLoader(true);
    }

    try {
      if (workspace?.source === "Confluence") {
        if (!workspace._id) {
          return;
        }

        await dispatch(
          listPagesInSpace__Confluence__api(workspace?._id, "page")
        );
        await dispatch(
          listPagesInSpace__Confluence__api(workspace?._id, "whiteboard")
        );
        await dispatch(retrieveAllPages__Confluence__api());
      } else if (workspace.source === "Jira") {
        if (!workspace._id) {
          return;
        }
        await dispatch(getAllBoards__Jira__api(workspace?._id));
      } else {
        const payload: GetAllComponentsParams = {
          workspaceId: workspace._id,
          componentType: "Boards",
          page: 1,
          limit: 10,
          sort: "asc",
        };

        const wh = {
          workspaceId: workspace._id,
          page: 1,
          limit: 10,
          sort: "asc",
        };
        const doc = {
          workspaceId: workspace._id,
          page: 1,
          limit: 10,
          sort: "asc",
          filter: "proposal",
          isStarred: false,
        };

        await dispatch(getAllComponents__api(payload));
        await dispatch(getAllWhiteboards__api(wh));
        const docs: any = await dispatch(getAllDocs__api(doc));
        // Extract document types from the fetched documents and update state
        const docTypes = docs?.data?.map((doc: ComponentItem) => ({
          _id: doc?._id,
          name: doc?.name || doc?.title,
          componentType: "Document", // Assuming 'Document' is the type for docs
        }));
        setDocumentTypes(docTypes);
        await dispatch(getAllWikis__api(workspace._id));
      }
    } catch (error) {
      console.error("Failed to get workspace items:", error);
    }
    if (isLoader) {
      setComponentsLoader(false);
    }
  };

  const handleToggleDropdown = (event: any): void => {
    event.preventDefault();
    event.stopPropagation();
    if (!isDropdownOpen) {
      handleGetWorkspaceItems();
    }
    setIsDropdownOpen((prev) => !prev);
  };

  const handleMouseEnter = (): void => {
    setIsHovered(true);
  };

  const handleMouseLeave = (): void => {
    setIsHovered(false);
  };
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = () => {
    setExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (open__bar) {
      handleGetWorkspaceItems();
    }    // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [open__bar])


  return (
    <Box>
      <Stack
        width={"100%"}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Stack
          width={"100%"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          position={"relative"}
          gap={1}
        >
          {" "}
          <img
            style={{
              position: "absolute",
              width: "16px",
              height: "16px",
              top: "7px",
              left: "13px",
            }}
            src={renderIconPng(
              workspace?.source === "Jira"
                ? "jiraPng"
                : workspace?.source === "Confluence"
                  ? "confluencePng"
                  : "bearishPng"
            )}
            alt="bearishPng"
          />
          {isDropdownOpen || isHovered ? (
            <IconButton
              sx={{ padding: "2px" }}
              onClick={(e: any) => handleToggleDropdown(e)}
            >
              <GreenDropdownIcon
                sx={{ width: "12px", height: "15px" }}
                open={isDropdownOpen}
              />
            </IconButton>
          ) : (
            <WorkspaceIcon2 w={20} color="black" />
          )}
          <StyledTooltip title={workspace?.name}>
            <Typography
              className={g.wname}
              sx={{
                maxWidth: minMaxEclip ? "160px" : "120px",
                minWidth: minMaxEclip ? "160px" : "120px",
                position: "relative",
              }}
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                handleNavigate(workspace._id, "", "", e);
              }}
              noWrap={true}
            // children={workspace?.name}
            >
              {workspace?.name}
            </Typography>
          </StyledTooltip>
        </Stack>
        <Stack
          width={"100%"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <StyledTooltip title="More Options">
            <IconButton onClick={(e: any) => { }} sx={{ padding: "2px" }}>
              <HDotsIcon w={20} color="black" />
            </IconButton>
          </StyledTooltip>
          <IconButton sx={{ padding: "2px" }}>
            <AddToWorkspace
              workspaceIds={workspace?._id}
              sources={workspace?.source}
              documentTypes={documentTypes}
              handleGetWorkspaceItems={handleGetWorkspaceItems}
            />
          </IconButton>
        </Stack>
      </Stack>
      {isDropdownOpen &&
        (!componentsLoader ? (
          <Stack height={"100%"} width={"100%"}>
            {/* component mapping below */}

            <Stack
              sx={{
                maxHeight: expanded ? "300px" : "160px", // Example heights
                transition: "max-height 0.3s ease-in-out", // Smooth transition for height change
                overflowY: "auto", // Enable vertical scroll
                overflowX: "hidden", // Hide horizontal scroll
                scrollbarWidth: "none", // For Firefox
                msOverflowStyle: "none", // For Internet Explorer and Edge
                "&::-webkit-scrollbar": {
                  display: "none", // For Chrome, Safari, and Opera
                },
                position: "relative",
              }}
              className={g.dropdownContent}
              width={"100%"}
              gap={"10px"}
            >
              {" "}
              {/* This need overflow hidden and scroll, also hide scollbar */}
              {workspaceComponents?.length > 0 ? (
                workspaceComponents?.map((component: ComponentItem) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      marginLeft: "5px",
                    }}
                    key={component._id}
                    onClick={(e: any) => {
                      e.preventDefault();
                      e.stopPropagation();
                      component?.componentType &&
                        component._id &&
                        handleNavigate(
                          component.workspaceId,
                          component?.componentType,
                          component._id,
                          e
                        );
                    }}
                  >
                    <RenderWorkspaceIcon
                      component={
                        (component?.componentType as IconKey) || "Board"
                      }
                      w={20}
                      color="black"
                    />
                    <Stack width={"100%"} direction={"column"} gap={1}>
                      <Stack
                        width={"92%"}
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Source Serif Pro",
                            fontWeight: "300",
                            fontSize: "13px",
                            lineHeight: "16.29px",
                            color: "black",
                            cursor: "pointer",
                            "&:hover": {
                              fontWeight: "bold",
                              color: "black",
                            },
                          }}
                          variant="body2"
                        >
                          {component?.name || component?.title || ""}
                        </Typography>

                        {component?.componentType === "Wiki" && (
                          <StyledTooltip title="Add Page">
                            <PlusIcon w={12} />
                          </StyledTooltip>
                        )}
                      </Stack>

                      <Stack direction={"column"} gap={1}>
                        {component?.componentType === "Wiki" && (
                          <>
                            {component?.pages?.map(
                              (pageId: any, index: number) => {
                                return (
                                  <div
                                    key={pageId?._id + index}
                                    style={{
                                      font: "normal normal 11px/13px Source Serif Pro",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e: any) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      component?.componentType &&
                                        component._id &&
                                        handleNavigate(
                                          component.workspaceId,
                                          component?.componentType,
                                          component._id,
                                          e,
                                          pageId?._id
                                        );
                                    }}
                                  >
                                    {" "}
                                    {pageId?.title}
                                  </div>
                                );
                              }
                            )}
                          </>
                        )}
                      </Stack>
                    </Stack>
                  </Box>
                ))
              ) : (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ font: "normal normal 500 12px/17px Source Serif Pro" }}
                >
                  There are no components available at the moment.
                </Typography>
              )}
            </Stack>

            {/* Button to show more or less */}
            {workspaceComponents && workspaceComponents?.length > 5 && (
              <Button
                sx={{
                  font: "normal normal 500 12px/17px Source Serif Pro",
                  textTransform: "capitalize",
                  color: "black",
                  marginTop: "10px",
                }}
                onClick={handleToggleExpand}
              >
                {expanded ? "Show less" : "Show more"}
              </Button>
            )}

            {/* button for show less or more */}
          </Stack>
        ) : (
          <Stack mt={1} spacing={1}>
            {Array.from({ length: 3 }, (_, index) => (
              <Skeleton
                key={index}
                variant="rounded"
                width={"100%"}
                height={40}
                sx={{ bgcolor: "lightgray", opacity: 0.6 }}
              />
            ))}
          </Stack>
        ))}

      {/* Dialog for creating a new page */}
      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle sx={{ font: "normal normal 14px/17px Source Serif Pro" }}>
          Create a New Wiki Page
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Page Name"
            fullWidth
            variant="outlined"
            value={newPageName}
            sx={{
              padding: "2px",
            }}
            onChange={(e) => setNewPageName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              font: "normal normal normal 14px/17px Source Serif Pro",
              textTransform: "capitalize",
              color: "red",
            }}
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
          <Button
            sx={{
              font: "normal normal normal 14px/17px Source Serif Pro",
              textTransform: "capitalize",
              color: "green",
            }}
            onClick={handleCreatePage}
            disabled={!newPageName}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default WorkspaceName;
