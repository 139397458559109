export const getContrastingColor = (imageUrl: string): Promise<'black' | 'white'> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.src = imageUrl;

        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            if (!ctx) {
                reject(new Error('Failed to get canvas context'));
                return;
            }

            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0, img.width, img.height);

            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;

            let totalLuminance = 0;
            let pixelCount = 0;

            for (let i = 0; i < imageData.length; i += 4) {
                const r = imageData[i];
                const g = imageData[i + 1];
                const b = imageData[i + 2];
                const alpha = imageData[i + 3];

                // Skip transparent pixels
                if (alpha === 0) continue;

                // Calculate luminance
                const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
                totalLuminance += luminance;
                pixelCount++;
            }

            // If no valid pixels are found
            if (pixelCount === 0) {
                resolve('black'); // Default fallback color
                return;
            }

            const averageLuminance = totalLuminance / pixelCount;

            // Threshold for determining light or dark
            resolve(averageLuminance > 127.5 ? 'black' : 'white');
        };

        img.onerror = () => reject(new Error('Failed to load image'));
    });
};
