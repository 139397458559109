import React from "react";
import {
  EmployeeType,
  employeeTypeBadgeOptionsList,
} from "./EmployeeTypeBadgeType";
import { BadgeDropdown } from "../../common";

interface EmployeeTypeBadgeProps {
  initialValue: EmployeeType;
  onSelection: (value: string) => void;
}

const EmployeeTypeBadge: React.FC<EmployeeTypeBadgeProps> = ({
  initialValue,
  onSelection,
}) => {
  return (
    <BadgeDropdown
      menuLabel="Employee Type"
      initialValue={initialValue}
      options={employeeTypeBadgeOptionsList}
      onSelectionChange={(value) => onSelection(value)}
    />
  );
};

export default EmployeeTypeBadge;
