import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";
import { SmallPinnedCard } from "../../../../common";
import { ButtonGroupOneView, ButtonGroupTwoView } from "./view";
import { MicrosoftTeamsLogoPng } from "../../../../../images";
import styles from "./CommandControlDashboard.module.css";

export type CommandControlDashboardCardVariants =
  | "task"
  | "taskDone"
  | "overdueTask"
  | "message"
  | "flag"
  | "chat"
  | "pendingEvent"
  | "upcomingEvent"
  | "reminderEvent"
  | "call"
  | "postCall"
  | "timeOff"
  | "expense"
  | "payroll"
  | "timesheet"
  | "performace"
  | "development"
  | "compliance";

interface CommandControlDashboardProps {
  cardType: any;
  showDetailsArea?: boolean;
  details?: string;
  dateOrAmmonut?: string;
  handleShowPopup?: () => void;
}

const CommandControlDashboard: React.FC<CommandControlDashboardProps> = ({
  cardType,
  dateOrAmmonut,
  details,
  showDetailsArea = false,
  handleShowPopup
}) => {
  const getProperty = (): {
    tagName: string;
    tagColor: string;
    buttonGroup: "group1" | "group2";
  } => {
    switch (cardType) {
      case "task":
        return { tagName: "@Task", tagColor: "#A53A01", buttonGroup: "group1" };
      case "taskDone":
        return {
          tagName: "@TaskDone",
          tagColor: "#A53A01",
          buttonGroup: "group1",
        };
      case "overdueTask":
        return {
          tagName: "@OverdueTask",
          tagColor: "#A53A01",
          buttonGroup: "group1",
        };
      case "message":
        return {
          tagName: "@message",
          tagColor: "#775EE2",
          buttonGroup: "group1",
        };
      case "flag":
        return { tagName: "@flag", tagColor: "#FF0000", buttonGroup: "group1" };
      case "chat":
        return { tagName: "@chat", tagColor: "#775EE2", buttonGroup: "group1" };
      case "pendingEvent":
        return {
          tagName: "@pendingEvent",
          tagColor: "#B5005B",
          buttonGroup: "group2",
        };
      case "upcomingEvent":
        return {
          tagName: "@upcomingEvent",
          tagColor: "#B5005B",
          buttonGroup: "group1",
        };
      case "reminderEvent":
        return {
          tagName: "@reminderEvent",
          tagColor: "#B5005B",
          buttonGroup: "group1",
        };
      case "call":
        return { tagName: "@call", tagColor: "#FF8181", buttonGroup: "group1" };
      case "postCall":
        return {
          tagName: "@postCall",
          tagColor: "#FF8181",
          buttonGroup: "group1",
        };
      case "timeOff":
        return {
          tagName: "@timeOff",
          tagColor: "#308893",
          buttonGroup: "group1",
        };
      case "expense":
        return {
          tagName: "@expense",
          tagColor: "#308893",
          buttonGroup: "group1",
        };
      case "payroll":
        return {
          tagName: "@payroll",
          tagColor: "#308893",
          buttonGroup: "group1",
        };
      case "timesheet":
        return {
          tagName: "@timesheet",
          tagColor: "#308893",
          buttonGroup: "group1",
        };
      case "performace":
        return {
          tagName: "@performace",
          tagColor: "#308893",
          buttonGroup: "group1",
        };
      case "development":
        return {
          tagName: "@development",
          tagColor: "#308893",
          buttonGroup: "group1",
        };
      case "compliance":
        return {
          tagName: "@compliance",
          tagColor: "#308893",
          buttonGroup: "group1",
        };
      default:
        return { tagName: "", tagColor: "", buttonGroup: "group1" }; // or handle the default case appropriately string;
    }
  };

  const getButton = (buttonGroup: "group1" | "group2") => {
    switch (buttonGroup) {
      case "group1":
        return <ButtonGroupOneView />;
      case "group2":
        return <ButtonGroupTwoView />;
    }
  };

  return (
    <Stack
      className={styles.CommandControlContainer}
      sx={{ bgcolor: adjustHexColor("#ffffff", 65) ,marginBottom:"10px"}}
    >
      <Stack className={styles.CommandControlDashboard}>
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }} onClick={handleShowPopup}>
          <SmallPinnedCard size={20} isActive={true} />
          <Typography className={styles.CommandControlDashboard__userName}>
            Jennifer
          </Typography>

          <Typography
            className={styles.CommandControlDashboard__tag}
            style={{ color: getProperty().tagColor }}
          >
            {getProperty().tagName}
          </Typography>
        </Stack>

        <Stack className={styles.CommandControlDashboard__buttonRow}>
          <Box
            component={"img"}
            src={MicrosoftTeamsLogoPng}
            alt="logo"
            className={styles.CommandControlDashboard__logo}
          />
          {getButton(getProperty().buttonGroup)}
        </Stack>
      </Stack>

      {showDetailsArea && details && (
        <Stack className={styles.CommandControlDashboard__detailsArea}>
          <Typography
            className={styles.CommandControlDashboard__detailsText}
            style={{ width: dateOrAmmonut ? "300px" : "440px" }}
          >
            {details}
          </Typography>
          <Typography sx={{ font: "inherit" }}>{dateOrAmmonut}</Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default CommandControlDashboard;
