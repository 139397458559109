import React, { useState } from "react";
import { DataTable } from "../DataTable";
import { EmployeeExpenseType } from "./ExpenseTableType";
import {
  CellValueChangedEvent,
  ColDef,
  ICellRendererParams,
} from "ag-grid-community";
import {
  TableCategoryArea,
  TableCurrencyArea,
  TableDateArea,
  TableEditButtonArea,
  TableEditTextArea,
  TableExpenseArea,
  TableExpTypeArea,
  TableHeader,
  TableReceiptActedArea,
  TableReceiptStatusArea,
  TableReceiptViewArea,
} from "../../common";
import { AddCalculationsMenu } from "../../../CRM/components";
import {
  BilledIcon,
  CheckboxFilledIcon,
  ConnectedIcon,
  CreatedIcon,
  DateIcon,
  EstimatedARRIcon,
  FundingIcon,
  NotesIcon,
  TagIcon,
} from "../../../../../images";

interface ExpenseTableProps {}

const peopleColumnDefs: ColDef<EmployeeExpenseType>[] = [
  {
    headerName: "Date",
    field: "date",
    headerClass: "my-header-class-trax",
    sortable: true,
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 140,
    width: 140,
    hide: false,
    pinned: "left",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerComponent: TableHeader,
    headerComponentParams: {
      headerSvg: DateIcon,
      fontStyle: "normal normal 300 12px/15px Source Serif Pro",
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <TableDateArea value={params.value} />;
    },
  },

  {
    headerName: "Expense",
    field: "expense",
    sortable: true,
    filter: true,
    headerClass: "my-header-class-trax",
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 100,
    width: 100,
    hide: false,
    headerComponent: TableHeader,
    headerComponentParams: {
      headerSvg: BilledIcon,
      fontStyle: "normal normal 300 12px/15px Source Serif Pro",
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <TableExpenseArea value={params.value} />;
    },
  },
  {
    headerName: "Details",
    field: "details",
    headerClass: "my-header-class-trax",
    sortable: true,
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 170,
    hide: false,
    headerComponent: TableHeader,
    headerComponentParams: {
      headerSvg: CreatedIcon,
      fontStyle: "normal normal 300 12px/15px Source Serif Pro",
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <TableEditTextArea value={params.value} />;
    },
  },

  {
    headerName: "Category",
    field: "category",
    sortable: true,
    filter: true,
    editable: false,
    wrapText: true,
    headerClass: "my-header-class-trax",
    autoHeight: true,
    minWidth: 110,
    width: 110,
    hide: false,
    headerComponent: TableHeader,
    headerComponentParams: {
      headerSvg: TagIcon,
      fontStyle: "normal normal 300 12px/15px Source Serif Pro",
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <TableCategoryArea value={params.value} />;
    },
  },
  {
    headerName: "Type",
    field: "type",
    sortable: true,
    headerClass: "my-header-class-trax",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 110,
    width: 110,
    hide: false,
    headerComponent: TableHeader,
    headerComponentParams: {
      headerSvg: EstimatedARRIcon,
      fontStyle: "normal normal 300 12px/15px Source Serif Pro",
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <TableExpTypeArea value={params.value} />;
    },
  },
  {
    headerName: "Currency",
    field: "currency",
    sortable: true,
    headerClass: "my-header-class-trax",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 100,
    width: 100,
    hide: false,
    headerComponent: TableHeader,
    headerComponentParams: {
      headerSvg: FundingIcon,
      fontStyle: "normal normal 300 12px/15px Source Serif Pro",
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <TableCurrencyArea value={params.value} />;
    },
  },
  {
    headerName: "Receipt",
    field: "recipt",
    sortable: true,
    headerClass: "my-header-class-trax",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 130,
    width: 130,
    hide: false,
    headerComponent: TableHeader,
    headerComponentParams: {
      headerSvg: ConnectedIcon,
      fontStyle: "normal normal 300 12px/15px Source Serif Pro",
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <TableReceiptViewArea value={params.value} />;
    },
  },
  {
    headerName: "Status",
    field: "status",
    sortable: true,
    headerClass: "my-header-class-trax",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 130,
    width: 130,
    hide: false,
    headerComponent: TableHeader,
    headerComponentParams: {
      headerSvg: CheckboxFilledIcon,
      fontStyle: "normal normal 300 12px/15px Source Serif Pro",
      svgColor: "black",
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return (
        <TableReceiptStatusArea
          value={params.value}
          // isShowOpt={!!params.data.recipt}
        />
      );
    },
  },
  {
    headerName: "Acted on by",
    field: "actedBy",
    sortable: true,
    headerClass: "my-header-class-trax",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 130,
    hide: false,
    headerComponent: TableHeader,
    headerComponentParams: {
      headerSvg: CheckboxFilledIcon,
      fontStyle: "normal normal 300 12px/15px Source Serif Pro",
      svgColor: "#0C501C",
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return (
        <TableReceiptActedArea
          value={params.value}
          // isShowOpt={!!params.data.recipt}
        />
      );
    },
  },
  {
    headerName: "Note",
    field: "currency",
    sortable: true,
    headerClass: "my-header-class-trax",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 170,
    hide: false,
    headerComponent: TableHeader,
    headerComponentParams: {
      headerSvg: NotesIcon,
      fontStyle: "normal normal 300 12px/15px Source Serif Pro",
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <TableEditTextArea value={params.value} />;
    },
  },
  {
    headerName: "Edit",
    field: "edit",
    sortable: true,
    headerClass: "my-header-class-trax",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 60,
    width: 60,
    hide: false,
    headerComponent: TableHeader,
    headerComponentParams: {
      fontStyle: "normal normal 300 12px/15px Source Serif Pro",
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <TableEditButtonArea expId={params.data._id} />;
    },
  },
];

const ExpenseTable: React.FC<ExpenseTableProps> = ({}) => {
  const [columnDefs] = useState(peopleColumnDefs);

  const ExpData: EmployeeExpenseType[] = [
    {
      _id: "1",
      expense: "10000",
      details: "some details about this exp is here",
      category: {
        _id: "1",
        badgeData: {
          badgeLabel: "Travel",
          badgeColor: "#4A90E2",
          fontColor: "#000",
        },
        subTypeName: ["Flights", "Ground", "Train", "Other"],
      },
      type: "creditCard",
      currency: "usd",
      recipt: false,
      status: "pending",
      date: "1 jun 2024 monday",
    },
    {
      _id: "2",
      expense: "10000",
      details: "some details about this exp is here",
      category: {
        _id: "1",
        badgeData: {
          badgeLabel: "Travel",
          badgeColor: "#4A90E2",
          fontColor: "#000",
        },
        subTypeName: ["Flights", "Ground", "Train", "Other"],
      },
      type: "creditCard",
      currency: "usd",
      recipt: true,
      status: "pending",
      actedBy: {
        userEmail: "",
        userName: "",
        userAvatarColor: "",
        userIconSrc: "",
      },
      date: "1 jun 2024 monday",
    },
    {
      _id: "3",
      expense: "10000",
      details: "some details about this exp is here",
      category: {
        _id: "1",
        badgeData: {
          badgeLabel: "Travel",
          badgeColor: "#4A90E2",
          fontColor: "#000",
        },
        subTypeName: ["Flights", "Ground", "Train", "Other"],
      },
      type: "cashAdvance",
      currency: "usd",
      recipt: true,
      status: "approved",
      actedBy: {
        userEmail: "",
        userName: "",
        userAvatarColor: "",
        userIconSrc: "",
      },
      date: "1 jun 2024 monday",
    },
  ];

  const updatePeopleData = async (
    event: CellValueChangedEvent<EmployeeExpenseType>
  ) => {
    const updatedData = event.data;
    // Make an API call to update the data
    try {
      console.log("Data updated successfully:", updatedData);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };
  return (
    <DataTable<EmployeeExpenseType>
      columnDefs={columnDefs}
      rowData={ExpData}
      onCellValueChanged={updatePeopleData}
    />
  );
};

export default ExpenseTable;
