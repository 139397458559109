import { Box, Stack } from "@mui/material";
import React from "react";
import {
  BearishPng,
  BoxLogoPng,
  CalendarIconBearishOSIconPng,
  ChecklistIconPng,
  CloudStorageIconPng,
  ConcurLogoPng,
  ConfluenceLogoPng,
  CRMIconPng,
  DocsIconPng,
  DropBoxLogoPng,
  ExchangeLogoPng,
  GmailLogoPng,
  GoogleCalendarLogoPng,
  GoogleDriveLogoPng,
  GoogleMeetLogoPng,
  Grids1IconPng,
  GridsIconPng,
  HRIconPng,
  JiraLogoPng,
  MicrosoftTeamsLogoPng,
  OneDriveLogoPng,
  OutlookCalendarLogoPng,
  OutlookLogoPng,
  SalesforceLogoPng,
  SAPSuccessFactorsLogoPng,
  SlackPng,
  StreamBearishOSLogoIconPng,
  StreamIconBearishOSIconPng,
  TaskCenterIconPng,
  WebexPng,
  WhiteboardsIconPng,
  WikisIconPng,
  ZoomLogoPng,
} from "../../../../../../../images";
import {
  SearchGroups,
  SearchIconPngNameType,
} from "../../CommandControlSearchContainerType";
import { AvatarNormal } from "../../../../../../common";

interface SearchIconViewProps {
  searchGroup: SearchGroups | "clay";
  iconType: SearchIconPngNameType;
  headerUserData?: { name?: string; userImgSrc?: string };
  boxSize?: 20 | 30;
  userName?: string;
  userImgSrc?: string;
}

export const renderIconPng = (iconType: SearchIconPngNameType): string => {
  switch (iconType) {
    case "streamBearishMsgPng":
      return StreamIconBearishOSIconPng;
    case "slackPng":
      return SlackPng;
    case "MicrosoftTeamPng":
      return MicrosoftTeamsLogoPng;
    case "streamBearishCallPng":
      return StreamBearishOSLogoIconPng;
    case "googleMeetPng":
      return GoogleMeetLogoPng;
    case "webexPng":
      return WebexPng;
    case "zoomPng":
      return ZoomLogoPng;
    case "outlookEmailPng":
      return OutlookLogoPng;
    case "gmailPng":
      return GmailLogoPng;
    case "exchangeServerPng":
      return ExchangeLogoPng;
    case "bearishCalendarPng":
      return CalendarIconBearishOSIconPng;
    case "outlookCalendarPng":
      return OutlookCalendarLogoPng;
    case "googleCalendarPng":
      return GoogleCalendarLogoPng;
    case "taskCenterPng":
      return TaskCenterIconPng;
    case "cloudStoragePng":
      return CloudStorageIconPng;
    case "docsPng":
      return DocsIconPng;
    case "gridsPng":
      return GridsIconPng;
    case "wikisPng":
      return WikisIconPng;
    case "checklistPng":
      return ChecklistIconPng;
    case "boardPng":
      return Grids1IconPng;
    case "decksPng":
      return WhiteboardsIconPng;
    case "oneDrivePng":
      return OneDriveLogoPng;
    case "boxPng":
      return BoxLogoPng;
    case "dropboxPng":
      return DropBoxLogoPng;
    case "googleDrivePng":
      return GoogleDriveLogoPng;
    case "jiraPng":
      return JiraLogoPng;
    case "confluencePng":
      return ConfluenceLogoPng;
    case "crmPng":
      return CRMIconPng;
    case "salesForcePng":
      return SalesforceLogoPng;
    case "traxHrPng":
      return HRIconPng;
    case "successFactorPng":
      return SAPSuccessFactorsLogoPng;
    case "concurPng":
      return ConcurLogoPng;
    case "bearishPng":
      return BearishPng;

    default:
      return CloudStorageIconPng;
  }
};

const SearchIconView: React.FC<SearchIconViewProps> = ({
  iconType,
  searchGroup,
  headerUserData,
  boxSize = 30,
  userImgSrc,
  userName,
}) => {
  const getBorderClr = (): string => {
    switch (searchGroup) {
      case "calendar":
        return "#B5005B";
      case "message":
        return "#775EE2";
      case "video":
        return "#FF8181";
      case "email":
        return "#2A30B5";
      case "workspace":
        return "#A53A01";
      case "crm":
        return "#E2875E";
      case "trax":
        return "#308893";
      case "clay":
        return "#E6BA8C";
    }
  };

  const getSize = (): { boxSize: string; imgSize: number } => {
    switch (boxSize) {
      case 20:
        return { boxSize: "20px", imgSize: 13 };
      case 30:
        return { boxSize: "30px", imgSize: 20 };
    }
  };
  return (
    <Stack
      sx={{
        height: getSize().boxSize,
        width: getSize().boxSize,
        borderRadius: "5px",
        border: `1px solid ${getBorderClr()}`,
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        flexShrink: 0,
        bgcolor: "white",
      }}
    >
      {iconType === "userAvatar" ? (
        <AvatarNormal
          size={getSize().imgSize}
          imgSrc={headerUserData?.userImgSrc}
          username={headerUserData?.name}
        />
      ) : (
        <Box
          sx={{ height: getSize().imgSize, width: getSize().imgSize }}
          component={"img"}
          src={renderIconPng(iconType)}
        />
      )}
    </Stack>
  );
};

export default SearchIconView;
