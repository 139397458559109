import {createApiAction} from "../constantApi_config";
import {getAllEvents__FailureAction, getAllEvents__RequestAction, getAllEvents__SuccessAction} from "./dashboardSlice";

export const getAllEventsAndTasksForDashboard__api = () =>
    createApiAction({
        axiosRequired: true,
        method: "GET",
        url: `/calendar/events`,
        onStart: getAllEvents__RequestAction.type,
        onSuccess: getAllEvents__SuccessAction.type,
        onError: getAllEvents__FailureAction.type,
    });