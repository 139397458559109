import { BadgeDropdownMenuType } from "../../common";
export type ReimburseBadgeExpenseType =
  | "reimburse"
  | "creditCard"
  | "cashAdvance"
  | "cAccount";

export const ReimburseMenuExpenseList: BadgeDropdownMenuType<ReimburseBadgeExpenseType>[] =
  [
    {
      _id: "1",
      label: "Reimburse",
      value: "reimburse",
      BadgeColor: "#32CD32",
      fontColor: "black",
      BadgeColorOpacity: 65,
    },
    { _id: "2", divider: true },
    {
      _id: "3",
      label: "Credit Card",
      value: "creditCard",
      BadgeColor: "#4169E1",
      fontColor: "white",
      BadgeColorOpacity: 65,
    },
    {
      _id: "4",
      label: "Cash Advance",
      value: "cashAdvance",
      BadgeColor: "#FFA500",
      fontColor: "black",
      BadgeColorOpacity: 65,
    },
    {
      _id: "5",
      label: "Company Account",
      badgeLabel: "C. Account",
      value: "cAccount",
      BadgeColor: "#696969",
      fontColor: "white",
      BadgeColorOpacity: 65,
    },
  ];
