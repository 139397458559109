import { PayloadAction } from "@reduxjs/toolkit";
import {
    AuthState,
    ExternalAuthState,
    fetchCompanyList,
    globalApiResult,
    OptSendOrVerifyResult,
    TempAuthenticationResult,
    User,
    usersPayload,
} from "../authTypes";

export const loginSuccess = (state: AuthState, action: PayloadAction<User>) => {
    state.isAuthenticated = true;
    /* action?.payload?.data */
    state.user = action.payload;
    state.user_token = action?.payload?.token;
    state.error = null;
};

export const updateUserProfileSuccess = (
    state: AuthState,
    action: PayloadAction<User>
) => {
    state.isAuthenticated = true;

    // Ensure state.user is not null or undefined
    if (state?.user && state?.user?.data) {
        const updatedData = action?.payload?.data?.user;

        // Check and update only the changed fields
        if (updatedData) {
            Object.keys(updatedData).forEach((key) => {
                // Ensure the field exists and only update if it has changed
                if (
                    updatedData[key] !== undefined &&
                    updatedData[key] !== state?.user?.data?.[key]
                ) {
                    if (state?.user?.data) {
                        state.user.data[key] = updatedData[key];
                    }
                }
            });
        }
    }

    state.error = null; // Clear any existing errors after successful update
};

export const optSendSuccess = (
    state: AuthState,
    action: PayloadAction<OptSendOrVerifyResult>
) => {
    state.isOptSend = true;
    state.error = null;
    state.data = action?.payload?.data || null;
};

export const tempAuthStoreSuccess = (
    state: AuthState,
    action: PayloadAction<TempAuthenticationResult>
) => {
    state.email_temp = action.payload.email_temp || "";
    state.password_temp = action.payload.password_temp || "";
    state.timestamp_store_at = action.payload.timestamp_store_at || "";
};

export const companyAvailibitySuccess = (
    state: AuthState,
    action: PayloadAction<any>
) => {
    state.error = null;
    state.companyAvailibility = action?.payload;
};

export const fetchCompanyListSuccess = (
    state: AuthState,
    action: PayloadAction<fetchCompanyList>
) => {
    state.error = null;
    state.companyList = action.payload;
};

export const getAllDepartmentsSuccess = (
    state: AuthState,
    action: PayloadAction<globalApiResult>
) => {
    state.error = null;
    state.departmentList = action?.payload?.data || [];
};

export const optVerificationSuccess = (
    state: AuthState,
    action: PayloadAction<OptSendOrVerifyResult>
) => {
    state.isOptVerify = true;
    state.error = null;
    state.data = action?.payload?.data || null;
};

export const resetOptSend = (state: AuthState) => {
    state.isOptSend = null;
};
export const resetOptVerify = (state: AuthState) => {
    state.isOptVerify = null;
};

export const signUpSuccess = (
    state: AuthState,
    action: PayloadAction<User>
) => {
    state.isSignUp = true;
    state.user = action.payload;
    state.error = null;
};

export const googleAuthSuccess = (
    state: AuthState,
    action: PayloadAction<ExternalAuthState>
) => {
    state.externalEntities = { type: "GoogleAuth" };
    state.message = action?.payload?.message;
    state.eData = action?.payload?.data;
    state.eSuccess = action?.payload?.success;
    state.eError = null; // Extract error if needed
};

export const microsoftAuthSuccess = (
    state: AuthState,
    action: PayloadAction<ExternalAuthState>
) => {
    state.externalEntities = { type: "MicrosoftAuth" };
    state.message = action?.payload?.message;
    state.eData = action?.payload?.data;
    state.eSuccess = action?.payload?.success;
    state.eError = null; // Extract error if needed
};

export const forgotPasswordSuccess = (state: AuthState) => {
    state.error = null;
};
export const createNewGuestSuccess = (
    state: AuthState,
    action: PayloadAction<usersPayload>
) => {
    //@ts-ignore
    state.user = action.payload;
};

export const oAuthCallBackSuccess = (
    state: AuthState,
    action: PayloadAction<any>
) => {
    state.isAuthenticated = true;
    /* action?.payload?.data */
    state.user = action.payload;
    state.user_token = action?.payload?.token;
    state.error = null;
    state.eData = null;
    state.eSuccess = null;
    state.eError = null;
    state.workOSClient = true;
};

export const getAllUsersSuccess = (
    state: AuthState,
    action: PayloadAction<usersPayload>
) => {
    state.error = null;
    state.usersList = action?.payload?.data || [];
    state.users = {
        ...state.users,
        ...action?.payload?.data?.reduce(
            (acc, user) => ({
                ...acc,
                [user._id]: user,
            }),
            {}
        ),
    };
    const usersArray = action.payload.data || [];
    state.usersListWithId = usersArray.reduce<Record<string, User>>(
        (acc, user) => {
            acc[user._id] = user;
            return acc;
        },
        {}
    );
};

export const changeUserPresenceStatusSuccess = ( state: AuthState, action: PayloadAction<any>) => {
    state.error = null;
}