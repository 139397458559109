import React from "react";
import TitleContainer from "../../TitleContainer/TitleContainer";
import { MultiSelectRadioBtn } from "../../../../../../../common";
import { AdditionalFilterMultiRadioBtnType } from "../../../../CommandControlSearchContainer";

const meetingTypeList: AdditionalFilterMultiRadioBtnType = {
  _id: "eventType",
  field: ["calendar"],
  translatedTitle: "Event Type",
  option: [
    { _id: "1", translatedTitle: "Meeting", value: "meeting" },
    { _id: "2", translatedTitle: "Task", value: "task" },
    { _id: "3", translatedTitle: "Appointment", value: "appointment" },
    { _id: "4", translatedTitle: "Bill/Invoice", value: "bill/invoice" },
    { _id: "5", translatedTitle: "Reminder", value: "reminder" },
    { _id: "6", translatedTitle: "All-Day", value: "allDay" },
  ],
};

type ComponentDataType = string[];
interface EventTypeProps {
  stateData: ComponentDataType; // Current data passed from the parent
  setStateData: (data: ComponentDataType) => void; // Function to update data in the parent
}

const EventType: React.FC<EventTypeProps> = ({ setStateData, stateData }) => {
  const handleSelectionChange = (value: string) => {
    const updatedMeetingTypes = stateData.includes(value)
      ? stateData.filter((item) => item !== value) // Remove if already selected
      : [...stateData, value]; // Add if not selected

    // Update the state in the parent
    setStateData(updatedMeetingTypes);
  };
  return (
    <TitleContainer
      title={meetingTypeList.translatedTitle}
      isCollapasable
      childrenSx={{ flexDirection: "row", flexWrap: "wrap" }}
    >
      {meetingTypeList.option.map((item) => (
        <MultiSelectRadioBtn
          sx={{ width: "45%" }}
          key={item._id}
          isChecked={stateData.includes(item.value)}
          label={item.translatedTitle}
          onClick={() => handleSelectionChange(item.value)}
        />
      ))}
    </TitleContainer>
  );
};

export default EventType;
