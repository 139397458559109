/* @EnhancedModernOAuth */

import React, { useEffect, useState } from "react";
import {
  externalEntitiesOAuthCallback,
  externalEntitiesOptions,
} from "../../../redux/Slice/auth/authActions";
import { useDispatch } from "react-redux";
import {
  CircularProgress,
  Typography,
  Box,
  Container,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { BearishOSIcon } from "../../../images";
import { setToken } from "../../common/LocalStorage";
import {
  cookieDomain,
  ENABLE_WORKOS_MODE,
  setAuthCookie,
} from "../../../Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { userSetup } from "../../../redux/Slice/auth/authSlice";

const CallBack = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation(); // Get current location (path)
  const [status, setStatus] = useState<"loading" | "success" | "error" | null>(
    null
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    const slug = location.pathname.split("/")[1]; // Extract 'oauth' or 'invitee' from the path

    if (code && slug) {
      handleCodeVerification(slug, code);
    } else {
      setStatus("error");
      console.error("No code parameter found in the URL or invalid path");
    }
  }, [location.pathname]); // Re-run effect if the path changes

  const handleCodeVerification = async (slug: string, code: string) => {
    setStatus("loading");
    try {
      if (!code) return;

      /* slug -> flow (invitee) */

      const action = externalEntitiesOAuthCallback(code, slug);
      const callBackRes: any = await dispatch(action);

      if (
        callBackRes?.state?.auth?.error &&
        callBackRes?.state?.auth?.error?.message === "Something went wrong"
      ) {
        setStatus("error");
      } else if (
        callBackRes?.success &&
        callBackRes?.data &&
        callBackRes?.token
      ) {
        setStatus("success");

        setToken(callBackRes?.token);
        await setAuthCookie(callBackRes?.token, callBackRes?.data?._id);

        // Delay navigation to the dashboard by 2 seconds
        setTimeout(() => {
          navigationToDashboard(callBackRes);
        }, 2000); // 2000 ms = 2 seconds
      } else {
        setStatus("error");
      }
    } catch (err) {
      setStatus("error");
      console.error("Error in handleCodeVerification:", err);
    }
  };

  const navigationToDashboard = async (callBackRes: any) => {
    await dispatch(userSetup(callBackRes));

    // Redirect based on subdomain
    const subdomain: any = callBackRes?.data?.companyId?.name
      ?.toLowerCase()
      ?.replace(/\s+/g, "");

    if (subdomain && ENABLE_WORKOS_MODE) {
      window.location.href = `http://${subdomain}${
        cookieDomain === ".localtest.me" ? ".localtest.me:3000" : cookieDomain
      }/dashboard`;
    } else {
      navigate("/dashboard");
    }
  };

  const handleRe_login = async () => {
    try {
      const res: any = await dispatch(
        externalEntitiesOptions({ optionAuth: "MicrosoftOAuth" })
      );

      if (res?.success && res?.data) {
        window.location.href = res?.data; // Redirect to eData path after 3 seconds
      }
    } catch (err) {
      console.error("error : ", err);
    }
  };

  const renderContent = () => {
    switch (status) {
      case "loading":
        return (
          <>
            <CircularProgress size={60} />
            <Typography
              sx={{ fontFamily: "Source Serif Pro" }}
              variant="h6"
              mt={2}
            >
              Connecting your account...
            </Typography>
          </>
        );
      case "success":
        return (
          <>
            <CheckCircleIcon
              sx={{
                fontSize: 80,
                color: "success.main",
                animation: "bounceIn 0.6s ease-out",
              }}
            />
            <Typography
              sx={{ fontFamily: "Source Serif Pro" }}
              variant="h5"
              fontWeight="bold"
              mt={2}
              color="text.primary"
            >
              Connection Successful!
            </Typography>
            <Typography
              sx={{ fontFamily: "Source Serif Pro" }}
              variant="body1"
              mt={1}
              color="text.secondary"
            >
              Your account is now securely connected. Enjoy seamless integration
              with Bearish OS.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{
                mt: 3,
                px: 4,
                borderRadius: "20px",
                fontFamily: "Source Serif Pro",
                textTransform: "capitalize",
              }}
            >
              Navigating to dashboard...
            </Button>
          </>
        );
      case "error":
        return (
          <>
            <ErrorIcon
              sx={{
                fontSize: 80,
                color: "error.main",
                animation: "shake 0.5s ease-out",
              }}
            />
            <Typography
              sx={{ fontFamily: "Source Serif Pro" }}
              variant="h5"
              fontWeight="bold"
              mt={2}
              color="text.primary"
            >
              Something Went Wrong!
            </Typography>
            <Typography
              sx={{ fontFamily: "Source Serif Pro" }}
              variant="body1"
              mt={1}
              color="text.secondary"
            >
              We couldn’t connect your account. Please try again or contact
              support if the issue persists.
            </Typography>
            <Button
              variant="outlined"
              color="error"
              sx={{
                fontFamily: "Source Serif Pro",
                mt: 3,
                px: 4,
                borderRadius: "20px",
              }}
              onClick={() => {
                handleRe_login();
              }}
            >
              Retry
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        width: "100%",
        fontFamily: "Source Serif Pro, serif",
      }}
    >
      <Card
        elevation={6}
        sx={{
          maxWidth: 600,
          width: "100%",
          borderRadius: "20px",
          textAlign: "center",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            background: "linear-gradient(135deg, #3f51b5, #5c6bc0)",
            py: 4,
          }}
        >
          <BearishOSIcon w={70} />
        </Box>
        <CardContent
          sx={{
            px: 4,
            py: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {renderContent()}
        </CardContent>
      </Card>
    </Container>
  );
};

export default CallBack;
