import { Stack, Typography } from "@mui/material";
import React from "react";
import CellWrapper from "../CellWrapper/CellWrapper";

interface HeaderCellProps {
  translatedLabel: string;
  icon: React.ElementType;
  iconColor?: string;
  colWidth: number;
}

const HeaderCell: React.FC<HeaderCellProps> = ({
  icon,
  translatedLabel,
  iconColor = "black",
  colWidth,
}) => {
  return (
    <CellWrapper colWidth={colWidth}>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
          justifyContent: "start",
        }}
      >
        <span style={{ flexShrink: 0, paddingTop: "2px" }}>
          {React.createElement(icon, { w: 15, color: iconColor })}
        </span>
        <Typography
          sx={{ font: "normal normal 300 12px/15px Source Serif Pro" }}
        >
          {translatedLabel}
        </Typography>
      </Stack>
    </CellWrapper>
  );
};

export default HeaderCell;
