import {
  Box,
  IconButton,
  Stack,
  SxProps,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { VoiceRecordIcon, WebexPng, XCircleIcon } from "../../../images";
import { EmailRichTextMenu } from "../EmailRichTextMenu";
import { SendMessageButton } from "../Buttons";
import { SendMessageButtonColor } from "../Buttons/AllButtonProps";
import { SmallPinnedCard } from "../SmallPinnedCard";
import { DropdownWithImageBadge } from "../DropdownWithImageBadge";
import { DUMMY_USER_EMAIL_LIST } from "../DropdownWithImageBadge/DropdownWithImageBadgeTypes";
import styles from "./MessageSmallerPopout.module.css";
import { EmojiPickerSmall } from "../EmojiPickerSmall";
import { QuickMessagePreviewBox } from "../QuickMessagePreviewBox";
import { QuickMessagePreviewBoxProps } from "../QuickMessagePreviewBox/QuickMessagePreviewBoxType";
import { AttechmentViewContainer } from "../AttechmentViewContainer";
import { AttachmentWithIconSmall } from "../AttachmentWithIconSmall";
import { useTranslation } from "react-i18next";
import { adjustHexColor } from "../../../Utils";

interface MessageSmallerPopoutProps {
  onBackButtonClick: () => void;
  sx?: SxProps;
  hideXbutton?: boolean;
}

const MessageSmallerPopout: React.FC<MessageSmallerPopoutProps> = ({
  onBackButtonClick,
  hideXbutton = false,
  sx,
}) => {
  const { t } = useTranslation();
  return (
    <Stack
      className={styles.MessageSmallerPopout}
      sx={{ width: "100%", ...sx }}
    >
      <Stack className={styles.MessageSmallerPopout__header}>
        <Stack className={styles.MessageSmallerPopout__headerUserInfo}>
          <SmallPinnedCard
            size={30}
            isActive={false}
            userName="John"
            userIconSrc="https://randomuser.me/api/portraits/men/22.jpg"
          />
          <Typography className={styles.MessageSmallerPopout__headerUserName}>
            User name here Lorem ipsum dolor, sit amet consectetur adipisicing
            elit. Corrupti, optio?
          </Typography>
          <Box
            component={"img"}
            src={WebexPng}
            alt="logo"
            sx={{ height: "15px", width: "15px", marginLeft: "10px" }}
          />
        </Stack>

        <IconButton
          sx={{ p: "2px", display: hideXbutton ? "none" : "block" }}
          disableRipple
          onClick={onBackButtonClick}
        >
          <XCircleIcon w={15} />
        </IconButton>
      </Stack>

      <Stack className={styles.MessageSmallerPopout__messageHeader}>
        <Typography className={styles.MessageSmallerPopout__messageHeaderTitle}>
          {t("MESSAGE.TO")}
        </Typography>
        <DropdownWithImageBadge
          options={DUMMY_USER_EMAIL_LIST}
          onChangeSelection={(value) => console.log(value)}
        />
      </Stack>
      <Stack className={styles.MessageSmallerPopout__messageContainer}>
        {/*-- message container-- */}
        <Stack sx={{ gap: "10px" }}>
          {DUMMY_MESSAGES.map((message, index) => (
            <QuickMessagePreviewBox
              key={index}
              messageSender={message.messageSender}
              messageText={message.messageText}
              messageType={message.messageType}
              timeStamp={message.timeStamp}
            />
          ))}
        </Stack>
      </Stack>

      {/* --------- Attachment Container Here --------- */}
      <Stack sx={{ gap: "10px",padding:"10px" }}>
        <Typography
          sx={{ font: "normal normal 300 14px/18px Source Serif Pro" }}
        >
          Attachments
        </Typography>
        <AttechmentViewContainer>
          {Array.from({ length: 1 }, (_, index) => (
            <AttachmentWithIconSmall
              showDeleteButton={false}
              key={index}
              fileName={`file${index + 1}.mp4`}
            />
          ))}
          {Array.from({ length: 3 }, (_, index) => (
            <AttachmentWithIconSmall
              key={index}
              fileName={`file${index + 1}.mp4`}
              showDeleteButton
              onDeleteButtonClick={() => null}
            />
          ))}
        </AttechmentViewContainer>
      </Stack>
      {/* --------- Attachment Container End --------- */}

      <Stack className={styles.MessageSmallerPopout__footer}>
        <TextareaAutosize
          placeholder="Type your text messages here"
          minRows={1}
          style={{
            backgroundColor:adjustHexColor("#ffffff", 25),
            color: "black",
            maxHeight: "200px", // Maximum height for the textarea
            scrollbarWidth: "none", // Hide scrollbar in Firefox
            flexGrow: 1, // Allow textarea to grow
            resize: "none", // Disable resizing
            border: "0px", // Remove border
            padding: "0px 0px 0px 10px", // Remove padding
            outline: "none", // Remove outline
            font: "normal normal 300 13px/16px Source Serif Pro", // Font style
            overflow: "hidden", // Hide any overflow in other directions
            marginBottom: "6px",
          }}
        />
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "15px" }}>
          <Tooltip title="Record Audio" placement="top" arrow>
            <IconButton sx={{ p: 0, borderRadius: "0px" }} disableRipple>
              <VoiceRecordIcon w={20} />
            </IconButton>
          </Tooltip>

          <EmojiPickerSmall
            w={20}
            onSelect={(emoji) => null}
            tooltipTitle="Add Emoji"
          />

          <EmailRichTextMenu />
          <SendMessageButton
            colorVarient={SendMessageButtonColor.MessagePurple}
            buttonSize={30}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MessageSmallerPopout;

const DUMMY_MESSAGES: QuickMessagePreviewBoxProps[] = [
  {
    messageType: "send",
    messageSender: "Alice",
    messageText: "Hey, are you available for a call?",
    timeStamp: "2024-08-12T09:15:00Z",
  },
  {
    messageType: "received",
    messageSender: "Bob",
    messageText: "Yes, I am free now. Let's talk.",
    timeStamp: "2024-08-12T09:16:30Z",
  },
  {
    messageType: "send",
    messageSender: "Alice",
    messageText: "Great! I'll call you in a minute.",
    timeStamp: "2024-08-12T09:17:45Z",
  },
  {
    messageType: "received",
    messageSender: "Bob",
    messageText: "Sounds good. See you soon.",
    timeStamp: "2024-08-12T09:18:00Z",
  },
  {
    messageType: "send",
    messageSender: "Alice",
    messageText: "Can you send me the report?",
    timeStamp: "2024-08-12T09:20:30Z",
  },
  {
    messageType: "received",
    messageSender: "Bob",
    messageText: "Sure, I'll send it over shortly.",
    timeStamp: "2024-08-12T09:22:15Z",
  },
  {
    messageType: "send",
    messageSender: "Alice",
    messageText:
      "Have you reviewed the latest vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvdraft?",
    timeStamp: "2024-08-12T09:25:00Z",
  },
  {
    messageType: "received",
    messageSender: "Bob",
    messageText:
      "Not yet, I'll get to it by ffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff of day.",
    timeStamp: "2024-08-12T09:27:10Z",
  },
  {
    messageType: "send",
    messageSender: "Alice",
    messageText: "Meeting scheduled for 3 PM today.",
    timeStamp: "2024-08-12T09:30:00Z",
  },
  {
    messageType: "received",
    messageSender: "Bob",
    messageText: "Got it. I'll be there.",
    timeStamp: "2024-08-12T09:32:30Z",
  },
  {
    messageType: "send",
    messageSender: "Alice",
    messageText: "Did you receive my email?",
    timeStamp: "2024-08-12T09:35:15Z",
  },
  {
    messageType: "received",
    messageSender: "Bob",
    messageText: "Yes, I'll review it this afternoon.",
    timeStamp: "2024-08-12T09:37:00Z",
  },
  {
    messageType: "send",
    messageSender: "Alice",
    messageText: "Let's finalize the project details.",
    timeStamp: "2024-08-12T09:40:30Z",
  },
  {
    messageType: "received",
    messageSender: "Bob",
    messageText: "Sure, we can discuss it later today.",
    timeStamp: "2024-08-12T09:42:15Z",
  },
  {
    messageType: "send",
    messageSender: "Alice",
    messageText: "Please confirm the meeting time.",
    timeStamp: "2024-08-12T09:45:00Z",
  },
  {
    messageType: "received",
    messageSender: "Bob",
    messageText: "Confirmed. See you at 3 PM.",
    timeStamp: "2024-08-12T09:47:30Z",
  },
  {
    messageType: "send",
    messageSender: "Alice",
    messageText: "I have updated the document.",
    timeStamp: "2024-08-12T09:50:15Z",
  },
  {
    messageType: "received",
    messageSender: "Bob",
    messageText: "Thanks for the update. I'll check it.",
    timeStamp: "2024-08-12T09:52:00Z",
  },
  {
    messageType: "send",
    messageSender: "Alice",
    messageText: "Can you send me the latest numbers?",
    timeStamp: "2024-08-12T09:55:30Z",
  },
  {
    messageType: "received",
    messageSender: "Bob",
    messageText: "I'll get them to you by noon.",
    timeStamp: "2024-08-12T09:57:15Z",
  },
  {
    messageType: "send",
    messageSender: "Alice",
    messageText: "Reminder: Project deadline is tomorrow.",
    timeStamp: "2024-08-12T10:00:00Z",
  },
  {
    messageType: "received",
    messageSender: "Bob",
    messageText: "Got it. I'll ensure everything is ready.",
    timeStamp: "2024-08-12T10:02:30Z",
  },
];
