import React, { useEffect, useRef, useState } from "react";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "./style.css";
import FroalaEditorComponent from "react-froala-wysiwyg";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import {Box, Button, IconButton, Typography} from "@mui/material";
import Tribute from "tributejs";
import { StyleIcon } from "../../../images";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { uploadCloudFile__api } from "../../../redux/Slice/CloudStorage/CloudStorageAction";

interface User {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface CustomComponentProps {
  handleSearchChanges: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSendMessage: any;
  value?: string;
  currentChannel?: any;
}

const CustomFroalaEditor: React.FC<CustomComponentProps> = ({
                                                              handleSearchChanges,
                                                              handleSendMessage,
                                                              value = "",
                                                              currentChannel,
                                                            }: CustomComponentProps) => {
  const { t } = useTranslation();
  const [editorData, setEditorData] = useState<string>(value);
  const editorRef: any = useRef<FroalaEditorComponent>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setEditorData(value);
  }, [value]);

  useEffect(() => {
    setEditorData("");
  }, [currentChannel]);

  const getTributeValues = (users: User[]) => {
    return users?.map((user) => ({
      key: `${user.firstName} ${user.lastName}`,
      value: `${user.firstName} ${user.lastName}`,
    }));
  };

  const toggleToolbar = () => {
    const toolbar = document.getElementsByClassName("fr-toolbar")?.[0];
    if (toolbar) {
      if (toolbar.classList.contains("fr-toolbar-visible")) {
        toolbar.classList.remove("fr-toolbar-visible");
        toolbar.classList.add("fr-toolbar-hide");
      } else {
        toolbar.classList.remove("fr-toolbar-hide");
        toolbar.classList.add("fr-toolbar-visible");
      }
    } else {
      editorRef.current?.element?.focus?.();
    }
  };

  const onFocusTextarea = () => {
    const toolbar = document.getElementsByClassName("fr-toolbar")?.[0];
    if (toolbar) {
      toolbar.classList.remove("fr-toolbar-visible");
      toolbar.classList.add("fr-toolbar-hide");
    }
  };

  const onBlurTextarea = () => {
    const toolbar = document.getElementsByClassName("fr-toolbar")?.[0];

    if (toolbar) {
      toolbar.classList.add("fr-toolbar-hide");
      toolbar.classList.remove("fr-toolbar-visible");
    }
  };

  useEffect(() => {
    if (currentChannel?.participants) {
      const tribute = new Tribute({
        collection: [
          {
            trigger: "@",
            values: getTributeValues(currentChannel?.participants),
          },
          {
            trigger: "/flag",
            values: [
              { key: "Low", value: "/Low" },
              { key: "Medium", value: "/Medium" },
              { key: "High", value: "/High" },
            ],
            selectTemplate: (item) => `${item.original.value}`,
          },
        ],
      });

      if (editorRef.current && editorRef.current.getEditor()) {
        tribute.attach(editorRef.current.getEditor().$el[0]);
      }

      return () => {
        if (editorRef.current && editorRef.current.getEditor()) {
          tribute.detach(editorRef.current.getEditor().$el[0]);
        }
      };
    }
  }, [editorRef, currentChannel?.participants]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSendMessage();
        setEditorData("");
      }
    };

    const editorElement = editorRef.current?.getEditor()?.$el[0];
    if (editorElement) {
      editorElement.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (editorElement) {
        editorElement.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [handleSendMessage]);

  const uploadImage = async (file: any) => {
    const formData = new FormData();
    formData.append("files", file);

    const response: any = await dispatch(uploadCloudFile__api(formData));
    const fileLink = response.data?.[0].path; // Assuming the API returns the link in the payload
    return fileLink;
  };

  const uploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append("files", file);

    const response: any = await dispatch(uploadCloudFile__api(formData));
    const fileLink = response.data?.[0].path; // Assuming the API returns the link in the payload
    return fileLink;
  };

  return (
      <>
        <Button
            variant="text"
            onClick={toggleToolbar}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "transparent",
              boxShadow: "none",
              border: "none",
              padding: 0,
              minWidth: "40px",
              textTransform: "none",
              cursor: "pointer",
              color: "inherit",
              fontWeight:"300"
            }}
        >
          <StyleIcon style={{ width: "24px", height: "24px", marginBottom: "4px", color: "black" }} />
          <Typography
              sx={{
                fontFamily: "Source Serif Pro, serif",
                fontWeight: 300, // Light font
                fontSize: "14px", // Text size
                lineHeight: "18px", // Matches proportional spacing
                textAlign: "center",
              }}
          >
            {t("MESSAGE.STYLE")}
          </Typography>
        </Button>
        <Box width="100%">
          <FroalaEditorComponent
              ref={editorRef}
              model={editorData}
              onModelChange={(e: any) => {
                setEditorData(e);
                handleSearchChanges(e);
              }}
              config={{
                placeholderText: "Type your text messages here",
                events: {
                  focus: onFocusTextarea,
                  blur: onBlurTextarea,
                  'image.beforeUpload': async function (files: any) {
                    const editor: any = this;
                    if (files.length) {
                      const fileLink = await uploadImage(files[0]);
                      editor.image.insert(fileLink, null, null, editor.image.get());
                    }
                    return false;
                  },
                  'file.beforeUpload': async function (files: any) {
                    const editor: any = this;
                    if (files.length) {
                      const fileLink = await uploadFile(files[0]);
                      editor.file.insert(fileLink, null, null, editor.file.get());
                    }
                    return false;
                  },
                },
                toolbarInline: false,
                showtoolbarInline: false,
                toolbarVisibleWithoutSelection: false,
                key: "8JF3bB2A5A4C3A1E2E2zPAENHMf1JPQRFZBTBf1WWEPYDbB3H3E2A16A19B7C5C6A2C3==", // License key to remove watermark
                attribution: false, // This will ensure the Froala watermark is removed
                charCounterCount: false,
                quickInsertButtons: [],
                quickInsertTags: [],
                pluginsEnabled: ["link", "image", "file"],
                toolbarButtons: [
                  "bold",
                  "italic",
                  "underline",
                  "insertLink",
                  "insertImage",
                  "insertFile",
                ],
                // Add custom CSS class
                htmlAllowedAttrs: ['style'],
                htmlAllowedTags: ['.*'],
                htmlRemoveTags: [],
                htmlRemoveAttrs: [],
                htmlAllowedEmptyTags: ['textarea', 'a', 'iframe', 'object', 'video', 'style', 'script'],
                htmlDoNotWrapTags: ['script', 'style'],
                htmlUntouched: true,
                contentChanged: function () {
                  const editor = this as any;
                  editor.$el.find('p').css({
                    'font-family': 'Source Serif Pro, serif',
                    'font-weight': 300,
                    'font-size': '13px',
                    'line-height': '16.29px'
                  });
                }
              }}
              tag="textarea"
          />
        </Box>
      </>
  );
};

export default CustomFroalaEditor;