import { Stack } from "@mui/material";
import React from "react";
import { HeaderView, ImagePreviewAreaView } from "./view";

interface QuickViewReceiptProps {}

const QuickViewReceipt: React.FC<QuickViewReceiptProps> = ({}) => {
  return (
    <Stack
      sx={{
        height: "100%",
        width: "435px",
        bgcolor: "white",
        overflow: "hidden",
      }}
    >
      <HeaderView />

      <Stack
        sx={{
          overflow: "auto",
          scrollbarWidth: "none",
          padding: "15px 20px 20px 20px",
          gap: "40px",
        }}
      >
        <ImagePreviewAreaView />
        <ImagePreviewAreaView />
        <ImagePreviewAreaView />
        <ImagePreviewAreaView />
        <ImagePreviewAreaView />
        <ImagePreviewAreaView />
        <ImagePreviewAreaView />
        <ImagePreviewAreaView />
      </Stack>
    </Stack>
  );
};

export default QuickViewReceipt;
