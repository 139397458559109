import React from "react";
import { ExpensesBadgesMenu, ExpensesBadgesMenuType } from "../../components";
import { Stack } from "@mui/material";

interface TableCategoryAreaProps {
  value: ExpensesBadgesMenuType;
}

const TableCategoryArea: React.FC<TableCategoryAreaProps> = ({ value }) => {
  return (
    <Stack alignItems={"center"}>
      <ExpensesBadgesMenu initialData={value} />
    </Stack>
  );
};

export default TableCategoryArea;
