import React, { useState } from "react";
import { Divider, IconButton, Popover, Stack, Typography } from "@mui/material";
import { CheckboxMedium } from "../../../CheckboxMedium";
import { AllDirArrowCircleIcon } from "../../../../../images";
import { FilterDocumentsSearchOptionsType } from "../../FilterDocumentsSearchType";

interface FilterCheckBoxMenuProps {
  menuTranslatedLabel?: string;
  options: FilterDocumentsSearchOptionsType[];
  selectedList: string[];
  checkBoxClickHandler: (_id: string) => void;
}
const FilterCheckBoxMenu: React.FC<FilterCheckBoxMenuProps> = ({
  options,
  menuTranslatedLabel,
  checkBoxClickHandler,
  selectedList = [],
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [menuLabel, setMenuLabel] = useState<string | undefined>();
  const [popoverContent, setPopoverContent] = useState<
    FilterDocumentsSearchOptionsType[]
  >([]);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    children: FilterDocumentsSearchOptionsType[],
    menuLabel?: string
  ) => {
    setAnchorEl(event.currentTarget);
    setMenuLabel(menuLabel);
    setPopoverContent(children);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Stack
      sx={{
        border: "1px solid #E9EDF2",
        borderRadius: "5px",
        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
        boxSizing: "border-box",
        bgcolor: "#fff",
        padding: "16px 10px 10px 10px",
        minWidth: "150px",
        width: "fit-content",
        gap: "15px",
      }}
    >
      {menuTranslatedLabel && (
        <Typography
          sx={{
            font: "normal normal 300 15px/19px Source Serif Pro",
          }}
        >
          {menuTranslatedLabel}
        </Typography>
      )}

      {options.map((item) =>
        item.divider ? (
          <Divider key={item._id} />
        ) : (
          <Stack
            key={item._id}
            sx={{
              flexDirection: "row",
              alignItems: "start",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <Stack
              onClick={() => checkBoxClickHandler(item._id)}
              sx={{
                flexDirection: "row",
                alignItems: "start",
                gap: "10px",
                cursor: "pointer",
              }}
            >
              <CheckboxMedium
                checked={selectedList.some((opt) => opt === item._id)}
              />
              <Typography
                sx={{
                  font: "normal normal 300 15px/19px Source Serif Pro",
                }}
              >
                {item.translatedLabel}
              </Typography>
            </Stack>

            {item.children && (
              <IconButton
                sx={{ p: 0 }}
                disableRipple
                onClick={(e) =>
                  handlePopoverOpen(e, item.children!, item.translatedLabel)
                }
              >
                <AllDirArrowCircleIcon direction="right" w={15} />
              </IconButton>
            )}
          </Stack>
        )
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            marginLeft: "20px",
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <FilterCheckBoxMenu
          selectedList={selectedList}
          options={popoverContent}
          menuTranslatedLabel={menuLabel}
          checkBoxClickHandler={checkBoxClickHandler}
        />
      </Popover>
    </Stack>
  );
};

export default FilterCheckBoxMenu;
