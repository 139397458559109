import { IconButton, Stack, Tooltip } from "@mui/material";
import React from "react";
import { CheckboxFilledIcon, XCircleIcon } from "../../../images";

interface AcceptRejectButtonGroupProps {
  onAcceptClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onRejectClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  iconSize?: number;
}

const AcceptRejectButtonGroup: React.FC<AcceptRejectButtonGroupProps> = ({
  onAcceptClick,
  onRejectClick,
  iconSize = 20,
}) => {
  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
      <Tooltip title="Approve" placement="top" arrow>
        <IconButton
          sx={{ p: 0, borderRadius: 0 }}
          disableRipple
          onClick={(event) => onAcceptClick?.(event)}
        >
          <CheckboxFilledIcon color="#21B481" w={iconSize} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Reject" placement="top" arrow>
        <IconButton
          sx={{ p: 0, borderRadius: 0 }}
          disableRipple
          onClick={(event) => onRejectClick?.(event)}
        >
          <XCircleIcon color="#FF0000" w={iconSize} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default AcceptRejectButtonGroup;
