import React from "react";
import {
  InputBase,
  InputBaseProps,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";

interface MiniPopupContainerProps {
  headerIcon: React.ElementType;
  headerTitle: string;
  headerTitleSx?: SxProps;
  subtitle?: string;
  subTitleSx?: SxProps;
  containerWidth?: string;
  children: React.ReactNode;
  childrenContainerSx?: SxProps;
  containerSx?: SxProps;
}

export const MiniPopupContainer: React.FC<MiniPopupContainerProps> = ({
  children,
  headerIcon,
  headerTitle,
  subtitle,
  containerWidth = "230px",
  childrenContainerSx,
  headerTitleSx,
  subTitleSx,
  containerSx,
}) => {
  return (
    <Stack
      sx={{
        padding: "15px",
        boxSizing: "border-box",
        width: containerWidth,
        borderRadius: "5px",
        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
        bgcolor: "white",
        ...containerSx
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "5px",
          marginBottom: "10px",
        }}
      >
        {React.createElement(headerIcon, { w: 15, color: "black" })}
        <Typography
          sx={{
            font: "normal normal 400 14px/18px Source Serif Pro",
            ...headerTitleSx,
          }}
        >
          {headerTitle}
        </Typography>
      </Stack>
      {subtitle && (
        <Typography
          sx={{
            font: "normal italic 300 14px/18px Source Serif Pro",
            letterSpacing: "-0.05em",
            margin: "0px 0px 12px 0px",
            ...subTitleSx,
          }}
        >
          {subtitle}
        </Typography>
      )}

      <Stack sx={{ gap: "15px", ...childrenContainerSx }}>{children}</Stack>
    </Stack>
  );
};

export const MiniPopupContainerLabel: React.FC<{ label: string }> = ({
  label,
}) => {
  return (
    <Typography sx={{ font: "normal normal 400 14px/18px Source Serif Pro" }}>
      {label}
    </Typography>
  );
};

export const MiniPopupContainerIconLabel: React.FC<{
  icon: React.ElementType;
  label: string;
}> = ({ label, icon }) => {
  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
      {React.createElement(icon, { w: 15, color: "black" })}
      <Typography sx={{ font: "normal normal 300 12px/15px Source Serif Pro" }}>
        {label}
      </Typography>
    </Stack>
  );
};

export const MiniPopupContainerInputArea: React.FC<InputBaseProps> = ({
  sx,
  ...props
}) => {
  return (
    <InputBase
      sx={{
        font: "normal italic 300 14px/18px Source Serif Pro",
        borderRadius: "5px",
        border: "1px solid #E9EDF2",
        padding: "0px 10px",
      }}
      {...props}
    />
  );
};
