import {InputBase, Stack, Typography} from "@mui/material";
import React from "react";
import { CreatedIcon } from "../../../../../../../images";

interface DetailsAreaProps {
    setVideoDescription: (value: string) => void;
}

const DetailsArea: React.FC<DetailsAreaProps> = ({setVideoDescription}) => {
  return (
    <Stack sx={{ gap: "5px" }}>
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
        <CreatedIcon color="black" w={15} />
        <Typography
          sx={{ font: "normal normal 300 12px/15px Source Serif Pro" }}
        >
          Details
        </Typography>
      </Stack>

      {/* rich text editor here */}
          <InputBase
              sx={{
                  height: "125px",
                  borderRadius: "5px",
                  border: "1px solid #E9EDF2",
                  font: "normal normal 400 13px/16px Source Serif Pro",
                  width: "100%",
                  padding: "0px 10px",
              }}
              onChange={(e) => setVideoDescription(e.target.value)}
          />
    </Stack>
  );
};

export default DetailsArea;
