import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../../../Utils";
import { FirstMessageIcon } from "../../../../../../../images";

interface SupportButtonProps {}

const SupportButton: React.FC<SupportButtonProps> = ({}) => {
  return (
    <Button
      sx={{
        height: "35px",
        borderRadius: "5px",
        boxSizing: "border-box",
        padding: "0px 5px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        textTransform: "none",
        gap: "10px",
        color: "#E6BA8C",
        flexShrink: 0,
        ":hover": { bgcolor: adjustHexColor("#E6BA8C", 15) },
      }}
    >
      <Stack
        sx={{
          height: "30px",
          width: "30px",
          borderRadius: "5px",
          border: `1px solid #E6BA8C`,
          justifyContent: "center",
          alignItems: "center",
          boxSizing: "border-box",
          flexShrink: 0,
          bgcolor: "white",
        }}
      >
        <FirstMessageIcon w={20} color="black" />
      </Stack>
      <Typography
        sx={{
          font: "normal normal 400 16px/20px Source Serif Pro",
          color: "black",
        }}
      >
        Contact Bearish OS Support
      </Typography>
    </Button>
  );
};

export default SupportButton;
