import React from "react";
import { Box, Link as MuiLink, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import styles from "./IconWordLongButton.module.css"; // Import the CSS module
import { createAdminPortalSession__api } from "../../../../redux/Slice/auth/authActions";
import { getUser } from "../../../../Utils";
import { useDispatch } from "react-redux";

interface IconWordLongButtonProps {
  iconSrc: string;
  text: string;
  toMuiLink?: string;
  isWorkOS?: boolean;
}

// This component is used in the drawer-navbar
const IconWordLongButton: React.FC<IconWordLongButtonProps> = ({
  iconSrc,
  text,
  toMuiLink = "#",
  isWorkOS = false,
}) => {
  const dispatch = useDispatch();
  const user = getUser();
  const handleClick = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (isWorkOS) {
      event.preventDefault();

      try {
        const action = createAdminPortalSession__api(user?.data?._id);

        const actionDispatch: any = await dispatch(action);

        if (actionDispatch?.success && actionDispatch?.data) {
          if (actionDispatch?.data?.link) {
            window.location.href = actionDispatch?.data?.link;
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="start"
      className={styles.iconWordLongButton}
    >
      <Box component="img" src={iconSrc} alt="icon" className={styles.icon} />
      <Typography component="p" className={styles.text}>
        <MuiLink
          component={Link}
          to={toMuiLink}
          onClick={handleClick}
          className={styles.link}
        >
          {text}
        </MuiLink>
      </Typography>
    </Stack>
  );
};

export default IconWordLongButton;
