import { Stack, Typography } from "@mui/material";
import React from "react";

interface HoursViewProps {
  totalTime?: string;
}

const HoursView: React.FC<HoursViewProps> = ({ totalTime }) => {
  return (
    <Stack>
      <Typography sx={{ font: "normal normal 400 12px/15px Source Serif Pro" }}>
        {totalTime}
      </Typography>
    </Stack>
  );
};

export default HoursView;
