import { Stack, Typography } from "@mui/material";
import React from "react";
import {
  StartUpIcon,
  TotalhoursIcon,
  TravelIcon,
} from "../../../../../../../images";

const InlineTimeDetailsCard: React.FC<{ title: string; value: string }> = ({
  title,
  value,
}) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography sx={{ font: "normal normal 400 13px/16px Source Serif Pro" }}>
        {title}
      </Typography>
      <Typography sx={{ font: "normal normal 700 13px/16px Source Serif Pro" }}>
        {value}
      </Typography>
    </Stack>
  );
};

export const StartTimeHoverCard: React.FC<{
  checkInTime: string;
  checkoutTime: string;
  totalWorkTime: string;
  totalBreakTime: string;
}> = ({ checkInTime, checkoutTime, totalBreakTime, totalWorkTime }) => {
  return (
    <Stack
      sx={{
        width: "200px",
        bgcolor: "white",
        borderRadius: "5px",
        color: "black",
        padding: "5px 10px",
        gap: "5px",
      }}
    >
      <InlineTimeDetailsCard title="Check in" value={checkInTime} />
      <InlineTimeDetailsCard title="Check out" value={checkoutTime} />
      <InlineTimeDetailsCard title="Total work" value={totalWorkTime} />
      <InlineTimeDetailsCard title="Total break" value={totalBreakTime} />
    </Stack>
  );
};

export const BreakHoverCard: React.FC<{ totalTime: String }> = ({
  totalTime,
}) => {
  return (
    <Stack
      sx={{
        width: "100px",
        height: "40px",
        boxSizing: "border-box",
        padding: "5px",
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
        bgcolor: "white",
        color: "black",
      }}
    >
      <TravelIcon w={15} />
      <Stack>
        <Typography
          sx={{ font: "normal normal 300 11px/14px Source Serif Pro" }}
        >
          Break
        </Typography>
        <Typography
          sx={{ font: "normal normal 600 11px/14px Source Serif Pro" }}
        >
          {totalTime}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const WorkHoverCard: React.FC<{ totalTime: string }> = ({
  totalTime,
}) => {
  return (
    <Stack
      sx={{
        width: "100px",
        height: "40px",
        boxSizing: "border-box",
        padding: "5px",
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
        bgcolor: "white",
        color: "black",
      }}
    >
      <StartUpIcon w={15} color="black" />
      <Stack>
        <Typography
          sx={{ font: "normal normal 300 11px/14px Source Serif Pro" }}
        >
          Work
        </Typography>
        <Typography
          sx={{ font: "normal normal 600 11px/14px Source Serif Pro" }}
        >
          {totalTime}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const OvertimeHoverCard: React.FC<{ totalTime: string }> = ({
  totalTime,
}) => {
  return (
    <Stack
      sx={{
        width: "100px",
        height: "40px",
        boxSizing: "border-box",
        padding: "5px",
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
        bgcolor: "white",
        color: "black",
      }}
    >
      <TotalhoursIcon w={15} />
      <Stack>
        <Typography
          sx={{ font: "normal normal 300 11px/14px Source Serif Pro" }}
        >
          Overtime
        </Typography>
        <Typography
          sx={{ font: "normal normal 600 11px/14px Source Serif Pro" }}
        >
          {totalTime}
        </Typography>
      </Stack>
    </Stack>
  );
};
