import React, { useEffect, useState } from "react";
import ExpensesBadges from "../../../ExpensesBadges/ExpensesBadges";
import {
  Button,
  Divider,
  IconButton,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import {
  ExpensesBadgesDataType,
  ExpensesBadgesMenuListType,
  ExpensesBadgesMenuType,
} from "../../ExpensesBadgesMenuType";
import { AllDirArrowCircleIcon } from "../../../../../../../images";
import ExpensesBadgesSubList from "../ExpensesBadgesSubList/ExpensesBadgesSubList";
import { CheckboxMedium } from "../../../../../../common";

interface MainListViewProps {
  options: ExpensesBadgesMenuListType[];
  initialData: ExpensesBadgesMenuType;
  onChange: (value: ExpensesBadgesMenuType) => void;
}

const MainListView: React.FC<MainListViewProps> = ({
  options,
  initialData,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openedMenu, setOpenedMenu] =
    useState<ExpensesBadgesMenuType>(initialData);
  const [subListOpt, setSubListOpt] = useState<string[]>([]);
  const [selectedBadgeData, setSelectedBadgeData] =
    useState<ExpensesBadgesMenuType>(initialData);

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    menuData: ExpensesBadgesMenuType
  ) => {
    setSubListOpt(menuData.subTypeName!);
    setOpenedMenu(menuData);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;

  const handleSelect = (newValue: ExpensesBadgesMenuType) => {
    const data: ExpensesBadgesMenuType = {
      _id: newValue._id,
      badgeData: newValue.badgeData,
    };
    setSelectedBadgeData(data);
  };

  useEffect(() => {
    onChange(selectedBadgeData);
  }, [selectedBadgeData]);

  return (
    <div>
      <Stack
        sx={{
          border: "1px solid #E9EDF2",
          borderRadius: "5px",
          //   boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
          boxSizing: "border-box",
          bgcolor: "#fff",
          padding: "13px 10px 10px 10px",
          width: "160px",
          maxHeight: "425px",
          gap: "15px",
        }}
      >
        <Stack sx={{ overflow: "auto", scrollbarWidth: "none", gap: "15px" }}>
          {options.map((item) =>
            item.divider ? (
              <Divider key={item._id} />
            ) : (
              <Stack
                key={item._id}
                sx={{
                  flexDirection: "row",
                  alignItems: "start",
                  justifyContent: "space-between",
                  gap: "5px",
                }}
              >
                <Stack
                  onClick={() => handleSelect(item)}
                  sx={{
                    flexDirection: "row",
                    alignItems: "start",
                    gap: "10px",
                    cursor: "pointer",
                  }}
                >
                  <CheckboxMedium
                    checked={
                      selectedBadgeData.badgeData.badgeLabel ===
                      item.badgeData.badgeLabel
                    }
                  />
                  <Typography
                    sx={{
                      font: "normal normal 300 15px/19px Source Serif Pro",
                    }}
                  >
                    {item.badgeData.badgeLabel}
                  </Typography>
                </Stack>

                {item.subTypeName && (
                  <IconButton
                    sx={{ p: 0 }}
                    disableRipple
                    onClick={(e) => handleClick(e, item)}
                  >
                    <AllDirArrowCircleIcon direction="right" w={15} />
                  </IconButton>
                )}
              </Stack>
            )
          )}
        </Stack>

        <Stack
          sx={{
            height: "37px",
            borderTop: "1px solid #E9EDF2",
            flexDirection: "row",
            alignItems: "end",
            justifyContent: "center",
          }}
        >
          <Button
            variant="text"
            sx={{
              textTransform: "none",
              padding: "5px 8px",
              boxSizing: "border-box",
              color: "#00767B",
              font: "normal normal 300 13px/16px Source Serif Pro",
            }}
          >
            Create New Type
          </Button>
        </Stack>
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            padding: "0px",
            marginLeft: "20px",
          },
        }}
      >
        <ExpensesBadgesSubList
          openedMenu={openedMenu}
          setOpenedMenu={setOpenedMenu}
          options={subListOpt}
          initialSelect={selectedBadgeData}
          setSelected={setSelectedBadgeData}
        />
      </Popover>
    </div>
  );
};

export default MainListView;
