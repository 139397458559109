import React from "react";
import {
  ButtonSmall,
  EditableTextArea,
  MiniPopupContainer,
  SmallButtonColor,
} from "../../../../common";
import { BilledIcon } from "../../../../../images";
import { ListTableView } from "./view";
import { Stack, Typography } from "@mui/material";
import { ExpenseItemType } from "../ReciptReview/ReciptReview";

interface ReceiptRejectedProps {
  rejectedList: ExpenseItemType[];
}

const ReceiptRejected: React.FC<ReceiptRejectedProps> = ({ rejectedList }) => {
  return (
    <MiniPopupContainer
      headerIcon={BilledIcon}
      headerTitle="Receipt Rejected"
      headerTitleSx={{ color: "#FF0000" }}
      subTitleSx={{
        width: "320px",
        font: "normal normal 300 12px/15px Source Serif Pro",
        letterSpacing: 0,
      }}
      childrenContainerSx={{ gap: "25px" }}
      containerWidth="540px"
      subtitle="You are about to reject the expenses below please type a few words on why you are rejecting these items and any way this can be corrected."
    >
      <ListTableView
        list={rejectedList}
        total={rejectedList.reduce((sum, item) => sum + item.total, 0)}
      />

      <Stack gap={"5px"} paddingRight={"32px"}>
        <Typography
          sx={{ font: "normal normal 400 12px/15px Source Serif Pro" }}
        >
          General Rejection Reason
        </Typography>

        <EditableTextArea
          minRows={7}
          maxRows={7}
          style={{ border: "1px solid #E9EDF2", padding: "0px 5px" }}
        />
      </Stack>

      <Stack alignItems={"end"}>
        <ButtonSmall
          label="Reject"
          colorVarient={SmallButtonColor.HighPriority}
        />
      </Stack>
    </MiniPopupContainer>
  );
};

export default ReceiptRejected;
