import React from "react";
import { PaymentMode, PaymentViaMenuOptions } from "./PaymentViaMenuType";
import { TextLabelDropdown } from "../../common";

interface PaymentViaMenuProps {
  initialValue: PaymentMode;
  onSelect: (value: string) => void;
}

const PaymentViaMenu: React.FC<PaymentViaMenuProps> = ({
  initialValue,
  onSelect,
}) => {
  return (
    <TextLabelDropdown
      menuLabel="Pay Via"
      initialValue={initialValue}
      options={PaymentViaMenuOptions}
      onSelect={(value) => onSelect(value)}
      menuWidth="152px"
    />
  );
};

export default PaymentViaMenu;
