import { Stack, Typography } from "@mui/material";
import React from "react";
import {
    BoxLogoPng,
    ConcurLogoPng,
    ConfluenceLogoPng,
    DropBoxLogoPng,
    ExchangeLogoPng,
    GmailLogoPng,
    GoogleCalendarLogoPng,
    GoogleDocsLogoPng,
    GoogleDriveLogoPng,
    GoogleMeetLogoPng,
    HubstaffLogoPng,
    JiraLogoPng,
    MicrosoftTeamsLogoPng,
    OneDriveLogoPng,
    OutlookCalendarLogoPng,
    OutlookLogoPng,
    SalesforceLogoPng,
    SAPSuccessFactorsLogoPng,
    SlackPng,
    WebexPng,
    ZoomLogoPng,
} from "../../../../../../images";
import { ButtonIconXlong } from "../../../../../common";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface IntelligentConnectionsProps { }

type connectionListType = {
    id: number;
    title: string;
    subTitle: string;
    imgSrc: string;
    comingSoon?: boolean; // Mark as optional
};

// Mapping for reusable attributes
const connectionData = [
    {
        id: 1,
        title: "Google Gmail",
        subKey: "CONNECT_AND_HANDLE_GMAIL_FROM_WITHIN_BEARISH_OS",
        img: GmailLogoPng,
    },
    {
        id: 2,
        title: "Outlook Email",
        subKey: "CONNECT_TO_MICROSOFT_OUTLOOK",
        img: OutlookLogoPng,
    },
    {
        id: 3,
        title: "Exchange Mail Management",
        subKey: "ENTERPRISE_ACCESS_TO_MICROSOFT_EXCHANGE_MAIL",
        img: ExchangeLogoPng,
    },
    {
        id: 4,
        title: "Microsoft Exchange Mail",
        subKey: "ENTERPRISE_ACCESS_TO_MICROSOFT_EXCHANGE_MAIL",
        img: ExchangeLogoPng,
    },
    {
        id: 5,
        title: "Google Calendar",
        subKey: "CONNECT_AND_HANDLE_GOOGLE_CALENDAR_WITHIN_BEARISH_OS",
        img: GoogleCalendarLogoPng,
    },
    {
        id: 6,
        title: "Outlook Calendar",
        subKey: "CONNECT_TO_MICROSOFT_OUTLOOK_CALENDAR",
        img: OutlookCalendarLogoPng,
    },
    {
        id: 7,
        title: "Exchange Calendar Management",
        subKey: "CONNECT_TO_YOU_MICROSOFT_EXCHANGE_CALENDAR",
        img: ExchangeLogoPng,
    },
    {
        id: 8,
        title: "Microsoft Exchange Calendar",
        subKey: "MANAGE_CALENDAR_EVENTS_TO_AND_FROM_EXCHANGE",
        img: ExchangeLogoPng,
    },
    {
        id: 9,
        title: "OneDrive",
        subKey: "MANAGE_ALL_YOUR_DOCUMENT_AND_MORE",
        img: OneDriveLogoPng,
    },
    {
        id: 10,
        title: "Box",
        subKey: "MANAGE_ALL_YOUR_BOX_DOCUMENT_AND_SUCH",
        img: BoxLogoPng,
    },
    {
        id: 11,
        title: "Google Drive",
        subKey: "ALL_YOUR_GOOGLE_DRIVE_DOCUMENTS_IN_BEARISH_OS",
        img: GoogleDriveLogoPng,
    },
    {
        id: 12,
        title: "Dropbox",
        subKey: "MANAGE_ALL_YOUR_DROPBOX_DUCUMENT_AND_SUCH",
        img: DropBoxLogoPng,
    },
    {
        id: 13,
        title: "Zoom",
        subKey: "CREATE_PLAN_AND_HAVE_MEETING_ON_ZOOM",
        img: ZoomLogoPng,
    },
    {
        id: 14,
        title: "Cisco Webex",
        subKey: "CREATE_PLAN_AND_HAVE_MEETING_ON_WEBEX",
        img: WebexPng,
    },
    {
        id: 15,
        title: "Google Meet",
        subKey: "CREATE_PLAN_AND_HAVE_MEETING_ON_MEET",
        img: GoogleMeetLogoPng,
    },
    {
        id: 16,
        title: "Teams",
        subKey: "CREATE_PLAN_AND_HAVE_MEETING_ON_TEAMS",
        img: MicrosoftTeamsLogoPng,
    },
    {
        id: 17,
        title: "Jira",
        subKey: "CONNECT_ALL_YOUR_JIRA_TASK_TO_BEARISH_OS",
        img: JiraLogoPng,
    },
    {
        id: 18,
        title: "Confluence",
        subKey: "CONNECT_AND_MANAGE_CONFLUENCE_IN_BEARISH_OS",
        img: ConfluenceLogoPng,
    },
    {
        id: 19,
        title: "Google Docs",
        subKey: "CREATE_AND_MANAGE_GOOGLE_DOCS_IN_BEARISH_OS",
        img: GoogleDocsLogoPng,
    },
    {
        id: 20,
        title: "Teams Messaging",
        subKey: "MANAGE_CREATE_AND_SEND_TEAMS_MESSAGE",
        img: MicrosoftTeamsLogoPng,
    },
    {
        id: 21,
        title: "Slack",
        subKey: "MANAGE_CREATE_AND_SEND_SLACK_MESSAGE",
        img: SlackPng,
    },
    {
        id: 22,
        title: "Salesforce",
        subKey: "CONNECT_AND_MANAGE_YOUR_CRM_IN_BEARISH_OS",
        img: SalesforceLogoPng,
    },
    {
        id: 23,
        title: "HubSpot",
        subKey: "CONNECT_AND_MANAGE_YOUR_HUBSPOT_CRM",
        img: HubstaffLogoPng,
    },
    {
        id: 24,
        title: "SAP Concur",
        subKey: "CONNECT_CONCUR_TO_TRACK_AND_MANAGE_EXPENSES",
        img: ConcurLogoPng,
    },
    {
        id: 25,
        title: "Sap SuccessFactors",
        subKey: "CONNECT_YOUR_HR_SUITE_TO_BEARISH_OS",
        img: SAPSuccessFactorsLogoPng,
    },
];

const connectionsList: connectionListType[] = connectionData.map((item) => ({
    id: item.id,
    title: item.title,
    subTitle: `SETTINGS.CONNECTIONS.${item.subKey}`,
    imgSrc: item.img,
}));

const IntelligentConnections: React.FC<IntelligentConnectionsProps> = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleOnClickIntelligentConnections = async (
        item: connectionListType
    ) => {
        const actions: Record<number, () => void> = {
            1: () => console.log("on click 1"),
            2: () => {
                navigate("/auth/redirect?platform=outlookEmail&groupName=emailBlue");
                console.log("on click 2");
            },
            3: () => console.log("on click 3"),
            4: () => console.log("on click 4"),
            5: () => console.log("on click 5"),
            6: () => {
                navigate(
                    "/auth/redirect?platform=outlookCalendar&groupName=calendarMarronPink"
                );
                console.log("on click 6");
            },
            7: () => console.log("on click 7"),
            8: () => console.log("on click 8"),
            9: () => console.log("on click 9"),
            10: () => {
                navigate("/auth/redirect?platform=box");
                console.log("on click 10");
            },
            11: () => console.log("on click 11"),
            12: () => console.log("on click 12"),
            13: () => console.log("on click 13"),
            14: () => console.log("on click 14"),
            15: () => console.log("on click 15"),
            16: () => console.log("on click 16"),
            17: () => {
                navigate(
                    "/auth/redirect?platform=jira&groupName=workspaceDarkOragne"
                );
                console.log("on click 17")},
            18: () => {
                navigate(
                    "/auth/redirect?platform=confluence&groupName=workspaceDarkOragne"
                );
                console.log("on click 18");
            },
            19: () => console.log("on click 19"),
            20: () => {
                navigate("/auth/redirect?platform=teams&groupName=messagePurple");
                console.log("on click 20");
            },
            21: () => {
                navigate("/auth/redirect?platform=slack&groupName=messagePurple");
                console.log("on click 21");
            },
            22: () => console.log("on click 22"),
            23: () => console.log("on click 23"),
            24: () => console.log("on click 24"),
            25: () => console.log("on click 25"),
        };

        // Execute the appropriate action if it exists
        if (actions[item.id]) {
            actions[item.id]();
        } else {
            console.log(`No action defined for id ${item.id}`);
        }
    };

    return (
        <Stack
            sx={{
                height: "100%",
                boxSizing: "border-box",
                padding: "30px 30px 0px 30px",
                overflow: "hidden",
                gap: "20px",
            }}
        >
            <Stack sx={{ gap: "20px" }}>
                <Typography
                    sx={{ font: "normal normal 600 20px/28px Source Serif Pro" }}
                >
                    {t("SETTINGS.DRAWER_LABELS.INTELLIGENT_CONNECTIONS")}
                </Typography>
                <Typography
                    sx={{
                        font: "italic normal normal 15px/21px Source Serif Pro",
                        width: "500px",
                    }}
                >
                    {t(
                        "SETTINGS.CONNECTIONS.CREATE_AND_DISCONNECT_INTELLIGENT_CONNECTIONS_TO_ALL_THE_APPS_YOU_ALREADY_USE_AND_LOVE_MISSING_AN_APP_WE_ADD_NEW_APPS_EVERY_WEEK_ON_FRIDAYS"
                    )}
                </Typography>
            </Stack>

            <Stack
                sx={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: "20px",
                    overflow: "auto",
                    scrollbarWidth: "none",
                    paddingBottom: "30px",
                }}
            >
                {connectionsList.map((item, index) => (
                    <ButtonIconXlong
                        key={index}
                        headerText={item.title}
                        bodyText={t(item.subTitle)}
                        imgSrc={item.imgSrc}
                        buttonClick={() => handleOnClickIntelligentConnections(item)}
                        actionButtonLabel="SETTINGS.ENABLE"
                    />
                ))}
            </Stack>
        </Stack>
    );
};

export default IntelligentConnections;
