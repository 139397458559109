import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../redux/store";
import {
  ListViewButtonCalendar,
  MediumSearchbar,
  QuickActionsMenu,
} from "../../common";
import {
  BookingLinkCalendarCreator,
  BookingLinkCalendarPreview,
  CalendarEventCardLong,
  CalendarViewSelect,
  FilterButton,
  LinkAnalyticsContainer,
  NamePlackCard,
  SidebarCalendar,
} from "./components";
import {
  AuthCalendars__api,
  changeLayoutState,
  getAllBookingLinks__api,
  getAllEvents__api,
  getCalendarSettings__api,
  getOneBookingLinks__api,
  recallAPI,
  getAllUsers,
  getAllCalendars__api,
  cmd__calendar_api,
} from "../../../redux";

import g from "./Calendar.module.css";
import { Popover, Stack, Typography, Zoom } from "@mui/material";
import MainCalendar from "./MainCalendar/MainCalendar";
import { msalInstance } from "./authConfigCalendar";
import { bookinglinkempty, EmptyEvent } from "../../../images";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { variantColorMap, VariantKeys } from "../../../Utils";

dayjs.extend(utc);
dayjs.extend(timezone);
const Calendar = () => {
  const { t } = useTranslation();
  const [view, setView] = useState("dayGridMonth");

  const dispatch = useDispatch();

  const calendarState = useSelector(
    (state: RootState) => state && state?.calendar
  );
  const { allCalenderEventsList, reCall, cmd__Notifications } =
    calendarState || {};

  const [eventsList, setEventsList] = useState<any | null>([]);

  const [pendingEvents, setPendingEvents] = useState<any[]>([]);
  const [upNextEvents, setUpNextEvents] = useState<any[]>([]);
  const [doneEvents, setDoneEvents] = useState<any[]>([]);


  useEffect(() => {
    if (!cmd__Notifications || cmd__Notifications.length === 0) return;

    const splitEvents = () => {
      const pending: any[] = [];
      const upNext: any[] = [];
      const done: any[] = [];

      cmd__Notifications.forEach((event: any) => {
        // Get the color for the event based on its variant
        const eventColor =
          event?.variant && event.variant in variantColorMap
            ? variantColorMap[event.variant as VariantKeys]
            : "#000000"; // Default color

        // Define event status based on conditions
        if (event?.status === "DONE") {
          done.push({ ...event, status: "done", color: eventColor });
        } else if (
          event?.userId === event?.createdBy &&
          event?.status !== "DONE"
        ) {
          // Add to upNext only if userId === createdBy and status is not "DONE" or other positive status
          upNext.push({ ...event, status: "up-next", color: eventColor });
        } else {
          // All other cases go to pending
          pending.push({ ...event, status: "pending", color: eventColor });
        }
      });

      // Update state with categorized events
      setPendingEvents(pending);
      setUpNextEvents(upNext);
      setDoneEvents(done);
    };

    splitEvents();
  }, [cmd__Notifications]);

  useEffect(() => {
    if (allCalenderEventsList && allCalenderEventsList?.length > 0) {
      setEventsList(allCalenderEventsList);
    }
  }, [allCalenderEventsList]);

  const [listViewShow, setListViewShow] = useState<boolean>(false);
  const [tabSelected, setTabSelected] = useState("Up Next");
  const [bookingLinks, setBookingLinks] = useState(false);
  const [activeTab, setActiveTab] = useState("all");

  const getCalendarSettings = async () => {
    try {
      const action = getCalendarSettings__api();
      await dispatch(action);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(recallAPI(null));
    }
  };

  const tabStyle = (tab: any) => ({
    marginRight: "15px", // Add spacing between the tabs
    cursor: "pointer", // Show pointer on hover
    fontWeight: activeTab === tab ? "bold" : "normal", // Bold the active tab
    borderBottom: activeTab === tab ? "2px solid #B5005B" : "none", // Underline for active tab
  });

  const handleViewSelect = (viewType: string) => {
    if (viewType === "Month View") setView("dayGridMonth");
    if (viewType === "Week View") setView("timeGridWeek");
    if (viewType === "Day View") setView("timeGridDay");
  };

  const handleShowListView = () => {
    const drawerType = "isDrawer_Calendar";

    // Toggle the listViewShow state
    const updatedListView = !listViewShow;

    // Open the drawer if listViewShow is false, otherwise close it
    if (!updatedListView) {
      dispatch(
        changeLayoutState({
          type: drawerType,
          value: true, // Open the drawer when listViewShow is false
        })
      );
    } else {
      dispatch(
        changeLayoutState({
          type: drawerType,
          value: false, // Close the drawer when listViewShow is true
        })
      );
    } // Update the listViewShow state
    setListViewShow(updatedListView);
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const getAllEvents = async () => {
    try {
      const action = getAllEvents__api();

      await dispatch(action);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(recallAPI(null));
    }
  };

  const [bookingLinkData, setbookingLinkData] = useState([]);
  const [bLinkId, setBLinkId] = useState("");
  const getAllBookingLinks = async () => {
    try {
      const action = getAllBookingLinks__api();

      const bre: any = await dispatch(action);

      if (bre?.success && bre?.data?.length > 0) {
        setbookingLinkData(bre?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(recallAPI(null));
    }
  };

  const getAllCalendar = async () => {
    try {
      const action = getAllCalendars__api();
      await dispatch(action);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(recallAPI(null)); // Reset any recall state if applicable
    }
  };

  const get__All__cmd = async () => {
    try {
      const action = cmd__calendar_api();
      await dispatch(action);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(recallAPI(null)); // Reset any recall state if applicable
    }
  };

  useEffect(() => {
    switch (reCall) {
      case "All":
        getAllEvents();
        getAllBookingLinks();
        getCalendarSettings();
        getAllCalendar();
        get__All__cmd();
        break;
      case "getAllCalEvent":
        getAllEvents();
        break;
      case "getAllBook":
        getAllBookingLinks();
        break;
      case "OutLookLogin":
        login();
        break;
      case "cmd":
        get__All__cmd();
        break;
      default:
        break;
    } // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [reCall]);

  const [bookLinkDataForHost, setBookLinkDataForHost] = useState<any>([]);
  const getBookingLinkData = async () => {
    try {
      if (!bLinkId) {
        return;
      }
      const action = getOneBookingLinks__api(bLinkId);

      const blinkRes: any = await dispatch(action);

      if (blinkRes?.success && blinkRes?.data) {
        setBookLinkDataForHost(blinkRes?.data);
      } else {
        setBookLinkDataForHost([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getBookingLinkData(); // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [bLinkId]);

  const [isMsalInitialized, setIsMsalInitialized] = useState<boolean>(false);

  const loginRequest = {
    scopes: ["openid", "profile", "Calendars.ReadWrite", "offline_access"],
  };

  useEffect(() => {
    const initializeMsal = async () => {
      try {
        // Only initialize MSAL once
        await msalInstance.initialize();
        setIsMsalInitialized(true);

        // Check for redirect and handle it immediately
        const handleRedirectResponse = async () => {
          const authResponse: any = await msalInstance.handleRedirectPromise();
          if (authResponse) {
            await sendTokensToBackend(authResponse);
          }
        };

        await handleRedirectResponse();
      } catch (err) {
        console.error("MSAL Initialization Error:", err);
      }
    };

    initializeMsal(); // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const login = async () => {
    try {
      if (!isMsalInitialized) return;

      // This will open a popup for the user to log in
      const loginResponse = await msalInstance.loginPopup(loginRequest);

      // After login, acquire the token
      const tokenResponse = await msalInstance.acquireTokenSilent({
        scopes: loginRequest.scopes,
        account: loginResponse.account,
      });

      // Send the acquired token to the backend
      await sendTokensToBackend(tokenResponse);
      dispatch(recallAPI(null));
    } catch (error) {
      console.error("Login failed", error);
    }
  };

  const sendTokensToBackend = async (tokenResponse: any) => {
    try {
      if (!tokenResponse) {
        return;
      }

      const action = AuthCalendars__api("Microsoft", tokenResponse);
      await dispatch(action);
      dispatch(recallAPI("All"));
    } catch (error) {
      console.error("Error saving tokens:", error);
    }
  };
  const handleGetAlluser = async () => {
    await dispatch(getAllUsers());
  };

  useEffect(() => {
    handleGetAlluser(); // ? If you are confident that the current implementation is correct
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);
  return (
    <Stack className={g.calendar_main}>
      <div className={g.calendar_container}>
        <MediumSearchbar ph={t("CALENDAR.SEARCH_ALL_CALENDAR")} />
      </div>
      <div className={g.tabs_container}>
        <div className={g.tabs}>
          <span
            onClick={() => setTabSelected("Up Next")}
            style={{
              cursor: "pointer",
              borderBottom:
                tabSelected === "Up Next" ? "2px solid #B5005B" : "none",
              padding: "8px 0px", // Ensures the dark border is flush with the light border
            }}
          >
            {t("CALENDAR.UP_NEXT")}
          </span>

          <span
            onClick={() => setTabSelected("Done")}
            style={{
              cursor: "pointer",
              borderBottom:
                tabSelected === "Done" ? "2px solid #B5005B" : "none",
              padding: "8px 0px", // Ensures the dark border is flush with the light border
            }}
          >
            {t("CALENDAR.DONE")}
          </span>

          <span
            onClick={() => setTabSelected("Pending")}
            style={{
              cursor: "pointer",
              borderBottom:
                tabSelected === "Pending" ? "2px solid #B5005B" : "none",
              padding: "8px 0px", // Ensures the dark border is flush with the light border
            }}
          >
            {t("CALENDAR.PENDING")}
          </span>

          <span
            onClick={() => setTabSelected("Booking links")}
            style={{
              cursor: "pointer",
              borderBottom:
                tabSelected === "Booking links" ? "2px solid #B5005B" : "none",
              padding: "8px 0px", // Ensures the dark border is flush with the light border
            }}
          >
            {t("CALENDAR.BOOKING_LINK")}
          </span>

          <span>|</span>

          <span
            onClick={() => setTabSelected("CalendarView")}
            className={g.tabs}
          >
            <CalendarViewSelect onClick={handleViewSelect} />
          </span>

          <span className={g.tabs}>
            <FilterButton onChange={() => {}} />
          </span>
        </div>

        <div className={g.lightning_icon}>
          {tabSelected === "Booking links" ||
          tabSelected === "Up Next" ||
          tabSelected === "Done" ||
          tabSelected === "Pending" ? (
            <span
              onClick={(e: any) => handleClick(e)}
              style={{
                color: "#B5005B",
                fontFamily: "Source Serif Pro",
                fontSize: "15px",
                fontWeight: "400",
              }}
            >
              {t("CALENDAR.CREATE_BOOKING_LINK")}
            </span>
          ) : (
            <ListViewButtonCalendar onClick={handleShowListView} />
          )}

          <QuickActionsMenu onSelect={() => {}} />
        </div>
      </div>
      <Zoom in={!bookingLinks}>
        <div
          className={g.Up_next}
          style={{
            display: bookingLinks ? "none" : "block", // Hide when bookingLinks is true
          }}
        >
          {tabSelected === "Up Next" ? (
            <>
              {upNextEvents && upNextEvents?.length > 0 ? (
                upNextEvents?.map((pending) => (
                  <CalendarEventCardLong
                    key={pending?._id}
                    openBookingLink={() => setBookingLinks(!bookingLinks)}
                    variant="up-next"
                    bookingLinkDatas={pending}
                  />
                ))
              ) : (
                <Stack
                  gap={2}
                  alignItems={"center"}
                  justifyContent={"center"}
                  direction={"column"}
                >
                  <img
                    style={{ width: "450px", height: "400px" }}
                    src={EmptyEvent}
                    alt="No UpComing events available"
                  />
                  <Typography
                    children={t("CALENDAR.NO_UPNEXT_EVENT")}
                    sx={{
                      font: "normal normal 500 17px/21px Source Serif Pro",
                      textAlign: "center",
                      color: "text.secondary", // Optional: adjust color to fit your theme
                    }}
                  />
                </Stack>
              )}
            </>
          ) : tabSelected === "Booking links" ? (
            <>
              {bookingLinkData && bookingLinkData?.length > 0 ? (
                bookingLinkData?.map((b: any) => (
                  <CalendarEventCardLong
                    key={b?._id}
                    bookingLinkDatas={b}
                    openBookingLink={() => {
                      // Toggle bookingLinks
                      setBookingLinks((prev) => !prev);

                      // If bookingLinks is going to be true, set the bLinkId; otherwise, clear it
                      setBLinkId((prev) => (bookingLinks ? null : b?._id));
                    }}
                    variant="bookinglink"
                  />
                ))
              ) : (
                <Stack
                  gap={2}
                  alignItems={"center"}
                  justifyContent={"center"}
                  direction={"column"}
                >
                  <img
                    style={{ width: "450px", height: "400px" }}
                    src={bookinglinkempty}
                    alt="No booking link created for event schedule"
                  />
                  <Typography
                    children={t("CALENDAR.NO_BOOKING_LINK_CREATED")}
                    sx={{
                      font: "normal normal 500 17px/21px Source Serif Pro",
                      textAlign: "center",
                      color: "text.secondary", // Optional: adjust color to fit your theme
                    }}
                  />
                </Stack>
              )}
            </>
          ) : tabSelected === "Done" ? (
            <>
              {doneEvents && doneEvents?.length > 0 ? (
                doneEvents?.map((Dones) => (
                  <CalendarEventCardLong
                    key={Dones?._id}
                    openBookingLink={() => setBookingLinks(!bookingLinks)}
                    variant="Done"
                    bookingLinkDatas={Dones}
                  />
                ))
              ) : (
                <Stack
                  gap={2}
                  alignItems={"center"}
                  justifyContent={"center"}
                  direction={"column"}
                >
                  <img
                    style={{ width: "450px", height: "400px" }}
                    src={EmptyEvent}
                    alt="No Done events available"
                  />
                  <Typography
                    children={t("CALENDAR.NO_DONE_EVENT")}
                    sx={{
                      font: "normal normal 500 17px/21px Source Serif Pro",
                      textAlign: "center",
                      color: "text.secondary", // Optional: adjust color to fit your theme
                    }}
                  />
                </Stack>
              )}
            </>
          ) : tabSelected === "Pending" ? (
            <>
              {pendingEvents && pendingEvents.length > 0 ? (
                pendingEvents.map((pendings) => (
                  <CalendarEventCardLong
                    key={pendings?._id}
                    openBookingLink={() => setBookingLinks(!bookingLinks)}
                    variant="Pending"
                    bookingLinkDatas={pendings}
                  />
                ))
              ) : (
                <Stack
                  gap={2}
                  alignItems={"center"}
                  justifyContent={"center"}
                  direction={"column"}
                >
                  <img
                    style={{ width: "450px", height: "400px" }}
                    src={EmptyEvent}
                    alt="No Pending events available"
                  />
                  <Typography
                    children={t("CALENDAR.NO_PENDING_EVENT")}
                    sx={{
                      font: "normal normal 500 17px/21px Source Serif Pro",
                      textAlign: "center",
                      color: "text.secondary", // Optional: adjust color to fit your theme
                    }}
                  />
                </Stack>
              )}
            </>
          ) : (
            <Stack sx={{ display: "flex", flexDirection: "row" }}>
              <Stack sx={{ flex: 1 }}>
                <MainCalendar
                  eventsList={eventsList}
                  type="calendarView"
                  calendarView={view}
                  l={listViewShow}
                />
              </Stack>
              {listViewShow && <SidebarCalendar dataList={eventsList} />}
            </Stack>
          )}
        </div>
      </Zoom>

      <Zoom in={!!bookingLinks}>
        <div
          style={{
            width: "100%",
            marginTop: "10px",
            overflowX: "auto",
            overflowY: "scroll",
            maxHeight: "calc(82vh - 45px)",
            height: "80vh",
            display: "flex",
            backgroundColor: "#ffffff",
            gap: "30px",
            scrollbarWidth: "none", // Hides scrollbar in Firefox
            msOverflowStyle: "none", // Hides scrollbar in Internet Explorer and Edge
          }}
        >
          <BookingLinkCalendarPreview
            bLinkIdData={bookLinkDataForHost}
            onClickhandle={() => setBookingLinks(!bookingLinks)}
          />
          <span
            style={{
              borderLeft: "1px solid #e0e0e0",
              height: "100%",
              marginRight: "10px",
              position: "relative",
            }}
          ></span>
          <div style={{ flex: 1 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <h2 style={{ marginRight: "30px", fontWeight: "bold" }}>
                {t("CALENDAR.MEETINGS")}
              </h2>

              <div style={tabStyle("all")} onClick={() => setActiveTab("all")}>
                {t("CALENDAR.All")}
              </div>

              <div
                style={tabStyle("past")}
                onClick={() => setActiveTab("past")}
              >
                {t("CALENDAR.Past")}
              </div>

              <div
                style={tabStyle("upnext")}
                onClick={() => setActiveTab("upnext")}
              >
                {t("CALENDAR.UP_NEXT")}
              </div>
            </div>
            <div
              style={{
                maxHeight: "50vh",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              {bookLinkDataForHost &&
                bookLinkDataForHost?.analytics &&
                bookLinkDataForHost?.analytics?.visits && // Access visits correctly
                bookLinkDataForHost?.analytics?.visits?.length > 0 &&
                bookLinkDataForHost?.analytics?.visits?.map((v: any) => {
                  return <NamePlackCard key={v?._id} vister={v} />;
                })}
            </div>

            <div>
              {bookLinkDataForHost && bookLinkDataForHost?.analytics && (
                <LinkAnalyticsContainer
                  analytics={bookLinkDataForHost?.analytics}
                />
              )}
            </div>
          </div>
        </div>
      </Zoom>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <BookingLinkCalendarCreator handleClose={handleClose} />
      </Popover>
    </Stack>
  );
};

export default Calendar;
