import React, { useState } from "react";
import styles from "./ListTableSwitch.module.css";
import { Box, Stack, Typography } from "@mui/material";
import { ListViewIcon, TableIcon } from "../../../images";

export type ListTableSwitchState = "list" | "table";

type ListTableSwitch = {
  viewMode: ListTableSwitchState;
  onClick: (newState: ListTableSwitchState) => void;
};

const ListTableSwitch: React.FC<ListTableSwitch> = ({ viewMode, onClick }) => {
  return (
    <Stack className={styles.ListTableSwitchContainer}>
      <Box
        className={`${styles.ListTableSwitch} ${
          viewMode === "list" ? styles["ListTableSwitch--selected"] : ""
        }`}
        component="span"
        onClick={() => onClick("list")}
        // style={{ pointerEvents: disabled ? "none" : "auto" }}
      >
        <ListViewIcon w={15} />
        <Typography
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
        >
          List
        </Typography>
      </Box>
      <Box
        className={`${styles.ListTableSwitch} ${
          viewMode === "table" ? styles["ListTableSwitch--selected"] : ""
        }`}
        component="span"
        onClick={() => onClick("table")}
        // style={{ pointerEvents: disabled ? "none" : "auto" }} // Disable click when disabled
      >
        <TableIcon w={15} />
        <Typography
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
        >
          Table
        </Typography>
      </Box>
    </Stack>
  );
};

export default ListTableSwitch;
