import React, { useEffect, useState } from "react";
import TitleContainer from "../TitleContainer/TitleContainer";
import { Stack, Typography } from "@mui/material";
import { StyledRadioButtonBlack } from "../../../../../../common";
import SearchIconView from "../../../CommandControlSearchContainer/view/SearchIconView/SearchIconView";
import {
  FilterSourceValueEnum,
  filterValueType,
  SearchGroups,
  SearchIconPngNameType,
  sourcesOptionsType,
} from "../../../CommandControlSearchContainer";
import ViewMoreContainer from "../ViewMoreContainer/ViewMoreContainer";

type InnerComponentType = {
  label: string;
  group: SearchGroups | "clay";
  isSelected: boolean;
  value: FilterSourceValueEnum;
  imgName: SearchIconPngNameType;
  onButtonClick: (value: string) => void;
};

const FilterRadioButton: React.FC<InnerComponentType> = ({
  isSelected,
  onButtonClick,
  group,
  value,
  label,
  imgName,
}) => {
  return (
    <Stack
      onClick={() => onButtonClick(value)}
      sx={{
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
        width: "fit-content",
        cursor: "pointer",
      }}
    >
      <StyledRadioButtonBlack radioSize={18} checked={!!isSelected} />
      <SearchIconView boxSize={20} iconType={imgName} searchGroup={group} />
      <Typography sx={{ font: "normal normal 300 14px/18px Source Serif Pro" }}>
        {label}
      </Typography>
    </Stack>
  );
};

interface SourcesFilterViewProps {
  primaryValue: filterValueType;
  setPrimaryValue: React.Dispatch<React.SetStateAction<filterValueType>>;
  sourcesoptionsList: sourcesOptionsType[];
}

const SourcesFilterView: React.FC<SourcesFilterViewProps> = ({
  primaryValue,
  setPrimaryValue,
  sourcesoptionsList,
}) => {
  const [relatedSourceName, setRelatedSourceName] =
    useState<sourcesOptionsType[]>(sourcesoptionsList);

  const selectedCenterlist = primaryValue.centersValue;

  useEffect(() => {
    const updatedSourceName: sourcesOptionsType[] =
      selectedCenterlist.length === 0
        ? sourcesoptionsList
        : sourcesoptionsList.filter((source) =>
            source.group.some((groupValue) =>
              selectedCenterlist.includes(groupValue)
            )
          );
    setRelatedSourceName(updatedSourceName);
  }, [selectedCenterlist, sourcesoptionsList]);

  // const handleButtonClick = (data: sourcesOptionsType) => {
  //   const isSelected = primaryValue.sources.some(
  //     (item) => item._id === data._id
  //   );

  //   if (isSelected) {
  //     // Unselecting
  //     const updatedSources = primaryValue.sources.filter(
  //       (item) => item._id !== data._id
  //     );
  //     const groupStillNeeded = updatedSources.some((source) =>
  //       source.group.some((group) => data.group.includes(group))
  //     );

  //     const updatedConditionalCentersValue = groupStillNeeded
  //       ? primaryValue.conditionalCentersValue
  //       : primaryValue.conditionalCentersValue.filter(
  //           (center) =>
  //             !data.group.includes(center) ||
  //             primaryValue.centersValue.includes(center) // Ensure it's not removed if in centersValue
  //         );

  //     setPrimaryValue({
  //       ...primaryValue,
  //       sources: updatedSources,
  //       conditionalCentersValue: updatedConditionalCentersValue,
  //     });
  //   } else {
  //     // Selecting
  //     const updatedSources = [...primaryValue.sources, data];
  //     const newConditionalCenters = data.group.some((group) =>
  //       primaryValue.centersValue.includes(group)
  //     )
  //       ? primaryValue.conditionalCentersValue
  //       : [...primaryValue.conditionalCentersValue, ...data.group];

  //     setPrimaryValue({
  //       ...primaryValue,
  //       sources: updatedSources,
  //       conditionalCentersValue: newConditionalCenters,
  //     });
  //   }
  // };
  const handleButtonClick = (data: sourcesOptionsType) => {
    const isSelected = primaryValue.sources.some(
      (item) => item._id === data._id
    );

    if (isSelected) {
      // Unselecting
      const updatedSources = primaryValue.sources.filter(
        (item) => item._id !== data._id
      );

      // Collect all groups from the remaining selected sources
      const remainingGroups = updatedSources.flatMap((source) => source.group);

      // Filter `conditionalCentersValue` to include only groups present in remaining selected sources
      const updatedConditionalCentersValue =
        primaryValue.conditionalCentersValue.filter(
          (center) =>
            remainingGroups.includes(center) || // Keep groups that are still needed
            primaryValue.centersValue.includes(center) // Ensure it's not removed if in centersValue
        );

      setPrimaryValue({
        ...primaryValue,
        sources: updatedSources,
        conditionalCentersValue: updatedConditionalCentersValue,
      });
    } else {
      // Selecting
      const updatedSources = [...primaryValue.sources, data];
      const newConditionalCenters = data.group.some((group) =>
        primaryValue.centersValue.includes(group)
      )
        ? primaryValue.conditionalCentersValue
        : [...primaryValue.conditionalCentersValue, ...data.group];

      setPrimaryValue({
        ...primaryValue,
        sources: updatedSources,
        conditionalCentersValue: newConditionalCenters,
      });
    }
  };

  const isViewMoreButtonVisible = (): boolean => {
    if (
      primaryValue.centersValue.length > 0 &&
      primaryValue.centersValue.length < 7
    ) {
      return true;
    }
    return false;
  };

  const viewMoreButtonClick = () => {
    setPrimaryValue((prev) => ({
      ...prev, // Preserve other properties in primaryValue
      centersValue: [], // Reset centersValue to an empty array
    }));
  };

  return (
    <ViewMoreContainer
      showViewMore={isViewMoreButtonVisible()}
      viewMoreClick={() => viewMoreButtonClick()}
    >
      {relatedSourceName.map((data, index) => (
        <FilterRadioButton
          key={index}
          imgName={data.imgName}
          group={data.borderColor}
          label={data.tranlatedLabel}
          value={data.value}
          isSelected={primaryValue.sources.some(
            (item) => item._id === data._id
          )}
          onButtonClick={() => handleButtonClick({ ...data })}
        />
      ))}
    </ViewMoreContainer>
  );
};

export default SourcesFilterView;
