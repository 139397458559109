import { Stack, Typography } from "@mui/material";
import React from "react";
import { TagIcon } from "../../../../../../../images";
import { ExpensesBadgesMenu } from "../../../ExpensesBadgesMenu";

interface CategoryViewProps {}

const CategoryView: React.FC<CategoryViewProps> = ({}) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
        <TagIcon w={15} />
        <Typography
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
        >
          Category
        </Typography>
      </Stack>
      <ExpensesBadgesMenu
        initialData={{
          _id: "1",
          badgeData: {
            badgeLabel: "Travel",
            badgeColor: "#4A90E2",
            fontColor: "#000",
          },
          subTypeName: ["Flights", "Ground", "Train", "Other"],
        }}
      />
    </Stack>
  );
};

export default CategoryView;
