interface DividerType {
    _id: string; // Unique identifier for the option
    value?: undefined; // Value of the option used in filtering
    translatedLabel?: undefined; // Translated label for display
    children?: undefined; // Nested sub-options
    divider?: true;
}

export interface FilterDocumentsSearchType {
    _id: string; // Unique identifier for the option
    value: string; // Value of the option used in filtering
    translatedLabel: string; // Translated label for display
    children?: FilterDocumentsSearchOptionsType[]; // Nested sub-options
    divider?: false;
}

export type FilterDocumentsSearchOptionsType = FilterDocumentsSearchType | DividerType;


// main list which render in component as map function 
export const FilterDocumentsSearchValues: FilterDocumentsSearchOptionsType[] = [
    {
        _id: '1',
        value: 'source',
        translatedLabel: 'Source',
        children: [
            { _id: '1.1', value: 'bearishCloud', translatedLabel: 'Bearish Cloud' },
            { _id: '1.2', value: 'googleDrive', translatedLabel: 'Google Drive' },
            { _id: '1.3', value: 'oneDrive', translatedLabel: 'OneDrive' },
            { _id: '1.4', value: 'box', translatedLabel: 'Box Storage' },
            { _id: '1.5', value: 'dropbox', translatedLabel: 'Dropbox Storage' },
        ],
    },
    {
        _id: '2',
        value: 'fileType',
        translatedLabel: 'File Type',
        children: [
            {
                _id: '2.1',
                value: 'documents',
                translatedLabel: 'Documents',
                children: [
                    { _id: '2.1.1', value: 'pdf', translatedLabel: 'PDF' },
                    { _id: '2.1.2', value: 'word', translatedLabel: 'Word Doc' },
                    { _id: '2.1.3', value: 'txt', translatedLabel: 'TXT' },
                ],
            },
            {
                _id: '2.2',
                value: 'grids',
                translatedLabel: 'Grids',
                children: [
                    { _id: '2.2.1', value: 'excel', translatedLabel: 'Excel' },
                    { _id: '2.2.2', value: 'csv', translatedLabel: 'CSV' },
                    { _id: '2.2.3', value: '.bgrids', translatedLabel: '.BGrids' },
                ],
            },
            {
                _id: '2.3',
                value: 'decks',
                translatedLabel: 'Decks',
                children: [
                    { _id: '2.3.1', value: 'powerpoints', translatedLabel: 'PowerPoint' },
                    { _id: '2.3.2', value: 'other', translatedLabel: 'Other' },
                    { _id: '2.3.3', value: '.bdecks', translatedLabel: '.BDecks' },
                ],
            },
            {
                _id: 'divider2.3',
                divider: true,
            },
            {
                _id: '2.4',
                value: 'media',
                translatedLabel: 'Media',
                children: [
                    { _id: '2.4.1', value: 'images', translatedLabel: 'Images' },
                    { _id: '2.4.2', value: 'videos', translatedLabel: 'Videos' },
                    { _id: '2.4.3', value: 'audioFiles', translatedLabel: 'Audio Files' },
                ],
            },
            {
                _id: '2.5',
                value: 'design',
                translatedLabel: 'Design Files',
                children: [
                    { _id: '2.5.1', value: 'adobeFiles', translatedLabel: 'Adobe Design Files' },
                    { _id: '2.5.2', value: 'sketch', translatedLabel: 'Sketch Files' },
                    { _id: '2.5.3', value: 'figma', translatedLabel: 'Figma Design Files' },
                ],
            },
            {
                _id: '2.6',
                value: 'codeAndScripts',
                translatedLabel: 'Code and Script',
                children: [
                    { _id: '2.6.1', value: 'javascript', translatedLabel: 'JavaScript' },
                    { _id: '2.6.2', value: 'html/Css', translatedLabel: 'HTML/CSS' },
                    { _id: '2.6.3', value: 'json/Xml', translatedLabel: 'JSON, XML' },
                ],
            },
            {
                _id: '2.7',
                value: 'compressed',
                translatedLabel: 'Compressed',
                children: [
                    { _id: '2.7.1', value: 'zip', translatedLabel: 'ZIP' },
                    { _id: '2.7.2', value: 'rar', translatedLabel: 'RAR' },
                ],
            },
        ],
    },
    {
        _id: '3',
        value: 'modified',
        translatedLabel: 'Modified',
        children: [
            { _id: '3.1', value: 'today', translatedLabel: 'Today' },
            { _id: '3.2', value: 'pastWeek', translatedLabel: 'Past Week' },
            { _id: '3.3', value: 'pastMonth', translatedLabel: 'Past Month' },
            { _id: '3.4', value: 'pastYear', translatedLabel: 'Past Year' },
            { _id: '3.5', value: 'custom', translatedLabel: 'Custom Range' },
        ],
    },
    {
        _id: 'divider3',
        divider: true,
    },
    {
        _id: '4',
        value: 'fileSize',
        translatedLabel: 'File Size',
        children: [
            { _id: '4.1', value: 'small', translatedLabel: 'Small (0-10 MB)' },
            { _id: '4.2', value: 'medium', translatedLabel: 'Medium (10-100 MB)' },
            { _id: '4.3', value: 'large', translatedLabel: 'Large (100 MB - 1 GB)' },
            { _id: '4.4', value: 'extraLarge', translatedLabel: 'Extra Large (1 GB+)' },
        ],
    },
    {
        _id: '5',
        value: 'activity',
        translatedLabel: 'Activity',
        children: [
            { _id: '5.1', value: 'recentlyAccessed', translatedLabel: 'Recently Accessed' },
            { _id: '5.2', value: 'recentlyModified', translatedLabel: 'Recently Modified' },
            { _id: '5.3', value: 'newlyUploaded', translatedLabel: 'Newly Uploaded' },
        ],
    },

    {
        _id: '6',
        value: 'owner',
        translatedLabel: 'Owner',
        children: [
            { _id: '6.1', value: 'me', translatedLabel: 'Me' },
            { _id: '6.2', value: 'shareWithMe', translatedLabel: 'Share with me' },
        ],
    },
    {
        _id: '7',
        value: 'accessLevels',
        translatedLabel: 'Access Levels',
        children: [
            { _id: '7.1', value: 'private', translatedLabel: 'Private' },
            { _id: '7.2', value: 'shared', translatedLabel: 'Shared' },
            { _id: '7.3', value: 'public', translatedLabel: 'Public' },
        ],
    },
];



// this is test data which pass as initail value in component as test
export const FilterDocTestData: FilterDocumentsSearchType[] = [
    {
        _id: '1',
        value: 'source',
        translatedLabel: 'Source',
        children: [
            { _id: '1.1', value: 'bearishCloud', translatedLabel: 'Bearish Cloud' },
            { _id: '1.2', value: 'googleDrive', translatedLabel: 'Google Drive' },
            { _id: '1.5', value: 'dropbox', translatedLabel: 'Dropbox Storage' },
        ],
    },
    {
        _id: '2',
        value: 'fileType',
        translatedLabel: 'File Type',
        children: [
            {
                _id: '2.1',
                value: 'documents',
                translatedLabel: 'Documents',
                children: [
                    { _id: '2.1.1', value: 'pdf', translatedLabel: 'PDF' },
                    { _id: '2.1.2', value: 'word', translatedLabel: 'Word Doc' },
                ],
            },
            {
                _id: '2.2',
                value: 'grids',
                translatedLabel: 'Grids',
                children: [
                    { _id: '2.2.1', value: 'excel', translatedLabel: 'Excel' },
                    { _id: '2.2.2', value: 'csv', translatedLabel: 'CSV' },
                ],
            },
        ],
    },
    {
        _id: '3',
        value: 'modified',
        translatedLabel: 'Modified',
        children: [
            { _id: '3.1', value: 'today', translatedLabel: 'Today' },
            { _id: '3.2', value: 'pastWeek', translatedLabel: 'Past Week' },
        ],
    },
    {
        _id: '4',
        value: 'fileSize',
        translatedLabel: 'File Size',
        children: [
            { _id: '4.1', value: 'small', translatedLabel: 'Small (0-10 MB)' },
            { _id: '4.2', value: 'medium', translatedLabel: 'Medium (10-100 MB)' },
        ],
    },
    {
        _id: '5',
        value: 'activity',
        translatedLabel: 'Activity',
        children: [
            { _id: '5.1', value: 'recentlyAccessed', translatedLabel: 'Recently Accessed' },
            { _id: '5.2', value: 'recentlyModified', translatedLabel: 'Recently Modified' },
        ],
    },
];
