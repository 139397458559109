import {
  BearishPng,
  BoxLogoPng,
  ConcurLogoPng,
  ConfluenceLogoPng,
  DropBoxLogoPng,
  ExchangeLogoPng,
  GmailLogoPng,
  GoogleCalendarLogoPng,
  GoogleDriveLogoPng,
  GoogleMeetLogoPng,
  JiraLogoPng,
  MicrosoftTeamsLogoPng,
  OneDriveLogoPng,
  OutlookCalendarLogoPng,
  OutlookLogoPng,
  SalesforceLogoPng,
  SAPSuccessFactorsLogoPng,
  SlackPng,
  WebexPng,
  ZoomLogoPng,
} from "../../../../../images";

export type RedirectServiceName =
  | "slack"
  | "teams"
  | "zoom"
  | "webex"
  | "meet"
  | "outlookEmail"
  | "gamil"
  | "exchange"
  | "outlookCalendar"
  | "googleCalendar"
  | "googleDrive"
  | "box"
  | "dropbox"
  | "oneDrive"
  | "jira"
  | "confluence"
  | "salesforce"
  | "successFactor"
  | "Concur";

export type RedirectServiceGroup =
  | "messagePurple"
  | "callsSalmon"
  | "emailBlue"
  | "calendarMarronPink"
  | "workspaceDarkOragne"
  | "crmOrange"
  | "traxBluish";

export type RedirectServiceImgName =
  | "slackPng"
  | "MicrosoftTeamPng"
  | "googleMeetPng"
  | "webexPng"
  | "zoomPng"
  | "outlookEmailPng"
  | "gmailPng"
  | "exchangeServerPng"
  | "outlookCalendarPng"
  | "googleCalendarPng"
  | "oneDrivePng"
  | "boxPng"
  | "dropboxPng"
  | "googleDrivePng"
  | "jiraPng"
  | "confluencePng"
  | "salesForcePng"
  | "successFactorPng"
  | "concurPng"
  | "bearishPng";

const getBorderClr = (groupName: RedirectServiceGroup): string => {
  switch (groupName) {
    case "calendarMarronPink":
      return "#B5005B";
    case "messagePurple":
      return "#775EE2";
    case "callsSalmon":
      return "#FF8181";
    case "emailBlue":
      return "#2A30B5";
    case "workspaceDarkOragne":
      return "#A53A01";
    case "crmOrange":
      return "#E2875E";
    case "traxBluish":
      return "#308893";
  }
};

export const getRedirectServiceCss = ({
  groupName,
  serviceName,
}: {
  groupName: RedirectServiceGroup;
  serviceName: RedirectServiceName;
}): {
  imgName: RedirectServiceImgName;
  labelText: string;
  labelColor: string;
} => {
  switch (serviceName) {
    case "slack":
      return {
        imgName: "slackPng",
        labelText: "Slack",
        labelColor: getBorderClr(groupName),
      };
    case "teams":
      return {
        imgName: "MicrosoftTeamPng",
        labelText: "Teams",
        labelColor: getBorderClr(groupName),
      };
    case "zoom":
      return {
        imgName: "zoomPng",
        labelText: "Zoom",
        labelColor: getBorderClr(groupName),
      };
    case "webex":
      return {
        imgName: "webexPng",
        labelText: "Webex",
        labelColor: getBorderClr(groupName),
      };
    case "meet":
      return {
        imgName: "googleMeetPng",
        labelText: "Meet",
        labelColor: getBorderClr(groupName),
      };
    case "outlookEmail":
      return {
        imgName: "outlookEmailPng",
        labelText: "Outlook",
        labelColor: getBorderClr(groupName),
      };
    case "gamil":
      return {
        imgName: "gmailPng",
        labelText: "Gmail",
        labelColor: getBorderClr(groupName),
      };
    case "exchange":
      return {
        imgName: "exchangeServerPng",
        labelText: "Exchange",
        labelColor: getBorderClr(groupName),
      };
    case "outlookCalendar":
      return {
        imgName: "outlookCalendarPng",
        labelText: "Outlook",
        labelColor: getBorderClr(groupName),
      };
    case "googleCalendar":
      return {
        imgName: "googleCalendarPng",
        labelText: "Google",
        labelColor: getBorderClr(groupName),
      };
    case "googleDrive":
      return {
        imgName: "googleDrivePng",
        labelText: "Google Drive",
        labelColor: getBorderClr(groupName),
      };
    case "box":
      return {
        imgName: "boxPng",
        labelText: "Box",
        labelColor: getBorderClr(groupName),
      };
    case "dropbox":
      return {
        imgName: "dropboxPng",
        labelText: "Dropbox",
        labelColor: getBorderClr(groupName),
      };
    case "oneDrive":
      return {
        imgName: "oneDrivePng",
        labelText: "OneDrive",
        labelColor: getBorderClr(groupName),
      };
    case "jira":
      return {
        imgName: "jiraPng",
        labelText: "Jira",
        labelColor: getBorderClr(groupName),
      };
    case "confluence":
      return {
        imgName: "confluencePng",
        labelText: "Confluence",
        labelColor: getBorderClr(groupName),
      };
    case "salesforce":
      return {
        imgName: "salesForcePng",
        labelText: "Salesforce",
        labelColor: getBorderClr(groupName),
      };
    case "successFactor":
      return {
        imgName: "successFactorPng",
        labelText: "SuccessFactor",
        labelColor: getBorderClr(groupName),
      };
    case "Concur":
      return {
        imgName: "concurPng",
        labelText: "Concur",
        labelColor: getBorderClr(groupName),
      };
    default:
      return {
        imgName: "bearishPng",
        labelText: "Bearish",
        labelColor: getBorderClr(groupName),
      };
  }
};

export const renderIconPng = (imgName: RedirectServiceImgName): string => {
  switch (imgName) {
    case "slackPng":
      return SlackPng;
    case "MicrosoftTeamPng":
      return MicrosoftTeamsLogoPng;
    case "googleMeetPng":
      return GoogleMeetLogoPng;
    case "webexPng":
      return WebexPng;
    case "zoomPng":
      return ZoomLogoPng;
    case "outlookEmailPng":
      return OutlookLogoPng;
    case "gmailPng":
      return GmailLogoPng;
    case "exchangeServerPng":
      return ExchangeLogoPng;
    case "outlookCalendarPng":
      return OutlookCalendarLogoPng;
    case "googleCalendarPng":
      return GoogleCalendarLogoPng;
    case "oneDrivePng":
      return OneDriveLogoPng;
    case "boxPng":
      return BoxLogoPng;
    case "dropboxPng":
      return DropBoxLogoPng;
    case "googleDrivePng":
      return GoogleDriveLogoPng;
    case "jiraPng":
      return JiraLogoPng;
    case "confluencePng":
      return ConfluenceLogoPng;
    case "salesForcePng":
      return SalesforceLogoPng;
    case "successFactorPng":
      return SAPSuccessFactorsLogoPng;
    case "concurPng":
      return ConcurLogoPng;
    case "bearishPng":
      return BearishPng;
  }
};
