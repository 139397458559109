import React from "react";
import { SmallThreeDotMenu } from "../../../../common";
import { ThreeDotMenuTraxCommandOptions } from "./ThreeDotMenuTraxCommandTypes";

interface ThreeDotMenuTraxCommandProps {}

const ThreeDotMenuTraxCommand: React.FC<
  ThreeDotMenuTraxCommandProps
> = ({}) => {
  return (
    <SmallThreeDotMenu
      menuItems={ThreeDotMenuTraxCommandOptions}
      menuWidth="150px"
      displayOption="left"
    />
  );
};

export default ThreeDotMenuTraxCommand;
