// src/i18n.ts
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

let isInitialized = false;

if (!isInitialized) {
    i18n
        .use(HttpApi)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            fallbackLng: "en",
            debug: false,
            ns: ["common"],
            defaultNS: "common",
            detection: {
                order: [
                    "queryString",
                    "cookie",
                    "localStorage",
                    "sessionStorage",
                    "navigator",
                    "htmlTag",
                    "path",
                    "subdomain",
                ],
                caches: ["cookie"],
            },
            backend: {
                loadPath: "/locales/{{lng}}/{{ns}}.json",
            },
            interpolation: {
                escapeValue: false,
            },
        });

    isInitialized = true;
}

export default i18n;
