import { Stack, SxProps, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";

const InlineContainer: React.FC<{
  label: string;
  value: string;
  headerBgColor: string;
  headerBoxSx?: SxProps;
  valueBoxSx?: SxProps;
}> = ({ label, value, headerBgColor, headerBoxSx, valueBoxSx }) => {
  return (
    <Stack
      sx={{
        flex: 1,
        flexShrink: 0,
        minWidth: "150px",
      }}
    >
      <Stack
        sx={{
          height: "30px",
          flexShrink: 0,
          boxSizing: "border-box",
          bgcolor: adjustHexColor(headerBgColor, 25),
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          ...headerBoxSx,
        }}
      >
        <Typography
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
        >
          {label}
        </Typography>
      </Stack>

      <Stack
        sx={{
          height: "40px",
          flexShrink: 0,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          boxSizing: "border-box",
          borderTop: "1px solid #E9EDF2",
          borderBottom: "1px solid #E9EDF2",
          borderRight: "1px solid #E9EDF2",
          borderRadius: "0px 0px 5px 5px",
          ...valueBoxSx,
        }}
      >
        <Typography
          sx={{ font: "normal normal 900 13px/16px Source Serif Pro" }}
        >
          {value}
        </Typography>
      </Stack>
    </Stack>
  );
};

interface PendingActionViewAreaProps {
  pendingOvertime: string;
  pendingExpenses: string;
  pendingSchedule: string;
}

const PendingActionViewArea: React.FC<PendingActionViewAreaProps> = ({
  pendingExpenses,
  pendingOvertime,
  pendingSchedule,
}) => {
  return (
    <Stack sx={{ gap: "15px" }}>
      <Typography sx={{ font: "normal normal 400 13px/16px Source Serif Pro" }}>
        Pending Actions
      </Typography>

      <Stack sx={{ flexDirection: "row", borderRadius: "5px" }}>
        <InlineContainer
          label="Pending Overtime"
          value={pendingOvertime}
          headerBgColor="#FF0000"
          headerBoxSx={{ borderTopLeftRadius: "5px" }}
          valueBoxSx={{ borderLeft: "1px solid #E9EDF2" }}
        />
        <InlineContainer
          label="Pending Expenses"
          value={pendingExpenses}
          headerBgColor="#FFA500"
        />
        <InlineContainer
          label="Pending Schedule"
          value={pendingSchedule}
          headerBgColor="#21B481"
          headerBoxSx={{ borderTopRightRadius: "5px" }}
        />
      </Stack>
    </Stack>
  );
};

export default PendingActionViewArea;
