import { CommandIconPng, ConnectionsPng, MaleUserGroupPng, ProjectPng } from "../../../images";

/**
 * Interface representing an action that can be associated with a navigation route.
 */
export interface TopNavbarRouteAction {
    desc: string;        /** Description of the action */
    action: string | number; /** Action can be either a string (URL) or number (identifier) */
}

/**
 * Interface representing a top navigation route.
 */
export interface TopNavbarRoute {
    id: number;          /** Unique identifier for the route */
    name: string;        /** Display name of the route */
    iconSrc: string;     /** Path to the icon/image associated with the route */
    actionType?: TopNavbarRouteAction; /** Optional action associated with this route */
}

/**
 * type representing for dynamic params scross all pages and routes.
 */
export type RoutesParamType = {
    workspaceId?: string;
    componentId?: string;
    fileType?: string;
    fileId?: string;
    sharedType?: string;
    SharedfileId?: string;
    empId?: string;
}

/**
 * Array of top navigation routes.
 */
export const TOP_NAVBAR_ROUTES: TopNavbarRoute[] = [
    // {
    //     id: 1,
    //     name: "Command",
    //     iconSrc: CommandIconPng,
    //     actionType: {
    //         desc: "Navigate to command panel",
    //         action: "/command",
    //     },
    // },
    {
        id: 2,
        name: "Projects",
        iconSrc: ProjectPng,
        actionType: {
            desc: "Navigate to project",
            action: "/Project",
        },
    },
    {
        id: 3,
        name: "Connect",
        iconSrc: ConnectionsPng,
        actionType: {
            desc: "Open connection",
            action: "/Settings/intelligent-connections"
        },
    },
    {
        id: 4,
        name: "Invite team",
        iconSrc: MaleUserGroupPng,
        actionType: {
            desc: "Show invite team popup or route",
            action: 2, /** Example of using a numeric action (could represent different invite actions) */
        },
    },
];

/**
 * Array of status lists.
 */
export const STATUS_LISTS: { id: number; name: string; color: string }[] = [
    {
        id: 1,
        name: "Online",
        color: "#008D4C",
    },
    {
        id: 2,
        name: "Offline",
        color: "#E0D700",
    },
    {
        id: 3,
        name: "Do not disturb",
        color: "#FF0000",
    },
    {
        id: 4,
        name: "Be back soon",
        color: "#0027FF",
    },
];
