import {Box, Stack} from "@mui/material";
import React, {lazy, Suspense, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {a11yProps, StyledTab, TabPanel, TabsWithState,} from "../../../../common/CardTabsWithoutIcon";
import {TabLoader} from "../../../../common/TabLoader";
import {SearchBar} from "../../../CRM/components";
import {CoverImgButton} from "../../../Dashboard/components/DashboardSidebarCalendar/view";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../redux/store";
import {uploadCloudFile__api} from "../../../../../redux";
import {saveOrUpdateCoverImage__api,} from "../../../../../redux/Slice/Global/globalActions";
import {GlobalState} from "../../../../../redux/Slice/Global/globalTypes";
import {getContrastingColor} from "../../../../../Utils";

interface VideoCenterTabsProps {}

const LazyNewUpdatesTab = lazy(
  () => import("./tabs/NewUpdatesTab/NewUpdatesTab")
);
const LazyDoneTab = lazy(() => import("./tabs/DoneTab/DoneTab"));
const LazySnoozedTab = lazy(() => import("./tabs/SnoozedTab/SnoozedTab"));

const tabs = [
  {
    label: "PAST_CALL_DETAILS.NEW_UPDATES",
    content: <LazyNewUpdatesTab />,
  },
  {
    label: "MESSAGE.DONE",
    content: <LazyDoneTab />,
  },
  {
    label: "MESSAGE.SNOOZED",
    content: <LazySnoozedTab />,
  },
];

const VideoCenterTabs: React.FC<VideoCenterTabsProps> = ({}) => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = React.useState(0);
    const dispatch = useDispatch();
    const [textColor, setTextColor] = useState<"black" | "white">("black");
    const fileInputRef = useRef<HTMLInputElement>(null);
    const {coverImages} = useSelector((state: RootState) => state.global as GlobalState);

    const handleSetCoverImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const selectedFile = event.target.files[0];
            const formData = new FormData();
            formData.append("files", selectedFile);

            const response: any = await dispatch(uploadCloudFile__api(formData));

            // file link from the response
            const fileLink = response.data?.[0].path;

            if (fileLink) {
                const payload = {
                    callCenterCoverImageUrl: fileLink,
                };
                await dispatch(saveOrUpdateCoverImage__api(payload));
            }
        }
    };
    const handleSelectCoverImage = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    useEffect(() => {
        const updateTextColor = async () => {
            if (coverImages?.callCenterCoverImageUrl) {
                const color = await getContrastingColor(
                    coverImages.callCenterCoverImageUrl
                );
                setTextColor(color);
            } else {
                setTextColor("black");
            }
        };

        updateTextColor();
    }, [coverImages?.callCenterCoverImageUrl]);

    return (
      <Box
          display="flex"
          padding="0 15px 0 15px"
          flexDirection="column"
          width="100%"
          sx={{
              backgroundImage: `url(${coverImages?.callCenterCoverImageUrl || ""})`,
              height: "90vh"
          }}
      >
          <Box display="flex" justifyContent="end" alignItems="center" flexDirection="row" p={2} style={{padding:"10px 20px 0px 10px"}}>
             <Stack sx={{flexDirection: "row", justifyContent: "end"}}>
                  <CoverImgButton
                      textColor={textColor}
                      onClick={handleSelectCoverImage}
                  />
                  <input
                      type="file"
                      ref={fileInputRef}
                      style={{display: "none"}}
                      onChange={handleSetCoverImage}
                  />
              </Stack>
          </Box>
          <Box display="flex" justifyContent="end" alignItems="center" flexDirection="row" p={2} style={{paddingTop:"0px"}}>
              <SearchBar
                  iconSize={15}
                  font="normal normal 400 11px/14px Source Serif Pro"
                  customStyles={{width: "330px", display: "flex", color: "black"}}
                  containerSx={{borderRadius: "5px!important",backgroundColor:"white"}}
                  placeholder={t("VIDEO_CENTER.MEETING.SEARCH_CALLS")}
              />
          </Box>
          <Box
              display="flex"
              alignItems="center"
              style={{ padding: "0 20px 0 20px" }}
          >
              <Stack
                  sx={{
                      width: "100%",
                      height: "100%",
                      boxSizing: "border-box",
                      gap: "20px",
                  }}
              >
                  <Stack
                      sx={{
                          flexDirection: "row",
                          alignItems: "center",
                          borderBottom: `2px solid #fff`,
                      }}
                  >
                      <TabsWithState
                          ariaLabel="call center tabs"
                          value={tabValue}
                          onChange={(event, newValue) => setTabValue(newValue)}
                          // tabIndicatorColor="white"
                          sx={{
                              maxWidth: "max-content",
                              gap: "25px",
                              borderBottom: "0px solid #E9EDF2",
                              "& .css-1aquho2-MuiTabs-indicator": {
                                  bottom: "0px !important",
                              },
                          }}
                      >
                          {tabs.map((tab, index) => (
                              <StyledTab
                                  sx={{
                                      font: "normal normal 300 15px/19px Source Serif Pro",
                                      padding: "10px 0px",
                                      color: `${textColor} !important`,
                                  }}
                                  key={index}
                                  // disableRipple
                                  label={t(tab.label)}
                                  {...a11yProps(index)}
                              />
                          ))}
                      </TabsWithState>
                  </Stack>

                  {tabs.map((tab, index) => (
                      <TabPanel
                          style={{ display: "contents"}}
                          key={index}
                          value={tabValue}
                          index={index}
                      >
                          <Suspense fallback={<TabLoader />}>{tab.content}</Suspense>
                      </TabPanel>
                  ))}
              </Stack>
          </Box>
      </Box>

  );
};

export default VideoCenterTabs;
