import React, { useState, useEffect } from "react";
import { InputBase, Stack, Popover } from "@mui/material";
import {
  MultiSectionDigitalClock,
  DateCalendar,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { styled } from "@mui/system";

interface TimestampViewProps {
  initialData?: string;
  onChange: (timestamp: string) => void;
}

const StyledMultiSectionDigitalClock = styled(MultiSectionDigitalClock)({
  "& .MuiMultiSectionDigitalClockSection-root": {
    "&::-webkit-scrollbar": {
      display: "none" /* Chrome, Safari, Opera */,
    },
    scrollbarWidth: "none" /* Firefox */,
  },
});

const TimestampView: React.FC<TimestampViewProps> = ({
  initialData,
  onChange,
}) => {
  const initialDate = initialData ? dayjs(initialData) : dayjs();

  const [anchorElTime, setAnchorElTime] = useState<null | HTMLElement>(null);
  const [anchorElDate, setAnchorElDate] = useState<null | HTMLElement>(null);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(initialDate);
  const [selectedTime, setSelectedTime] = useState<Dayjs | null>(initialDate);

  // useEffect(() => {
  //   if (selectedDate && selectedTime) {
  //     // Combine date and time into a single Dayjs object
  //     const combinedDateTime = selectedDate
  //       .set("hour", selectedTime.hour())
  //       .set("minute", selectedTime.minute());

  //     // Convert to ISO string and pass to onChange
  //     onChange(combinedDateTime.toISOString());
  //   }
  // }, [selectedDate, selectedTime, onChange]);

  const handleClickTime = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElTime(event.currentTarget);
  };

  const handleClickDate = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElDate(event.currentTarget);
  };

  const handleCloseTime = () => {
    setAnchorElTime(null);
  };

  const handleCloseDate = () => {
    setAnchorElDate(null);
  };
  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
    handleCloseDate();

    if (date && selectedTime) {
      // Combine date and time into a single Dayjs object
      const combinedDateTime = date
        ?.set("hour", selectedTime.hour())
        ?.set("minute", selectedTime.minute());

      // Convert to ISO string and pass to onChange
      onChange(combinedDateTime.toISOString());
    }
  };

  const handleTimeChange = (time: Dayjs | null) => {
    setSelectedTime(time);
  };

  const openTime = Boolean(anchorElTime);
  const openDate = Boolean(anchorElDate);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack sx={{ flexDirection: "row", gap: "10px" }}>
        <InputBase
          onClick={handleClickTime}
          sx={{
            padding: "0px",
            height: "17px",
            width: "70px",
            font: "normal normal 300 13px/17px Source Serif Pro",
            textAlign: "center",
            cursor: "pointer",
          }}
          inputProps={{
            style: {
              padding: "0px",
            },
            readOnly: true,
          }}
          value={selectedTime ? selectedTime?.format("hh:mm A") : ""}
        />
        <Popover
          open={openTime}
          anchorEl={anchorElTime}
          onClose={handleCloseTime}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <StyledMultiSectionDigitalClock
            timeSteps={{ minutes: 1 }}
            value={selectedTime}
            onChange={handleTimeChange}
          />
        </Popover>

        <InputBase
          onClick={handleClickDate}
          sx={{
            padding: "0px",
            height: "17px",
            width: "100px",
            font: "normal normal 300 13px/17px Source Serif Pro",
            textAlign: "center",
            cursor: "pointer",
          }}
          inputProps={{
            style: {
              padding: "0px",
            },
            readOnly: true,
          }}
          value={selectedDate ? selectedDate?.format("MM-DD-YYYY") : ""}
        />
        <Popover
          open={openDate}
          anchorEl={anchorElDate}
          onClose={handleCloseDate}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <DateCalendar
            value={selectedDate}
            onChange={(date: any) => handleDateChange(date)}
          />
        </Popover>
      </Stack>
    </LocalizationProvider>
  );
};

export default TimestampView;
