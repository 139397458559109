/* CompanyTable.tsx */
import React, { useEffect, useState } from "react";
import { ColDef, CellValueChangedEvent } from "ag-grid-community";
import { ICellRendererParams } from "ag-grid-community";
import {
  AddCalculationsMenu,
  CategoriesTable,
  CName,
  DataTable,
  DomainTable,
  RatingTable,
  PeopleData,
  PrimaryLocationTable,
} from "../../../components";
import { AutoSizeTextArea, DateAndTimePicker } from "../../../../../common";
import { AddColumnCrmTable, CustomHeaderComponent } from "../../../common";
import {
  AngelListBlackPng,
  CompanyIcon,
  DataIDnumberIcon,
  DescriptionIcon,
  DomainIcon,
  FacebookBlackPng,
  JobTitleIcon,
  LasTTouchPointIcon,
  LocationIcon,
  RelationshipStrengthIcon,
  TagIcon,
} from "../../../../../../images";
import { Stack } from "@mui/material";
import { RootState } from "../../../../../../redux/store";
import { useSelector } from "react-redux";

const peopleColumnDefs: ColDef<PeopleData>[] = [
  {
    headerName: "Full Name",
    field: "fullName",
    headerClass: "my-header-class",
    sortable: true,
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    pinned: "left",
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerComponent: CustomHeaderComponent,
    // headerComponentParams: {
    //   imageUrl:
    //     "https://vignette.wikia.nocookie.net/es.pokemon/images/f/f4/Charizard_PLB.png/revision/latest?cb=20170426010226", // URL of the image
    // },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return (
        <CName
          fortarget={"user"}
          targetOpen={true}
          p={params}
          hPopover={true}
          value={params.value}
        />
      );
    },
  },

  {
    headerName: "Categories",
    field: "categories",
    sortable: true,
    filter: true,
    headerClass: "my-header-class",
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 275,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: TagIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <CategoriesTable p={params} words={[]} />;
    },
  },
  {
    headerName: "Company",
    field: "company",
    headerClass: "my-header-class",
    sortable: true,
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: CompanyIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return (
        <CName
          hPopover={true}
          br={"25%"}
          value={params?.value?.companyName}
          p={params}
        />
      );
    },
  },

  {
    headerName: "Last touchpoint",
    field: "lastTouchpoint",
    sortable: true,
    filter: true,
    editable: false,
    wrapText: true,
    headerClass: "my-header-class",
    autoHeight: true,
    minWidth: 230,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: LasTTouchPointIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return (
        <DateAndTimePicker
          sx={{ font: "normal normal 400 12px/16px Source Serif Pro" }}
        />
      );
    },
  },
  {
    headerName: "Relationship Strength",
    field: "relationshipStrength",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 200,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: RelationshipStrengthIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <RatingTable tableData={params} />;
    },
  },
  {
    headerName: "Description",
    field: "description",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: DescriptionIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <AutoSizeTextArea TableData={params} />;
    },
  },
  {
    headerName: "Domain",
    field: "domain",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSVg: DomainIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <DomainTable TableData={params} />;
    },
  },

  {
    headerName: "Data ID number",
    field: "dataIDNumber",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: DataIDnumberIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <AutoSizeTextArea TableData={params} />;
    },
  },

  {
    headerName: "Primary location",
    field: "primaryLocation",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 250,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: LocationIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <PrimaryLocationTable TableData={params.value} />;
    },
  },
  {
    headerName: "AngleList",
    field: "angleList",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 200,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      imageUrl: AngelListBlackPng,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <DomainTable useWithoutBorder TableData={params} />;
    },
  },
  {
    headerName: "FaceBook",
    field: "faceBook",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 200,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      imageUrl: FacebookBlackPng,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <DomainTable useWithoutBorder TableData={params} />;
    },
  },
  {
    headerName: "jobTitle",
    field: "jobTitle",
    sortable: true,
    headerClass: "my-header-class",
    filter: true,
    editable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 200,
    hide: false,
    headerComponent: CustomHeaderComponent,
    headerComponentParams: {
      headerSvg: JobTitleIcon,
    },
    cellRenderer: (params: ICellRendererParams) => {
      if (params.node.rowPinned) {
        return <AddCalculationsMenu />;
      }
      return <AutoSizeTextArea TableData={params} />;
    },
  },
];

const peopleRowData: PeopleData[] = [
  {
    fullName: "Richard Cornsmith",
    categories: [
      { name: "Software", id: "kd" },
      { name: "Business", id: "eo" },
      { name: "Network", id: "eoe" },
    ],
    company: {
      companyName: "Red Hair Pirate - Shanks",
      companyLogoUrl:
        "https://static.wikia.nocookie.net/p__/images/e/e0/Red_hair.jpg/revision/latest?cb=20200824232939&path-prefix=protagonist",
    },
    lastTouchpoint: Date.now(),
    relationshipStrength: 1.5,
    description: "The relationship",
    domain: "tech.com",
    dataIDNumber: "hdi-973n",
    primaryLocation: "india",
    angleList: "http://www.angleList.com",
    faceBook: "http://www.faceBook.com",
    jobTitle: "Software Engineer",
  },
];

/* No Modification to below pinnedBottomRowData (IMPORTANT) */
const pinnedBottomRowData: PeopleData[] = [
  {
    fullName: "Nico Robin",
    company: {
      companyName: "Red Hair Pirate - Shanks",
      companyLogoUrl:
        "https://static.wikia.nocookie.net/p__/images/e/e0/Red_hair.jpg/revision/latest?cb=20200824232939&path-prefix=protagonist",
    },
    categories: [
      { name: "Software", id: "kd" }, // color as well
      { name: "Business", id: "eo" },
      { name: "Network", id: "eoe" },
    ],
    lastTouchpoint: Date.now(),
    relationshipStrength: 1.5,
    description: "The relationship ",
    domain: "tech.com",
    dataIDNumber: "hdi-973n",
    primaryLocation: "india",
    angleList: "http://www.  crunchBase.com",
    faceBook: "esstimate arr",
    jobTitle: "projectsInvolvedIn",
    actions: <AddCalculationsMenu />, // Rendering your component
  },

  /* addition : 
  teams : [...],
  crunchBase url : http://
  */
];

const updatePeopleData = async (event: CellValueChangedEvent<PeopleData>) => {
  const updatedData = event.data;
  // Make an API call to update the data
  try {
    console.log("Data updated successfully:", updatedData);
  } catch (error) {
    console.error("Error updating data:", error);
  }
};

const PeopleTable = () => {
  const crmState = useSelector((state: RootState) => state && state?.crm);
  const { contactList, outlookContactList } = crmState || {};

  const [rowData, setRowData] = useState<any>([]);
  const [columnDefs] = useState(peopleColumnDefs);

  useEffect(() => {
    const transformedContacts: PeopleData[] = [];

    // Transforming the contactList
    if (contactList && contactList?.length > 0) {
      const transformedContactList: PeopleData[] =
        contactList?.map((contact: any) => ({
          fullName: `${contact?.firstName} ${contact?.lastName}`,
          categories: contact?.businessId?.categories, // Populate with actual categories if available
          lastTouchpoint: new Date(contact?.updatedAt)?.getTime(),
          relationshipStrength: contact?.relationshipIndicator,
          description:
            contact?.description || contact?.businessId?.description || "", // No description provided in contact data
          domain:
            contact?.businessId?.domain || contact?.email?.split("@")[1],
          company: {
            companyName: contact?.businessId?.businessName, // No companyName in the original data, set accordingly
            companyLogoUrl: contact?.businessId?.businessLogo, // If available, you can add this
          },
          dataIDNumber: contact?._id,
          primaryLocation: contact?.primaryLocation, //
          angleList: contact?.businessId?.socialMediaLinks?.crunchbase, // No angleList data provided
          faceBook: contact?.businessId?.socialMediaLinks?.facebook,
          jobTitle: contact?.jobTitle,
          allData: contact,
          // actions are left out as you mentioned
        })) || [];

      transformedContacts?.push(...transformedContactList);
    }

    // Transforming the outlookContactList
    if (outlookContactList && outlookContactList?.length > 0) {
      const transformedOutlookList: PeopleData[] =
        outlookContactList?.map((outlookContact: any) => ({
          fullName: outlookContact?.displayName,
          categories: outlookContact?.categories || [],
          lastTouchpoint: new Date(
            outlookContact?.lastModifiedDateTime
          )?.getTime(),
          relationshipStrength: outlookContact?.relationshipStrength || "", // If available
          description: outlookContact?.personalNotes || "", // Personal notes if available
          domain:
            outlookContact.emailAddresses?.[0]?.address?.split("@")[1] || "", // Assuming first email
          company: {
            companyName: outlookContact?.companyName || "",
            companyLogoUrl: "", // If available, you can add this
          },
          dataIDNumber: outlookContact?.id,
          primaryLocation: `${outlookContact?.homeAddress?.city || ""}, ${
            outlookContact?.homeAddress?.state || ""
          }, ${outlookContact?.homeAddress?.postalCode || ""}, ${
            outlookContact?.homeAddress?.countryOrRegion || ""
          }`?.replace(/,\s*$/, ""),
          angleList: "", // No angleList data provided
          faceBook: "", // Assuming no Facebook link available in outlook
          jobTitle: outlookContact?.jobTitle || "",
          // actions are left out as you mentioned
        })) || [];

      transformedContacts?.push(...transformedOutlookList);
    }

    // Set the transformed data to rowData state
    setRowData(transformedContacts);
  }, [contactList, outlookContactList]);

  return (
    <Stack width={"100%"} direction={"row"} alignItems={"flex-start"} gap={0}>
      <DataTable<PeopleData>
        columnDefs={columnDefs}
        rowData={rowData}
        pinnedBottomRowData={pinnedBottomRowData}
        onCellValueChanged={updatePeopleData}
      />
      <AddColumnCrmTable />
    </Stack>
  );
};

export default PeopleTable;
