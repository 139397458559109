import * as React from "react";
import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import S from "./DealStageDropdown.module.css";
import { GreenDropdownIcon } from "../../../images";
import {
  DealStageCurrencyEnum,
  DealStageCurrencyList,
  DealStageCurrencyListInterface,
} from "./DealStageDropdownTypes";

interface DealStageDropdownProps {
  width?: string;
  label?: string;
  options?: DealStageCurrencyListInterface[];
  initialValue?: DealStageCurrencyEnum;
  onChange: (selectedOption: DealStageCurrencyEnum) => void;
}

const DealStageDropdown: React.FC<DealStageDropdownProps> = ({
  width = "125px",
  onChange,
  options = DealStageCurrencyList,
  label,
  initialValue,
  ...props
}) => {
  const defaultInitialValue = initialValue || options[0].value;
  const [selectedOption, setSelectedOption] =
    React.useState<DealStageCurrencyEnum>(defaultInitialValue);
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    if (onChange) {
      onChange(selectedOption);
    }
  }, [selectedOption, onChange]);

  const handleChange = (event: SelectChangeEvent<DealStageCurrencyEnum>) => {
    const { value } = event.target;
    setSelectedOption(value as DealStageCurrencyEnum);
    if (onChange) {
      onChange(value as DealStageCurrencyEnum);
    }
  };

  const handleIconClick = () => {
    setIsOpen(true);
  };

  const selectedOptionData = options.find(
    (option) => option.value === selectedOption
  );

  const menuProps = {
    PaperProps: {
      className: S["deal-stage-dropdown__menu"],
    },
    anchorOrigin: {
      vertical: "bottom" as const,
      horizontal: "right" as const,
    },
    transformOrigin: {
      vertical: "top" as const,
      horizontal: "right" as const,
    },
  };

  return (
    <div>
      <FormControl
        size="small"
        style={{
          minWidth: width,
        }}
        {...props}
      >
        {label && (
          <Typography
            sx={{
              font: "normal normal 300 10px/13px Source Serif Pro",
              marginBottom: "5px",
            }}
          >
            {label}
          </Typography>
        )}
        <Select
          onClick={!isOpen ? handleIconClick : () => null}
          IconComponent={() => <GreenDropdownIcon open={isOpen} />}
          open={isOpen}
          value={selectedOption}
          onChange={handleChange}
          input={
            <OutlinedInput
              className={S["deal-stage-dropdown__inputbox"]}
              classes={{
                notchedOutline: S["deal-stage-dropdown__notched-outline"],
                focused: S["deal-stage-dropdown__focused"],
              }}
            />
          }
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          renderValue={(selected) => {
            if (!selected) {
              return (
                <em className={S["deal-stage-dropdown__select-placeholder"]}>
                  Select option
                </em>
              );
            }

            return (
              <span className={S["deal-stage-dropdown__selected"]}>
                {selectedOptionData?.name}
              </span>
            );
          }}
          MenuProps={menuProps}
        >
          {options.map((option) => (
            <MenuItem
              className={S["deal-stage-dropdown__menu-item"]}
              key={option.id}
              value={option.value}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                width="100%"
              >
                <Typography className={S["deal-stage-dropdown__name"]}>
                  {option.name}
                </Typography>
                <Typography className={S["deal-stage-dropdown__value"]}>
                  {option.value}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default DealStageDropdown;
