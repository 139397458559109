import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import {
  adjustHexColor,
  CalendarTypesEnum,
  getCalenderLogo,
} from "../../../../../../../Utils";
import { VideoCallFilledIcon } from "../../../../../../../images";

interface HeaderViewProps {
  titleText: string;
}

const HeaderView: React.FC<HeaderViewProps> = ({ titleText }) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        gap: "13px",
        paddingLeft: "27px",
        height: "40px",
        borderRadius: "5px 5px 0px 0px",
        bgcolor: adjustHexColor("#FF8181", 80),
      }}
    >
      <VideoCallFilledIcon w={20} color="white" />
      <Typography
        sx={{
          font: "normal normal 900 18px/23px Source Serif Pro",
          color: "white",
          marginLeft: "-5px",
        }}
      >
        Post {titleText}
      </Typography>

      <Box
        component={"img"}
        src={getCalenderLogo({
          calendarType: CalendarTypesEnum.OutlookCalendar,
        })}
        alt="logo"
        sx={{ height: "20px", width: "20px" }}
      />
    </Stack>
  );
};

export default HeaderView;
