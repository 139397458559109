import {Button, InputBase, Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import {
    EditableTextArea,
    EditableTimeTextField,
} from "../../../../../../common";
import {adjustHexColor} from "../../../../../../../Utils";
import {useTranslation} from "react-i18next";
import {uploadCloudFile__api} from "../../../../../../../redux";
import {useDispatch} from "react-redux";

interface AgendaFormViewProps {
    agendaItem: {
        title?: string;
        description?: string;
        allottedTime: {
            hrs: string;
            mins: string;
        };
    };
    onChange: (data: any) => void;
    setIsFormValid: (isValid: boolean) => void;
}

const AgendaFormView: React.FC<AgendaFormViewProps> = ({
                                                           agendaItem,
                                                           onChange,
                                                           setIsFormValid,
                                                       }) => {
    const {t} = useTranslation()
    const [fileName, setFileName] = useState<string>("");
    const dispatch = useDispatch();
    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange({...agendaItem, title: e.target.value});
    };

    const handleDetailsChange = (newValue: string) => {
        onChange({...agendaItem, description: newValue});
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setFileName(event.target.files[0].name);
            const formData = new FormData();
            formData.append("files", event.target.files[0]);

            const response: any = await dispatch(uploadCloudFile__api(formData));
            const fileLink = response.data?.[0].path; // Assuming the API returns the link in the payload
            if (fileLink) {
                onChange({
                    ...agendaItem,
                    mediaUrl:fileLink,
                });
            }
        }
    };

    const handleTimeChange = (variant: "hrs" | "mins", newValue: string) => {
        onChange({
            ...agendaItem,
            allottedTime: {...agendaItem.allottedTime, [variant]: newValue},
        });
    };
    return (
        <Stack sx={{marginTop: "17px", gap: "10px"}}>
            <Stack gap={"5px"}>
                <Typography
                    sx={{font: "normal normal 200 10px/13px Source Serif Pro"}}
                >
                    {t('VIDEO_CENTER.AGENDA.AGENDA_ITEM_TITLE')}
                </Typography>
                <InputBase
                    value={agendaItem?.title}
                    onChange={handleTitleChange}
                    placeholder={t('VIDEO_CENTER.AGENDA.AWESOME_AGENDA_ITEM')}
                    sx={{
                        height: "30px",
                        border: "1px solid #E9EDF2",
                        borderRadius: "5px",
                        padding: "0px 5px",
                        font: "normal normal 900 13px/16px Source Serif Pro",
                    }}
                />
            </Stack>

            <Stack gap={"5px"}>
                <Typography
                    sx={{font: "normal normal 200 10px/13px Source Serif Pro"}}
                >
                    {t('VIDEO_CENTER.AGENDA.AGENDA_ITEM_DETAILS')}
                </Typography>
                <EditableTextArea
                    minRows={15}
                    maxRows={15}
                    value={agendaItem.description}
                    onTextChange={handleDetailsChange}
                    style={{
                        font: "normal normal 400 13px/16px Source Serif Pro",
                        border: "1px solid #E9EDF2",
                        borderRadius: "5px",
                        padding: "5px",
                        height: "240px",
                        maxHeight: "240px",
                    }}
                    placeholder={t('VIDEO_CENTER.AGENDA.DETAILS_PLACEHOLDER')}
                />
            </Stack>

            <Stack sx={{flexDirection: "row", alignItems: "center", gap: "70px"}}>
                <Stack gap={"5px"}>
                    <Typography
                        sx={{font: "normal normal 200 10px/13px Source Serif Pro"}}
                    >
                        {t('VIDEO_CENTER.AGENDA.ALLOTTED_TIME_IN_MEETING')}
                    </Typography>

                    <Stack
                        sx={{flexDirection: "row", alignItems: "center", gap: "10px"}}
                    >
                        <EditableTimeTextField
                            onValidation={(isValid) => setIsFormValid(isValid)}
                            initialValue={agendaItem?.allottedTime?.hrs}
                            onSave={(newValue) => handleTimeChange("hrs", newValue)}
                            variant="hrs"
                            labelText="hr"
                            containerSx={{
                                font: "normal normal 900 13px/16px Source Serif Pro",
                                gap: "5px",
                            }}
                            inputSx={{
                                height: "30px",
                                width: "40px",
                                border: "1px solid #E9EDF2",
                                borderRadius: "5px",
                                paddingLeft: "12px",
                            }}
                        />
                        <EditableTimeTextField
                            initialValue={agendaItem?.allottedTime?.mins}
                            onValidation={(isValid) => setIsFormValid(isValid)}
                            onSave={(newValue) => handleTimeChange("mins", newValue)}
                            variant="mins"
                            containerSx={{
                                font: "normal normal 900 13px/16px Source Serif Pro",
                                gap: "5px",
                            }}
                            inputSx={{
                                height: "30px",
                                width: "40px",
                                border: "1px solid #E9EDF2",
                                borderRadius: "5px",
                                paddingLeft: "12px",
                            }}
                        />
                    </Stack>
                </Stack>

                <Stack gap={"5px"}>
                    <Typography
                        sx={{font: "normal normal 200 10px/13px Source Serif Pro"}}
                    >
                        {t('VIDEO_CENTER.AGENDA.CONNECT_FILE_TO_AGENDA_ITEM')}
                    </Typography>
                    <Button
                        variant="text"
                        component="label"
                        sx={{
                            height: "30px",
                            border: "1px solid #E9EDF2",
                            borderRadius: "5px",
                            padding: "0px 10px",
                            flexDirection: "row",
                            justifyContent: "start",
                            textTransform: "none",
                            color: "black",
                        }}
                    >
                        <Typography
                            sx={{
                                font: "normal normal 900 13px/16px Source Serif Pro",
                                color: adjustHexColor("#000000", 30),
                            }}
                        >
                            {fileName || "Search & Choose File"}
                        </Typography>
                        <input type="file" hidden onChange={handleFileChange}/>
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default AgendaFormView;
