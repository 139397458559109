import VideoCenterTabs from "./components/VideoCenterTabs/VideoCenterTabs";

type CallsProps = {};

const Calls = (props: CallsProps) => {
    return(
        <div >
            <VideoCenterTabs/>
        </div>
    )
};

export default Calls;