import React from "react";
import { MultiSelectRadioBtn } from "../../../../../../../common";
import TitleContainer from "../../TitleContainer/TitleContainer";
import { AdditionalFilterMultiRadioBtnType } from "../../../../CommandControlSearchContainer";

const MeetingTypeList: AdditionalFilterMultiRadioBtnType = {
  _id: "meetingType",
  field: ["video"],
  translatedTitle: "Meeting Type",
  option: [
    { _id: "1", translatedTitle: "Scheduled", value: "Scheduled" },
    { _id: "2", translatedTitle: "Instant", value: "Instant" },
    { _id: "3", translatedTitle: "Recurring", value: "Recurring" },
    { _id: "4", translatedTitle: "Joined", value: "Joined" },
  ],
};

type ComponentDataType = string[]; // here define type for this component

interface MeetingTypeProps {
  stateData: ComponentDataType; // Current data passed from the parent
  setStateData: (data: ComponentDataType) => void; // Function to update data in the parent
}

const MeetingType: React.FC<MeetingTypeProps> = ({
  stateData,
  setStateData,
}) => {
  const handleSelectionChange = (value: string) => {
    const updatedMeetingTypes = stateData.includes(value)
      ? stateData.filter((item) => item !== value) // Remove if already selected
      : [...stateData, value]; // Add if not selected

    // Update the state in the parent
    setStateData(updatedMeetingTypes);
  };
  return (
    <TitleContainer title={MeetingTypeList.translatedTitle} isCollapasable>
      {MeetingTypeList.option.map((item) => (
        <MultiSelectRadioBtn
          key={item._id}
          isChecked={stateData.includes(item.value)}
          label={item.translatedTitle}
          onClick={() => handleSelectionChange(item.value)}
        />
      ))}
    </TitleContainer>
  );
};

export default MeetingType;
