import React from "react";
import {
  SmallThreeDotMenu,
  SmallThreeDotMenuListInterface,
} from "../../../../common";
import {
  AnalyticsIcon,
  ArchiveIcon,
  CardIcon,
  ColorIcon,
  DeleteIcon,
} from "../../../../../images";

interface ObjectiveCardThreeDotManuProps {}

const MENU_LISTS: SmallThreeDotMenuListInterface[] = [
  {
    id: 1,
    icon: CardIcon,
    text: "Open card",
  },
  {
    id: 2,
    icon: AnalyticsIcon,
    text: "Analytics",
  },
  {
    id: 3,
    icon: ColorIcon,
    text: "Edit color",
  },
  {
    id: 4,
    icon: ArchiveIcon,
    text: "Archive",
  },
  {
    id: 5,
    icon: DeleteIcon,
    text: "Delete objective",
    color: "red",
  },
];

const ObjectiveCardThreeDotManu: React.FC<
  ObjectiveCardThreeDotManuProps
> = ({}) => {
  return <SmallThreeDotMenu menuItems={MENU_LISTS} hoverText="Menu" />;
};

export default ObjectiveCardThreeDotManu;
