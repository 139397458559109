import { Stack, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";
import { ChevronIcon, ProjectIcon } from "../../../../../images";

interface ConnectedProjectChipButtonProps {
  project?: any;
  btnWidth?: any;
}

const ConnectedProjectChipButton: React.FC<ConnectedProjectChipButtonProps> = ({
  project,
  btnWidth,
}) => {
  const adjustedColor = adjustHexColor("#21B481", 70);
  return (
    <Stack sx={{ height: "35px", flexDirection: "row", gap: "10px"}}>
      <Stack
        sx={{
          width: "30px",
          borderRadius: "5px 0px",
          bgcolor: adjustedColor,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ProjectIcon w={20} color="white" />
      </Stack>

      <Stack
        sx={{
          width: btnWidth,
          borderRadius: "5px 0px",
          bgcolor: adjustedColor,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "15px",
          padding: "0px 10px 0px 5px",
          boxSizing: "border-box",
          cursor: "pointer",
        }}
      >
        <Typography
          sx={{
            font: "normal normal 400 13px/16px Source Serif Pro",
            color: "white",

            display: "-webkit-box",
            WebkitLineClamp: 1, // Limit to 2 lines
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {project?.name}
        </Typography>

        <ChevronIcon w={20} direction="right" color="#fff" />
      </Stack>
    </Stack>
  );
};

export default ConnectedProjectChipButton;
