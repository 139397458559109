export type SearchGroups =
  | "message"
  | "video"
  | "email"
  | "calendar"
  | "workspace"
  | "crm"
  | "trax";

export type SearchIconPngNameType =
  | "streamBearishMsgPng"
  | "slackPng"
  | "MicrosoftTeamPng"
  | "streamBearishCallPng"
  | "googleMeetPng"
  | "webexPng"
  | "zoomPng"
  | "outlookEmailPng"
  | "gmailPng"
  | "exchangeServerPng"
  | "bearishCalendarPng"
  | "outlookCalendarPng"
  | "googleCalendarPng"
  | "taskCenterPng"
  | "cloudStoragePng"
  | "docsPng"
  | "gridsPng"
  | "wikisPng"
  | "checklistPng"
  | "boardPng"
  | "decksPng"
  | "oneDrivePng"
  | "boxPng"
  | "dropboxPng"
  | "googleDrivePng"
  | "jiraPng"
  | "confluencePng"
  | "crmPng"
  | "salesForcePng"
  | "userAvatar"
  | "traxHrPng"
  | "successFactorPng"
  | "concurPng"
  | "bearishPng";

export enum FilterSourceValueEnum {
  BEARISH_OS = "bearishOS",
  TEAMS = "teams",
  EXCHANGE = "exchange",
  OUTLOOK_EMAIL = "outlookEmail",
  OUTLOOK_CALENDAR = "outlookCalendar",
  SALESFORCE = "salesforce",
  SLACK = "slack",
  JIRA = "jira",
  CONFLUENCE = "confluence",
  BOX = "box",
  DROPBOX = "dropbox",
  ONE_DRIVE = "oneDrive",
  WEBEX = "webex",
  ZOOM = "zoom",
  CONCUR = "concur",
  SUCCESS_FACTORS = "successFactors",
  GOOGLE_CALENDAR = "googleCalendar",
  GOOGLE_DRIVE = "googleDrive",
  GMAIL = "gmail",
  GOOGLE_MEET = "googleMeet",
}

export type sourcesOptionsType = {
  _id: string;
  tranlatedLabel: string;
  value: FilterSourceValueEnum;
  group: SearchGroups[];
  borderColor: SearchGroups | "clay";
  imgName: SearchIconPngNameType;
};
export const sourcesOptionsList: sourcesOptionsType[] = [
  {
    _id: "1",
    group: [
      "calendar",
      "crm",
      "email",
      "message",
      "trax",
      "video",
      "workspace",
    ],
    borderColor: "clay",
    imgName: "bearishPng",
    tranlatedLabel: "Bearish OS",
    value: FilterSourceValueEnum.BEARISH_OS,
  },
  {
    _id: "2",
    group: ["message", "video", "calendar"],
    borderColor: "message",
    imgName: "MicrosoftTeamPng",
    tranlatedLabel: "Teams",
    value: FilterSourceValueEnum.TEAMS,
  },
  {
    _id: "3",
    group: ["email"],
    borderColor: "email",
    imgName: "exchangeServerPng",
    tranlatedLabel: "Exchange",
    value: FilterSourceValueEnum.EXCHANGE,
  },
  {
    _id: "4",
    group: ["email"],
    borderColor: "email",
    imgName: "outlookEmailPng",
    tranlatedLabel: "Outlook Email",
    value: FilterSourceValueEnum.OUTLOOK_EMAIL,
  },
  {
    _id: "5",
    group: ["calendar"],
    borderColor: "calendar",
    imgName: "outlookCalendarPng",
    tranlatedLabel: "Outlook Calendar",
    value: FilterSourceValueEnum.OUTLOOK_CALENDAR,
  },
  {
    _id: "6",
    group: ["crm"],
    borderColor: "crm",
    imgName: "salesForcePng",
    tranlatedLabel: "Salesforce",
    value: FilterSourceValueEnum.SALESFORCE,
  },
  {
    _id: "7",
    group: ["message"],
    borderColor: "message",
    imgName: "slackPng",
    tranlatedLabel: "Slack",
    value: FilterSourceValueEnum.SLACK,
  },
  {
    _id: "8",
    group: ["workspace"],
    borderColor: "workspace",
    imgName: "jiraPng",
    tranlatedLabel: "Jira",
    value: FilterSourceValueEnum.JIRA,
  },
  {
    _id: "9",
    group: ["workspace"],
    borderColor: "workspace",
    imgName: "confluencePng",
    tranlatedLabel: "Confluence",
    value: FilterSourceValueEnum.CONFLUENCE,
  },
  {
    _id: "10",
    group: ["workspace"],
    borderColor: "workspace",
    imgName: "boxPng",
    tranlatedLabel: "Box",
    value: FilterSourceValueEnum.BOX,
  },
  {
    _id: "11",
    group: ["workspace"],
    borderColor: "workspace",
    imgName: "dropboxPng",
    tranlatedLabel: "Dropbox",
    value: FilterSourceValueEnum.DROPBOX,
  },
  {
    _id: "12",
    group: ["workspace"],
    borderColor: "workspace",
    imgName: "oneDrivePng",
    tranlatedLabel: "OneDrive",
    value: FilterSourceValueEnum.ONE_DRIVE,
  },
  {
    _id: "13",
    group: ["video"],
    borderColor: "video",
    imgName: "webexPng",
    tranlatedLabel: "Webex",
    value: FilterSourceValueEnum.WEBEX,
  },
  {
    _id: "14",
    group: ["video"],
    borderColor: "video",
    imgName: "zoomPng",
    tranlatedLabel: "Zoom",
    value: FilterSourceValueEnum.ZOOM,
  },
  {
    _id: "15",
    group: ["trax"],
    borderColor: "trax",
    imgName: "concurPng",
    tranlatedLabel: "Concur",
    value: FilterSourceValueEnum.CONCUR,
  },
  {
    _id: "16",
    group: ["trax"],
    borderColor: "trax",
    imgName: "successFactorPng",
    tranlatedLabel: "SuccessFactors",
    value: FilterSourceValueEnum.SUCCESS_FACTORS,
  },
  {
    _id: "17",
    group: ["calendar"],
    borderColor: "calendar",
    imgName: "googleCalendarPng",
    tranlatedLabel: "Google Calendar",
    value: FilterSourceValueEnum.GOOGLE_CALENDAR,
  },
  {
    _id: "18",
    group: ["workspace"],
    borderColor: "workspace",
    imgName: "googleDrivePng",
    tranlatedLabel: "Google Drive",
    value: FilterSourceValueEnum.GOOGLE_DRIVE,
  },
  {
    _id: "19",
    group: ["email"],
    borderColor: "email",
    imgName: "gmailPng",
    tranlatedLabel: "Gmail",
    value: FilterSourceValueEnum.GMAIL,
  },
  {
    _id: "20",
    group: ["video"],
    borderColor: "video",
    imgName: "googleMeetPng",
    tranlatedLabel: "Google Meet",
    value: FilterSourceValueEnum.GOOGLE_MEET,
  },
];

export enum KeyWordsMatchStateEnum {
  EXACT_MATCH = "EXACT_MATCH",
  CONTAINS = "CONTAINS",
  DOES_NOT_CONTAIN = "DOES_NOT_CONTAIN",
}

export type AdditionalFilterType =
  | "meetingType"
  | "duration"
  | "recordingStatus"
  | "emailType"
  | "emailSource"
  | "emailBasicFilters"
  | "eventType"
  | "eventStatus"
  | "bookingLink"
  | "messageTypeAndStatus"
  | "messageSource"
  | "attachements"
  | "contactConnections"
  | "interactionType"
  | "groupOrDealName"
  | "employeeType"
  | "employeeDepartment"
  | "employeeStatus"
  | "workspaceType"
  | "workspaceOwnership"
  | "cloudStorageOwnership"
  | "cloudStorageType"
  | "cloudStorageSize";

export type filterValueType = {
  centersValue: SearchGroups[];
  conditionalCentersValue: SearchGroups[];
  sources: sourcesOptionsType[];
  dateAndTime: {
    from: { start: string; end: string };
    on: { start: string };
    at: { start: string };
  };
  primaryKeywords: {
    bodyKeywords: string[];
    matchType: KeyWordsMatchStateEnum;
  };
  additionalFields: Record<AdditionalFilterType, any>;
};

export type AdditionalFilterMultiRadioBtnType = {
  _id: string;
  field: SearchGroups[];
  translatedTitle: string;
  option: { _id: string; translatedTitle: string; value: string }[];
};

export const dummy_CommandControlSearchData: any[] = [
  {
    _id: "abcd1234",
    resultType: "message",
    oriantations: "slackPng",
    userData: [
      {
        name: "John Doe",
        userImgSrc: "https://randomuser.me/api/portraits/men/1.jpg",
        status: true,
      },
    ],
    details: `This is a random example message that was sent by this person up here. This is a randomly generated example message. More random content follows...`,
    dateOrTime: "10:45 am",
  },

  {
    _id: "abcd1236",
    resultType: "message",
    oriantations: "streamBearishMsgPng",
    userData: [{ name: "Jane Smith", userImgSrc: "", status: false }],
    details:
      "This is a random text message. More random content follows with new details for a different user. The message content is now dynamic.",
    dateOrTime: "03/12/24",
  },
  {
    _id: "abcd1236",
    resultType: "message",
    oriantations: "MicrosoftTeamPng",
    userData: [
      {
        name: "Alice Johnson",
        userImgSrc: "https://randomuser.me/api/portraits/women/2.jpg",
        status: true,
      },
    ],
    details:
      "A dynamic message that is generated randomly for this example. This content may change or vary depending on the context or time.",
    dateOrTime: "11:15 am",
  },

  {
    _id: "abcd1235",
    resultType: "video",
    oriantations: "googleMeetPng",
    userData: [
      {
        name: "Robert Brown",
        userImgSrc: "https://randomuser.me/api/portraits/men/3.jpg",
      },
    ],
    resultTitle: "Random Meeting",
    details:
      "This is a random meeting message. The details of this meeting are dynamically generated. Additional random content can be added as needed.",
    dateOrTime: "09:30 am",
  },

  {
    _id: "abcd1235",
    resultType: "video",
    oriantations: "zoomPng",
    userData: [{ name: "Emily Davis", userImgSrc: "" }],
    resultTitle: "Zoom Session 101",
    details:
      "This Zoom session involves random topics. Details are randomly generated based on context. User data and session details may vary.",
    dateOrTime: "05:00 pm",
  },
  {
    _id: "abcd1235dd",
    resultType: "video",
    oriantations: "zoomPng",
    userData: [
      {
        name: "William Mike",
        userImgSrc: "https://randomuser.me/api/portraits/men/4.jpg",
        email: "",
        avatarColor: "",
      },
      {
        name: "Olivia Clark",
        userImgSrc: "https://randomuser.me/api/portraits/women/3.jpg",
        email: "",
        avatarColor: "",
      },
      {
        name: "Olivia Clark",
        // userImgSrc: "https://randomuser.me/api/portraits/women/13.jpg",
        email: "",
        avatarColor: "",
      },
      {
        name: "Olivia Clark",
        // userImgSrc: "https://randomuser.me/api/portraits/women/23.jpg",
        email: "",
        avatarColor: "",
      },
      {
        name: "Olivia Clark",
        // userImgSrc: "https://randomuser.me/api/portraits/women/33.jpg",
        email: "",
        avatarColor: "",
      },
      {
        name: "Olivia Clark",
        // userImgSrc: "https://randomuser.me/api/portraits/women/43.jpg",
        email: "",
        avatarColor: "",
      },
      {
        name: "James Lewis",
        userImgSrc: "https://randomuser.me/api/portraits/men/5.jpg",
        email: "",
        avatarColor: "",
      },
      { name: "Sophia Walker", userImgSrc: "" },
    ],
    resultTitle: "The Meeting - Randomized",
    details:
      "This is a large group meeting with randomized user participation. Content and details are generated on the fly. This message can vary based on context.",
    dateOrTime: "07:30 am",
  },

  {
    _id: "abcd1235dd",
    resultType: "email",
    oriantations: "outlookEmailPng",
    userData: [
      {
        name: "Daniel Lewis",
        userImgSrc: "https://randomuser.me/api/portraits/men/6.jpg",
      },
    ],
    details: "This is an email with a randomized content",
    dateOrTime: "01:00 pm",
  },

  {
    _id: "abcd1235dd",
    resultType: "crm",
    oriantations: "salesForcePng",
    userData: [{ name: "Michael Thomas", userImgSrc: "" }],
  },
  {
    _id: "abcd1235dd",
    resultType: "trax",
    oriantations: "traxHrPng",
    userData: [{ name: "Jordan Michaelson", userImgSrc: "" }],
    primaryDetails: "Payroll",
    details: "$100,000.00",
    action: "paid at",
    dateOrTime: "08/10/24",
  },
  {
    _id: "abcd1235123",
    resultType: "trax",
    oriantations: "userAvatar",
    headerUserData: {
      name: "Olivia Clark",
      userImgSrc: "https://randomuser.me/api/portraits/women/43.jpg",
    },
    userData: [{ name: "Jordan Michaelson", userImgSrc: "" }],
    primaryDetails: "Payroll",
    details: "$100,000.00",
    action: "paid at",
    dateOrTime: "08/10/24",
  },

  {
    _id: "doc1234",
    resultType: "workspace",
    oriantations: "oneDrivePng",
    fileType: "pdf",
    resultTitle: "Example PDF Document Ideas",
    dateOrTime: "08/10/24",
  },
  {
    _id: "doc1234",
    resultType: "workspace",
    oriantations: "oneDrivePng",
    fileType: "mp4",
    resultTitle: "Example videofile here",
    dateOrTime: "08/10/24",
  },
  {
    _id: "doc1234",
    resultType: "workspace",
    oriantations: "dropboxPng",
    fileType: "doc",
    resultTitle: "Document file name here",
    dateOrTime: "08/10/24",
  },
  {
    _id: "doc1234",
    resultType: "workspace",
    oriantations: "googleDrivePng",
    fileType: "txt",
    resultTitle: "Example text Document file",
    dateOrTime: "08/10/24",
  },

  {
    _id: "abcd1235",
    resultType: "video",
    oriantations: "zoomPng",
    userData: [{ name: "Ava Martinez", userImgSrc: "" }],
    resultTitle: "The Meeting Name",
    primaryDetails: "Bearish OS Workspace Name",
    dateOrTime: "08/10/24",
  },
];
