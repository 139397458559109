import {
  Divider,
  IconButton,
  InputBase,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import React from "react";
import { SearchIcon } from "../../../images";
import { SearchLoader } from "../SearchLoader";

interface SearchMembersProps {
  isLoading?: boolean;
  children?: React.ReactNode;
  viewMoreButtonClick?: () => void;
  childrenContainerSx?: SxProps;
}

const SearchMembers: React.FC<SearchMembersProps> = ({
  children,
  viewMoreButtonClick,
  childrenContainerSx,
  isLoading = false,
}) => {
  return (
    <Stack
      sx={{
        width: "515px",
        boxSizing: "border-box",
        borderRadius: "5px",
        border: "2px solid #E9EDF2",
        boxShadow: "5px 5px 5px 0px rgba(0, 0, 0, 0.16)",
        bgcolor: "white",
        padding: "0px 20px",
      }}
    >
      {/* Search area here */}
      <Stack
        sx={{
          height: "60px",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        {isLoading ? <SearchLoader w={25} /> : <SearchIcon w={25} />}

        <InputBase
          placeholder="Search here"
          sx={{
            flex: 1,
            font: "normal normal 900 18px/23px Source Serif Pro",
          }}
        />
      </Stack>

      {/* --------------------- */}
      {children && (
        <Stack sx={{ marginTop: "12px", gap: "20px" }}>
          <Divider sx={{ borderBottom: "1px solid #E9EDF2" }} />
          <Typography
            sx={{ font: "normal normal 900 13px/16px Source Serif Pro" }}
          >
            Results
          </Typography>

          <Stack
            sx={{
              maxHeight: "250px",
              gap: "20px",
              overflow: "auto",
              scrollbarWidth: "none",
              ...childrenContainerSx,
            }}
          >
            {children}
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "end",
              marginBottom: "40px",
              paddingRight: "30px",
            }}
          >
            <IconButton
              onClick={viewMoreButtonClick}
              sx={{
                padding: "2px 4px",
                borderRadius: 0,
                color: "black",
                font: "normal normal 900 13px/16px Source Serif Pro",
              }}
              disableRipple
            >
              View More...
            </IconButton>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default SearchMembers;
