import React from "react";
import { ReimburseMenuExpense } from "../../components/ReimburseMenuExpense";
import { Stack } from "@mui/material";

interface TableExpTypeAreaProps {
  value: any;
}

const TableExpTypeArea: React.FC<TableExpTypeAreaProps> = ({ value }) => {
  return (
    <Stack alignItems={"center"}>
      <ReimburseMenuExpense initialSelected={value} onChange={() => null} />
    </Stack>
  );
};

export default TableExpTypeArea;
