// TaskView.tsx
import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { SidebarCalendarDataListInterface } from "../../SidebarCalendarType";
import { StyledRadioButtonBlack } from "../../../../../../common";

type TaskDataListType = Extract<
  SidebarCalendarDataListInterface["dataList"][number],
  { type: "task" }
>["data"];

// Define the props for TaskView
interface TaskViewProps {
  tasks: any;
  updateTaskState?: any
}

const TaskView: React.FC<TaskViewProps> = ({ tasks, updateTaskState }) => {
  const [taskCompleted, setTaskCompleted] = useState<boolean>(
    tasks?.isCompleted
  );

  return (
    <Stack
      sx={{
        gap: "10px",
        bgcolor: "white",
        flexDirection: "row",
        alignItems: "start",
        padding: "5px",
        borderRadius: "5px",
        border: "1px solid white",
      }}
    >
      <StyledRadioButtonBlack
        radioSize={20}
        checked={taskCompleted}
        onClick={() => {
          const newTaskState = !taskCompleted;
          setTaskCompleted(newTaskState);
          updateTaskState(tasks?.e?.calendarId, tasks?.e?._id); // Call the function with the updated state
        }}
      />


      <Stack>
        <Typography
          sx={{
            font: "normal normal 300 15px/19px Source Serif Pro",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "200px",
            textDecoration: taskCompleted ? "line-through" : "none",
          }}
        >
          {tasks?.taskName}
        </Typography>
        <Typography
          sx={{
            font: "normal normal 300 13px/16px Source Serif Pro",
            textDecoration: taskCompleted ? "line-through" : "none",
            color: "#B9B9B9",
          }}
        >
          {tasks?.taskTime}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default TaskView;
