import React from "react";
import { SmallThreeDotMenu } from "../../../../common";
import { ThreeDotMenuTraxCommandUsersOptions } from "./ThreeDotMenuTraxCommandUsersType";

interface ThreeDotMenuTraxCommandUsersProps {}

const ThreeDotMenuTraxCommandUsers: React.FC<
  ThreeDotMenuTraxCommandUsersProps
> = ({}) => {
  return (
    <SmallThreeDotMenu
      menuItems={ThreeDotMenuTraxCommandUsersOptions}
      menuWidth="150px"
      displayOption="left"
    />
  );
};

export default ThreeDotMenuTraxCommandUsers;
