import { IconButton, Popover, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { FundingIcon } from "../../../../../../../images";
import { CurrencyDropdown, DealStageDropdown } from "../../../../../../common";

interface CurrencyViewProps {}

const CurrencyView: React.FC<CurrencyViewProps> = ({}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "currency-popover" : undefined;

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingRight: "20px",
      }}
    >
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
        <FundingIcon w={15} />
        <Typography
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
        >
          Currency
        </Typography>
      </Stack>
      {/* <IconButton
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          font: "normal normal 900 12px/15px Source Serif Pro",
          color: "black",
          p: "0px 5px",
          borderRadius: 0,
        }}
        disableRipple
      >
        USD
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            padding: 0,
          },
        }}
      >
        <DealStageDropdown onChange={() => null} />
      </Popover> */}
      <CurrencyDropdown />
    </Stack>
  );
};

export default CurrencyView;
