import React, { useState } from "react";
import TitleContainer from "../../TitleContainer/TitleContainer";
import { MultiSelectRadioBtn } from "../../../../../../../common";
import { AdditionalFilterMultiRadioBtnType } from "../../../../CommandControlSearchContainer";

const DurationList: AdditionalFilterMultiRadioBtnType = {
  _id: "duration",
  field: ["video", "calendar"],
  translatedTitle: "Duration",
  option: [
    {
      _id: "1",
      translatedTitle: "Short (Less than 30 mins)",
      value: "short",
    },
    {
      _id: "2",
      translatedTitle: "Medium (30 mins to 60 mins)",
      value: "medium",
    },
    {
      _id: "3",
      translatedTitle: "Long (60 mins +)",
      value: "long",
    },
  ],
};

type ComponentDataType = string[];

interface DurationProps {
  stateData: ComponentDataType; // Current data passed from the parent
  setStateData: (data: ComponentDataType) => void; // Function to update data in the parent
}

const Duration: React.FC<DurationProps> = ({ setStateData, stateData }) => {
  // const [durationValues, setDurationValues] = useState<string[]>([]);

  const handleSelectionChange = (value: string) => {
    const updatedMeetingTypes = stateData.includes(value)
      ? stateData.filter((item) => item !== value) // Remove if already selected
      : [...stateData, value]; // Add if not selected

    // Update the state in the parent
    setStateData(updatedMeetingTypes);
  };
  return (
    <TitleContainer title={DurationList.translatedTitle} isCollapasable>
      {DurationList.option.map((item) => (
        <MultiSelectRadioBtn
          key={item._id}
          isChecked={stateData.includes(item.value)}
          label={item.translatedTitle}
          onClick={() => handleSelectionChange(item.value)}
        />
      ))}
    </TitleContainer>
  );
};

export default Duration;
