import { IconButton, Stack } from "@mui/material";
import React from "react";
import { NotesIcon, NotesLineIcon } from "../../../../../../../images";

interface NotesViewProps {
  onButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const NotesView: React.FC<NotesViewProps> = ({ onButtonClick }) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
      }}
    >
      <IconButton onClick={(event) => onButtonClick?.(event)}>
        <NotesLineIcon w={15} />
      </IconButton>
    </Stack>
  );
};

export default NotesView;
