import React, { MouseEvent, useState } from "react";
import { Button, IconButton, Popover, Stack, Typography } from "@mui/material";
import styles from "./ObjectiveAndTaskLargeModel.module.css";
import {
  ClockIcon,
  ColorIcon,
  CoverIcon,
  DepedanciesIcon,
  FlagIcon,
  LastCalendarIcon,
  PreviewIcon,
  StatusIcon,
  StrongestrelationshipIcon,
} from "../../../../../images";
import {
  AssigneesDropdown,
  CheckList,
  ConnectedToTask,
  CustomElements,
  DetailsTextArea,
  SubTasks,
  TaskTitle,
} from "./view";
import {
  DropdownWithColor,
  DUMMY_PRIORITY_OPTIONS,
  DUMMY_TODO_OPTIONS,
  GreenArrowButton,
  EditableTimeTextField,
  IconWordIconDropdown,
  ProjectImageSelector,
  SingleDatePickerSmall,
  SquareColorPicker,
  EmojiPickerSmall,
} from "../../../../common";
import { createTask__api, CreateTaskPayload } from "../../../../../redux";
import { useDispatch } from "react-redux";
import { getUser } from "../../../../../Utils";

interface OandTaskLargeModelProps {
  toggleDrawer?: () => void;
  mode: "add" | "view"; // Add mode prop to differentiate behavior
  workspaceItemData?: any;
  fetchComponentData?: (justTask: boolean) => void;
  objectTaskData?: any;
  updateStackData?: any;
}
const getDefaultDueDate = () => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 7); // Add 7 days to the current date
  return currentDate.toISOString(); // Convert to ISO string for consistency
};
const ObjectiveAndTaskLargeModel: React.FC<OandTaskLargeModelProps> = ({
  toggleDrawer,
  mode, // Destructure the mode prop
  workspaceItemData,
  fetchComponentData,
  objectTaskData,
  updateStackData,
}) => {
  console.log("mode", mode, "workspaceItemData", workspaceItemData,"objectTaskData", objectTaskData)
  const dispatch = useDispatch();
  const user = getUser();

  const [taskDetails, setTaskDetails] = useState(() => {
    const defaultPriority = {
      id: 3,
      value: "Low",
      defaultColorHex: "#00FF00",
      customColorHex: "#00FF00",
    };
  
    const defaultStatus = {
      id: 1,
      value: "To Do",
      defaultColorHex: "#808080",
      customColorHex: "#808080",
    };
  
    const defaultValues = {
      assignees: [], // No assignees initially
      priority: defaultPriority,
      dueDate: getDefaultDueDate(),
      status: defaultStatus,
      cardColor: "#EF7028",
      hoursValue: "00",
      minutesValue: "00",
      isExpanded: false,
      workspaceName: "",
      dependencies: 0,
      emoji: "🚀",
      taskType: "Milestone",
    };
  
    if (mode === "view") {
      return {
        id: objectTaskData?.id || "",
        taskName: objectTaskData?.text || objectTaskData?.title || "",
        details: objectTaskData?.detail || "",
        assignees: objectTaskData?.assignedTo || defaultValues.assignees,
        priority: objectTaskData?.priority || defaultValues.priority,
        dueDate: objectTaskData?.dueDate || defaultValues.dueDate,
        status: objectTaskData?.status || defaultValues.status,
        cardColor: objectTaskData?.color || defaultValues.cardColor,
        hoursValue: objectTaskData?.estimatedTime?.hours || defaultValues.hoursValue,
        minutesValue: objectTaskData?.estimatedTime?.minutes || defaultValues.minutesValue,
        isExpanded: defaultValues.isExpanded,
        workspaceName: defaultValues.workspaceName,
        dependencies: defaultValues.dependencies,
        emoji: objectTaskData?.emoji || defaultValues.emoji,
        taskType: objectTaskData?.taskType || defaultValues.taskType,
      };
    }
  
    return {
      taskName: mode === "add" ? "" : "Existing Task",
      details: "",
      assignees: defaultValues.assignees, // No assignees initially
      priority: defaultValues.priority,
      dueDate: defaultValues.dueDate,
      status: defaultValues.status,
      cardColor: defaultValues.cardColor,
      hoursValue: defaultValues.hoursValue,
      minutesValue: defaultValues.minutesValue,
      isExpanded: defaultValues.isExpanded,
      workspaceName: defaultValues.workspaceName,
      dependencies: defaultValues.dependencies,
      emoji: defaultValues.emoji,
      taskType: defaultValues.taskType,
    };
  });
  


  const handleSaveTask = async () => {
    if (!workspaceItemData?._id) {
      return;
    }

    const {
      taskName,
      details,
      assignees,
      priority,
      dueDate,
      status,
      cardColor,
      hoursValue,
      minutesValue,
      dependencies,
      emoji,
      taskType,
    } = taskDetails;

    // Convert hours and minutes to total minutes
    const estimatedTime =
      parseInt(hoursValue, 10) * 60 + parseInt(minutesValue, 10);
  // Ensure to append the current user's ID before creating the task
  const updatedAssignees = [...assignees, user?.data?._id].filter(Boolean);
    const payload: CreateTaskPayload = {
      name: taskName,
      details,
      componentId: workspaceItemData?._id, // Example componentId
      assignedUserIds: updatedAssignees, // Use the updated assignees array
      priority,
      dueDate, // Make sure it's in ISO format
      status,
      taskColor: cardColor,
      estimatedTime, // Total minutes
      dependencies,
      cardColor,
      emoji,
      taskType,
    };

    const action = createTask__api(payload);

    try {
      const res: any = await dispatch(action);

      if (res?.success && res?.message === "Task added successfully") {
        if (fetchComponentData) {
          fetchComponentData(true);
        }
      }
    } catch (error) {
      // Optionally handle error (e.g., show an error message)
      console.error("Failed to create task:", error);
      return;
    }

    if (toggleDrawer) {
      toggleDrawer();
    }
  };

  const [anchorElUpdateCover, setAnchorElUpdateCover] =
    useState<HTMLButtonElement | null>(null);

  // const handleOpenUpdateCover = (event: MouseEvent<HTMLButtonElement>) => {
  //   setAnchorElUpdateCover(event.currentTarget);
  // };
  const handleCloseUpdateCover = () => {
    setAnchorElUpdateCover(null);
  };

  const openUpdateCover = Boolean(anchorElUpdateCover);

  const toggleExpand = () => {
    setTaskDetails((prev) => ({
      ...prev,
      isExpanded: !prev.isExpanded,
    }));
  };

  const updateTaskDetails = (field: string, value: string) => {
    setTaskDetails((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Handle adding a new assignee (only _id) without removing the existing ones
  const handleAssigneeSelect = (userData: any) => {

    setTaskDetails((prevDetails: any) => {
      const { _id } = userData; // Extract _id from userData

      // Ensure prevDetails and assignees exist
      const currentAssignees = prevDetails?.assignees || [];

      // Check if the selected user _id is already in the assignees array
      const isAlreadyAssigned = currentAssignees.includes(_id);

      // If not, add the new _id to the assignees array
      const updatedAssignees = isAlreadyAssigned
        ? currentAssignees // Keep the existing array if the user is already assigned
        : [...currentAssignees, _id]; // Otherwise, add the new _id

      // Return the updated taskDetails object with the modified assignees array
      return {
        ...prevDetails,
        assignees: updatedAssignees,
      };
    });
  };

  const helperupdateStackData = (field: any, value: any) => {
    if (!field || !value) return;
    if (mode === "view" && updateStackData) {
      updateStackData?.(taskDetails?.id, field, value);
    }
  };

  return (
    <Stack
      sx={{ paddingBottom: mode === "add" ? "20px" : "0" }}
      className={styles["objective-and-task"]}
    >
      {/* header */}
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        className={styles["objective-and-task__header"]}
      >
        <Stack
          onClick={() => {
            toggleDrawer && toggleDrawer();
          }}
          direction={"row"}
          gap={"5px"}
          alignItems={"center"}
        >
          <IconButton sx={{ p: 0 }} disableRipple>
            <PreviewIcon w={25} />
          </IconButton>
          <Typography className={styles["objective-and-task__header-text"]}>
            Close
          </Typography>
        </Stack>

        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "40px" }}>
          <IconWordIconDropdown
            initialSelectedOptions={taskDetails?.taskType}
            onChange={(taskType: any) => {
              setTaskDetails((prevDetails: any) => ({
                ...prevDetails,
                taskType: taskType,
              }));

              helperupdateStackData("taskType", taskType);
            }}
          />

          <Stack>
            <IconButton
              sx={{
                borderRadius: "5px",
                gap: "5px",
                ":hover": { backgroundColor: "#fbfbfc" },
              }}
              disableRipple
            // onClick={handleOpenUpdateCover}
            >
              <EmojiPickerSmall
                w={20}
                onSelect={(emoji: any) => {
                  setTaskDetails((prevDetails: any) => ({
                    ...prevDetails,
                    emoji: emoji?.native,
                  }));
                  helperupdateStackData("emoji", emoji?.native);
                }}
                showEmojiOnSelect={true}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
              />
              <Typography
                sx={{ font: "normal normal 300 14px/20px Source Serif Pro" }}
              >
                Add icon
              </Typography>
            </IconButton>
            <Popover
              open={openUpdateCover}
              anchorEl={anchorElUpdateCover}
              onClose={handleCloseUpdateCover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sx={{
                ".MuiPopover-paper": {
                  padding: "4px",
                  boxShadow: "none",
                  border: "none",
                },
              }}
            >
              <ProjectImageSelector />
            </Popover>
          </Stack>
        </Stack>
      </Stack>

      {/* body */}
      <Stack width={'100%'} className={styles["objective-and-task__body"]}>
        <Stack className={styles["objective-and-task__content"]}>
          <TaskTitle
            taskTitle={taskDetails.taskName}
            onTasknameChange={(value) => {
              updateTaskDetails("taskName", value);
              helperupdateStackData("text", value);
            }}
            workspaceName={`${workspaceItemData?.name}` || "Workspace Name"}
          />
          <Stack gap={"30px"}>
            <Stack
              className={`${styles["objective-and-task__scrollable"]} ${taskDetails.isExpanded
                ? styles["objective-and-task__scrollable--collapsed"]
                : ""
                } `}
            >
              <Stack className={styles["objective-and-task__option-row"]}>
                <Stack className={styles["objective-and-task__option-label"]}>
                  <StatusIcon w={25} />
                  <Typography
                    sx={{
                      font: "normal normal 300 14px/20px Source Serif Pro",
                    }}
                  >
                    Status
                  </Typography>
                </Stack>
                <Stack className={styles["objective-and-task__option-control"]}>
                  <DropdownWithColor
                    options={DUMMY_TODO_OPTIONS}
                    onChange={(value) => null}
                    initialValue={taskDetails?.status}
                    onSelect={(value) => {
                      setTaskDetails((prevDetails: any) => ({
                        ...prevDetails,
                        status: {
                          id: value?.id,
                          value: value?.value,
                          defaultColorHex: value?.defaultColorHex,
                          customColorHex: value?.customColorHex || "",
                        },
                      }));
                      helperupdateStackData("status", value);
                    }}
                  />
                </Stack>
              </Stack>

              <Stack className={styles["objective-and-task__option-row"]}>
                <Stack className={styles["objective-and-task__option-label"]}>
                  <FlagIcon w={25} />
                  <Typography
                    sx={{
                      font: "normal normal 300 14px/20px Source Serif Pro",
                    }}
                  >
                    Priority
                  </Typography>
                </Stack>
                <Stack className={styles["objective-and-task__option-control"]}>
                  <DropdownWithColor
                    options={DUMMY_PRIORITY_OPTIONS}
                    onChange={(value) => null}
                    initialValue={taskDetails?.priority}
                    onSelect={(value) => {
                      setTaskDetails((prevDetails: any) => ({
                        ...prevDetails,
                        priority: {
                          id: value?.id,
                          value: value?.value,
                          defaultColorHex: value?.defaultColorHex,
                          customColorHex: value?.customColorHex || "",
                        },
                      }));

                      helperupdateStackData("priority", value);
                    }}
                  />
                </Stack>
              </Stack>

              <Stack className={styles["objective-and-task__option-row"]}>
                <Stack className={styles["objective-and-task__option-label"]}>
                  <ClockIcon w={25} />
                  <Typography
                    sx={{
                      font: "normal normal 300 14px/20px Source Serif Pro",
                    }}
                  >
                    Due date
                  </Typography>
                </Stack>
                <Stack
                  id="objectivedatepicker"
                  className={styles["objective-and-task__option-control"]}
                >
                  <SingleDatePickerSmall
                    initialDate={taskDetails?.dueDate}
                    onSelect={(date: any) => {
                      setTaskDetails((prevDetails: any) => ({
                        ...prevDetails,
                        dueDate: date,
                      }));
                      helperupdateStackData("dueDate", date);
                    }}
                  />
                </Stack>
              </Stack>

              <Stack className={styles["objective-and-task__option-row"]}>
                <Stack className={styles["objective-and-task__option-label"]}>
                  <ColorIcon w={25} />
                  <Typography
                    sx={{
                      font: "normal normal 300 14px/20px Source Serif Pro",
                    }}
                  >
                    Card color
                  </Typography>
                </Stack>
                <Stack className={styles["objective-and-task__option-control"]}>
                  <SquareColorPicker
                    initialColor={taskDetails?.cardColor}
                    showColorName
                    onColorChange={(color) => {
                      setTaskDetails((prevDetails: any) => ({
                        ...prevDetails,
                        cardColor: color,
                      }));

                      helperupdateStackData("cardColor", color);
                    }}
                  />
                </Stack>
              </Stack>

              <Stack className={styles["objective-and-task__option-row"]}>
                <Stack className={styles["objective-and-task__option-label"]}>
                  <StrongestrelationshipIcon w={25} />
                  <Typography
                    sx={{
                      font: "normal normal 300 14px/20px Source Serif Pro",
                    }}
                  >
                    Assignees
                  </Typography>
                </Stack>
                <Stack className={styles["objective-and-task__option-control"]}>
                  <AssigneesDropdown
                    mode={mode}
                    selectedUser={ {
                      id: parseInt(user?.data?._id || "0", 10),
                      name: user?.data?.name || "Unknown User",
                      email: user?.data?.email || "unknown@example.com",
                      avatarColor: "#33c5ff", // Default color
                      imgSrc: user?.data?.avatar
                  } }
                    onSelect={(userData: any) => {
                      handleAssigneeSelect(userData);
                      helperupdateStackData("assignedTo", userData);
                    }}
                    allusers={taskDetails?.assignees}
                  />
                </Stack>
              </Stack>

              <Stack className={styles["objective-and-task__option-row"]}>
                <Stack className={styles["objective-and-task__option-label"]}>
                  <LastCalendarIcon w={25} />
                  <Typography
                    sx={{
                      font: "normal normal 300 14px/20px Source Serif Pro",
                    }}
                  >
                    Time estimated
                  </Typography>
                </Stack>
                <Stack
                  gap={"15px"}
                  className={styles["objective-and-task__option-control"]}
                >
                  <EditableTimeTextField
                    variant="hrs"
                    initialValue={taskDetails.hoursValue}
                    onSave={(newValue: string) => {
                      updateTaskDetails("hoursValue", newValue);

                      helperupdateStackData("estimatedTime", {
                        hours: newValue,
                        minutes: taskDetails?.minutesValue || 0,
                      });
                    }}
                  />
                  <EditableTimeTextField
                    variant="mins"
                    initialValue={taskDetails?.minutesValue || "00"}
                    onSave={(newValue: string) => {
                      updateTaskDetails("minutsValue", newValue);

                      helperupdateStackData("estimatedTime", {
                        hours: taskDetails?.hoursValue,
                        minutes: newValue || 0,
                      });
                    }}
                  />
                </Stack>
              </Stack>

              <Stack className={styles["objective-and-task__option-row"]}>
                <Stack className={styles["objective-and-task__option-label"]}>
                  <DepedanciesIcon w={25} />
                  <Typography
                    sx={{
                      font: "normal normal 300 14px/20px Source Serif Pro",
                    }}
                  >
                    Dependancies
                  </Typography>
                </Stack>
                <Stack className={styles["objective-and-task__option-control"]}>
                  <Typography
                    sx={{
                      font: "normal normal 300 14px/20px Source Serif Pro",
                    }}
                  >
                    0/0
                  </Typography>
                </Stack>
              </Stack>
            </Stack>

            <Stack flexDirection={"row"} onClick={toggleExpand}>
              <Stack
                direction={"row"}
                gap={"5px"}
                className={styles["objective-and-task__show-fewer-fields"]}
              >
                <GreenArrowButton
                  sx={{
                    transform: taskDetails.isExpanded
                      ? "rotate(90deg)"
                      : "rotate(270deg)",
                    transition: "transform 0.5s ease-in-out",
                  }}
                />

                <Typography
                  sx={{ font: "normal normal 300 13px/17px Source Serif Pro" }}
                >
                  {!taskDetails.isExpanded
                    ? "Show fewer fields"
                    : "Show more fields"}
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          <DetailsTextArea
            initialValue={taskDetails?.details || ""}
            onChange={(value) => {
              setTaskDetails((prevDetails: any) => ({
                ...prevDetails,
                details: value,
              }));

              helperupdateStackData("detail", value);
            }}
          />
          {mode !== "add" && (
            <>
              <SubTasks componentId={objectTaskData?.id || ""} />
              <CheckList
                objectTaskData={objectTaskData}
                helperupdateStackData={helperupdateStackData}
              />
              <CustomElements
                objectTaskData={objectTaskData}
                helperupdateStackData={helperupdateStackData}
              />
            </>
          )}
          <ConnectedToTask />
        </Stack>
      </Stack>

      {/* Sticky Button */}
      {mode === "add" && (
        <div className={styles["sticky-button-container"]}>
          <Stack direction={"row"} alignItems={"center"} gap={2} width={"100%"}>
            <Button
              onClick={() => handleSaveTask()}
              disabled={
                !taskDetails?.taskName ||
                !taskDetails?.status?.value ||
                !taskDetails?.priority?.value ||
                taskDetails?.assignees?.length === 0
              }
              className={styles["sticky-button"]}
            >
              Create
            </Button>
            <Typography className={styles.loeq}>
              Create and Add Another
            </Typography>
          </Stack>
        </div>
      )}
    </Stack>
  );
};

export default ObjectiveAndTaskLargeModel;
