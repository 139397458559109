import React from "react";
import TitleContainer from "../../TitleContainer/TitleContainer";
import { MultiSelectRadioBtn } from "../../../../../../../common";
import { AdditionalFilterMultiRadioBtnType } from "../../../../CommandControlSearchContainer";

const messageTypeList: AdditionalFilterMultiRadioBtnType = {
  _id: "messageType",
  field: ["message"],
  translatedTitle: "Message Type and Status",
  option: [
    { _id: "1", translatedTitle: "Direct", value: "direct" },
    { _id: "2", translatedTitle: "Unread", value: "unread" },
    { _id: "3", translatedTitle: "Group", value: "group" },
    { _id: "4", translatedTitle: "Read", value: "read" },
    { _id: "5", translatedTitle: "Channel", value: "channel" },
    { _id: "6", translatedTitle: "Starred/Pinned", value: "starred/pinned" },
  ],
};

type ComponentDataType = string[]; // this is type for this component data
interface MessageTypeAndStatusProps {
  stateData: ComponentDataType; // Current data passed from the parent
  setStateData: (data: ComponentDataType) => void; // Function to update data in the parent
}

const MessageTypeAndStatus: React.FC<MessageTypeAndStatusProps> = ({
  setStateData,
  stateData,
}) => {
  const handleSelectionChange = (value: string) => {
    const updatedMeetingTypes = stateData.includes(value)
      ? stateData.filter((item) => item !== value) // Remove if already selected
      : [...stateData, value]; // Add if not selected

    // Update the state in the parent
    setStateData(updatedMeetingTypes);
  };
  return (
    <TitleContainer
      title={messageTypeList.translatedTitle}
      isCollapasable
      childrenSx={{ flexDirection: "row", flexWrap: "wrap" }}
    >
      {messageTypeList.option.map((item, index) => (
        <MultiSelectRadioBtn
          sx={{ width: "45%" }}
          key={item._id}
          isChecked={stateData.includes(item.value)}
          label={item.translatedTitle}
          onClick={() => handleSelectionChange(item.value)}
        />
      ))}
    </TitleContainer>
  );
};

export default MessageTypeAndStatus;
