import { Stack } from "@mui/material";
import React from "react";
import { EventIconButton } from "../../../../../../common";
import {
  CheckboxFilledIcon,
  MultiCircleIcon,
  XCircleIcon,
} from "../../../../../../../images";

interface ButtonGroupTwoViewProps {}

const ButtonGroupTwoView: React.FC<ButtonGroupTwoViewProps> = ({}) => {
  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
      <EventIconButton
        buttonTitle="Accept"
        icon={CheckboxFilledIcon}
        iconColor="#21B481"
      />
      <EventIconButton
        buttonTitle="Reject"
        icon={XCircleIcon}
        iconColor="#FF0000"
      />
      <EventIconButton
        buttonTitle="Maybe"
        icon={MultiCircleIcon}
        iconColor="#FFB100"
      />
    </Stack>
  );
};

export default ButtonGroupTwoView;
