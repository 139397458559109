import React from "react";
import { BadgeDropdown } from "../../common";
import {
  ReimburseBadgeExpenseType,
  ReimburseMenuExpenseList,
} from "./ReimburseMenuExpenseType";

interface ReimburseMenuExpenseProps {
  initialSelected: ReimburseBadgeExpenseType;
  onChange: (value: string) => void;
}

const ReimburseMenuExpense: React.FC<ReimburseMenuExpenseProps> = ({
  initialSelected,
  onChange,
}) => {
  return (
    <BadgeDropdown
      menuLabel="Type"
      initialValue={initialSelected}
      options={ReimburseMenuExpenseList}
      onSelectionChange={(value) => onChange(value)}
      badgeSx={{ width: "80px" }}
      badgeFont="normal normal 400 12px/15px Source Serif Pro"
      hideMenuLabelUnderline
      menuContainerSx={{ paddingLeft: "15px" }}
    />
  );
};

export default ReimburseMenuExpense;
