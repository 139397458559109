import { Button, Stack, Typography, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { adjustHexColor } from "../../../Utils";
import {
  TeamMemberRoleList,
  TeamMemberRoleType,
} from "./AddNowTeamMembersType";
import { CheckedCircleIcon } from "../../../images";

type AssignRoleType = {
  buttonType: "AssignRole";
  initialValue?: TeamMemberRoleType;
  onSelect?: (value: TeamMemberRoleType) => void;
};

type InviteType = {
  buttonType: "invite";
  isInvited?: boolean;
  onBtnClick?: () => void;
};

export type AddNowTeamMembersProps = AssignRoleType | InviteType;

const AddNowTeamMembers: React.FC<AddNowTeamMembersProps> = (props) => {
  const { buttonType } = props;

  const [selectedRole, setSelectedRole] = useState<
    TeamMemberRoleType | "Add Now"
  >(buttonType === "AssignRole" ? props.initialValue || "Add Now" : "Add Now");
  const [isInvited, setIsInvited] = useState<boolean | "Add Now">(
    buttonType === "invite" ? props.isInvited || "Add Now" : "Add Now"
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (buttonType === "AssignRole") {
      setAnchorEl(event.currentTarget);
    }
    if (buttonType === "invite") {
      setIsInvited(true);
      props.onBtnClick && props.onBtnClick();
    }
  };

  const changeStatusHandler = (value: TeamMemberRoleType) => {
    setSelectedRole(value);
    props.buttonType === "AssignRole" &&
      props.onSelect &&
      props.onSelect(value);
    handleClose();
  };

  return (
    <Stack>
      <Button
        variant="text"
        onClick={handleClick}
        sx={{
          textTransform: "none",
          minWidth: 0,
          minHeight: 0,
          height: "25px",
          width: "90px",
          boxSizing: "border-box",
          borderRadius: "5px",
          border: "0.5px solid #E9EDF2",
          bgcolor: "#308893",
          color: "white",
          boxShadow: "5px 5px 5px 0px rgba(0, 0, 0, 0.16)",
          ":hover": {
            bgcolor: adjustHexColor("#308893", 110),
            boxShadow: "5px 5px 5px 0px rgba(0, 0, 0, 0.16)",
          },
        }}
      >
        {props.buttonType === "AssignRole" ? (
          <Typography
            sx={{
              font: "normal normal 400 13px/16px Source Serif Pro",
              color: "white",
            }}
          >
            {selectedRole}
          </Typography>
        ) : props.isInvited ? (
          <CheckedCircleIcon color="white" w={20} />
        ) : (
          <Typography
            sx={{
              font: "normal normal 400 13px/16px Source Serif Pro",
              color: "white",
            }}
          >
            Add Now
          </Typography>
        )}
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            width: "90px",
            borderRadius: "5px",
            backgroundColor: "white",
            border: "0.5px solid #E9EDF2",
            boxShadow: "5px 5px 5px 0px rgba(0, 0, 0, 0.16)",
            padding: "0px",
          },
        }}
      >
        {TeamMemberRoleList.map((item, index) => (
          <MenuItem
            sx={{
              minHeight: 0,
              padding: "0px 0px 0px 10px",
              height: "27px",
              font: "normal normal 900 13px/16px Source Serif Pro",
            }}
            key={index}
            onClick={() => changeStatusHandler(item)}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};

export default AddNowTeamMembers;
