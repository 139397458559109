import React, { useState } from "react";
import {
  Box,
  IconButton,
  InputBase,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  adjustHexColor,
  CalendarTypesEnum,
  getCalenderLogo,
} from "../../../../../Utils";
import {
  AllDirArrowCircleIcon,
  DeadlineIcon,
  DeleteIcon,
  DescriptionIcon,
  EditPencilIcon,
  FlagIcon,
  LastCalendarIcon,
  StatusIcon,
  TeamMemberIcon,
  VideoCallFilledIcon,
} from "../../../../../images";
import styles from "./InCalendarEventHover.module.css";
import {
  CheckboxMedium,
  DatePickerMini,
  DatePickerWithIcon,
  DropdownWithColor,
  DropdownWithImageBadge,
  DUMMY_PRIORITY_OPTIONS,
  EditableTextArea,
} from "../../../../common";
import { CalendarPicker } from "../CalendarPicker";
import {
  CalendarPickerOptionInterface,
  DUMMY_CalendarPickerlist,
} from "../CalendarPicker/CalendarPickerType";
import { AppointmentMenu } from "../AppointmentMenu";
import { ShowAsFilter } from "../ShowAsFilter";
import { useSelector } from "react-redux";
import { CalendarState } from "../../../../../redux";
import { RootState } from "../../../../../redux/store";

interface InCalendarEventHoverProps {
  event__data?: any;
}

const InCalendarEventHover: React.FC<InCalendarEventHoverProps> = ({
  event__data,
}) => {
  console.log(event__data, "event__data");
  const calendarState = useSelector(
    (state: RootState) => state && (state?.calendar as CalendarState)
  );
  const { allCalendarList } = calendarState || {};
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const cardColor = event__data?.cardColor;
  const adjustedHeaderColor = adjustHexColor(event__data?.cardColor, 80);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const calendarPickerlist: CalendarPickerOptionInterface[] =
    allCalendarList && typeof allCalendarList === "object" // Check if it's a valid object
      ? Object?.values(allCalendarList)?.map((calendar: any) => {
          let calendarType = CalendarTypesEnum.BearishCalendar; // Default value

          if (calendar?.isExternal) {
            // If it's an external calendar, check the provider
            if (calendar?.externalCalendar?.provider === "Microsoft") {
              calendarType = CalendarTypesEnum.OutlookCalendar;
            } else if (calendar?.externalCalendar?.provider === "Google") {
              calendarType = CalendarTypesEnum.GoogleCalendar;
            } else if (calendar?.externalCalendar?.provider === "Teams") {
              calendarType = CalendarTypesEnum.TeamsCalendar;
            } else if (calendar?.externalCalendar?.provider === "Jira") {
              calendarType = CalendarTypesEnum.JiraCalendar;
            }
          }

          return {
            id: calendar?._id,
            calendarType, // Dynamically assigned calendarType
            calendarName: calendar?.name,
            _id: calendar?._id,
          };
        })
      : []; // Return an empty array if allCalendarList is not valid

  return (
    <Stack className={styles["in-calendar-event-hover"]}>
      {/* Card Header */}
      <Stack className={styles["in-calendar-event-hover__header"]}>
        <Stack
          className={styles["in-calendar-event-hover__header-stripe"]}
          sx={{ bgcolor: cardColor }}
        />
        <Stack
          className={styles["in-calendar-event-hover__header-content"]}
          sx={{ bgcolor: adjustedHeaderColor }}
        >
          <Stack className={styles["in-calendar-event-hover__header-title"]}>
            <Typography
              className={styles["in-calendar-event-hover__header-text"]}
            >
              {event__data?.title}
            </Typography>
            <Box
              component={"img"}
              src={getCalenderLogo({
                calendarType:
                  event__data?.calendarProvider === "Microsoft"
                    ? CalendarTypesEnum.OutlookCalendar
                    : event__data?.calendarProvider === "Google"
                    ? CalendarTypesEnum.GoogleCalendar
                    : event__data?.calendarProvider === "Teams"
                    ? CalendarTypesEnum.TeamsCalendar
                    : event__data?.calendarProvider === "Jira"
                    ? CalendarTypesEnum.JiraCalendar
                    : CalendarTypesEnum.BearishCalendar, // Fallback/default value
              })}
              alt="calendar logo"
              className={styles["in-calendar-event-hover__header-logo"]}
            />
          </Stack>
          <Stack className={styles["in-calendar-event-hover__header-actions"]}>
            <Tooltip title="Set Priority" placement="top" arrow>
              <IconButton
                className={styles["in-calendar-event-hover__icon-button"]}
                onClick={handlePopoverOpen} // Open popover on click
              >
                <FlagIcon w={20} color="#E9EDF2" />
              </IconButton>
            </Tooltip>

            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                style: { boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)" },
              }}
            >
              <DropdownWithColor
                options={DUMMY_PRIORITY_OPTIONS}
                onChange={(newValue) => null}
                onSelect={(value) => null}
                placeholder="Choose priority"
                disableAddingNewOpt
                disableColorEditing
              />
            </Popover>

            <Tooltip title="Edit" placement="top" arrow>
              <IconButton
                className={styles["in-calendar-event-hover__icon-button"]}
              >
                <EditPencilIcon w={20} color="#fff" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete" placement="top" arrow>
              <IconButton
                className={styles["in-calendar-event-hover__icon-button"]}
              >
                <DeleteIcon w={20} color="#fff" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
      {/* End of Card Header */}

      {/* Scrollable Container */}
      <Stack className={styles["in-calendar-event-hover__scrollable-body"]}>
        <Stack className={styles["in-calendar-event-hover__input-group"]}>
          <DeadlineIcon w={20} />
          <InputBase
            className={styles["in-calendar-event-hover__input"]}
            placeholder="Event title"
            readOnly
            value={event__data?.title || "Event title"}
          />
        </Stack>

        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
          <CheckboxMedium boxSize={18} checked={true} readonlyComponent />
          <Typography
            sx={{
              font: "normal normal 900 15px/19px Source Serif Pro",
            }}
          >
            Event
          </Typography>

          <AllDirArrowCircleIcon w={15} direction="right" />
          <AppointmentMenu
            onSelect={(value) => null}
            readonlyComponents
            initialValue="Reminders"
          />
        </Stack>

        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            font: "normal normal 300 15px/19px Source Serif Pro",
          }}
        >
          <Stack
            sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}
          >
            <LastCalendarIcon w={20} />
            <DatePickerWithIcon
              readonlyComponent
              hideIcon
              // initialDate={event__data?.st}
              dateFormat="MMM D, YYYY"
              sx={{ font: "inherit", width: "85px" }}
            />
          </Stack>
          <Stack
            sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}
          >
            <DatePickerMini
              readonlyComponent
              hideIcon
              sx={{
                p: 0,
                border: "none",
                width: "65px",
                height: "20px",
                font: "inherit",
              }}
              // initialTime={event__data?.st}
            />
            -
            <DatePickerMini
              readonlyComponent
              hideIcon
              sx={{
                p: 0,
                border: "none",
                width: "65px",
                height: "20px",
                font: "inherit",
              }}
              // initialTime={event__data?.et}
            />
          </Stack>
          <Stack sx={{ cursor: "pointer" }}>PST</Stack>
        </Stack>

        <Stack sx={{ flexDirection: "row", alignItems: "start", gap: "10px" }}>
          <span style={{ marginTop: "10px" }}>
            <TeamMemberIcon w={20} />
          </span>
          <DropdownWithImageBadge
            initialData={event__data?.guests || []}
            onChangeSelection={(value) => console.log(value)}
            readonlyComponent
            showMaxSelected={3}
          />
        </Stack>

        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
          <VideoCallFilledIcon w={20} />
          <Stack
            sx={{
              flex: 1,
              height: "30px",
              border: "0.5px solid #E9EDF2",
              borderRadius: "5px", font: "normal normal 300 15px/19px Source Serif Pro",
            }}
          >
            {event__data?.videoCall?.meetingId}
          </Stack>
        </Stack>

        <Stack sx={{ flexDirection: "row", gap: "10px", alignItems: "center" }}>
          <StatusIcon w={20} />
          <CheckboxMedium boxSize={18} checked={true} readonlyComponent />
          <Typography
            sx={{
              font: "normal normal 300 15px/19px Source Serif Pro",
              marginRight: "25px",
            }}
          >
            Show as
          </Typography>

          <AllDirArrowCircleIcon w={15} direction="right" />
          <ShowAsFilter
            onSelect={(value) => null}
            initialValue={event__data?.showAs || "Busy"}
            readonlyComponent
          />
        </Stack>

        <CalendarPicker
          initialValue={{
            id: 10,
            calendarName:
              event__data?.calendarProvider === "Microsoft"
                ? "Microsoft Calendar"
                : event__data?.calendarProvider === "Google"
                ? "Google Calendar"
                : event__data?.calendarProvider === "Teams"
                ? "Teams Calendar"
                : event__data?.calendarProvider === "Jira"
                ? "Jira Calendar"
                : "Bearish OS Calendar",
            calendarType:
              event__data?.calendarProvider === "Microsoft"
                ? CalendarTypesEnum.OutlookCalendar
                : event__data?.calendarProvider === "Google"
                ? CalendarTypesEnum.GoogleCalendar
                : event__data?.calendarProvider === "Teams"
                ? CalendarTypesEnum.TeamsCalendar
                : event__data?.calendarProvider === "Jira"
                ? CalendarTypesEnum.JiraCalendar
                : CalendarTypesEnum.BearishCalendar, // Fallback/default value,
          }}
          readonlyComponent
          options={calendarPickerlist || []}
          onSelect={(value) => null}
          width="300px"
        />
        <Stack sx={{ flexDirection: "row", alignItems: "start", gap: "10px" }}>
          <DescriptionIcon w={20} />
          <EditableTextArea
            placeholder="Add description"
            readOnly
            value={event__data?.description}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default InCalendarEventHover;
