import React, { useEffect, useState } from "react";
import { IconButton, InputBase, Popover, Stack } from "@mui/material";
import { BearishOSIcon, FiltersIcon } from "../../../../../images";
import styles from "./CommandControlSearchContainer.module.css";
import {
  dummy_CommandControlSearchData,
  filterValueType,
  KeyWordsMatchStateEnum,
  sourcesOptionsList,
} from "./CommandControlSearchContainerType";
import SearchResultCard from "./view/SearchResultCard/SearchResultCard";
import { GroupContainer, SupportButton } from "./view";
import { CommandControlFilterMenu } from "../CommandControlFilterMenu";

interface CommandControlSearchContainerProps {}

const CommandControlSearchContainer: React.FC<
  CommandControlSearchContainerProps
> = ({}) => {
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [filterFields, setFilterFields] = useState<filterValueType>({
    centersValue: [],
    conditionalCentersValue: [],
    sources: [],
    dateAndTime: {
      from: { start: "", end: "" },
      on: { start: "" },
      at: { start: "" },
    },
    primaryKeywords: {
      bodyKeywords: [] as string[],
      matchType: "" as KeyWordsMatchStateEnum,
    },
    additionalFields: {
      meetingType: [] as string[], // define here data structure as need
      duration: [] as string[],
      recordingStatus: [] as string[],
      emailType: [] as string[],
      messageSource: {
        from: [],
        hosts: [],
      },
      emailSource: {
        from: [],
        hosts: [],
        bodyKeywords: [] as string[],
        matchType: "" as KeyWordsMatchStateEnum,
      },
      emailBasicFilters: [] as string[],
      eventType: [] as string[],
      eventStatus: [] as string[],
      bookingLink: [] as string[],
      messageTypeAndStatus: [] as string[],
      attachements: [] as string[],
      contactConnections: {},
      interactionType: [] as string[],
      groupOrDealName: {
        bodyKeywords: [] as string[],
        matchType: "" as KeyWordsMatchStateEnum,
      },
      employeeType: [] as string[],
      employeeDepartment: {
        bodyKeywords: [] as string[],
        matchType: "" as KeyWordsMatchStateEnum,
      },
      employeeStatus: [] as string[],
      workspaceType: [] as string[],
      workspaceOwnership: [] as string[],
      cloudStorageOwnership: [] as string[],
      cloudStorageType: [] as string[],
      cloudStorageSize: [] as string[],
    },
  });

  const [recentSearch, setRecentSearch] = useState<any[]>(
    dummy_CommandControlSearchData.slice(0, 5)
  );
  const [searchResultData, setSearchResultData] = useState<any[]>(
    dummy_CommandControlSearchData
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  const groupByResultType = (data: any[]): any[] => {
    // Initialize an object to hold the grouped data
    const grouped: { [key: string]: any[] } = {};

    // Iterate over the data and group by resultType
    data.forEach((item) => {
      if (!grouped[item.resultType]) {
        grouped[item.resultType] = [];
      }
      grouped[item.resultType].push(item);
    });

    // Convert the grouped object to an array of objects with resultType and resultData
    return Object.keys(grouped).map((resultType) => ({
      resultType,
      resultData: grouped[resultType],
    }));
  };

  const resultData: { resultType: string; resultData: any[] }[] =
    groupByResultType(searchResultData);

    
  // only for test , remove this use - effect hook
  useEffect(() => {
    console.log("this is filter fields", filterFields);
  }, [filterFields]);

  return (
    <Stack className={styles.CommandControlSearchContainer}>
      {/* ---- header or search area ---- */}
      <Stack className={styles.CommandControlSearchContainer__header}>
        <Stack className={styles.CommandControlSearchContainer__headerBox}>
          <BearishOSIcon w={30} />
          <InputBase
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            sx={{
              font: "normal normal 300 16px/20px Source Serif Pro",
              width: "100%",
            }}
            placeholder="Search Bearish OS, @BEBA to ask a question, or @create to quick add...."
          />
        </Stack>

        <IconButton onClick={handleFilterClick}>
          <FiltersIcon w={20} />
        </IconButton>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            sx: {
              boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
              p: 0,
            },
          }}
        >
          <CommandControlFilterMenu
            sourcesoptionsList={sourcesOptionsList}
            primaryValue={filterFields}
            setPrimaryValue={setFilterFields}
          />
        </Popover>
      </Stack>

      {/* -------------- search results ----------- */}
      <Stack
        sx={{ padding: "0px 10px", overflow: "auto", scrollbarWidth: "none" }}
      >
        {!searchValue ? (
          <Stack sx={{ gap: "10px" }}>
            <GroupContainer title="Recent">
              {recentSearch.map((item, index) => (
                <SearchResultCard key={index} data={item} />
              ))}
            </GroupContainer>
            <SupportButton />
          </Stack>
        ) : (
          <Stack sx={{ paddingBottom: "15px" }}>
            {resultData.map((item, index) => (
              <GroupContainer key={index} title={item.resultType}>
                {item.resultData.map((searchData, i) => (
                  <SearchResultCard key={i} data={searchData} />
                ))}
              </GroupContainer>
            ))}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default CommandControlSearchContainer;
