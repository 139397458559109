import React from "react";
import { AttendanceStatusType, CrmStatusBadge } from "../../../CrmStatusBadge";
import { Stack, Typography } from "@mui/material";

interface AttendanceBadgeProps {
  AttendanceType: AttendanceStatusType;
}

const AttendanceBadge: React.FC<AttendanceBadgeProps> = ({
  AttendanceType,
}) => {
  return (
    <Stack sx={{ gap: "5px" }}>
      <Typography sx={{ font: "normal normal 900 13px/16px Source Serif Pro" }}>
        Type:
      </Typography>
      <CrmStatusBadge variant={AttendanceType} />
    </Stack>
  );
};

export default AttendanceBadge;
