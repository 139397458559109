import { Stack, Typography } from "@mui/material";
import React from "react";
import { timeCardTableRowsWidth } from "../../../TimeCardTable";

interface NoWorkCardProps {
  label: String;
}

const NoWorkCard: React.FC<NoWorkCardProps> = ({ label }) => {
  const boxWidth = 100;
  return (
    <Stack
      sx={{
        position: "sticky",
        left: timeCardTableRowsWidth.totalColWidth / 2 - boxWidth / 2,
        alignItems: "center",
        width: boxWidth,
      }}
    >
      <Typography sx={{ font: "normal normal 900 14px/18px Source Serif Pro" }}>
        {label}
      </Typography>
    </Stack>
  );
};

export default NoWorkCard;
