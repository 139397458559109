import { PayloadAction } from "@reduxjs/toolkit";
import { VideoCenterState } from "../VideoCenterTypes";

export const createMeetingFailure = (state: VideoCenterState, action: PayloadAction<string>) => {
    state.errors = action.payload;
    state.loading = false;
};
export const getUserMeetingsFailure = (state: VideoCenterState, action: PayloadAction<string>) => {
    state.errors = action.payload;
    state.loading = false;
};
export const getPastMeetingsFailure = (state: VideoCenterState, action: PayloadAction<string>) => {
    state.errors = action.payload;
    state.loading = false;
};
export const getSingleMeetingFailure = (state: VideoCenterState, action: PayloadAction<any>) => {
    state.errors = action.payload;
    state.loading = false;
};
export const getOnGoingMeetingAgendaFailure = (
  state: VideoCenterState,
  action: PayloadAction<any>
) => {
  state.errors = action.payload;
  state.loading = false;
};
export const getSelectedMeetingAgendaFailure = (
  state: VideoCenterState,
  action: PayloadAction<any>
) => {
  state.errors = action.payload;
  state.loading = false;
};
export const updateAgendaStatusFailure = (state: VideoCenterState, action: PayloadAction<any>) => {
    state.errors = action.payload;
    state.loading = false;
};
export const deleteAgendaFailure = (state: VideoCenterState, action: PayloadAction<any>) => {
    state.errors = action.payload;
    state.loading = false;
};
export const getAllMeetingNoteFailure = (state: VideoCenterState, action: PayloadAction<any>) => {
    state.errors = action.payload;
    state.loading = false;
};
export const createMeetingNoteFailure = (state: VideoCenterState, action: PayloadAction<any>) => {
    state.errors = action.payload;
    state.loading = false;
};
export const updateMeetingNoteFailure = (state: VideoCenterState, action: PayloadAction<any>) => {
    state.errors = action.payload;
    state.loading = false;
};
export const deleteMeetingNotesFailure = (state: VideoCenterState, action: PayloadAction<any>) => {
    state.errors = action.payload;
    state.loading = false;
};
export const getCallActivityDetailsFailure = (state: VideoCenterState, action: PayloadAction<any>) => {
    state.errors = action.payload;
    state.loading = false;
};
export const getPastMeetingDetailsFailure = (state: VideoCenterState, action: PayloadAction<any>) => {
    state.errors = action.payload;
    state.loading = false;
};
export const addWorkspacesAndProjectsToMeetingFailure = (state: VideoCenterState, action: PayloadAction<any>) => {
    state.errors = action.payload;
    state.loading = false;
};

export const updateInviteStatus__Failure = (state: VideoCenterState, action: PayloadAction<any>) => {
    state.errors = action.payload;
    state.loading = false;
}

export const updateHomeScreenNotifications__Failure = (state: VideoCenterState, action: PayloadAction<any>) => {
    state.errors = action.payload;
}

export const getSnoozedHomeScreenNotifications__Failure = (state: VideoCenterState, action: PayloadAction<any>) => {
    state.errors = action.payload;
}

export const getNewHomeScreenNotifications__Failure = (state: VideoCenterState, action: PayloadAction<any>) => {
    state.errors = action.payload;
}

export const getDoneHomeScreenNotifications__Failure = (state: VideoCenterState, action: PayloadAction<any>) => {
    state.errors = action.payload;
}