import {
  BoardIcon,
  ChecklistIcon,
  DecksIcon,
  DocumentIcon,
  GridsIcon,
  ListsIcon,
  WhiteboardIcon,
  WikiIcon,
} from "../../../../../images";

/** 
 * Enum representing different types of workspace options.
 * The values in this enum are used as identifiers to categorize various workspace options.
 * This ensures that the workspace options are strongly typed and easy to reference.
 */
export enum AddToWorkspaceOptionsEnum {
  Board = "Board",
  Checklist = "Checklist",
  List = "List",
  Grid = "Grid",
  Document = "Document",
  Wiki = "Wiki",
  Whiteboard = "Whiteboard",
  Decks = "Decks",
}

/**
 * Interface representing a workspace option object.
 * This ensures that each workspace option has a consistent structure with an id, icon, value, and optionally a `full__developed` status.
 */
export interface AddToWorkspaceOptionsInterface {
  id: number;                          // Unique identifier for the workspace option
  icon: React.ElementType;              // React component to render as the icon for the workspace option
  value: AddToWorkspaceOptionsEnum;     // The value corresponds to the Enum value representing the workspace type
  full__developed?: boolean;            // Flag indicating whether the workspace option is fully developed; defaults to true
}

/**
 * Helper function to create a workspace option.
 * This function encapsulates the logic of creating a workspace option object, ensuring that each workspace option is constructed consistently.
 * 
 * @param id - The unique identifier for the workspace option
 * @param icon - The React component representing the icon of the workspace option
 * @param value - The workspace option value from the enum
 * @param full__developed - Optional flag indicating if the workspace option is fully developed (defaults to true)
 * @returns {AddToWorkspaceOptionsInterface} - The workspace option object with all relevant properties
 * 
 * This function centralizes the creation logic and allows easy modification of workspace option creation logic in the future.
 */
const createWorkspaceOption = (
  id: number,
  icon: React.ElementType,
  value: AddToWorkspaceOptionsEnum,
  full__developed: boolean = true // Default to true for fully developed options
): AddToWorkspaceOptionsInterface => ({
  id,
  icon,
  value,
  full__developed,
});

/**
 * List of workspace options available for addition to a workspace.
 * This array is generated dynamically using the `createWorkspaceOption` helper function, ensuring that all workspace options are created consistently.
 * 
 * Each option corresponds to a specific workspace feature (e.g., Board, Checklist, Document, etc.) and can have a flag (`full__developed`) indicating its development status.
 * Some options like `Grid` and `Decks` are marked as not fully developed, which could be useful in scenarios where you want to show/hide incomplete features.
 * 
 * This dynamic approach provides flexibility and scalability for adding or modifying workspace options in the future.
 */
export const AddToWorkspaceOptionsList: AddToWorkspaceOptionsInterface[] = [
  createWorkspaceOption(1, BoardIcon, AddToWorkspaceOptionsEnum.Board),
  createWorkspaceOption(3, ChecklistIcon, AddToWorkspaceOptionsEnum.Checklist),
  createWorkspaceOption(4, ListsIcon, AddToWorkspaceOptionsEnum.List),
  createWorkspaceOption(5, GridsIcon, AddToWorkspaceOptionsEnum.Grid, false), // Grid is not fully developed
  createWorkspaceOption(6, DocumentIcon, AddToWorkspaceOptionsEnum.Document),
  createWorkspaceOption(7, WikiIcon, AddToWorkspaceOptionsEnum.Wiki),
  createWorkspaceOption(8, WhiteboardIcon, AddToWorkspaceOptionsEnum.Whiteboard),
  createWorkspaceOption(9, DecksIcon, AddToWorkspaceOptionsEnum.Decks, false), // Decks is not fully developed
];
