import React, { useState } from "react";
import { Button, Typography, Stack, Popover } from "@mui/material";
import { ArrowAllIcon } from "../../../../../images";
import { CheckboxMedium } from "../../../../common";
import { TextLabelDropdownOptionsType } from "./TextLabelDropdownType";

interface TextLabelDropdownProps {
  menuLabel: string;
  initialValue?: string;
  onSelect: (value: string) => void;
  options: TextLabelDropdownOptionsType[];
  menuWidth?: string;
}

const TextLabelDropdown: React.FC<TextLabelDropdownProps> = ({
  menuLabel,
  options,
  initialValue,
  onSelect,
  menuWidth = "150px",
}) => {
  const [selectedData, setSelectedData] = useState<string>(initialValue || "");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeHandler = (value: string) => {
    setSelectedData(value);
    onSelect(value);
    handleClose();
  };

  return (
    <div>
      <Button
        variant="text"
        sx={{
          textTransform: "none",
          width: "fit-content",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flexShrink: 0,
          p: "2px 3px",
          gap: "5px",
          color: "black",
          minWidth: "30px",
        }}
        disableRipple
        onClick={handleClick}
      >
        <Typography
          sx={{
            width: "fit-content",
            font: "normal normal 400 13px/16px Source Serif Pro",
          }}
        >
          {options.find((item) => item.value === selectedData)?.label}
        </Typography>
        <ArrowAllIcon w={18} open={open} />
      </Button>

      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            padding: 0,
            marginTop: "15px",
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <Stack
          sx={{
            width: menuWidth,
            boxSizing: "border-box",
            borderRadius: "5px",
            bgcolor: "#fff",
            padding: "15px 10px",
            gap: "7px",
            maxHeight: "500px",
            border: "1px solid #E9EDF2",
          }}
        >
          <Typography
            sx={{
              paddingBottom: "9px",
              font: "normal normal 300 15px/19px Source Serif Pro",
              borderBottom: "1px solid #E9EDF2",
              whiteSpace: "nowrap",
            }}
          >
            {menuLabel}
          </Typography>
          <Stack sx={{ gap: "10px", overflow: "auto", scrollbarWidth: "none" }}>
            {options.map((item) => (
              <Stack
                onClick={() => changeHandler(item.value)}
                key={item._id}
                sx={{
                  flexDirection: "row",
                  alignItems: "start",
                  gap: "10px",
                  cursor: "pointer",
                }}
              >
                <CheckboxMedium
                  checked={selectedData === item.value}
                  boxSize={17}
                />
                <Typography
                  sx={{
                    font: "normal normal 300 15px/19px Source Serif Pro",
                  }}
                >
                  {item.label}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Popover>
    </div>
  );
};

export default TextLabelDropdown;
