import { Stack, Typography } from "@mui/material";
import React from "react";
import { EstimatedARRIcon, TagIcon } from "../../../../../../../images";
import { ReimburseMenuExpense } from "../../../ReimburseMenuExpense";

interface TypeViewProps {}

const TypeView: React.FC<TypeViewProps> = ({}) => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
        <EstimatedARRIcon w={15} />
        <Typography
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
        >
          Type
        </Typography>
      </Stack>
      <ReimburseMenuExpense
        initialSelected={"reimburse"}
        onChange={() => null}
      />
    </Stack>
  );
};

export default TypeView;
