import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";
import {
  box__loginUrl__handle,
  CloudStorageState,
  setSelectedDrive,
} from "../../../../../../redux";
import { Stack, Typography } from "@mui/material";
import { getDriveIcon } from "../../../../../../Utils";
import { useCustomSnackbar } from "../../../../EnqueueSnackbar";
import { ThreeDotMenuSmall } from "../../../../../pages/CloudStorage/components";

type Props = {};

const AvailableConnection = (props: Props) => {
  const dispatch = useDispatch();
  const enqueueSnackbar_v1 = useCustomSnackbar();
  const cloudStorageState = useSelector(
    (state: RootState) => state && (state?.cloudStorage as CloudStorageState)
  );
  const { selectedDrive, availableDrives } = cloudStorageState || {};

  const [isDebouncing, setIsDebouncing] = useState(false);

  const handleDriveClick = useCallback(
    (driveType: string) => {
      if (isDebouncing) return; // Prevent action if currently debouncing

      const clickedDrive = availableDrives?.find(
        (drive) => drive.type === driveType
      );

      if (!clickedDrive) {
        console.log(`Drive of type ${driveType} not found.`);
        return;
      }

      if (!clickedDrive.connected) {
        console.log(`${clickedDrive.name} is not connected.`);
        runAlternativeFunction(clickedDrive);
        return;
      }

      setIsDebouncing(true);

      if (selectedDrive === driveType) {
        console.log("Drive already selected: " + selectedDrive);
        return;
      }

      dispatch(setSelectedDrive(driveType));

      enqueueSnackbar_v1("success", `Drive Changed to ${driveType}`, {
        position: { vertical: "top", horizontal: "center" },
        autoHideDuration: 5000, // Optional custom auto-hide duration in milliseconds
      });

      // Set timeout to reset debouncing state
      setTimeout(() => {
        setIsDebouncing(false);
      }, 1000); // Adjust debounce duration as needed
    },
    [dispatch, enqueueSnackbar_v1, isDebouncing, availableDrives, selectedDrive]
  );

  // Example alternative function to handle unconnected drives
  const runAlternativeFunction = async (drive: (typeof availableDrives)[0]) => {
    console.log(
      `Alternative function executed for ${drive.name}, which is not connected.`
    );
    if (drive.name === "Box") {
      try {
        // Dispatch the action to get the Box login URL
        const action = box__loginUrl__handle();
        const r: any = await dispatch(action);

        // Automatically redirect the user to the Box login URL
        if (r?.loginUrl) {
          window.location.href = r.loginUrl; // Redirect to Box login URL
        } else {
          console.log("Box login URL not found");
        }
      } catch (e) {
        console.log(e);
      }
    }

    // Add your custom logic here
  };

  return (
    <div>
      <Stack
        mt={1}
        direction={"column"}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
        gap={1}
      >
        {availableDrives?.map((drive) => (
          <Stack
            key={drive.id}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              cursor: "pointer", // Show pointer cursor on hover
              padding: 1,
              width: "100%",
              borderRadius: 1,
              backgroundColor:
                selectedDrive === drive.type ? "#f0f0f0" : "transparent", // Highlight selected drive
              transition: "background-color 0.3s",
              "&:hover": {
                backgroundColor: "#dcffde", // Light hover effect
              },
            }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                onClick={() => handleDriveClick(drive.type)} // Handle click to set selected drive
                justifyContent={"flex-start"}
                gap={2}
              >
                {getDriveIcon(drive.type, 20)}
                <Typography
                  sx={{
                    fontFamily: "Source Serif Pro",
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    lineHeight: "17.54px",
                  }}
                >
                  {drive.name}
                </Typography>
              </Stack>
              <ThreeDotMenuSmall dy={drive.type} />
            </Stack>
          </Stack>
        ))}
      </Stack>
    </div>
  );
};

export default AvailableConnection;
