import { Stack, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../Utils";
import {
  AttendanceStatusColorLiist,
  AttendanceStatusType,
} from "./CrmStatusBadgeType";

export interface CrmStatusBadgeProps {
  variant: AttendanceStatusType | "active" | "inActive";
}

const CrmStatusBadge: React.FC<CrmStatusBadgeProps> = ({ variant }) => {
  const getBadgeData = (): {
    fontColor: string;
    badgeColor: string;
    clrOpacity: number;
    title: string;
  } => {
    switch (variant) {
      case "onTime":
        return {
          badgeColor: AttendanceStatusColorLiist.onTime,
          clrOpacity: 35,
          fontColor: "#000",
          title: "On Time",
        };
      case "late":
        return {
          badgeColor: AttendanceStatusColorLiist.late,
          clrOpacity: 35,
          fontColor: "#000",
          title: "Late",
        };
      case "sick":
        return {
          badgeColor: AttendanceStatusColorLiist.sick,
          clrOpacity: 35,
          fontColor: "#000",
          title: "Sick",
        };
      case "holiday":
        return {
          badgeColor: AttendanceStatusColorLiist.holiday,
          clrOpacity: 35,
          fontColor: "#000",
          title: "Holiday",
        };
      case "pto":
        return {
          badgeColor: AttendanceStatusColorLiist.pto,
          clrOpacity: 35,
          fontColor: "#000",
          title: "PTO",
        };
      case "absent":
        return {
          badgeColor: AttendanceStatusColorLiist.absent,
          clrOpacity: 35,
          fontColor: "#000",
          title: "Absent",
        };
      case "dayoff":
        return {
          badgeColor: AttendanceStatusColorLiist.dayoff,
          clrOpacity: 35,
          fontColor: "#000",
          title: "Day off",
        };
      case "active":
        return {
          badgeColor: "#21B481",
          clrOpacity: 35,
          fontColor: "#000",
          title: "Active",
        };
      case "inActive":
        return {
          badgeColor: "#E9EDF2",
          clrOpacity: 35,
          fontColor: "#000",
          title: "Inactive",
        };
      default:
        return {
          badgeColor: "#E9EDF2",
          clrOpacity: 35,
          fontColor: "#000",
          title: variant,
        };
    }
  };

  return (
    <Stack
      sx={{
        flexDirection: "row",
        borderRadius: "5px",
        bgcolor: "white",
        flexShrink: 0,
        boxSizing: "border-box",
        width: "fit-content",
      }}
    >
      <Typography
        sx={{
          font: "normal normal 400 12px/16px Source Serif Pro",
          padding: "2px 4px",
          borderRadius: "5px",
          boxSizing: "border-box",
          color: getBadgeData().fontColor,
          bgcolor: adjustHexColor(
            getBadgeData().badgeColor,
            getBadgeData().clrOpacity
          ),
        }}
      >
        {getBadgeData().title}
      </Typography>
    </Stack>
  );
};

export default CrmStatusBadge;
