import { useRef, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";

const ScrollingTextTypography = ({ text }: { text: string }) => {
  const textRef = useRef<HTMLDivElement | null>(null);
  const [animationDuration, setAnimationDuration] = useState<string>("0s");

  useEffect(() => {
    if (textRef.current) {
      const textWidth = textRef.current.scrollWidth; // Full width of the text
      const containerWidth = textRef.current.offsetWidth; // Visible width of the container

      if (textWidth > containerWidth) {
        const speed = 30; // Pixels per second (constant speed)
        const duration = containerWidth / speed; // Calculate duration for the full text
        setAnimationDuration(`${duration}s`);
      } else {
        setAnimationDuration("0s"); // No animation for non-scrollable text
      }
    }
  }, [text]);

  return (
    <Stack sx={{ overflow: "hidden" }}>
      <Typography
        ref={textRef}
        sx={{
          font: "normal normal 300 13px/16px Source Serif Pro",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          position: "relative",
          display: "block",
          "&:hover": {
            overflow: "visible",
            whiteSpace: "nowrap",
            animation: `scrollText ${animationDuration} linear infinite`,
          },
          "@keyframes scrollText": {
            "0%": { transform: "translateX(0%)" }, // Start position
            "5%": { transform: "translateX(0%)" }, // 1s delay at the start
            "95%": { transform: "translateX(-100%)" }, // Scroll ends
            "100%": { transform: "translateX(-100%)" }, // 2s delay at the end
          },
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
};

export default ScrollingTextTypography;
