import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { XCircleIcon } from "../../../images";

const Root = styled("div")(
  ({ theme }) => `
  width: 100%;
  box-sizing: border-box;
  color: ${
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)"
  };
  font-size: 14px;
`
);

const InputWrapper = styled("div")(
  () => `
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  & input {
    color: #000;
    height: 30px;
    box-sizing: border-box;
    padding: 2px 4px 0px 4px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
    font: normal normal 300 13px/13px Source Serif Pro;

    &::placeholder {
      font: normal normal 300 10px/10px Source Serif Pro;
      color: #888; /* Change the color */
    }
  }
`
);

const StyledTag = styled("div")(
  () => `
  display: flex;
  align-items: center;
  margin: 2px;
  height: 30px;
  border: 1px solid #E9EDF2;
  border-radius: 100px;
  box-sizing: content-box;
  padding: 0px 8px;
  gap: 5px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color:  #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font: normal normal 300 13px/16px Source Serif Pro;
  }

  & svg {
    cursor: pointer;
  }
`
);

interface ChipInputFieldProps {
  onChange: (list: string[]) => void;
  initialChips?: string[];
  updateFilterData?: (key: "name" | "from" | "subjectKeywords" | "subjectMatchType" | "bodyKeywords" | "bodyMatchType" | "hosts" | "basicFilters" | "priorityLevel" | "advancedActions", value: any) => void;

}

const ChipInputField: React.FC<ChipInputFieldProps> = ({ onChange, initialChips = [] }) => {
  const [chips, setChips] = useState<string[]>(initialChips);
  const [inputValue, setInputValue] = useState("");

  const handleChange = (e: string) => {
    setInputValue(e);
  }

  const handleAddChip = (event: React.KeyboardEvent<HTMLInputElement>) => { 
    if (event.key === "Enter" && inputValue.trim()) {
      if (!chips.includes(inputValue.trim())) {
        setChips([...chips, inputValue.trim()]);
        // onChange([...chips, inputValue.trim()]);
      }
      setInputValue(""); 
    } else if (event.key === "Backspace" && !inputValue) {
      setChips(chips.slice(0, -1));
    }
  };

  const handleDeleteChip = (chipToDelete: string) => () => {
    const delChip = (chips: string[]) => chips.filter((chip: string) => chip !== chipToDelete);
    const newChips = delChip(chips);
    setChips(newChips);
    // onChange(newChips);
  };

  useEffect(() => {
    if(initialChips != chips){
      onChange(chips);
    }
  }, [chips]);

  // useEffect(() => {
  //   if (initialChips) {
  //     setChips(initialChips);
  //   }
  // }, [initialChips]);

  return (
    <Root>
      <InputWrapper>
        {chips.map((chip, index) => (
          <StyledTag key={index}>
            <span>{chip}</span>
            <span style={{ display: "flex" }} onClick={handleDeleteChip(chip)}>
              <XCircleIcon w={12} />
            </span>
          </StyledTag>
        ))}
        <input
          value={inputValue}
          onChange={(e) => handleChange(e.target.value)}
          onKeyDown={handleAddChip}
          placeholder="Type something and press Enter"
        />
      </InputWrapper>
    </Root>
  );
};

export default ChipInputField;
