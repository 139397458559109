import {Box, Drawer, IconButton, Popover, Stack, Typography} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import CompanyIconAndName from "../../CompanyIconAndName";
import { MainActionButton } from "../../../Buttons";
import styles from "../../DrawerNavbar.module.css";
import { ButtonType, MainActionColor } from "../../../Buttons/AllButtonProps";
import { GearIcon } from "../../../../../images";
import DrawerSectionText from "../../DrawerSectionText";
import { SidebarPinnedChatContainer } from "../../../../pages/Message/components";
import { MenuOptions, SmartFilter } from "./Components";
import { AccountsPinnedCard } from "../../../../pages/Email/components/AccountsChip";
import {
  MailSettings,
  SmartFiltersDropdown,
} from "../../../../pages/Email/components";
import { fetchEmailsFromBackend } from "../../../../../redux/Slice/email/MsalInitialize";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseURL, getCurrentIp } from "../../../../../redux/middleware/apiMiddleware";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../../redux/rootReducer";
import { getAccessToken, getToken } from "../../../LocalStorage";
import { getConnectedAccounts } from "../../../../../Utils";
import {
  AccountCenter,
  AuthState,
  getMailSettings__api,
  recallAPI__MAIL,
} from "../../../../../redux";
import CommandControlSearchContainer
    from "../../../../pages/Dashboard/components/CommandControlSearchContainer/CommandControlSearchContainer";

type EmailDrawerProps = {
  drawerOpen: boolean;
  drawerCloser: () => void;
};

interface FilterItem {
  id: number;
  _id: string;
  isActive: boolean;
  name: string;
  count: number;
  icon: React.ReactNode;
}

interface ReduxAccountData {
  displayName: string;
  icon: string;
  accountType: string;
}

interface TransformedAccountData {
  userNameOrEmail: string;
  userIconSrc: string;
  topIcon: string;
}

const DrawerContainer = styled("div")({
  position: "fixed",
  top: 0,
  height: "100vh",
  zIndex: 1100,
});

const EmailDrawer: React.FC<EmailDrawerProps> = React.memo(
  ({ drawerOpen, drawerCloser }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
      null
    );
    const [popoverType, setPopoverType] = React.useState<
      "smartFilter" | "mailSettings" | null
    >(null);
    const [dataList, setDataList] = useState<FilterItem[]>([]);
      const [showSearchContainer, setShowSearchContainer] = useState(false);
    const accessToken = getAccessToken();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getSettings = async () => {
      try {
        const action = getMailSettings__api();

        await dispatch(action);
      } catch (err) {
        console.error("Error fetching settings:", err);
      }
    };

    useEffect(() => {
      getSettings();
    }, []);

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const handleClick = (
      event: React.MouseEvent<HTMLButtonElement>,
      type: "smartFilter" | "mailSettings"
    ) => {
      setAnchorEl(event.currentTarget);
      setPopoverType(type);
    };

    const handleClose = () => {
      setAnchorEl(null);
      setPopoverType(null);
    };

    const handleFilterEmails = async (value: string) => {
      navigate("/Email/");
      try {
        const res = await fetchEmailsFromBackend(accessToken, dispatch, value);
        return res;
      } catch (err) {
        console.log(err);
      }
    };

    const handleMenuOpts = async (value: string) => {
      try {
        const res = await fetchEmailsFromBackend(accessToken, dispatch, value);
        return res;
      } catch (err) {
        console.log(err);
      }
    };

    const authState = useSelector(
      (state: RootState) => state.auth as AuthState
    );
    const mailAccounts = getConnectedAccounts(authState, AccountCenter.EMAIL);

    const getFilters = async () => {
      try {
        const res = await axios.get(`${baseURL}/email/filter`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Current-IP": await getCurrentIp(),
          },
        });
        if (res.status === 200) {
          setDataList(res.data.data);
        }
      } catch (err) {
        console.log(err);
      }
    };

    // const transformAccountData = (reduxData: ReduxAccountData[]): TransformedAccountData[] => {
    //   return reduxData?.map((account) => ({
    //     userNameOrEmail: account.displayName,
    //     userIconSrc: account.icon,
    //     topIcon: account.accountType.toLowerCase(),
    //   }));
    // };

    // const loggedinUsers = useSelector(
    //   (state: RootState) => state?.emails?.emails?.data?.userData?.accountTab || [],
    //   (prev, next) => prev === next
    // );

    // const connectedAccounts = transformAccountData(loggedinUsers);

    useEffect(() => {
      getFilters();
    }, []);

    const handleReCall = async () => {
      dispatch(recallAPI__MAIL("COMPOSE"));
    };

      useEffect(() => {
          const handleKeyDown = (event: KeyboardEvent) => {
              if (
                  (event.ctrlKey && event.key === " ") ||
                  (event.metaKey && event.key === " ") ||
                  (event.ctrlKey && event.key === "s") ||
                  (event.metaKey && event.key === "s")
              ) {
                  event.preventDefault();
                  setShowSearchContainer((prev: any) => !prev);
              }
          };

          window.addEventListener("keydown", handleKeyDown);

          return () => {
              window.removeEventListener("keydown", handleKeyDown);
          };
      }, []);

    return (
      <>
        <DrawerContainer>
          <Drawer
            sx={{
              position: "absolute",
              top: 0,
              height: "100vh",
              width: "215px",
              // left: '-240px', // Start hidden behind the Navbar
              transition: drawerOpen
                ? "left 225ms ease-out"
                : "left 195ms ease-in",
              left: drawerOpen ? "30px" : "-215px",
              zIndex: 1100, // Ensure the drawer is behind the navbar
              "& .MuiPaper-root": {
                position: "absolute",
                top: 0,
                height: "100vh",
                width: "215px",
                left: 0,
                boxSizing: "border-box",
                zIndex: 1100, // Ensure the paper is also behind the navbar
              },
            }}
            variant="persistent"
            anchor="left"
            open={drawerOpen}
          >
            <Stack
              bgcolor={"white"}
              direction="column"
              flex={1}
              className={`${styles.drawerNavbar__drawer}`}
            >
              {/* Company logo and name here */}
              <CompanyIconAndName arrowButtonClickHandler={drawerCloser} />
              <Stack className={styles.drawerNavbar__content}>
                {/* Buttons and other lists here */}
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  width={"100%"}
                >
                  <MainActionButton
                    sx={{ marginY: "25px" }}
                    onClick={() => handleReCall()}
                    colorVarient={MainActionColor.EmailBlue}
                    label="Compose"
                    types={ButtonType.Button}
                  />

                  <Stack>
                    <IconButton
                      onClick={(event) => handleClick(event, "mailSettings")}
                    >
                      <GearIcon w={17} />
                    </IconButton>
                  </Stack>
                </Stack>

                <Stack
                  height="100%"
                  overflow="hidden"
                  maxHeight="84%"
                  sx={{
                    "&::-webkit-scrollbar": {
                      width: "0 !important",
                    },
                    scrollbarWidth: "none",
                    overflowX: "hidden",
                    overflowY: "scroll",
                  }}
                >
                  <DrawerSectionText title="Accounts" />
                  <Stack mt={"15px"} mb={"15px"} sx={{ marginBottom: "30px" }}>
                    <SidebarPinnedChatContainer needName={false}>
                      {mailAccounts && mailAccounts?.length > 0 ? (
                        mailAccounts?.map((mA: any, index: number) => (
                          <AccountsPinnedCard
                            topIcon={mA?.type}
                            userIconSrc={mA?.accountProfilePic}
                            userNameOrEmail={mA?.name}
                            key={index}
                          />
                        ))
                      ) : (
                        <Typography
                          variant="body2"
                          color="#737373"
                          style={{
                            fontSize: "0.69rem",
                            fontFamily: "Source Serif Pro",
                          }}
                        >
                          Please connect your account
                        </Typography>
                      )}
                    </SidebarPinnedChatContainer>
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <DrawerSectionText title="Smart filters" />
                    <IconButton
                      onClick={(event) => handleClick(event, "smartFilter")}
                    >
                      <GearIcon w={17} />
                    </IconButton>
                  </Stack>
                  <Stack mb={"15px"} width={"100%"} mt={"10px"}>
                    <SmartFilter
                      activeFilterList={dataList}
                      handleFilterEmails={handleFilterEmails}
                    />
                  </Stack>
                  <DrawerSectionText title="Menu" />
                  <Stack mb={"15px"} width={"100%"} mt={"10px"}>
                    <MenuOptions handleMenuOpts={handleMenuOpts} />
                  </Stack>
                  <DrawerSectionText title="Folders" />
                </Stack>
              </Stack>
            </Stack>
            {/* Popover */}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
            >
              {popoverType === "smartFilter" ? (
                <SmartFiltersDropdown
                  getFilters={getFilters}
                  dataList={dataList}
                />
              ) : null}
              {popoverType === "mailSettings" ? (
                <MailSettings handleClose={handleClose} />
              ) : null}
            </Popover>
              {showSearchContainer && (
                  <Box
                      sx={{
                          position: "fixed",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          zIndex: 1000,
                      }}
                  >
                      <CommandControlSearchContainer />
                  </Box>
              )}
          </Drawer>
        </DrawerContainer>
      </>
    );
  }
);

export default EmailDrawer;
