import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { ButtonSmall } from "../Buttons";
import { SoonHorizontal } from "../SoonHorizontal";
import styles from "./ButtonIconXlong.module.css";
import { ButtonType, SmallButtonColor } from "../Buttons/AllButtonProps";
import { useTranslation } from "react-i18next";

interface ButtonIconXlongProps {
  headerText: string;
  bodyText: string;
  imgSrc: string;
  comingSoon?: boolean; // Mark as optional
  buttonClick?: () => void;
  actionButtonLabel?: string;
}

const ButtonIconXlong: React.FC<ButtonIconXlongProps> = ({
  headerText,
  bodyText,
  imgSrc,
  comingSoon = false, // Default value
  actionButtonLabel = "SETTINGS.ENABLE",
  buttonClick,
}) => {
  const { t } = useTranslation();
  return (
    <Stack
      className={styles["button-icon-xlong"]}
      role="region"
      aria-label={headerText}
    >
      <Stack className={styles["button-icon-xlong__content"]}>
        <Stack className={styles["button-icon-xlong__text-group"]}>
          <Box
            className={styles["button-icon-xlong__image"]}
            sx={{
              backgroundImage: `url(${imgSrc})`,
            }}
            role="img"
            aria-label={headerText}
          />
          <Box>
            <Typography className={styles["button-icon-xlong__text"]}>
              {headerText}
            </Typography>
            <Typography className={styles["button-icon-xlong__body-text"]}>
              {bodyText}
            </Typography>
          </Box>
        </Stack>
        <Stack className={styles["button-icon-xlong__actions"]}>
          {comingSoon && <SoonHorizontal />}
          <ButtonSmall
            onClick={buttonClick}
            colorVarient={SmallButtonColor.Orange}
            label={t(actionButtonLabel)}
            loaderLogin={false}
            types={ButtonType.Button}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ButtonIconXlong;
