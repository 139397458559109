import {Drawer, Popover, Stack} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box, styled } from "@mui/system";
import CompanyIconAndName from "../../CompanyIconAndName";
import { MainActionButton } from "../../../Buttons";
import styles from "../../DrawerNavbar.module.css";
import { ButtonType, MainActionColor } from "../../../Buttons/AllButtonProps";
import {
  SidebarMsgContainer,
  SettingsMessagesMain,
  SidebarPinnedForChannelContainer, CreateNewMessages,
} from "../../../../pages/Message/components";
import { MessageView } from "../../../QuickActions/View";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllExistingUsers } from "../../../../../redux/Slice/auth/authActions";
import { MessagesState } from "../../../../../redux/Slice/message/messageTypes";
import { RootState } from "../../../../../redux/store";
import { useTranslation } from "react-i18next";
import {
  getSlackUsers,
  getTeamsUser,
  getThirdPartyUserProfiles,
  getUserConnections,
  markMessagesAsRead,
} from "../../../../../redux/Slice/message/messageActions";
import pusher from "../../../../../Utils/pusher";
import {
  addMessageAction,
  addNewConnectionAction,
  sortChannelsBasedOnLastContentAction,
} from "../../../../../redux/Slice/message/messageSlice";
import {
  requestNotificationPermission,
  triggerNotification,
  triggerNotificationForCreatedNewConnection,
  triggerNotificationForEditMessage,
  triggerNotificationForReaction,
} from "../../../../../Utils/notifications";
import { AuthState } from "../../../../../redux";
import { MESSAGE_ENUMS } from "../../../../pages/Message/constant";
import {CommandControlSearchContainer} from "../../../../pages/Dashboard/components/CommandControlSearchContainer";
import FullScreenLoader from "../../../Loaders/FullScreenLoader";

type MessageDrawerProps = {
  drawerOpen: boolean;
  drawerCloser: () => void;
};

const DrawerContainer = styled("div")({
  position: "fixed",
  top: 0,
  height: "100vh",
  zIndex: 1100, // Ensure the drawer is behind the navbar
});

const MessageDrawer: React.FC<MessageDrawerProps> = ({
  drawerOpen,
  drawerCloser,
}) => {
  const [filterOptions, setFilterOptions] = useState<any>([
    { id: 1, value: "Show Internal Chats", isSelected: true },
    { id: 2, value: "Show External Chats", isSelected: true },
    { id: 3, value: "Show Pinned Chats", isSelected: true },
    { id: 4, value: "Show Channels", isSelected: true },
    { id: 5, value: "Show Apps", isSelected: true },
  ]);

  const [showAllExternalChannels, setShowAllExternalChannels] = useState(false);
  const [showAllInternalChannels, setShowAllInternalChannels] = useState(false);
  const [showSearchContainer, setShowSearchContainer] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();

  const { user } = useSelector((state: RootState) => state.auth as AuthState);
  const dispatch = useDispatch();

  const { channels, externalChannels, currentChannel,loading } = useSelector(
    (state: RootState) => state.message as MessagesState
  );

  const handleBackButtonClick = (channelId: any) => {
    console.log("channelId: ", channelId);
  };

  const handleOnChange = async (data: any) => {
    setFilterOptions(data);
  };

  const handleViewMoreInternalChats = () => {
    setShowAllInternalChannels(!showAllInternalChannels);
  };
  const handleViewMoreExternalChats = () => {
    setShowAllExternalChannels(!showAllExternalChannels);
  };
  const handleClick =  async (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseCreateNewConnection = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  const limitedChannels = Object.keys(channels)
    .slice(0, 4)
    .reduce((result: any, key: any) => {
      result[key] = channels[key];
      return result;
    }, {});
  const limitedExternalChannels = Object.keys(externalChannels)
    .slice(0, 4)
    .reduce((result: any, key: any) => {
      result[key] = externalChannels[key];
      return result;
    }, {});

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getUserConnections());
      await dispatch(getAllExistingUsers());
      const userProfiles: any = await dispatch(getThirdPartyUserProfiles());
      if (userProfiles?.data?.slackProfile !== null) {
       await dispatch(getSlackUsers());
      } else if (userProfiles?.data?.teamsProfile !== null) {
        dispatch(getTeamsUser());
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    requestNotificationPermission();
    const currentChannelIdInst: any = id;

    if (user?.data?._id) {
      const channel = pusher.subscribe(user?.data?._id ? user?.data?._id : "");

      const callback = (eventName: any, data: any) => {
        if (eventName === "new-connection") {
          dispatch(addNewConnectionAction(data));
          triggerNotificationForCreatedNewConnection(data, dispatch);
        }
        if (eventName === "new-message") {
          if (data?.message?.channelId === currentChannelIdInst) {
            dispatch(addMessageAction(data));
            dispatch(sortChannelsBasedOnLastContentAction(data));
            if (data.addEmoji) {
              triggerNotificationForReaction(dispatch, data, user?.data);
            } else if (data.isEdited) {
              triggerNotificationForEditMessage(dispatch, data, user?.data);
            } else if (data.message.userId !== user?.data?._id) {
              triggerNotification(dispatch, data, user?.data, channels);
            } else {
              console.log(data.message);
            }
            if (currentChannel?.channelType === MESSAGE_ENUMS.BEARISH) {
              dispatch(markMessagesAsRead(currentChannelIdInst));
            }
          } else {
            dispatch(sortChannelsBasedOnLastContentAction(data));
            if (data.addEmoji) {
              triggerNotificationForReaction(dispatch, data, user?.data);
            } else if (data.isEdited) {
              triggerNotificationForEditMessage(dispatch, data, user?.data);
            } else {
              triggerNotification(dispatch, data, user?.data, channels);
            }
          }
        }
      };

      channel.bind_global(callback);

      return () => {
        if (channel) {
          channel.unbind_global(callback);
          pusher.unsubscribe(user?.data?._id);
        }
      };
    }
  }, [id]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
          (event.ctrlKey && event.key === " ") ||
          (event.metaKey && event.key === " ") ||
          (event.ctrlKey && event.key === "s") ||
          (event.metaKey && event.key === "s")
      ) {
        event.preventDefault();
        setShowSearchContainer((prev: any) => !prev);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  if (loading) {
    return <FullScreenLoader />;
  }
  return (
    <>
      <DrawerContainer>
        <Drawer
          sx={{
            position: "absolute",
            top: 0,
            height: "100vh",
            width: "215px",
            transition: drawerOpen
              ? "left 225ms ease-out"
              : "left 195ms ease-in",
            left: drawerOpen ? "30px" : "-215px",
            zIndex: 1100,
            "& .MuiPaper-root": {
              position: "absolute",
              top: 0,
              height: "100vh",
              width: "215px",
              left: 0,
              boxSizing: "border-box",
              zIndex: 1100,
            },
          }}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
        >
          <Stack
            bgcolor={"white"}
            direction="column"
            flex={1}
            className={`${styles.drawerNavbar__drawer}`}
          >
            <CompanyIconAndName arrowButtonClickHandler={drawerCloser} />
            <Stack className={styles.drawerNavbar__content}>
              <Box style={{ display: "flex" }}>
                <MainActionButton
                  sx={{ marginY: "25px" }}
                  onClick={(event) => handleClick(event)}
                  colorVarient={MainActionColor.MessagePurple}
                  label={t("MESSAGE.MESSAGE")}
                  types={ButtonType.Button}
                />
                <Popover
                    id={popoverId}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleCloseCreateNewConnection}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                >
                  <CreateNewMessages handleGetBackClick={handleCloseCreateNewConnection} />
                </Popover>
                <div style={{ marginLeft: "52px", display: "flex" }}>
                  <SettingsMessagesMain
                    onChange={handleOnChange}
                    displayOption="right"
                  />
                </div>
              </Box>
              {filterOptions[2].isSelected && (
                <SidebarPinnedForChannelContainer />
              )}
              {filterOptions[0].isSelected && (
                <SidebarMsgContainer
                  children={
                    <MessageView
                      channels={
                        showAllInternalChannels ? channels : limitedChannels
                      }
                      onBackButtonClick={handleBackButtonClick}
                    />
                  }
                  label={t("MESSAGE.INTERNAL_CHATS")}
                  isOpen={
                    Object.keys(
                      showAllInternalChannels ? channels : limitedChannels
                    ).length > 0
                      ? true
                      : false
                  }
                  viewAll={showAllInternalChannels}
                  viewAllButtonClick={handleViewMoreInternalChats}
                />
              )}
              {filterOptions[1].isSelected && (
                <SidebarMsgContainer
                  children={
                    <MessageView
                      channels={
                        showAllExternalChannels
                          ? externalChannels
                          : limitedExternalChannels
                      }
                      onBackButtonClick={handleBackButtonClick}
                    />
                  }
                  label={t("MESSAGE.EXTERNAL_CHATS")}
                  isOpen={
                    Object.keys(
                      showAllExternalChannels
                        ? externalChannels
                        : limitedExternalChannels
                    ).length > 0
                      ? true
                      : false
                  }
                  viewAll={showAllExternalChannels}
                  viewAllButtonClick={handleViewMoreExternalChats}
                />
              )}
              {showSearchContainer && (
                  <Box
                      sx={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 1000,
                      }}
                  >
                    <CommandControlSearchContainer />
                  </Box>
              )}
            </Stack>
          </Stack>
        </Drawer>
      </DrawerContainer>
    </>
  );
};

export default MessageDrawer;