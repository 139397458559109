import {
  FormControlLabel,
  Radio,
  RadioProps,
  Stack,
  styled,
  SxProps,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";

// ----  custom radio button ------

interface RadiobuttonBlackProps extends RadioProps {
  radioSize?: number;
  radioColor?: string;
}

export const StyledRadioButtonBlack: React.FC<RadiobuttonBlackProps> = ({
  radioSize = 15,
  radioColor = "black",
  ...props
}) => {
  return (
    <Radio
      {...props}
      sx={{
        color: radioColor,
        p: 0,
        "&.Mui-checked": {
          color: radioColor,
        },
        "& .MuiSvgIcon-root": {
          fontSize: radioSize,
        },
        ...props.sx,
      }}
    />
  );
};

// --------  custom foemcontrol label for radio buttons ---------

const StyledRadioFormControlLabel = styled(FormControlLabel)<{
  labelFont: string;
}>(({ labelFont }) => ({
  margin: 0,
  maxWidth: "max-content",
  flexDirection: "row",
  gap: "10px",

  "& .MuiFormControlLabel-label": {
    font: labelFont,
  },
}));

interface RadioFormControlLabelProps {
  value: string;
  label: string;
  radioSize?: number;
  labelFont?: string;
  sx?: object; // Added for sx props
  [key: string]: any; // For other props
  tooltipText?: string;
  onContainerClick?: () => void;
}

export const CustomRadioFormControlLabel: React.FC<
  RadioFormControlLabelProps
> = ({
  label,
  onContainerClick,
  value,
  labelFont = "normal normal 300 14px/18px Source Serif Pro",
  tooltipText,
  radioSize = 17,
  sx = {},
  ...otherProps
}) => {
  return (
    <Tooltip title={tooltipText} placement="top" arrow disableInteractive>
      <StyledRadioFormControlLabel
        onClick={onContainerClick}
        control={<StyledRadioButtonBlack radioSize={radioSize} />}
        label={label}
        labelFont={labelFont}
        sx={{ ...sx }} // Merge with existing styles
        value={value} // Pass the value prop to FormControlLabel
        {...otherProps}
      />
    </Tooltip>
  );
};

interface MultiSelectRadioProps {
  label: string;
  isChecked: boolean;
  onClick: () => void;
  radioSize?: number;
  sx?: SxProps;
}

export const MultiSelectRadioBtn: React.FC<MultiSelectRadioProps> = ({
  label,
  onClick,
  radioSize = 17,
  isChecked,
  sx,
}) => {
  return (
    <Stack
      onClick={onClick}
      sx={{
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
        width: "min-content",
        font: "normal normal 300 14px/18px Source Serif Pro",
        cursor: "pointer",
        ...sx,
      }}
    >
      <StyledRadioButtonBlack checked={isChecked} radioSize={radioSize} />
      <Typography sx={{ font: "inherit", whiteSpace: "nowrap" }}>
        {label}
      </Typography>
    </Stack>
  );
};
