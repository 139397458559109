import { AdditionalFilterType, SearchGroups } from "../CommandControlSearchContainer";

// Dynamically create a union type of all filterName value


export type FilterNameType = {
    _id: string;
    filterName: AdditionalFilterType;
    relatedGroup: SearchGroups[];
};

export const filterFilesNames: FilterNameType[] = [
    { _id: 'meetingType', filterName: 'meetingType', relatedGroup: ['video'] },
    { _id: 'duration', filterName: 'duration', relatedGroup: ['video', 'calendar'] },
    { _id: 'recordingStatus', filterName: 'recordingStatus', relatedGroup: ['video'] },
    { _id: 'emailType', filterName: 'emailType', relatedGroup: ['email'] },
    { _id: 'emailSource', filterName: 'emailSource', relatedGroup: ['email'] },
    { _id: 'emailBasicFilters', filterName: 'emailBasicFilters', relatedGroup: ['email'] },
    { _id: 'eventType', filterName: 'eventType', relatedGroup: ['calendar'] },
    { _id: 'eventStatus', filterName: 'eventStatus', relatedGroup: ['calendar'] },
    { _id: 'bookingLink', filterName: 'bookingLink', relatedGroup: ['calendar'] },
    { _id: 'messageTypeAndStatus', filterName: 'messageTypeAndStatus', relatedGroup: ['message'] },
    { _id: 'messageSource', filterName: 'messageSource', relatedGroup: ['message'] },
    { _id: 'attachements', filterName: 'attachements', relatedGroup: ['message'] },
    { _id: 'contactConnections', filterName: 'contactConnections', relatedGroup: ['crm'] },
    { _id: 'interactionType', filterName: 'interactionType', relatedGroup: ['crm'] },
    { _id: 'groupOrDealName', filterName: 'groupOrDealName', relatedGroup: ['crm'] },
    { _id: 'employeeType', filterName: 'employeeType', relatedGroup: ['trax'] },
    { _id: 'employeeDepartment', filterName: 'employeeDepartment', relatedGroup: ['trax'] },
    { _id: 'employeeStatus', filterName: 'employeeStatus', relatedGroup: ['trax'] },
    { _id: 'workspaceType', filterName: 'workspaceType', relatedGroup: ['workspace'] },
    { _id: 'workspaceOwnership', filterName: 'workspaceOwnership', relatedGroup: ['workspace'] },
    { _id: 'cloudStorageOwnership', filterName: 'cloudStorageOwnership', relatedGroup: ['workspace'] },
    { _id: 'cloudStorageType', filterName: 'cloudStorageType', relatedGroup: ['workspace'] },
    { _id: 'cloudStorageSize', filterName: 'cloudStorageSize', relatedGroup: ['workspace'] },
];
