import { Stack } from "@mui/material";
import React from "react";
import { TraxApprovedBadge } from "../../components";
import { UserInfoChip } from "../../../Email/components";

interface TableReceiptActedAreaProps {
  value: any;
}

const TableReceiptActedArea: React.FC<TableReceiptActedAreaProps> = ({
  value,
}) => {
  return (
    <Stack alignItems={"center"}>
      {value ? (
        <UserInfoChip
          popoverPlace="top"
          chipHeight="30px"
          userEmail="userEmail here"
          userName="userName here"
          // closeButtonClick={() => null}
        />
      ) : (
        <TraxApprovedBadge variant="pending" />
      )}
    </Stack>
  );
};

export default TableReceiptActedArea;
