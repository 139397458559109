import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { LargeInputBox } from "../LargeInputBox";
import { ButtonXLarge } from "../Buttons";
import { ButtonType, ButtonXLargeColor } from "../Buttons/AllButtonProps";
import { CloseIcon } from "../../../images";
import styles from "./PopupLarge.module.css";
import { PopUpProps } from "./popupTypes";
import { getUser } from "../../../Utils";
import { updateIpRestrictions__api } from "../../../redux/Slice/auth/authActions";
import { useDispatch } from "react-redux";

const IpRestrictionsPopup: React.FC<PopUpProps> = ({ handleClosePopup }) => {
  const [ipAddress, setIpAddress] = React.useState<string>("");

  const dispatch = useDispatch();
  const closeHandler = () => {
    handleClosePopup?.(null);
  };

  const add__IP_Restrictions = async () => {
    try {
      if (!ipAddress) {
        return;
      }

      const payload = {
        ipRestrictions: [ipAddress],
      };

      const action = updateIpRestrictions__api(payload);

      await dispatch(action);
    } catch (err) {
      console.error(err);
    }
  };
  const user = getUser();

  return (
    <Stack className={styles.popupLarge}>
      <Box
        component="span"
        className={styles.popupLarge__closeIcon}
        onClick={() => closeHandler()}
      >
        <CloseIcon />
      </Box>

      <Typography className={styles.popupLarge__title}>
        Ip restrictions
      </Typography>
      <Typography className={styles.popupLarge__subtitle}>
        Add custom ip address to limit access to this account
      </Typography>
      <Typography className={styles.popupLarge__email}>
        {user?.data?.email}
      </Typography>
      <Stack className={styles.popupLarge__content}>
        <LargeInputBox
          borderColor="black"
          label="Add ip"
          value={ipAddress}
          onChange={(e) => {
            const value = typeof e === "string" ? e : e.target.value;
            setIpAddress(value);
          }}
        />
        <ButtonXLarge
          onClick={add__IP_Restrictions}
          label="Save"
          types={ButtonType.Button}
          colorVarient={ButtonXLargeColor.Green}
        />
        <ButtonXLarge label="Close" colorVarient={ButtonXLargeColor.Orange} />
      </Stack>
    </Stack>
  );
};

export default IpRestrictionsPopup;
