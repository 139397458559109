import { Stack } from "@mui/material";
import {
  AddGuestButton,
  ChangeBackgroundButton,
  EndCallButton,
  LiveChatButton,
  MicButton,
  ShareScreenButton,
  VideoButton,
} from "./components";
import VideoTiles from "./VideoTiles";
import { MeetingContext } from "../../Povider";
import { useCallback, useContext,useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { logMeetingActivity } from "../../../redux";
import { useDispatch, useSelector } from "react-redux";

const CenterVideoTile = ({
  videoTile = {},
  ownContentTile,
  ownVideoTile,
  meetingDetails,
  userId,
  newcss,
  videoTiledata,
}) => {
  const meetingContext = useContext(MeetingContext);
  const { onGoingMeeting } = useSelector((state) => state.videoCenter);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onBlurButtonClickHandler = useCallback(async () => {
    await meetingContext.blurBackground();
  }, []);

  const sendActivityToAPI = async (event) => {
    try {
      const meetingId = onGoingMeeting?.meeting?._id;
      const response = {
         event
      };
      await dispatch(logMeetingActivity(meetingId, response));
    } catch (error) {
      console.error("Failed to send activity to API", error);
    }
  };

  const onMicButtonClickHandler = useCallback(async () => {
    if (ownVideoTile?.muted) {
      await meetingContext.unmuteAudioInput();
      sendActivityToAPI("MIC_ON");
    } else {
      await meetingContext.muteAudioInput();
      sendActivityToAPI("MIC_OFF");
    }
  }, [ownVideoTile?.muted]);

  const onVideoButtonClickHandler = useCallback(async () => {
    if (ownVideoTile?.videoTileState?.active) {
      await meetingContext.stopVideoInput();
      sendActivityToAPI("CAMERA_OFF");
    } else {
      await meetingContext.startVideoInput();
      sendActivityToAPI("CAMERA_ON");
    }
  }, [ownVideoTile?.videoTileState?.active]);

  const onShareScreenButtonClickHandler = useCallback(async () => {
    if (ownContentTile?.videoTileState?.active) {
      await meetingContext.stopScreenShare();
      sendActivityToAPI("STOP_SCREEN_SHARE");
    } else {
      await meetingContext.startScreenShare();
      sendActivityToAPI("START_SCREEN_SHARE");
    }
  }, [ownContentTile?.videoTileState?.active]);

  const handleCallEnd = useCallback(async () => {
    if (userId === meetingDetails?.meetingHostId) {
      const meetingDetail = {
        meeting: meetingDetails,
      };
      meetingContext.handleCallEnd(meetingDetail);
      meetingContext.leaveMeeting();
    } else {
      sendActivityToAPI("USER_LEFT");
      meetingContext.leaveMeeting();
    }
    navigate("/Calls");
  }, []);

  return (
    <>
      <Stack sx={{ flexDirection: newcss ? "row-reverse" : "column", gap: 2 }}>
        <Stack
          sx={{ flexDirection: "column", gap: 2, minWidth: newcss && "275px" }}
        >
          <VideoTiles {...videoTile} aspectRatio={"750/350"} />
          {newcss && (
            <Stack>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 1,
                  flex: 8,
                }}
              >
                {videoTiledata?.slice(1).map((tile, i) => (
                  <VideoTiles
                    {...tile}
                    aspectRatio={1}
                    key={i}
                    showIcon={true}
                  />
                ))}
              </Box>
            </Stack>
          )}
        </Stack>

        <Stack
          sx={{ flexDirection: "row", gap: 2, justifyContent: "space-between" }}
        >
          {!newcss && (
            <Stack sx={{ flexDirection: "row", gap: 2 }}>
              {/*<AddGuestButton />*/}
            </Stack>
          )}
          <Stack sx={{ flexDirection: newcss ? "column" : "row", gap: 2 }}>
            {newcss &&
                <Stack sx={{ flexDirection: "row", gap: 2 }}>
                  <ChangeBackgroundButton onClick={onBlurButtonClickHandler} />
              </Stack>
            }
            {!newcss && (
              <ChangeBackgroundButton onClick={onBlurButtonClickHandler} />
            )}
            <MicButton
              initialStatus={ownVideoTile?.muted}
              onClickChange={onMicButtonClickHandler}
            />
            <EndCallButton
              onClick={handleCallEnd}
              btnSize={newcss ? "small" : "large"}
            />
            <VideoButton
              initialStatus={ownVideoTile?.videoTileState?.active}
              onClickChange={onVideoButtonClickHandler}
            />
            <ShareScreenButton
              initialStatus={ownContentTile?.videoTileState?.active}
              onClick={onShareScreenButtonClickHandler}
            />
            {newcss && <Stack sx={{ flexDirection: "row", gap: 2 }}>
              {/*<LiveChatButton />*/}
            </Stack>}
          </Stack>
          {!newcss && (
            <Stack sx={{ flexDirection: "row", gap: 2 }}>
              {/*<LiveChatButton />*/}
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default CenterVideoTile;
