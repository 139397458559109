import { Button, InputBase, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./DeleteItemCloud.module.css";

interface DeleteItemCloudProps {
  itemName: string; // Expecting the name of the item to delete
  fileId: string; // The ID of the file to be deleted
  onDelete: (fileId: string, provider?:string) => void; // Callback to delete the item
  onClose: any;
  provider?: string; //
}

const DeleteItemCloud: React.FC<DeleteItemCloudProps> = ({
  itemName,
  fileId,
  onClose,
  onDelete,
  provider = "BearishOS",
}) => {
  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    return () => {
      setInputValue("");
    };
  }, []);

  const handleDeleteClick = () => {
    // Call the onDelete function with the fileId if the input matches the itemName
    if (inputValue === itemName) {
      onClose();
      onDelete(fileId, provider); // Directly call the delete action with the fileId
    }
  };

  return (
    <Stack className={styles["deleteItemCloud"]}>
      {/* Header of this card */}
      <Stack className={styles["deleteItemCloud__header"]}>
        <Typography className={styles["deleteItemCloud__title"]}>
          Delete an item
        </Typography>
      </Stack>
      {/* ----------------- */}

      {/* ------ Text and input area here ------ */}
      <Stack className={styles["deleteItemCloud__content"]}>
      <Typography className={styles["deleteItemCloud__description"]}>
  You are about to permanently delete <strong>{itemName}</strong>, and this cannot be undone. 
  Type the name of your item below to continue.
</Typography>
        <InputBase
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Type the name of your item here..."
          className={styles["deleteItemCloud__input"]}
        />
      </Stack>
      {/* ----------------- */}

      {/* ------ Button here ------ */}
      <Stack className={styles["deleteItemCloud__buttonContainer"]}>
        <Button
          sx={{ textTransform: "capitalize" }}
          className={styles["deleteItemCloud__button"]}
          onClick={handleDeleteClick}
          disabled={inputValue !== itemName} // Disable button if names do not match
        >
          <Typography className={styles["deleteItemCloud__buttonText"]}>
            Permanently delete this item
          </Typography>
        </Button>
      </Stack>
      {/* ----------------- */}
    </Stack>
  );
};

export default DeleteItemCloud;
