import { Button, ButtonProps, Typography } from "@mui/material";
import React from "react";
import { CoverBoldIcon } from "../../../../../../../images";

interface CoverImgButtonProps extends ButtonProps {
  textColor: "white" | "black";
}

const CoverImgButton: React.FC<CoverImgButtonProps> = ({
  textColor,
  sx,
  ...props
}) => {
  return (
    <Button
      sx={{
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
        textTransform: "none",
        color: "black",
        padding: "0xp 5px",
        minWidth: 0,
        ...sx,
      }}
      {...props}
    >
      <CoverBoldIcon w={15} color={textColor} />
      <Typography
        sx={{
          font: "normal normal 300 11px/14px Source Serif Pro",
          color: textColor,
        }}
      >
        Cover Image
      </Typography>
    </Button>
  );
};

export default CoverImgButton;
