import {PayloadAction} from "@reduxjs/toolkit";
import {DashboardState} from "../dashboardTypes";

export const getAllEvents__Success = (
    state: DashboardState,
    action: PayloadAction<any>
) => {
    state.error = null;
    state.loading = false;

    state.eventsAndTasks = action.payload.data.reduce(
        (acc: Record<string, any>, events: any) => {
            acc[events._id] = events;
            return acc;
        },
        {} as Record<string, any>
    );
}