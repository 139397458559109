import {
  Box,
  Button,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import CompanyIconAndName from "../../CompanyIconAndName";
import { MainActionButton } from "../../../Buttons";
import styles from "../../DrawerNavbar.module.css";
import { ButtonType, MainActionColor } from "../../../Buttons/AllButtonProps";
import DrawerSectionText from "../../DrawerSectionText";
import { NavigationRoute } from "../../../NavigationRoute";
import {
  CompaniesIcon,
  DealsIcon,
  GearIcon,
  PeopleIcon,
} from "../../../../../images";
import { MUIModalstyle } from ".";
import { CrmLargePopup } from "../../../../pages/CRM/components/CrmLargePopup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { recallAPI__CRM } from "../../../../../redux";
import CommandControlSearchContainer
    from "../../../../pages/Dashboard/components/CommandControlSearchContainer/CommandControlSearchContainer";
type CrmDrawerProps = {
  drawerOpen: boolean;
  drawerCloser: () => void;
};

const DrawerContainer = styled("div")({
  position: "fixed",
  top: 0,
  height: "100vh",
  zIndex: 1100, // Ensure the drawer is behind the navbar
});
const CrmDrawer: React.FC<CrmDrawerProps> = ({ drawerOpen, drawerCloser }) => {
  const dispatch = useDispatch();
  const crmState = useSelector((state: RootState) => state && state?.crm);
  const { groupList } = crmState || {};
  const [showSearchContainer, setShowSearchContainer] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = (label: "company" | "contact" | "deal" | "group" | "") => {
    setCreateType(label);
    setOpen(true);

    handleCloseMenu();
  };
  const handleClose = () => {
    setCreateType("");
    setOpen(false);
  };

  const [createType, setCreateType] = useState<
    "company" | "contact" | "deal" | "group" | ""
  >("");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [grpList, setGrpList] = useState<any>([]);

  useEffect(() => {
    if (groupList && groupList?.length > 0) {
      setGrpList(groupList);
    }
  }, [groupList]);

  const loginOutlook = async () => {
    dispatch(recallAPI__CRM("loginOutLookContacts"));
  };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (
                (event.ctrlKey && event.key === " ") ||
                (event.metaKey && event.key === " ") ||
                (event.ctrlKey && event.key === "s") ||
                (event.metaKey && event.key === "s")
            ) {
                event.preventDefault();
                setShowSearchContainer((prev: any) => !prev);
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

  return (
    <>
      <DrawerContainer>
        <Drawer
          sx={{
            position: "absolute",
            top: 0,
            height: "100vh",
            width: "215px",
            // left: '-240px', // Start hidden behind the Navbar
            transition: drawerOpen
              ? "left 225ms ease-out"
              : "left 195ms ease-in",
            left: drawerOpen ? "30px" : "-215px",
            zIndex: 1100, // Ensure the drawer is behind the navbar
            "& .MuiPaper-root": {
              position: "absolute",
              top: 0,
              height: "100vh",
              width: "215px",
              left: 0,
              boxSizing: "border-box",
              zIndex: 1100, // Ensure the paper is also behind the navbar
            },
          }}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
        >
          <Stack
            bgcolor={"white"}
            direction="column"
            flex={1}
            className={`${styles.drawerNavbar__drawer}`}
          >
            {/* Company logo and name here */}
            <CompanyIconAndName arrowButtonClickHandler={drawerCloser} />
            <Stack className={styles.drawerNavbar__content}>
              {/* Buttons and other lists here */}
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                <MainActionButton
                  sx={{ marginY: "25px" }}
                  onClick={(e: any) => {
                    handleClickMenu(e);
                  }}
                  colorVarient={MainActionColor.Orange}
                  label="Create"
                  types={ButtonType.Button}
                />{" "}
                <Stack>
                  <IconButton onClick={(event) => loginOutlook()}>
                    <GearIcon w={17} />
                  </IconButton>
                </Stack>
              </Stack>
              <Stack
                direction="column"
                gap={3} // Adjusted for simplicity
              >
                <Stack>
                  <DrawerSectionText gap={0} title="Relationships" />
                  <Stack mt={2} direction={"column"} gap={1.5}>
                    <NavigationRoute
                      label="People"
                      path="/CRM/people"
                      labelFont="normal normal 500 14px/19px Source Serif Pro"
                      icon={<PeopleIcon w={20} />}
                    />
                    <NavigationRoute
                      label="Companies"
                      path="/CRM/company"
                      labelFont="normal normal 500 14px/19px Source Serif Pro"
                      icon={<CompaniesIcon w={20} />}
                    />
                    <NavigationRoute
                      label="Deals"
                      path="/CRM/deal"
                      labelFont="normal normal 500 14px/19px Source Serif Pro"
                      icon={<DealsIcon w={20} />}
                    />
                  </Stack>
                </Stack>
                <Stack>
                  <DrawerSectionText gap={0} title="Groups" />
                  <Stack mt={2} direction={"column"} gap={1.5}>
                    <Stack gap={"5px"} width={"100%"}>
                      {grpList?.length > 0 ? (
                        grpList?.map((group: any, index: number) => (
                          <Button
                            key={group?._id}
                            sx={{
                              width: "100%",
                              minWidth: "none",
                              textTransform: "capitalize",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              gap: "10px",
                              color: "black",
                              font: "normal normal 400 14px/18px Source Serif Pro",
                              transition: "all 0.3s ease", // Apply transition to all properties
                              border: "1px solid transparent", // Default border
                              "&:hover": {
                                fontWeight: "bold", // Bold font on hover
                                backgroundColor: "#F0F3F6", // Slightly orange background on hover
                                borderColor: "#F0F3F6", // Normal orange border on hover
                              },
                            }}
                            onClick={() => navigate(`/CRM/group/${group?._id}`)} // Navigate to group details
                          >
                            <span>{group?.groupIcon || "🔘"}</span>{" "}
                            {/* Default icon if none is provided */}
                            <span>{group?.groupName}</span>
                          </Button>
                        ))
                      ) : (
                        <Typography
                          sx={{
                            font: "normal normal 400 14px/18px  Source Serif Pro",
                          }}
                          variant="body2"
                          color="textSecondary"
                        >
                          No groups created yet.
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
            {showSearchContainer && (
                <Box
                    sx={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 1000,
                    }}
                >
                    <CommandControlSearchContainer />
                </Box>
            )}
        </Drawer>
      </DrawerContainer>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Stack
          direction={"column"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          gap={0}
        >
          {" "}
          <Button
            sx={{
              font: "normal normal 300 14px/20px Source Serif Pro",
              color: "black",
              width: "100%",
              textTransform: "capitalize",
              textAlign: "left",
              justifyContent: "flex-start",
            }}
            onClick={() => handleOpen("contact")}
          >
            Create a new contact
          </Button>
          <Button
            sx={{
              font: "normal normal 300 14px/20px Source Serif Pro",
              color: "black",
              width: "100%",
              textTransform: "capitalize",
              textAlign: "left",
              justifyContent: "flex-start",
            }}
            onClick={() => handleOpen("company")}
          >
            Create a new company
          </Button>
          <Button
            sx={{
              font: "normal normal 300 14px/20px Source Serif Pro",
              color: "black",
              width: "100%",
              textTransform: "capitalize",
              textAlign: "left",
              justifyContent: "flex-start",
            }}
            onClick={() => handleOpen("deal")}
          >
            Create a new deal
          </Button>
          <Button
            sx={{
              font: "normal normal 300 14px/20px Source Serif Pro",
              color: "black",
              width: "100%",
              textTransform: "capitalize",
              textAlign: "left",
              justifyContent: "flex-start",
            }}
            onClick={() => handleOpen("group")}
          >
            Create a new group
          </Button>
        </Stack>
      </Menu>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={MUIModalstyle}>
          <CrmLargePopup handleClose={handleClose} label={createType} />
        </Box>

      </Modal>
    </>
  );
};

export default CrmDrawer;
