import { Stack, Typography } from "@mui/material";
import React from "react";
import { CheckboxMedium } from "../../../../../../common";
import {
  ExpensesBadgesDataType,
  ExpensesBadgesMenuType,
} from "../../ExpensesBadgesMenuType";

interface ExpensesBadgesSubListProps {
  openedMenu: ExpensesBadgesMenuType;
  setOpenedMenu: React.Dispatch<React.SetStateAction<ExpensesBadgesMenuType>>;
  options: string[];
  initialSelect: ExpensesBadgesMenuType;
  setSelected: React.Dispatch<React.SetStateAction<ExpensesBadgesMenuType>>;
}

const ExpensesBadgesSubList: React.FC<ExpensesBadgesSubListProps> = ({
  initialSelect,
  openedMenu,
  options,
  setOpenedMenu,
  setSelected,
}) => {
  // const handleSelectOption = (option: string) => {
  //   if (badgeLabel !== initialSelect.badgeData.badgeLabel) {
  //     // Replace the menu label and reset the subList to contain only the selected option
  //     setSelected({
  //       badgeData:{badgeLabel}
  //       subList: [option],
  //     });
  //   } else {
  //     // Continue selecting/unselecting options for the same badgeLabel
  //     setSelected((prev) => ({
  //       ...prev,
  //       subList: prev.subList.includes(option)
  //         ? prev.subList.filter((item) => item !== option) // Unselect if already selected
  //         : [...prev.subList, option], // Select if not selected
  //     }));
  //   }
  // };

  const handleSelectOption = (option: string) => {
    setSelected((prev) => {
      if (openedMenu.badgeData.badgeLabel !== prev.badgeData.badgeLabel) {
        // If a different badge is selected, replace the badge and start a fresh subtype list
        return {
          _id: openedMenu._id,
          badgeData: openedMenu.badgeData,
          subTypeName: [option],
        };
      } else {
        // If the same badge is selected, update the subtype selection
        const subTypeName = prev.subTypeName || [];
        const isSelected = subTypeName.includes(option);

        return {
          ...prev,
          subTypeName: isSelected
            ? subTypeName.filter((item) => item !== option) // Unselect if already selected
            : [...subTypeName, option], // Select if not selected
        };
      }
    });
  };
  const check =
    openedMenu.badgeData.badgeLabel == initialSelect.badgeData.badgeLabel;

  return (
    <Stack
      sx={{
        width: "160px",
        boxSizing: "border-box",
        borderRadius: "5px",
        bgcolor: "#fff",
        padding: "10px 10px 10px 15px",
        gap: "15px",
        maxHeight: "500px",
      }}
    >
      <Typography sx={{ font: "normal normal 300 15px/19px Source Serif Pro" }}>
        Subtypes
      </Typography>

      <Stack sx={{ overflow: "auto", scrollbarWidth: "none", gap: "15px" }}>
        {options.map((item, index) => (
          <Stack
            key={index}
            sx={{
              flexDirection: "row",
              alignItems: "start",
              gap: "10px",
              cursor: "pointer",
            }}
            onClick={() => handleSelectOption(item)}
          >
            <CheckboxMedium
              checked={
                check &&
                initialSelect.subTypeName &&
                initialSelect.subTypeName.includes(item)
              }
            />
            <Typography
              sx={{
                font: "normal normal 300 15px/19px Source Serif Pro",
              }}
            >
              {item}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default ExpensesBadgesSubList;
