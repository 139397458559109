import { SmallThreeDotMenuListInterface } from "../../../../common";

export const ThreeDotMenuTraxCommandOptions: SmallThreeDotMenuListInterface[] = [
    { id: 1, text: "Reject", textColor: "#FF0000", LANG: "VIDEO_CENTER.MEETING.REJECT" },
    { id: 2, text: "View & Edit", LANG: "TRAX.VIEW_AND_EDIT" },
    { id: 3, divider: true },
    { id: 4, text: "Clarify", LANG: "TRAX.CLARIFY" },
    { id: 5, text: "Mark as reviewed", LANG: "TRAX.MARK_AS_REVIEWED" },
    { id: 6, text: "Assign", LANG: "TRAX.ASSIGN" },
    { id: 7, divider: true },
    { id: 8, text: "Done", textColor: "#008D4C", LANG: "TRAX.STATUS_TAB.DONE" },
]