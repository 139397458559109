import React, { useEffect } from "react";
import {
  AdditionalFilterType,
  filterValueType,
} from "../../../CommandControlSearchContainer";
import { FilterNameType } from "../../CommandControlFilterMenuType";
import { Stack } from "@mui/material";
import {
  Attachements,
  BookingLink,
  CloudStorageOwnership,
  CloudStorageSize,
  CloudStorageType,
  ContactConnections,
  Duration,
  EmailBasicFilters,
  EmailSource,
  EmailType,
  EmployeeDepartment,
  EmployeeStatus,
  EmployeeType,
  EventStatus,
  EventType,
  GroupOrDealName,
  InteractionType,
  MeetingType,
  MessageSource,
  MessageTypeAndStatus,
  RecordingStatus,
  WorkspaceOwnership,
  WorkspaceType,
} from "./files";

interface AddionalFilterValueProps {
  primaryValue: filterValueType;
  setPrimaryValue: React.Dispatch<React.SetStateAction<filterValueType>>;
  additionalFileNames: FilterNameType[];
}

const AddionalFilterValue: React.FC<AddionalFilterValueProps> = ({
  additionalFileNames,
  primaryValue,
  setPrimaryValue,
}) => {
  const selectedCenter = primaryValue.conditionalCentersValue;

  // Create the list of components that are filtered by the selectedCenter
  const componentsList: AdditionalFilterType[] = additionalFileNames
    .filter((file) =>
      file.relatedGroup.some((group) => selectedCenter.includes(group))
    )
    .map((file) => file.filterName);

  // Function to handle setting the filtered data back to primaryValue
  const handleUpdate = (
    filterName: AdditionalFilterType,
    updatedValue: any
  ) => {
    setPrimaryValue((prev) => ({
      ...prev,
      additionalFields: {
        ...prev.additionalFields,
        [filterName]: updatedValue,
      },
    }));
  };

  // Function to reset state data for a specific filter
  const resetStateData = (filterName: AdditionalFilterType) => {
    setPrimaryValue((prev) => ({
      ...prev,
      additionalFields: {
        ...prev.additionalFields,
        [filterName]: [], // Reset to null or default value
      },
    }));
  };

  // Cleanup mechanism to reset state on component unmount or conditionalCentersValue change
  useEffect(() => {
    // Identify filters to reset
    additionalFileNames.forEach((file) => {
      const filterName = file.filterName;
      const isGroupAvailable = file.relatedGroup.some((group) =>
        selectedCenter.includes(group)
      );

      // If the filter's group is no longer available in selectedCenter, reset its data
      if (
        !isGroupAvailable &&
        primaryValue.additionalFields[filterName] !== undefined
      ) {
        resetStateData(filterName);
      }
    });
  }, [selectedCenter, additionalFileNames,]);

  // Function to render a component dynamically based on its name
  const renderComponent = (filterName: AdditionalFilterType) => {
    const componentProps = {
      stateData: primaryValue.additionalFields[filterName],
      setStateData: (data: any) => handleUpdate(filterName, data),
    };

    switch (filterName) {
      case "meetingType":
        return <MeetingType {...componentProps} />;
      case "duration":
        return <Duration {...componentProps} />;
      case "recordingStatus":
        return <RecordingStatus {...componentProps} />;
      case "emailType":
        return <EmailType {...componentProps} />;
      case "emailSource":
        return <EmailSource {...componentProps} />;
      case "emailBasicFilters":
        return <EmailBasicFilters {...componentProps} />;
      case "eventType":
        return <EventType {...componentProps} />;
      case "eventStatus":
        return <EventStatus {...componentProps} />;
      case "bookingLink":
        return <BookingLink {...componentProps} />;
      case "messageTypeAndStatus":
        return <MessageTypeAndStatus {...componentProps} />;
      case "messageSource":
        return <MessageSource {...componentProps} />;
      case "attachements":
        return <Attachements {...componentProps} />;
      case "contactConnections":
        return <ContactConnections {...componentProps} />;
      case "interactionType":
        return <InteractionType {...componentProps} />;
      case "groupOrDealName":
        return <GroupOrDealName {...componentProps} />;
      case "employeeType":
        return <EmployeeType {...componentProps} />;
      case "employeeDepartment":
        return <EmployeeDepartment {...componentProps} />;
      case "employeeStatus":
        return <EmployeeStatus {...componentProps} />;
      case "workspaceType":
        return <WorkspaceType {...componentProps} />;
      case "workspaceOwnership":
        return <WorkspaceOwnership {...componentProps} />;
      case "cloudStorageOwnership":
        return <CloudStorageOwnership {...componentProps} />;
      case "cloudStorageType":
        return <CloudStorageType {...componentProps} />;
      case "cloudStorageSize":
        return <CloudStorageSize {...componentProps} />;
      default:
        return null;
    }
  };

  return (
    <Stack sx={{ gap: "15px" }}>
      {componentsList.map((filterName) => (
        <React.Fragment key={filterName}>
          {renderComponent(filterName)}
        </React.Fragment>
      ))}
    </Stack>
  );
};

export default AddionalFilterValue;
