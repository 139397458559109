import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  InputBase,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import {
  adjustHexColor,
  CalendarTypesEnum,
  getCalenderLogo,
} from "../../../../../Utils";
import {
  AllDirArrowCircleIcon,
  DeadlineIcon,
  UpdateIcon,
  XCircleIcon,
} from "../../../../../images";
import { EventTab, TaskTab } from "./tab";
import styles from "./InCalendarEventPopup.module.css";
import {
  ButtonSmall,
  ButtonType,
  CheckboxMedium,
  SmallButtonColor,
} from "../../../../common";
import { CalendarPicker } from "../CalendarPicker";
import { CalendarPickerOptionInterface } from "../CalendarPicker/CalendarPickerType";
import { AppointmentMenu } from "../AppointmentMenu";
import {
  CalendarState,
  createEvent__api,
  EventData, getUpcomingMeetings,
  ProposedTime,
  recallAPI,
} from "../../../../../redux";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

interface InCalendarEventPopupProps {
  handleClose?: any;
}

const InCalendarEventPopup: React.FC<InCalendarEventPopupProps> = ({
  handleClose,
}) => {
  const dispatch = useDispatch();
  const calendarState = useSelector(
    (state: RootState) => state && (state?.calendar as CalendarState)
  );

  const [createLoader, setCreateLoader] = useState<boolean>(false);

  const { allCalendarList } = calendarState || {};

  // // Function to format time in 12-hour format with zero-padded hours
  // const formatTime = (date: Date) => {
  //   let hours = date?.getHours();
  //   let minutes: any = date?.getMinutes();
  //   const ampm = hours >= 12 ? "PM" : "AM";

  //   hours = hours % 12;
  //   hours = hours ? hours : 12; // the hour '0' should be '12'

  //   // Zero-pad hours and minutes if less than 10
  //   const paddedHours = hours < 10 ? `0${hours}` : hours;
  //   const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  //   return `${paddedHours}:${paddedMinutes} ${ampm}`;
  // };

  // // Function to add hours to a date object
  // const addHours = (date: Date, hours: number) => {
  //   const newDate = new Date(date);
  //   newDate?.setHours(newDate?.getHours() + hours);
  //   return newDate;
  // };

  const [selectedCalendar, setSelectedCalendar] = useState<any>(
    CalendarTypesEnum.BearishCalendar
  );

  const [eventData, setEventData] = useState<EventData>({
    title: "",
    eventType: "appointment",
    taskOrEvent: "event",
    calendarId: allCalendarList?.[0]?._id || "",
    startTime: new Date().toISOString(), // Current date and time in ISO format
    endTime: new Date(new Date().getTime() + 15 * 60 * 1000).toISOString(), // 15 min from the current time in ISO format
    allDay: false,
    timezone: "UTC",
    guests: [],
    videoCall: {
      platform: "BearishOS",
      customLink: "",
    },
    location: "",
    showAs: "busy",
    description: "",
    repeat: {
      type: "none",
      custom: {
        interval: 1,
        repeatDays: [],
        frequency: "day",
        endDate: null,
      },
    },
    travelTime: {
      time: 0,
      direction: "before",
    },
    proposedTimes: [], // Can store up to 3 proposed times
    createdByType: "user", // Defaults to user-created event
  });

  useEffect(() => {
    const fetchTimezone = async () => {
      try {
        // Fetch geolocation data
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();

        if (data && data.timezone) {
          // Update the timezone in the state
          setEventData((prevEventData) => ({
            ...prevEventData,
            timezone: data.timezone,
          }));
        }
      } catch (error) {
        console.error("Error fetching timezone:", error);
      }
    };

    fetchTimezone();
  }, []); // Runs once on component mount

  // Update startTime and endTime when a new date is selected
  const updateDate = (selectedDate: Date) => {
    // Only update the date part, preserving the existing time.
    const updatedStartTime = new Date(selectedDate);
    const updatedEndTime = new Date(selectedDate);

    // Set the updated date for both startTime and endTime
    updateField && updateField("startTime", updatedStartTime.toISOString());
    updateField && updateField("endTime", updatedEndTime.toISOString());
  };

  // Update startTime when a new time is selected
  const updateStartTime = (formattedTime: string) => {
    // Extract the current UTC date and time from eventData.startTime without converting it to local time
    const existingDate = new Date(eventData.startTime);

    // Get the UTC components of the existing date (to avoid timezone conversions)
    const year = existingDate.getUTCFullYear();
    const month = existingDate.getUTCMonth();
    const day = existingDate.getUTCDate();

    // Parse the formattedTime to get hours and minutes
    const [hour, minute] = formattedTime
      .split(":")
      .map((timePart) => parseInt(timePart));

    // Create the new start time in UTC
    const updatedStartTime = new Date(
      Date.UTC(year, month, day, hour, minute, 0, 0)
    );

    // Set the endTime to be 15 minutes ahead of the updated startTime (also in UTC)
    const updatedEndTime = new Date(updatedStartTime);
    updatedEndTime.setMinutes(updatedEndTime.getMinutes() + 15); // Add 15 minutes to startTime

    // Update the fields (startTime and endTime) in UTC format
    if (updateField) {
      updateField("startTime", updatedStartTime.toISOString()); // Ensures the time is in UTC
      updateField("endTime", updatedEndTime.toISOString()); // Ensures the time is in UTC
    }
  }; 

  // Update endTime when a new time is selected
  const updateEndTime = (formattedTime: string) => {
    // Extract the current UTC date and time from eventData.endTime without converting it to local time
    const existingDate = new Date(eventData.endTime);

    // Get the UTC components of the existing date (to avoid timezone conversions)
    const year = existingDate.getUTCFullYear();
    const month = existingDate.getUTCMonth();
    const day = existingDate.getUTCDate();

    // Parse the formattedTime to get hours and minutes
    const [hour, minute] = formattedTime
      .split(":")
      .map((timePart) => parseInt(timePart));

    // Create the new end time in UTC
    const updatedEndTime = new Date(
      Date.UTC(year, month, day, hour, minute, 0, 0)
    );

    // Update the endTime field in UTC format
    if (updateField) {
      updateField("endTime", updatedEndTime.toISOString()); // Ensures the time is in UTC
    }
  };

  // Function to update proposed times in the event data
  const updateProposedTimes = (proposedTimes: ProposedTime[]) => {
    setEventData((prev) => ({
      ...prev,
      proposedTimes: proposedTimes.slice(0, 3), // Ensure maximum of 3 proposed times
    }));
  };

  // Function to update the state
  const updateField = (field: keyof EventData, value: any) => {
    setEventData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const [tabView, setTabView] = useState<"event" | "task">("event");
  // "#B5005B" is calendarPink, "#A53A01" is crmOrange
  const [cardColor, setCardColor] = useState<"#B5005B" | "#A53A01">("#B5005B");

  // Popover state management
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Cache adjusted colors to avoid unnecessary recalculations
  const adjustedHeaderColor = adjustHexColor(cardColor, 80);

  const tabSwich = (tabName: "event" | "task") => {
    if (tabName === "event") {
      setCardColor("#B5005B");
      setTabView(tabName);
    } else {
      setCardColor("#A53A01");
      setTabView(tabName);
    }
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const calendarPickerlist: CalendarPickerOptionInterface[] =
    allCalendarList && typeof allCalendarList === "object" // Check if it's a valid object
      ? Object?.values(allCalendarList)?.map((calendar: any) => {
          let calendarType = CalendarTypesEnum.BearishCalendar; // Default value

          if (calendar?.isExternal) {
            // If it's an external calendar, check the provider
            if (calendar?.externalCalendar?.provider === "Microsoft") {
              calendarType = CalendarTypesEnum.OutlookCalendar;
            } else if (calendar?.externalCalendar?.provider === "Google") {
              calendarType = CalendarTypesEnum.GoogleCalendar;
            } else if (calendar?.externalCalendar?.provider === "Teams") {
              calendarType = CalendarTypesEnum.TeamsCalendar;
            } else if (calendar?.externalCalendar?.provider === "Jira") {
              calendarType = CalendarTypesEnum.JiraCalendar;
            }
          }

          return {
            id: calendar?._id,
            calendarType, // Dynamically assigned calendarType
            calendarName: calendar?.name,
            _id: calendar?._id,
          };
        })
      : []; // Return an empty array if allCalendarList is not valid

  const handleCreateEventOrTask = async () => {
    try {
      if (!eventData?.title || !eventData?.calendarId) {
        return;
      }

      // Format startTime and endTime to ISO strings
      const formattedStartTime = new Date(eventData.startTime).toISOString();
      const formattedEndTime = new Date(eventData.endTime).toISOString();

      // Update eventData with formatted times
      const updatedEventData = {
        ...eventData,
        startTime: formattedStartTime,
        endTime: formattedEndTime,
      };

      setCreateLoader(true);

      // Use updatedEventData instead of eventData
      const action = createEvent__api(updatedEventData);

      const createEorTRes: any = await dispatch(action);

      if (createEorTRes?.success && handleClose) {
        dispatch(getUpcomingMeetings());
        handleClose();
        dispatch(recallAPI("getAllCalEvent"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCreateLoader(false);
    }
  };

  return (
    <Stack className={styles["in-calendar-event-popup"]}>
      {/* Card Header */}
      <Stack className={styles["in-calendar-event-popup__header"]}>
        <Stack
          className={styles["in-calendar-event-popup__header-stripe"]}
          sx={{ bgcolor: cardColor }}
        />
        <Stack
          className={styles["in-calendar-event-popup__header-content"]}
          sx={{ bgcolor: adjustedHeaderColor }}
        >
          <Stack className={styles["in-calendar-event-popup__header-title"]}>
            <Typography
              className={styles["in-calendar-event-popup__header-text"]}
            >
              {eventData?.title || "Calendar Name"}
            </Typography>
            <Box
              component={"img"}
              src={getCalenderLogo({
                calendarType: selectedCalendar,
              })}
              alt="calendar logo"
              className={styles["in-calendar-event-popup__header-logo"]}
            />
          </Stack>
          <Stack className={styles["in-calendar-event-popup__header-actions"]}>
            {tabView === "event" && (
              <IconButton
                className={styles["in-calendar-event-popup__icon-button"]}
                onClick={handlePopoverOpen} // Open popover on click
              >
                <UpdateIcon w={20} color="#fff" />
              </IconButton>
            )}

            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                style: { boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)" },
              }}
            >
              <CalendarPicker
                width="200px"
                buttonSx={{ paddingLeft: "5px" }}
                options={calendarPickerlist || []}
                onSelect={(value) => {
                  updateField("calendarId", value?._id || value?.id);
                  setSelectedCalendar(value?.calendarType as CalendarTypesEnum);
                }}
              />
            </Popover>
            <IconButton
              onClick={() => handleClose && handleClose()}
              className={styles["in-calendar-event-popup__icon-button"]}
            >
              <XCircleIcon w={20} color="#fff" />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
      {/* End of Card Header */}

      {/* Scrollable Container */}
      <Stack className={styles["in-calendar-event-popup__scrollable-body"]}>
        <Stack className={styles["in-calendar-event-popup__input-group"]}>
          <DeadlineIcon w={20} />
          <InputBase
            className={styles["in-calendar-event-popup__input"]}
            placeholder="Event title"
            value={eventData?.title}
            onChange={(e: any) => {
              updateField("title", e.target.value);
            }}
          />
        </Stack>

        <Stack className={styles["in-calendar-event-popup__button-group"]}>
          <Stack className={styles["in-calendar-event-popup__button-row"]}>
            <Stack
              onClick={() => {
                tabSwich("event");
                updateField("taskOrEvent", "event");
              }}
              className={styles["in-calendar-event-popup__button-item"]}
            >
              <CheckboxMedium boxSize={15} checked={tabView === "event"} />
              <Typography
                sx={{
                  font: "normal normal 300 15px/19px Source Serif Pro",
                  fontWeight: tabView === "event" ? 900 : 300,
                }}
              >
                Event
              </Typography>
            </Stack>
            {tabView === "event" && (
              <Stack
                className={styles["in-calendar-event-popup__appointment-menu"]}
              >
                <AllDirArrowCircleIcon w={15} direction="right" />
                <AppointmentMenu
                  onSelect={(value) => {
                    updateField(
                      "eventType",
                      value as
                        | "Appointment"
                        | "Meeting"
                        | "Reminders"
                        | "Bills/Payment"
                        | "Workshop"
                        | "Other"
                        | "Out of Office"
                    );
                  }}
                />
              </Stack>
            )}
          </Stack>

          <Stack
            onClick={() => {
              tabSwich("task");
              updateField("taskOrEvent", "task");
            }}
            className={styles["in-calendar-event-popup__button-item"]}
          >
            <CheckboxMedium boxSize={15} checked={tabView === "task"} />
            <Typography
              sx={{
                font: "normal normal 300 15px/19px Source Serif Pro",
                fontWeight: tabView === "task" ? 900 : 300,
              }}
            >
              Task
            </Typography>
          </Stack>
        </Stack>

        <Stack>
          {tabView === "event" ? (
            <EventTab
              updateStartTime={updateStartTime}
              updateEndTime={updateEndTime}
              updateDate={updateDate}
              updateProposedTimes={updateProposedTimes}
              updateField={updateField}
              eventData={eventData}
            />
          ) : (
            <TaskTab
              updateField={updateField}
              updateStartTime={updateStartTime}
              updateEndTime={updateEndTime}
              updateDate={updateDate}
            />
          )}
        </Stack>

        <Stack sx={{ flexDirection: "row", justifyContent: "end" }}>
          <ButtonSmall
            label="Save"
            onClick={() => handleCreateEventOrTask()}
            types={ButtonType.Button}
            loaderLogin={createLoader}
            colorVarient={
              tabView === "event"
                ? SmallButtonColor.CalendarMarronPink
                : SmallButtonColor.Orange
            }
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default InCalendarEventPopup;
