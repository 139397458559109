import * as React from "react";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import { Stack, Typography } from "@mui/material";
import styles from "./AvatarMedium.module.css";
import { BgColorCode } from "../AvatarLarge/AvatarLargeTypes";

// Define the props for AvatarMedium
type StyledBadgeProps = {
  showBadge: boolean;
  userName: string;
  bgColorCode: BgColorCode;
  imgSrc?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
  },
}));

const AvatarMedium: React.FC<StyledBadgeProps> = ({
  imgSrc,
  showBadge = false,
  userName = "AA",
  bgColorCode = BgColorCode.DarkBlue,
  onClick,
}) => {
  // Remove the `#` from the bgColorCode
  const sanitizedBgColorCode = bgColorCode.replace("#", "");

  // Dynamically create the class name
  const bgColorClass =
    styles[`AvatarMedium--${sanitizedBgColorCode}`] ||
    styles["AvatarMedium--default"];

  return (
    <Stack justifyContent={"center"} sx={{ cursor: "pointer" }}>
      <StyledBadge
        onClick={onClick}
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        variant={showBadge ? "dot" : "standard"}
      >
        <Avatar
          alt={userName}
          sx={{ justifyContent: "center", alignItems: "center" }}
          src={imgSrc || undefined} // Use undefined if imgSrc is null or invalid
          className={` ${bgColorClass}`}
        >
          {!imgSrc && userName} {/* Fallback initials */}
        </Avatar>
      </StyledBadge>
    </Stack>
  );
};

export default AvatarMedium;
