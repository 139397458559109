import React from "react";
import { AdditionalFilterMultiRadioBtnType } from "../../../../CommandControlSearchContainer";
import TitleContainer from "../../TitleContainer/TitleContainer";
import { MultiSelectRadioBtn } from "../../../../../../../common";

const cloudStorageSizeList: AdditionalFilterMultiRadioBtnType = {
  _id: "cloudStorageSize",
  field: ["workspace"],
  translatedTitle: "Cloud Storage Size",
  option: [
    { _id: "1", translatedTitle: "0-1 GB", value: "0-1 GB" },
    { _id: "2", translatedTitle: "1-10 GBs", value: "1-10 GBs" },
    { _id: "3", translatedTitle: "10+ GBs ", value: "10+ GBs " },
  ],
};

type ComponentDataType = string[]; // this is type for this component data
interface CloudStorageSizeProps {
  stateData: ComponentDataType; // Current data passed from the parent
  setStateData: (data: ComponentDataType) => void; // Function to update data in the parent
}

const CloudStorageSize: React.FC<CloudStorageSizeProps> = ({
  setStateData,
  stateData,
}) => {
  const handleSelectionChange = (value: string) => {
    const updatedMeetingTypes = stateData.includes(value)
      ? stateData.filter((item) => item !== value) // Remove if already selected
      : [...stateData, value]; // Add if not selected

    // Update the state in the parent
    setStateData(updatedMeetingTypes);
  };

  return (
    <TitleContainer title={cloudStorageSizeList.translatedTitle} isCollapasable>
      {cloudStorageSizeList.option.map((item, index) => (
        <MultiSelectRadioBtn
          key={item._id}
          isChecked={stateData.includes(item.value)}
          label={item.translatedTitle}
          onClick={() => handleSelectionChange(item.value)}
        />
      ))}
    </TitleContainer>
  );
};

export default CloudStorageSize;
