import { Dialog, DialogContent, IconButton, Stack, Tooltip } from "@mui/material";
import React, { useState } from "react";
import {
  ArchiveIcon,
  ClockIcon,
  DeleteIcon,
  EyeIcon,
} from "../../../../../../images";
import EmailApiAction from "../../EmailActionApi/EmailActions";
import { SnoozeEmail } from "../../SnoozeEmail";
import useEmailApiAction from "../../EmailActionApi/EmailActions";
import axios from "axios";
import { baseURL, getCurrentIp } from "../../../../../../redux/middleware/apiMiddleware";
import { getToken } from "../../../../../common/LocalStorage";

interface Email {
  id: string;
}

interface IconsGroupViewProps {
  email: Email;
  accessToken: string;
  messageId: string;
}

const IconsGroupView: React.FC<IconsGroupViewProps> = ({ email, accessToken, messageId }) => {
  const [openModal, setOpenModal] = useState(false);
  const { EmailApiAction } = useEmailApiAction();
const token = getToken();

  const handleAction = async (accessToken: string, messageId: string, actionType: 'delete' | 'archive' | 'markRead') => {
    try {
      const res = await EmailApiAction(accessToken, messageId, actionType);
      return res;
    } catch (err) {
      console.log(err);
    }
  }

  const handleSnoozeEmail = async (value: string): Promise<void> => {
    try {
      const res = await axios.put(
        `${baseURL}/email/snooze-email`,
        { messageId, snoozeTime: value },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Current-IP": await getCurrentIp(),
          }
        }
      );
      if (res?.status === 200) {
        setOpenModal(false);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const snoozeModal = () => {
    console.log("Opening modal...");
    setOpenModal(true);  
  };

  const snoozeModalClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => { 
    setOpenModal(false);  
  };

  return (
    <>
      <Stack
      className="icon-group"
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "12px",
        }}
      >
        <Tooltip title="Snooze" placement="top" arrow>
          <IconButton sx={{ p: 0 }} onClick={snoozeModal} disableRipple>
            <ClockIcon w={15} />
          </IconButton>
        </Tooltip>

        <Tooltip title="Archive" placement="top" arrow>
          <IconButton
            sx={{ p: 0 }}
            onClick={() => messageId && handleAction(accessToken, messageId, 'archive')}

            disableRipple
          >
            <ArchiveIcon w={15} />
          </IconButton>
        </Tooltip>

        <Tooltip title="Mark as Read" placement="top" arrow>
          <IconButton
            sx={{ p: 0 }}
            onClick={() => messageId && handleAction(accessToken, messageId, 'markRead')}
            disableRipple
          >
            <EyeIcon w={15} />
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete" placement="top" arrow>
          <IconButton
            sx={{ p: 0 }}
            onClick={() => messageId && handleAction(accessToken, messageId, 'delete')}
            disableRipple
          >
            <DeleteIcon w={15} />
          </IconButton>
        </Tooltip>
      </Stack >

      {/* {currentAction && (
        <EmailApiAction
          accessToken={accessToken}
          messageId={messageId}
          actionType={currentAction}
        />
      )} */}

      <Dialog
        open={openModal}
        onClose={(event, reason) => snoozeModalClose(event, reason)}
      >
        <DialogContent>
          <SnoozeEmail variant="SnoozeEmail" handleSnoozeEmail={handleSnoozeEmail} />
        </DialogContent>
      </Dialog >
    </>
  );
};

export default IconsGroupView;
