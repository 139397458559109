import { AttachmentTypeEnum } from "../../../../../../common";
import { EmailListInterface } from "../../../EmailInCRMPreview/EmailInCRMPreviewTypes";

const dummyEmails: EmailListInterface[] = [
  {
    id: "1",
    subject: "Subject Line of Email will be here",
    sender: {
      name: "Jake Michaels",
      email: "jakemichael@example.com",
      avatarUrl: "https://randomuser.me/api/portraits/men/1.jpg",
    },
    recipients: {
      to: [{ name: "John Doe", email: "johndoe@example.com" }],
      cc: [
        {
          name: "Jane Smith",
          email: "janesmith@example.com",
          avatarUrl: "https://randomuser.me/api/portraits/women/1.jpg",
        },
        { name: "Paul Walker", email: "paulwalker@example.com" },
        {
          name: "Laura Lee",
          email: "lauralee@example.com",
          avatarUrl: "https://randomuser.me/api/portraits/women/2.jpg",
        },
        { name: "Tom Brown", email: "tombrown@example.com" },
      ],
      bcc: [
        { name: "Admin", email: "admin@example.com" },
        {
          name: "Chris Evans",
          email: "chrisevans@example.com",
          avatarUrl: "https://randomuser.me/api/portraits/men/2.jpg",
        },
        { name: "Diana Prince", email: "dianaprince@example.com" },
        {
          name: "Bruce Wayne",
          email: "brucewayne@example.com",
          avatarUrl: "https://randomuser.me/api/portraits/men/3.jpg",
        },
      ],
    },
    date: "2024-12-30",
    emailContent: {
      text: `This is the text of an email that has been sent to a user in the email section of the Bearish OS CRM.
                The email can have up to 3 lines that can be seen here and can be done in this message and system. 
                These few lines will allow the people to have a bit of understanding and it will show the emails like soThis
                is the text of an email that has been sent to a user in the email section of the Bearish OS CRM.
                The email can have up to 3 lines that can be seen here and can be done in this message and system. 
                These few lines will allow the people to have a bit of understanding and it will show the emails like
                soThis is the text of an email that has been sent to a user in the email section of the Bearish OS CRM.
                The email can have up to 3 lines that can be seen here and can be done in this message and system.
                These few lines will allow the people to have a bit of understanding and it will show the emails like so.`,
      html: "<p>This is the text of an email that has been sent to a user in the email section of the Bearish OS CRM. The email can have up to 3 lines that can be seen here and can be done in this message and system. These few lines will allow the people to have a bit of understanding and it will show the emails like so.</p>",
    },
    attachments: [],
  },
  {
    id: "2",
    subject: "Meeting Schedule",
    sender: {
      name: "Alice Johnson",
      email: "alicejohnson@example.com",
      avatarUrl: "https://randomuser.me/api/portraits/women/3.jpg",
    },
    recipients: {
      to: [{ name: "Bob Brown", email: "bobbrown@example.com" }],
      cc: [
        { name: "Charlie Davis", email: "charliedavis@example.com" },
        {
          name: "David Johnson",
          email: "davidjohnson@example.com",
          avatarUrl: "https://randomuser.me/api/portraits/men/4.jpg",
        },
        { name: "Emma Wilson", email: "emmawilson@example.com" },
      ],
    },
    date: "2024-11-25",
    emailContent: {
      text: "This is a reminder for the upcoming meeting scheduled in the Bearish OS CRM.",
      html: "<p>This is a reminder for the upcoming meeting scheduled in the Bearish OS CRM.</p>",
    },
    attachments: [
    ],
  },
  {
    id: "3",
    subject: "Project Update",
    sender: {
      name: "Carol White",
      email: "carolwhite@example.com",
      avatarUrl: "https://randomuser.me/api/portraits/women/4.jpg",
    },
    recipients: {
      to: [{ name: "Dave Wilson", email: "davewilson@example.com" }],
      cc: [
        {
          name: "Gina Martinez",
          email: "ginamartinez@example.com",
          avatarUrl: "https://randomuser.me/api/portraits/women/5.jpg",
        },
        { name: "Harry Potter", email: "harrypotter@example.com" },
        {
          name: "Ivy League",
          email: "ivyleague@example.com",
          avatarUrl: "https://randomuser.me/api/portraits/women/6.jpg",
        },
      ],
      bcc: [
        { name: "Jake Peralta", email: "jakeperalta@example.com" },
        {
          name: "Kate Beckett",
          email: "katebeckett@example.com",
          avatarUrl: "https://randomuser.me/api/portraits/women/7.jpg",
        },
        { name: "Leslie Knope", email: "leslieknope@example.com" },
      ],
    },
    date: "2024-10-15",
    emailContent: {
      text: "Here is the latest update on the project. Please review the attached documents.",
      html: "<p>Here is the latest update on the project. Please review the attached documents.</p>",
    },
    attachments: [

    ],
  },
  {
    id: "4",
    subject: "Weekly Newsletter",
    sender: {
      name: "Eve Martinez",
      email: "evemartinez@example.com",
      avatarUrl: "https://randomuser.me/api/portraits/women/8.jpg",
    },
    recipients: {
      to: [{ name: "Frank Green", email: "frankgreen@example.com" }],

      bcc: [
        { name: "Newsletter Subscribers", email: "newsletter@example.com" },
        { name: "Quinn Fabray", email: "quinnfabray@example.com" },
        {
          name: "Rachel Greessssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssn",
          email: "rachelgreen@example.com",
          avatarUrl: "https://randomuser.me/api/portraits/women/10.jpg",
        },
      ],
    },
    date: "2024-09-10",
    emailContent: {
      text: "Welcome to our weekly newsletter. Here are the latest updates.",
      html: "<p>Welcome to our weekly newsletter. Here are the latest updates.</p>",
    },
    attachments: [

    ],
  },
];

// export default dummyEmails;

export {}