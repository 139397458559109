import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import {
  StrongestrelationshipIcon,
  TeamMemberIcon,
} from "../../../../../../../images";
import {
  DropdownWithImageBadge,
  DUMMY_USER_EMAIL_LIST,
} from "../../../../../../common";
import { getUserList } from "../../../../../../../Utils";

interface AddGuestViewProps {
  showOptional: boolean;
  optionalBtnClick: () => void;
  updateField?: (field: string, value: any) => void; // Ensure updateField is a function
}

const AddGuestView: React.FC<AddGuestViewProps> = ({
  showOptional,
  optionalBtnClick,
  updateField,
}) => {
  const userList: any = getUserList();

  // Function to convert user data to DropdownWithImgBadgeInterface format
  const convertUserList = () => {
    return userList?.map((user: any) => ({
      _id: user?._id,
      name: `${user?.firstName || user?.name}`, // Use name or combined first and last name for external users
      email: user?.email,
      userIconSrc: user?.userIconSrc || user?.avatar || "", // Use appropriate icon source
      firstName: user?.firstName, // Optional field
      displayName: `${user?.firstName || ""} ${user?.lastName || ""}`.trim(), // Combine names for internal users
    }));
  };

  // Call convertUserList to get the formatted options
  const formattedUserList = convertUserList();

  const handleUserSelection = (value: any) => {
    if (value && updateField) {
      const guests = value?.map((selectedUser: any) => {
        // Prepare note based on your requirements
        const note = "I'll confirm closer to the time.";

        if (selectedUser?._id === null) {
          // External user
          return {
            email: selectedUser?.email,
            isExternal: true,
            responseStatus: "maybe",
            note,
          };
        }
        // Internal user
        return {
          userId: selectedUser?._id,
          email: selectedUser?.email,
          isExternal: false,
          responseStatus: "maybe",
          note,
        };
      });

      // Update the guests field with the new payload structure
      updateField("guests", guests);
      // console.log(guests); // For debugging purposes
    }
  };

  return (
    <Stack sx={{ gap: "20px" }}>
      <Stack sx={{ flexDirection: "row", alignItems: "start", gap: "10px" }}>
        <span style={{ marginTop: "10px" }}>
          <TeamMemberIcon w={20} />
        </span>
        <DropdownWithImageBadge
          options={formattedUserList || DUMMY_USER_EMAIL_LIST}
          onChangeSelection={handleUserSelection} // Use updated handler
          showMaxSelected={4}
          placeholder="Add guests"
        />
        {!showOptional && (
          <IconButton
            sx={{ p: 0, borderRadius: 0, color: "black", mt: "10px" }}
            onClick={optionalBtnClick}
          >
            <Typography
              sx={{
                font: "normal normal 300 15px/19px Source Serif Pro",
                color: "#B5005B",
              }}
            >
              Optional
            </Typography>
          </IconButton>
        )}
      </Stack>
      {showOptional && (
        <Stack sx={{ flexDirection: "row", alignItems: "start", gap: "10px" }}>
          <span style={{ marginTop: "10px" }}>
            <StrongestrelationshipIcon w={20} />
          </span>
          <DropdownWithImageBadge
            options={formattedUserList || DUMMY_USER_EMAIL_LIST}
            onChangeSelection={handleUserSelection} // Use updated handler
            placeholder="Add guests"
          />
        </Stack>
      )}
    </Stack>
  );
};

export default AddGuestView;
