import { Stack } from "@mui/material";
import React from "react";
import { EventIconButton } from "../../../../../../common";
import { DoneIcon, EyeIcon } from "../../../../../../../images";
import { SnoozeButton } from "../../../../../Message/components";
import { useTranslation } from "react-i18next";

interface ButtonGroupThreeViewProps {
    onButtonClick: (eventId: number,snoozeDuration:string) => void; // Update this prop
}

const ButtonGroupThreeView: React.FC<ButtonGroupThreeViewProps> = ({onButtonClick}) => {
  const { t } = useTranslation();
    const handleClick = (eventId:any,snoozeDuration:any) => {
        onButtonClick(eventId,snoozeDuration || "0"); // Pass the unique ID
    };
  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
      <EventIconButton
        buttonTitle={t("CALENDAR.VIEW")}
        icon={EyeIcon}
        iconColor="#000"
        onClick={() => handleClick(5, "view")} // Add onClick handler
      />
      <EventIconButton
        buttonTitle={t("MESSAGE.DONE")}
        icon={DoneIcon}
        iconColor="#008D4C"
        onClick={() => handleClick(6, "done")} // Add onClick
      />
      <SnoozeButton onSelect={(value:any) =>handleClick(7,value)} />
    </Stack>
  );
};

export default ButtonGroupThreeView;
