import React from "react";
import { AttendanceStatusType } from "../../../CrmStatusBadge";
import { BreakListInterface } from "../../../TimeCardTable";
import NoWorkCard from "../NoWorkCard/NoWorkCard";
import TimeDetailsCard from "../TimeDetailsCard/TimeDetailsCard";

interface AttendanceCardProps {
  workType: AttendanceStatusType;
  workStartTime?: string;
  workStopTime?: string;
  breakList?: BreakListInterface[];
  overtime?: BreakListInterface;
}

const AttendanceCard: React.FC<AttendanceCardProps> = ({
  workType,
  overtime,
  workStartTime,
  workStopTime,
  breakList,
}) => {
  const getCard = (): React.ReactNode => {
    switch (workType) {
      case "absent":
        return <NoWorkCard label="Absant" />;
      case "dayoff":
        return <NoWorkCard label="Day Off" />;
      case "holiday":
        return <NoWorkCard label="Holiday" />;
      case "pto":
        return <NoWorkCard label="Pto" />;
      case "sick":
        return <NoWorkCard label="Sick" />;
      case "late":
      case "onTime":
        return (
          <TimeDetailsCard
            workType={workType}
            breakList={breakList}
            overtime={overtime}
            workStartTime={workStartTime}
            workStopTime={workStopTime}
          />
        );
    }
  };

  return <>{getCard()}</>;
};

export default AttendanceCard;
