import {
  Box,
  Divider,
  IconButton,
  Modal,
  Popover,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  AllDirArrowsIcon,
  ClockIcon,
  CreatedIcon,
  DeleteIcon,
  FlagIcon,
  ForwardIcon,
  ReplyIcon,
} from "../../../../../images";
import { EmailClickedMenu } from "../EmailClickedMenu";
import { EmailPageController } from "../EmailPageController";
import { EmailPreviewArea } from "../EmailPreviewArea";
import { SnoozeEmail } from "../SnoozeEmail";
import { useNavigate, useParams } from "react-router-dom";
import {
  CustomRadioFormControlLabel,
  EmailSmallerPopout,
} from "../../../../common";
import axios from "axios";
import { baseURL, getCurrentIp } from "../../../../../redux/middleware/apiMiddleware";
import useEmailApiAction from "../EmailActionApi/EmailActions";
import { PrioritylevelView } from "../CreateSmartFilter/view";
import { getAccessToken, getToken } from "../../../../common/LocalStorage";
import { RootState } from "../../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";

const style = {
  position: "absolute" as "absolute",
  maxWidth: "50%",
  minWidth: "50%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
};

const usePopover = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return { anchorEl, open, handleOpen, handleClose };
};

interface InsideEmailTopBarProps {
  infoButton?: any;
}

interface EmailAddress {
  name: string;
  address: string;
}

interface Recipient {
  emailAddress: EmailAddress;
}

interface EmailBody {
  contentType: string;
  content: string;
}

interface Sender {
  emailAddress: EmailAddress;
}

interface Flag {
  flagStatus: string;
}

interface Email {
  id: string;
  priority: string;
  parentFolderId: string;
  createdDateTime: string; // ISO date string
  categories: string[]; // Array of categories
  hasAttachments: boolean;
  subject: string;
  bodyPreview: string;
  isDeliveryReceiptRequested: boolean | null;
  isReadReceiptRequested: boolean;
  isRead: boolean;
  isDraft: boolean;
  webLink: string;
  body: EmailBody;
  sender: Sender;
  from: Sender; // Same structure as sender
  toRecipients: Recipient[];
  ccRecipients: Recipient[];
  bccRecipients: Recipient[];
  replyTo: Recipient[];
  flag: Flag;
}
const InsideEmailTopBar: React.FC<InsideEmailTopBarProps> = ({
  infoButton,
}) => {
  const navigate = useNavigate();
  const snoozePopover = usePopover();
  const flagPopover = usePopover();
  const { emailId, emailIndex } = useParams();
  const [priorityLevel, setPriorityLevel] = useState("");
  const [open, setOpen] = React.useState(false);
  const [popupType, setPopupType] = React.useState<
    "email" | "compose" | "reply" | "forward" | "self-compose" | null
  >(null);

  const handleOpenBody = async (index: number) => {
    if (emailsList && emailsList.length > index) {
      const emailId = emailsList[index - 1]?.id;

      if (emailId) {
        navigate(`/Email/${emailId}/${index + 1}`);
      } else {
        console.warn(`Email ID not found at index ${index}`);
      }
    } else {
      console.warn(`Index ${index} is out of bounds for emailsList`);
    }
  };

  const emailsList = useSelector(
    (state: RootState) => state && state?.emails?.emails?.data?.emails
  );

  const [emailData, setData] = useState<Email>({
    id: "",
    priority: "",
    parentFolderId: "",
    createdDateTime: "",
    categories: [], // Should be an array of strings
    hasAttachments: false, // Should be a boolean
    subject: "",
    bodyPreview: "",
    isDeliveryReceiptRequested: null, // Can be null or boolean
    isReadReceiptRequested: false, // Should be a boolean
    isRead: false, // Should be a boolean
    isDraft: false, // Should be a boolean
    webLink: "",
    body: {
      contentType: "", // Initialize with empty string
      content: "", // Initialize with empty string
    },
    sender: {
      emailAddress: {
        name: "", // Initialize with empty string
        address: "", // Initialize with empty string
      },
    },
    from: {
      emailAddress: {
        name: "", // Initialize with empty string
        address: "", // Initialize with empty string
      },
    },
    toRecipients: [], // Should be an array of Recipient
    ccRecipients: [], // Should be an array of Recipient
    bccRecipients: [], // Should be an array of Recipient
    replyTo: [], // Should be an array of Recipient
    flag: {
      flagStatus: "notFlagged", // Initialize with a default value
    },
  });
  const { EmailApiAction } = useEmailApiAction();

  const accessToken = getAccessToken();
  const token = getToken();

  const handleNavigate = () => {
    navigate(`/Email/`);
  };
  const handleOpen = (e: any) => {
    setPopupType(e);
    setOpen(true);
  };
  const handleClose = () => {
    setPopupType(null);
    setOpen(false);
  };

  const handleSnoozeEmail = async (value: string): Promise<void> => {
    try {
      const res = await axios.put(
        `${baseURL}/email/snooze-email`,
        { messageId: emailId, snoozeTime: value },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Current-IP": await getCurrentIp(),
          },
        }
      );
      if (res?.status === 200) {
        // handleNavigate();
        // You can navigate after success if needed
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleGetMail = async () => {
    try {
      const res = await axios.post(
        `${baseURL}/email/get-one-email`,
        { messageId: emailId, accessToken },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Current-IP": await getCurrentIp(),
          },
        }
      );

      if (res?.status === 200) {
        setData(res?.data?.data);
      }
      return res;
    } catch (err) {
      console.log(err);
    }
  };

  const handleAPi = async (
    accessToken: string,
    emailId: string,
    actionType: "delete" | "archive" | "markRead"
  ) => {
    const messageId = emailId;
    try {
      const res = await EmailApiAction(accessToken, messageId, actionType);
      if (res?.status === 200) {
        handleNavigate();
      }
      return res;
    } catch (err) {
      console.log(err);
    }
  };

  const handleSetPriorityLevel = async (value: string) => {
    try {
      const res = await axios.put(
        `${baseURL}/email/set-email-priority`,
        { messageId: emailId, accessToken, priority: value },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Current-IP": await getCurrentIp(),
          },
        }
      );
      if (res?.status === 200) {
        handleGetMail();
      }
      return res;
    } catch (err) {
      console.log(err);
    }
  };

  const handleRadioChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = (event.target as HTMLInputElement).value;
    if (value !== priorityLevel) {
      setPriorityLevel(value);
      await handleSetPriorityLevel(value);
    }
  };

  useEffect(() => {
    handleGetMail();
  }, [emailId]);

  // useEffect(() => {
  //    if(emailData) {
  //     setPriorityLevel(`${emailData?.priority}`);
  //    }
  // }, [emailData])

  const flagColor = () => {
    switch (priorityLevel) {
      case "1":
        return "#90EE90";
      case "2":
        return "#FFD700";
      case "3":
        return "#FF0000";
      default:
        return "#FF0000";
    }
  };

  return (
    <Stack
      padding={"5px"}
      sx={{ width: "100%", height: "100%", bgcolor: "#ffffff87" }}
    >
      {/* --- upper side buttons here */}
      <Stack
        sx={{
          height: "45px",
          borderTop: "1px solid #E9EDF2",
          borderBottom: "1px solid #E9EDF2",
          flexDirection: "row",
          alignItems: "center",
          boxSizing: "border-box",
          bgcolor: "#ffffff00",
          paddingLeft: "5px",
          justifyContent: "space-between",
          flexShrink: 0,
        }}
      >
        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
          <Tooltip title="Back" arrow placement="top">
            <IconButton
              onClick={() => handleNavigate()}
              sx={{ p: "2.5px" }}
              disableRipple
            >
              <AllDirArrowsIcon direction="left" w={15} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Compose a new email" arrow placement="top">
            <IconButton
              onClick={() => handleOpen("self-compose")}
              sx={{ p: "2.5px" }}
              disableRipple
            >
              <CreatedIcon w={15} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Priority" arrow placement="top">
            <IconButton
              sx={{ p: "2.5px" }}
              onClick={flagPopover.handleOpen}
              disableRipple
            >
              <FlagIcon w={15} color={flagColor()} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete" arrow placement="top">
            <IconButton
              sx={{ p: "2.5px" }}
              onClick={() =>
                emailId && handleAPi(accessToken, emailId, "delete")
              }
              disableRipple
            >
              <DeleteIcon w={15} color="black" />
            </IconButton>
          </Tooltip>

          <Divider
            orientation="vertical"
            sx={{ borderColor: "#E9EDF2", padding: "10px 0px" }}
          />

          <Tooltip title="Reply" arrow placement="top">
            <IconButton
              onClick={() => handleOpen("reply")}
              sx={{ p: "2.5px" }}
              disableRipple
            >
              <ForwardIcon w={15} />
            </IconButton>
          </Tooltip>

          <>
            <Tooltip title="Snooze" arrow placement="top">
              <IconButton
                sx={{ p: "2.5px" }}
                disableRipple
                onClick={snoozePopover.handleOpen}
              >
                <ClockIcon w={15} />
              </IconButton>
            </Tooltip>

            {/* Priority lvel popup */}
            <Popover
              id={flagPopover.open ? "flag-popover" : undefined}
              open={flagPopover.open}
              anchorEl={flagPopover.anchorEl}
              onClose={flagPopover.handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                style: {
                  marginTop: "12px",
                  boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
                },
              }}
            >
              <Stack sx={{ gap: "15px", marginTop: "10px", padding: "10px" }}>
                <RadioGroup
                  aria-labelledby="subjectKeyword-radio-buttons-group"
                  name="subjectKeyword-radio-buttons-group"
                  value={priorityLevel}
                  onChange={(e) => handleRadioChange(e)}
                  sx={{ p: 0, marginTop: "5px", gap: "13px" }}
                >
                  <CustomRadioFormControlLabel value={"3"} label="Highest" />
                  <CustomRadioFormControlLabel value={"2"} label="Medium" />
                  <CustomRadioFormControlLabel value={"1"} label="Lowest" />
                </RadioGroup>
              </Stack>
            </Popover>

            {/* Priority lvel popup end */}

            <Popover
              id={snoozePopover.open ? "snooze-popover" : undefined}
              open={snoozePopover.open}
              anchorEl={snoozePopover.anchorEl}
              onClose={snoozePopover.handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                style: {
                  marginTop: "12px",
                  boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
                },
              }}
            >
              <SnoozeEmail
                variant="SnoozeEmail"
                handleSnoozeEmail={handleSnoozeEmail}
              />
            </Popover>
          </>

          <Tooltip title="Forward" arrow placement="top">
            <IconButton
              onClick={() => handleOpen("forward")}
              sx={{ p: "2.5px" }}
              disableRipple
            >
              <ReplyIcon w={15} />
            </IconButton>
          </Tooltip>
        </Stack>

        <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "15px" }}>
          <EmailPageController
            totalPage={emailsList?.length || 0}
            currentPage={Number(emailIndex) || 0}
            onChange={(pageNum) => {
              handleOpenBody(pageNum);
            }}
          />
          <EmailClickedMenu tooltipText="Menu" displayOption="left" />
        </Stack>
      </Stack>

      {/* --------- Email Preview here --------- */}
      <div style={{ marginTop: "30px" }} />
      <EmailPreviewArea infoButton={infoButton} emailData={emailData} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <EmailSmallerPopout
            popupType={popupType}
            emailData={emailData}
            onCloseClick={() => handleClose()}
          />
        </Box>
      </Modal>
    </Stack>
  );
};

export default InsideEmailTopBar;
