import React, { useState, MouseEvent } from "react";
import {
    IconButton,
    Popover,
    Box,
    SxProps,
    Tooltip,
    Typography,
} from "@mui/material";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { SmilyFaceIcon } from "../../../images";

interface EmojiSkin {
    unified: string;
    native: string;
    src?: string;
    sheetX?: number;
    sheetY?: number;
}

interface EmojiData {
    id: string;
    name: string;
    native: string;
    unified: string;
    shortcodes?: string;
    keywords: string[];
    skins?: EmojiSkin[];
}

interface EmojiCategory {
    id: string;
    name: string;
    emojis: string[];
}

interface EmojiMartData {
    emojis: Record<string, EmojiData>;
    categories: EmojiCategory[];
}

interface SelectedEmoji extends EmojiData {
    shortcodes: string;
}

interface EmojiPickerSmallButtonProps {
    onSelect: (selectedEmoji: SelectedEmoji) => void;
    showEmojiOnSelect?: boolean;
    componentIcon?: string | React.ElementType;
    w?: number;
    sx?: SxProps;
    tooltipTitle?: string;
    transformOrigin?: any;
    labeltext?: string;
    labelSx?: SxProps;
}

const emojiData: EmojiMartData = data as EmojiMartData;

const EmojiPickerSmallButton: React.FC<EmojiPickerSmallButtonProps> = ({
                                                               onSelect,
                                                               showEmojiOnSelect = false,
                                                               componentIcon,
                                                               w = 15,
                                                               tooltipTitle,
                                                               sx = {},
                                                               transformOrigin,
                                                               labelSx,
                                                               labeltext,
                                                           }) => {
    const [anchorElAddIcon, setAnchorElAddIcon] =
        useState<HTMLButtonElement | null>(null);
    const [selectedEmoji, setSelectedEmoji] = useState<SelectedEmoji | null>(
        null
    );

    const handleOpenAddIcon = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorElAddIcon(event.currentTarget);
    };

    const openAddIcon = Boolean(anchorElAddIcon);

    const handleCloseAddIcon = () => {
        setAnchorElAddIcon(null);
    };

    const handleEmojiSelect = (emoji: SelectedEmoji) => {
        setSelectedEmoji(emoji);
        onSelect(emoji);
        handleCloseAddIcon();
    };

    const renderIcon = () => {
        if (showEmojiOnSelect && selectedEmoji) {
            return <Box fontSize={w}>{selectedEmoji.native}</Box>;
        } else if (typeof componentIcon === "string") {
            return <Box fontSize={w}>{componentIcon}</Box>;
        } else if (componentIcon) {
            return React.createElement(componentIcon, { w });
        } else {
            return <SmilyFaceIcon w={w} />;
        }
    };

    const IconButtonComponent = (
        <IconButton
            disableRipple
            sx={{
                display: "flex", // Ensures column layout
                flexDirection: "column", // Stack icon and text vertically
                alignItems: "center",
                justifyContent: "center",
                padding: 0,
                backgroundColor: "transparent", // Removes button background
                boxShadow: "none",
                borderRadius: "0", // Matches square/flat edges
                color: "inherit",
                ...sx, // Allows additional styles to be passed
            }}
            onClick={handleOpenAddIcon}
        >
            {/* Renders the icon */}
            {renderIcon && (
                <Box
                    sx={{
                        width: "24px", // Adjust icon size
                        height: "24px",
                        marginBottom: "4px", // Space between icon and text
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {renderIcon()}
                </Box>
            )}

            {/* Renders the label text */}
            {labeltext && (
                    <Typography
                        sx={{
                            fontFamily: "Source Serif Pro, serif",
                            fontWeight: 300, // Light font
                            fontSize: "14px", // Text size
                            lineHeight: "18px", // Matches proportional spacing
                            textAlign: "center",
                        }}
                    >
                        {labeltext}
                    </Typography>
            )}
        </IconButton>
    );

    return (
        <>
            <Tooltip title={tooltipTitle} placement="top" arrow>
                {IconButtonComponent}
            </Tooltip>
            <Popover
                open={openAddIcon}
                anchorEl={anchorElAddIcon}
                onClose={handleCloseAddIcon}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: transformOrigin ? transformOrigin?.vertical : "bottom",
                    horizontal: transformOrigin ? transformOrigin?.horizontal : "right",
                }}
                sx={{
                    ".MuiPopover-paper": {
                        width: "352px",
                        padding: "0px",
                        borderRadius: "5px",
                        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
                    },
                }}
            >
                <Picker
                    previewPosition={"none"}
                    theme={"dark"}
                    data={emojiData}
                    onEmojiSelect={handleEmojiSelect}
                />
            </Popover>
        </>
    );
};

export default EmojiPickerSmallButton;
