import React from "react";
import { Box } from "@mui/material";
import { CloudStorageFileCompanyTypeEnum } from "./CloudStorageFileType";
import { getFileIcon } from "../../../Utils";
import { getPlateform } from "../../../Utils/csIcon";

interface CloudStorageFileIconProps {
  fileName: string;
  plateform: CloudStorageFileCompanyTypeEnum;
}

// height and width is 32px fixed
const CloudStorageFileIcon: React.FC<CloudStorageFileIconProps> = ({
  plateform,
  fileName,
}) => {
  return (
    <Box
      sx={{
        height: "32px",
        width: "32px",
      }}
    >
      {getFileIcon({ name: fileName, iconGroup: "group2", iconSize: 25 })}
      <Box
        component={"img"}
        src={getPlateform(plateform)}
        alt="logo"
        sx={{
          width: "20px",
          height: "20px",
          position: "relative",
          bottom: "14px",
          left: "14px",
        }}
      />
    </Box>
  );
};

export default CloudStorageFileIcon;
