import React from "react";
import { InputBase, Stack } from "@mui/material";
import { EventTimeView, HeaderView } from "./view";
import {
  DeadlineIcon,
  TeamMemberIcon,
  VideoCallFilledIcon,
} from "../../../../../images";
import {
  ButtonSmall,
  DropdownWithImageBadge,
  DUMMY_USER_EMAIL_LIST,
  SmallButtonColor,
} from "../../../../common";
interface PostCallEventHoverProps {}

const PostCallEventHover: React.FC<PostCallEventHoverProps> = ({}) => {
  return (
    <Stack sx={{ width: "100%" }}>
      <HeaderView titleText="event name" />

      <Stack
        sx={{ boxSizing: "border-box", padding: "20px 25px", gap: "25px" }}
      >
        <EventTimeView />

        <Stack sx={{ flexDirection: "row", alignItems: "start", gap: "10px" }}>
          <span style={{ marginTop: "6px" }}>
            <TeamMemberIcon w={20} />
          </span>
          <DropdownWithImageBadge
            options={DUMMY_USER_EMAIL_LIST}
            initialData={DUMMY_USER_EMAIL_LIST.slice(3, 10)}
            onChangeSelection={() => null}
            showMaxSelected={4}
            readonlyComponent
            placeholder="Add guests"
          />
        </Stack>

        <Stack
          sx={{
            flexDirection: "row",
            gap: "15px",
            justifyContent: "space-between",
          }}
        >
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
              flex: 1,
            }}
          >
            <VideoCallFilledIcon />
            <ButtonSmall
              label="Go to log"
              colorVarient={SmallButtonColor.Unbilled}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default PostCallEventHover;
