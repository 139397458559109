import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Stack, Typography, Zoom } from "@mui/material";
import {
  cookieDomain,
  getContrastingColor,
  getUser,
  isAuthenticated,
} from "../../../Utils";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";
import {
  CommandControlDashboard,
  CommandControlDashboardCardVariants,
  CommandControlSearchContainer,
  DashboardCardHoverPopup,
  DashboardSidebarCalendar,
  TodayShiftDashboard,
} from "./components";
import { GlobalState } from "../../../redux/Slice/Global/globalTypes";
import { reLoginUserDetails } from "../../../redux/Slice/auth/authActions";
import { getCoverImages__api } from "../../../redux/Slice/Global/globalActions";
import { clearAllStorage } from "../../../redux/middleware/apiMiddleware";
import { DUMMY_SIDEBAR_CALENDAR_LIST } from "../Calendar/components";
import { WelcomeWalkthrough } from "../../common";
import {getAllEventsAndTasksForDashboard__api} from "../../../redux/Slice/dashboard/dashboardActions";

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const authState = useSelector((state: RootState) => state && state.auth);

  const [showWelcome, setShowWelcome] = useState(() => {
    const showWelcomeLocalStorage = localStorage.getItem("WelcomeWalkthrough");
    return !showWelcomeLocalStorage || showWelcomeLocalStorage === "true";
  });

  const auth = useMemo(() => isAuthenticated(), []);
  const user = useMemo(() => getUser(authState), [authState]);
  const [textColor, setTextColor] = useState<"black" | "white">("black");
  const token = useMemo(() => localStorage.getItem("token"), []);
  const userId = useMemo(() => localStorage.getItem("user_id"), []);
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState<{
    cardType: CommandControlDashboardCardVariants;
    data: any;
  } | null>(null);
  const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout | null>(null);
  const { coverImages } = useSelector(
    (state: RootState) => state.global as GlobalState
  );
  const [showSearchContainer, setShowSearchContainer] = useState(false);

  const userFirstName = useMemo(() => {
    if (!auth || !user?.data) return "";
    const { firstName = "", lastName = "" } = user?.data;
    return `${firstName} ${lastName}`?.trim();
  }, [auth, user]);

  const handleReLogin = useCallback(() => {
    if (!authState?.isAuthenticated && !authState?.user && token && userId) {
      try {
        dispatch(reLoginUserDetails(userId));
      } catch (error) {
        console.error("Error during re-login:", error);
      }
    }
  }, [authState, token, userId, dispatch]);

  useEffect(() => {
    handleReLogin();
  }, [handleReLogin]);

  useEffect(() => {
    (async () => {
      await dispatch(getCoverImages__api());
      await  dispatch(getAllEventsAndTasksForDashboard__api());
    })();
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.get("f") === "1") {
      clearAllStorage();

      const redirectUrl = `http://os${
        cookieDomain === ".localtest.me" ? ".localtest.me:3000" : cookieDomain
      }/login?f=success`;

      window.location.replace(redirectUrl);
    }
  }, [location]);

  const dummyCommandControlData = [
    { cardType: "task", data: { id: 1, title: "Task 1" } },
    { cardType: "taskDone", data: { id: 2, title: "Task Done 1" } },
    { cardType: "overdueTask", data: { id: 3, title: "Overdue Task 1" } },
    { cardType: "message", data: { id: 4, title: "Message 1" } },
    { cardType: "flag", data: { id: 5, title: "Flag 1" } },
    { cardType: "chat", data: { id: 6, title: "Chat 1" } },
    { cardType: "pendingEvent", data: { id: 7, title: "Pending Event 1" } },
    { cardType: "upcomingEvent", data: { id: 8, title: "Upcoming Event 1" } },
    { cardType: "reminderEvent", data: { id: 9, title: "Reminder Event 1" } },
    { cardType: "call", data: { id: 10, title: "Call 1" } },
  ];

  const handleMouseEnter = (cardType: any, data: any) => {
    const timeout = setTimeout(() => {
      setPopupData({ cardType, data });
      setShowPopup(true);
    }, 2000);
    setHoverTimeout(timeout);
  };

  const handleMouseLeave = () => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      setHoverTimeout(null);
    }
  };

  const handleBackClick = () => {
    setShowPopup(false);
    setPopupData(null);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        (event.ctrlKey && event.key === " ") ||
        (event.metaKey && event.key === " ") ||
        (event.ctrlKey && event.key === "s") ||
        (event.metaKey && event.key === "s")
      ) {
        event.preventDefault();
        setShowSearchContainer((prev: any) => !prev);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

    useEffect(() => {
        const updateTextColor = async () => {
            if (coverImages?.dashboardCoverImageUrl) {
                const color = await getContrastingColor(
                    coverImages.dashboardCoverImageUrl
                );
                setTextColor(color);
            } else {
                setTextColor("black");
            }
        };

    updateTextColor();
  }, [coverImages?.dashboardCoverImageUrl]);

    return (
        <Box
            sx={{
                width: "100%",
                overflow: "hidden",
                backgroundImage: `url(${coverImages?.dashboardCoverImageUrl || ""})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "92vh",
            }}
        >
            <Stack
                sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    height: "100%",
                    gap: "5px",
                }}
            >
                <Stack
                    sx={{
                        flexDirection: "row",
                        gap: "45px",
                        paddingLeft: "15px",
                    }}
                >
                    <Stack sx={{gap: "15px", overflow: "hidden"}}>
                        <Typography
                            sx={{
                                font: "normal normal 900 40px/50px Source Serif Pro",
                                wordWrap: "break-word",
                                color: textColor,
                                marginTop: "25px",
                                padding:"10px",
                                paddingBottom:"0px !important"
                            }}
                        >
                            {t("DASHBOARD.GOOD_MORNING")}
                        </Typography>
                        <Stack
                            sx={{
                                height: "calc(100vh - 200px)",
                                overflow: "auto",
                                scrollbarWidth: "none",
                                padding:"10px"
                            }}
                        >
                            {dummyCommandControlData.length > 0 &&
                                dummyCommandControlData?.map((item, index) => (
                                    <div
                                        key={index}
                                        onMouseEnter={() =>
                                            handleMouseEnter(item.cardType, item.data)
                                        }
                                        onMouseLeave={handleMouseLeave}
                                        style={{cursor: "pointer"}}
                                    >
                                        <CommandControlDashboard cardType={item.cardType}/>
                                    </div>
                                ))}
                        </Stack>
                    </Stack>
                    <Stack sx={{marginTop: "90px"}}>
                        {showPopup && popupData && (
                            <DashboardCardHoverPopup
                                cardType={popupData.cardType}
                                data={popupData.data}
                                handleOnBackClick={handleBackClick}
                            />
                        )}
                    </Stack>
                </Stack>
                <Stack sx={{height: "100%"}}>
                    <Stack sx={{height: "calc(100% - 280px)"}}>
                        <DashboardSidebarCalendar
                            dataList={DUMMY_SIDEBAR_CALENDAR_LIST}
                            textColor={textColor}
                        />
                    </Stack>
                    <TodayShiftDashboard />
                </Stack>

                <Stack position="absolute" bottom="30px" right="30px">
                    <Zoom
                        in={showWelcome}
                        style={{transitionDelay: showWelcome ? "500ms" : "0ms"}}
                    >
                        <div>
                            <WelcomeWalkthrough
                                setShowWelcome={setShowWelcome}
                                userFirstName={userFirstName}
                            />
                        </div>
                    </Zoom>
                </Stack>
                {showSearchContainer && (
                    <Box
                        sx={{
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            zIndex: 1000,
                        }}
                    >
                        <CommandControlSearchContainer/>
                    </Box>
                )}
            </Stack>
        </Box>
    );
};

export default Dashboard;
