import * as React from "react";
import {
  Box,
  Popper,
  ClickAwayListener,
  InputBase,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { CalendarIcon } from "../../../images";
import advancedFormat from "dayjs/plugin/advancedFormat"; // for the 'Do' format

dayjs.extend(advancedFormat);

interface SingleDatePickerSmallProps {
  label?: string;
  initialDate?: string;
  onSelect?: (date: string | null) => void; // Updated to accept a string
}

const SingleDatePickerSmall: React.FC<SingleDatePickerSmallProps> = ({
  initialDate,
  onSelect,
  label,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(
    initialDate ? dayjs(initialDate) : null // Initialize with initialDate if provided
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "single-date-picker-popper" : undefined;

  const formatDate = selectedDate ? selectedDate?.format("MMM D, YYYY") : "";

  const handleDateChange = (newValue: Dayjs | null) => {
    setSelectedDate(newValue);
    setAnchorEl(null);
    handleSelectedDate(newValue);
  };

  // this function call when date is selected
  const handleSelectedDate = (date: Dayjs | null) => {
    if (!date) return;
    if (onSelect && date) {
      onSelect(date.toISOString()); // Pass the ISO string
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Stack
          sx={{
            cursor: "pointer",
            position: "relative",
            boxSizing: "border-box",
            maxWidth: "fit-content",
          }}
        >
          {label && (
            <Typography
              sx={{
                font: "normal normal 300 10px/13px Source Serif Pro",
                marginBottom: "5px",
              }}
            >
              {label}
            </Typography>
          )}
          <InputBase
            inputProps={{
              style: { cursor: "pointer" },
            }}
            sx={{
              borderRadius: "5px",
              height: "25px",
              width: "125px",
              border: "0.5px solid #E9EDF2",
              boxSizing: "border-box",
              font: "normal normal 300 12px/16px Source Serif Pro",
              paddingLeft: "8px",
              cursor: "pointer",
            }}
            endAdornment={
              <InputAdornment
                position="end"
                sx={{
                  marginLeft: "auto",
                  padding: "0px 5px 0px 0px",
                }}
              >
                <CalendarIcon w={15} />
              </InputAdornment>
            }
            onClick={handleClick}
            value={formatDate}
            placeholder="Select date"
            readOnly
          />

          <Popper
            id={id}
            container={
              document && document?.getElementById("objectivedatepicker")
            }
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
            style={{ zIndex: 1 }}
          >
            <Box
              sx={{
                bgcolor: "background.paper",
                boxShadow: 1,
                cursor: "pointer",
                mt: 1,
              }}
            >
              <DateCalendar value={selectedDate} onChange={handleDateChange} />
            </Box>
          </Popper>
        </Stack>
      </ClickAwayListener>
    </LocalizationProvider>
  );
};

export default SingleDatePickerSmall;
