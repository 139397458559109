import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { AllDirArrowCircleIcon } from "../../../images";
import { getFileIcon } from "../../../Utils";
import styles from "./TraxDocumentPreviewCard.module.css";

interface TraxDocumentPreviewCardProps {}

const TraxDocumentPreviewCard: React.FC<
  TraxDocumentPreviewCardProps
> = ({}) => {
  return (
    <Button className={styles.traxDocumentPreviewCard}>
      <Stack className={styles.traxDocumentPreviewCard__content}>
        {getFileIcon({ name: "file.pdf", iconSize: 25 })}
        <Stack className={styles.traxDocumentPreviewCard__textWrapper}>
          <Typography className={styles.traxDocumentPreviewCard__fileName}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
            accusantium nam exercitationem unde dolorem repudiandae.
          </Typography>

          <Typography className={styles.traxDocumentPreviewCard__date}>
            01-23-2023
          </Typography>
        </Stack>
      </Stack>

      <AllDirArrowCircleIcon direction="right" color="#0027FF" w={20} />
    </Button>
  );
};

export default TraxDocumentPreviewCard;
