import { IconButton, Popover } from "@mui/material";
import React from "react";
import { DealStageDropdown } from "../DealStageDropdown";

interface CurrencyDropdownProps {}

const CurrencyDropdown: React.FC<CurrencyDropdownProps> = ({}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "currency-popover" : undefined;
  return (
    <div>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          font: "normal normal 900 12px/15px Source Serif Pro",
          color: "black",
          p: "0px 5px",
          borderRadius: 0,
        }}
        disableRipple
      >
        USD
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            padding: 0,
          },
        }}
      >
        <DealStageDropdown onChange={() => null} />
      </Popover>
    </div>
  );
};

export default CurrencyDropdown;
