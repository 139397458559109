import { Stack, Typography } from "@mui/material";
import React from "react";

interface ChildContainerProps {
  labelBoxColor: string;
  labelTitle?: string;
  boxHeight?: string;
  children: React.ReactNode;
}

const ChildContainer: React.FC<ChildContainerProps> = ({
  children,
  labelBoxColor,
  boxHeight = "570px",
  labelTitle,
}) => {
  return (
    <Stack sx={{ height: boxHeight, borderRadius: "5px" }}>
      <Stack
        sx={{
          height: "38px",
          flexShrink: 0,
          bgcolor: labelBoxColor,
          borderRadius: "5px 5px 0px 0px",
          boxSizing: "border-box",
          flexDirection: "row",
          alignItems: "center",
          padding: "0px 10px",
        }}
      >
        <Typography
          sx={{
            font: "normal normal 600 13px/16px Source Serif Pro",
            color: "white",
          }}
        >
          {labelTitle}
        </Typography>
      </Stack>
      <Stack
        sx={{
          height: "calc(100% - 40px)",
          borderRadius: "5px 5px 0px 0px",
          position: "relative",
          bottom: "3px",
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
};

export default ChildContainer;
