import { TextLabelDropdownOptionsType } from "../../common"

export type PayFrequencyMode =
    | 'daily'
    | 'weekly'
    | 'biWeekly'
    | 'monthly'
    | 'biMonthly'
    | 'quarterly'

export const PayFrequencyMenuOptions: TextLabelDropdownOptionsType<PayFrequencyMode>[] = [
    { _id: '1', label: "Daily", value: "daily" },
    { _id: '2', label: "Weekly", value: "weekly" },
    { _id: '3', label: "Bi-Weekly", value: "biWeekly" },
    { _id: '4', label: "Monthly", value: "monthly" },
    { _id: '5', label: "Bi-Monthly", value: "biMonthly" },
    { _id: '6', label: "Quarterly", value: "quarterly" },
]