import {PayloadAction} from "@reduxjs/toolkit";
import {
  ChangeUserIdPayload,
  Channel,
  ConnectionPayload,
  CreateNewConnectionPayload,
  Message,
  MessagePayload,
  MessagesState,
} from "../messageTypes";
import dayjs from "dayjs";

export const getChannelMessageSuccess = (
  state: MessagesState,
  action: PayloadAction<MessagePayload>
) => {
  state.error = null;
  state.messages =
    action?.payload?.data?.reduce(
      (acc: Record<string, Message>, message: Message) => {
        acc[message._id] = message;
        return acc;
      },
      {} as Record<string, Message>
    ) ?? {};
  state.loading = false;
};

export const sendMessageSuccess = (
  state: MessagesState,
  action: PayloadAction<MessagePayload>
) => {
  state.error = null;
  state.replyMessageContent = null;

  if (action.payload.data && state.channels[action.payload.data.channelId]) {
    state.channels[action.payload.data.channelId].lastMessage =
      action.payload.data;
    state.messages[action.payload.data._id] = action.payload.data;
  }

  if (state.channels[action.payload.data?.channelId]) {
    state.channels[action.payload.data.channelId].lastMessage =
      action.payload.data;

    const sortedChannelIds: any = Object.keys(state.channels).sort((a, b) => {
      const dateA: any = state.channels[a].lastMessage?.createdAt
        ? dayjs(state.channels[a].lastMessage.createdAt)
        : dayjs(state.channels[a].createdAt);
      const dateB = state.channels[b].lastMessage?.createdAt
        ? dayjs(state.channels[b].lastMessage.createdAt)
        : dayjs(state.channels[b].createdAt);

      return dateB.valueOf() - dateA.valueOf();
    });

    const sortedChannels: any = {};
    sortedChannelIds.forEach((id: any) => {
      sortedChannels[id] = state.channels[id];
    });

    state.channels = sortedChannels;
  }
};

export const getUserConnectionsSuccess = (
    state: MessagesState,
    action: PayloadAction<ConnectionPayload>
) => {
  state.error = null;
  if (Array.isArray(action.payload.data)) {
    state.channels = action.payload.data.reduce(
        (acc: Record<string, any>, connection: Channel) => {
          acc[connection._id] = connection;
          return acc;
        },
        {} as Record<string, any>
    );

    const sortedChannelIds = Object.keys(state.channels).sort((a, b) => {
      const dateA = state.channels[a].lastMessage?.createdAt
          ? dayjs(state.channels[a].lastMessage.createdAt)
          : dayjs(state.channels[a].createdAt);
      const dateB = state.channels[b].lastMessage?.createdAt
          ? dayjs(state.channels[b].lastMessage.createdAt)
          : dayjs(state.channels[b].createdAt);

      return dateB.valueOf() - dateA.valueOf();
    });

    const sortedChannels: any = {};
    sortedChannelIds.forEach((id) => {
      sortedChannels[id] = state.channels[id];
    });

    state.channels = sortedChannels;

    state.currentChannel = action.payload.data.find(
        (channel: any) => channel._id === state.currentChannelId
    );
  } else {
    state.channels = {};
    state.currentChannel = null;
  }
  state.loading = false;
};


export const getExternalConnectionsSuccess = (
  state: MessagesState,
  action: PayloadAction<ConnectionPayload>
) => {
  state.error = null;
  state.externalChannels =
    action?.payload?.data?.reduce(
      (acc: Record<string, any>, connection: Channel) => {
        acc[connection._id] = connection;
        return acc;
      },
      {} as Record<string, any>
    ) ?? {};
  const currentChannel: any = action?.payload?.data?.find(
    (channel: any) => channel._id === state.currentChannelId
  );
  state.currentChannel = currentChannel;
};

export const createNewConnectionSuccess = (
  state: MessagesState,
  action: PayloadAction<CreateNewConnectionPayload>
) => {
  state.error = null;
  state.currentChannelId = action?.payload?.data?._id;
  if (action?.payload?.data) {
    state.channels[action.payload.data._id] = action.payload.data;
  }
};

export const updateConnectionSuccess = (
  state: MessagesState,
  action: PayloadAction<CreateNewConnectionPayload>
) => {
  state.error = null;
  const channelId = action?.payload?.data?._id;
  if (channelId && state.channels[channelId]) {
    state.channels[channelId].isPinned = action?.payload?.data?.isPinned;
  }
};

export const updateMessageSuccess = (
  state: MessagesState,
  action: PayloadAction<CreateNewConnectionPayload>
) => {
  state.error = null;
  state.editMessageContent = {};
};

export const scheduleMessageSuccess = (
  state: MessagesState,
  action: PayloadAction<CreateNewConnectionPayload>
) => {
  state.error = null;
  state.loading = false;
};

export const forwardMessageSuccess = (
  state: MessagesState,
  action: PayloadAction<CreateNewConnectionPayload>
) => {
  state.error = null;
  state.loading = false;
};

export const addReactionToMessageSuccess = (
  state: MessagesState,
  action: PayloadAction<CreateNewConnectionPayload>
) => {
  state.error = null;
  const message = action.payload.data;
  if (message?._id) {
    state.messages[message._id] = message;
  }
  state.loading = false
};

export const removeReactionToMessageSuccess = (
  state: MessagesState,
  action: PayloadAction<CreateNewConnectionPayload>
) => {
  state.error = null;
  const message = action.payload.data;
  if (message?._id) {
    state.messages[message._id] = message;
  }
  state.loading = false
};
export const markMessagesAsReadSuccess = (
  state: MessagesState,
  action: PayloadAction<CreateNewConnectionPayload>
) => {
  state.error = null;
};

export const changeParticipantsRoleSuccess = (
  state: MessagesState,
  action: PayloadAction<ChangeUserIdPayload>
) => {
  state.error = null;
  const { userId, role } = action.payload?.data?.updatedParticipant;
  state.currentChannel.participants = state.currentChannel.participants.map((participant: any) => {
    if (participant.userId === userId) {
      return {
        ...participant,
        role: role,
      };
    }
    return participant;
  });

  state.loading = false;
};

export const getChannelActivitiesSuccess = (
  state: MessagesState,
  action: PayloadAction<ChangeUserIdPayload>
) => {
  state.error = null;
  state.channelActivity = action.payload.data
};

export const getMessageActivitiesSuccess = (
    state: MessagesState,
    action: PayloadAction<ChangeUserIdPayload>
) => {
  state.error = null;
  if (Array.isArray(action.payload.data) && action.payload.data.length > 0) {
    state.messageActivity = action.payload.data.reduce(
        (acc: Record<string, any>, activity: any) => {
          acc[activity._id] = activity;
          return acc;
        },
        {} as Record<string, any>
    );
  } else {
    state.messageActivity = {};
  }
  state.loading = false;
};
export const changeMessagesActivitiesStatusSuccess = (
  state: MessagesState,
  action: PayloadAction<ChangeUserIdPayload>
) => {
  state.error = null;
  state.loading = false;
};
export const setSnoozeMessagesActivitiesSuccess = (
  state: MessagesState,
  action: PayloadAction<ChangeUserIdPayload>
) => {
  state.error = null;
  state.loading = false
};
export const getSlackUsersSuccess = (
  state: MessagesState,
  action: PayloadAction<ChangeUserIdPayload>
) => {
  state.error = null;
  state.slackUsers = action.payload.data;
  state.loading = false
};
export const getSlackUserProfileSuccess = (
  state: MessagesState,
  action: PayloadAction<ChangeUserIdPayload>
) => {
  state.error = null;
  state.slackUserProfile = action.payload.data.slackProfile;
  state.teamsUserProfile = action.payload.data.teamsProfile;
  state.loading = false
};
export const getTeamsUserProfileSuccess = (
  state: MessagesState,
  action: PayloadAction<ChangeUserIdPayload>
) => {
  state.error = null;
  state.teamsUserProfile = action.payload.data;
  state.loading = false
};

/* get messages from channel */
export const getMessagesFromChannelSuccess = (
  state: MessagesState,
  action: PayloadAction<any>
) => {
  state.error = null;
};

/* send message to channel */
export const sendMessageToChannelSuccess = (
  state: MessagesState,
  action: PayloadAction<any>
) => {
  state.error = null;
};

/* send chat message */
export const sendChatMessageSuccess = (
  state: MessagesState,
  action: PayloadAction<any>
) => {
  state.error = null;
};

/* get mentions in messages */
export const getMentionsInMessagesSuccess = (
  state: MessagesState,
  action: PayloadAction<any>
) => {
  state.error = null;
};

/* create channel */
export const createChannelSuccess = (
  state: MessagesState,
  action: PayloadAction<any>
) => {
  state.error = null;
};

/* get user presence */
export const getUserPresenceSuccess = (
  state: MessagesState,
  action: PayloadAction<any>
) => {
  state.error = null;
};

/* create online meeting */
export const createOnlineMeetingSuccess = (
  state: MessagesState,
  action: PayloadAction<any>
) => {
  state.error = null;
};

/* get files in channel */
export const getFilesInChannelSuccess = (
  state: MessagesState,
  action: PayloadAction<any>
) => {
  state.error = null;
};

/* access files in personal chats */
export const accessFilesInPersonalChatsSuccess = (
  state: MessagesState,
  action: PayloadAction<any>
) => {
  state.error = null;
};

/* upload files to personal chats */
export const uploadFilesToPersonalChatsSuccess = (
  state: MessagesState,
  action: PayloadAction<any>
) => {
  state.error = null;
};

/* get user channels from team */
export const getUserChannelsFromTeamSuccess = (
  state: MessagesState,
  action: PayloadAction<any>
) => {
  state.error = null;
};

/* get user team */
export const getUserTeamSuccess = (
  state: MessagesState,
  action: PayloadAction<any>
) => {
  state.error = null;
};
