import React from "react";
import TitleContainer from "../../TitleContainer/TitleContainer";
import {
  BadgeDropdownTitleContainer,
  DropdownWithImageBadge,
  DUMMY_USER_EMAIL_LIST,
  HostDropdown,
} from "../../../../../../../common";

type ComponentDataType = any; // this is type for this component data
interface ContactConnectionsProps {
  stateData: ComponentDataType; // Current data passed from the parent
  setStateData: (data: ComponentDataType) => void; // Function to update data in the parent
}

const ContactConnections: React.FC<ContactConnectionsProps> = ({
  setStateData,
  stateData,
}) => {
  // Handle updates for the "from" field
  const handleFromChange = (newFrom: any[]) => {
    setStateData({ ...stateData, from: newFrom });
  };

  // Handle updates for the "hosts" field
  const handleHostsChange = (newHosts: any[]) => {
    setStateData({ ...stateData, hosts: newHosts });
  };

  return (
    <TitleContainer
      title={"Contact Connections"}
      isCollapasable
      childrenSx={{ gap: "15px" }}
    >
      <BadgeDropdownTitleContainer title="Related">
        <DropdownWithImageBadge
          initialData={stateData.from}
          onChangeSelection={handleFromChange} // Pass handler for from
          alwaysShowPlaceholder
          placeholder="Type here..."
          options={DUMMY_USER_EMAIL_LIST}
        />
      </BadgeDropdownTitleContainer>

      <BadgeDropdownTitleContainer title="Company">
        <HostDropdown
          getHosts={() => null}
          onChangeSelection={() => null}
          options={[]}
        />
      </BadgeDropdownTitleContainer>
    </TitleContainer>
  );
};

export default ContactConnections;
