import { Stack } from "@mui/material";
import React from "react";
import { IntelligentConnections } from "../common";

const IntelligentConnection: React.FC = () => {
  return (
    <Stack sx={{ height: "calc(100vh - 65px)" }}>
      <IntelligentConnections />
    </Stack>
  );
};

export default IntelligentConnection;
