import React, { useEffect, useState } from "react";
import { Box, Stack, Modal, Typography } from "@mui/material";
import { HeaderOverViewName, ReactangleHeaderOverView } from "../components";
import { MultiIconButton } from "../../common";
import {
  BarButtonOptions,
  CrmRightSideTabs,
  TimelineTabs,
} from "../../components";
import {
  AddtoGroupIcon,
  CompanyIcon,
  LasTTouchPointIcon,
  RelationshipStrengthIcon,
} from "../../../../../images";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addToGroup__api,
  getOneBusiness__api,
  updateOneBusiness__api,
} from "../../../../../redux";
import { RootState } from "../../../../../redux/store";
import { formatDate } from "../../../../../Utils";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  height: "fit-content",
  borderRadius: "6px",
};
type Params = Record<string, string | undefined>;
const CompanyID = () => {
  const { companyId } = useParams<Params>();
  const dispatch = useDispatch();

  const crmState = useSelector((state: RootState) => state && state?.crm);
  const { groupList } = crmState || {};
  const [grpList, setGrpList] = useState<any>([]);

  useEffect(() => {
    if (groupList && groupList?.length > 0) {
      setGrpList(groupList);
    }
  }, [groupList]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [companyDetails, setCompanyDetails] = useState<any>({});
  const getCompanyDetails = async () => {
    try {
      if (!companyId) {
        return;
      }

      const action = getOneBusiness__api(companyId);

      const getCompanyRes: any = await dispatch(action);

      if (getCompanyRes?.success && getCompanyRes?.data) {
        setCompanyDetails(getCompanyRes?.data);
      } else {
        setCompanyDetails({});
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCompanyDetails();
  }, [companyId]);

  const updateCompanyData = async (updatedFields: { [key: string]: any }) => {
    try {
      if (!companyDetails?._id) {
        return;
      }
      // Update local state with the new values
      setCompanyDetails((prevDetails: any) => ({
        ...prevDetails,
        ...updatedFields, // Merge updated fields into the existing state
      }));
      // Constructing the payload based on updated fields
      const payload: any = {
        businessName:
          updatedFields?.businessName || companyDetails?.businessName, // Use updated name or existing
        businessLogo:
          updatedFields?.businessLogo || companyDetails?.businessLogo || "", // Use updated logo or existing
        description:
          updatedFields?.description || companyDetails?.description || "", // Use updated description or existing
        domain: updatedFields?.domain || companyDetails?.domain || [], // Use updated domains or existing
        categories:
          updatedFields?.categories || companyDetails?.categories || [], // Use updated categories or existing
        primaryLocation:
          updatedFields?.primaryLocation ||
          companyDetails?.primaryLocation ||
          "", // Use updated location or existing
        socialLinks:
          updatedFields?.socialLinks || companyDetails?.socialLinks || {}, // Use updated social links or existing
        employeeCount:
          updatedFields?.employeeCount !== undefined
            ? updatedFields?.employeeCount
            : companyDetails?.employeeCount || 0, // Use updated count or existing
        estimatedARR:
          updatedFields?.estimatedARR !== undefined
            ? updatedFields?.estimatedARR
            : companyDetails?.estimatedARR || 0, // Use updated ARR or existing
        fundingAmount:
          updatedFields?.fundingAmount !== undefined
            ? updatedFields?.fundingAmount
            : companyDetails?.fundingAmount || 0, // Use updated funding amount or existing
        foundingDate:
          updatedFields?.foundingDate || companyDetails?.foundingDate || "", // Use updated founding date or existing
      };

      // Dispatch the action to update the business
      const action = updateOneBusiness__api(companyDetails._id, payload);
      await dispatch(action);
    } catch (error) {
      console.log(error);
    }
  };

  const addToGroup = async (_id: string) => {
    try {
      if (!companyId || !_id) {
        return;
      }

      const payload: any = {
        companyId: companyId,
      };

      const action = addToGroup__api(_id, payload);
      await dispatch(action);
    } catch (error) {
      console.log(error);
    }
  };

  // You can pass custom badge data or use the default one
  const customBadgeData = [
    {
      title: `${companyDetails?.relationshipStrength}⭐`,
      icon: <RelationshipStrengthIcon />, // Replace with your own icon
    },
    {
      title: `${formatDate(companyDetails?.updatedAt)}`,
      icon: <LasTTouchPointIcon />, // Replace with another icon
    },
    {
      title: companyDetails?.businessName || "No business name",
      imageUrl: companyDetails?.businessLogo,
    },
  ];

  return (
    <Box>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        gap={0.5}
        sx={{ padding: "4px 10px" }}
      >
        <HeaderOverViewName
          imageUrl={companyDetails?.businessLogo}
          headerType="company"
          name={companyDetails?.businessName}
          roundedOrSquare="rd"
          onUpdate={updateCompanyData} // Pass the update function for company
        />
        <Stack
          direction={"row"}
          onClick={handleOpen}
          alignItems={"center"}
          gap={1}
        >
          <BarButtonOptions
            onClick={() => {
              console.log("");
            }}
            label={{ id: 1, name: "Add to Group" }}
            icon={<AddtoGroupIcon />}
          />
          <MultiIconButton />
        </Stack>
      </Stack>

      <Stack>
        <ReactangleHeaderOverView badgeData={customBadgeData} />
      </Stack>

      <Stack direction={"row"} height={"80vh"}>
        <TimelineTabs contactDetails={companyDetails} cType={'COMPANY'} />
        <CrmRightSideTabs
          typeOfIdentifier={"company"}
          onUpdate={updateCompanyData}
          contactDetails={companyDetails}
        />
      </Stack>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <CreateGroupPopUp variant="group" /> */}
          <Stack minWidth={"200px"} direction={"column"} gap={1}>
            {grpList &&
              grpList?.length > 0 &&
              grpList?.map((g: any) => {
                return (
                  <Typography
                    key={g?._id}
                    sx={{
                      font: " normal normal normal 14px/20px Source Serif Pro",
                      padding: "10px 20px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      addToGroup(g?._id);
                    }}
                  >
                    {" "}
                    <span style={{ marginRight: "10px" }}>
                      {" "}
                      {g?.groupIcon}{" "}
                    </span>{" "}
                    {g?.groupName}{" "}
                  </Typography>
                );
              })}
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

export default CompanyID;
