import { Stack, Typography } from "@mui/material";
import React from "react";
import { CheckboxFilledIcon, XCircleIcon } from "../../../../../../../images";

interface StatusInfoAreaProps {
  infoType: "approved" | "rejected";
}

const StatusInfoArea: React.FC<StatusInfoAreaProps> = ({ infoType }) => {
  const getComponent = (): {
    icon: React.ReactNode;
    label: string;
  } => {
    switch (infoType) {
      case "approved":
        return {
          icon: <CheckboxFilledIcon color="#21B481" w={20} />,
          label: "Approved",
        };
      case "rejected":
        return {
          icon: <XCircleIcon color="#FF0000" w={20} />,
          label: "Rejected",
        };
    }
  };
  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
      {getComponent().icon}
      <Typography sx={{ font: "normal normal 300 14px/18px Source Serif Pro" }}>
        {getComponent().label}
      </Typography>
    </Stack>
  );
};

export default StatusInfoArea;
