import { Stack, Typography } from "@mui/material";
import React from "react";

interface ApprovalTimeViewProps {}

const ApprovalTimeView: React.FC<ApprovalTimeViewProps> = ({}) => {
  return (
    <Stack sx={{ gap: "5px" }}>
      <Typography sx={{ font: "normal normal 900 13px/16px Source Serif Pro" }}>
        Approved on:
      </Typography>
      <Typography sx={{ font: "normal normal 400 13px/16px Source Serif Pro" }}>
        07-00-2024 at 00:08:00 am
      </Typography>
    </Stack>
  );
};

export default ApprovalTimeView;
