import { Stack } from "@mui/material";
import React from "react";
import { getFileIcon } from "../../../../../../../../Utils";

interface FilePreviewAreaProps {
  fileType: string;
}

const FilePreviewArea: React.FC<FilePreviewAreaProps> = ({ fileType }) => {
  return (
    <Stack
      sx={{
        height: "30px",
        width: "30px",
        borderRadius: "30px",
        boxSizing: "border-box",
        border: "1px solid #E9EDF2",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "white",
      }}
    >
      {getFileIcon({ name: `file.${fileType}`, iconSize: 15,iconGroup: "group2" })}
    </Stack>
  );
};

export default FilePreviewArea;
