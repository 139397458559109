import React from "react";
import TitleContainer from "../../TitleContainer/TitleContainer";
import { Stack, Typography } from "@mui/material";
import { CheckboxMedium } from "../../../../../../../common";
import { AdditionalFilterMultiRadioBtnType } from "../../../../CommandControlSearchContainer";

const basicFilterList: AdditionalFilterMultiRadioBtnType = {
  _id: "basicfilters",
  field: ["email"],
  translatedTitle: "Basic filters",
  option: [
    { _id: "1", translatedTitle: "Has attachment", value: "attachment" },
    { _id: "2", translatedTitle: "Has link", value: "link" },
    { _id: "3", translatedTitle: "Status: Unread", value: "unread" },
    { _id: "4", translatedTitle: "Status: Read", value: "read" },
  ],
};

type ComponentDataType = string[];
interface EmailBasicFiltersProps {
  stateData: ComponentDataType; // Current data passed from the parent
  setStateData: (data: ComponentDataType) => void; // Function to update data in the parent
}

const EmailBasicFilters: React.FC<EmailBasicFiltersProps> = ({
  setStateData,
  stateData,
}) => {
  const handleSelectionChange = (value: string) => {
    const updatedMeetingTypes = stateData.includes(value)
      ? stateData.filter((item) => item !== value) // Remove if already selected
      : [...stateData, value]; // Add if not selected

    // Update the state in the parent
    setStateData(updatedMeetingTypes);
  };
  return (
    <TitleContainer title={basicFilterList.translatedTitle} isCollapasable>
      {basicFilterList.option.map((item, index) => (
        <Stack
          key={index}
          sx={{
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
            cursor: "pointer",
          }}
          onClick={() => handleSelectionChange(item.value)}
        >
          <CheckboxMedium checked={stateData.includes(item.value)} />
          <Typography
            sx={{
              font: "normal normal 300 13px/16px Source Serif Pro",
              whiteSpace: "nowrap",
            }}
          >
            {item.translatedTitle}
          </Typography>
        </Stack>
      ))}
    </TitleContainer>
  );
};

export default EmailBasicFilters;
