import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { IconButton } from "@mui/material";
import { GearIcon } from "../../../../../images";

type Props = {
  sortEmailsByPriority: () => void;
  handleTabSwitch: (value: string) => void;
  theme?: any;
};

export default function ColorTabsEmail({
  sortEmailsByPriority,
  handleTabSwitch,
  theme,
}: Props) {
  const [value, setValue] = React.useState("one");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);

    if (newValue === "four") {
      sortEmailsByPriority();
    } else if (newValue === "three") {
      handleTabSwitch("Snoozed");
    } else if (newValue === "one") {
      handleTabSwitch("");
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="inherit"
        aria-label="secondary tabs example"
        sx={{
          padding: 0,
          "& .MuiTab-root": {
            fontFamily: "Source Serif Pro",
            fontWeight: 500,
            padding: "4px 8px",
            fontSize: "15px",
            textTransform: "capitalize",
          },
          "& .MuiTabs-indicator": {
            backgroundColor: "#FFFFFF",
          },
        }}
      >
        <Tab
          sx={{ color: theme ? "white" : "black" }}
          value="one"
          label="New Updates"
        />
        <Tab
          sx={{ color: theme ? "white" : "black" }}
          value="two"
          label="Done"
        />
        <Tab
          sx={{ color: theme ? "white" : "black" }}
          value="three"
          label="Snoozed"
        />
        <Tab
          sx={{ color: theme ? "white" : "black" }}
          value="four"
          label="Priority"
        />
      </Tabs>

      <IconButton>
        <GearIcon w={15} />
      </IconButton>
    </Box>
  );
}
