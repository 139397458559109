import React from "react";
import TitleContainer from "../../TitleContainer/TitleContainer";
import { MultiSelectRadioBtn } from "../../../../../../../common";
import { AdditionalFilterMultiRadioBtnType } from "../../../../CommandControlSearchContainer";
import { Stack } from "@mui/material";

const interactionTypeList: AdditionalFilterMultiRadioBtnType = {
  _id: "interactionType",
  field: ["crm"],
  translatedTitle: "Interaction Type",
  option: [
    { _id: "1", translatedTitle: "Email", value: "Email" },
    { _id: "2", translatedTitle: "Workspace", value: "Workspace" },
    { _id: "3", translatedTitle: "Call", value: "Call" },
    { _id: "4", translatedTitle: "Event", value: "Event" },
    { _id: "5", translatedTitle: "Message", value: "Message" },
    { _id: "6", translatedTitle: "Trax", value: "Trax" },
  ],
};

type ComponentDataType = string[]; // this is type for this component data
interface InteractionTypeProps {
  stateData: ComponentDataType; // Current data passed from the parent
  setStateData: (data: ComponentDataType) => void; // Function to update data in the parent
}

const InteractionType: React.FC<InteractionTypeProps> = ({
  setStateData,
  stateData,
}) => {
  const handleSelectionChange = (value: string) => {
    const updatedMeetingTypes = stateData.includes(value)
      ? stateData.filter((item) => item !== value) // Remove if already selected
      : [...stateData, value]; // Add if not selected

    // Update the state in the parent
    setStateData(updatedMeetingTypes);
  };
  return (
    <Stack sx={{ gap: "15px" }}>
      {/* ------------------------------ */}
      <TitleContainer
        title={interactionTypeList.translatedTitle}
        isCollapasable
        childrenSx={{ flexDirection: "row", flexWrap: "wrap" }}
      >
        {interactionTypeList.option.map((item) => (
          <MultiSelectRadioBtn
            sx={{ width: "45%" }}
            key={item._id}
            isChecked={stateData.includes(item.value)}
            label={item.translatedTitle}
            onClick={() => handleSelectionChange(item.value)}
          />
        ))}
      </TitleContainer>
      {/* ------------------------------ */}
    </Stack>
  );
};

export default InteractionType;
