import { Stack, SxProps, Typography } from "@mui/material";
import React from "react";
import { DealStageCurrencySymbols, TextLinkButton } from "../../../../common";
import {
  BonusIcon,
  CardIcon,
  FundingIcon,
  TotalhoursIcon,
} from "../../../../../images";
import { PayFrequencyMenu, PaymentViaMenu } from "../../components";
import { BonusEligibilityRadioGroups, LastPaymentView } from "./View";

const InlineGrideContainer: React.FC<{
  firstLabel: string;
  firstIcon: React.ReactNode;
  firstChildren: React.ReactNode;
  secondLabel: string;
  SecondIcon: React.ReactNode;
  secondChildren: React.ReactNode;
  sx?: SxProps;
}> = ({
  firstChildren,
  firstIcon,
  firstLabel,
  secondChildren,
  secondLabel,
  SecondIcon,
  sx,
}) => {
  return (
    <Stack
      sx={{
        flex: 1,
        boxSizing: "border-box",
        border: "1px solid #E9EDF2",
        flexDirection: "row",
        ...sx,
      }}
    >
      <Stack sx={{ flex: "50%", borderRight: "1px solid #E9EDF2" }}>
        <Stack
          sx={{
            flexDirection: "row",
            padding: "0px 10px",
            height: "30px",
            alignItems: "center",
            borderBottom: "1px solid #E9EDF2",
            boxSizing: "border-box",
            gap: "5px",
          }}
        >
          {firstIcon}
          <Typography
            sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
          >
            {firstLabel}
          </Typography>
        </Stack>

        <Stack
          sx={{
            height: "38px",
            flexShrink: 0,
            flexDirection: "row",
            alignItems: "center",
            padding: "0px 10px",
          }}
        >
          {firstChildren}
        </Stack>
      </Stack>

      <Stack sx={{ flex: "50%" }}>
        <Stack
          sx={{
            flexDirection: "row",
            padding: "0px 10px",
            height: "30px",
            alignItems: "center",
            borderBottom: "1px solid #E9EDF2",
            boxSizing: "border-box",
            gap: "5px",
          }}
        >
          {SecondIcon}
          <Typography
            sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
          >
            {secondLabel}
          </Typography>
        </Stack>

        <Stack
          sx={{
            height: "38px",
            flexShrink: 0,
            flexDirection: "row",
            alignItems: "center",
            padding: "0px 10px",
          }}
        >
          {secondChildren}
        </Stack>
      </Stack>
    </Stack>
  );
};

interface PaymentDetailsAreaProps {}

const PaymentDetailsArea: React.FC<PaymentDetailsAreaProps> = ({}) => {
  const cSymbol = DealStageCurrencySymbols.USD;
  return (
    <Stack gap={"15px"}>
      {/* ---------------------------------------------------------- */}
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ font: "normal normal 400 13px/16px Source Serif Pro" }}
        >
          Payment Details
        </Typography>
        <TextLinkButton
          buttonLabel="View all"
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
        />
      </Stack>
      {/* ---------------------------------------------------------- */}

      <InlineGrideContainer
        firstIcon={<TotalhoursIcon color="black" w={20} />}
        firstLabel="Frequency"
        firstChildren={
          <PayFrequencyMenu initialValue="monthly" onSelect={(value) => null} />
        }
        SecondIcon={<CardIcon color="black" w={20} />}
        secondLabel="Pay Via"
        secondChildren={
          <PaymentViaMenu initialValue="payCard" onSelect={(value) => null} />
        }
      />
      {/* ---------------------------------------------------------- */}

      <InlineGrideContainer
        sx={{ marginTop: "8px" }}
        firstIcon={<FundingIcon color="black" w={20} />}
        firstLabel="Last payment"
        firstChildren={
          <LastPaymentView
            currencySymbol={cSymbol}
            ammount={3500}
            date="10/12"
          />
        }
        SecondIcon={<BonusIcon color="black" w={20} />}
        secondLabel="Bonus Eligibility"
        secondChildren={<BonusEligibilityRadioGroups />}
      />

      {/* ---------------------------------------------------------- */}
    </Stack>
  );
};

export default PaymentDetailsArea;
