import { Box, Popover, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { SmallPinnedCard } from "../SmallPinnedCard";
import { adjustHexColor } from "../../../Utils";
import { useDispatch, useSelector } from "react-redux";
import {
  addChannelIdAction,
  addChannelNameAction,
} from "../../../redux/Slice/message/messageSlice";
import {useNavigate, useParams} from "react-router-dom";
import {
  ChannelDetailsMenu,
  MessageDetailsMenus,
} from "../../pages/Message/components";
import {
  hideLeaveForGroup,
  hideLeaveForPrsonalChat,
  messageDetailsMenusChannelList,
  messageDetailsMenusChatList,
  thirdPartyChat,
} from "../../pages/Message/components/MessageDetailsMenus/MessageDetailsMenusType";
import {
  getUserConnections,
  updateConnections,
} from "../../../redux/Slice/message/messageActions";
import { getHTMLContent, MESSAGE_ENUMS } from "../../pages/Message/constant";
import { RootState } from "../../../redux/store";

interface ChatDrawerCardProps {
  key?: any;
  userName?: any;
  userIconSrc?: string;
  isUserActive?: boolean;
  message?: string;
  timeStamp?: string;
  companyImgSrc?: string;
  showDetailsLine?: 1 | 2;
  channelId?: any;
  channelName?: string;
  isSelected?: boolean;
  onClick?: any;
  channelDetails?: any;
  userData?: any;
  lastMessage?: any;
}

const ChatDrawerCard: React.FC<ChatDrawerCardProps> = ({
  key,
  companyImgSrc,
  isUserActive,
  message,
  timeStamp,
  userName,
  userIconSrc,
  showDetailsLine = 1,
  channelId,
  channelName,
  isSelected = false,
  channelDetails,
  userData,
  lastMessage = { content: "" },
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [channelData, setChannelData] = useState({
    chName: "",
    topicName: "",
    chDescription: "",
  });
  const [openChannelDetailSection, setOpenChannelDetailSection] =
    useState<boolean>(false);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false); // New state variable
  const [timeStampText, setTimeStampText] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "menu-popover" : undefined;
  const detailRef = useRef<HTMLDivElement>(null);
  const { currentChannel } = useSelector((state: RootState) => state.message);

  const handleClickOutsideForDetail = (event: MouseEvent) => {
    if (document.getElementById("openunderMenu")) {
      return;
    }
    if (
      detailRef.current &&
      !detailRef.current.contains(event.target as Node)
    ) {
      setOpenChannelDetailSection(false);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (lastMessage?.createdAt) {
      const calculateTimeDifference = () => {
        let messageTime;
        if (lastMessage.createdAt.includes("T")) {
          messageTime = new Date(lastMessage.createdAt);
        } else {
          messageTime = new Date(parseFloat(lastMessage.createdAt) * 1000);
        }
        const currentTime = new Date();
        // const messageTime = new Date(lastMessage.createdAt);
        const timeDifference = Math.abs(
          currentTime.getTime() - messageTime.getTime()
        );
        const minutesDifference = Math.floor(timeDifference / (1000 * 60));
        const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
        const daysDifference = Math.floor(
          timeDifference / (1000 * 60 * 60 * 24)
        );

        let timeStamp = "";
        if (daysDifference > 0) {
          timeStamp = `${daysDifference} days`;
        } else if (hoursDifference > 0) {
          timeStamp = `${hoursDifference} hour`;
        } else {
          timeStamp = `${minutesDifference} min`;
        }

        setTimeStampText(timeStamp);
      };

      calculateTimeDifference();

      const interval = setInterval(calculateTimeDifference, 60000);

      return () => clearInterval(interval);
    }
  }, [lastMessage]);

  useEffect(() => {
    if (
      channelData.chName ||
      channelData.topicName ||
      channelData.chDescription
    ) {
      const timer = setTimeout(() => {
        HandleUpdateChannelDetails(channelData);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [channelData]);

  useEffect(() => {
    if (openChannelDetailSection) {
      document.addEventListener("mousedown", handleClickOutsideForDetail);
    } else {
      document.removeEventListener("mousedown", handleClickOutsideForDetail);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideForDetail);
    };
  }, [openChannelDetailSection]);

  const onHandleChatClick = async () => {
    if (menuOpen) return;
    setIsContextMenuOpen(false);
    await dispatch(addChannelIdAction(channelId));
    await dispatch(addChannelNameAction(channelName));
    navigate(`/Message/${channelId}`);
  };

  const handleRightClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setOpenChannelDetailSection(false);
    setIsContextMenuOpen(true); // Set context menu open flag
  };

  const onChatMenuClick = async (data: any) => {
    if (data.value?.includes("Pin")) {
      const channelIsPinned = {
        isPinned: data?.isPinned ? false : true,
        userId: userData?.data._id,
      };
      await dispatch(updateConnections(channelId, channelIsPinned));
      await dispatch(getUserConnections());
    }
    if (data.value === "View Channel Details") {
      setOpenChannelDetailSection(true);
    }
    if (data.value?.includes("Leave")) {
      const channelIsPinned = {
        channelId: channelId,
        userId: userData?.data._id,
        isLeave: true,
      };
      await dispatch(updateConnections(channelId, channelIsPinned));
      await dispatch(getUserConnections());
    }
    if (data.value.includes("Hide")) {
      const channelIsHide = {
        isHide: true,
        userId: userData?.data._id,
        channelId: channelId,
      };
      await dispatch(updateConnections(channelId, channelIsHide));
      await dispatch(getUserConnections());
      navigate("/Message");
    }
    if (data.value.includes("Copy")) {
      navigator.clipboard.writeText(
        `https://os.bearishos.com/Message/${channelId}`
      );
    }
    handleClose();
    setIsContextMenuOpen(false);
  };
  const handleCloseChannelDetailSection = () => {
    setOpenChannelDetailSection(false);
  };

  const HandleOnChangedData = (data: any) => {
    setChannelData({
      chName: data.chName,
      topicName: data.topicName,
      chDescription: data.chDescription,
    });
  };

  const HandleUpdateChannelDetails = async (channelDetailData: any) => {
    if (!channelDetailData.chName && !channelDetailData.chDescription) return;
    if (
      channelDetailData.chName ||
      channelDetailData.topicName ||
      channelDetailData.chDescription
    ) {
      const payload = {
        name: channelDetailData.chName,
        topic: channelDetailData.topicName,
        details: channelDetailData.chDescription,
      };
      try {
        await dispatch(updateConnections(channelId, payload));
        await dispatch(getUserConnections());
      } catch (error) {
        console.error("Failed to update channel details:", error);
      }
    }
  };

  return (
    <Stack
      key={key}
      sx={{
        flexShrink: 0,
        height: "45px",
        px: "5px",
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
        flexGrow: 1,
        boxSizing: "border-box",
        borderRadius: "5px",
        width: "100%",
        bgcolor: isSelected ? "#775ee240" : "white",
        ":hover": { bgcolor: adjustHexColor("#fff", 104) },
        cursor: "pointer",
      }}
      onClick={onHandleChatClick}
      onContextMenu={handleRightClick}
    >
      <SmallPinnedCard
        size={30}
        userName={userName}
        isActive={isUserActive}
        userIconSrc={userIconSrc}
      />

      <Stack
        sx={{
          gap: "3px",
          flexDirection: "column",
          width: "calc(100% - (25px + 30px + 30px))",
        }}
      >
        <Typography
          sx={{
            font: "normal normal 600 11px/14px Source Serif Pro",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            flexGrow: 1,
          }}
        >
          {channelName}
        </Typography>
        <Typography
          sx={{
            font: "normal normal 300 8px/10px Source Serif Pro",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "normal",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: showDetailsLine,
            lineClamp: showDetailsLine,
            flexGrow: 1,
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: getHTMLContent(lastMessage?.content || ""),
            }}
          />
        </Typography>
      </Stack>

      <Stack
        sx={{
          gap: "5px",
          width: "40px",
          flexShrink: 0,
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ font: "normal normal 300 8px/10px Source Serif Pro" }}
        >
          {lastMessage?.content && timeStampText}
        </Typography>
        <Box
          component={"img"}
          sx={{ height: "15px", width: "15px" }}
          src={companyImgSrc}
          alt="logo"
        />
      </Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            bgcolor: "transparent",
            p: 0,
            boxShadow: "none",
            scrollbarWidth: "none",
          },
        }}
      >
        <MessageDetailsMenus
          handleClick={onChatMenuClick}
          options={
            channelDetails.channelType === MESSAGE_ENUMS.BEARISH
              ? channelDetails?.isGroupChat
                ? !channelDetails?.currentUserLeave
                  ? messageDetailsMenusChannelList
                  : hideLeaveForGroup
                : !channelDetails?.currentUserLeave
                ? messageDetailsMenusChatList
                : hideLeaveForPrsonalChat
              : thirdPartyChat
          }
        />
      </Popover>

      {openChannelDetailSection && (
        <div ref={detailRef}>
          <ChannelDetailsMenu
            onChangedData={HandleOnChangedData}
            channelDetails={currentChannel}
            closeChannelDetailSection={handleCloseChannelDetailSection}
          />
        </div>
      )}
    </Stack>
  );
};

export default ChatDrawerCard;
