import React, { useState, ReactNode, Dispatch, SetStateAction } from "react";
import g from "./Security.module.css";
import { Box, Modal, Stack, Typography } from "@mui/material";
import {
  DeleteAccountPopup,
  IpRestrictionsPopup,
  MediumTableAndButtons,
} from "../../../../common";
import { DeviceData, securityOptions } from "./components";
import { GreenArrow } from "../../../../../images";
import IconButton from "@mui/material/IconButton";
import { update2FactorAuthentication__api } from "../../../../../redux/Slice/auth/authActions";
import { useDispatch } from "react-redux";

type SecurityProps = {};

const getModalContent = (
  value: string | null,
  setContent: Dispatch<SetStateAction<string | null>>
): ReactNode => {
  if (!value) {
    return (
      <>
        {" "}
        <h2>Comming Soon...</h2>{" "}
      </>
    );
  }

  const props = { handleClosePopup: setContent || (() => {}) };
  const popupMapper: Record<string, ReactNode> = {
    setupIpRestrictions: <IpRestrictionsPopup {...props} />,
    deactivateYourAccount: <DeleteAccountPopup {...props} />,
  };

  return popupMapper[value] || null;
};

const Security: React.FC<SecurityProps> = () => {
  const dispatch = useDispatch();
  const [currentPopup, setCurrentPopup] = useState<string | null>(null);

  const handleTogglePopup = async (value: string) => {
    if (value === "setup2FactorAuthentication") {
      // Toss a random boolean value: true or false
      const randomBoolean = Math.random() < 0.5; // Randomly returns true or false

      // Pass the random value as a payload to the API action
      const action = update2FactorAuthentication__api({
        twoFactorEnabled: randomBoolean,
      });

      await dispatch(action);
    }

    setCurrentPopup(value);
  };

  return (
    <Box sx={{ padding: "20px 25px 25px 25px" }}>
      <div>
        <Typography
          className={g["gs-container-header"]}
          children={"Session Audits"}
        />
        <Typography
          className={g["italic-text-subject"]}
          children={
            "Understand every time your account was logged into where you logged in, and the device that was used"
          }
        />
      </div>

      <Stack mt={"20px"}>
        <MediumTableAndButtons deviceData={DeviceData} />
      </Stack>

      <Stack mt={"60px"}>
        <div>
          <Typography
            className={g["gs-container-header"]}
            children={"Secure your account"}
          />
        </div>

        <Stack mt={"30px"} gap={"10px"} direction={"column"}>
          {securityOptions.map((securityOptions) => {
            return (
              <>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  gap={"25px"}
                  key={securityOptions.id}
                  width={"30%"}
                  onClick={() => handleTogglePopup(securityOptions?.popupStr)}
                >
                  <Typography
                    children={securityOptions.name}
                    sx={{ color: securityOptions.danger ? "red" : "black" }}
                    className={g.so}
                  />
                  <IconButton>
                    <img src={GreenArrow} alt="" />
                  </IconButton>
                </Stack>
              </>
            );
          })}
        </Stack>
      </Stack>
      <Modal open={Boolean(currentPopup)} onClose={() => setCurrentPopup(null)}>
        <div className={g.modalContainer}>
          {getModalContent(currentPopup, setCurrentPopup)}
        </div>
      </Modal>
    </Box>
  );
};

export default Security;
