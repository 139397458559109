import {
  InputBase,
  Stack,
  Typography,
  ClickAwayListener,
  Tooltip,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import styles from "./SearchFoldersDropdown.module.css";
import { SearchFoldersDropdownInterface } from "./SearchFoldersDropdownType";
import { getFileIcon } from "../../../Utils";
import { FilterDocumentsSearch } from "../FilterDocumentsSearch";

interface SearchFoldersDropdownProps {
  options: SearchFoldersDropdownInterface[];
  showFilterMenu?: any;
  onSelect: (selectedOption: SearchFoldersDropdownInterface) => void;
}

const SearchFoldersDropdown: React.FC<SearchFoldersDropdownProps> = ({
  options,
  onSelect,
  showFilterMenu = false,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setIsDropdownOpen(true);
    setHighlightedIndex(-1); // Reset the highlighted index when input changes
  };

  const handleOptionClick = (option: SearchFoldersDropdownInterface) => {
    onSelect(option);
    setInputValue(option.folderName || option.foldername);
    setIsDropdownOpen(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!isDropdownOpen || filteredOptions?.length === 0) return;

    switch (event.key) {
      case "ArrowDown":
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex < filteredOptions?.length - 1 ? prevIndex + 1 : 0
        );
        break;
      case "ArrowUp":
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : filteredOptions?.length - 1
        );
        break;
      case "Enter":
        event.preventDefault();
        if (
          highlightedIndex >= 0 &&
          highlightedIndex < filteredOptions?.length
        ) {
          handleOptionClick(filteredOptions[highlightedIndex]);
        }
        break;
      case "Escape":
        setIsDropdownOpen(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // Scroll to the highlighted option if dropdownRef and highlighted option exist
    if (dropdownRef.current && highlightedIndex >= 0) {
      const highlightedOption = dropdownRef.current.children[
        highlightedIndex
      ] as HTMLElement;
      highlightedOption.scrollIntoView({ block: "nearest" });
    }
  }, [highlightedIndex]);

  const filteredOptions = options?.filter(
    (option) =>
      option?.folderName?.toLowerCase()?.includes(inputValue?.toLowerCase()) ||
      option?.foldername?.toLowerCase()?.includes(inputValue?.toLowerCase())
  );

  return (
    <ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
      <Stack className={styles.searchFoldersDropdown}>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: isDropdownOpen ? "5px 5px 0px 0px" : "5px",
            bgcolor: "white",
            border: "0.5px solid #e9edf2",
          }}
        >
          <InputBase
            sx={{
              borderRadius: isDropdownOpen ? "5px 5px 0px 0px" : "5px",
            }}
            placeholder="Search all folders"
            value={inputValue}
            onChange={handleInputChange}
            className={styles.searchFoldersDropdown__inputBase}
            onClick={() => (inputValue ? setIsDropdownOpen(true) : null)}
            onKeyDown={handleKeyDown}
          />
          {showFilterMenu && (
            <FilterDocumentsSearch onChange={(selectedValues) => null} />
          )}
        </Stack>
        {isDropdownOpen && filteredOptions?.length > 0 && (
          <Stack
            className={styles.searchFoldersDropdown__dropdown}
            ref={dropdownRef}
          >
            {filteredOptions?.map((option, index) => (
              <Stack
                key={option?._id || option?.folderid}
                className={`${styles.searchFoldersDropdown__item} ${
                  index === highlightedIndex
                    ? styles.searchFoldersDropdown__itemHighlighted
                    : ""
                }`}
                onClick={() => handleOptionClick(option)}
                sx={{ bgcolor: "rgba(25, 118, 210, 0.12)" }}
              >
                {getFileIcon({
                  name: option?.folderName || option?.foldername,
                  iconSize: 20,
                })}
                <Tooltip
                  placement="top"
                  arrow
                  title={option?.folderName || option?.foldername}
                  disableInteractive
                >
                  <Typography
                    className={styles.searchFoldersDropdown__itemText}
                  >
                    {option?.folderName || option?.foldername}
                  </Typography>
                </Tooltip>
              </Stack>
            ))}
          </Stack>
        )}
      </Stack>
    </ClickAwayListener>
  );
};

export default SearchFoldersDropdown;
