import React from "react";
import { AcceptRejectButtonGroup } from "../../../../common";
import { Stack } from "@mui/material";
import { TraxApprovedBadge } from "../../components";

interface TableReceiptStatusAreaProps {
  value: any;
}

const TableReceiptStatusArea: React.FC<TableReceiptStatusAreaProps> = ({
  value,
}) => {
  const getComponent = () => {
    switch (value as "approved" | "rejected" | "pending") {
      case "pending":
        return <AcceptRejectButtonGroup />;
      case "approved":
        return <TraxApprovedBadge variant="approved" />;
      case "rejected":
        return <TraxApprovedBadge variant="rejected" />;
    }
  };
  return <Stack alignItems={"center"}>{getComponent()}</Stack>;
};

export default TableReceiptStatusArea;
