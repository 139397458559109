import { Box, Stack } from "@mui/material";
import React from "react";
import {
  BreakView,
  CellWrapper,
  HoursView,
  NotesView,
  ShiftView,
} from "../../view";
import {
  AttendanceListInterface,
  timeCardTableRowsWidth,
} from "../../TimeCardTableType";

interface OnTimecardProps {
  data: AttendanceListInterface;
}

const OnTimecard: React.FC<OnTimecardProps> = ({ data }) => {
  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", height: "100%" }}>
      <CellWrapper colWidth={timeCardTableRowsWidth.ShiftWidth}>
        <ShiftView startTime="0:08:00 am" endTime="0:05:00 pm" />
      </CellWrapper>

      <CellWrapper colWidth={timeCardTableRowsWidth.BreakWidth}>
        <BreakView totalBreak={3} usedBreak={1} />
      </CellWrapper>

      <CellWrapper colWidth={timeCardTableRowsWidth.HoursWidth}>
        <HoursView totalTime="8 hrs 00 mins" />
      </CellWrapper>

      <CellWrapper colWidth={timeCardTableRowsWidth.OvertWidth}>
        <HoursView totalTime={"1 hrs 12 mint"} />
      </CellWrapper>

      <CellWrapper colWidth={timeCardTableRowsWidth.NotesWidth}>
        <NotesView />
      </CellWrapper>
    </Stack>
  );
};

export default OnTimecard;
