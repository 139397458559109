import { Stack, SxProps } from "@mui/material";
import React from "react";

interface CellWrapperProps {
  colWidth: number;
  hideRightBorder?: boolean;
  sx?: SxProps;
  children?: React.ReactNode;
}

const CellWrapper: React.FC<CellWrapperProps> = ({
  colWidth,
  children,
  hideRightBorder = false,
  sx,
}) => {
  return (
    <Stack
      sx={{
        height: "100%",
        boxSizing: "border-box",
        borderRight: hideRightBorder
          ? "0px solid #E9EDF2"
          : "1px solid #E9EDF2",
        flexDirection: "row",
        alignItems: "center",
        padding: "0px 10px 0px 10px",
        width: colWidth,
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
};

export default CellWrapper;
