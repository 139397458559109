import {Box, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {BearishOSIcon, BothSideArrowIcon} from "../../../../images";
import {getRedirectServiceCss, IconsContainer, RedirectButtons,} from "../common";
import {Trans, useTranslation} from "react-i18next";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {slackRedirect, syncSlackWithBearish, teamsRedirect} from "../../../../redux/Slice/message/messageActions";
import {useDispatch} from "react-redux";

const RedirectStatus: React.FC = () => {
    const {t} = useTranslation();
    const [isSuccess, setIsSuccess] = useState<boolean>(true);
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const params: any = useParams();

    const navigate = useNavigate();
    const {imgName, labelText, labelColor} = getRedirectServiceCss({
        groupName: params?.groupName || "workspaceDarkOragne",
        serviceName: params?.platform || "jira",
    });


    const handleAuthRedirect = async (platform: string | null) => {
        const code = searchParams.get("code");
        switch (platform) {
            case "slack":
                if (code) {
                    const response: any = await dispatch(slackRedirect(code));
                    if (response?.success) {
                        setIsSuccess(true);
                        dispatch(syncSlackWithBearish())
                        setTimeout(() => {
                            navigate("/Message")
                        }, 10000);
                    }
                }
                break;
            case "teams":
                if (code) {
                    const response: any = await dispatch(teamsRedirect(code));
                    if (response?.success) {
                        setIsSuccess(true);
                        setTimeout(() => {
                            navigate("/Message");
                        }, 10000);
                    }
                }
                break;
            case "box":
                console.log("box");
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        const platform = params?.platform || null;
        (async () => {
            await handleAuthRedirect(platform);
        })();
    }, [params]);

    const handleRedirectToDashboard = () => {
        navigate("/dashboard");
    }

    const handleRedirectToTryAgain = () => {
        navigate("/Settings/intelligent-connections");
    }

    return (
        <Box
            sx={{
                height: "100vh",
                width: "100vw",
                bgcolor: "#F2F5F8",
                padding: "50px 30px 0px 30px",
                boxSizing: "border-box",
                overflow: "scroll",
                scrollbarWidth: "none",
            }}
        >
            <Stack sx={{flexDirection: "row", alignItems: "center", gap: "15px"}}>
                <BearishOSIcon w={75}/>

                <Typography
                    sx={{font: "normal normal 400 60px/73px Averia Serif Libre"}}
                >
                    Bearish OS
                </Typography>
            </Stack>

            <Stack sx={{alignItems: "center", mt: "100px", gap: "45px"}}>
                <Typography
                    sx={{font: "normal normal 900 40px/50px Source Serif Pro"}}
                >
                    {isSuccess ? (
                        <Trans
                            i18nKey="DASHBOARD.SUCCESSFULLY_CONNECTED_TO"
                            components={{
                                span: <span style={{color: labelColor}}/>,
                            }}
                            values={{serviceName: labelText}}
                        />
                    ) : (
                        <Trans
                            i18nKey="DASHBOARD.CONNECTION_ISSUE"
                            components={{
                                span: <span style={{color: labelColor}}/>,
                            }}
                            values={{serviceName: labelText}}
                        />
                    )}
                </Typography>

                <Stack sx={{flexDirection: "row", alignItems: "center", gap: "15px"}}>
                    <IconsContainer borderColor="#775EE2" imgName="bearishPng"/>
                    <BothSideArrowIcon w={50}/>
                    <IconsContainer borderColor={labelColor} imgName={imgName}/>
                </Stack>

                <Typography
                    sx={{
                        font: "normal italic 300 20px/25px Source Serif Pro",
                        width: "450px",
                    }}
                >
                    {t("DASHBOARD.REDIRECT_MESSAGE", {
                        serviceName: labelText,
                        time: 10,
                    })}
                </Typography>

                <Stack sx={{mt: "-20px", gap: "25px"}}>
                    {isSuccess ? (
                        <>
                            <RedirectButtons
                                btnLabel={t("DASHBOARD.BACK_TO_BEARISH_OS_SUCCESS")}
                                onClick={handleRedirectToDashboard}
                            />
                        </>
                    ) : (
                        <>
                            <RedirectButtons
                                btnLabel={t("DASHBOARD.LOGIN_FAILED_TRY_AGAIN")}
                                btnBgColor="pink"
                                btnTextColor="white"
                                onClick={handleRedirectToTryAgain}
                            />
                            <RedirectButtons
                                btnLabel={t("DASHBOARD.BACK_TO_BEARISH_OS_TRY_AGAIN_LATER")}
                                onClick={handleRedirectToDashboard}
                            />
                        </>
                    )}
                </Stack>
            </Stack>
        </Box>
    );
};

export default RedirectStatus;
