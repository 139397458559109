// utils.ts

type TimeFormatOptions = {
  hour12?: boolean;
  locale?: string;
  timeZone?: string;
};

type DateFormatOptions = {
  locale?: string;
  useShortMonth?: boolean;
  timeZone?: string;
};

/**
 * Format a date object into a time string based on the provided options.
 * @param {Date} date - The date object to format.
 * @param {TimeFormatOptions} options - Formatting options.
 * @returns {string} Formatted time string.
 */
const formatTime = (
  date: Date,
  { hour12 = true, locale = "en-US", timeZone = "UTC" }: TimeFormatOptions = {}
): string => {
  return date.toLocaleTimeString(locale, {
    hour: "2-digit",
    minute: "2-digit",
    hour12,
    timeZone,
  });
};

/**
 * Format a date object into a date string based on the provided options.
 * @param {Date} date - The date object to format.
 * @param {DateFormatOptions} options - Formatting options.
 * @returns {string} Formatted date string.
 */
const formatDate = (
  date: Date,
  {
    locale = "en-US",
    useShortMonth = false,
    timeZone = "UTC",
  }: DateFormatOptions = {}
): string => {
  return date.toLocaleDateString(locale, {
    year: "numeric",
    month: useShortMonth ? "short" : "long",
    day: "numeric",
    timeZone,
  });
};

/**
 * Get current time in various formats.
 * @param {TimeFormatOptions} [options] - Custom options for formatting.
 * @returns {string[]} Array of formatted time strings.
 */
export const getCurrentTimeFormats = (
  options: TimeFormatOptions = {}
): string[] => {
  const now = new Date();
  const { hour12 = true, locale = "en-US", timeZone = "UTC" } = options;

  return [
    `12 Hours: ${formatTime(now, { hour12, locale, timeZone })}`,
    `24 Hours: ${formatTime(now, { hour12: false, locale, timeZone })}`,
  ];
};

/**
 * Get current date in various formats.
 * @param {DateFormatOptions} [options] - Custom options for formatting.
 * @returns {string[]} Array of formatted date strings.
 */
export const getCurrentDateFormats = (
  options: DateFormatOptions = {}
): string[] => {
  const now = new Date();
  const { locale = "en-US", useShortMonth = false, timeZone = "UTC" } = options;

  return [
    formatDate(now, { locale, useShortMonth, timeZone }),
    formatDate(now, { locale: "en-GB", useShortMonth, timeZone }),
  ];
};

export const formatFileSize = (sizeInBytes: number): string => {
  const units = ["B", "KB", "MB", "GB", "TB"];
  let size = sizeInBytes;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  return `${size.toFixed(2)} ${units[unitIndex]}`;
};

export const getBaseUrl = () => {
  const env = process.env.REACT_APP_NODE;

  switch (env) {
    case "production":
      return process.env.REACT_APP_BACKEND_BASEURL_PRODUCTION;
    case "localDevelopment":
      return process.env.REACT_APP_BACKEND__LOCAL_BASEURL_DEVELOPMENT;
    case "development":
      return process.env.REACT_APP_BACKEND_BASEURL_DEVELOPMENT;
    default:
      return "";
  }
};


// Define type for the variants
export type VariantKeys =
  | "@calls"
  | "@mention"
  | "@pending_events"
  | "@reminder_events"
  | "@flag"
  | "@chat"
  | "@recap"
  | "@timeoff"
  | "@expense"
  | "@payroll"
  | "@timesheet"
  | "@performance"
  | "@development"
  | "@compliance"
  | "@post_call"
  | "@upcoming_events"
  | "@ongoing_events"
  | "@past_events"
  | "@booking_links"
  | "@action_required"
  | "@read_mails"
  | "@unread_mails";

// Define the color mapping
export const variantColorMap: Record<VariantKeys, string> = {
  "@calls": "#2196F3",
  "@mention": "#FFC107",
  "@pending_events": "#FF5722",
  "@reminder_events": "#4CAF50",
  "@flag": "#F44336",
  "@chat": "#673AB7",
  "@recap": "#3F51B5",
  "@timeoff": "#009688",
  "@expense": "#8BC34A",
  "@payroll": "#CDDC39",
  "@timesheet": "#FF9800",
  "@performance": "#795548",
  "@development": "#607D8B",
  "@compliance": "#9E9E9E",
  "@post_call": "#8E44AD",
  "@upcoming_events": "#16A085",
  "@ongoing_events": "#16A085",
  "@past_events": "#8E44AD",
  "@booking_links": "#34495E",
  "@action_required": "#F44336",
  "@read_mails": "#F54336",
  "@unread_mails": "#F64336",
};