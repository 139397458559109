import { Stack } from "@mui/material";
import React from "react";
import { DateHeaderView, EventView, TaskView } from "./view";
import {
  adjustHexColor,
  CalendarTypesEnum,
  getUser,
} from "../../../../../Utils"; // Import your enum
import { eventResponse__api, updateEventTaskStatus__api } from "../../../../../redux";
import { useDispatch } from "react-redux";

interface SidebarCalendarProps {
  dataList: any[]; // The raw data you're passing in (the original 'datalist')
  transparantBackground?: boolean;
  maxHeight?: string;
}

const SidebarCalendar: React.FC<SidebarCalendarProps> = ({
  dataList,
  transparantBackground = true,
  maxHeight = "100vh",
}) => {
  const dispatch = useDispatch();
  console.log(dataList, "dataList")
  const userId = getUser();

  // Helper function to determine the status
  function determineStatus(item: any, currentUserId: string) {
    // If the current user is the creator, no specific status is required
    if (item?.createdBy === currentUserId) {
      return ""; // No status needed for the creator
    }

    // Check if the current user is in the guest list
    const guest =
      item?.guests &&
      item?.guests?.find((guest: any) => guest?.userId === currentUserId);

    if (guest) {
      if (guest.responseStatus === "maybe") {
        return "pending"; // Guest response is "maybe"
      } else if (guest.responseStatus === "rejected") {
        return "removed"; // Guest response is "rejected"
      }
      // If the response is "accepted" or any other, no status is required
      return "";
    }

    // If the user is not in the guest list and not the creator, default to "removed"
    return "removed";
  }

  // Helper function to format time
  function formatEventTime(
    startTime: string,
    endTime: string,
    timezone: string
  ): string {
    const options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      hour12: true, // For AM/PM format
      timeZone: timezone, // Use provided timezone
    };

    const formattedStartTime = new Intl.DateTimeFormat("en-US", options).format(
      new Date(startTime)
    );
    const formattedEndTime = new Intl.DateTimeFormat("en-US", options).format(
      new Date(endTime)
    );

    return `${formattedStartTime} - ${formattedEndTime} ${timezone}`;
  }

  // Transformation function to convert raw data into SidebarCalendarDataListInterface format
  const transformDataList = (data: any) => {
    const groupedData: any = {};

    data?.forEach((item: any) => {
      // Determine calendar type based on provider
      let calendarType;
      if (item?.calendarProvider === "Microsoft") {
        calendarType = CalendarTypesEnum.OutlookCalendar;
      } else if (item?.calendarProvider === "Google") {
        calendarType = CalendarTypesEnum.GoogleCalendar;
      } else if (item?.calendarProvider === "Teams") {
        calendarType = CalendarTypesEnum.TeamsCalendar;
      } else if (item?.calendarProvider === "Jira") {
        calendarType = CalendarTypesEnum.JiraCalendar;
      } else {
        calendarType = CalendarTypesEnum.BearishCalendar; // Fallback/default value
      }

      // Convert date to a human-readable format (e.g., Tuesday, Sep 10)
      const date = new Date(item?.startTime).toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      // Prepare the task or event object based on the type
      const dataItem = {
        type: item?.taskOrEvent,
        data: {
          id: item?._id,
          taskName: item?.taskOrEvent === "task" ? item?.title : undefined,
          isCompleted:
            item?.taskOrEvent === "task" ? item?.forTaskCompletion : undefined,
          taskTime: formatEventTime(
            item?.startTime,
            item?.endTime,
            item?.timezone
          ),
          calendarType: calendarType,
          eventName: item?.taskOrEvent === "event" ? item?.title : undefined,
          eventTime: formatEventTime(
            item?.startTime,
            item?.endTime,
            item?.timezone
          ), // Use helper to format
          status: determineStatus(item, userId?.data?._id), // Use a helper function for status
          e:item
        },
      };

      // Group by date
      if (!groupedData?.[date]) {
        groupedData[date] = [];
      }
      groupedData[date]?.push(dataItem);
    });

    // Convert grouped data into the final format
    return Object?.entries(groupedData)?.map(([date, dataList], index) => ({
      id: index + 1, // or use a unique identifier from your data if available
      dateAndTime: date,
      dataList,
    }));
  };

  // Ensure `transformDataList` returns an array, even if `dataList` is undefined or empty
  const finalDataList: any[] =
    Array.isArray(dataList) && dataList.length > 0
      ? transformDataList(dataList)
      : [];



  const handleEventResponse = async (cId: any, eId: any, ceType: any, responseType: string) => {
    try {
      if (!cId || !eId || !responseType) {
        return;
      }
      let forMatRes =
        responseType === "Accept"
          ? "accepted"
          : responseType === "Reject"
            ? "rejected"
            : responseType === "Maybe"
              ? "maybe"
              : null;

      if (forMatRes === null) {
        return;
      }

      const action = eventResponse__api(
        cId,
        eId,
        forMatRes,
        ceType
      );
      await dispatch(action);
    } catch (err) {
      console.log(err);
    }
  };

  const updateTaskState = async (cId: any, eId: any) => {
    if (!cId || !eId) {
      return;
    }

    const action = updateEventTaskStatus__api(
      cId,
      eId
    );

    await dispatch(action);
  };

  return (
    <Stack
      sx={{
        width: "265px",
        height: "100%",
        overflowY: "scroll",
        scrollbarWidth: "none",
        backgroundColor: transparantBackground
          ? adjustHexColor("#ffffff", 65)
          : "white",
        borderRadius: "5px",
        padding: "12px 12px 15px 15px",
        boxSizing: "border-box",
        gap: "3px",
        maxHeight: { maxHeight },
        borderTop: transparantBackground ? "1px solid #E9EDF2" : "",
        borderBottom: transparantBackground ? "1px solid #E9EDF2" : "",
        borderLeft: transparantBackground ? "1px solid #E9EDF2" : "",
      }}
    >
      {Array?.isArray(finalDataList) && finalDataList?.length > 0 ? (
        finalDataList?.map((item: any) => (
          <Stack sx={{ gap: "3px" }} key={item?.id}>
            <DateHeaderView date={item?.dateAndTime} />
            <Stack sx={{ gap: "3px" }}>
              {Array?.isArray(item?.dataList) &&
                item?.dataList?.map((value: any) =>
                  value?.type === "event" ? (
                    <EventView key={value?.data?.id} events={value?.data} handleEventResponse={handleEventResponse} />
                  ) : (
                    <TaskView updateTaskState={updateTaskState} key={value?.data?.id} tasks={value?.data} />
                  )
                )}
            </Stack>
          </Stack>
        ))
      ) : (
        <div>No data available</div> // Handle empty or invalid data
      )}
    </Stack>
  );
};

export default SidebarCalendar;
