import React from "react";
import { PayrollCardHeaderButton, PayrollCardMenu } from "../../common";
import { DealStageCurrencySymbols } from "../../../../common";
import { HRIcon } from "../../../../../images";

interface AllowancesPayrollCardProps {}

const AllowancesPayrollCard: React.FC<AllowancesPayrollCardProps> = ({}) => {
  const currencySymbol = DealStageCurrencySymbols.USD;
  return (
    <div>
      <PayrollCardHeaderButton
        bgColor="#FFA500"
        currencySymbol={currencySymbol}
        icon={HRIcon}
        label="Allowances"
        value={15000.0}
      >
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Travel"
          primaryValue={50000}
          secondryValue={50000}
          showReimbursementSymbol
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Meal"
          showReimbursementSymbol
          primaryValue={1000}
          secondryValue={1000}
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Housing"
          primaryValue={2500}
          secondryValue={3500}
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Relocation"
          primaryValue={5000}
          secondryValue={8500}
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Transportation"
          primaryValue={1000}
          secondryValue={1000}
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Communication"
          primaryValue={2000}
          secondryValue={2000}
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Other"
          primaryValue={500}
          secondryValue={800}
        />
      </PayrollCardHeaderButton>
    </div>
  );
};

export default AllowancesPayrollCard;
