import { Button, ButtonProps, Typography } from "@mui/material";
import React from "react";
import { adjustHexColor } from "../../../../../../../Utils";

interface ButtonViewProps extends ButtonProps {
  buttonLabel: string;
  bgclr: string;
}

const ButtonView: React.FC<ButtonViewProps> = ({
  bgclr,
  buttonLabel,
  ...props
}) => {
  return (
    <Button
      variant="text"
      sx={{
        border: "1px solid #E9EDF2",
        borderRadius: "5px",
        flexDirection: "row",
        height: "30px",
        boxSizing: "border-box",
        justifyContent: "center",
        flex: 1,
        alignItems: "center",
        textTransform: "none",
        bgcolor: adjustHexColor(bgclr, 55),
        ":hover": {
          bgcolor: adjustHexColor(bgclr, 70),
        },
      }}
      {...props}
    >
      <Typography
        sx={{
          font: "normal normal 900 13px/16px Source Serif Pro",
          color: "white",
        }}
      >
        {buttonLabel}
      </Typography>
    </Button>
  );
};

export default ButtonView;
