import React, { useRef, useState } from "react";
import { IconButton, Stack, SxProps, Typography } from "@mui/material";
import { PlayArrowIcon } from "../../../../../../../images";

interface TitleContainerProps {
  title: string;
  children: React.ReactNode;
  childrenSx?: SxProps;
  isCollapasable?: boolean;
  initialCollapsed?: boolean;
}

const TitleContainer: React.FC<TitleContainerProps> = ({
  children,
  title,
  childrenSx,
  isCollapasable = false,
  initialCollapsed = false,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(initialCollapsed);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setIsCollapsed((prev) => !prev);
  };

  return (
    <Stack sx={{ gap: "15px" }}>
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{ font: "normal normal 400 14px/18px Source Serif Pro" }}
        >
          {title}
        </Typography>
        {isCollapasable && (
          <IconButton sx={{ padding: "2px" }} onClick={handleToggle}>
            <PlayArrowIcon w={20} direction={isCollapsed ? "down" : "up"} />
          </IconButton>
        )}
      </Stack>
      <div
        ref={contentRef}
        style={{
          height: isCollapsed ? "0px" : `${contentRef.current?.scrollHeight}px`,
          overflow: "hidden",
          transition: "height 0.3s ease",
        }}
      >
        <Stack
          sx={{
            gap: "10px",
            ...childrenSx,
          }}
        >
          {children}
        </Stack>
      </div>
    </Stack>
  );
};

export default TitleContainer;
