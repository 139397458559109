import React from "react";
import {Avatar, Box, Button, Typography} from "@mui/material";
import {getHTMLContent} from "../../pages/Message/constant";
import {useTranslation} from "react-i18next";
import moment from "moment-timezone";

interface MessageReplyBar {
  message?: string;
  handleEmojiSelect?: any;
  handleSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleClick?: () => void;
  replyMessage?: any;
  handleCancelReply?: () => void;
  currentChannel: any;
}

const MessageReplyBar: React.FC<MessageReplyBar> = ({
  replyMessage,
  handleCancelReply,
  currentChannel,
}) => {
  const {t}=useTranslation()
  return (
      <Box display="flex" flexDirection="column" width="100%">
        {/* Replying to section */}
        {replyMessage && (
            <Box
                sx={{
                  borderRadius: "4px", // Rounded corners for the entire box
                  marginBottom: "10px",
                  border: "1px solid #E9EDF2", // Light gray border
                }}
            >
              <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "12px", // Inner padding
                    borderLeft: "30px solid #ddd7f8", // Purple left border
                    backgroundColor: "#FFFFFF", // White background
                    borderRadius: "4px",
                  }}
              >
                {/* Left Section: Message and Avatar */}
                <Box display="flex" flexDirection="column">
                  <Typography
                      sx={{
                        fontFamily: "Source Serif Pro, serif",
                        fontWeight: 600,
                        fontSize: "16px",
                        lineHeight: "20px",
                        color: "#000000", // Black text
                      }}
                  >
                    {t('MESSAGE.REPLYING_TO')}
                  </Typography>
                  <Box display="flex" alignItems="center" marginTop="8px">
                    {/* Avatar */}
                    <Avatar
                        src={replyMessage?.messageDetails?.avatar || ""}
                        alt={"nameLetters"}
                        sx={{
                          width: "32px",
                          height: "32px",
                          marginRight: "8px",
                        }}
                    >
                      <Typography>{currentChannel?.displayName?.charAt(0)}</Typography>
                    </Avatar>
                    {/* Message Details */}
                    <Box display="flex" flexDirection="column">
                      <Box display="flex" flexDirection="row">
                      <Typography
                          sx={{
                            fontFamily: "Source Serif Pro, serif",
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "18px",
                            color: "#000000",
                          }}
                      >
                        {replyMessage?.messageDetails?.displayName} {" "}
                      </Typography>
                      <Typography
                          sx={{
                            fontFamily: "Source Serif Pro, serif",
                            fontWeight: 300,
                            fontSize: "14px",
                            lineHeight: "18px",
                            color: "#000000",
                            marginLeft:"5px"
                          }}
                      >
                        {" "}  at {moment(replyMessage?.messageDetails?.createdAt).format("MM/DD/YYYY [at] hh:mm A")}
                      </Typography>
                        </Box>
                        {/* Message Content */}
                      <Typography
                          sx={{
                            fontFamily: "Source Serif Pro, serif",
                            fontWeight: 300,
                            fontSize: "14px",
                            lineHeight: "18px",
                            color: "#5F6368", // Light gray for the message text
                          }}
                          dangerouslySetInnerHTML={{
                            __html: getHTMLContent(replyMessage?.content),
                          }}
                      ></Typography>
                    </Box>
                  </Box>
                </Box>

                {/* Cancel Button */}
                <Button
                    size="small"
                    onClick={handleCancelReply}
                    sx={{
                      font: "normal normal 600 13.78px/11px Source Serif Pro",
                      padding: "4px 12px",
                      borderRadius: "16px",
                      color: "#f50057", // Pink for the cancel button
                      textTransform: "none", // Keeps text case as it is
                      ":hover": {
                        backgroundColor: "rgba(245, 0, 87, 0.1)", // Soft pink hover
                      },
                    }}
                >
                  {t('MESSAGE.CANCEL')}
                </Button>
              </Box>
            </Box>
        )}
      </Box>
  );
};

export default MessageReplyBar;
