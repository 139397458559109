import React, { useState } from "react";
import {
  SmallGroupedAvatarDataTpyes,
  SmallGroupedAvatars,
} from "../../../../../../../common";
import { Popper, Stack, Typography } from "@mui/material";
import { UserInfoChip } from "../../../../../../Email/components";

interface MultiUserDataAreaProps {
  userDataList?: SmallGroupedAvatarDataTpyes[];
}

const MultiUserDataArea: React.FC<MultiUserDataAreaProps> = ({
  userDataList = [],
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMouseLeave = () => {
    if (anchorEl && !anchorEl.contains(document.activeElement)) {
      setAnchorEl(null);
    }
  };
  return (
    <Stack
      sx={{
        cursor: "pointer",
        width: "fit-content",
      }}
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <SmallGroupedAvatars maxAvatars={4} usersData={userDataList} />

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-start"
        onMouseLeave={handleMouseLeave}
        sx={{
          zIndex: 10,
          boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
          borderRadius: "5px",
          paddingTop: "10px",
        }}
      >
        <Stack
          sx={{
            maxHeight: "200px",
            overflow: "hidden",
            bgcolor: "white",
          }}
        >
          <Typography
            sx={{
              font: "normal normal 400 13px/16px Source Serif Pro",
              margin: "10px 0px 0px 10px",
            }}
          >
            Participants
          </Typography>
          <Stack
            sx={{
              borderRadius: "5px",
              width: "100%",
              overflow: "auto",
              scrollbarWidth: "none",
              flexDirection: "column",
              alignItems: "start",
              boxSizing: "border-box",
              padding: "10px",
              gap: "10px",
            }}
          >
            {userDataList.map((userData, index) => (
              <UserInfoChip
                containerSx={{ maxWidth: "100%", width: "100%" }}
                chipHeight="30px"
                key={index}
                popoverPlace="right"
                userName={userData.name || ""}
                userEmail={userData.email || ""}
                userIconSrc={userData.imgSrc}
                userAvatarColor={userData.avatarColor}
              />
            ))}
          </Stack>
        </Stack>
      </Popper>
    </Stack>
  );
};

export default MultiUserDataArea;
