import React, { useState, useRef,useEffect } from "react";
import {
  IconButton,
  Stack,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import {
  ChevronIcon,
  PauseCircleIcon,
  PlayCircleIcon,
  RadioButtonIcon,
  StopCircleIcon,
} from "../../../../../images";
import styles from "./RecordingCallMenu.module.css";
import { useTranslation } from "react-i18next";
import {saveMeetingRecording, uploadFile__api} from "../../../../../redux";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../redux/store";
import {VideoCenterState} from "../../../../../redux/Slice/videoCenter/VideoCenterTypes";

interface RecordingCallMenuProps {}

type RecordingStatusType = "default" | "started" | "pause" | "stopped";

type StatusListProps = {
  id: number;
  icon: React.ElementType;
  label: string;
  value: RecordingStatusType;
  hoverText: string;
};

const RecordingCallMenu: React.FC<RecordingCallMenuProps> = () => {
  const { t } = useTranslation();
  const [recordingStatus, setRecordingStatus] =
      useState<RecordingStatusType>("default");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [time, setTime] = useState(0);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const recordedChunksRef = useRef<Blob[]>([]);
  const [isRecording, setIsRecording] = useState(false);

  const { onGoingMeeting } = useSelector(
      (state: RootState) => state.videoCenter as VideoCenterState
  );
  const statusList: StatusListProps[] = [
    {
      id: 1,
      icon: PlayCircleIcon,
      label: t("VIDEO_CENTER.MEETING.START_RECORDING_CALL"),
      value: "started",
      hoverText:
          "When starting a recording this call will be recorded and saved with an attached transcription.",
    },
    {
      id: 2,
      icon: PauseCircleIcon,
      label: t("VIDEO_CENTER.MEETING.PAUSE_RECORDING"),
      value: "pause",
      hoverText:
          "Pausing this recording will pause the recording and transcription, but you can continue to record by pressing start.",
    },
    {
      id: 3,
      icon: StopCircleIcon,
      label: t("VIDEO_CENTER.MEETING.STOP_RECORDING"),
      value: "stopped",
      hoverText:
          "Stopping the recording will finalize the video and transcription for this call, you can not start a new recording with this call if you stop the recording.",
    },
  ];

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    if (recordingStatus === "started") {
      timer = setInterval(() => setTime((prev) => prev + 1), 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [recordingStatus]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = async (value: RecordingStatusType) => {
    switch (value) {
      case "started":
        if (recordingStatus === "pause") {
          mediaRecorderRef.current?.resume();
        } else {
          await handleScreenRecordClick();
        }
        break;
      case "pause":
        mediaRecorderRef.current?.pause();
        break;
      case "stopped":
        mediaRecorderRef.current?.stop();
        break;
      default:
        break;
    }
    setRecordingStatus(value);
    handleClose();
  };

  const checkDisableMenu = (status: RecordingStatusType): boolean => {
    if (
        recordingStatus === "default" &&
        (status === "pause" || status === "stopped")
    )
      return true;
    if (recordingStatus === "started" && status === "started") return true;
    return recordingStatus === "pause" && status === "pause";
  };

  const getStatus = (): { colorValue: string; labelText: string } => {
    switch (recordingStatus) {
      case "started":
        return {
          colorValue: "#FF0000",
          labelText: t("VIDEO_CENTER.MEETING.RECORDING_IN_PROGRESS"),
        };
      case "pause":
        return {
          colorValue: "#FFB100",
          labelText: t("VIDEO_CENTER.MEETING.RECORDING_PAUSED"),
        };
      case "stopped":
        return {
          colorValue: "#000",
          labelText: t("VIDEO_CENTER.MEETING.STOP_RECORDING"),
        };
      default:
        return {
          colorValue: "#707070",
          labelText: t("VIDEO_CENTER.MEETING.RECORD_CALL"),
        };
    }
  };

  const handleScreenRecordClick = async () => {
    if (isRecording) {
      mediaRecorderRef.current?.stop();
      setIsRecording(false);
    } else {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      recordedChunksRef.current = [];

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunksRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = async () => {
        const blob = new Blob(recordedChunksRef.current, {
          type: "video/webm",
        });
        const file = new File([blob], "recording.webm", {
          type: "video/webm",
        });

        const formData = new FormData();
        formData.append("files", file);

        const action = uploadFile__api(formData);
        try {
          const response: any = await dispatch(action);
          if (response?.success && response?.data) {
            const payload = {
              recordingUrl : response?.data[0].path,
              title: "Recording",
            }
            await dispatch(saveMeetingRecording(onGoingMeeting?.meeting?._id,payload))
          }
        } catch (e) {
          console.log(e, "Error uploading");
        }
      };

      mediaRecorder.start();
      setIsRecording(true);
    }
  };

  return (
      <Stack className={styles["RecordingCallMenu__container"]}>
        <Stack className={styles["RecordingCallMenu__status"]}>
          <RadioButtonIcon w={23} color={getStatus().colorValue} />
          <Typography className={styles["RecordingCallMenu__text"]}>
            {getStatus().labelText}
          </Typography>
        </Stack>

        <Stack className={styles["RecordingCallMenu__controls"]}>





          <Typography className={styles["RecordingCallMenu__time"]}>
            {new Date(time * 1000).toISOString().substr(14, 5)}
          </Typography>
          <IconButton
              disabled={recordingStatus === "stopped"}
              className={styles["RecordingCallMenu__iconButton"]}
              onClick={handleClick}
          >
            <ChevronIcon w={15} />
          </IconButton>

        </Stack>

        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              style: {
                boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
                marginTop: "10px",
                padding: 0,
              },
            }}
        >
          {statusList.map((status) => (
              <MenuItem
                  disabled={checkDisableMenu(status.value)}
                  key={status.id}
                  onClick={() => handleMenuItemClick(status.value)}
                  className={styles["RecordingCallMenu__menuItem"]}
              >
                <Tooltip
                    title={
                      <Typography className={styles["RecordingCallMenu__hoverText"]}>
                        {status.hoverText}
                      </Typography>
                    }
                    placement="top"
                    arrow
                    disableInteractive
                    classes={{
                      tooltip: styles.customTooltip,
                      arrow: styles.customTooltipArrow,
                    }}
                >
                  <Stack className={styles["RecordingCallMenu__menuContent"]}>
                    {React.createElement(status.icon, { w: 15 })}
                    <Typography className={styles["RecordingCallMenu__menuText"]}>
                      {recordingStatus === "pause" &&
                      status.label === "Start recording call"
                          ? "Resume recording call"
                          : status.label}
                    </Typography>
                  </Stack>
                </Tooltip>
              </MenuItem>
          ))}
        </Menu>
      </Stack>
  );
};

export default RecordingCallMenu;