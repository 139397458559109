import React,{useState} from "react";
import {
  Box,
  Typography,
  Button, Popover,
} from "@mui/material";
import { MessageReplyBar } from "../MessageReplyBar";
import { ScheduleMessage } from "../../pages/Message/components";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { scheduleMessage } from "../../../redux/Slice/message/messageActions";
import CustomFroalaEditor from "../CustomFroalaEditor";
import "froala-editor/css/froala_editor.pkgd.min.css";
import { useTranslation } from "react-i18next";
import {EmojiPickerSmallButton} from "../EmojiPickerSmallButton";

interface MessageActionBarProps {
  message?: string;
  handleEmojiSelect?: any;
  handleSearchChange?: any;
  handleKeyPress?: any;
  handleSendMessage?: () => void;
  replyMessage?: any; // New prop for handling the message being replied to
  handleCancelReply?: () => void; // To cancel the reply
  currentChannel?: any;
  handleClearInput: any;
  userDetails?: any;
  openSchedule?: any;
  setOpenSchedule?: any;
}

const MessageActionBar: React.FC<MessageActionBarProps> = ({
  message,
  handleEmojiSelect,
  handleSearchChange,
  handleKeyPress,
  handleSendMessage,
  replyMessage,
  handleCancelReply,
  currentChannel,
  handleClearInput,
  userDetails,
  openSchedule,
  setOpenSchedule,
}) => {
  const {t}=useTranslation()
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenSchedule = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenSchedule(!openSchedule);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenSchedule(false);
  };

  function formatHtml(html:any) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    function processNode(node:any) {
      if (node.nodeType === Node.TEXT_NODE) {
        return node.textContent.trim() + " ";
      }
      let result = "";

      for (let child of node.childNodes) {
        if (child.nodeName === "STRONG") {
          result += "*" + processNode(child) + "* ";
        } else if (child.nodeName === "EM") {
          result += "_" + processNode(child) + "_ ";
        } else if (child.nodeName === "BR") {
          result += "\n"; // Replace <br> with newline
        } else {
          result += processNode(child);
        }
      }
      return result;
    }

    return processNode(tempDiv).trim();
  }

  const handleScheduleMessage = async (dateAndTime: any) => {
    if (!message) {
      setOpenSchedule(false);
      return;
    }
    const formattedDateTime = dayjs(dateAndTime).second(0).toISOString();
    const formattedMessage = formatHtml(message);
    const payload = {
      scheduledAt: formattedDateTime,
      userId: userDetails?.data._id,
      content: formattedMessage,
      channelId: currentChannel?._id,
      channelType: currentChannel?.channelType
    };
    await dispatch(scheduleMessage(payload));

    handleClearInput();
    setOpenSchedule(false);
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box
        display="flex"
        alignItems="center"
        border="1px solid #D9E3EF"
        borderRadius="4px"
        padding="10px"
        width="100%"
        flexDirection="column"
      >
        {replyMessage?.content && (
            <MessageReplyBar
                currentChannel={currentChannel}
                handleCancelReply={handleCancelReply}
                replyMessage={replyMessage}
            />
        )}
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          marginRight="8px"
          width="100%"
        >
        <Box display="flex" alignItems="center" marginRight="16px">
              <EmojiPickerSmallButton
                w={20}
                onSelect={handleEmojiSelect}
                tooltipTitle={t('MESSAGE.ADD_EMOJI')}
                labeltext={t('MESSAGE.EMOJIS')}
              />
        </Box>
        <CustomFroalaEditor
          currentChannel={currentChannel}
          value={message}
          handleSendMessage={handleSendMessage}
          handleSearchChanges={handleSearchChange}
        />
        {/*<Box*/}
        {/*  display="flex"*/}
        {/*  flexDirection="column"*/}
        {/*  alignItems="center"*/}
        {/*  marginRight="8px"*/}
        {/*>*/}
        {/*  <IconButton size="small">*/}
        {/*    <VoiceRecordIcon w={20} />*/}
        {/*  </IconButton>*/}
        {/*  <Typography variant="caption">{t('MESSAGE.RECORD')}</Typography>*/}
        {/*</Box>*/}
          <Popover
              id={anchorEl ? 'simple-popover' : undefined}
              open={openSchedule}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical:  "bottom",
                horizontal: "right",
              }}
          >
            <ScheduleMessage
                onSchedule={handleScheduleMessage}
            />
          </Popover>
        <Button
          variant="outlined"
          style={{
            borderColor: "#D9E3EF",
            color: "black",
            textTransform: "none",
            width:"95px",
            height:"35px"
          }}
          disabled={!message}
          onClick={handleOpenSchedule}
        >
          <Typography sx={{ font: "normal normal 400 16.29px/13px Source Serif Pro"}} >
            {t('MESSAGE.SCHEDULE')}
          </Typography>
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{
            marginLeft: "8px",
            color: "white",
            backgroundColor: "#775EE2",
            textTransform: "none",
            width:"95px",
            height:"35px"
          }}
          sx={{ font: "normal normal 400 16.29px/13px Source Serif Pro"}}
          onClick={handleSendMessage}
          disabled={!message}
        >
          <Typography sx={{ font: "normal normal 400 16.29px/13px Source Serif Pro"}} >
            {t('MESSAGE.SEND')}
          </Typography>
        </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MessageActionBar;
