import {
    InputBase,
    Stack,
    Typography,
    ClickAwayListener,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import styles from "./SearchProjectsDropdown.module.css";
import {
    ProjectIcon,
} from "../../../images";
import { SearchProjectsDropdownInterface } from "./SearchProjectsDropdownType";


interface SearchProjectsDropdownProps {
    options?: SearchProjectsDropdownInterface[];
    onSelect?: (selectedOption: SearchProjectsDropdownInterface) => void;
    alwaysOpen?: boolean
}

const SearchProjectsDropdown: React.FC<SearchProjectsDropdownProps> = ({
    options,
    onSelect,
    alwaysOpen = false
}) => {
    const [inputValue, setInputValue] = useState<string>("");
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);

    const dropdownRef = useRef<HTMLDivElement | null>(null);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
        setIsDropdownOpen(true);
        setHighlightedIndex(-1); // Reset the highlighted index when input changes
    };

    const handleOptionClick = (option: any) => {
        if (onSelect) {
            onSelect(option);
        }
        setInputValue(option.name || option.name);
        setIsDropdownOpen(false);
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (!isDropdownOpen || filteredOptions.length === 0) return;

        switch (event.key) {
            case "ArrowDown":
                event.preventDefault();
                setHighlightedIndex((prevIndex) =>
                    prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : 0
                );
                break;
            case "ArrowUp":
                event.preventDefault();
                setHighlightedIndex((prevIndex) =>
                    prevIndex > 0 ? prevIndex - 1 : filteredOptions.length - 1
                );
                break;
            case "Enter":
                event.preventDefault();
                if (
                    highlightedIndex >= 0 &&
                    highlightedIndex < filteredOptions.length
                ) {
                    handleOptionClick(filteredOptions[highlightedIndex]);
                }
                break;
            case "Escape":
                setIsDropdownOpen(false);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        // Scroll to the highlighted option if dropdownRef and highlighted option exist
        if (dropdownRef.current && highlightedIndex >= 0) {
            const highlightedOption = dropdownRef.current.children[
                highlightedIndex
            ] as HTMLElement;
            highlightedOption.scrollIntoView({ block: "nearest" });
        }
    }, [highlightedIndex]);

    const filteredOptions = options?.filter(
        (option) =>
            option?.name?.toLowerCase()?.includes(inputValue?.toLowerCase()) ||
            option?.name?.toLowerCase()?.includes(inputValue?.toLowerCase())
    ) || [];

    return (
        <ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
            <Stack className={styles.SearchProjectsDropdown}>
                <InputBase
                    sx={{ borderRadius: isDropdownOpen ? "5px 5px 0px 0px" : "5px" }}
                    placeholder="Search all Project"
                    value={inputValue}
                    onChange={handleInputChange}
                    className={styles.SearchProjectsDropdown__inputBase}
                    onClick={() => (inputValue ? setIsDropdownOpen(true) : null)}
                    onKeyDown={handleKeyDown}
                />
                {(alwaysOpen || (isDropdownOpen && filteredOptions?.length > 0)) && (
                    <Stack
                        className={styles.SearchProjectsDropdown__dropdown}
                        ref={dropdownRef}
                    >
                        {filteredOptions?.length > 0 ? filteredOptions?.map((option, index) => (
                            <Stack
                                key={option?._id || index}
                                className={`${styles.SearchProjectsDropdown__item} ${index === highlightedIndex
                                    ? styles.SearchProjectsDropdown__itemHighlighted
                                    : ""
                                    }`}
                                onClick={() => handleOptionClick(option)}
                                sx={{ bgcolor: "rgba(25, 118, 210, 0.12)" }}
                            >
                                <ProjectIcon w={20} />
                                <Typography className={styles.SearchProjectsDropdown__itemText}>
                                    {option?.name || option?.name}
                                </Typography>
                            </Stack>
                        )) :
                            <Stack sx={{ flexDirection: 'row', justifyContent: 'center', paddingTop: "20px", width: "100%" }} >
                                <Typography sx={{ font: 'normal normal 300 14px/18px Source Serif Pro' }} >No Data Found</Typography>
                            </Stack>

                        }
                    </Stack>
                )}
            </Stack>
        </ClickAwayListener>
    );
};

export default SearchProjectsDropdown;
