import { Button, Popover, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { CRMIcon } from "../../../../../../../Router/RouteIcon";
import {
  DropdownWithImageBadge,
  DUMMY_USER_EMAIL_LIST,
} from "../../../../../../common";

interface ConnectedPeopleViewProps {}

const ConnectedPeopleView: React.FC<ConnectedPeopleViewProps> = ({}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "currency-popover" : undefined;
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "start",
        justifyContent: "space-between",
      }}
    >
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
        <CRMIcon w={15} />
        <Typography
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
        >
          Connected Projects
        </Typography>

        <Button
          aria-describedby={id}
          onClick={handleClick}
          variant="text"
          sx={{
            textTransform: "none",
            color: "#0027FF",
            marginLeft: "5px",
            padding: "2px 5px",
            borderRadius: "5px",
            font: "normal normal 300 11px/14px Source Serif Pro",
          }}
        >
          Add people
        </Button>
      </Stack>

      <Stack sx={{ width: "200px" }}>
        <DropdownWithImageBadge
          onChangeSelection={() => null}
          readonlyComponent
          initialData={DUMMY_USER_EMAIL_LIST.slice(3, 6)}
          showMaxSelected={2}
        />
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            padding: 0,
            width: "350px",
            height: "350px",
            scrollbarWidth: "none",
          },
        }}
      >
        <DropdownWithImageBadge
          onChangeSelection={() => null}
          initialData={DUMMY_USER_EMAIL_LIST.slice(1, 4)}
          placeholder="Search here..."
          alwaysShowPlaceholder
          showMaxSelected={4}
          options={DUMMY_USER_EMAIL_LIST}
        />
      </Popover>
    </Stack>
  );
};

export default ConnectedPeopleView;
