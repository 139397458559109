import {Stack, Typography} from "@mui/material";
import React, {useState, useEffect} from "react";
import {adjustHexColor} from "../../../../../Utils";
import {ButtonView} from "./view";
import {useTranslation} from "react-i18next";
import axios from "axios";
import moment from "moment-timezone";
import {useDispatch} from "react-redux";
import {setUserTimezoneAction} from "../../../../../redux/Slice/Global/globalSlice";

interface TodayShiftDashboardProps {
}

const TodayShiftDashboard: React.FC<TodayShiftDashboardProps> = ({}) => {
    const {t} = useTranslation();
    const [localTime, setLocalTime] = useState<string>("");
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchTimezone = async () => {
            try {
                const response = await axios.get("https://ipapi.co/json/");
                const timezone = response.data.timezone;
                if (timezone) {
                dispatch(setUserTimezoneAction(timezone));
                updateLocalTime(timezone);
                }
                const intervalId = setInterval(() => {
                    updateLocalTime(timezone);
                }, 60000);
                return () => clearInterval(intervalId);
            } catch (error) {
                console.error("Error fetching timezone:", error);
            }
        };

        const updateLocalTime = (timezone: string) => {
            setLocalTime(moment.tz(timezone).format("hh:mm a z"));
        };

        fetchTimezone();
    }, []);


    return (
        <Stack
            sx={{
                height: "265px",
                width: "265px",
                borderRadius: "5px",
                boxSizing: "border-box",
                boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.25)",
                bgcolor: adjustHexColor("#ffffff", 65),
                borderTop: "1px solid #E9EDF2",
                borderLeft: "1px solid #E9EDF2",
                borderBottom: "1px solid #E9EDF2",
                padding: "15px 10px 10px 13px",
                gap: "20px",
                flexShrink: 0,
            }}
        >
            <Stack
                sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Typography
                    sx={{font: "normal normal 400 16px/20px Source Serif Pro"}}
                >
                    {t("DASHBOARD.TODAY_SHIFT")}
                </Typography>
                <Typography
                    sx={{font: "normal normal 300 16px/20px Source Serif Pro"}}
                >
                    {localTime}
                </Typography>
            </Stack>

            <Stack gap={"5px"}>
                <Stack sx={{flexDirection: "row", alignItems: "center", gap: "10px"}}>
                    <ButtonView buttonLabel="Clock In" bgclr="#308893"/>
                    <ButtonView buttonLabel="Clock Out" bgclr="#FF0000"/>
                </Stack>

                <ButtonView bgclr="#FFB100" buttonLabel="Clock Out, On a Break"/>
            </Stack>
        </Stack>
    );
};

export default TodayShiftDashboard;
