import React from "react";
import style from "./MainActionButton.module.css";
import { Button, ButtonProps, Stack, Typography } from "@mui/material";
import { HomeIcon, EditIcon, AddCalPlusIcon } from "../../../../images";
import { ButtonType, MainActionColor } from "../AllButtonProps";

interface ButtonXLargeProps extends ButtonProps {
  colorVarient: MainActionColor;
  icon?: "editIcon" | "circlePlus";
  iconColor?: string;
  label: string;
  types?: ButtonType;
}

const MainActionButton: React.FC<ButtonXLargeProps> = ({
  colorVarient,
  icon = "editIcon",
  iconColor = "white",
  label,
  types = ButtonType.Button,
  ...props
}) => {
  const getIcon = (): React.ReactNode => {
    switch (icon) {
      case "editIcon":
        return <EditIcon color={iconColor} w={20} />;
      case "circlePlus":
        return <AddCalPlusIcon color={iconColor} w={20} />;
    }
  };

  return (
    <Button
      disableElevation
      variant="contained"
      type={types}
      className={`
        ${style.MainActionButton}
        ${style[`MainActionButton--${colorVarient}`]}
      `}
      {...props}
    >
      <Stack className={style.MainActionButton__box}>
        {getIcon()}
        <Typography className={style.MainActionButton__font}>
          {label}
        </Typography>
      </Stack>
    </Button>
  );
};

export default MainActionButton;
