export type ExpensesBadgesDataType = {
  badgeLabel: string;
  badgeColor: string;
  fontColor?: string;
};

export type ExpensesBadgesMenuType = {
  _id: string;
  divider?: false;
  badgeData: ExpensesBadgesDataType;
  subTypeName?: string[];
};

type DividerType = {
  _id: string;
  divider: true;
  badgeData?: undefined;
  subTypeName?: undefined;
};

export type ExpensesBadgesMenuListType = ExpensesBadgesMenuType | DividerType;

// export const ExpensesBadgesList: ExpensesBadgesDataType[] = [
//     { id: 1, badgeLabel: "Travel", badgeColor: "#4A90E2", fontColor: "#000" },
//     { id: 1, badgeLabel: "Lodging", badgeColor: "#FF95F1", fontColor: "#000" },
//     { id: 1, badgeLabel: "Meals", badgeColor: "#FFA500", fontColor: "#000" },
//     { id: 1, badgeLabel: "Office", badgeColor: "#A9A9A9", fontColor: "#000" },
//     { id: 1, badgeLabel: "Professionals", badgeColor: "#228B22", fontColor: "#fff" },
//     { id: 1, badgeLabel: "Business Fees", badgeColor: "#800080", fontColor: "#fff" },
//     { id: 1, badgeLabel: "Conferences", badgeColor: "#8B0000", fontColor: "#fff" },
//     { id: 1, badgeLabel: "Benefits", badgeColor: "#90EE90", fontColor: "#000" },
//     { id: 1, badgeLabel: "Other", badgeColor: "#FFD700", fontColor: "#000" },
// ]

export const ExpensesBadgesMenuOptions: ExpensesBadgesMenuListType[] = [
  {
    _id: '1',
    badgeData: {
      badgeLabel: "Travel",
      badgeColor: "#4A90E2",
      fontColor: "#000",
    },
    subTypeName: [
      "Flights",
      "Ground",
      "Train",
      "Rental Cars",
      "Mileage",
      "Parking Fees",
      "Tolls",
      "Other",
    ],
  },
  {
    _id: '2',
    badgeData: {
      badgeLabel: "Lodging",
      badgeColor: "#FF95F1",
      fontColor: "#000",
    },
    subTypeName: [
      "Hotels",
      "Short Term",
      "Extended Stay",
      "Room Service",
      "Laundry Fees",
      "Other",
    ],
  },
  {
    _id: '3',
    badgeData: {
      badgeLabel: "Meals",
      badgeColor: "#FFA500",
      fontColor: "#000",
    },
    subTypeName: [
      "Client Meals",
      "Employee Meals",
      "Per Diem",
      "Entertainment",
      "Event Catering",
      "Other Meals",
    ],
  },

  { _id: '4', divider: true },

  {
    _id: '5',
    badgeData: {
      badgeLabel: "Office",
      badgeColor: "#A9A9A9",
      fontColor: "#000",
    },
    subTypeName: [
      "Office Supplies",
      "IT Equipment ",
      "Software SaaS",
      "Furniture",
      "Technical Accessories",
      "Other",
    ],
  },
  {
    _id: '6',
    badgeData: {
      badgeLabel: "Professionals",
      badgeColor: "#228B22",
      fontColor: "#fff",
    },
    subTypeName: [
      "Consultants",
      "Contractors",
      "Training",
      "Development",
      "Recruitment",
      "Other",
    ],
  },
  {
    _id: '7',
    badgeData: {
      badgeLabel: "Business Fees",
      badgeColor: "#800080",
      fontColor: "#fff",
    },
    subTypeName: ["Subscriptions", "Cloud Services", "Telecoms"],
  },
  {
    _id: '8',
    badgeData: {
      badgeLabel: "Conferences",
      badgeColor: "#8B0000",
      fontColor: "#fff",
    },
    subTypeName: [
      "Registration",
      "Booths",
      "Promotional",
      "Transportation",
      "Other",
    ],
  },

  { _id: '9', divider: true },

  {
    _id: '10',
    badgeData: {
      badgeLabel: "Benefits",
      badgeColor: "#90EE90",
      fontColor: "#000",
    },
    subTypeName: ["Health", "Wellness", "Education", "Remote Stipend", "Other"],
  },
  {
    _id: '11',
    badgeData: {
      badgeLabel: "Other",
      badgeColor: "#FFD700",
      fontColor: "#000",
    },
    subTypeName: ["Petty Cash", "Donations", "Gifts", "Other"],
  },
];
