import { Box, Stack, SxProps } from "@mui/material";
import React, {useEffect,useRef} from "react";
import { DummyImage } from "../../../images";

interface LiveCameraPreviewAreaProps {
  sx?: SxProps;
    isCameraActive: boolean;
    userDetails?: any;
}

const LiveCameraPreviewArea: React.FC<LiveCameraPreviewAreaProps> = ({
  sx,
                                                                         isCameraActive,
                                                                         userDetails
}) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (isCameraActive && videoRef.current) {
            navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
                videoRef.current!.srcObject = stream;
            });
        }
    }, [isCameraActive]);

  return (
      <Stack
          sx={{
              height: "200px",
              width: "200px",
              borderRadius: "200px",
              boxSizing: "border-box",
              bgcolor: "#E9EDF2",
              overflow: "hidden",
              ...sx,
          }}
      >
          {isCameraActive ? (
              <video
                  ref={videoRef}
                  autoPlay
                  style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "200px",
                      objectFit: "cover"
                  }}
              />
          ) : (
              <Box
                  sx={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "200px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "50px",
                      color: "#fff",
                  }}
              >
                  {userDetails?.avatar ? (
                      <img src={userDetails.avatar} alt="avatar" style={{ borderRadius: "200px", width: "100%", height: "100%" }} />
                  ) : (
                      `${userDetails?.firstName?.charAt(0)}${userDetails?.lastName?.charAt(0)}`
                  )}
              </Box>
          )}
      </Stack>
  );
};

export default LiveCameraPreviewArea;
