import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { AddNowTeamMembers } from "../AddNowTeamMembers";

interface ProjectSearchedCardProps {
  iconType: "emoji" | "img" | "svg";
  icon: string | React.ElementType;
}

/* <Box sx={{ fontSize: "35px" }}>😄</Box>; */

const ProjectSearchedCard: React.FC<ProjectSearchedCardProps> = ({
  iconType,
  icon,
}) => {
  const renderIcon = () => {
    switch (iconType) {
      case "emoji":
        return (
          <Box sx={{ fontSize: "33px" }}>
            {typeof icon === "string" ? icon : ""}
          </Box>
        );

      case "img":
        return (
          <Box
            component={"img"}
            src={typeof icon === "string" ? icon : ""}
            sx={{ height: "35px", width: "35px" }}
          />
        );

      case "svg":
        return icon && typeof icon !== "string" ? (
          <span>{React.createElement(icon, { w: 35, color: "black" })}</span>
        ) : null;

      default:
        return null;
    }
  };

  return (
    <Stack
      sx={{
        height: "45px",
        width: "100%",
        flexShrink: 0,
        flexDirection: "row",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "0px 17px 0px 8px",
        gap: "6px",
      }}
    >
      <Stack
        sx={{
          width: "45px",
          flexShrink: 0,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {renderIcon()}
      </Stack>
      <Stack sx={{ width: "calc(100% - 145px)", gap: "3px" }}>
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            font: "normal normal 600 18px/23px Source Serif Pro",
          }}
        >
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Harum
          molestias rerum eligendi ullam odit? Aliquam.
        </Typography>
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            font: "normal normal 300 13px/16px Source Serif Pro",
          }}
        >
          10/10/2024
        </Typography>
      </Stack>
      <AddNowTeamMembers buttonType="invite" />
    </Stack>
  );
};

export default ProjectSearchedCard;
