import { Stack, Typography } from "@mui/material";
import React from "react";

interface BreakViewProps {
  totalBreak?: number;
  usedBreak?: number;
}

const BreakView: React.FC<BreakViewProps> = ({
  totalBreak = 0,
  usedBreak = 0,
}) => {
  const formatNumber = (num: number): string => num.toString().padStart(2, "0");

  return (
    <Stack>
      <Typography sx={{ font: "normal normal 400 12px/15px Source Serif Pro" }}>
        {formatNumber(usedBreak)} of {formatNumber(totalBreak)}
      </Typography>
    </Stack>
  );
};

export default BreakView;
