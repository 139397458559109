import React, { useState } from "react";
import { AdditionalFilterMultiRadioBtnType } from "../../../../CommandControlSearchContainer";
import TitleContainer from "../../TitleContainer/TitleContainer";
import { MultiSelectRadioBtn } from "../../../../../../../common";

const bookingLinkList: AdditionalFilterMultiRadioBtnType = {
  _id: "bookingLink",
  field: ["calendar"],
  translatedTitle: "Booking Link",
  option: [
    {
      _id: "1",
      translatedTitle: "Booked via Booking Link",
      value: "Booked via Booking Link",
    },
    {
      _id: "2",
      translatedTitle: "Manually Scheduled",
      value: "Manually Scheduled",
    },
    {
      _id: "3",
      translatedTitle: "Scheduled by BEBA",
      value: "Scheduled by BEBA",
    },
  ],
};

type ComponentDataType = string[]; // this is type for this component data
interface BookingLinkProps {
  stateData: ComponentDataType; // Current data passed from the parent
  setStateData: (data: ComponentDataType) => void; // Function to update data in the parent
}

const BookingLink: React.FC<BookingLinkProps> = ({
  setStateData,
  stateData,
}) => {
  const handleSelectionChange = (value: string) => {
    const updatedMeetingTypes = stateData.includes(value)
      ? stateData.filter((item) => item !== value) // Remove if already selected
      : [...stateData, value]; // Add if not selected

    // Update the state in the parent
    setStateData(updatedMeetingTypes);
  };

  return (
    <TitleContainer title={bookingLinkList.translatedTitle} isCollapasable>
      {bookingLinkList.option.map((item, index) => (
        <MultiSelectRadioBtn
          key={item._id}
          isChecked={stateData.includes(item.value)}
          label={item.translatedTitle}
          onClick={() => handleSelectionChange(item.value)}
        />
      ))}
    </TitleContainer>
  );
};

export default BookingLink;
