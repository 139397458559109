import React from "react";
import { BilledIcon } from "../../../../../images";
import { TraxApprovedBadge } from "../../components";
import { Stack } from "@mui/material";

interface TableReceiptViewAreaProps {
  value: boolean;
}

const TableReceiptViewArea: React.FC<TableReceiptViewAreaProps> = ({
  value,
}) => {
  return (
    <Stack alignItems={"center"}>
      {value ? (
        <BilledIcon color="#0C501C" w={15} />
      ) : (
        <TraxApprovedBadge variant="pending" />
      )}
    </Stack>
  );
};

export default TableReceiptViewArea;
