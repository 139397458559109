import { CloudStorageState } from "../CloudStorageType";

export const uploadCloudFile__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};

// Get All Files
export const getAllFiles__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};

// Get One File
export const getOneFile__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};

// Download File
export const downloadFile__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
    state.downloadFile = null;
};

// Share File With Users
export const shareFileWithUsers__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};

// Share File With Email
export const shareFileWithEmail__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};

// Delete File
export const deleteFile__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};

// Update File
export const updateFile__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};

// Upload New Version
export const uploadNewVersion__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};

// Create Folder
export const createFolder__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};

// Rename Folder
export const renameFolder__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};

// Get All Folders
export const getAllFolders__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};
// Get All Folders
export const getAllMemberCS__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
    state.memberOfCloudStorageFile = null;
};

// Create Link
export const createLink__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};

// Get Link
export const getLink__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
    state.linkData = null;
};

// Delete Link
export const deleteLink__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};

// Get All Link
export const getAllLink__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};

// Add View
export const addView__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};

// Add Time
export const addTime__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};

// Toggle Download
export const toggleDownload__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};

// Toggle Ask Details
export const toggleAskDetails__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};

// Update Expiration
export const updateExpiration__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};

// Update Link Password
export const updateLinkPassword__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};

// Toggle Watermark
export const toggleWatermark__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};

/* ---------------------- BOX -------------------------------- */

// File Management Routes
export const uploadFile__BOX__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};
export const downloadFile__BOX__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};
export const lockFile__BOX__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};
export const unlockFile__BOX__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};
export const createSharedLink__BOX__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};
export const disableSharedLink__BOX__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};
export const addComment__BOX__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};
export const retrieveComments__BOX__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};

// Folder Management
export const createFolder__BOX__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};
export const deleteFolder__BOX__Request = (state: CloudStorageState) => {
    state.error = null;
    state.data = null;
};
export const searchFilesAndFolders__BOX__Request = (
    state: CloudStorageState
) => {
    state.error = null;
    state.data = null;
};
