import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { AcceptRejectButtonGroup } from "../../../../common";
import { DateIcon, FirstMessageIcon } from "../../../../../images";
import { TraxApprovedBadge } from "../TraxApprovedBadge";

interface ReceiptActionAreaProps {
  date: string;
  displayArea: "buttons" | "approved" | "rejected" | "pending";
  commentIconButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ReceiptActionArea: React.FC<ReceiptActionAreaProps> = ({
  date,
  displayArea,
  commentIconButtonClick,
}) => {
  const getComponent = () => {
    switch (displayArea) {
      case "buttons":
        return <AcceptRejectButtonGroup />;
      case "approved":
        return <TraxApprovedBadge variant="approved" />;
      case "rejected":
        return <TraxApprovedBadge variant="rejected" />;
      case "pending":
        return <TraxApprovedBadge variant="pending" />;
    }
  };

  return (
    <Stack
      sx={{
        flex: 1,
        border: "2px solid #E9EDF2",
        borderRadius: "5px",
        height: "75px",
        minHeight: "75px",
        boxSizing: "border-box",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 15px",
        flexShrink: 0,
      }}
    >
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
        <DateIcon w={25} />
        <Typography
          sx={{ font: "normal normal 900 13px/16px Source Serif Pro" }}
        >
          {date}
        </Typography>
      </Stack>

      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "15px" }}>
        {commentIconButtonClick && (
          <IconButton
            sx={{ p: 0 }}
            disableRipple
            onClick={(event) => commentIconButtonClick(event)}
          >
            <FirstMessageIcon w={20} color="black" />
          </IconButton>
        )}
        {getComponent()}
      </Stack>
    </Stack>
  );
};

export default ReceiptActionArea;
