import { Stack, styled, Typography, Popover, SxProps } from "@mui/material";
import React, { useState, useEffect } from "react";
import { MultiSectionDigitalClock } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ClockIcon } from "../../../images";

const StyledMultiSectionDigitalClock = styled(MultiSectionDigitalClock)({
  "& .MuiMultiSectionDigitalClockSection-root": {
    "&::-webkit-scrollbar": {
      display: "none" /* Chrome, Safari, Opera */,
    },
    scrollbarWidth: "none" /* Firefox */,
  },
  "& .MuiMultiSectionDigitalClockClock-root": {
    fontFamily: "Source Serif Pro, serif",
  },
  "& .MuiMultiSectionDigitalClockSection-item": {
    fontFamily: "Source Serif Pro, serif",
  },
});

interface DatePickerMiniProps {
  sx?: SxProps;
  hideIcon?: boolean;
  readonlyComponent?: boolean;
  onTimeChange?: (time: Dayjs | null) => void;
  onFormatedTimeSelect?: (time: string) => void;
  initialTime?: string;
  additionalWord?: string;
}

const DatePickerMini: React.FC<DatePickerMiniProps> = ({
  sx,
  hideIcon = false,
  readonlyComponent = false,
  onTimeChange,
  onFormatedTimeSelect,
  initialTime,
  additionalWord,
}) => {

  // Parse initialTime in UTC and convert it to local timezone
  const [selectedTime, setSelectedTime] = useState<Dayjs | null>(
    initialTime ? dayjs.utc(initialTime).local() : dayjs() // Parse as UTC and convert to local time
  );

  const [anchorElTime, setAnchorElTime] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (initialTime) {
      // Convert incoming UTC time to local time and update state
      const parsedTime = dayjs.utc(initialTime).local(); // Ensure UTC to local conversion
      setSelectedTime(parsedTime);
    }
  }, [initialTime]);

  const handleClickTime = (event: React.MouseEvent<HTMLElement>) => {
    if (!readonlyComponent) {
      setAnchorElTime(event.currentTarget);
    }
  };

  const handleCloseTime = () => {
    setAnchorElTime(null);
  };

  const handleTimeChange = (time: Dayjs | null) => {
    setSelectedTime(time);

    // Only call these when user actually changes something
    if (onTimeChange) {
      onTimeChange(time);
    }

    if (onFormatedTimeSelect && time) {
      onFormatedTimeSelect(time.format("hh:mm A"));
    }
  };

  const openTime = Boolean(anchorElTime);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack
        onClick={(e: any) => handleClickTime(e)}
        sx={{
          height: "25px",
          width: "80px",
          border: "0.5px solid #E9EDF2",
          borderRadius: "5px",
          flexDirection: "row",
          alignItems: "center",
          px: "5px",
          boxSizing: "border-box",
          justifyContent: "space-between",
          cursor: "pointer",
          flexShrink: 0,
          font: "normal normal 300 12px/16px Source Serif Pro",
          ...sx,
        }}
      >
        <Typography sx={{ font: "inherit" }}>
          {selectedTime ? selectedTime.format("hh:mm A") : "time here"}{" "}
          {additionalWord}
        </Typography>
        {!hideIcon && <ClockIcon w={15} />}
      </Stack>
      <Popover
        open={openTime}
        anchorEl={anchorElTime}
        onClose={handleCloseTime}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <StyledMultiSectionDigitalClock
          timeSteps={{ minutes: 1 }}
          value={selectedTime}
          onChange={handleTimeChange}
        />
      </Popover>
    </LocalizationProvider>
  );
};

export default DatePickerMini;
