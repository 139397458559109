import React, { useState } from "react";
import { IconButton, Stack, Typography, Popover } from "@mui/material";
import { AllDirArrowCircleIcon } from "../../../../../../../images";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import dayjs from "dayjs"; // Import Day.js for date manipulation

interface DateSelectorAreaProps {
  textColor: "white" | "black";
}

const DateSelectorArea: React.FC<DateSelectorAreaProps> = ({ textColor }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [currentDate, setCurrentDate] = useState<Dayjs | null>(dayjs());

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  const handleDateChange = (newValue: Dayjs | null) => {
    setCurrentDate(newValue);
    handleClosePopover();
  };

  const handlePreviousDate = () => {
    setCurrentDate((prev) => (prev ? prev.subtract(1, "day") : dayjs()));
  };

  const handleNextDate = () => {
    setCurrentDate((prev) => (prev ? prev.add(1, "day") : dayjs()));
  };

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingRight: "16px",
      }}
    >
      <span style={{ cursor: "pointer" }} onClick={handleOpenPopover}>
        <Typography
          sx={{
            font: "normal normal 900 15px/19px Source Serif Pro",
            color: textColor,
          }}
        >
          {currentDate?.format("MMMM Do, YYYY")}
        </Typography>
      </span>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{ sx: { mt: "10px" } }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar value={currentDate} onChange={handleDateChange} />
        </LocalizationProvider>
      </Popover>

      <Stack sx={{ flexDirection: "row", gap: "10px" }}>
        <IconButton onClick={handlePreviousDate}>
          <AllDirArrowCircleIcon color={textColor} direction="left" />
        </IconButton>
        <IconButton onClick={handleNextDate}>
          <AllDirArrowCircleIcon color={textColor} direction="right" />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default DateSelectorArea;
