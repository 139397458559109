import { Stack, Typography } from "@mui/material";
import React from "react";

interface ShiftViewProps {
  startTime?: string;
  endTime?: string;
}

const ShiftView: React.FC<ShiftViewProps> = ({ startTime, endTime }) => {
  return (
    <Stack
      sx={{
        font: "normal normal 300 13px/16px Source Serif Pro",
        flexDirection: "row",
        gap: "5px",
        flexWrap: "wrap",
        alignItems: "center",
      }}
    >
      <Typography sx={{ font: "inherit" }}>{startTime}</Typography>
      <Typography sx={{ font: "inherit", fontWeight: 600 }}>to</Typography>
      <Typography sx={{ font: "inherit" }}>{endTime}</Typography>
    </Stack>
  );
};

export default ShiftView;
