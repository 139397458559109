import { Popover, Stack } from "@mui/material";
import { useState } from "react";
import { LastCalendarIcon } from "../../../../../../../images";
import {
  DatePickerMini,
  DatePickerWithIcon,
  TimeZoneCalendarDropdown,
} from "../../../../../../common";

interface EventTimeViewProps {
  updateField?: any;
}

const EventTimeView: React.FC<EventTimeViewProps> = ({ updateField }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "pst-popover" : undefined;

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        gap: "15px",
        font: "inherit",
      }}
    >
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
        <LastCalendarIcon w={20} />
        <DatePickerWithIcon
          hideIcon
          onDateChange={(data: any) => {
            // Check if data and data.$d are defined
            // if (data && data?.$d) {
            //   // Convert to ISO format
            //   const isoDate = new Date(data?.$d)?.toISOString();
            //   // Update the field with the ISO formatted date
            //   updateField && updateField("date", isoDate);
            // }
          }}
          dateFormat="MMM D, YYYY"
          sx={{
            width: "85px",
            font: "normal normal 500 15px/19px Source Serif Pro",
          }}
        />
      </Stack>
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
        <DatePickerMini
          hideIcon
          sx={{
            p: 0,
            border: "none",
            width: "65px",
            height: "20px",
            font: "normal normal 500 15px/19px Source Serif Pro",
          }}
          onFormatedTimeSelect={(value) => console.log(value)}
        />
        -
        <DatePickerMini
          hideIcon
          sx={{
            p: 0,
            border: "none",
            width: "65px",
            height: "20px",
            font: "normal normal 500 15px/19px Source Serif Pro",
          }}
          onFormatedTimeSelect={(value) => console.log(value)}
        />
      </Stack>
      <Stack onClick={handleClick} sx={{ cursor: "pointer" }}>
        PST
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
            height: "auto",
            overflow: "hidden",
            scrollbarWidth: "none",
          },
        }}
      >
        <TimeZoneCalendarDropdown
          initialValue="America/Argentina/Catamarca"
          onChange={(value) => null}
        />
      </Popover>
    </Stack>
  );
};

export default EventTimeView;
