import { Stack, Typography } from "@mui/material";
import React from "react";
import { TextLinkButton } from "../../../../common";
import {
  CentersFilterView,
  DateFilterView,
  KeywordFilterView,
  SourcesFilterView,
  AddionalFilterValue,
} from "./InnerComponent";
import Styles from "./CommandControlFilterMenu.module.css";
import {
  filterValueType,
  sourcesOptionsType,
} from "../CommandControlSearchContainer";
import { filterFilesNames } from "./CommandControlFilterMenuType";

interface CommandControlFilterMenuProps {
  primaryValue: filterValueType;
  setPrimaryValue: React.Dispatch<React.SetStateAction<filterValueType>>;
  sourcesoptionsList: sourcesOptionsType[];
}

const CommandControlFilterMenu: React.FC<CommandControlFilterMenuProps> = ({
  primaryValue,
  setPrimaryValue,
  sourcesoptionsList,
}) => {
  return (
    <Stack className={Styles.commandcontrolFilterMenu}>
      {/* -------- fixed header------ */}
      <Stack className={Styles.commandcontrolFilterMenu__headerbox}>
        <Typography
          className={Styles["commandcontrolFilterMenu__headerbox-title"]}
        >
          Filter
        </Typography>

        <TextLinkButton
          buttonLabel="Go"
          sx={{
            color: "#2A30B5",
            font: "normal normal 400 14px/18px Source Serif Pro",
          }}
        />
      </Stack>
      {/* ----- scrollable conainer ----- */}

      <Stack
        sx={{
          overflow: "auto",
          scrollbarWidth: "none",
          gap: "15px",
          paddingBottom: "25px",
        }}
      >
        <CentersFilterView
          sourcesoptionsList={sourcesoptionsList}
          primaryValue={primaryValue}
          setPrimaryValue={setPrimaryValue}
        />
        <SourcesFilterView
          sourcesoptionsList={sourcesoptionsList}
          primaryValue={primaryValue}
          setPrimaryValue={setPrimaryValue}
        />
        <DateFilterView />
        <KeywordFilterView
          primaryValue={primaryValue}
          setPrimaryValue={setPrimaryValue}
        />

        {/* ------------  conditional rending filter fields ------------- */}

        <AddionalFilterValue
          additionalFileNames={filterFilesNames}
          primaryValue={primaryValue}
          setPrimaryValue={setPrimaryValue}
        />
      </Stack>
    </Stack>
  );
};

export default CommandControlFilterMenu;
