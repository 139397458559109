import {InputBase, Stack, Typography} from "@mui/material";
import React from "react";
import {MediumButtonSmall} from "../MediumButtonSmall";
import {ButtonType} from "../../../../common";

interface JoinMeetingCardProps {
    handleJoinCall: () => void;
    onChangeLink: (value: string) => void;
}

const JoinMeetingCard: React.FC<JoinMeetingCardProps> = ({handleJoinCall, onChangeLink}) => {
    const handleInputChange = (value: string) => {
        onChangeLink(value)
    }

    return (
    <Stack
      sx={{
        width: "300px",
        height: "150px",
        boxSizing: "border-box",
        padding: "15px 15px 10px 15px",
        alignItems: "center",
        bgcolor: "white",
        borderRadius: "5px",
        boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
      }}
    >
      <Typography
        sx={{
          font: "normal normal 900 20px/25px Source Serif Pro",
          bolor: "black",
        }}
      >
        Join a Meeting
      </Typography>
      <InputBase
        sx={{
          font: "normal normal 300 13px/16px Source Serif Pro",
          borderRadius: "5px",
          border: "1px solid #E9EDF2",
          width: "100%",
          padding: "0px 10px",
          margin: "20px 0px 15px 0px",
        }}
        placeholder="Your meeting ID or Link"
        onChange={(e) => handleInputChange(e.target.value)}
      />
      <MediumButtonSmall
        label="Join Now"
        types={ButtonType.Button}
        onClick={handleJoinCall}
      />
    </Stack>
  );
};

export default JoinMeetingCard;
