import React, { useState } from "react";
import TitleContainer from "../../TitleContainer/TitleContainer";
import {
  BadgeDropdownTitleContainer,
  ChipInputField,
  CustomRadioFormControlLabel,
  MultiSelectRadioBtn,
} from "../../../../../../../common";
import { AdditionalFilterMultiRadioBtnType } from "../../../../CommandControlSearchContainer";
import { RadioGroup, Stack } from "@mui/material";

const employeeTypeList: AdditionalFilterMultiRadioBtnType = {
  _id: "employeeType",
  field: ["trax"],
  translatedTitle: "Employee Type",
  option: [
    { _id: "1", translatedTitle: "Full Time", value: "Full Time" },
    { _id: "2", translatedTitle: "Freelancer", value: "Freelancer" },
    { _id: "3", translatedTitle: "Part Time", value: "Part Time" },
    { _id: "4", translatedTitle: "Intern", value: "Intern" },
    { _id: "5", translatedTitle: "Contract", value: "Contract" },
    { _id: "6", translatedTitle: "Independent C.", value: "Independent C." },
  ],
};

type ComponentDataType = string[]; // this is type for this component data
interface EmployeeTypeProps {
  stateData: ComponentDataType; // Current data passed from the parent
  setStateData: (data: ComponentDataType) => void; // Function to update data in the parent
}

const EmployeeType: React.FC<EmployeeTypeProps> = ({
  setStateData,
  stateData,
}) => {
  const handleSelectionChange = (value: string) => {
    const updatedMeetingTypes = stateData.includes(value)
      ? stateData.filter((item) => item !== value) // Remove if already selected
      : [...stateData, value]; // Add if not selected

    // Update the state in the parent
    setStateData(updatedMeetingTypes);
  };

  return (
    <Stack sx={{ gap: "15px" }}>
      <TitleContainer
        title={employeeTypeList.translatedTitle}
        isCollapasable
        childrenSx={{ flexDirection: "row", flexWrap: "wrap" }}
      >
        {employeeTypeList.option.map((item, index) => (
          <MultiSelectRadioBtn
            sx={{ width: "45%" }}
            key={item._id}
            isChecked={stateData.includes(item.value)}
            label={item.translatedTitle}
            onClick={() => handleSelectionChange(item.value)}
          />
        ))}
      </TitleContainer>
    </Stack>
  );
};

export default EmployeeType;
