import React from "react";
import { Stack, Tooltip } from "@mui/material";
import { AttendanceStatusType } from "../../../CrmStatusBadge";
import {
  BreakHoverCard,
  OvertimeHoverCard,
  StartTimeHoverCard,
  WorkHoverCard,
} from "./HoverComponents";

export const StartTimeArea: React.FC<{
  cardWidth: string;
  cardtype: AttendanceStatusType;
  checkInTime: string;
  checkoutTime: string;
  totalBreak: string;
  totalWorkTime: string;
}> = ({
  cardWidth,
  cardtype,
  checkInTime,
  checkoutTime,
  totalBreak,
  totalWorkTime,
}) => {
  const getClr = () => {
    switch (cardtype) {
      case "onTime":
        return "#308893";

      case "late":
        return "#FF0000";
    }
  };

  return (
    <Tooltip
      title={
        <StartTimeHoverCard
          checkInTime={checkInTime}
          checkoutTime={checkoutTime}
          totalBreakTime={totalBreak}
          totalWorkTime={totalWorkTime}
        />
      }
      placement="top"
      arrow
      disableInteractive
    >
      <Stack
        sx={{
          height: "100%",
          width: cardWidth,
          bgcolor: "#FAF3F3",
          borderRadius: "5px 0px 0px 5px",
          position: "relative",
          flexDirection: "row",
        }}
      >
        <Stack
          sx={{
            width: "5px",
            bgcolor: getClr(),
            height: "35px",
            borderRadius: "5px",
            position: "absolute",
            left: "5px",
            top: "3px",
          }}
        />
      </Stack>
    </Tooltip>
  );
};

export const OverTimeArea: React.FC<{
  cardWidth: string;
  totalTime: string;
}> = ({ cardWidth, totalTime }) => {
  return (
    <Tooltip
      title={<OvertimeHoverCard totalTime={totalTime} />}
      placement="top"
      arrow
      disableInteractive
    >
      <Stack
        sx={{
          height: "100%",
          width: cardWidth,
          bgcolor: "#FF000073",
          borderRadius: "5px",
        }}
      />
    </Tooltip>
  );
};

export const BreakArea: React.FC<{ cardWidth: string; totalTime: string }> = ({
  cardWidth,
  totalTime,
}) => {
  return (
    <Tooltip
      title={<BreakHoverCard totalTime={totalTime} />}
      placement="top"
      arrow
      disableInteractive
    >
      <Stack sx={{ height: "100%", width: cardWidth, bgcolor: "#8C8C8C" }} />
    </Tooltip>
  );
};

export const WorkTimeArea: React.FC<{
  cardWidth: string;
  totalTime: string;
}> = ({ cardWidth, totalTime }) => {
  return (
    <Tooltip
      title={<WorkHoverCard totalTime={totalTime} />}
      placement="top"
      arrow
      disableInteractive
    >
      <Stack sx={{ height: "100%", width: cardWidth, bgcolor: "#FAF3F3" }} />
    </Tooltip>
  );
};
