import { Drawer, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { BilledIcon, ConnectedIcon } from "../../../../../../../images";
import { QuickViewReceipt } from "../../../QuickViewReceipt";

interface ReceiptViewProps {}

const ReceiptView: React.FC<ReceiptViewProps> = ({}) => {
  const [showDrawer, setShowDrawer] = useState(false);
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "5px" }}>
        <ConnectedIcon w={15} />
        <Typography
          sx={{ font: "normal normal 300 13px/16px Source Serif Pro" }}
        >
          Receipt
        </Typography>
      </Stack>
      <IconButton onClick={() => setShowDrawer(true)}>
        <BilledIcon color="#0C501C" w={15} />
      </IconButton>

      <Drawer
        anchor={"right"}
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        sx={{ zIndex: "1211 !important" }}
      >
        <QuickViewReceipt />
      </Drawer>
    </Stack>
  );
};

export default ReceiptView;
