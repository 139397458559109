import { TextLabelDropdownOptionsType } from "../../common"

export type PaymentMode =
    | 'directDeposit'
    | 'physicalCheck'
    | 'payCard'
    | 'mobilePay'

export const PaymentViaMenuOptions: TextLabelDropdownOptionsType<PaymentMode>[] = [
    { _id: '1', label: "Direct Deposit", value: "directDeposit" },
    { _id: '2', label: "Physical Check", value: "physicalCheck" },
    { _id: '3', label: "Pay Card", value: "payCard" },
    { _id: '4', label: "Mobile Pay", value: "mobilePay" },
]