import {Dialog, Popover, Stack} from "@mui/material";
import React, {lazy, Suspense} from "react";
import {a11yProps, StyledTab, TabPanel, TabsWithState,} from "../../../../common/CardTabsWithoutIcon";
import {AddToMeetingMenu} from "../AddToMeetingMenu";
import {AddAgendaPopup} from "../AddAgendaPopup";
import {AuthState, postMeetingsFiles, updateMeetingsDetails, uploadCloudFile__api} from "../../../../../redux";
import {RootState} from "../../../../../redux/store";
import {useDispatch, useSelector} from "react-redux";
import {VideoCenterState} from "../../../../../redux/Slice/videoCenter/VideoCenterTypes";
import {QuickActionsMenu} from "../../../../common";
import {useTranslation} from "react-i18next";
import {MeetingNotesCardLarge} from "../MeetingNotesCardLarge";
import {ConnectFilePopup} from "../ConnectFilePopup";
import {SearchWorkSpaceDropdown} from "../../../../common/SearchWorkSpaceDropdown";
import SearchProjectsDropdown from "../../../../common/SearchProjectsDropdown/SearchProjectsDropdown";
import {GlobalState} from "../../../../../redux/Slice/Global/globalTypes";

interface CallCenterTabsProps {
}

const LazyAgendaTab = lazy(() => import("./tabs/AgendaTab/AgendaTab"));
const LazyDetailsTab = lazy(() => import("./tabs/DetailsTab/DetailsTab"));
const LazyFilesTab = lazy(() => import("./tabs/FilesTab/FilesTab"));
const LazyNotesTab = lazy(() => import("./tabs/NotesTab/NotesTab"));

const CallCenterTabs: React.FC<CallCenterTabsProps> = ({}) => {
    const {t} = useTranslation();
    const [tabValue, setTabValue] = React.useState(0);
    const [openNotePopup, setOpenNotePopup] = React.useState(false);
    const [openAgendaPopup, setOpenAgendaPopup] = React.useState(false);
    const [openFilesPopup, setOpenFilesPopup] = React.useState(false);
    const [editAgendaItem, setEditAgendaItem] = React.useState(null);
    const [anchorWorkSpacePopup, setAnchorWorkSpacePopup] = React.useState<HTMLButtonElement | null>(null);
    const [anchorProjectPopup, setAnchorProjectPopup] = React.useState<HTMLButtonElement | null>(null);
    const {selectedMeeting} = useSelector(
        (state: RootState) => state.videoCenter as VideoCenterState
    );
    const {user} = useSelector((state: RootState) => state.auth as AuthState);
    const dispatch = useDispatch();
    const {userProjects, userWorkspaces} = useSelector((state: RootState) => state.global as GlobalState);

    const openWorkSpacePopup = Boolean(anchorWorkSpacePopup);
    const workSpacePopupId = openWorkSpacePopup ? 'simple-popover' : undefined;
    const openProjectPopup = Boolean(anchorProjectPopup);
    const projectPopupId = openProjectPopup ? 'simple-popover' : undefined;

    const tabs: {
        label: string;
        content: React.ReactNode;
    }[] = [
        {
            label: t("VIDEO_CENTER.MEETING.DETAILS"),
            content: <LazyDetailsTab/>,
        },
        {
            label: t("VIDEO_CENTER.MEETING.NOTES"),
            content: <LazyNotesTab/>,
        },
        {
            label: t("VIDEO_CENTER.MEETING.AGENDA"),
            content: <LazyAgendaTab/>,
        },
        {
            label: t("VIDEO_CENTER.MEETING.FILES"),
            content: <LazyFilesTab/>,
        },
    ];

    const handleClickOnCancelNote = () => {
        setOpenNotePopup(false);
    };

    const handleClickOnCancelAgenda = () => {
        setOpenAgendaPopup(false);
        setEditAgendaItem(null);
    };

    const handleOnSelect = (value: string, event: any) => {
        if (value === "New Pre-Meeting Note") {
            setOpenNotePopup(true);
        }
        if (value === "New Agenda Item") {
            setOpenAgendaPopup(true);
        }
        if (value === "Connect a File") {
            setOpenFilesPopup(true);
        }
        if (value.includes("Workspace")) {
            handleOpenWorkSpacePopup(event);
        }
        if (value.includes("Project")) {
            handleOpenProjectPopup(event);
        }
    };

    const handleOpenWorkSpacePopup = (event: any) => {
        setAnchorWorkSpacePopup(event.currentTarget);
    };

    const handleCloseWorkSpacePopup = () => {
        setAnchorWorkSpacePopup(null);
    };

    const handleOpenProjectPopup = (event: any) => {
        setAnchorProjectPopup(event.currentTarget);
    };

    const handleCloseProjectPopup = () => {
        setAnchorProjectPopup(null);
    };

    const handleClickOnCloseFile = () => {
        setOpenFilesPopup(false);
    }

    const handleSelectProjects = async (selected: any) => {
        const payload = {
            projects: [selected?._id],
        };
        dispatch(
            updateMeetingsDetails(selectedMeeting?.meeting?._id, payload)
        );
        handleCloseProjectPopup();
    };

    const handleSelectWorkSpace = async (selected: any) => {
        const payload = {
            workspace: [selected?._id],
        };
        dispatch(
            updateMeetingsDetails(selectedMeeting?.meeting?._id, payload)
        );
        handleCloseProjectPopup();
    };

    const handleAddFileToMeeting = async (file: File, title: string, description: string) => {
        const formData = new FormData();
        formData.append("files", file);

        const response: any = await dispatch(uploadCloudFile__api(formData));
        const fileLink = response.data?.[0].path; // Assuming the API returns the link in the payload

        if (fileLink) {
            const payload = {
                title,
                description,
                fileUrl: fileLink,
            };
            await dispatch(postMeetingsFiles(selectedMeeting?.meeting?.chimeMeetingInfo?._id, payload));
        }
        setOpenFilesPopup(!openFilesPopup);
    };

    return (
        <Stack
            sx={{
                bgcolor: "white",
                width: "100%",
                height: "100%",
                boxSizing: "border-box",
                gap: "20px",
            }}
        >
            <Stack
                sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "24px",
                    borderBottom: "1px solid #E9EDF2",
                    justifyContent: "space-between",
                }}
            >
                <Stack direction="row" gap="24px">
                    <TabsWithState
                        ariaLabel="Call center tabs"
                        value={tabValue}
                        onChange={(event, newValue) => setTabValue(newValue)}
                        tabIndicatorColor="#FF8181"
                        sx={{
                            maxWidth: "max-content",
                            gap: "25px",
                            borderBottom: "0px solid #E9EDF2",
                            "& .css-1aquho2-MuiTabs-indicator": {
                                bottom: "0px !important",
                            },
                        }}
                    >
                        {tabs.map((tab, index) => (
                            <StyledTab
                                style={{
                                    font: "normal normal 300 15px/19px Source Serif Pro",
                                }}
                                key={index}
                                disableRipple
                                label={tab.label}
                                {...a11yProps(index)}
                            />
                        ))}
                    </TabsWithState>
                    {user?.data?._id === selectedMeeting?.meeting?.createdBy && (
                        <span style={{paddingBottom: "4px"}}>
            <AddToMeetingMenu onSelect={handleOnSelect}/>
          </span>)}
                </Stack>
                <span style={{paddingBottom: "4px", display: "flex"}}>
          <QuickActionsMenu onSelect={handleOnSelect}/>
        </span>
                <Popover
                    id={workSpacePopupId}
                    open={openWorkSpacePopup}
                    anchorEl={anchorWorkSpacePopup}
                    onClose={handleCloseWorkSpacePopup}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    PaperProps={{
                        sx: {
                            width: "350px",
                            height: "200px"
                        }
                    }}
                >
                    <SearchWorkSpaceDropdown
                        alwaysOpen={true}
                        onSelect={handleSelectWorkSpace}
                        options={userWorkspaces}
                    />
                </Popover>
                <Popover
                    id={projectPopupId}
                    open={openProjectPopup}
                    anchorEl={anchorProjectPopup}
                    onClose={handleCloseProjectPopup}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    PaperProps={{
                        sx: {
                            width: "350px",
                            height: "200px"
                        }
                    }}
                >
                    <SearchProjectsDropdown
                        alwaysOpen
                        onSelect={handleSelectProjects}
                        options={userProjects}
                    />
                </Popover>
            </Stack>
            <MeetingNotesCardLarge
                userData={user?.data}
                isDrawerOpen={openNotePopup}
                meetingData={selectedMeeting?.meeting}
                isPreMeeting={true}
                drawerCloseButtonClk={handleClickOnCancelNote}
            />
            <Dialog onClose={handleClickOnCancelAgenda} open={openAgendaPopup}>
                <AddAgendaPopup
                    userData={user?.data}
                    meetingData={selectedMeeting?.meeting}
                    editAgendaItem={editAgendaItem}
                    onClose={handleClickOnCancelAgenda}
                />
            </Dialog>

            <Dialog open={openFilesPopup} onClose={() => setOpenFilesPopup(false)}>
                <ConnectFilePopup onClose={handleClickOnCloseFile} onSave={handleAddFileToMeeting}/>
            </Dialog>

            {tabs.map((tab, index) => (
                <TabPanel
                    style={{display: "contents"}}
                    key={index}
                    value={tabValue}
                    index={index}
                >
                    <Suspense fallback={<div>Loading...</div>}>{tab.content}</Suspense>
                </TabPanel>
            ))}
        </Stack>
    );
};

export default CallCenterTabs;
