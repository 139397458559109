import React, { useEffect, useRef } from "react";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  ApprovalActionArea,
  AttendanceListInterface,
  CellWrapper,
  timeCardTableRowsWidth,
  timeHeaderListArray,
  timeListTableRowsWidth,
} from "../TimeCardTable";
import { AttendanceCard } from "./view";
import {
  AttendanceStatusColorLiist,
  AttendanceStatusType,
} from "../CrmStatusBadge";
import { adjustHexColor } from "../../../../../Utils";

interface TimeListTableProps {
  tableDataList: AttendanceListInterface[];
}

interface Event {
  type: "work" | "break" | "overtime" | "dayOff";
  start: string;
  end: string;
  label?: string; // Optional label for breaks or overtime
}

interface DayData {
  date: string;
  team: string;
  events: Event[];
}

const TimeListTable: React.FC<TimeListTableProps> = ({ tableDataList }) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const getBgColor = (workType: AttendanceStatusType): string => {
    const clrOpacity = 20;
    switch (workType) {
      case "absent":
        return adjustHexColor(AttendanceStatusColorLiist.absent, clrOpacity);
      case "dayoff":
        return adjustHexColor(AttendanceStatusColorLiist.dayoff, clrOpacity);
      case "holiday":
        return adjustHexColor(AttendanceStatusColorLiist.holiday, clrOpacity);
      case "pto":
        return adjustHexColor(AttendanceStatusColorLiist.pto, clrOpacity);
      case "sick":
        return adjustHexColor(AttendanceStatusColorLiist.sick, clrOpacity);
      default:
        return "#ffffff";
    }
  };

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        left: timeListTableRowsWidth.timeAreaWidth * 6,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <Stack
      sx={{
        width: timeCardTableRowsWidth.totalColWidth,
        height: "100%",
        overflowX: "auto",
        bgcolor: "#f5f5f5",
        boxSizing: "border-box",
      }}
    >
      <TableContainer
        ref={scrollContainerRef}
        sx={{
          height: "100%",
          scrollbarWidth: "none",
          boxShadow: "none",
          borderRadius: 0,
          border: "1px solid #E9EDF2",
          borderRight: "0px solid #E9EDF2 !important",
        }}
        component={Paper}
      >
        <Table stickyHeader>
          {/* Header */}
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  borderBottom: "1px solid #E9EDF2",
                  height: "30px",
                  padding: 0,
                  boxSizing: "border-box",
                  minHeight: 0,
                }}
              >
                <Stack sx={{ flexDirection: "row", height: "100%" }}>
                  <CellWrapper
                    colWidth={timeListTableRowsWidth.DateWidth}
                    sx={{
                      position: "sticky",
                      left: 0,
                      zIndex: 200,
                      height: "100%",
                      bgcolor: "white",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        font: "normal normal 300 14px/18px Source Serif Pro",
                      }}
                    >
                      Date
                    </Typography>
                  </CellWrapper>
                  {timeHeaderListArray.map((slot, index) => (
                    <CellWrapper
                      key={index}
                      colWidth={timeListTableRowsWidth.timeAreaWidth}
                      sx={{ justifyContent: "center" }}
                    >
                      <Typography
                        sx={{
                          font: "normal normal 300 14px/18px Source Serif Pro",
                        }}
                      >
                        {slot}
                      </Typography>
                    </CellWrapper>
                  ))}

                  <CellWrapper
                    sx={{
                      position: "sticky",
                      right: 0,
                      zIndex: 200,
                      backgroundColor: "#fff",
                      fontWeight: "bold",
                      textAlign: "center",
                      borderTopLeftRadius: "5px",
                      boxShadow: "-3px 0px 3px 0px rgba(0, 0, 0, 0.15)",
                    }}
                    colWidth={timeListTableRowsWidth.ApprovalWidth}
                  >
                    <Typography
                      sx={{
                        font: "normal normal 300 14px/18px Source Serif Pro",
                      }}
                    >
                      Approval
                    </Typography>
                  </CellWrapper>
                </Stack>
              </TableCell>
            </TableRow>
          </TableHead>

          {/* Body */}
          <TableBody>
            {tableDataList.map((row, dayIndex) => (
              <TableRow key={dayIndex}>
                <TableCell
                  sx={{
                    height: "50px",
                    padding: 0,
                    boxSizing: "border-box",
                    minHeight: 0,
                  }}
                >
                  <Stack sx={{ flexDirection: "row", height: "100%" }}>
                    {/* Fixed Date Column */}
                    <CellWrapper
                      colWidth={timeListTableRowsWidth.DateWidth}
                      sx={{
                        position: "sticky",
                        left: 0,
                        backgroundColor: "#fff",
                        zIndex: 1,
                        height: "auto",
                      }}
                    >
                      <Typography
                        sx={{
                          font: "normal normal 300 14px/18px Source Serif Pro",
                        }}
                      >
                        {row.day}, {row.date}
                      </Typography>
                    </CellWrapper>

                    {/* Scrollable Time Slots */}
                    <Stack
                      sx={{
                        width: timeListTableRowsWidth.totalTimeAreaWidth,
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "start",
                        height: "100%",
                        bgcolor: getBgColor(row.attendanceType),
                      }}
                    >
                      {/* this component is attendance display card */}
                      <AttendanceCard
                        workType={row.attendanceType}
                        breakList={row.breakList}
                        overtime={row.overtime}
                        workStartTime={row.workStartTime}
                        workStopTime={row.workStopTime}
                      />
                    </Stack>

                    {/* action button area */}
                    <CellWrapper
                      colWidth={timeListTableRowsWidth.ApprovalWidth}
                      sx={{
                        position: "sticky",
                        right: 0,
                        backgroundColor: "#fff",
                        zIndex: 1,
                        height: "auto",
                        alignItems: "center",
                        justifyContent: "center",
                        boxShadow: "-3px 0px 3px 0px rgba(0, 0, 0, 0.15)",
                      }}
                    >
                      <ApprovalActionArea
                        approvalStatus={row.approvalStatus}
                        cardType={row.attendanceType}
                        isOvertime={!!true}
                        hideNotedButton
                      />
                    </CellWrapper>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

// Helper: Get Background Color Based on Event Type
const getEventColor = (events: Event[], time: string): string => {
  const event = events.find((e) => time >= e.start && time < e.end);
  switch (event?.type) {
    case "work":
      return "#e8f5e9"; // Light green
    case "break":
      return "#eeeeee"; // Light gray
    case "overtime":
      return "#ffebee"; // Light red
    case "dayOff":
      return "#fbe9e7"; // Light pink
    default:
      return "transparent";
  }
};

// Helper: Render Event Label
const renderEventLabel = (events: Event[], time: string): string | null => {
  const event = events.find((e) => time >= e.start && time < e.end);
  return event?.label || null;
};

export default TimeListTable;
