import { PayloadAction } from "@reduxjs/toolkit";
import { CalendarState } from "../CalendarType";

// Successes
export const LoginExternalCalendar__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Successes
export const AuthExternalCalendar__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Successes
export const createCalendar__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const updateCalendarAppearance__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getSharedWith__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
  state.memberOfCalendar = action?.payload?.data;
};

export const shareCalendarWithUser__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const updateEventDate__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
export const updateEventTaskStatus__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const subscribeToICal__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};
export const subscribeToUserCal__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllCalendars__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
  state.allCalendarList = action?.payload?.data;
  state.isGetAllCalendarList = false;
  state.lastApiCallTime = Date.now(); // Set timestamp after successful API call
};

export const getOneCalendar__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getOneEvent__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const updateCalendar__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const deleteCalendar__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const createCalendarSettings__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getCalendarSettings__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
  state.calSettings = action?.payload?.data;
};

export const updateCalendarSettings__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
  state.calSettings = action?.payload?.data; // Ensure calSettings is also updated
};

export const addConnectedCalendar__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const removeConnectedCalendar__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const createEvent__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllEvents__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
  state.allCalenderEventsList = action?.payload?.data;
};

export const createTask__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllTasks__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const createBookingLink__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getBookingLinks__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const eventResponse__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

// Successes
export const createSchedule__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllSchedules__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.scheduleList = action?.payload?.data;
  state.data = action?.payload?.data;
};

export const getOneSchedule__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const updateSchedule__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const deleteSchedule__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAllBookingLinks__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const trackVisit__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getPublicBookingLink__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getPrivateBookingLink__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const getAvailableTimeSlots__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const createBookingEvent__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  state.data = action?.payload?.data;
};

export const cmd__calendar__Success = (
  state: CalendarState,
  action: PayloadAction<any>
) => {
  // Filter the data where center is "PLAN"
  const filteredData = action?.payload?.data?.filter(
    (item: any) => item.center === "PLAN"
  );

  // Store only the filtered data
  state.cmd__Notifications = filteredData;
};
