import React from "react";
import { PayrollCardHeaderButton, PayrollCardMenu } from "../../common";
import { DealStageCurrencySymbols } from "../../../../common";
import { FundingIcon } from "../../../../../images";

interface EarningsCardPayrollProps {}

const EarningsCardPayroll: React.FC<EarningsCardPayrollProps> = ({}) => {
  const currencySymbol = DealStageCurrencySymbols.USD;
  return (
    <div>
      <PayrollCardHeaderButton
        bgColor="#007BB2"
        currencySymbol={currencySymbol}
        icon={FundingIcon}
        label="Earnings"
        value={25000.0}
      >
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Base Salary"
          primaryValue={50000}
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Overtime Pay"
          showLockIcon={true}
          primaryValue={1000}
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Bonus Pay"
          primaryValue={2500}
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Commissions"
          primaryValue={500}
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Incentives"
          primaryValue={10000}
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Holiday Pay"
          primaryValue={2000}
        />
        <PayrollCardMenu
          onButtonClick={() => {
            console.log("ok");
          }}
          currencySymbol={currencySymbol}
          translatedLabel="Other Earnings"
          primaryValue={500}
        />
      </PayrollCardHeaderButton>
    </div>
  );
};

export default EarningsCardPayroll;
