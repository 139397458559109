import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import {StreamBearishOSLogoIconPng} from "../../../../../images";
import { AvatarNormal } from "../../../../common";
import styles from "./MeetingInfoCardSquare.module.css";
import {getHTMLContent} from "../../../Message/constant";

interface MeetingInfoCardSquareProps {
  noteDetails?:any
  meetingInfo?: any;
}

const MeetingInfoCardSquare: React.FC<MeetingInfoCardSquareProps> = ({noteDetails,meetingInfo}) => {
  return (
    <Stack className={styles.meetingInfoCardSquare}>
      <Stack className={styles.meetingInfoCardSquare__content}>
        <Stack className={styles.meetingInfoCardSquare__header}>
          <Box
            component={"img"}
            src={StreamBearishOSLogoIconPng}
            className={styles.meetingInfoCardSquare__logo}
            alt="logo"
          />
          <Typography className={styles.meetingInfoCardSquare__title}>
            {meetingInfo?.meeting?.title || "Meeting Title"}
          </Typography>
        </Stack>

          <Stack className={styles.meetingInfoCardSquare__description}>
            <Typography className={styles.meetingInfoCardSquare__noteName} dangerouslySetInnerHTML={{ __html: getHTMLContent(noteDetails?.title) }}/>
            <Typography className={styles.meetingInfoCardSquare__noteInfo} dangerouslySetInnerHTML={{ __html: getHTMLContent(noteDetails?.content) }}/>
          </Stack>
        </Stack>

      <Stack className={styles.meetingInfoCardSquare__footer}>
        <Stack className={styles.meetingInfoCardSquare__footerLeft}>
          <AvatarNormal
            size={20}
            avatarColor="green"
            imgSrc={meetingInfo?.meeting?.createdByInfo?.avatar}
            username={meetingInfo?.meeting?.createdByInfo.firstName + " " + meetingInfo?.meeting?.createdByInfo?.lastName }
          />
          <Typography className={styles.meetingInfoCardSquare__footerText}>
            {meetingInfo?.meeting?.createdByInfo.firstName + " " + meetingInfo?.meeting?.createdByInfo?.lastName || "Host Name"}
          </Typography>
        </Stack>
        <Typography className={styles.meetingInfoCardSquare__date}>
          {new Date(noteDetails?.createdAt).toLocaleDateString('en-GB')}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default MeetingInfoCardSquare;
