import React, { useEffect, useState } from "react";
import {
  FilterDocumentsSearchOptionsType,
  FilterDocumentsSearchType,
  FilterDocumentsSearchValues,
} from "./FilterDocumentsSearchType";
import { FilterCheckBoxMenu } from "./view";
import { Button, Popover, Stack, Typography } from "@mui/material";
import { FiltersIcon } from "../../../images";

interface FilterDocumentsSearchProps {
  options?: FilterDocumentsSearchOptionsType[];
  initialData?: FilterDocumentsSearchType[];
  onChange: (dataList: FilterDocumentsSearchType[]) => void;
}

const FilterDocumentsSearch: React.FC<FilterDocumentsSearchProps> = ({
  options = FilterDocumentsSearchValues,
  onChange,
  initialData = [],
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIdList, setSelectedIdList] = useState<string[]>([]);
  // const [selectedItemList, setSelectedItemList] =
  //   useState<FilterDocumentsSearchType[]>(initialData);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const updateSelectedItems = (newList: string[]) => {
    const filterById = (data: any[]): any[] => {
      return data
        .map((item) => {
          // Check if the current item's _id is in the newList
          if (newList.includes(item._id)) {
            return {
              ...item,
              children: item.children ? filterById(item.children) : [], // Recursively filter children
            };
          }
          return null; // If no match, return null to exclude this item
        })
        .filter(Boolean); // Remove null values
    };

    const updatedItems = filterById(options); // Start filtering from the root options
    // setSelectedItemList(updatedItems as FilterDocumentsSearchType[]);

    onChange(updatedItems);
  };

  const checkBoxClickHandler = (_id: string) => {
    const updatedSelectedIdList = selectedIdList.slice(); // Create a copy of the current state

    const hierarchyIds = _id.split(".");
    const parentIds = hierarchyIds.map((_, index) =>
      hierarchyIds.slice(0, index + 1).join(".")
    ); // Generate parent IDs, e.g., ["2", "2.4", "2.4.3"]

    let newList: string[] = [];
    if (updatedSelectedIdList.includes(_id)) {
      // If the ID is already in the list, remove it and all its descendants
      newList = updatedSelectedIdList.filter((id) => !id.startsWith(_id));
    } else {
      // If the ID is not in the list, add the ID and its parent IDs
      const toAdd = parentIds.filter(
        (id) => !updatedSelectedIdList.includes(id)
      ); // Only add missing parent IDs
      newList = [...updatedSelectedIdList, ...toAdd];
    }

    setSelectedIdList(newList);
    updateSelectedItems(newList); // Call the function to update selected items
  };

  // const checkBoxClickHandler = (_id: string) => {
  //   setSelectedIdList((prevList) => {
  //     const hierarchyIds = _id.split(".");
  //     const parentIds = hierarchyIds.map((_, index) =>
  //       hierarchyIds.slice(0, index + 1).join(".")
  //     ); // Generate parent IDs, e.g., ["2", "2.4", "2.4.3"]

  //     if (prevList.includes(_id)) {
  //       // If the ID is already in the list, remove it and all its descendants
  //       return prevList.filter((id) => !id.startsWith(_id));
  //     } else {
  //       // If the ID is not in the list, add the ID and its parent IDs
  //       const toAdd = parentIds.filter((id) => !prevList.includes(id)); // Only add missing parent IDs
  //       return [...prevList, ...toAdd];
  //     }
  //   });
  // };

  // useEffect(() => {
  //   // console.log("Selected IDs:", selectedIdList);
  //   console.log("Selected Items:", selectedItemList);
  // }, [selectedIdList, selectedItemList]);

  const getAllIdsFromData = (data: FilterDocumentsSearchType[]): string[] => {
    const collectIds = (items: FilterDocumentsSearchType[]): string[] => {
      return items.reduce<string[]>((acc, item) => {
        acc.push(item._id); // Add the current item's _id
        if (item.children && item.children.length > 0) {
          // If there are children, recursively collect their _ids
          acc = [
            ...acc,
            ...collectIds(item.children as FilterDocumentsSearchType[]),
          ];
        }
        return acc;
      }, []);
    };

    return collectIds(data);
  };

  useEffect(() => {
    if (initialData) {
      const idList = getAllIdsFromData(initialData);
      setSelectedIdList(idList);
    }
  }, []);

  return (
    <Stack>
      <Button
        onClick={handleClick}
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
          textTransform: "none",
          color: "black",
        }}
      >
        <FiltersIcon w={15} />
        <Typography
          sx={{ font: "normal normal 300 12px/15px Source Serif Pro" }}
        >
          Filter
        </Typography>
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            marginTop: "15px",
            boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <FilterCheckBoxMenu
          options={options}
          selectedList={selectedIdList}
          checkBoxClickHandler={(_id) => checkBoxClickHandler(_id)}
        />
      </Popover>
    </Stack>
  );
};

export default FilterDocumentsSearch;
