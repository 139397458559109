import React from "react";
import TitleContainer from "../TitleContainer/TitleContainer";
import { RadioGroup, Stack } from "@mui/material";
import {
  BadgeDropdownTitleContainer,
  ChipInputField,
  CustomRadioFormControlLabel,
} from "../../../../../../common";
import {
  filterValueType,
  KeyWordsMatchStateEnum,
} from "../../../CommandControlSearchContainer";

interface KeywordFilterViewProps {
  primaryValue: filterValueType;
  setPrimaryValue: React.Dispatch<React.SetStateAction<filterValueType>>;
}

const KeywordFilterView: React.FC<KeywordFilterViewProps> = ({
  primaryValue,
  setPrimaryValue,
}) => {
  const handleChipChange = (keywords: string[]) => {
    setPrimaryValue((prev) => ({
      ...prev,
      primaryKeywords: {
        ...prev.primaryKeywords,
        bodyKeywords: keywords,
      },
    }));
  };

  const handleMatchTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const matchType = event.target.value as KeyWordsMatchStateEnum;
    setPrimaryValue((prev) => ({
      ...prev,
      primaryKeywords: {
        ...prev.primaryKeywords,
        matchType,
      },
    }));
  };

  return (
    <TitleContainer title="Keywords">
      <BadgeDropdownTitleContainer title="Subject keywords">
        <ChipInputField
          initialChips={primaryValue.primaryKeywords.bodyKeywords}
          onChange={handleChipChange}
        />
      </BadgeDropdownTitleContainer>
      <RadioGroup
        aria-labelledby="subjectKeyword-radio-buttons-group"
        name="subjectKeyword-radio-buttons-group"
        value={primaryValue.primaryKeywords.matchType}
        onChange={handleMatchTypeChange}
        sx={{ p: 0, marginTop: "5px", gap: "13px" }}
      >
        <CustomRadioFormControlLabel
          value={KeyWordsMatchStateEnum.EXACT_MATCH}
          label="Exact match"
        />
        <CustomRadioFormControlLabel
          value={KeyWordsMatchStateEnum.CONTAINS}
          label="Contains"
        />
        <CustomRadioFormControlLabel
          value={KeyWordsMatchStateEnum.DOES_NOT_CONTAIN}
          label="Does not contain"
        />
      </RadioGroup>
    </TitleContainer>
  );
};

export default KeywordFilterView;
