import React from "react";
import TitleContainer from "../../TitleContainer/TitleContainer";
import {
  BadgeDropdownTitleContainer,
  DropdownWithImageBadge,
  DUMMY_USER_EMAIL_LIST,
  HostDropdown,
} from "../../../../../../../common";

type ComponentDataType = {
  from: any[]; // Array of selected "from" items
  hosts: any[]; // Array of selected "hosts" items
};

interface MessageSourceProps {
  stateData: ComponentDataType; // Current data passed from the parent
  setStateData: (data: ComponentDataType) => void; // Function to update data in the parent
}

const MessageSource: React.FC<MessageSourceProps> = ({
  setStateData,
  stateData,
}) => {
  // Handle updates for the "from" field
  const handleFromChange = (newFrom: any[]) => {
    setStateData({ ...stateData, from: newFrom });
  };

  // Handle updates for the "hosts" field
  const handleHostsChange = (newHosts: any[]) => {
    setStateData({ ...stateData, hosts: newHosts });
  };

  return (
    <TitleContainer
      title={"Message Source"}
      isCollapasable
      childrenSx={{ gap: "15px" }}
    >
      {/* ------------------------------------------ */}
      <BadgeDropdownTitleContainer title="From">
        <DropdownWithImageBadge
          initialData={stateData.from}
          onChangeSelection={handleFromChange} // Pass handler for from
          alwaysShowPlaceholder
          placeholder="Type here..."
          options={DUMMY_USER_EMAIL_LIST}
        />
      </BadgeDropdownTitleContainer>

      {/* ------------------------------------------ */}

      <BadgeDropdownTitleContainer title="Hosts">
        <HostDropdown
          getHosts={() => null}
          onChangeSelection={() => null}
          options={[]}
          initialHosts={stateData.hosts}
        />
      </BadgeDropdownTitleContainer>
    </TitleContainer>
  );
};

export default MessageSource;
